import { graphql } from '@/__generated/gql';

export const CUSTOMER_BVN_DETAILS = graphql(/* GraphQL */ `
  query GetNewBvnDetails($bvn: String!) {
    getNewBvnDetails(bvn: $bvn) {
      bvn
      dateOfBirth
      firstName
      gender
      id
      image
      lastName
      phone
    }
  }
`);
