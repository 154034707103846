import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { connection_limit } from '../config/config';
import { TRANSACTIONS_QUERY } from '../graphql/queries';
import Constants from '../lib/Constants';
import { useCustomState } from '../lib/CustomHooks';
import { deepCopy, exportToCsv, trimGraphQLError } from '../lib/Utils';
import { toast } from 'react-toastify';

const LiquidityManagement = () => {
  const liquidityManagementInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    searchParams: {
      disbursementWalletId: useCustomState(),
      disbursementWalletAmount: useCustomState(),
      dWCreatedAtLt: useCustomState(),
      dWCreatedAtGt: useCustomState(),
    },
  };

  const handleHideToast = e => {
    e.preventDefault();

    liquidityManagementInput.showToast.setValue(false);
  };

  const { data, loading, error, refetch } = useQuery(TRANSACTIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: connection_limit.value,
      disbursementWalletType: Constants.DISBURSEMENT_TRANSACTION_TYPE,
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(
        trimGraphQLError(
          error?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
    return () => toast.dismiss();
  }, [error]);

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    reFetch(refetch, liquidityManagementInput.searchParams);
  };

  const handleDisbursementWalletReset = async (e, reFetch) => {
    e.preventDefault();

    const { searchParams } = liquidityManagementInput;

    searchParams.disbursementWalletId.setValue('');
    searchParams.dWCreatedAtLt.setValue('');
    searchParams.dWCreatedAtGt.setValue('');

    reFetch({
      disbursementWalletId: undefined,
      dWCreatedAtLt: undefined,
      dWCreatedAtGt: undefined,
      first: connection_limit.value,
    });
  };

  const setSearchParams = searchParams => {
    const { disbursementWalletId, dWCreatedAtLt, dWCreatedAtGt } = searchParams;

    return {
      disbursementWalletId: disbursementWalletId.value
        ? disbursementWalletId.value
        : undefined,
      dWCreatedAtLt: dWCreatedAtLt.value || undefined,
      dWCreatedAtGt: dWCreatedAtGt.value || undefined,
      first: connection_limit.value || undefined,
    };
  };

  const reFetch = async (refetch, searchParams) => {
    refetch(setSearchParams(searchParams));
  };

  const handleExportToCsv = (e, data) => {
    if (data == null) {
      liquidityManagementInput.showToast.setValue(true);
      liquidityManagementInput.status.setValue('Failed');
      liquidityManagementInput.content.setValue(
        Constants.errorMessages.NO_EXPORT_DATA,
      );

      setTimeout(() => {
        liquidityManagementInput.showToast.setValue(false);
      }, 2000);
    }

    let csvData = null;
    let csvName = null;

    const {
      getWallets: {
        account: {
          wallets: {
            disbursementWallet: {
              transactions: { nodes },
            },
          },
        },
      },
    } = data;

    csvData = deepCopy(nodes);
    csvName = Constants.csvNames.DISBURSEMENT_WALLET;

    const filterCsvData = () => {
      const newCsvData = [];
      for (let node of csvData) {
        const items = {};

        items.ID = node.id;
        items.Amount = node.amount;
        items.Amount_Disbursed = node.portfolio.amountDisbursed;
        items.type = node.type.name;
        items.status = node.status;
        items.Portfolio_ID = node.portfolio.id;
        items.Loan_ID = node.portfolio.portfolioNumber;
        items.Recipient_Name = `${node.portfolio.user.firstName} ${node.portfolio.user.lastName}`;
        items.Customer_Email = node.portfolio.user.email;
        items.Recipient_Account_Number = node.accountDetails.accountNumber;
        items.Recipient_Bank = node.accountDetails.bankName;
        items.Date_Disbursed = `"${moment(node.createdAt).format('llll')}"`;

        newCsvData.push(items);
      }
      csvData = newCsvData;
    };
    filterCsvData();

    exportToCsv(csvData, csvName);
  };

  const handleDisbursementWalletStartDate = e => {
    liquidityManagementInput.searchParams.dWCreatedAtLt.setValue(e);
  };

  const handleDisbursementWalletEndDate = e => {
    liquidityManagementInput.searchParams.dWCreatedAtGt.setValue(e);
  };

  return {
    data,
    loading,
    input: liquidityManagementInput,
    handleHideToast: e => handleHideToast(e),
    searchQuery: e => handleRefetch(e, refetch),
    handleDisbursementWalletReset: e =>
      handleDisbursementWalletReset(e, refetch),
    handleDisbursementWalletStartDate,
    handleDisbursementWalletEndDate,
    handleExportToCsv: e => handleExportToCsv(e, data),
    setFilterParams: setSearchParams,
    error,
  };
};

export default LiquidityManagement;
