import { gql } from 'apollo-boost';

export const UPDATE_STANDARD_WALLET_BALANCE_MUTATION = gql`
  mutation MP_UpdateStandardWalletBalanceMutation {
    updateStandardWalletBalance {
      id
      amount
    }
  }
`;
