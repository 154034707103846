import React, { Fragment } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Close, Modal, NewRadio, Toast } from '../../../../components';

const ChangeRepaymentDate = ({
  portfolioData,
  handleModalToggle,
  updateRepaymentData,
  handleUpdateRepaymentDueDate,
  startDate,
  setStartDate,
  repayDate,
  handleRepayDate,
  UpdateRepaymentToast,
}) => {
  return (
    <Fragment>
      <Toast
        title={`Change Repayment Date`}
        content={updateRepaymentData.content.value}
        classes={updateRepaymentData.status.value}
        active={updateRepaymentData.showToast.value}
        clickEvent={UpdateRepaymentToast}
      />
      <Modal
        title={'Change Repayment Date'}
        active={updateRepaymentData.showModal.value}
        classes="repayment-date-modal"
      >
        <Close className="close-icon" onClick={handleModalToggle} />
        <div className="repayment-date">
          {portfolioData &&
          portfolioData.portfolio &&
          portfolioData.portfolio.application &&
          portfolioData.portfolio.application.credit &&
          portfolioData.portfolio.application.credit.salaryDay === 0 ? (
            ''
          ) : (
            <NewRadio
              name="repay-date"
              label="Set as salary date"
              checked={repayDate.selectedDate.value === 'no'}
              onChange={() => handleRepayDate({ value: 'no' })}
            />
          )}

          <NewRadio
            name="repay-date"
            label="Choose Repayment Date"
            checked={repayDate.selectedDate.value === 'yes'}
            onChange={() => handleRepayDate({ value: 'yes' })}
          />
          <div className="repayment-datepicker" id={'yeye'}>
            <DatePicker
              disabled={
                !repayDate.selectedDate.value ||
                repayDate.selectedDate.value === 'no'
              }
              selected={startDate}
              onChange={date => setStartDate(date)}
              minDate={new Date()}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <Button
            classes="secondary center repayment-date-btn"
            click_event={handleUpdateRepaymentDueDate}
          >
            Change Repayment Date
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ChangeRepaymentDate;
