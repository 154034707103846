import { useQuery } from '@apollo/client';
import {
  GetPoliciesQueryVariables,
  GetPoliciesQuery,
} from '@/__generated/graphql';
import { GET_LOAN_PRODUCTS } from '@/graphql/queries/loan-products';

export type LoanProductsArrayType =
  GetPoliciesQuery['viewer']['account']['policies']['nodes'];

export const useGetLoanProducts = ({
  name,
  status,
  loanType,
  durationType,
  repaymentType,
  minLoanAmount,
  maxLoanAmount,
  minLoanDuration,
  maxLoanDuration,
}: GetPoliciesQueryVariables = {}) => {
  const { data, loading, error, refetch, fetchMore, networkStatus } = useQuery(
    GET_LOAN_PRODUCTS,
    {
      variables: {
        name,
        status,
        loanType,
        durationType,
        repaymentType,
        minLoanAmount,
        maxLoanAmount,
        minLoanDuration,
        maxLoanDuration,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const loadingInitialProducts = networkStatus === 1; // networkStatus 1 indicates initial loading
  const isRefetchingLoanProducts = networkStatus === 4; // networkStatus 4 indicates refetching
  const fetchingMoreLoanProducts = networkStatus === 3; // networkStatus 3 indicates fetching more

  const fetchMoreLoanProducts = () => {
    fetchMore({
      variables: {
        after: data?.viewer?.account?.policies.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const mergedData = {
          ...fetchMoreResult,
          viewer: {
            ...fetchMoreResult.viewer,
            account: {
              ...fetchMoreResult.viewer.account,
              policies: {
                ...fetchMoreResult.viewer.account.policies,
                nodes: [
                  ...prev.viewer.account.policies.nodes,
                  ...fetchMoreResult.viewer.account.policies.nodes,
                ],
              },
            },
          },
        };

        return mergedData;
      },
    });
  };

  return {
    fetchMoreLoanProducts,
    refetchProducts: refetch,
    loanProductsError: error,
    loanProductsLoading: loading,
    loanProducts: data?.viewer?.account?.policies.nodes,
    loanProductsCount: data?.viewer?.account?.policies.totalCount,
    loanProductsPageInfo: data?.viewer?.account?.policies.pageInfo,
    loadingSubsequent:
      loading &&
      !loadingInitialProducts &&
      !isRefetchingLoanProducts &&
      !fetchingMoreLoanProducts,
    isRefetchingLoanProducts,
    fetchingMoreLoanProducts,
    loadingInitialProducts,
  };
};
