import React, { Fragment } from 'react';
import { Button, DataCard, Export, Table } from '../../../../components';
import { roundDoublePrecisionWithCommas } from '../../../../lib/Utils';

const MyBankStatementTab = ({ data, handleExportToCsv }) => {
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Button classes="right" click_event={handleExportToCsv}>
            <Export />
            <span className="export-csv">Export CSV</span>
          </Button>
          <Table>
            <div className="table__body">
              {data && data.application && data.application.credit && (
                <ul>
                  <li>
                    <span>Name</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.Name}
                    </span>
                  </li>
                  <li>
                    <span>NUBAN</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.Nuban}
                    </span>
                  </li>
                  <li>
                    <span>Currency</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.Currency}
                    </span>
                  </li>
                  <li>
                    <span>Applicant</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.Applicants}
                    </span>
                  </li>
                  <li>
                    <span>Address</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.Address}
                    </span>
                  </li>
                  <li>
                    <span>Account Category</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.AccountCategory}
                    </span>
                  </li>
                  <li>
                    <span>Account Type</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.AccountType}
                    </span>
                  </li>
                  <li>
                    <span>Available Balance</span>
                    <span className="right-text">
                      {roundDoublePrecisionWithCommas(
                        parseFloat(
                          data.application.credit.bankStatement.AvailableBal,
                        ),
                      )}
                    </span>
                  </li>
                  <li>
                    <span>Currency</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.Currency}
                    </span>
                  </li>
                  <li>
                    <span>Tenor</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.Tenor}
                    </span>
                  </li>
                  <li>
                    <span>Ticket No</span>
                    <span className="right-text">
                      {data.application.credit.bankStatement.TicketNo}
                    </span>
                  </li>
                  <li>
                    <span>Total Credit</span>
                    <span className="right-text">
                      {roundDoublePrecisionWithCommas(
                        parseFloat(
                          data.application.credit.bankStatement.TotalCredit,
                        ),
                      )}
                    </span>
                  </li>
                  <li>
                    <span>Total Debit</span>
                    <span className="right-text">
                      {roundDoublePrecisionWithCommas(
                        parseFloat(
                          data.application.credit.bankStatement.TotalDebit,
                        ),
                      )}
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default MyBankStatementTab;
