import React, { useState } from 'react';
import './_DropDown.scss';

const DropDown = ({ dropdownTrigger, dropdownContent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(prevState => !prevState);

  return (
    <>
      {isOpen && (
        <div
          onClick={toggleMenu}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 9,
            background: 'transparent',
          }}
        />
      )}

      <div
        className="dd-wrapper"
        style={{
          position: 'relative',
          zIndex: 9,
        }}
      >
        <div onClick={toggleMenu}>{dropdownTrigger}</div>

        {isOpen ? (
          <aside className="dropdown" onClick={toggleMenu}>
            {dropdownContent}
          </aside>
        ) : null}
      </div>
    </>
  );
};

export default DropDown;
