import * as React from 'react';

function ToggleVisibilityIcon(props) {
  return (
    <svg
      width={41}
      height={34}
      viewBox="0 0 41 34"
      fill={props.color || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 0h39a2 2 0 012 2v30a2 2 0 01-2 2H0V0z" fill="#F2F4F8" />
      <path
        d="M21 13.063c-2.351-.04-4.783 1.604-6.312 3.287a.97.97 0 000 1.298c1.495 1.647 3.92 3.329 6.312 3.288 2.392.04 4.817-1.64 6.314-3.288a.97.97 0 000-1.298c-1.53-1.683-3.963-3.327-6.314-3.287z"
        stroke="#A5ADB6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.188 17a2.188 2.188 0 11-4.376-.001 2.188 2.188 0 014.375.001z"
        stroke="#A5ADB6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ToggleVisibilityIcon;
