import React, { Fragment } from 'react';
import { Alert, Button, Table } from '../../../../components';
import { Link } from 'react-router-dom';
import {
  capitalizeInitialLetter,
  formatLoanDuration,
  getLoanDurationName,
  nairaAmount,
  removeUnderscore,
  sumColumn,
} from '../../../../lib/Utils';
import { OriginateClient, UrlFromS3Provider } from '../../../../Providers';
import moment from 'moment';

const APPLICATION_IN_PROGRESS = ['PENDING', 'AWAITING_FEEDBACK'];

const ApplicationInformationTab = ({
  data,
  repaymentBreakdown,
  offerLetter,
  offerLetterDocument,
  missingApplicationStepMessage,
  handleModalApplyMoratoriumToggle,
  handleModalModifyLoanDurationToggle,
  handleProcessingFeeModalToggle,
  handleApplicationLoanAmountModalToggle,
  handleApplicationInterestRateModalToggle,
  handleAssignAndUnassignApplicationToggle,
}) => {
  return (
    <Fragment>
      <div className="no-policy-alert">
        {!data.application.policy &&
        APPLICATION_IN_PROGRESS.includes(data.application.status.name) ? (
          <Alert error message="No Loan Product Attached to this Application" />
        ) : (
          APPLICATION_IN_PROGRESS.includes(data.application.status.name) &&
          (!data.application.completedSteps.every(s =>
            data.application.requiredSteps.includes(s),
          ) ||
            !data.application.requiredSteps.every(s =>
              data.application.completedSteps.includes(s),
            )) && <Alert warning message={missingApplicationStepMessage} />
        )}
      </div>
      <div className="information--content">
        {data && data.application && (
          <Table>
            {data.application &&
              data.application.portfolio &&
              data.application.portfolio.portfolioNumber && (
                <Button classes="link right">
                  <Link
                    to={`/loans/${data.application.portfolio.portfolioNumber}/${data.application.portfolio.user.email}`}
                  >
                    View Linked Portfolio
                  </Link>
                </Button>
              )}
            <div className="table__body">
              <ul>
                <li>
                  <span>Application ID</span>
                  <span className="right-text">
                    {data.application.applicationNumber}
                  </span>
                </li>
                <li>
                  <span className="processingfee-edit-container">
                    Amount Requested
                    <Fragment>
                      {data.application &&
                      !(
                        data.application.status.name === 'APPROVED' ||
                        data.application.status.name === 'DENIED' ||
                        data.application.status.name === 'ABANDONED' ||
                        data.application.status.name === 'OFFER_LETTER_SENT'
                      ) ? (
                        <span className="edit-button-container">
                          <div className="moratorium-period">
                            <Button
                              classes="edit-processing-fee"
                              click_event={
                                handleApplicationLoanAmountModalToggle
                              }
                            >
                              {' '}
                              ( Edit )
                            </Button>
                          </div>
                        </span>
                      ) : (
                        ''
                      )}
                    </Fragment>
                  </span>
                  <span className="right-text">
                    {nairaAmount(data.application.amount)}
                  </span>
                </li>

                {repaymentBreakdown ? (
                  <Fragment>
                    <li>
                      <span className="processingfee-edit-container">
                        Interest
                        <Fragment>
                          {data.application &&
                          !(
                            data.application.status.name === 'APPROVED' ||
                            data.application.status.name === 'DENIED' ||
                            data.application.status.name === 'ABANDONED' ||
                            data.application.status.name === 'OFFER_LETTER_SENT'
                          ) ? (
                            <span className="edit-button-container">
                              <div className="moratorium-period">
                                <Button
                                  classes="edit-processing-fee"
                                  click_event={
                                    handleApplicationInterestRateModalToggle
                                  }
                                >
                                  {' '}
                                  ( Edit )
                                </Button>
                              </div>
                            </span>
                          ) : (
                            ''
                          )}
                        </Fragment>
                      </span>
                      <span className="right-text">
                        {nairaAmount(
                          repaymentBreakdown &&
                            sumColumn(repaymentBreakdown, 'interestPortion'),
                        )}{' '}
                        <OriginateClient
                          render={({
                            clientId: {
                              clientInfo: { loanAndApplicationServiceEnabled },
                            },
                          }) => (
                            <Fragment>
                              {loanAndApplicationServiceEnabled ? (
                                <Fragment>
                                  {data &&
                                  data.application.policyDetails &&
                                  data.application.policyDetails
                                    .interestRateValue
                                    ? `(${data.application.policyDetails.interestRateValue}%)`
                                    : ''}
                                </Fragment>
                              ) : (
                                ''
                              )}
                            </Fragment>
                          )}
                        />
                      </span>
                    </li>

                    <li>
                      <span>Total Amount</span>
                      <span className="right-text">
                        {nairaAmount(
                          repaymentBreakdown &&
                            sumColumn(repaymentBreakdown, 'expectedPayment'),
                        )}
                      </span>
                    </li>
                  </Fragment>
                ) : (
                  <>
                    <li>
                      <span>Interest Amount</span>
                      <span className="right-text">
                        {nairaAmount(data.application.chargesAmount)}
                      </span>
                    </li>

                    <li>
                      <span>Total Amount</span>
                      <span className="right-text">
                        {nairaAmount(data.application.fullAmount)}
                      </span>
                    </li>
                  </>
                )}

                <li>
                  <span className="processingfee-edit-container">
                    Processing Fee
                    <Fragment>
                      {data.application &&
                      !(
                        data.application.status.name === 'APPROVED' ||
                        data.application.status.name === 'DENIED' ||
                        data.application.status.name === 'ABANDONED' ||
                        data.application.status.name === 'OFFER_LETTER_SENT'
                      ) ? (
                        <span className="edit-button-container">
                          <div className="moratorium-period">
                            <Button
                              classes="edit-processing-fee"
                              click_event={handleProcessingFeeModalToggle}
                            >
                              {' '}
                              ( Edit )
                            </Button>
                          </div>
                        </span>
                      ) : (
                        ''
                      )}
                    </Fragment>
                  </span>
                  <span className="right-text">
                    {data.application.processingFee > 0
                      ? nairaAmount(data.application.processingFee)
                      : 'N/A'}
                  </span>
                </li>

                <li>
                  <span>Status</span>
                  <span className="right-text">
                    {removeUnderscore(
                      capitalizeInitialLetter(data.application.status.name),
                    )}
                  </span>
                </li>

                <li>
                  <span className="processingfee-edit-container">
                    Duration
                    <Fragment>
                      {data.application &&
                      !(
                        data.application.status.name === 'APPROVED' ||
                        data.application.status.name === 'DENIED' ||
                        data.application.status.name === 'ABANDONED' ||
                        data.application.status.name === 'OFFER_LETTER_SENT'
                      ) ? (
                        <span className="edit-button-container">
                          <div className="moratorium-period">
                            <Button
                              classes="edit-processing-fee"
                              click_event={handleModalModifyLoanDurationToggle}
                            >
                              {' '}
                              ( Edit )
                            </Button>
                          </div>
                        </span>
                      ) : (
                        ''
                      )}
                    </Fragment>
                  </span>
                  <span className="right-text">
                    {data.application.policy != null
                      ? `${formatLoanDuration(
                          data.application.loanDuration,
                          data.application.policy,
                        )} ${getLoanDurationName(
                          data.application.policy.durationType,
                        )}`
                      : 'N/A'}
                  </span>
                </li>

                <li>
                  <span className="processingfee-edit-container">
                    Assigned Loan Officer
                    <Fragment>
                      <span className="edit-button-container">
                        <div className="moratorium-period">
                          <Button
                            classes="edit-processing-fee"
                            click_event={
                              handleAssignAndUnassignApplicationToggle
                            }
                          >
                            {' '}
                            ( Edit )
                          </Button>
                        </div>
                      </span>
                    </Fragment>
                  </span>
                  <span className="right-text">
                    {data.application.assignedTo
                      ? `${data.application.assignedTo.firstName} ${data.application.assignedTo.lastName}`
                      : 'N/A'}
                  </span>
                </li>

                <li>
                  <span>Application Date</span>
                  <span className="right-text">
                    {data.application.createdAt
                      ? moment(data.application.createdAt).format('lll')
                      : 'N/A'}
                  </span>
                </li>

                <li>
                  <span>Submission Date</span>
                  <span className="right-text">
                    {data.application.submittedAt
                      ? moment(data.application.submittedAt).format('lll')
                      : 'N/A'}
                  </span>
                </li>

                <li>
                  <span>Source</span>
                  <span className="right-text">
                    {data.application.source || 'N/A'}
                  </span>
                </li>

                {offerLetter ? (
                  <Fragment>
                    <li className="flex-display">
                      <span>Offer Letter Document</span>
                      <UrlFromS3Provider file={offerLetterDocument.file}>
                        {({ exportFromS3, loading }) => (
                          <span className="right-text">
                            <Button
                              classes="link custom"
                              click_event={exportFromS3}
                              disabled={loading}
                            >
                              View Offer Letter
                            </Button>
                          </span>
                        )}
                      </UrlFromS3Provider>
                    </li>

                    <li>
                      <span>Offer Letter Status</span>
                      <span className="right-text">
                        <aside
                          className={`offer_status offer_status_${offerLetter.status.toLowerCase()}`}
                        >
                          {offerLetter.status.toLowerCase()}
                        </aside>
                      </span>
                    </li>
                  </Fragment>
                ) : (
                  ''
                )}

                <li>
                  {data.application &&
                    !(
                      data.application.status.name === 'APPROVED' ||
                      data.application.status.name === 'DENIED' ||
                      data.application.status.name === 'ABANDONED' ||
                      data.application.status.name === 'OFFER_LETTER_SENT'
                    ) && (
                      <Fragment>
                        <span className="processingfee-edit-container">
                          Moratorium Period
                          <span className="edit-button-container">
                            <div className="moratorium-period">
                              <Button
                                classes="edit-processing-fee"
                                click_event={handleModalApplyMoratoriumToggle}
                              >
                                {' '}
                                ( Edit )
                              </Button>
                            </div>
                          </span>
                        </span>
                        <span className="right-text">
                          {data.application.moratoriumData != null ? (
                            <Fragment>
                              {data.application.moratoriumData.numberOfPeriods}{' '}
                              {parseInt(
                                data.application.moratoriumData.numberOfPeriods,
                              ) === 1
                                ? getLoanDurationName(
                                    data.application.moratoriumData
                                      .repaymentType,
                                  )
                                    .toLowerCase()
                                    .slice(0, -1)
                                : getLoanDurationName(
                                    data.application.moratoriumData
                                      .repaymentType,
                                  ).toLowerCase()}
                            </Fragment>
                          ) : (
                            'N/A'
                          )}
                        </span>{' '}
                      </Fragment>
                    )}
                </li>
              </ul>
            </div>
          </Table>
        )}
      </div>
    </Fragment>
  );
};

export default ApplicationInformationTab;
