import { graphql } from '@/__generated/gql';

export const CUSTOMERS = graphql(/* GraphQL */ `
  query Customers($where: CustomerWhereInput, $after: ConnectionCursor) {
    mp {
      customers(after: $after, where: $where) {
        edges {
          node {
            accountName
            channel {
              type
              user {
                id
                lastName
                email
                firstName
              }
            }
            createdAt
            id
            loanStatus {
              amount
              status
            }
            status
            type
            user {
              email
              firstName
              lastName
              phone
              status
              id
            }
          }
        }
        pageInfo {
          endCursor
          startCursor
        }
        totalCount
      }
    }
  }
`);
