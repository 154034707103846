import { Icon, IconProps } from '@chakra-ui/icon';
import React from 'react';

const ArrowRightIcon = ({ ...props }: IconProps) => {
  return (
    <Icon w={6} h={6} fill="gray.500" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M20 12a1 1 0 01-1 1H5a1 1 0 110-2h14a1 1 0 011 1z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M19.707 12.707a1 1 0 01-1.414 0l-6-6a1 1 0 011.414-1.414l6 6a1 1 0 010 1.414z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12.293 18.707a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414l-6 6a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

export default ArrowRightIcon;
