import React, { Fragment } from 'react';
import { DataCard, Table } from '../../../../components';
import {
  numberWithCommas,
  roundWithDoublePrecision,
  sumColumn,
} from '../../../../lib/Utils';
import moment from 'moment';

const RepaymentBreakdownTab = ({ repaymentBreakdown }) => {
  return (
    <Fragment>
      <DataCard noPadding={false}>
        <Table
          tableHeaders={[
            'Repayment Date',
            'Principal',
            'Interest',
            'Total Payment',
          ]}
        >
          <div className="table__body">
            <ul>
              {repaymentBreakdown &&
                repaymentBreakdown.map((breakDownData, index) => (
                  <li key={index}>
                    <span>{moment(breakDownData.dueDate).format('ll')}</span>
                    <span>
                      ₦{numberWithCommas(breakDownData.principalPortion)}
                    </span>
                    <span>
                      ₦{numberWithCommas(breakDownData.interestPortion)}
                    </span>
                    <span>
                      ₦
                      {numberWithCommas(
                        roundWithDoublePrecision(breakDownData.expectedPayment),
                      )}
                    </span>
                  </li>
                ))}

              <li className="repaymentBreakdown_Foot">
                <span>Total</span>
                <span>
                  ₦
                  {numberWithCommas(
                    roundWithDoublePrecision(
                      repaymentBreakdown &&
                        sumColumn(repaymentBreakdown, 'principalPortion'),
                    ),
                  )}
                </span>
                <span>
                  ₦
                  {numberWithCommas(
                    roundWithDoublePrecision(
                      repaymentBreakdown &&
                        sumColumn(repaymentBreakdown, 'interestPortion'),
                    ),
                  )}
                </span>
                <span>
                  ₦
                  {numberWithCommas(
                    roundWithDoublePrecision(
                      repaymentBreakdown &&
                        sumColumn(repaymentBreakdown, 'expectedPayment'),
                    ),
                    1,
                  )}
                </span>
              </li>
            </ul>
          </div>
        </Table>
      </DataCard>
    </Fragment>
  );
};

export default RepaymentBreakdownTab;
