import { withRouter } from 'react-router-dom';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { useCustomState } from '../lib/CustomHooks';
import { UPDATE_STANDARD_WALLET_BALANCE_MUTATION } from '../graphql/mutations';
import { connection_limit } from '../config/config';
import { TRANSACTIONS_QUERY } from '../graphql/queries';
import Constants from '../lib/Constants';

const UpdateStandardWalletBalance = ({ render }) => {
  const updateStandardWalletBalanceInput = {
    showToast: useCustomState(false),
    content: useCustomState(),
    status: useCustomState(),
    comment: useCustomState(),
    message: useCustomState(),
    showPopup: useCustomState(false),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      updateStandardWalletBalanceInput.showToast.setValue(false);
    }, 2500);
  };

  const [updateStandardWallet, { loading }] = useMutation(
    UPDATE_STANDARD_WALLET_BALANCE_MUTATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        updateStandardWalletBalanceInput.showToast.setValue(true);
        updateStandardWalletBalanceInput.status.setValue('Success');
        updateStandardWalletBalanceInput.content.setValue(
          'Wallet Top Up Successful',
        );
        handleHideToast();
      },

      onError: error => {
        updateStandardWalletBalanceInput.showToast.setValue(true);
        updateStandardWalletBalanceInput.status.setValue('Failed');
        updateStandardWalletBalanceInput.content.setValue(
          trimGraphQLError(error.message),
        );
        handleHideToast();
      },
    },
  );

  const handleUpdateStandardWalletBalance = async e => {
    e.preventDefault();
    hideUpdateStandardWalletBalancePopup(e);
    await updateStandardWallet({
      refetchQueries: () => [
        {
          query: TRANSACTIONS_QUERY,
          variables: {
            first: connection_limit.value,
            disbursementWalletType: Constants.DISBURSEMENT_TRANSACTION_TYPE,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  const showUpdateStandardWalletBalancePopup = e => {
    e.preventDefault();
    updateStandardWalletBalanceInput.showPopup.setValue(true);
  };

  const hideUpdateStandardWalletBalancePopup = e => {
    e.preventDefault();
    updateStandardWalletBalanceInput.showPopup.setValue(false);
  };

  return render({
    handleHideToast,
    handleUpdateStandardWalletBalance: e =>
      handleUpdateStandardWalletBalance(e),
    input: updateStandardWalletBalanceInput,
    standardWalletBalanceLoader: loading,
    showUpdateStandardWalletBalancePopup,
    hideUpdateStandardWalletBalancePopup,
  });
};

export default withRouter(UpdateStandardWalletBalance);
