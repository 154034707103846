import React, { Fragment } from 'react';
import { ApproveManualReviewApplication } from '../../../../Providers';
import {
  Button,
  DataCard,
  Loader,
  Modal,
  TextArea,
  Toast,
} from '../../../../components';
import { Close } from '../../../../components/Icons';

const ApproveManualReviewApplications = ({ data }) => {
  return (
    <Fragment>
      <ApproveManualReviewApplication
        applicationId={data.application.id}
        render={({
          handleHideToast,
          input,
          handleApproveReviewApplication,
          showApproveManualApplicationPopup,
          hideApproveManualApplicationPopup,
          approveApplicationLoader,
        }) => (
          <Fragment>
            {approveApplicationLoader && <Loader loadingText="loading..." />}

            <Modal
              title={'Complete Approval'}
              classes="complete-review-popup"
              active={input.showPopup.value}
            >
              <Close
                className="close-icon"
                onClick={e => hideApproveManualApplicationPopup(e)}
              />
              <DataCard noPadding={true}>
                <span className="optional">(optional)</span>
                <TextArea
                  cols={1}
                  rows={7}
                  label="Add Comment"
                  placeholder="Comments"
                  {...input.comment.input}
                />
                <Button
                  classes="green complete-review"
                  click_event={handleApproveReviewApplication}
                >
                  Approve Loan
                </Button>
              </DataCard>
            </Modal>

            <Toast
              title="Approve Loan"
              content={input.content.value}
              classes={input.status.value}
              active={input.showToast.value}
              clickEvent={handleHideToast}
            />
            <Button
              classes="right green"
              click_event={showApproveManualApplicationPopup}
            >
              Approve Loan
            </Button>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default ApproveManualReviewApplications;
