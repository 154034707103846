import { Query } from '@apollo/react-components';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connection_limit } from '../config/config';
import { OFFLINE_REPAYMENTS_QUERY } from '../graphql/queries';

const ManualRepaymentsTableProvider = ({ render, setFilterParams }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchMore = (e, fetchMore, data) => {
    e.preventDefault();
    setIsLoading(true);
    fetchMore({
      variables: {
        cursor: data.offlineRepayments.pageInfo.endCursor,
        ...setFilterParams,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoading(false);
        const newNodes = fetchMoreResult.offlineRepayments.nodes;
        const pageInfo = fetchMoreResult.offlineRepayments.pageInfo;

        return newNodes.length
          ? {
              offlineRepayments: {
                __typename: prev.offlineRepayments.__typename,
                nodes: [...prev.offlineRepayments.nodes, ...newNodes],
                pageInfo,
              },
            }
          : prev;
      },
    });
  };

  return (
    <Query
      query={OFFLINE_REPAYMENTS_QUERY}
      variables={{ first: connection_limit.value }}
    >
      {({ data, fetchMore }) => {
        return render({
          manualRepaymentsTableData: data,
          isLoading,
          handlePagination: e => handleFetchMore(e, fetchMore, data),
        });
      }}
    </Query>
  );
};

export default withRouter(ManualRepaymentsTableProvider);
