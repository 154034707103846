import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useStep } from 'react-hooks-helper';
import { useQuery } from '@apollo/react-hooks';

import { useClientInfo } from '../../Providers-new/Client';
import { GET_ENUM } from '../../Providers-new/EnumTypes';
import {
  useGetPolicyRuleTypes,
  useGetLoanProducts,
  useMutateCreateCustomRule,
} from '../../Providers-new/LoanRules';
import { Loader } from '../../components';

import NewRuleForm from './NewRuleForm';

import './_Styles.scss';

const pageSteps = [{ id: 'step-one' }];

const defaultStepType = '-- Select One --';

const CreateNewRule = ({
  loanProducts,
  nigeriaStates,
  ruleTypes,
  ruleId,
  loanRuleList,
}) => {
  const { step } = useStep({ initialStep: 0, steps: pageSteps });
  const { id } = step;
  const appRuleTypes = ruleTypes;

  const [rmvApproveRules, setRmvApproveRules] = useState([]);
  const [rmvUnderReviewRules, setrmvUnderReviewRules] = useState([]);

  const {
    form,
    data,
    handleCreateCustomRule,
    approvedEmploymentStatuses,
    underReviewEmploymentStatuses,
    approvedBlacklistStatuses,
    underReviewBlacklistStatuses,
    approvedCreditRegistryDelinquency,
    underReviewCreditRegistryDelinquency,
    approvedFirstCentralDelinquency,
    underReviewFirstCentralDelinquency,
    approvedCrcDelinquency,
    underReviewCrcDelinquency,
    approvedBvnNinMatch,
    underReviewBvnNinMatch,
    handleUpdateCustomRule,
    loanRuleInView,
    loading: createCustomRuleLoading,
    updateLoading: updateCustomRuleLoading,
  } = useMutateCreateCustomRule({
    appRuleTypes,
    ruleId,
    loanRuleList,
    rmvApproveRules,
    rmvUnderReviewRules,
  });
  const isEditMode = data.isEditMode.value;

  let blankStep = isEditMode ? [] : [{ stepType: defaultStepType }];
  let unrBlankStep = isEditMode ? [] : [{ stepType: defaultStepType }];

  const [stepState, setStepState] = useState(blankStep);
  const [unrStepState, setUnrStepState] = useState(unrBlankStep);

  useEffect(() => {
    if (loanRuleInView) {
      const { approve, manualReview } = loanRuleInView;

      if (approve.length > 0) {
        approve.forEach(item => {
          const { name, label, status } = item.type;
          if (status === 'ACTIVE')
            blankStep.push({ stepType: name, stepLabel: label });
        });
        setStepState([...blankStep]);
      }

      if (manualReview.length > 0) {
        manualReview.forEach(item => {
          const { name, label, status } = item.type;
          if (status === 'ACTIVE')
            unrBlankStep.push({ stepType: name, stepLabel: label });
        });
        setUnrStepState([...unrBlankStep]);
      }
    }
  }, [loanRuleInView]);

  // start - handles approved steps functionality

  const addNewStep = () => {
    setStepState([...stepState, { stepType: defaultStepType }]);
  };
  const removeStep = (id, item) => {
    const newStepState = stepState.filter((_, idx) => idx !== id);
    setStepState([...newStepState]);

    if (isEditMode) {
      const removedSteps = stepState.filter(
        _ => _.stepType === item.stepType && _.stepType !== defaultStepType,
      );
      if (removedSteps.length === 1)
        setRmvApproveRules([...rmvApproveRules, removedSteps[0].stepType]);
    }
  };
  const handleSelectChange = e => {
    const selectedOption = e.target.value;
    const updatedSteps = [...stepState];
    updatedSteps[e.target.dataset.idx][e.target.className] = selectedOption;
    setStepState(updatedSteps);

    if (isEditMode) {
      const newRmvRules = rmvApproveRules.filter(
        stepType => stepType !== selectedOption,
      );
      setRmvApproveRules(newRmvRules);
    }
  };
  // ends

  // start - handles under review steps functionality
  const addNewUnrStep = () => {
    setUnrStepState([...unrStepState, { stepType: defaultStepType }]);
  };
  const removeUnrStep = (id, item) => {
    const newUnrStepState = unrStepState.filter((_, idx) => idx !== id);
    setUnrStepState([...newUnrStepState]);

    if (isEditMode) {
      const removedSteps = unrStepState.filter(
        _ => _.stepType === item.stepType && _.stepType !== defaultStepType,
      );
      if (removedSteps.length === 1)
        setrmvUnderReviewRules([
          ...rmvUnderReviewRules,
          removedSteps[0].stepType,
        ]);
    }
  };
  const handleSelectChangeUnr = e => {
    const selectedOption = e.target.value;
    const updatedSteps = [...unrStepState];
    updatedSteps[e.target.dataset.idx][e.target.className] = selectedOption;
    setUnrStepState(updatedSteps);

    if (isEditMode) {
      const newRmvRules = rmvUnderReviewRules.filter(
        stepType => stepType !== selectedOption,
      );
      setrmvUnderReviewRules(newRmvRules);
    }
  };
  // ends

  const underReviewProps = {
    unrStepState,
    setUnrStepState,
    addNewUnrStep,
    removeUnrStep,
    handleSelectChangeUnr,
    underReviewEmploymentStatuses,
    underReviewFirstCentralDelinquency,
    underReviewCrcDelinquency,
    underReviewBlacklistStatuses,
    underReviewCreditRegistryDelinquency,
    underReviewBvnNinMatch,
  };

  const approvedProps = {
    stepState,
    setStepState,
    addNewStep,
    removeStep,
    handleSelectChange,
    approvedEmploymentStatuses,
    approvedFirstCentralDelinquency,
    approvedCrcDelinquency,
    approvedBlacklistStatuses,
    approvedCreditRegistryDelinquency,
    approvedBvnNinMatch,
  };

  const other_props = { ...underReviewProps, ...approvedProps };

  const props = {
    form,
    data,
    loanProducts,
    nigeriaStates,
    appRuleTypes,
    loanRuleInView,
    handleCreateCustomRule,
    handleUpdateCustomRule,
    createCustomRuleLoading,
    updateCustomRuleLoading,
    ...other_props,
  };

  switch (id) {
    case 'step-one':
      return <NewRuleForm {...props} />;
    default:
      return <div>Empty</div>;
  }
};

const CreateNewRuleWrapper = (render = {}) => {
  const loanRuleName = render.match.params.loanRuleName
    ? render.match.params.loanRuleName
    : null;
  const { loading: loanRuleListLoading, result: loanRuleList } = useClientInfo({
    privateFields: true,
  });
  const { loading: ruleTypesLoading, result: ruleTypesResult } =
    useGetPolicyRuleTypes();
  const { loading: loanProductsLoading, result: loanProductsResult } =
    useGetLoanProducts();

  const { loading: statesLoading, data: nigeriaStates } = useQuery(GET_ENUM, {
    variables: {
      type: 'NigerianStates',
    },
  });

  if (
    ruleTypesLoading ||
    loanProductsLoading ||
    statesLoading ||
    loanRuleListLoading
  )
    return <Loader />;

  let loanProducts =
    loanProductsResult.data && loanProductsResult.data.viewer
      ? loanProductsResult.data.viewer.account
      : [];
  let ruleTypes = ruleTypesResult.data
    ? ruleTypesResult.data.policyRuleTypes
    : [];

  let States = [];
  nigeriaStates.enumValues.map(item =>
    States.push({ value: item.name, label: item.name }),
  );
  let ruleSets = loanRuleList.data ? loanRuleList.data.clientInfo.ruleSets : [];

  return (
    <CreateNewRule
      loanProducts={loanProducts.policies ? loanProducts.policies.nodes : []}
      ruleTypes={ruleTypes}
      nigeriaStates={States}
      ruleId={loanRuleName}
      loanRuleList={ruleSets.nodes}
    />
  );
};

export default withRouter(CreateNewRuleWrapper);
