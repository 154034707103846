import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { trimGraphQLError } from '../../lib/Utils';
import { toast } from 'react-toastify';
import Constants from '../../lib/Constants';
import { ASSIGN_APPLICATION_MUTATION } from '../../graphql/mutations';
import { connection_limit } from '../../config/config';
import { APPLICATION_QUERY } from '../../graphql/queries';
import { UNASSIGN_APPLICATION_MUTATION } from '../../graphql/mutations/unassignApplication';

const useMutateAssignAndUnassignApplication = ({ applicationNumber }) => {
  const [
    openAssignAndUnassignApplicationModal,
    setOpenAssignAndUnassignApplicationModal,
  ] = useState(false);

  const handleAssignAndUnassignApplicationToggle = useCallback(() => {
    setOpenAssignAndUnassignApplicationModal(prev => !prev);
  }, [setOpenAssignAndUnassignApplicationModal]);

  const [error, setError] = useState();

  const [
    mutateAssignApplication,
    { data: assignApplicationData, loading: assignApplicationIsLoading },
  ] = useMutation(ASSIGN_APPLICATION_MUTATION, {
    refetchQueries: () => [
      {
        query: APPLICATION_QUERY,
        variables: { applicationNumber, first: connection_limit.value },
      },
    ],
    onError: err => {
      setError(err);
    },
  });

  const [
    mutateUnassignApplication,
    { data: unassignApplicationData, loading: unAssignApplicationIsLoading },
  ] = useMutation(UNASSIGN_APPLICATION_MUTATION, {
    refetchQueries: () => [
      {
        query: APPLICATION_QUERY,
        variables: { applicationNumber, first: connection_limit.value },
      },
    ],
    onError: err => {
      setError(err);
    },
  });

  const assignApplication = ({ applicationId, assignTo }) => {
    mutateAssignApplication({
      variables: {
        applicationId,
        assignTo,
      },
    });
  };

  const unassignApplication = ({ applicationId }) => {
    mutateUnassignApplication({
      variables: {
        applicationId,
      },
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(
        trimGraphQLError(error?.message) ||
          Constants.errorMessages.NETWORK_ERROR,
      );
    }

    return () => toast.dismiss();
  }, [error]);

  useEffect(() => {
    if (assignApplicationData?.assignApplicationToMerchantUser?.success) {
      toast.success(
        assignApplicationData?.assignApplicationToMerchantUser?.message ||
          'Application assigned successfully',
      );
      handleAssignAndUnassignApplicationToggle();
    }

    return () => toast.dismiss();
  }, [assignApplicationData]);

  useEffect(() => {
    if (unassignApplicationData?.unassignApplication?.success) {
      toast.success(
        unassignApplicationData?.unassignApplication?.message ||
          'Application unassigned successfully',
      );
      handleAssignAndUnassignApplicationToggle();
    }

    return () => toast.dismiss();
  }, [unassignApplicationData]);

  return {
    assignApplicationData,
    error,
    assignApplicationIsLoading,
    unAssignApplicationIsLoading,
    assignApplication,
    unassignApplication,
    openAssignAndUnassignApplicationModal,
    handleAssignAndUnassignApplicationToggle,
  };
};

export { useMutateAssignAndUnassignApplication };
