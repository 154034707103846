import { GraduatedLoanCycle } from '@/__generated/graphql';
import parse from 'date-fns/parse';

export const formatNumber = (number: number) => {
  return Intl.NumberFormat().format(number);
};

export const formatAmount = (number: number) => {
  return Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(number);
};

export const formatDate = (dateString: string, hideTime = false) => {
  if (!dateString) return;

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return dateString;
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: !hideTime ? '2-digit' : undefined,
    minute: !hideTime ? '2-digit' : undefined,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const removeCommas = (string: string): string => {
  const hasComma = /,/;

  return hasComma.test(string) ? string.replace(/,/g, '') : string;
};

export const parseNumericValue = (value: string | number): number => {
  if (!value) return value as number;

  return parseFloat(removeCommas(value.toString()));
};

type LoanCycle = {
  amount: GraduatedLoanCycle['amount'];
};

export const getGraduatedMinMaxAmount = (data: LoanCycle[]) => {
  const amounts: number[] = data.map(item => item.amount);
  const minAmount: number = Math.min(...amounts);
  const maxAmount: number = Math.max(...amounts);
  return `${formatAmount(minAmount)} - ${formatAmount(maxAmount)}`;
};

export const convertRatioToPercent = (ratio: number): string => {
  if (typeof ratio === 'number') {
    return `${ratio * 100}%`;
  } else {
    return `${ratio}`;
  }
};

export const parseIsoDate = (
  dateString: string,
  timeOfDay: 'late' | 'early' = 'early',
) => {
  if (!dateString) return;

  const parsedDate = parse(dateString, 'dd-MM-yyyy', new Date());

  const hours = timeOfDay === 'late' ? 23 : 0;
  const minutes = timeOfDay === 'late' ? 59 : 0;
  const seconds = timeOfDay === 'late' ? 59 : 0;

  parsedDate.setHours(hours, minutes, seconds, 0);

  return parsedDate.toISOString();
};

export const formatFileSize = (bytes: number): string => {
  const sizes: string[] = ['bytes', 'kb', 'mb', 'gb', 'tb'];

  if (bytes === 0) return 'n/a';

  const i: number = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]}`;

  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};
