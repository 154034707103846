import PropTypes from 'prop-types';
import React from 'react';
import './_Button.scss';

const Button = ({
  name,
  buttonId,
  value,
  classes,
  disabled,
  click_event,
  type,
  children,
  ...restProps
}) => {
  return (
    <button
      name={name}
      id={buttonId}
      value={value}
      className={`button ${classes == null ? '' : classes} ${
        disabled === true ? 'loading' : ''
      }`}
      disabled={disabled}
      onClick={click_event}
      type={type ? type : 'button'}
      {...restProps}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.string,
  buttonId: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  click_event: PropTypes.func,
};

export default Button;
