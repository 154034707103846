import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../_Reports.scss';
import { getUserFullDetails, hasPermissions } from '../../../Config';
import {
  VIEW_ACCOUNTS_REPORT,
  VIEW_APPLICATIONS_REPORT,
  VIEW_LOANS_REPORT,
  VIEW_REPAYMENTS_REPORT,
} from '../../../Config/constants';

const ReportsNavbar = () => {
  const userDetails = getUserFullDetails();
  const userPermissions = userDetails.permissions || [];

  return (
    <>
      {hasPermissions(VIEW_APPLICATIONS_REPORT, userPermissions) ||
      hasPermissions(VIEW_LOANS_REPORT, userPermissions) ||
      hasPermissions(VIEW_REPAYMENTS_REPORT, userPermissions) ||
      hasPermissions(VIEW_ACCOUNTS_REPORT, userPermissions) ? (
        <div className="reports-navbar">
          {hasPermissions(VIEW_APPLICATIONS_REPORT, userPermissions) && (
            <NavLink to="/reports" exact data-testid="application-menu">
              Applications
            </NavLink>
          )}
          {hasPermissions(VIEW_LOANS_REPORT, userPermissions) && (
            <NavLink to="/reports/loans" exact data-testid="loans-menu">
              Loans
            </NavLink>
          )}
          {hasPermissions(VIEW_REPAYMENTS_REPORT, userPermissions) && (
            <NavLink
              to="/reports/repayments"
              exact
              data-testid="repayments-menu"
            >
              Repayments
            </NavLink>
          )}
          {hasPermissions(VIEW_ACCOUNTS_REPORT, userPermissions) && (
            <NavLink to="/reports/accounts" exact data-testid="accounts-menu">
              Accounts
            </NavLink>
          )}
        </div>
      ) : null}
    </>
  );
};
export default ReportsNavbar;
