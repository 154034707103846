import { GET_DASHBOARD_REPORT_URL } from '@/graphql/queries/dashboard';
import { useQuery } from '@apollo/client';

export const useDashboardReportUrl = () => {
  const { data, loading, error } = useQuery(GET_DASHBOARD_REPORT_URL);

  return {
    dashboardReportUrlError: error,
    dashboardReportUrlLoading: loading,
    dashboardReportUrl: data?.getDashboardReportUrl,
  };
};
