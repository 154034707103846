import { gql } from 'apollo-boost';

export const BLACKLIST_CUSTOMER_MUTATION = gql`
  mutation MP_BlacklistCustomerMutation(
    $userId: ID!
    $reason: String!
    $expiryDate: DateTime
  ) {
    blacklistCustomer(
      input: { userId: $userId, reason: $reason, expiryDate: $expiryDate }
    ) {
      id
      reason
    }
  }
`;
