import React, { Fragment, useState } from 'react';
import './_Toast.scss';
import PropTypes from 'prop-types';
import CloseIcon from '../Icons/CloseIcon';

const Toast = ({ classes, title, active, content }) => {
  const [isToastOpen, setIsToastOpen] = useState(true);

  const handleHideToast = () => setIsToastOpen(false);

  return (
    <Fragment>
      {isToastOpen && (
        <aside
          className={`toast ${classes ? classes : ''} ${
            active ? 'active' : ''
          }`}
        >
          <div className="toast__title">
            <h6>{title}</h6>
            <CloseIcon onClick={handleHideToast} />
          </div>
          <p>{content}</p>
        </aside>
      )}
    </Fragment>
  );
};

Toast.propTypes = {
  classes: PropTypes.string,
  active: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  clickEvent: PropTypes.func,
};

export default Toast;
