import { POLICY_DELETE_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { CREDIT_POLICIES_QUERY } from '../graphql/queries';

const PolicyDeleteProvider = () => {
  const policyDeleteInput = {
    policyId: useCustomState(),
    content: useCustomState(),
    status: useCustomState(),
    showToast: useCustomState(false),
    isDeletePolicyPopupVisible: useCustomState(false),
    selectedPolicyData: useCustomState({}),
  };

  const toggleDeletePolicyPopup = (e, data = {}) => {
    e.preventDefault();

    policyDeleteInput.isDeletePolicyPopupVisible.setValue(
      !policyDeleteInput.isDeletePolicyPopupVisible.value,
    );
    policyDeleteInput.selectedPolicyData.setValue(data);
  };

  const handlePolicyDelete = async (e, policyData = {}) => {
    e.preventDefault();

    if (!policyData && !policyData.id) {
      policyDeleteInput.showToast.setValue(true);
      policyDeleteInput.content.setValue(
        `Selected Loan Product ${policyData.name || ''} cannot be deleted `,
      );
      handleHideToast(e);
      return;
    }

    await handlePolicyDeleteMutation({
      variables: {
        id: policyData.id,
      },
      refetchQueries: () => [{ query: CREDIT_POLICIES_QUERY }],
    });
  };

  const handleHideToast = () => {
    setTimeout(() => {
      policyDeleteInput.showToast.setValue(false);
    }, 4000);
  };

  const [handlePolicyDeleteMutation, { loading }] = useMutation(
    POLICY_DELETE_MUTATION,
    {
      async onCompleted() {
        policyDeleteInput.status.setValue('Success');
        policyDeleteInput.showToast.setValue(true);
        policyDeleteInput.content.setValue('Loan Product Deleted Successfully');

        handleHideToast();
        policyDeleteInput.isDeletePolicyPopupVisible.setValue(
          !policyDeleteInput.isDeletePolicyPopupVisible.value,
        );
      },

      onError(error) {
        policyDeleteInput.status.setValue('Failed');
        policyDeleteInput.showToast.setValue(true);
        policyDeleteInput.content.setValue(trimGraphQLError(error.message));
        handleHideToast();
      },
    },
  );

  return {
    loading,
    policyDeleteInput: policyDeleteInput,
    handleHideToast: e => handleHideToast(e),
    handlePolicyDelete: (e, policyData) => handlePolicyDelete(e, policyData),
    toggleDeletePolicyPopup: (e, selectedPolicy) =>
      toggleDeletePolicyPopup(e, selectedPolicy),
  };
};

export default PolicyDeleteProvider;
