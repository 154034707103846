/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BorderProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  Skeleton,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import {
  Props as ReactSelectProps,
  Select as ReactSelect,
} from 'chakra-react-select';

export interface MultiSelectProps extends ReactSelectProps {
  label?: ReactNode;
  isLoading?: boolean;
  errorMessage?: string;
  helperText?: ReactNode;
  borderRadius?: BorderProps['borderRadius'];
  onChange: (selectedOption: any) => void;
  value: string;
}

const MultiSelect = forwardRef(
  (
    {
      id,
      name,
      label,
      value,
      options,
      onChange,
      isLoading,
      isDisabled,
      isRequired,
      isReadOnly,
      isInvalid,
      helperText,
      placeholder,
      chakraStyles,
      errorMessage,
      borderRadius,
      placeholderColor,
      ...restProps
    }: MultiSelectProps,
    ref,
  ) => {
    const selectedOption = options?.find(
      (option: any) => option.value === value,
    );

    const handleSelectChange = (selectedOption: any) => {
      const selectedProductId = selectedOption ? selectedOption.value : null;
      onChange(selectedProductId);
    };

    const styles: ReactSelectProps['chakraStyles'] = {
      control: provided => ({
        ...provided,
        cursor: 'pointer',
        borderRadius: borderRadius || 0,
      }),
      dropdownIndicator: provided => ({
        ...provided,
        px: 2,
        bg: 'transparent',
        cursor: 'pointer',
        color: placeholderColor,
      }),
      indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
      }),
      placeholder: provided => ({
        ...provided,
        color: placeholderColor,
      }),
      ...chakraStyles,
    };

    return (
      <FormControl
        id={id || name}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        isInvalid={isInvalid || !!errorMessage}
      >
        {label && <FormLabel>{label}</FormLabel>}

        <Skeleton isLoaded={!isLoading}>
          <ReactSelect
            ref={ref}
            menuPlacement="auto"
            chakraStyles={styles}
            value={selectedOption}
            placeholder={placeholder}
            onChange={handleSelectChange}
            menuPortalTarget={document.body}
            isInvalid={isInvalid || !!errorMessage}
            options={[
              ...(options
                ? [{ value: '', label: placeholder || 'Select' }, ...options]
                : []),
            ]}
            styles={{
              menuPortal: provided => ({ ...provided, zIndex: 99999 }),
            }}
            {...restProps}
          />
        </Skeleton>

        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);

export default MultiSelect;
