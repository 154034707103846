import React from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Button } from '../../../../../../components';
import {
  capitalizeInitialLetter,
  normalizeDateTime,
} from '../../../../../../lib/Utils';

export const EditRepaymentFormRow = ({
  control,
  remove,
  index,
  disabled,
  status,
}) => {
  return (
    <section className="edit-repayment-row">
      <section>
        <Controller
          control={control}
          name={`repayments.${index}.principalPortion`}
          render={({ field }) => (
            <EditRepaymentNumberInput
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              disabled={disabled}
              required
            />
          )}
        />
      </section>

      <section>
        <Controller
          control={control}
          name={`repayments.${index}.interestPortion`}
          render={({ field }) => (
            <EditRepaymentNumberInput
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              disabled={disabled}
            />
          )}
        />
      </section>

      <section>
        <Controller
          control={control}
          name={`repayments.${index}.lateFee`}
          render={({ field }) => (
            <EditRepaymentNumberInput
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              disabled={disabled}
            />
          )}
        />
      </section>

      <section>
        <Controller
          control={control}
          name={`repayments.${index}.dueDate`}
          render={({ field }) => (
            <EditRepaymentDatePicker
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              disabled={disabled}
              required
            />
          )}
        />
      </section>

      <section className="delete-repayment-button">
        {status === 'PENDING' ? (
          <Button
            disabled={index === 0 || disabled}
            classes="red btn-small"
            click_event={index === 0 ? null : () => remove(index)}
          >
            Delete
          </Button>
        ) : (
          <section
            className={`repayment-tag repayment-tag__${status.toLowerCase()}`}
          >
            {capitalizeInitialLetter(status)}
          </section>
        )}
      </section>
    </section>
  );
};

const EditRepaymentNumberInput = ({
  name,
  value,
  onChange,
  disabled,
  required,
}) => (
  <NumberFormat
    thousandSeparator
    className="edit-repayment-input"
    name={name}
    value={value}
    onChange={onChange}
    disabled={disabled}
    allowNegative={false}
    customInput={Input}
    required={required}
  />
);

const EditRepaymentDatePicker = ({ name, value, onChange, disabled }) => {
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <DatePicker
      selected={value ? normalizeDateTime(value) : null}
      minDate={tomorrow}
      className="edit-repayment-input"
      name={name}
      onChange={onChange}
      dateFormat="d MMM yyyy"
      disabled={disabled}
      required
    />
  );
};

const Input = props => <input {...props} />;
