import React, { Fragment } from 'react';
import {
  Button,
  CheckBox,
  CheckBoxGroup,
  DataCard,
  Input,
  Popup,
  TextArea,
  Toast,
} from '../../../../components';
import { RejectManualReviewApplication } from '../../../../Providers';

const checkboxes = [
  {
    name: 'State of residence not supported',
    key: 'checkBox1',
    label: 'State of residence not supported',
    id: '1',
  },
  {
    name: 'Outstanding unpaid loan ',
    key: 'checkBox2',
    label: 'Outstanding unpaid loan ',
    id: '2',
  },
  {
    name: 'Debt-service ratio exceeded',
    key: 'checkBox3',
    label: 'Debt-service ratio exceeded',
    id: '3',
  },
  {
    name: 'Salary not sufficient',
    key: 'checkBox4',
    label: 'Salary not sufficient',
    id: '4',
  },
  {
    name: 'Unverified Documents ',
    key: 'checkBox5',
    label: 'Unverified Documents ',
    id: '5',
  },
  {
    name: 'Other Reasons',
    key: 'checkBox6',
    label: 'Others',
    id: '6',
  },
];

const RejectManualReviewApplications = ({ data }) => {
  return (
    <Fragment>
      <RejectManualReviewApplication
        applicationId={data.application.id}
        render={({
          handleHideToast,
          input,
          handleRejectReviewApplication,
          showRejectManualApplicationPopup,
          hideRejectManualApplicationPopup,
          handleCheckBoxInput,
        }) => (
          <Fragment>
            <Popup
              title={'Review Application'}
              classes="manual-approval__popup"
              active={input.showPopup.value}
            >
              <DataCard noPadding={true}>
                <span className="decline-message">
                  Are you sure you want to decline this loan ?
                </span>
                <Input
                  label="Application Number"
                  name="userId"
                  value={data.application.applicationNumber}
                  classes="app-number"
                  readOnly
                />
                <label className="label">
                  Please select one or more reasons for decline{' '}
                </label>
                <CheckBoxGroup>
                  {checkboxes.map(item => (
                    <label key={item.key}>
                      <CheckBox
                        {...input.message.input}
                        classes="manualreviewcheckbox"
                        onChange={handleCheckBoxInput}
                        label={item.label}
                        id={item.id}
                        value={item.name}
                      />
                    </label>
                  ))}
                </CheckBoxGroup>
                <TextArea
                  classes="text-area"
                  {...input.otherMessage.input}
                  cols={1}
                  rows={3}
                  label="Comments"
                  placeholder="Comments"
                />
                <div className="right">
                  <Button
                    click_event={handleRejectReviewApplication}
                    classes="secondary green"
                    type="submit"
                  >
                    Decline
                  </Button>
                  <Button
                    click_event={hideRejectManualApplicationPopup}
                    type="submit"
                  >
                    Cancel
                  </Button>
                </div>
              </DataCard>
            </Popup>
            <Toast
              title="Decline Loan"
              content={input.content.value}
              classes={input.status.value}
              active={input.showToast.value}
              clickEvent={handleHideToast}
            />
            <Button
              classes="right"
              click_event={showRejectManualApplicationPopup}
            >
              Decline Loan
            </Button>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default RejectManualReviewApplications;
