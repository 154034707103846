import {
  ChakraProviderProps,
  extendTheme,
  withDefaultColorScheme,
  ThemeOverride,
} from '@chakra-ui/react';

export const overrides: ThemeOverride<ChakraProviderProps['theme']> = {
  colors: {
    blue: {
      '50': '#E5F1FF',
      '100': '#B8D9FF',
      '200': '#8AC0FF',
      '300': '#5CA8FF',
      '400': '#2E8FFF',
      '500': '#0077FF',
      '600': '#005FCC',
      '700': '#004799',
      '800': '#003066',
      '900': '#001833',
    },
  },
  styles: {
    global: {
      html: {
        fontSize: '100%',
      },
      body: {
        bg: 'gray.50',
        color: 'gray.700',
        fontSize: '16px',
        minHeight: '100vh',
      },
      a: {
        color: 'blue.500',
        _hover: {
          color: 'blue.500',
          display: 'inherit',
          textDecoration: 'underline',
        },
        _focus: {
          display: 'inherit',
          color: 'inherit',
        },
      },
    },
  },
};

export const theme: ChakraProviderProps['theme'] = extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: 'blue' }),
);
