import React, { Fragment } from 'react';
import {
  Button,
  Input,
  Loader,
  Modal,
  NewCheckBox,
  NewRadio,
  Notification,
  Toast,
} from '../../../../components';
import { Close, CompleteIcon } from '../../../../components/Icons';

const UpdateProcessingFee = ({
  data,
  handleHideProcessingFeeToast,
  updateProcessingFeeLoader,
  handleProcessingFeeModalToggle,
  modifyProcessingFeeDataInput,
  handleUpdateProcessingFee,
  handleCheckBoxInput,
  processingFeeCheckbox,
  handleProcessingFeeCheck,
  processingFeeCheck,
}) => {
  return (
    <Fragment>
      {updateProcessingFeeLoader ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          <Toast
            title="Update Processing Fee"
            content={modifyProcessingFeeDataInput.content.value}
            clickEvent={handleHideProcessingFeeToast}
            active={modifyProcessingFeeDataInput.showToast.value}
            classes={modifyProcessingFeeDataInput.status.value}
          />
          {modifyProcessingFeeDataInput.showNotificationPopup.value && (
            <Notification>
              <div className="notification_icon-holder">
                <CompleteIcon />
              </div>
              <h5 className={'center-text'}>
                {processingFeeCheckbox !== false ? (
                  <p>
                    You have successfully waived all processing fees for loan{' '}
                    <b>{data.application.applicationNumber}</b> and your
                    customer has been notified.
                  </p>
                ) : (
                  <p>
                    You have successfully updated the loan processing fee for
                    application <b>{data.application.applicationNumber}</b> and
                    your customer has been notified
                  </p>
                )}
              </h5>
            </Notification>
          )}

          <Modal
            title={'Update Processing Fee'}
            classes="cash-repayment"
            active={modifyProcessingFeeDataInput.showModal.value}
          >
            <Close
              className="close-icon"
              onClick={handleProcessingFeeModalToggle}
            />
            <div>
              <p>Kindly Select one of this method</p>
              <div className="processingfee-radios">
                <NewRadio
                  name="processingFee"
                  label="Amount"
                  value="FIXED"
                  checked={processingFeeCheck.checked.value === 'FIXED'}
                  onChange={() =>
                    handleProcessingFeeCheck({
                      value: 'FIXED',
                    })
                  }
                />
                <NewRadio
                  name="processingFee"
                  label="Percentage"
                  value="PERCENTAGE"
                  checked={processingFeeCheck.checked.value === 'PERCENTAGE'}
                  onChange={() =>
                    handleProcessingFeeCheck({
                      value: 'PERCENTAGE',
                    })
                  }
                />

                {processingFeeCheck.checked.value === 'PERCENTAGE' ? (
                  <Input
                    label="Percentage"
                    placeholder="Enter new percentage"
                    type="number"
                    {...modifyProcessingFeeDataInput.processingFeePercent.input}
                    disabled={
                      processingFeeCheckbox ||
                      processingFeeCheck.checked.value == null
                    }
                  />
                ) : (
                  <Input
                    label="Amount"
                    placeholder="Enter new amount"
                    amountField={true}
                    {...modifyProcessingFeeDataInput.processingFeeFixed.input}
                    disabled={
                      processingFeeCheckbox ||
                      processingFeeCheck.checked.value == null
                    }
                  />
                )}

                <div className="processingfee-aside">
                  {processingFeeCheck.checked.value === 'PERCENTAGE'
                    ? '%'
                    : 'NGN'}
                </div>
              </div>
              <NewCheckBox
                label="Wavie all processing fees"
                onChange={handleCheckBoxInput}
                classes="processing-fee-checkbox"
                checked={processingFeeCheckbox}
                {...modifyProcessingFeeDataInput.wavieProcessingFee.value}
                disabled={
                  modifyProcessingFeeDataInput.processingFeePercent.value ||
                  modifyProcessingFeeDataInput.processingFeeFixed.value
                }
              />

              <div className="processing-fee-button">
                <Button click_event={handleProcessingFeeModalToggle}>
                  Cancel
                </Button>

                <Button
                  classes="secondary"
                  click_event={handleUpdateProcessingFee}
                >
                  Update
                </Button>
              </div>
            </div>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UpdateProcessingFee;
