import { useMutation } from '@apollo/react-hooks';
import { UPDATE_EXTERNALLY_DISBURSED_LOAN_MUTATION } from '../graphql/mutations';
import { PORTFOLIO_QUERY } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';

const UpdateExternallyDisbursedLoanProvider = ({ render, portfolio }) => {
  const updateExternallydisbursedLoanInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    message: useCustomState(),
    comment: useCustomState(),
    showPopup: useCustomState(false),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      updateExternallydisbursedLoanInput.showToast.setValue(false);
    }, 3000);
  };

  const updateExternalDisbursment = async (e, updateDisbursment) => {
    e.preventDefault();

    await updateDisbursment({
      variables: {
        portfolioId: portfolio.id,
      },
      refetchQueries: () => [
        {
          query: PORTFOLIO_QUERY,
          variables: { portfolioNumber: portfolio.portfolioNumber },
        },
      ],
    });
  };

  const showUpdateExternalDisbursmentPopup = e => {
    e.preventDefault();
    updateExternallydisbursedLoanInput.showPopup.setValue(true);
  };

  const hideUpdateExternalDisbursmentPopup = e => {
    e.preventDefault();
    updateExternallydisbursedLoanInput.showPopup.setValue(false);
  };

  const [updateDisbursment, { loading }] = useMutation(
    UPDATE_EXTERNALLY_DISBURSED_LOAN_MUTATION,
    {
      async onCompleted() {
        updateExternallydisbursedLoanInput.showToast.setValue(true);
        updateExternallydisbursedLoanInput.status.setValue('Success');
        updateExternallydisbursedLoanInput.content.setValue(
          'Externally Disbursed Loan',
        );
        handleHideToast();
        hideUpdateExternalDisbursmentPopup();
      },

      onError(error) {
        updateExternallydisbursedLoanInput.showToast.setValue(true);
        updateExternallydisbursedLoanInput.status.setValue('Failed');
        updateExternallydisbursedLoanInput.content.setValue(
          trimGraphQLError(error.message),
        );
        handleHideToast();
      },
    },
  );

  return render({
    externaldisbursmentLoader: loading,
    handleHideToast,
    hideUpdateExternalDisbursmentPopup,
    showUpdateExternalDisbursmentPopup,
    input: updateExternallydisbursedLoanInput,
    updateExternalDisbursment: e =>
      updateExternalDisbursment(e, updateDisbursment),
  });
};
export default UpdateExternallyDisbursedLoanProvider;
