import { CREATE_CUSTOMER } from '@/graphql/mutations/customer';
import { useMutation } from '@apollo/client';
import { CustomerCreateMpInput } from '@/__generated/graphql';
import { baseRoutes } from '@/routes/routes';
import { useNavigate } from 'react-router-dom-v6';

export const useCreateCustomer = () => {
  const navigate = useNavigate();

  const [mutate, { loading, error }] = useMutation(CREATE_CUSTOMER, {
    refetchQueries: () => [],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onError: () => {},
    onCompleted: ({ createCustomerMp }) => {
      navigate(
        encodeURI(
          `${baseRoutes?.customers}/${createCustomerMp?.account?.name}`,
        ),
      );
    },
  });

  const createCustomer = ({
    email,
    phone,
    address,
    firstName,
    gender,
    lastName,
    localGovernment,
    bvn,
  }: CustomerCreateMpInput) => {
    mutate({
      variables: {
        input: {
          email,
          phone,
          address,
          firstName,
          gender,
          lastName,
          localGovernment,
          bvn,
        },
      },
    });
  };

  return {
    createCustomerError: error,
    createCustomerLoading: loading,
    createCustomer,
  };
};
