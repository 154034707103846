import { Query } from '@apollo/react-components';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connection_limit } from '../config/config';
import { CREDIT_POLICIES_QUERY } from '../graphql/queries';

const CreditPolicyTableProvider = ({ render }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchMore = (e, fetchMore, data) => {
    e.preventDefault();
    setIsLoading(true);
    fetchMore({
      variables: {
        cursor: data.viewer.account.policies.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoading(false);
        const newNodes = fetchMoreResult.viewer.account.policies.nodes;
        const pageInfo = fetchMoreResult.viewer.account.policies.pageInfo;
        return newNodes.length
          ? {
              viewer: {
                __typename: prev.viewer.__typename,
                account: {
                  __typename: prev.viewer.account.__typename,
                  policies: {
                    __typename: prev.viewer.account.policies.__typename,
                    nodes: [...prev.viewer.account.policies.nodes, ...newNodes],
                    pageInfo,
                  },
                },
              },
            }
          : prev;
      },
    });
  };

  return (
    <Query
      query={CREDIT_POLICIES_QUERY}
      variables={{ first: connection_limit.value }}
    >
      {({ data, fetchMore }) => {
        return render({
          creditPolicyTableData: data,
          isLoading,
          handlePagination: e => handleFetchMore(e, fetchMore, data),
        });
      }}
    </Query>
  );
};

export default withRouter(CreditPolicyTableProvider);
