import React from 'react';
import PropTypes from 'prop-types';
import './_PopUp.scss';
import { DataCard } from '../index';

const Popup = props => {
  return (
    <section
      className={`popup ${props.classes ? props.classes : ''} ${
        props.active ? 'active' : ''
      }`}
    >
      <div className="popup__content">
        <DataCard title={props.title}>{props.children}</DataCard>
      </div>
    </section>
  );
};

Popup.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool,
  classes: PropTypes.string,
};

export default Popup;
