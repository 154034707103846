import React, { Fragment, useEffect, useState } from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';

import {
  BreadCrumbSection,
  Button,
  CustomerDetailsSideBar,
  DataCard,
  EmptyScreen,
  KycUnverified,
  Loader,
} from '../../components';

import { capitalizeInitialLetter, removeCommas } from '../../lib/Utils';
import {
  ApplicationDetailsProvider,
  ChangeApplicationPolicyProvider,
  RepaymentBreakdownProvider,
  SetManualReviewApplication,
  useRuleBreakDownProvider,
  useSetApplicationBankAccount,
} from '../../Providers';
import { useClientInfo } from '../../Providers-new/Client';
import {
  ModifyProcessingFee,
  useMutateApplyMoratoriumOnApplication,
  useMutateRequestSupportingDocuments,
  useMutateReviewSupportingDocuments,
  useMutateUpdateLoanDuration,
  useMutateUpdateApplicationLoanAmount,
  useMutateModifyApplicationInterestRate,
} from '../../Providers-new/LoanModification';

import {
  SideBar,
  RejectManualReviewApplications,
  ApproveManualReviewApplications,
  AdvanceApplicationWorkFlowSteps,
  RevertApplicationWorkFlowSteps,
  ModifyLoanDuration,
  ApplyMoratoriumOnApplication,
  UpdateProcessingFee,
  RuleBreakdown,
  RequestSupportingDoc,
  ReviewDoc,
  UpdateApplicationLoanAmount,
  ApplicationInformationTab,
  UploadedBankStatementTab,
  CreditBureauScoreReportTab,
  MyBankStatementTab,
  CrcNanoReportScoreTab,
  CreditRegistryReportTab,
  ModelResponseTab,
  BankStatementAnalysisTab,
  RepaymentDetailsTab,
  SupportingDocumentsTab,
  PropertyDetailsTab,
  OkraSummaryTab,
  DecideBankStatementAnalysisTab,
  CrcMicroReportScoreReportTab,
  ModifyInterestRate,
  SetApplicationBankAcc,
  RepaymentBreakdownTab,
  ActivitiesTab,
  AssignApplication,
} from './ApplicationDetailsSubfiles/index';
import { ErrorBoundary } from 'react-error-boundary';

import './_Loans.scss';
import './_Styles.scss';
import { useMutateAssignAndUnassignApplication } from '../../Providers-new/Applications';
import { Link } from 'react-router-dom';

let showPropertyDetails = false;
let hasApplicationMeta = false;
let applicationMeta = [];
let applicationMetaKey = [];
let propertyApplicationMeta = [];
let requiredPropertyApplicationMeta = [];

const getSetManualReviewText = status => {
  let pre = '';
  if (status === 'ABANDONED')
    pre = 'This application was abandoned by the customer. ';
  if (status === 'PENDING')
    pre = 'This application was not completed by the customer. ';
  return `${pre}Would you like to change the status so you can process it manually?`;
};

// TEMP Fix to accommodate irregular model response output
const displayModelOutput = ({
  output,
  risk_output: risk,
  affordability_output: affordability,
} = {}) => {
  const outArray = [];

  if (output) outArray.push(output);
  if (risk) outArray.push(`${capitalizeInitialLetter(risk)} Risk`);
  if (affordability && affordability.maxAmountAllowable > 0) {
    const { minAmountAllowable, maxAmountAllowable } = affordability;
    outArray.push(
      `Affordability range: ${minAmountAllowable} - ${maxAmountAllowable}`,
    );
  }

  return outArray.length !== 0 ? outArray.join(', ') : 'N/A';
};

const ApplicationDetails = ({
  data = {},
  input,
  refetch,
  customerName,
  handleExportToCsv,
  isVisible,
  facilityInfo = {},
  handleExportToCsvCrcScoreReport,
  handleExportToCsvCreditBureauScoreReport,
  handleExportToCsvModelResponse,
  handleExportToCsvBankStatementAnalysis,
  handleExportToCsvCreditRegistryReport,
  modelResults,
  modelResponseData,
  bankStatementAnalysisData,
  missingApplicationStepMessage,
  handleExportToOkraSummary,
  decideBankStatementAnalysis,
  applicationHistoryPagination,
  applicationHistoryLoading,
  runSearchQuery,
  applicationHistoryInput,
  currentTab,
  setCurrentTab,
}) => {
  const [applicationSupportingDocuments, setApplicationSupportingDocuments] =
    useState([]);
  const [fileName, setFileName] = useState('');
  const [decideLoanAmount, setDecideLoanAmount] = useState('');

  useEffect(() => {
    showPropertyDetails = localStorage.getItem('slug') === 'citihomes';
    applicationMeta = data?.application?.meta?.length
      ? data.application.meta.map(item => item.document)
      : [];
    propertyApplicationMeta = data?.application?.meta
      ? data.application.meta
      : [];

    applicationMetaKey = data?.application?.meta?.length
      ? data.application.meta.map(item => ({
          document: item.document,
          key: item.key,
        }))
      : [];

    requiredPropertyApplicationMeta = propertyApplicationMeta.filter(el => {
      return !el.document;
    });
    hasApplicationMeta = applicationMeta.length >= 1;
    setApplicationSupportingDocuments(applicationMeta);
  }, []);

  const updateApplicationSupportingDocument = value => {
    const applicationSupportingDocument =
      applicationSupportingDocuments.concat(value);
    setApplicationSupportingDocuments(applicationSupportingDocument);
  };

  const {
    data: requestSupportingDocData,
    handleModalToggle,
    loading: requestSupportingDocLoader,
    inputs: requestSupportingDocInput,
    handleRequestSupportingDocument,
    handleHideToast,
    addNewStep,
    stepState,
    handleStepChange,
    removeStep,
  } = useMutateRequestSupportingDocuments({
    application: data?.application,
  });

  const {
    data: reviewSupportingDocData,
    handleModalToggle: handleModalReviewDocToggle,
    handleReviewSupportingDocument,
    handleHideToast: handleHideReviewDocToast,
    loading: reviewDocLoader,
  } = useMutateReviewSupportingDocuments({ application: data?.application });

  const {
    handleSetManualReviewApplication,
    loading: setManualReviewLoading,
    input: setManualReviewInput,
    handleSetManualReviewApplicationToast,
    showManualReviewPopup,
    hideManualReviewPopup,
  } = SetManualReviewApplication({ application: data?.application });

  const {
    handleHideProcessingFeeToast,
    updateProcessingFeeLoader,
    handleProcessingFeeModalToggle,
    modifyProcessingFeeDataInput,
    handleUpdateProcessingFee,
    handleCheckBoxInput,
    checkboxState: processingFeeCheckbox,
    handleProcessingFeeCheck,
    processingFeeCheck,
  } = ModifyProcessingFee({ application: data?.application });
  const {
    data: applyMoratoriumOnApplicationData,
    handleModalToggle: handleModalApplyMoratoriumToggle,
    loading: applyMoratoriumOnApplicationLoader,
    inputs: applyMoratoriumOnApplicationInput,
    handleApplyMoratoriumOnApplication,
    handleHideToast: handleHideApplyMoratoriumOnApplication,
    repaymentValue,
    handleMoratoriumCheckBoxInput,
    checkboxState: moratoriumCheckbox,
  } = useMutateApplyMoratoriumOnApplication({ application: data?.application });

  const {
    handleHideToast: handleHideModifyLoanDurationToast,
    loading: modifyLoanDurationLoading,
    handleModalToggle: handleModalModifyLoanDurationToggle,
    data: modifyLoanDurationData,
    inputs: modifyLoanDurationInput,
    handleUpdateLoanDuration,
    duration: modifyLoanDuration,
    durationTypeLoading: modifyLoanDurationType,
  } = useMutateUpdateLoanDuration({ application: data?.application });

  const {
    applicationLoanAmountData,
    handleHideApplicationLoanAmountToast,
    updateApplicationLoanAmountLoader,
    handleApplicationLoanAmountModalToggle,
    handleLoanAmountCheckBoxInput,
    handleUpdateApplicationLoanAmount,
    handleModifyRequestedAmountCheck,
    modifyRequestedAmountCheck,
    checkboxState: loanAmountCheckboxState,
  } = useMutateUpdateApplicationLoanAmount({
    application: data?.application,
    decideLoanAmount,
  });

  const {
    handleAssignAndUnassignApplicationToggle,
    openAssignAndUnassignApplicationModal,
    assignApplication,
    unassignApplication,
    assignApplicationIsLoading,
    unAssignApplicationIsLoading,
  } = useMutateAssignAndUnassignApplication({
    applicationNumber: data?.application?.applicationNumber,
  });

  let bankStatementAnalysis = [];
  bankStatementAnalysisData?.length !== 0 &&
    Object.keys(bankStatementAnalysisData[0]).forEach(key => {
      const filteredKeys = key !== 'bsp_status';

      if (filteredKeys) {
        const getBankStatementAnalysisData = bankStatementAnalysisData.map(
          val => val[key],
        );

        const getBankStatementAnalysis = getBankStatementAnalysisData.find(
          val => val['flat'],
        );
        const getBSFlatData = getBankStatementAnalysis.flat;

        if (
          typeof getBSFlatData == 'object' &&
          Object.keys(getBSFlatData).length
        ) {
          bankStatementAnalysis.push(getBSFlatData);
        }
      }
    });

  const hasBankStatementAnalysis =
    bankStatementAnalysis.length !== 0 && bankStatementAnalysis;

  const hasDecideBankStatementAnalysisData =
    decideBankStatementAnalysis &&
    decideBankStatementAnalysis != null &&
    Object.keys(decideBankStatementAnalysis).includes(
      'behaviouralAnalysis',
      'cashFlowAnalysis',
      'incomeAnalysis',
      'spendAnalysis',
      'transactionPatternAnalysis',
    );

  const decideAffordabilityLoanAmount =
    hasDecideBankStatementAnalysisData &&
    decideBankStatementAnalysis.incomeAnalysis.averageSalary != null &&
    decideBankStatementAnalysis.incomeAnalysis.averageOtherIncome != null
      ? parseInt(decideBankStatementAnalysis.incomeAnalysis.averageSalary) +
        parseInt(decideBankStatementAnalysis.incomeAnalysis.averageOtherIncome)
      : null;

  const {
    data: ruleBreakdownData,
    loading: ruleBreakdownLoading,
    showChangeRuleBreakDownPopup,
    hideChangeRuleBreakDownPopup,
    inputs,
  } = useRuleBreakDownProvider({
    applicationId: data?.application?.id,
  });

  const {
    handleHideApplicationInterestRateToast,
    modifyApplicationInterestRateLoader,
    handleApplicationInterestRateModalToggle,
    modifyApplicationInterestRateDataInput,
    handleModifyApplicationInterestRate,
  } = useMutateModifyApplicationInterestRate({
    application: data?.application,
    policyId: data?.application?.policy?.id,
    principalAmount: parseFloat(removeCommas(data?.application?.amount)),
    duration: parseInt(data?.application?.loanDuration),
    moratoriumPeriod:
      data?.application?.moratoriumData !== null
        ? parseInt(data?.application?.moratoriumData?.numberOfPeriods)
        : '',
  });

  const {
    handleChangeApplicationPolicy,
    loading: changePolicyLoading,
    applicationPolicyInput,
    handleHideToast: handleChangeApplicationPolicyToast,
    showChangePolicyPopup,
    hideChangePolicyPopup,
    getChangeDescription,
  } = ChangeApplicationPolicyProvider({ application: data?.application });

  const {
    setApplicationBankAccountData,
    setApplicationBankAccountLoading,
    setApplicationBankAccountToast,
    setApplicationBankAccountToggle,
    handleSetApplicationBankAccount,
  } = useSetApplicationBankAccount({ application: data?.application });

  const { clientInfo } = useClientInfo({
    privateFields: true,
  });

  const {
    requiresOkraTransactions,
    requiresCrcReport,
    requiresCrcMicroReport,
  } = clientInfo;

  const offers = [];
  const cashflows = [];
  const estimations = [];
  const reasonCodes = [];
  const filteredModelResponseData =
    modelResponseData != null &&
    modelResponseData.find(item => item && item.risk_output);
  const risk =
    filteredModelResponseData &&
    capitalizeInitialLetter(filteredModelResponseData.risk_output);

  const { repaymentBreakdown } = RepaymentBreakdownProvider({
    policyId: data?.application?.policy?.id,
    principalAmount: parseFloat(removeCommas(data?.application?.amount)),
    duration: parseInt(data?.application?.loanDuration),
    moratoriumPeriod:
      data?.application?.moratoriumData !== null
        ? parseInt(data?.application?.moratoriumData?.numberOfPeriods)
        : '',
    applicationId: data?.application?.id,
  });

  modelResponseData != null &&
    modelResponseData
      .filter(item => item.affordability_output)
      .forEach(({ affordability_output: affordability }) => {
        if (affordability.offers) offers.push(affordability.offers);

        if (affordability.cashflow) cashflows.push(affordability.cashflow);

        if (affordability.estimations)
          estimations.push(affordability.estimations);

        if (affordability.reasonCode)
          reasonCodes.push(affordability.reasonCode);
      });

  if (modifyLoanDurationType) return <Loader />;

  const offerLetter =
    data?.application?.status &&
    data?.application?.reviewFeedbacks &&
    data.application.reviewFeedbacks.find(
      feedback => feedback.reviewType === 'OFFER_LETTER_REQUEST',
    );

  const offerLetterDocument = offerLetter
    ? offerLetter.documentRequests[0].document
    : {};

  return (
    <>
      <Fragment>
        <BreadCrumbSection unlinklast={true}>
          {/* TODO: Remove this after full go live */}
          <Button classes="btn-small">
            <Link to={`/v1/applications/${data.application.applicationNumber}`}>
              <span style={{ fontSize: '13px', fontWeight: '600' }}>
                View Latest Version
              </span>
            </Link>
          </Button>
        </BreadCrumbSection>

        <CustomerDetailsSideBar>
          <SideBar
            data={data}
            refetch={refetch}
            isVisible={isVisible}
            changePolicyLoading={changePolicyLoading}
            facilityInfo={facilityInfo}
            customerName={customerName}
            applicationPolicyInput={applicationPolicyInput}
            handleChangeApplicationPolicyToast={
              handleChangeApplicationPolicyToast
            }
            getChangeDescription={getChangeDescription}
            hideChangePolicyPopup={hideChangePolicyPopup}
            handleChangeApplicationPolicy={handleChangeApplicationPolicy}
            setManualReviewLoading={setManualReviewLoading}
            setManualReviewInput={setManualReviewInput}
            handleSetManualReviewApplicationToast={
              handleSetManualReviewApplicationToast
            }
            getSetManualReviewText={getSetManualReviewText}
            hideManualReviewPopup={hideManualReviewPopup}
            handleSetManualReviewApplication={handleSetManualReviewApplication}
            showChangePolicyPopup={showChangePolicyPopup}
            missingApplicationStepMessage={missingApplicationStepMessage}
          />
        </CustomerDetailsSideBar>

        <main className="layout-customerdetails">
          <ErrorBoundary
            fallback={
              <DataCard noPadding={true}>
                <EmptyScreen message="Error Fetching Applications Details" />
              </DataCard>
            }
          >
            <div className="layout-customerdetails--header">
              {isVisible
                ? data?.application && (
                    <h5>{`${facilityInfo?.facilityName}'s Application`}</h5>
                  )
                : data?.application?.user && (
                    <h5>{`${data.application.user.firstName}'s Application`}</h5>
                  )}

              {!data?.application?.user.isBvnVerified ? (
                <KycUnverified />
              ) : null}

              <div className="manual-approval">
                {data && (
                  <Fragment>
                    {!(
                      data?.application?.status.name === 'APPROVED' ||
                      data?.application?.status.name === 'DENIED'
                    ) && <RejectManualReviewApplications data={data} />}
                    {!data?.application?.policy ? (
                      missingApplicationStepMessage
                        .split(',')
                        .includes('Pending Application Bank Account') &&
                      data?.application?.account?.bankAccounts.length > 0 ? (
                        <Fragment>
                          <Button
                            classes="right secondary"
                            click_event={setApplicationBankAccountToggle}
                          >
                            Set Default Bank Account
                          </Button>
                          <SetApplicationBankAcc
                            setApplicationBankAccountData={
                              setApplicationBankAccountData
                            }
                            setApplicationBankAccountLoading={
                              setApplicationBankAccountLoading
                            }
                            setApplicationBankAccountToast={
                              setApplicationBankAccountToast
                            }
                            setApplicationBankAccountToggle={
                              setApplicationBankAccountToggle
                            }
                            handleSetApplicationBankAccount={
                              handleSetApplicationBankAccount
                            }
                          />
                        </Fragment>
                      ) : (
                        <Button
                          classes="right secondary"
                          click_event={showChangePolicyPopup}
                        >
                          Apply Loan Product
                        </Button>
                      )
                    ) : ['PENDING', 'ABANDONED'].includes(
                        data.application.status.name,
                      ) ? (
                      <Button
                        classes="right secondary"
                        click_event={showManualReviewPopup}
                      >
                        Move to Manual Review
                      </Button>
                    ) : !data.application.approvalWorkflow ? (
                      !(
                        data.application.status.name === 'APPROVED' ||
                        data.application.status.name === 'DENIED' ||
                        data.application.status.name === 'AWAITING_FEEDBACK'
                      ) && <ApproveManualReviewApplications data={data} />
                    ) : (
                      data.application.status.name !== 'DENIED' &&
                      data.application.approvalWorkflow.nextStep && (
                        <AdvanceApplicationWorkFlowSteps data={data} />
                      )
                    )}
                    {data?.application?.status?.name !== 'DENIED' &&
                      data?.application?.approvalWorkflow?.nextStep && (
                        <RevertApplicationWorkFlowSteps data={data} />
                      )}
                  </Fragment>
                )}
              </div>
            </div>

            <UpdateApplicationLoanAmount
              applicationLoanAmountData={applicationLoanAmountData}
              handleHideApplicationLoanAmountToast={
                handleHideApplicationLoanAmountToast
              }
              updateApplicationLoanAmountLoader={
                updateApplicationLoanAmountLoader
              }
              handleApplicationLoanAmountModalToggle={
                handleApplicationLoanAmountModalToggle
              }
              handleLoanAmountCheckBoxInput={handleLoanAmountCheckBoxInput}
              handleUpdateApplicationLoanAmount={
                handleUpdateApplicationLoanAmount
              }
              handleModifyRequestedAmountCheck={
                handleModifyRequestedAmountCheck
              }
              modifyRequestedAmountCheck={modifyRequestedAmountCheck}
              loanAmountCheckboxState={loanAmountCheckboxState}
              setDecideLoanAmount={setDecideLoanAmount}
              decideAffordabilityLoanAmount={decideAffordabilityLoanAmount}
              decideLoanAmount={decideLoanAmount}
              hasDecideBankStatementAnalysisData={
                hasDecideBankStatementAnalysisData
              }
            />
            <ModifyLoanDuration
              data={data}
              handleHideModifyLoanDurationToast={
                handleHideModifyLoanDurationToast
              }
              modifyLoanDurationLoading={modifyLoanDurationLoading}
              handleModalModifyLoanDurationToggle={
                handleModalModifyLoanDurationToggle
              }
              modifyLoanDurationData={modifyLoanDurationData}
              modifyLoanDurationInput={modifyLoanDurationInput}
              handleUpdateLoanDuration={handleUpdateLoanDuration}
              modifyLoanDuration={modifyLoanDuration}
            />
            <UpdateProcessingFee
              data={data}
              handleHideProcessingFeeToast={handleHideProcessingFeeToast}
              updateProcessingFeeLoader={updateProcessingFeeLoader}
              handleProcessingFeeModalToggle={handleProcessingFeeModalToggle}
              modifyProcessingFeeDataInput={modifyProcessingFeeDataInput}
              handleUpdateProcessingFee={handleUpdateProcessingFee}
              handleCheckBoxInput={handleCheckBoxInput}
              processingFeeCheckbox={processingFeeCheckbox}
              handleProcessingFeeCheck={handleProcessingFeeCheck}
              processingFeeCheck={processingFeeCheck}
            />

            <ApplyMoratoriumOnApplication
              data={data}
              applyMoratoriumOnApplicationData={
                applyMoratoriumOnApplicationData
              }
              handleModalApplyMoratoriumToggle={
                handleModalApplyMoratoriumToggle
              }
              applyMoratoriumOnApplicationLoader={
                applyMoratoriumOnApplicationLoader
              }
              applyMoratoriumOnApplicationInput={
                applyMoratoriumOnApplicationInput
              }
              handleApplyMoratoriumOnApplication={
                handleApplyMoratoriumOnApplication
              }
              handleHideApplyMoratoriumOnApplication={
                handleHideApplyMoratoriumOnApplication
              }
              repaymentValue={repaymentValue}
              handleMoratoriumCheckBoxInput={handleMoratoriumCheckBoxInput}
              moratoriumCheckbox={moratoriumCheckbox}
              repaymentBreakdown={repaymentBreakdown}
            />
            <RequestSupportingDoc
              requestSupportingDocData={requestSupportingDocData}
              handleModalToggle={handleModalToggle}
              requestSupportingDocLoader={requestSupportingDocLoader}
              requestSupportingDocInput={requestSupportingDocInput}
              handleRequestSupportingDocument={handleRequestSupportingDocument}
              handleHideToast={handleHideToast}
              addNewStep={addNewStep}
              stepState={stepState}
              handleStepChange={handleStepChange}
              removeStep={removeStep}
            />

            <ReviewDoc
              reviewSupportingDocData={reviewSupportingDocData}
              handleModalReviewDocToggle={handleModalReviewDocToggle}
              handleReviewSupportingDocument={handleReviewSupportingDocument}
              handleHideReviewDocToast={handleHideReviewDocToast}
              reviewDocLoader={reviewDocLoader}
            />

            <RuleBreakdown
              ruleBreakdownData={ruleBreakdownData}
              ruleBreakdownLoading={ruleBreakdownLoading}
              hideChangeRuleBreakDownPopup={hideChangeRuleBreakDownPopup}
              inputs={inputs}
            />

            <ModifyInterestRate
              data={data}
              handleHideApplicationInterestRateToast={
                handleHideApplicationInterestRateToast
              }
              modifyApplicationInterestRateLoader={
                modifyApplicationInterestRateLoader
              }
              handleApplicationInterestRateModalToggle={
                handleApplicationInterestRateModalToggle
              }
              modifyApplicationInterestRateDataInput={
                modifyApplicationInterestRateDataInput
              }
              handleModifyApplicationInterestRate={
                handleModifyApplicationInterestRate
              }
            />

            <AssignApplication
              handleAssignAndUnassignApplicationToggle={
                handleAssignAndUnassignApplicationToggle
              }
              openAssignAndUnassignApplicationModal={
                openAssignAndUnassignApplicationModal
              }
              assignApplication={assignApplication}
              unassignApplication={unassignApplication}
              assignApplicationIsLoading={assignApplicationIsLoading}
              unAssignApplicationIsLoading={unAssignApplicationIsLoading}
              applicationId={data?.application?.id}
              assignedMerchantUser={
                data?.application?.assignedTo?.accountUserId
              }
            />
            <DataCard noPadding={true}>
              <Tabs
                onChange={tabId => {
                  setCurrentTab(tabId);
                }}
                defaultTab={currentTab}
              >
                <TabList>
                  <Tab tabFor="application-information" className={'tab-title'}>
                    Information
                  </Tab>
                  {data?.application?.credit?.bankStatement?.status ===
                    'success' && (
                    <Tab tabFor="my-bank-statement" className={'tab-title'}>
                      My Bank Statement
                    </Tab>
                  )}
                  {data?.application?.credit?.okraSummary &&
                    requiresOkraTransactions && (
                      <Tab tabFor="okra-summary" className={'tab-title'}>
                        Okra Bank Statement
                      </Tab>
                    )}
                  {data?.application?.credit?.creditBureauScoreReport !=
                    null && (
                    <Tab tabFor={'credit-bureau-score-report'}>
                      First Central Report
                    </Tab>
                  )}
                  {data?.application?.credit?.crcScoreReport != null &&
                    requiresCrcReport && (
                      <Tab tabFor={'crc-nano-score-report'}>
                        CRC Nano Report
                      </Tab>
                    )}
                  {data?.application?.credit?.crcMicroScoreReport != null &&
                    requiresCrcMicroReport && (
                      <Tab tabFor={'crc-micro-score-report'}>
                        CRC Micro Report
                      </Tab>
                    )}
                  {data?.application?.credit?.creditRegistryReport != null && (
                    <Tab tabFor={'credit-registry-report'}>
                      Credit Registry Report
                    </Tab>
                  )}
                  {data?.application?.credit?.modelResults?.length !== 0 &&
                    modelResponseData != null &&
                    modelResponseData.length && (
                      <Tab tabFor={'model-response'}>Model Response</Tab>
                    )}
                  {data?.application?.credit?.modelResults?.length !== 0 &&
                    hasBankStatementAnalysis && (
                      <Tab tabFor={'bank-statement-analysis'}>
                        Bank Statement Analysis
                      </Tab>
                    )}
                  {hasDecideBankStatementAnalysisData && (
                    <Tab tabFor={'decide-bank-statement-analysis'}>
                      Decide Bank Statement Analysis
                    </Tab>
                  )}
                  {data?.application?.uploadedBankStatement?.url != null && (
                    <Tab
                      tabFor="uploaded-bank-statement"
                      className={'tab-title'}
                    >
                      Bank Statement
                    </Tab>
                  )}
                  {data?.application && (
                    <Tab tabFor={'activities'}>Activity</Tab>
                  )}
                  {repaymentBreakdown ||
                  data?.application?.portfolio?.repayments?.length > 0 ? (
                    <Tab tabFor={'repayment-details'}>Repayment Details</Tab>
                  ) : (
                    ''
                  )}
                  <Tab tabFor={'supporting-documents'}>
                    Supporting Documents
                  </Tab>
                  {showPropertyDetails && hasApplicationMeta && (
                    <Tab tabFor={'property-details'}>Property Information</Tab>
                  )}
                </TabList>
                <TabPanel tabId="application-information">
                  <ApplicationInformationTab
                    data={data}
                    repaymentBreakdown={repaymentBreakdown}
                    offerLetter={offerLetter}
                    offerLetterDocument={offerLetterDocument}
                    missingApplicationStepMessage={
                      missingApplicationStepMessage
                    }
                    handleApplicationLoanAmountModalToggle={
                      handleApplicationLoanAmountModalToggle
                    }
                    handleProcessingFeeModalToggle={
                      handleProcessingFeeModalToggle
                    }
                    handleModalModifyLoanDurationToggle={
                      handleModalModifyLoanDurationToggle
                    }
                    handleModalApplyMoratoriumToggle={
                      handleModalApplyMoratoriumToggle
                    }
                    handleApplicationInterestRateModalToggle={
                      handleApplicationInterestRateModalToggle
                    }
                    handleAssignAndUnassignApplicationToggle={
                      handleAssignAndUnassignApplicationToggle
                    }
                  />
                </TabPanel>
                {data && (
                  <TabPanel tabId={'uploaded-bank-statement'}>
                    <UploadedBankStatementTab data={data} />
                  </TabPanel>
                )}
                {data?.application?.credit?.bankStatement?.status ===
                  'success' && (
                  <TabPanel tabId={'my-bank-statement'}>
                    <MyBankStatementTab
                      data={data}
                      handleExportToCsv={handleExportToCsv}
                    />
                  </TabPanel>
                )}
                {data?.application?.credit?.okraSummary && (
                  <TabPanel tabId={'okra-summary'}>
                    <OkraSummaryTab
                      data={data}
                      handleExportToOkraSummary={handleExportToOkraSummary}
                      handleHideToast={handleHideToast}
                      input={input}
                    />
                  </TabPanel>
                )}
                {data?.application?.credit?.crcScoreReport != null ? (
                  <TabPanel tabId={'crc-nano-score-report'}>
                    <CrcNanoReportScoreTab
                      data={data}
                      handleExportToCsvCrcScoreReport={
                        handleExportToCsvCrcScoreReport
                      }
                    />
                  </TabPanel>
                ) : (
                  ''
                )}
                {data?.application?.credit?.crcMicroScoreReport != null ? (
                  <TabPanel tabId={'crc-micro-score-report'}>
                    <CrcMicroReportScoreReportTab
                      data={data}
                      handleExportToCsvCrcScoreReport={
                        handleExportToCsvCrcScoreReport
                      }
                    />
                  </TabPanel>
                ) : (
                  ''
                )}
                {data?.application?.credit?.creditRegistryReport != null && (
                  <TabPanel tabId={'credit-registry-report'}>
                    <CreditRegistryReportTab
                      data={data}
                      handleExportToCsvCreditRegistryReport={
                        handleExportToCsvCreditRegistryReport
                      }
                    />
                  </TabPanel>
                )}
                {data?.application?.credit?.creditBureauScoreReport != null && (
                  <TabPanel tabId={'credit-bureau-score-report'}>
                    <CreditBureauScoreReportTab
                      handleExportToCsvCreditBureauScoreReport={
                        handleExportToCsvCreditBureauScoreReport
                      }
                      data={data}
                    />
                  </TabPanel>
                )}
                {modelResponseData != null &&
                  modelResponseData.length !== 0 && (
                    <TabPanel tabId={'model-response'}>
                      <ModelResponseTab
                        handleExportToCsvModelResponse={
                          handleExportToCsvModelResponse
                        }
                        modelResults={modelResults}
                        offers={offers}
                        risk={risk}
                        cashflows={cashflows}
                        estimations={estimations}
                        reasonCodes={reasonCodes}
                        displayModelOutput={displayModelOutput}
                      />
                    </TabPanel>
                  )}
                {hasBankStatementAnalysis &&
                  bankStatementAnalysis?.map((flat, idx) => (
                    <TabPanel key={idx} tabId={'bank-statement-analysis'}>
                      <BankStatementAnalysisTab
                        handleExportToCsvBankStatementAnalysis={
                          handleExportToCsvBankStatementAnalysis
                        }
                        flat={flat}
                      />
                    </TabPanel>
                  ))}
                {hasDecideBankStatementAnalysisData && (
                  <>
                    <TabPanel tabId={'decide-bank-statement-analysis'}>
                      <DecideBankStatementAnalysisTab
                        decideBankStatementAnalysis={
                          decideBankStatementAnalysis
                        }
                      />
                    </TabPanel>
                  </>
                )}
                {data?.application && (
                  <TabPanel tabId="activities">
                    <ActivitiesTab
                      data={data}
                      showChangeRuleBreakDownPopup={
                        showChangeRuleBreakDownPopup
                      }
                      applicationHistoryPagination={
                        applicationHistoryPagination
                      }
                      applicationHistoryLoading={applicationHistoryLoading}
                      runSearchQuery={runSearchQuery}
                      applicationHistoryInput={applicationHistoryInput}
                    />
                  </TabPanel>
                )}
                <TabPanel tabId="repayment-details">
                  {repaymentBreakdown ? (
                    <RepaymentBreakdownTab
                      repaymentBreakdown={repaymentBreakdown}
                    />
                  ) : (
                    <RepaymentDetailsTab data={data} />
                  )}
                </TabPanel>
                <TabPanel tabId={'supporting-documents'}>
                  <SupportingDocumentsTab
                    data={data}
                    fileName={fileName}
                    setFileName={setFileName}
                    updateApplicationSupportingDocument={
                      updateApplicationSupportingDocument
                    }
                    hasApplicationMeta={hasApplicationMeta}
                    applicationMeta={applicationMeta}
                    handleModalReviewDocToggle={handleModalReviewDocToggle}
                    handleModalToggle={handleModalToggle}
                    applicationMetaKey={applicationMetaKey}
                  />
                </TabPanel>
                <TabPanel tabId={'property-details'}>
                  <PropertyDetailsTab
                    hasApplicationMeta={hasApplicationMeta}
                    requiredPropertyApplicationMeta={
                      requiredPropertyApplicationMeta
                    }
                  />
                </TabPanel>
              </Tabs>
            </DataCard>
          </ErrorBoundary>
        </main>
      </Fragment>
    </>
  );
};

const ApplicationDetailsWrapper = () => (
  <ApplicationDetailsProvider
    render={({
      data,
      error,
      input,
      refetch,
      customerName,
      facilityName,
      handleExportToCsv,
      loading,
      facilityInfo,
      isVisible,
      handleExportToCsvCrcScoreReport,
      handleExportToCsvCreditBureauScoreReport,
      handleExportToCsvModelResponse,
      handleExportToCsvBankStatementAnalysis,
      handleExportToCsvCreditRegistryReport,
      modelResults,
      modelResponseData,
      bankStatementAnalysisData,
      missingApplicationStepMessage,
      handleExportToOkraSummary,
      decideBankStatementAnalysis,
      handlePagination,
      applicationHistoryLoading,
      runSearchQuery,
      applicationHistoryInput,
      currentTab,
      setCurrentTab,
    }) => (
      <ApplicationDetails
        data={data}
        error={error}
        refetch={refetch}
        input={input}
        customerName={customerName}
        facilityName={facilityName}
        handleExportToCsv={handleExportToCsv}
        loading={loading}
        isVisible={isVisible}
        facilityInfo={facilityInfo}
        handleExportToCsvCrcScoreReport={handleExportToCsvCrcScoreReport}
        handleExportToCsvCreditBureauScoreReport={
          handleExportToCsvCreditBureauScoreReport
        }
        handleExportToCsvModelResponse={handleExportToCsvModelResponse}
        handleExportToCsvBankStatementAnalysis={
          handleExportToCsvBankStatementAnalysis
        }
        handleExportToCsvCreditRegistryReport={
          handleExportToCsvCreditRegistryReport
        }
        modelResults={modelResults}
        modelResponseData={modelResponseData}
        bankStatementAnalysisData={bankStatementAnalysisData}
        missingApplicationStepMessage={missingApplicationStepMessage}
        handleExportToOkraSummary={handleExportToOkraSummary}
        decideBankStatementAnalysis={decideBankStatementAnalysis}
        applicationHistoryPagination={handlePagination}
        applicationHistoryLoading={applicationHistoryLoading}
        runSearchQuery={runSearchQuery}
        applicationHistoryInput={applicationHistoryInput}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    )}
  />
);

export default ApplicationDetailsWrapper;
