import React from 'react';
import { withRouter } from 'react-router-dom';
import { CREATE_POLICY_MUTATION } from '../graphql/mutations';
import { GET_ENUM } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import { useMutation, useQuery } from '@apollo/react-hooks';
import OriginateClient from './OriginateClient';
import { removeCommas } from '../lib/Utils';

const CreatePolicy = ({ render }) => {
  let enumData = {};

  const createPolicyInput = {
    name: useCustomState(),
    repaymentType: useCustomState(),
    durationType: useCustomState(),
    minLoanAmount: useCustomState(),
    maxLoanAmount: useCustomState(),
    minLoanDuration: useCustomState(),
    maxLoanDuration: useCustomState(),
    lateRepaymentTypeName: useCustomState(),
    lateRepaymentTypeCalcBy: useCustomState(),
    lateRepaymentTypeValue: useCustomState(),
    interestRateName: useCustomState(),
    interestRateCalcBy: useCustomState(),
    interestRateValue: useCustomState(),
    maxLateFeeDuration: useCustomState(),
    showToast: useCustomState(false),
    content: useCustomState(),
    processingFeeDebitMethod: useCustomState(),
  };

  const handleReset = () => {
    createPolicyInput.name.setValue('');
    createPolicyInput.repaymentType.setValue('');
    createPolicyInput.minLoanAmount.setValue('');
    createPolicyInput.maxLoanAmount.setValue('');
    createPolicyInput.minLoanDuration.setValue('');
    createPolicyInput.maxLoanDuration.setValue('');
    createPolicyInput.lateRepaymentTypeName.setValue('');
    createPolicyInput.lateRepaymentTypeCalcBy.setValue('');
    createPolicyInput.lateRepaymentTypeValue.setValue('');
    createPolicyInput.interestRateName.setValue('');
    createPolicyInput.interestRateCalcBy.setValue('');
    createPolicyInput.interestRateValue.setValue('');
    createPolicyInput.interestRateValue.setValue('');
    createPolicyInput.maxLateFeeDuration.setValue('');
  };

  const { data: rateCalcBy = [] } = useQuery(GET_ENUM, {
    variables: {
      type: 'RateCalcBy',
    },
  });

  const { data: durationType = [] } = useQuery(GET_ENUM, {
    variables: {
      type: 'DurationType',
    },
  });

  enumData.rateCalcBy = rateCalcBy.enumValues;
  enumData.durationType = durationType.enumValues;

  const handleHideToast = () => {
    setTimeout(() => {
      createPolicyInput.showToast.setValue(false);
    }, 3000);
  };

  const loanDescription = data => {
    let response = 'Days';

    switch (data) {
      case 'WEEKLY':
        response = 'Days';
        break;
      case 'MONTHLY':
        response = 'Months';
        break;
      case 'ANUALLY':
        response = 'Years';
        break;
      default:
        response = 'Days';
    }
    return response;
  };

  const handleCreatePolicy = async (
    e,
    handleCreatePolicyMutation,
    clientId,
  ) => {
    e.preventDefault();

    if (
      !createPolicyInput.name.value ||
      !createPolicyInput.repaymentType.value ||
      !createPolicyInput.durationType.value ||
      !createPolicyInput.lateRepaymentTypeCalcBy.value ||
      !createPolicyInput.interestRateCalcBy.value
    ) {
      createPolicyInput.showToast.setValue(true);
      createPolicyInput.content.setValue(
        'Please fill all required (*) field input',
      );

      handleHideToast(e);

      return;
    }

    await handleCreatePolicyMutation({
      variables: {
        clientId: clientId.clientInfo.clientId,
        name: createPolicyInput.name.value,
        processingFeeDebitMethod:
          createPolicyInput.processingFeeDebitMethod.value,
        repaymentType: createPolicyInput.repaymentType.value,
        minLoanAmount: parseFloat(
          removeCommas(createPolicyInput.minLoanAmount.value) || 0,
        ),
        maxLoanAmount: parseFloat(
          removeCommas(createPolicyInput.maxLoanAmount.value) || 0,
        ),
        minLoanDuration: parseInt(createPolicyInput.minLoanDuration.value || 0),
        maxLoanDuration: parseInt(createPolicyInput.maxLoanDuration.value || 0),
        lateRepaymentTypeValue: parseFloat(
          createPolicyInput.lateRepaymentTypeValue.value || 0,
        ),
        interestRateValue: parseFloat(
          createPolicyInput.interestRateValue.value,
        ),
        lateRepaymentTypeCalcBy:
          createPolicyInput.lateRepaymentTypeCalcBy.value,
        interestRateCalcBy: createPolicyInput.interestRateCalcBy.value,
        maxLateFeeDuration: parseInt(
          createPolicyInput.maxLateFeeDuration.value || 0,
        ),
      },
    });
  };

  const [handleCreatePolicyMutation, { loading, error }] = useMutation(
    CREATE_POLICY_MUTATION,
    {
      async onCompleted() {
        createPolicyInput.showToast.setValue(true);
        createPolicyInput.content.setValue('Policy Created Successfully.');

        handleHideToast();
        handleReset();
      },
    },
  );

  return (
    <OriginateClient
      render={({ clientId }) =>
        render({
          loading,
          error,
          handleHideToast: e => handleHideToast(e),
          handleCreatePolicy: e =>
            handleCreatePolicy(e, handleCreatePolicyMutation, clientId),
          policyInput: createPolicyInput,
          enumData,
          loanDescription,
        })
      }
    />
  );
};

export default withRouter(CreatePolicy);
