import React from 'react';

const CloseIcon = props => (
  <svg width={10} height={10} fill="none" stroke="#07F" {...props}>
    <path
      d="M9 1L1 9M1 1l8 8"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseIcon;
