export const baseRoutes = {
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    resetPasswordToken: '/reset-password/:token',
  },
  loanProducts: '/loan-products',
  applications: '/applications',
  loans: '/loans',
  reports: '/reports',
  dashboard: '/dashboard',
  customers: '/customers',
};
