import { PopoverTrigger } from '@/components/PopoverTrigger';
import { ArrowRightIcon } from '@/icons/ArrowRightIcon';
import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  Portal,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import {
  Calendar,
  CalendarControls,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarValues,
  CalendarWeek,
} from '@uselessdev/datepicker';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import { enGB } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { Input, InputProps } from '../Input';
import { calendarTheme } from './theme';

type DateRangeValues = {
  start: string;
  end: string;
};

type DateRangePickerCalendarProps = Pick<
  Calendar,
  | 'disableWeekends'
  | 'disablePastDates'
  | 'disableFutureDates'
  | 'allowSelectSameDay'
>;

type DateRangePickerInputProps = Omit<InputProps, 'onChange'>;

type DateRangePickerProps = DateRangePickerInputProps &
  DateRangePickerCalendarProps & {
    values: DateRangeValues;
    onSelectDate: (dates: DateRangeValues) => void;
  };

const DateRangePicker = ({
  values = {
    start: '',
    end: '',
  },
  onSelectDate,
  disableWeekends,
  disablePastDates,
  disableFutureDates,
  allowSelectSameDay = true,
  ...props
}: DateRangePickerProps) => {
  const [dates, setDates] = React.useState<CalendarValues>({});
  const [inputValues, setInputValues] = useState(values);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const calendarRef = React.useRef(null);

  const handleSelectDate = (selectedDates: CalendarValues) => {
    setDates({ start: selectedDates.start, end: selectedDates.end });

    const start = isValid(selectedDates.start)
      ? format(selectedDates.start, 'dd-MM-yyyy')
      : '';
    const end = isValid(selectedDates.end)
      ? format(selectedDates.end, 'dd-MM-yyyy')
      : '';

    onSelectDate?.({ start, end });
  };

  const match = (value: string) =>
    RegExp(/(\d{2})-(\d{2})-(\d{4})/).exec(value);

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  useEffect(() => {
    setInputValues(values);

    const newDates = { ...dates };

    if (match(values.start)) {
      const startDate = parse(values?.start, 'dd-MM-yyyy', new Date());

      const isValidStartDate = isValid(startDate);
      const isAfterEndDate = dates.end && isAfter(startDate, dates.end);

      if (isValidStartDate && !isAfterEndDate) {
        newDates.start = startDate;
      }
    } else {
      newDates.start = undefined;
    }

    if (match(values.end)) {
      const endDate = parse(values?.end, 'dd-MM-yyyy', new Date());

      const isValidEndDate = isValid(endDate);
      const isBeforeStartDate = dates.start && isBefore(endDate, dates.start);

      if (isValidEndDate && !isBeforeStartDate) {
        newDates.end = endDate;
      }
    } else {
      newDates.end = undefined;
    }

    setDates(newDates);
  }, [values]);

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
      initialFocusRef={initialRef}
      isLazy
    >
      <PopoverTrigger>
        <Box onClick={onOpen} ref={initialRef}>
          <Flex
            px={3}
            border="1px solid"
            alignItems="center"
            justifyContent="space-between"
            borderColor={isOpen ? 'blue.500' : 'gray.200'}
          >
            <Input
              px={0}
              my="-1px"
              border="none"
              isReadOnly
              {...props}
              value={inputValues.start}
              placeholder="Start Date"
            />

            <ArrowRightIcon w={5} h={5} />

            <Input
              px={0}
              my="-1px"
              border="none"
              isReadOnly
              {...props}
              textAlign="right"
              value={inputValues.end}
              placeholder="End Date"
            />
          </Flex>
        </Box>
      </PopoverTrigger>

      <Portal>
        <PopoverContent
          p={0}
          w="min-content"
          border="none"
          outline="none"
          _focus={{ boxShadow: 'none' }}
          ref={calendarRef}
        >
          <ChakraProvider theme={calendarTheme} cssVarsRoot="#date-range">
            <Box color="gray.700" id="date-range">
              <Calendar
                locale={enGB}
                value={dates}
                highlightToday
                allowOutsideDays
                onSelectDate={handleSelectDate}
                disableWeekends={disableWeekends}
                disablePastDates={disablePastDates}
                disableFutureDates={disableFutureDates}
                allowSelectSameDay={allowSelectSameDay}
              >
                <PopoverBody p={0}>
                  <CalendarControls>
                    <CalendarPrevButton />
                    <CalendarNextButton />
                  </CalendarControls>

                  <CalendarMonths>
                    <CalendarMonth>
                      <CalendarMonthName />
                      <CalendarWeek />
                      <CalendarDays />
                    </CalendarMonth>
                  </CalendarMonths>

                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    pt={2}
                    pb={4}
                  >
                    <Button
                      size="sm"
                      variant="link"
                      colorScheme="red"
                      _focus={{}}
                      _focusVisible={{
                        boxShadow: 'outline',
                      }}
                      onClick={() => {
                        handleSelectDate({
                          start: undefined,
                          end: undefined,
                        });

                        onClose();
                      }}
                    >
                      Clear date
                    </Button>
                  </Flex>
                </PopoverBody>
              </Calendar>
            </Box>
          </ChakraProvider>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default DateRangePicker;
