import { useLazyQuery } from '@apollo/react-hooks';
import React from 'react';
import { Loader } from '../components';
import { EXPORT_TO_CSV } from '../graphql/queries';
import { trimGraphQLError } from '../lib/Utils';
import { useKeyPair } from '../Providers-new/Form';

const useExportToCSV = ({ children, exportType, fileName = 'NewFile' }) => {
  const inputs = {
    showToast: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      inputs.showToast.setValue(false);
    }, 5000);
  };
  const onError = error => {
    inputs.showToast.setValue(true);
    inputs.status.setValue('Failed');
    inputs.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const showToast = () => {
    inputs.showToast.setValue(true);
    inputs.status.setValue('Success');
    inputs.content.setValue('CSV Exported Successfully');
    handleHideToast();
  };

  const [openFile, { loading, error }] = useLazyQuery(EXPORT_TO_CSV, {
    variables: {
      input: {
        exportType: exportType,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted({ exportToCsv }) {
      props.exportToCsv = exportToCsv;
      props.fileUrl = `${exportToCsv.mimeType}${exportToCsv.dataUrl}`;
      const blob = base64toBlob(exportToCsv.dataUrl, exportToCsv.mimeType);
      const url = window.URL.createObjectURL(blob);
      fileName = `${fileName}_${Math.floor(Date.now() / 1000)}`;

      const link = document.createElement('a');
      link.href = url;
      link.style = 'visibility:hidden';
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      showToast();
    },
    onError,
  });

  const exportToCsv = searchQuery => {
    openFile({
      variables: {
        input: {
          exportType: exportType,
          searchQuery,
        },
      },
    });
  };

  const props = {
    loading,
    error,
    inputs,
    handleHideToast,
    fetchExportData: {},
    fileUrl: '',
    exportToCsv,
  };

  if (loading) {
    return <Loader />;
  }

  const base64toBlob = (base64Str, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64Str);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  return children(props);
};

export default useExportToCSV;
