import { useQuery } from '@apollo/react-hooks';
import { GET_LOAN_CATEGORY_ATTRIBUTES } from './queries';

const useGetLoanCategoryAttribute = () => {
  let output = {
    getLoanCategoryAttributeResponse: [],
  };

  const qResult = useQuery(GET_LOAN_CATEGORY_ATTRIBUTES);
  const { data, loading, error } = qResult;

  if (!loading && data && data.getLoanCategoryAttributes) {
    output = {
      getLoanCategoryAttributeResponse: data.getLoanCategoryAttributes,
    };
  }

  return {
    loading,
    error,
    ...output,
    result: qResult,
  };
};

export { useGetLoanCategoryAttribute };
