import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { trimGraphQLError } from '../../lib/Utils';
import { toast } from 'react-toastify';
import Constants from '../../lib/Constants';
import { GET_BANKS } from '../../graphql/queries';

const useGetBanks = () => {
  const {
    data,
    loading: fetchBanksIsLoading,
    error: fetchBanksError,
  } = useQuery(GET_BANKS);

  useEffect(() => {
    if (fetchBanksError) {
      toast.error(
        trimGraphQLError(
          fetchBanksError?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
  }, [fetchBanksError]);

  return {
    banks: data?.getBanks || [],
    fetchBanksError,
    fetchBanksIsLoading,
  };
};

export { useGetBanks };
