import { gql } from 'apollo-boost';

export const CREATE_POLICY_MUTATION = gql`
  mutation MP_CreatePolicyMutation(
    $name: String!
    $durationType: DurationType
    $repaymentType: DurationType!
    $minLoanAmount: Float!
    $maxLoanAmount: Float!
    $minLoanDuration: Int!
    $maxLoanDuration: Int!
    $interestRateCalcBy: RateCalcBy!
    $interestRateName: String
    $interestRateValue: Float!
    $lateRepaymentTypeCalcBy: RateCalcBy!
    $lateRepaymentTypeName: String
    $lateRepaymentTypeValue: Float!
    $maxLateFeeDuration: Int!
    $processingFeeDebitMethod: PolicyProcessingFeeDebitMethod
  ) {
    policyCreate(
      input: {
        isDefault: false
        name: $name
        status: DRAFT
        durationType: $durationType
        repaymentType: $repaymentType
        minLoanAmount: $minLoanAmount
        maxLoanAmount: $maxLoanAmount
        minLoanDuration: $minLoanDuration
        maxLoanDuration: $maxLoanDuration
        interestRate: {
          name: $interestRateName
          calcBy: $interestRateCalcBy
          value: $interestRateValue
        }
        processingFeeDebitMethod: $processingFeeDebitMethod
        lateRepaymentRate: {
          name: $lateRepaymentTypeName
          calcBy: $lateRepaymentTypeCalcBy
          value: $lateRepaymentTypeValue
        }
        maxLateFeeDuration: $maxLateFeeDuration
      }
    ) {
      record {
        name
        id
        status
        minLoanAmount
        maxLoanAmount
        minLoanDuration
        maxLoanDuration
      }
    }
  }
`;

export const CHANGE_APPLICATION_POLICY_MUTATION = gql`
  mutation MP_ChangeApplicationPolicy($policyId: ID!, $applicationId: ID!) {
    changeApplicationPolicy(
      input: { applicationId: $applicationId, policyId: $policyId }
    ) {
      success
    }
  }
`;

export const POLICY_DELETE_MUTATION = gql`
  mutation MP_PolicyDelete($id: ID!) {
    policyDelete(id: $id) {
      success
    }
  }
`;

export const POLICY_ENABLE_MUTATION = gql`
  mutation MP_PoliceEnable($id: ID!) {
    policyEnable(id: $id) {
      recordId
      status
    }
  }
`;

export const POLICY_DISABLE_MUTATION = gql`
  mutation MP_PoliceDisable($id: ID!) {
    policyDisable(id: $id) {
      recordId
      status
    }
  }
`;
