import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  DataCard,
  Card,
  Button,
  Input,
  BreadCrumbSection,
  Toast,
  Loader,
  TextArea,
  ReactSelect,
  CheckBoxGroup,
  CheckBox,
  EmptyScreen,
} from '../../components';
import {
  useMutateCreateLoanGroup,
  useGetLoanCategories,
} from '../../Providers-new/LoanGroup';
import {
  capitalizeInitialLetter,
  renameSupportingDocuments,
  insertSpaceBeforeCapitalLetter,
} from '../../lib/Utils';
import { ErrorBoundary } from 'react-error-boundary';
import { DeleteIcon, Plus } from '../../components/Icons';

const CreateLoanGroup = ({ match, loanCategoryResults }) => {
  let loanCategories = loanCategoryResults?.data
    ? loanCategoryResults?.data?.getLoanCategories?.categories
    : [];

  // Get category name
  const categoryName = match.params.categoryName
    ? match.params.categoryName
    : null;

  let categoryInView =
    loanCategories != null
      ? loanCategories?.find(({ name }) => name === categoryName)
      : null;

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    products: [],
  });

  const updateLoanCategoryInput = e => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const {
    data,
    loading,
    handleHideToast,
    handleChangeLoanProducts,
    policies,
    prefilledLoanProducts,
    activePoliciesLoading,
    isEditMode,
    handleSumbitLoanGroup,
    handleChange,
    loanCategoryAttributeData,
    getAllAttributeLoading,
    addNewStep,
    stepState,
    removeStep,
    handleStepChange,
    handleRepaymentServicesChange,
    isUpdateLoanCategoryLoading,
  } = useMutateCreateLoanGroup({ categoryName, categoryInView, formData });

  const { name, description } = formData;

  const attributeData =
    loanCategoryAttributeData != null
      ? loanCategoryAttributeData.find(
          attrib => attrib.name === 'requiredSupportingDocuments',
        )
      : null;

  const [supportingDocsChecked, setSupportingDocsChecked] = useState(false);
  const [otherDocsChecked, setOtherDocsChecked] = useState(false);
  const [editedAttributes, setEditedAttributes] = useState(false);
  const [repaymentServices, setRepaymentServices] = useState([]);
  const [checkedRepaymentServices, setCheckedRepaymentServices] = useState([]);
  const [checkedDirectDebit, setCheckedDirectDebit] = useState(false);
  const directDebitServices = ['REMITA', 'RECOVA'];

  const updateRepaymentServices = useCallback(data => {
    setRepaymentServices(data);
  }, []);

  useEffect(() => {
    const isDireceDebitChecked = checkedRepaymentServices.some(r =>
      directDebitServices.includes(r),
    );

    setCheckedDirectDebit(isDireceDebitChecked);
  }, [checkedRepaymentServices]);

  useEffect(() => {
    handleRepaymentServicesChange(checkedRepaymentServices);
  }, [checkedRepaymentServices]);

  const handleRepaymentServiceChange = e => {
    const { value } = e.target;
    const currentChecked = [...checkedRepaymentServices];

    const index = currentChecked.indexOf(value);

    if (index === -1) {
      currentChecked.push(value);
    } else {
      currentChecked.splice(index, 1);
    }

    setCheckedRepaymentServices(currentChecked);
  };

  useEffect(() => {
    if (!categoryInView) return;

    const findRepaymentServices = categoryInView?.loanCategoryAttributes.find(
      attrib => attrib?.attribute.name === 'repaymentServices',
    );

    const currentRepaymentServices = [];
    findRepaymentServices?.data?.map(v =>
      currentRepaymentServices.push(v.name),
    );

    setCheckedRepaymentServices(currentRepaymentServices);
  }, [categoryInView]);

  useEffect(() => {
    const repaymentServiceAttributes = data.allAttributesData.value.find(
      item => item?.name === 'repaymentServices',
    );

    updateRepaymentServices(repaymentServiceAttributes);
  }, [data?.allAttributesData, updateRepaymentServices]);

  useEffect(() => {
    if (categoryInView) {
      setFormData({
        name: categoryInView.name,
        description: categoryInView.description,
        products: categoryInView.products,
      });

      const processDocData = (attributeInViewData, categoryInViewData) => {
        if (
          attributeInViewData.data === null ||
          categoryInViewData.data === null
        )
          return;
        const resultSet = [];

        for (let i = 0; i < categoryInViewData.data.length; i++) {
          resultSet.push(categoryInViewData.data[i].documentName);
        }

        setSupportingDocsChecked(resultSet);
        return resultSet;
      };

      const transformLoanCatAttribData = () => {
        if (categoryInView.length === 0) {
          return null;
        }
        const categoryInViewData = categoryInView.loanCategoryAttributes.find(
          custAttrib =>
            custAttrib.attribute.name === 'requiredSupportingDocuments',
        );
        setEditedAttributes(categoryInViewData);

        if (attributeData != null && categoryInViewData != null) {
          return processDocData(attributeData, categoryInViewData);
        }
      };

      transformLoanCatAttribData();
      let attribute = categoryInView.loanCategoryAttributes.map(item => ({
        ...item.attribute,
        value: item.value,
      }));
      setOtherDocsChecked(attribute);
    }
  }, [categoryInView, attributeData]);

  return (
    <>
      <BreadCrumbSection />
      <Fragment>
        {isEditMode && (
          <Toast
            title={'Update Loan Category'}
            content={data?.content?.value}
            classes={data?.status?.value}
            active={data?.showToast?.value}
            clickEvent={handleHideToast}
          />
        )}

        <ErrorBoundary
          fallback={
            <DataCard noPadding={false}>
              <EmptyScreen message="Error Fetching Loan Categories" />
            </DataCard>
          }
        >
          <form>
            <div className="form-card create-approval-workflow">
              <DataCard>
                <div className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Basic Information">
                      This is where you define the name and a description that
                      applicants will see on the loan application form
                    </Card>
                  </div>
                  <div className="form-fields">
                    <div className="flex-container">
                      <div className="form-fields">
                        <Card title="Name" required>
                          <Input
                            placeholder="Name of loan category"
                            required
                            type="text"
                            name="name"
                            value={name || ''}
                            onChange={updateLoanCategoryInput}
                          />
                        </Card>
                      </div>
                    </div>

                    <div className="flex-container">
                      <div className="form-fields desc-note">
                        <Card title="Description" Required>
                          <TextArea
                            placeholder="Description of loan category"
                            type="text"
                            name="description"
                            value={description}
                            onChange={updateLoanCategoryInput}
                          />
                          <span className="desc-note">
                            Note: To add a new line, type the ~ sign at the end
                            of each line.
                          </span>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </DataCard>

              <DataCard>
                <div className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Loan Products">
                      This is where you link loan products to the Loan Category.
                    </Card>
                  </div>
                  <div className="form-fields">
                    <Card>
                      <div className="multi-select controlled-multi-select">
                        <ReactSelect
                          maxMenuHeight="150px"
                          name="products"
                          value={prefilledLoanProducts}
                          options={policies}
                          isMulti
                          onChange={handleChangeLoanProducts}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
              </DataCard>

              <DataCard>
                <div className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Loan Attributes">
                      This is where you configure the attributes of the loan
                      category
                    </Card>
                  </div>
                  <div className="form-fields">
                    {data?.allAttributesData?.value?.length >= 1 && (
                      <Card>
                        <CheckBoxGroup>
                          {data.allAttributesData.value.map(item => {
                            return (
                              <label key={item?.name}>
                                {item.name !== 'requiredSupportingDocuments' &&
                                  item.name !== 'repaymentServices' && (
                                    <CheckBox
                                      label={renameSupportingDocuments(
                                        capitalizeInitialLetter(
                                          insertSpaceBeforeCapitalLetter(
                                            item?.name,
                                          ),
                                        ),
                                      )}
                                      id={item?.id}
                                      value={item?.name}
                                      classes="other-docs"
                                      onChange={e => handleChange(e)}
                                      defaultChecked={
                                        categoryInView &&
                                        (() => {
                                          return (
                                            categoryInView &&
                                            otherDocsChecked?.some(
                                              attr =>
                                                attr?.id === item?.id &&
                                                attr?.value === 'true',
                                            )
                                          );
                                        })()
                                      }
                                    />
                                  )}
                              </label>
                            );
                          })}
                        </CheckBoxGroup>

                        <CheckBoxGroup>
                          <section className="checkbox-flex-wrapper">
                            <p>Configure Repayment Service(s)</p>
                            {repaymentServices?.data?.map((item, index) => (
                              <div key={index} className="checkbox-flex">
                                <CheckBox
                                  label={`Requires ${capitalizeInitialLetter(
                                    item?.name,
                                  )} ${
                                    item?.name === 'CARD'
                                      ? '(Paystack)'
                                      : 'Direct Debit'
                                  }`}
                                  value={item?.name}
                                  classes="repayment-services"
                                  disabled={
                                    item?.name !== 'CARD' &&
                                    checkedDirectDebit &&
                                    !checkedRepaymentServices.includes(
                                      item.name,
                                    )
                                  }
                                  onChange={handleRepaymentServiceChange}
                                  defaultChecked={
                                    categoryInView &&
                                    checkedRepaymentServices?.includes(
                                      item?.name,
                                    )
                                  }
                                />
                              </div>
                            ))}
                          </section>
                        </CheckBoxGroup>

                        <CheckBoxGroup>
                          {!isEditMode ||
                          (editedAttributes?.data &&
                            editedAttributes.data[0]?.documentName) ? (
                            <p>Required Supporting Documents</p>
                          ) : (
                            ''
                          )}
                          {isEditMode
                            ? editedAttributes?.data?.map((item, index) =>
                                item?.documentName !== null ? (
                                  <CheckBox
                                    key={index}
                                    label={item?.documentName}
                                    value={item?.documentName}
                                    classes="supporting-docs"
                                    defaultChecked={
                                      categoryInView &&
                                      supportingDocsChecked &&
                                      (() => {
                                        return supportingDocsChecked.some(
                                          doc => doc === item?.documentName,
                                        );
                                      })()
                                    }
                                  />
                                ) : (
                                  ''
                                ),
                              )
                            : data.allAttributesData?.value?.map(
                                (item, index) => {
                                  return (
                                    <label key={index}>
                                      {item?.name ===
                                        'requiredSupportingDocuments' &&
                                        item?.data?.map(
                                          (supportingDoc, index) => {
                                            return (
                                              <CheckBox
                                                key={index}
                                                label={
                                                  supportingDoc?.documentName
                                                }
                                                id={item.id}
                                                value={
                                                  supportingDoc?.documentName
                                                }
                                                classes="supporting-docs"
                                                onChange={e =>
                                                  handleChange(e, item?.id)
                                                }
                                              />
                                            );
                                          },
                                        )}
                                    </label>
                                  );
                                },
                              )}
                        </CheckBoxGroup>
                      </Card>
                    )}
                  </div>
                </div>

                <>
                  <div className="form-fields">
                    {!isEditMode ? (
                      <p className="add-supp-doc">Add Supporting Document(s)</p>
                    ) : (
                      <p className="add-supp-doc">
                        Add More Supporting Document(s)
                      </p>
                    )}
                    {stepState?.length >= 1 &&
                      stepState?.map((val, idx) => {
                        const documentNameId = `documentName-${idx}`;
                        const descriptionId = `description-${idx}`;
                        return (
                          <Fragment key={idx}>
                            <div
                              className="remove-step"
                              onClick={() => removeStep(idx)}
                            >
                              <DeleteIcon color={'#C1292E'} />
                            </div>
                            <div className="flex-container" key={`${idx}`}>
                              <div className="form-fields step-text">
                                <Card title={`${idx + 1}`} />
                              </div>
                              <div className="form-fields">
                                <Card title="Document Name">
                                  <Input
                                    name={documentNameId}
                                    type="text"
                                    id={documentNameId}
                                    placeholder="Add a document Name"
                                    data-idx={idx}
                                    onChange={handleStepChange}
                                    classes="documentName"
                                    data-set="documentName"
                                    value={stepState[idx]?.documentName || ''}
                                  />
                                </Card>
                              </div>
                              <div className="form-fields">
                                <Card title="Description">
                                  <Input
                                    name={descriptionId}
                                    type="text"
                                    id={descriptionId}
                                    data-idx={idx}
                                    onChange={handleStepChange}
                                    data-set="description"
                                    classes="description"
                                    placeholder="Document Description"
                                    value={stepState[idx]?.description || ''}
                                  />
                                </Card>
                              </div>
                              {/* <div className="form-fields">
                                  <Card title="Supporting Document Level">
                                    <Select
                                      name={supportingDocLevelId}
                                      data-idx={idx}
                                      id={supportingDocLevelId}
                                      onChange={handleStepChange}
                                      className="supportingDocLevel"
                                      data-set="supportingDocLevel"
                                    >
                                      <option>-- Select --</option>
                                      {supportingDocLevel?.enumValues?.map(
                                        (item, key) => (
                                          <option key={key} value={item.name}>
                                            {removeUnderscore(item.name)}
                                          </option>
                                        ),
                                      )}
                                    </Select>
                                  </Card>
                                </div> */}
                            </div>
                          </Fragment>
                        );
                      })}
                  </div>

                  <div className="form-fields">
                    <div
                      className="flex-container add-step"
                      onClick={() => addNewStep()}
                    >
                      <Plus /> Add new supporting document
                    </div>
                  </div>
                </>
              </DataCard>

              <div className="flex-container justify-end">
                <Link to="/loan-categories">
                  <Button classes="primary" type="submit">
                    Cancel
                  </Button>
                </Link>
                {loading ||
                activePoliciesLoading ||
                getAllAttributeLoading ||
                isUpdateLoanCategoryLoading ? (
                  <Loader loadingText={'...loading'} />
                ) : (
                  <Button
                    classes="secondary"
                    click_event={handleSumbitLoanGroup}
                  >
                    {isEditMode ? 'Update' : 'Create'}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </ErrorBoundary>
      </Fragment>
    </>
  );
};

const CreateLoanGroupWrapper = ({ match }) => {
  const { loading: loanCategoryLoading, result: loanCategoryResults } =
    useGetLoanCategories();

  if (loanCategoryLoading) return <Loader loadingText="...loading" />;
  return (
    <CreateLoanGroup match={match} loanCategoryResults={loanCategoryResults} />
  );
};

export default withRouter(CreateLoanGroupWrapper);
