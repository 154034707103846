import React, { Fragment } from 'react';
import {
  Button,
  DataCard,
  Popup,
  Toast,
  Loader,
} from '../../../../components/index';
import { UpdateExternallyDisbursedLoanProvider } from '../../../../Providers/index';

const UpdateExternallyDisbursedLoan = ({ portfolioData }) => {
  return (
    <Fragment>
      <div className="manual-approval">
        <UpdateExternallyDisbursedLoanProvider
          portfolio={portfolioData.portfolio}
          render={({
            input,
            handleHideToast,
            externaldisbursmentLoader,
            updateExternalDisbursment,
            showUpdateExternalDisbursmentPopup,
            hideUpdateExternalDisbursmentPopup,
          }) => (
            <Fragment>
              {externaldisbursmentLoader && <Loader />}
              <Toast
                title="Mark as Disbursed"
                content={input.content.value}
                classes={input.status.value}
                active={input.showToast.value}
                clickEvent={handleHideToast}
              />
              <Button
                classes="right green"
                click_event={showUpdateExternalDisbursmentPopup}
              >
                Mark Loan as Disbursed
              </Button>

              <Popup
                title={'Are you sure you want to mark loan as disbursed?'}
                classes="cash-repayment"
                active={input.showPopup.value}
              >
                <DataCard noPadding={true}>
                  <div className="flex-container space-between pad">
                    <Button
                      click_event={updateExternalDisbursment}
                      classes="right green"
                    >
                      Accept
                    </Button>
                    <Button click_event={hideUpdateExternalDisbursmentPopup}>
                      Cancel
                    </Button>
                  </div>
                </DataCard>
              </Popup>
            </Fragment>
          )}
        />
      </div>
    </Fragment>
  );
};

export default UpdateExternallyDisbursedLoan;
