import React, { Fragment } from 'react';
import { Document } from '../../../../components/Icons';
import {
  insertSpaceBeforeTwoConsecutiveCapitalLetters,
  removeUnderscoreAndCapitalizeInitialLetters,
} from '../../../../lib/Utils';
import { UrlFromS3Provider } from '../../../../Providers';

const SupportingDocumentsTab = ({
  hasApplicationMeta,
  portfolioData,
  applicationMetaKey,
}) => {
  return (
    <Fragment>
      <div className={'supporting-doc__wrapper wrap'}>
        {hasApplicationMeta &&
          applicationMetaKey
            ?.filter(item => item.document != null)
            ?.map(({ document, key }) => {
              return document != null ? (
                <Fragment>
                  <UrlFromS3Provider file={document.file}>
                    {({ loading, exportFromS3 }) => (
                      <div className="supporting-doc__card">
                        <div className={'icon'}>
                          <Document />
                        </div>
                        <div className={'title'}>
                          {insertSpaceBeforeTwoConsecutiveCapitalLetters(key)}
                        </div>
                        <div className={'desc'}>
                          <button
                            className={'viewFile'}
                            onClick={exportFromS3}
                            disabled={loading}
                          >
                            View File
                          </button>
                        </div>
                      </div>
                    )}
                  </UrlFromS3Provider>
                </Fragment>
              ) : (
                ''
              );
            })}

        {portfolioData?.portfolio?.application?.reviewFeedbacks?.map(item => {
          const document = item.documentRequests;
          const status = item.status === 'COMPLETED';
          return document.map(item => {
            return status && item.document ? (
              <div className="supporting-doc__card">
                <div className={'icon'}>
                  <Document />
                </div>
                <UrlFromS3Provider file={item.document.file}>
                  {({ exportFromS3, fileUrl, loading }) => (
                    <Fragment>
                      <div className={'title'}>
                        {removeUnderscoreAndCapitalizeInitialLetters(
                          item.document.documentName,
                        )}
                      </div>
                      <div className={'desc'}>
                        <button
                          className={'viewFile'}
                          onClick={exportFromS3}
                          disabled={loading}
                        >
                          {fileUrl ? 'View File ' : 'Pending'}
                        </button>
                      </div>
                    </Fragment>
                  )}
                </UrlFromS3Provider>
              </div>
            ) : (
              <div className="flexStart supporting-doc__card">
                <div className={'icon'}>
                  <Document />
                </div>
                <div className={'title'}>{item.title}</div>
                <div className={'desc center-text'}>Pending</div>
              </div>
            );
          });
        })}
      </div>
    </Fragment>
  );
};

export default SupportingDocumentsTab;
