import { graphql } from '@/__generated/gql';

export const GET_WALLET_BALANCE = graphql(/* GraphQL */ `
  query WalletBalance {
    walletBalance {
      currency
      balance
    }
  }
`);
