import React from 'react';

const DeleteIcon = props => (
  <svg width={20} height={21} viewBox="0 0 20 21" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09003 4.8594V4.46045C8.09003 4.24139 8.31143 4.06283 8.58321 4.06283H11.3507C11.6232 4.06283 11.8446 4.24139 11.8446 4.46045V4.8594H8.09003ZM14.0844 5.85451C14.0268 7.35803 13.6695 16.5704 13.6244 16.9853C13.5979 17.2335 13.5064 17.3557 13.4573 17.4048H6.47657C6.42685 17.3557 6.33604 17.2335 6.30952 16.9853C6.26511 16.5704 5.8654 7.36467 5.80043 5.85451H14.0844ZM12.8349 4.85942V4.46047C12.8349 3.69178 12.2138 3.06714 11.4495 3.06714H8.48444C7.72014 3.06714 7.09902 3.69178 7.09902 4.46047V4.85942H4.1333V5.85447H4.80944L5.32383 17.347C5.33245 17.3895 5.55319 18.4005 6.40764 18.4005H13.525C14.3801 18.4005 14.6008 17.3895 14.6141 17.3145L15.1 5.85447H15.8V4.85942H12.8349ZM12.6332 6.65188H11.6422L11.3333 16.6084H12.3243L12.6332 6.65188ZM9.471 16.6085H10.462V6.65199H9.471V16.6085ZM8.59987 16.6084L8.29097 6.65188H7.29997L7.60821 16.6084H8.59987Z"
      fill={props.color || '#0077FF'}
    />
  </svg>
);

export default DeleteIcon;
