import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';

import {
  DataCard,
  Card,
  Button,
  Input,
  BreadCrumbSection,
  Toast,
  Notification,
  Loader,
  ReactSelect,
} from '../../components';
import { CompleteIcon } from '../../components/Icons';

import {
  useMutateClientAccountUser,
  useRolesAndPermissions,
  useClientAcountUser,
} from '../../Providers-new/UserManagement';
import { useClientInfo } from '../../Providers-new/Client';

import './_UserManagement.scss';

const CreateUser = ({ params }) => {
  const { clientInfo } = useClientInfo();
  const { permissions, roles } = useRolesAndPermissions();

  const { clientAccountUsers } = useClientAcountUser({
    variables: {
      where: {
        clientId: clientInfo.clientId,
        email: params.userEmail || '',
      },
    },
  });

  const {
    data,
    form,
    loading,
    handleHideToast,
    handleCreateClientAccountUser,
    selectedRoles,
    updateSelectedRoles,
  } = useMutateClientAccountUser({
    roles,
    permissions,
    clientId: clientInfo.clientId,
    accountUser: clientAccountUsers[0],
  });

  return (
    <>
      <BreadCrumbSection />
      <Fragment>
        <Toast
          title="Create User"
          content={data.content.value}
          classes={data.status.value}
          active={data.showToast.value}
          clickEvent={handleHideToast}
        />
        {data.showNotificationPopup.value && (
          <Notification title="Success">
            <div className="notification_icon-holder">
              <CompleteIcon />
            </div>
            <h5 className={'center-text'}>{'User Created Successfully'}</h5>
            <p className="email-prompt">
              Invite mail has been sent to
              <span className="email">{`${form.emailAddress.input.value}`}</span>
              . Kindly prompt user to check their email for more info.
            </p>
          </Notification>
        )}
        <form onSubmit={handleCreateClientAccountUser}>
          <div className="form-card top-border user-creation">
            <DataCard>
              <div className="flex-container space-between">
                <div className="form-desc">
                  <Card title="Basic Information">
                    Define basic information
                  </Card>
                </div>

                <div className="form-fields">
                  <div className="flex-container">
                    <div className="form-fields">
                      <Card title="Email Address" required>
                        <Input
                          placeholder="shola@indicina.co"
                          required
                          type="text"
                          {...form.emailAddress.input}
                        />
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </DataCard>

            <DataCard>
              <div className="flex-container space-between">
                <div className="form-desc">
                  <Card title="Role & Privileges">What they can do</Card>
                </div>
                <div className="form-fields">
                  <Card title="Select a role" required>
                    <div className={'wrapper'}>
                      <ReactSelect
                        options={roles.map(i => ({
                          value: i.id,
                          label: i.name,
                        }))}
                        name="userRoles"
                        isMulti
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                        }}
                        value={selectedRoles}
                        maxMenuHeight="120px"
                        onChange={updateSelectedRoles}
                        menuPortalTarget={document?.body}
                        menuPlacement="bottom"
                        menuPosition="absolute"
                      />
                    </div>
                  </Card>
                </div>
              </div>
            </DataCard>

            <div className="flex-container justify-end">
              <Link to="/settings/user-management">
                <Button classes="primary" type="submit">
                  Cancel
                </Button>
              </Link>
              {loading ? (
                <Loader />
              ) : (
                <Button classes="secondary" type="submit">
                  Create User
                </Button>
              )}
            </div>
          </div>
        </form>
      </Fragment>
    </>
  );
};

const CreateUserWrapper = ({ match: { params } }) => {
  const { loading } = useRolesAndPermissions();
  const { loading: ciLoading } = useClientInfo();

  if (loading || ciLoading) return <Loader />;
  return <CreateUser params={params} />;
};

export default withRouter(CreateUserWrapper);
