import * as React from 'react';

const CircleCheckIcon = props => (
  <svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
    <circle cx={14} cy={14} r={14} fill="#6FCF97" fillOpacity={0.24} />
    <path d="M8 16l3 3L21 9" stroke="#6FCF97" strokeLinecap="round" />
  </svg>
);

export default CircleCheckIcon;
