import { gql } from 'apollo-boost';

export const fragments = {
  user: gql`
    fragment UserDetails on User {
      id
      email
      firstName
      lastName
    }
  `,
};

export const OFFLINE_REPAYMENTS_QUERY = gql`
  query MP_OfflineRepaymentsQuery(
    $cursor: ConnectionCursor
    $first: ConnectionLimitInt
    $id: ID
    $email: String
    $name: String
    $status: String
    $amountLte: Float
    $amountGte: Float
    $createdAtLte: DateTime
    $createdAtGte: DateTime
    $portfolioID: ID
  ) {
    offlineRepayments(
      first: $first
      after: $cursor
      orderBy: createdAt_DESC
      where: {
        id: $id
        status: $status
        amount_lte: $amountLte
        amount_gte: $amountGte
        createdAt_lte: $createdAtLte
        createdAt_gte: $createdAtGte
        portfolioID: $portfolioID
        customer: { email_contains: $email, fullName_contains: $name }
      }
    ) @connection(key: "offlineRepayments") {
      nodes {
        id
        amount
        user {
          ...UserDetails
        }
        portfolio {
          id
          amountPaid
          fullAmount
          portfolioNumber
          user {
            ...UserDetails
          }
          status {
            name
          }
        }
        metadata
        bank {
          name
        }
        status
        supportingDocument {
          id
          fileUrl
          file {
            bucket
            key
          }
        }
        service
        createdAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }

  ${fragments.user}
`;
