import React from 'react';
import { withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { useCustomState } from '../lib/CustomHooks';
import { RESET_PASSWORD_MUTATION } from '../graphql/mutations';
import { Loader } from '../components';
import { trimGraphQLError } from '../lib/Utils';

const ResetPassword = ({ render, history, match: { params } }) => {
  const { resetToken } = params;

  const resetPwdInput = {
    password: useCustomState(),
    confirmPassword: useCustomState(),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  if (!resetToken || resetToken == null) {
    resetPwdInput.showToast.setValue(true);
    resetPwdInput.status.setValue('Failed');
    resetPwdInput.content.setValue('No valid token detected');
    return;
  }

  const handleResetPassword = (e, resetPwdMutation) => {
    e.preventDefault();

    if (resetPwdInput.password.value !== resetPwdInput.confirmPassword.value) {
      resetPwdInput.showToast.setValue(true);
      resetPwdInput.status.setValue('Failed');
      resetPwdInput.content.setValue("Passwords don't match");

      handleHideToast();
      return;
    }

    if (!resetPwdInput.password.value || !resetPwdInput.confirmPassword.value) {
      resetPwdInput.showToast.setValue(true);
      resetPwdInput.status.setValue('Failed');
      resetPwdInput.content.setValue('Please fill all required fields');

      handleHideToast();
      return;
    }

    resetPwdMutation({
      variables: {
        password: resetPwdInput.password.value,
        resetToken,
      },
    });
  };

  const handleHideToast = () => {
    setTimeout(() => {
      resetPwdInput.showToast.setValue(false);
    }, 2500);
  };

  return (
    <Mutation
      mutation={RESET_PASSWORD_MUTATION}
      onCompleted={({ resetPassword: { ok } }) => {
        if (ok) {
          history.push('/password-reset-complete');
        }
      }}
      onError={error => {
        resetPwdInput.showToast.setValue(true);
        resetPwdInput.status.setValue('Failed');
        resetPwdInput.content.setValue(trimGraphQLError(error.message));
      }}
    >
      {(resetPwdMutation, { loading }) => {
        if (loading) {
          return <Loader />;
        }

        return render({
          onSubmit: e => handleResetPassword(e, resetPwdMutation),
          input: resetPwdInput,
          handleHideToast,
        });
      }}
    </Mutation>
  );
};

export default withRouter(ResetPassword);
