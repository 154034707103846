import React from 'react';
import PropTypes from 'prop-types';
import './_NewRadio.scss';

const NewRadio = ({ label, classes, ...restProps }) => {
  return (
    <label className={'new-radio'}>
      <input
        className={`${classes == null ? '' : classes}`}
        type="radio"
        {...restProps}
      />
      <span className={`radio__label ${classes == null ? '' : classes}`}>
        {label}
      </span>
    </label>
  );
};

NewRadio.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
};

export default NewRadio;
