export default {
  errorMessages: {
    NETWORK_ERROR:
      'An error has occurred. Please check your internet connection or try again.',
    NO_EXPORT_DATA: 'Nothing to export',
  },
  csvNames: {
    LOANS: 'LOANS',
    APPLICATIONS: 'APPLICATIONS',
    CUSTOMERS: 'CUSTOMERS',
    MY_BANK_STATEMENT: 'BANK STATEMENT',
    POLICIES: 'CREDIT POLICIES',
    PORTFOLIOS: 'CUSTOMER PORTFOLIOS',
    DISBURSEMENT_WALLET: 'DISBURSEMENT_WALLET',
    INTEREST_WALLET: 'INTEREST_WALLET',
    COLLECTIONS: 'COLLECTIONS',
    MANUAL_REPAYMENTS: 'MANUAL_REPAYMENTS',
    APPLICABLE_APPLICATIONS: 'APPLICABLE_APPLICATIONS',
    CREDIT_BUREAU_SCORE_REPORT: 'CREDIT BUREAU SCORE REPORT',
    MODEL_RESPONSE: 'MODEL_RESPONSE',
    BANK_STATEMENT_ANALYSIS: 'BANK_STATEMENT_ANALYSIS',
    OKRA_SUMMARY: 'OKRA_SUMMARY',
    CREDIT_REGISTRY_REPORT: 'CREDIT_REGISTRY_REPORT',
  },
  SERVICE_CHARGE_TRANSACTION_TYPE: 'Service Charge',
  DISBURSEMENT_TRANSACTION_TYPE: 'Disbursement',
};
