import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const Page = ({ component, title }) => {
  const PageComponent = component;

  return (
    <Fragment>
      <Helmet title={title} />

      <PageComponent />
    </Fragment>
  );
};

export default Page;
