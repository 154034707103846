import { CUSTOMERS } from '@/graphql/queries/customers';
import { parseIsoDate } from '@/utils/formatNumber';
import { CustomerWhereInput } from '@/__generated/graphql';
import { useQuery } from '@apollo/client';
import { omitBy, isEmpty } from 'lodash';

export type CustomersFilterType = {
  customerName?: string;
  customerEmail?: string;
  customerId?: string;
  customerPhone?: string;
  createdAfter?: string;
  createdBefore?: string;
  customerLoanStatus?: string;
  createdDates?: {
    start: string;
    end: string;
  };
};

export const useGetCustomersList = (filterParams: CustomersFilterType) => {
  const processedParams = processCustomerFilterParams(filterParams);

  const { data, loading, error, refetch, fetchMore, networkStatus } = useQuery(
    CUSTOMERS,
    {
      variables: {
        where: processedParams,
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onError: () => {},
    },
  );

  const loadingInitialCustomers = networkStatus === 1; // networkStatus 1 indicates initial loading
  const fetchingMoreCustomers = networkStatus === 3; // networkStatus 3 indicates fetching more

  const fetchMoreCustomers = () => {
    fetchMore({
      variables: {
        after: data?.mp?.customers?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const mergedData = {
          ...fetchMoreResult,
          mp: {
            ...fetchMoreResult.mp,
            customers: {
              ...fetchMoreResult.mp.customers,
              edges: [
                ...prev.mp.customers.edges,
                ...fetchMoreResult.mp.customers.edges,
              ],
            },
          },
        };

        return mergedData;
      },
    });
  };

  return {
    customersError: error,
    refetchCustomers: refetch,
    customersLoading: loading,
    customersList: data?.mp?.customers?.edges,
    customersCount: data?.mp?.customers?.totalCount,
    loadingInitialCustomers,
    fetchingMoreCustomers,
    fetchMoreCustomers,
  };
};

export const processCustomerFilterParams = (
  params: CustomersFilterType,
): CustomerWhereInput => {
  const customerParams = {
    email_contains: params.customerEmail,
    fullName_contains: params.customerName,
    phone_contains: params.customerPhone,
    name_contains: params.customerId,
    status: params.customerLoanStatus,
  };
  const customer: CustomerWhereInput = omitBy(customerParams, isEmpty);

  const createdAt_gte = parseIsoDate(params.createdAfter, 'early');
  const createdAt_lt = parseIsoDate(params.createdBefore, 'late');

  const parsedData: CustomerWhereInput = {
    ...customer,
    createdAt_lt,
    createdAt_gte,
  };

  return omitBy(parsedData, isEmpty);
};
