import React, { Fragment } from 'react';
import { DataCard, Table } from '../../../../components';
import { nairaAmount, ratioToPercent } from '../../../../lib/Utils';

const IncomeAnalysisTab = ({ incomeAnalysis }) => {
  const {
    averageSalary,
    medianIncome,
    lastSalaryDate,
    averageOtherIncome,
    confidenceIntervalonSalaryDetection,
    numberSalaryPayments,
    numberOtherIncomePayments,
    expectedSalaryDay,
    salaryEarner,
    salaryFrequency,
  } = incomeAnalysis || {};
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Table>
            <div className="table__body">
              {incomeAnalysis && Object.keys(incomeAnalysis).length ? (
                <ul>
                  <li>
                    <span className="decide-analysis-span">
                      Income Analysis
                    </span>
                  </li>
                  <li>
                    <span>Average Salary</span>
                    <span className="right-text">
                      {averageSalary != null
                        ? nairaAmount(averageSalary)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Median Monthly Income</span>
                    <span className="right-text">
                      {medianIncome != null ? nairaAmount(medianIncome) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Last Salary Date</span>
                    <span className="right-text">
                      {lastSalaryDate != null ? lastSalaryDate : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Expected Income</span>
                    <span className="right-text">
                      {averageSalary != null && averageOtherIncome != null
                        ? nairaAmount(averageSalary + averageOtherIncome)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Average Other Income</span>
                    <span className="right-text">
                      {averageOtherIncome != null
                        ? nairaAmount(averageOtherIncome)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Confidence Interval on Salary Detection</span>
                    <span className="right-text">
                      {confidenceIntervalonSalaryDetection != null
                        ? ratioToPercent(confidenceIntervalonSalaryDetection)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Number of Salary Payments</span>
                    <span className="right-text">
                      {numberSalaryPayments != null
                        ? numberSalaryPayments
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Number of Other Income Payments</span>
                    <span className="right-text">
                      {numberOtherIncomePayments != null
                        ? numberOtherIncomePayments
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Expected Salary Day</span>
                    <span className="right-text">
                      {expectedSalaryDay != null ? expectedSalaryDay : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Salary Earner</span>
                    <span className="right-text">
                      {salaryEarner != null ? salaryEarner : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Number of Salary Payments/Month</span>
                    <span className="right-text">
                      {salaryFrequency != null ? salaryFrequency : '__'}
                    </span>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default IncomeAnalysisTab;
