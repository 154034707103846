import React from 'react';

const Document = props => (
  <svg width={38} height={43} viewBox="0 0 38 43" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 0H12a3 3 0 00-2.54 1.421l-8 8.008A3.004 3.004 0 000 12.012v27.026a3.001 3.001 0 003 3.003h32c1.657 0 3-1.344 3-3.003V3.003A3.001 3.001 0 0035 0zM9 6.126v2.883H6.12L9 6.126zm26 32.912H3V12.112h.06l.06-.06H12V3.163l.06-.06v-.06h22.9L35 39.038z"
      fill="#4C5C6D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29 23.023H9a1.5 1.5 0 000 3.002h20a1.5 1.5 0 000-3.002zM26 30.03H9a1.5 1.5 0 000 3.002h17a1.5 1.5 0 000-3.003zM22 19.019h6c1.657 0 3-1.345 3-3.003V10.01a3.001 3.001 0 00-3-3.003h-6c-1.657 0-3 1.344-3 3.003v6.006a3.001 3.001 0 003 3.003zm0-9.01h6v6.007h-6V10.01z"
      fill="#4C5C6D"
    />
  </svg>
);

export default Document;
