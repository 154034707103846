import React from 'react';
import PropTypes from 'prop-types';
import './_TextArea.scss';

const TextArea = ({ label, classes, errorMessage, ...restProps }) => {
  return (
    <label className={'textarea__group'}>
      {label == null ? '' : <span className={'textarea__label'}>{label}</span>}

      <textarea
        className={`textarea ${classes == null ? '' : classes}`}
        {...restProps}
      />
      {errorMessage && (
        <span className={'textarea__error'}>{errorMessage}</span>
      )}
    </label>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default TextArea;
