import { useState } from 'react';

export const useCustomState = (initialValue = '', options = {}) => {
  const { type } = options;
  const [value, setValue] = useState(initialValue);

  let input;

  switch (type) {
    case 'checkbox':
      input = {
        onClick: e => {
          setValue(e.target.checked);
        },
        checked: value,
      };
      break;
    case 'radio':
      input = selectedValue => ({
        onChange: e => setValue(e.target.value),
        value: selectedValue,
      });
      break;
    default:
      input = {
        onChange: e => setValue(e.target.value),
        value,
      };
      break;
  }

  return {
    input,
    value,
    options,
    setValue,
    initialValue,
  };
};
