import { gql } from 'apollo-boost';

const APPLICATION_FRAGMENT = `
    on Application {
        id
        applicationNumber
        source
        user {
          id
          firstName
          lastName
          email
          phone
          isBvnVerified
          blacklistDetails {
            blacklisted
            isInternalBlacklist
            reports {
              id
              reason
              isSameEmail
              isSamePhone
              isSameBvn
              createdAt
              actionBy {
                id
                firstName
                lastName
              }
            }
          }
        }
        policy {
          id
          name
          status
          durationType
          interestRate {
            value
            type
          }
        }
        loanCategory {
          id
          name
        }
        bankAccount {
          id
          bank { name }
          accountName
        }
        moratoriumData {
          numberOfPeriods
          repaymentType
        }
        policyDetails{
          interestRateValue
        }
        amount
        status {
          name
        }
        meta {
          key
          value
          document {
            id
            uploadType
            file {
              key
              bucket
            }
          }
        }
        amount
        baseAmount
        taxAmount
        submittedAt
        createdAt
        chargesAmount
        fullAmount
        loanDuration
        processingFee
        dateOfRepayment
        requiredSteps
        completedSteps
        traces {
          nodes {
            id
            page
            comment
            createdAt
          }
        }
        credit {
           bankStatement
           okraSummary {
              availableBalance
              totalDebit
              totalCredit
              accountName
              accountNumber
              transactions
              bankName
           }
           crcScoreReport
           crcMicroScoreReport
           creditRegistryReport
           creditBureauScoreReport
           decideBankStatementAnalysis
           modelResults {
            name
            modelResponse
            bankStatementAnalysis
          }
        }
        uploadedBankStatement {
          url
          password
        }
        approvalWorkflow {
            id
            workflow {
                id
                name
                status
                isCustom
                steps {
                    id
                    approvalType
                }
            }
            nextStep {
                id
                approvalType
                orderNo
            }
        }
        histories (
            where: $where
            after: $cursor
            first: $first
        ) @connection(key: "applicationHistoryConnection"){
            pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
            nodes {
              id
              newStatus { name }
              oldStatus { name }
              comment
              actionBy {
                firstName
                lastName
              }
              createdAt
            }
        }
        portfolio {
            id
            portfolioNumber
            user{
                id
                firstName
                lastName
                email
            }
            repayments {
                id
                interestPortion
                principalPortion
                principalBalance
                repaymentDate
                totalPayment
                dueDate
            }
        }
        reviewFeedbacks {
          id
          status
          reviewType
          documentRequests {
            id
            title
            message
            document {
                id
                fileUrl
                documentName
                file {
                  key
                  bucket
                }
            }
          }
        }
        assignedTo {
          accountUserId
          firstName
          lastName
        }
        account {
          id
          bankAccounts {
            id
            bank { name }
          }
        }
    }
`;

export const APPLICATION_BY_ID_QUERY = gql`
    query MP_ApplicationByIdQuery($id: ID!) {
        applicationById(id: $id) {
            ...${APPLICATION_FRAGMENT}
        }
    }
`;

export const APPLICATION_QUERY = gql`
    query MP_Application(
    $applicationNumber: String!
    $where: ApplicationHistoryWhereInput
    $first: ConnectionLimitInt
    $cursor: ConnectionCursor
    ) {
      application(applicationNumber: $applicationNumber) {
        ...${APPLICATION_FRAGMENT}
      }
    }
`;
