import { useMutation } from '@apollo/react-hooks';
import { CLOSE_OUT_LOAN_BEFORE_DISBURSEMENT } from './queries';
import { useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { PORTFOLIO_QUERY } from '../../graphql/queries';

const useMutateCloseOutLoanBeforeDisbursement = ({
  portfolioId,
  portfolioNumber,
  comment,
  setComment,
}) => {
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3000);
  };

  const closeOutLoanBeforeDisbursementToggle = () => {
    data.showModal.setValue(!data.showModal.value);
    setComment('');
  };

  const [closeOutLoanBeforeDisbursement, { loading }] = useMutation(
    CLOSE_OUT_LOAN_BEFORE_DISBURSEMENT,
    {
      onCompleted: () => {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Loan has been closed successfully');
        data.showModal.setValue(false);

        handleHideToast();
        setComment('');
      },
      onError: error => {
        data.showToast.setValue(true);
        data.status.setValue('Failed');
        data.content.setValue(trimGraphQLError(error.message));

        handleHideToast();
        closeOutLoanBeforeDisbursementToggle();
      },
    },
  );

  const handleCloseOutLoanBeforeDisbursement = async e => {
    e.preventDefault();

    await closeOutLoanBeforeDisbursement({
      variables: {
        input: {
          portfolioId,
          comment,
        },
      },
      refetchQueries: () => [
        {
          query: PORTFOLIO_QUERY,
          variables: {
            portfolioNumber,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return {
    handleHideToast,
    loading,
    closeOutLoanBeforeDisbursementToggle,
    data,
    handleCloseOutLoanBeforeDisbursement,
  };
};

export { useMutateCloseOutLoanBeforeDisbursement };
