import { Box, Grid, Stack, Text } from '@chakra-ui/layout';
import React, { ReactNode } from 'react';

type FieldsetProps = {
  label: string;
  description?: string;
  children: ReactNode;
};

const Fieldset = ({ label, description, children }: FieldsetProps) => {
  return (
    <Grid
      as="fieldset"
      gap={{ base: 6, lg: 10 }}
      gridTemplateColumns={{ lg: '3fr 7fr' }}
    >
      <Stack>
        <Text as="legend" fontWeight="semibold">
          {label}
        </Text>

        {!!description && (
          <Text color="gray.500" maxW={{ base: null, lg: 64 }}>
            {description}
          </Text>
        )}
      </Stack>

      <Box maxW={{ lg: 'xl' }}>{children}</Box>
    </Grid>
  );
};

export default Fieldset;
