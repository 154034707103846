import { graphql } from '@/__generated/gql';

export const DISABLE_LOAN_PRODUCT = graphql(/* GraphQL */ `
  mutation DisableLoanProduct($id: ID!) {
    policyDisable(id: $id) {
      recordId
      status
    }
  }
`);
