import React from 'react';
import {
  Button,
  Card,
  DataCard,
  Input,
  Loader,
  Toast,
} from '../../../components';
import { useMerchantConfig } from '../../../Providers-new/Configuration';

const MerchantConfiguration = () => {
  const {
    handleUpdateAttributes,
    loading,
    ciLoading,
    inputs,
    isDisabled,
    data,

    handleHideToast,
  } = useMerchantConfig();

  return (
    <>
      {(loading || ciLoading) && <Loader loadingText="loading..." />}
      <Toast
        title="Update Configurations"
        content={data.content.value}
        classes={data.status.value}
        active={data.showToast.value}
        clickEvent={handleHideToast}
      />

      <DataCard>
        <div className="flex-container space-between">
          <div className="form-desc">
            <Card title="Configurations" />
          </div>

          <div className="form-fields">
            <div className="flex-container">
              <div className="form-fields">
                <Card title="Slug" />
                <Input
                  placeholder="Enter Slug"
                  type="text"
                  {...inputs.clientSlug.input}
                  disabled
                />
              </div>
            </div>
            <div className="grid-container">
              <div className="form-fields">
                <Card title="Primary Color" />
                <Input
                  placeholder="Enter Primary Color"
                  type="color"
                  style={{ height: '36px' }}
                  required
                  {...inputs.primaryColor.input}
                />
              </div>
              <div className="form-fields">
                <Card title="Secondary Color" />
                <Input
                  placeholder="Enter Secondary Color"
                  type="color"
                  style={{ height: '36px' }}
                  required
                  {...inputs.secondaryColor.input}
                />
              </div>

              <div className="form-fields">
                <Card title="Support Phone" />
                <Input
                  placeholder="Enter Support Phone"
                  required
                  phoneField
                  maxLength={11}
                  {...inputs.phone.input}
                />
              </div>

              <div className="form-fields">
                <Card title="Support Email" />
                <Input
                  placeholder="Enter Support Email"
                  type="email"
                  required
                  {...inputs.email.input}
                />
              </div>

              <div className="form-fields">
                <Card title="App URL" />
                <Input
                  placeholder="Enter App URL"
                  type="url"
                  required
                  disabled
                  readOnly
                  {...inputs.clientAppUrl.input}
                />
              </div>
            </div>
            <div className="flex-container">
              <Button
                classes="secondary space-left"
                click_event={handleUpdateAttributes}
                disabled={isDisabled}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </DataCard>
    </>
  );
};

export default MerchantConfiguration;
