import React, { Fragment } from 'react';
import { Button, DataCard, Export, Table, Toast } from '../../../../components';
import { nairaAmount } from '../../../../lib/Utils';

const OkraSummaryTab = ({
  data,
  handleExportToOkraSummary,
  handleHideToast,
  input,
}) => {
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Button
            classes="right"
            click_event={() => {
              handleExportToOkraSummary();
            }}
          >
            <Export />
            <span className="export-csv">Export CSV</span>
          </Button>
          <Toast
            title="Export CSV"
            content={input.content.value}
            clickEvent={handleHideToast}
            active={input.showToast.value}
            classes={input.status.value}
          />
          <Table>
            <div className="table__body">
              <ul>
                <li>
                  <span>Bank Name</span>
                  <span className="right-text">
                    {data.application.credit.okraSummary.bankName != null
                      ? data.application.credit.okraSummary.bankName
                      : 'N/A'}
                  </span>
                </li>
                <li>
                  <span>Account Name</span>
                  <span className="right-text">
                    {data.application.credit.okraSummary.accountName != null
                      ? data.application.credit.okraSummary.accountName
                      : 'N/A'}
                  </span>
                </li>
                <li>
                  <span>Account Number</span>
                  <span className="right-text">
                    {data.application.credit.okraSummary.accountNumber != null
                      ? data.application.credit.okraSummary.accountNumber
                      : 'N/A'}
                  </span>
                </li>
                <li>
                  <span>Available Balance</span>
                  <span className="right-text">
                    {data.application.credit.okraSummary.availableBalance !=
                    null
                      ? nairaAmount(
                          data.application.credit.okraSummary.availableBalance,
                        )
                      : 'N/A'}
                  </span>
                </li>
                <li>
                  <span>Total Credit</span>
                  <span className="right-text">
                    {data.application.credit.okraSummary.totalCredit != null
                      ? nairaAmount(
                          data.application.credit.okraSummary.totalCredit,
                        )
                      : 'N/A'}
                  </span>
                </li>
                <li>
                  <span>Total Debit</span>
                  <span className="right-text">
                    {data.application.credit.okraSummary.totalDebit != null
                      ? nairaAmount(
                          data.application.credit.okraSummary.totalDebit,
                        )
                      : 'N/A'}
                  </span>
                </li>
              </ul>
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default OkraSummaryTab;
