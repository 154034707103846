import { gql } from 'apollo-boost';

export const fragments = {
  application: gql`
    fragment ApplicationDetails on Application {
      id
    }
  `,

  portfolio: gql`
    fragment PortfolioDetails on Portfolio {
      id
    }
  `,
};

export const COMPLETE_APPLICATION_REVIEW = gql`
  mutation MP_CompleteApplicationReviewMutation(
    $applicationId: ID!
    $comment: String
  ) {
    completeApplicationReview(
      input: { applicationId: $applicationId, comment: $comment }
    ) {
      approved
      application {
        ...ApplicationDetails
      }
    }
  }

  ${fragments.application}
`;

export const ADVANCE_APPLICATION_WORKFLOW_STEP = gql`
  mutation MP_AdvanceApplicationWorkflowStep($input: ApplicationStageInput!) {
    advanceApplicationWorkflowStep(input: $input) {
      success
      application {
        ...ApplicationDetails
      }
    }
  }

  ${fragments.application}
`;

export const REVERT_APPLICATION_WORKFLOW_STEP = gql`
  mutation MP_RevertApplicationWorkflowStep($input: ApplicationStageInput!) {
    revertApplicationWorkflowStep(input: $input) {
      success
      application {
        ...ApplicationDetails
      }
    }
  }

  ${fragments.application}
`;

export const UPDATE_APPROVAL_WORKFLOW = gql`
  mutation MP_UpdateApprovalWorkflowMutation(
    $value: ApprovalWorkflowTemplate!
  ) {
    updateApprovalWorkflow(value: $value) {
      ok
    }
  }
`;

export const DISBURSE_LOAN_TO_BANK = gql`
  mutation MP_DisburseLoanToBankMutation($portfolioId: ID!) {
    disburseLoanToBank(input: { portfolioId: $portfolioId }) {
      portfolio {
        ...PortfolioDetails
      }
      message
      success
    }
  }

  ${fragments.portfolio}
`;
