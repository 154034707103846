import { withRouter } from 'react-router-dom';
import { trimGraphQLError, validateEmail } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { useKeyPair, useFormInput } from '../Providers-new/Form';
import { UPDATE_CLIENT_ACCOUNT_USER_DETAILS } from '../graphql/mutations';

const useUpdateClientAccountUserDetails = ({ render }) => {
  const { email, firstName, lastName } = localStorage;

  const data = {
    showToast: useKeyPair(false),
    content: useKeyPair(),
    status: useKeyPair(),
    showPopup: useKeyPair(false),
    comment: useKeyPair(),
    message: useKeyPair(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 2500);
  };

  const inputs = {
    email: useFormInput(email),
    firstName: useFormInput(firstName),
    lastName: useFormInput(lastName),
    password: useFormInput(),
    oldPassword: useFormInput(),
    confirmPassword: useFormInput(),
  };

  const handleUpdateClientUserDetails = (
    e,
    updateClientAccountUserMutation,
  ) => {
    e.preventDefault();

    if (inputs.password.value !== inputs.confirmPassword.value) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue("Passwords don't match");

      handleHideToast();
      return;
    }

    if (
      !inputs.email.value ||
      !inputs.firstName.value ||
      !inputs.lastName.value ||
      !inputs.password.value
    ) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please fill all required input');

      handleHideToast();

      return;
    }

    if (!validateEmail(inputs.email.value)) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please enter a valid email');

      handleHideToast();

      return;
    }

    updateClientAccountUserMutation({
      variables: {
        input: {
          email: inputs.email.value,
          firstName: inputs.firstName.value,
          lastName: inputs.lastName.value,
          password: inputs.password.value,
        },
      },
    });
  };

  const handleLogout = async () => {
    window.location.href = '/';
    localStorage.clear();
  };

  const [updateClientAccountUserMutation, { loading }] = useMutation(
    UPDATE_CLIENT_ACCOUNT_USER_DETAILS,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Successful');

        setTimeout(() => {
          handleLogout();
        }, 1500);
      },

      onError(error) {
        data.showToast.setValue(true);
        data.status.setValue('Failed');
        data.content.setValue(trimGraphQLError(error.message));
      },
    },
  );

  return render({
    handleHideToast,
    handleUpdateUserDetails: e =>
      handleUpdateClientUserDetails(e, updateClientAccountUserMutation),
    form: inputs,
    data,
    loading,
  });
};

export default withRouter(useUpdateClientAccountUserDetails);
