import React, { Fragment, useState } from 'react';
import {
  Button,
  DataCard,
  Popup,
  TextArea,
  Toast,
  Loader,
} from '../../../../components/index';
import { useAddApplicationComment } from '../../../../Providers-new/Applications/index';

const AddApplicationComment = ({ data }) => {
  const [comment, setComment] = useState('');
  const updateCommentInput = e => {
    const { value } = e.target;
    setComment(value);
  };

  const {
    addCommentToApplication,
    handleHideToast,
    hideAddApplicationCommentPopup,
    input,
    loading,
    showAddApplicationCommentPopup,
  } = useAddApplicationComment({
    application: data.application,
    comment,
    setComment,
  });

  return (
    <Fragment>
      <div className="manual-approval">
        {loading && <Loader />}
        <Toast
          title="Add Comment To Application"
          content={input.content.value}
          classes={input.status.value}
          active={input.showToast.value}
          clickEvent={handleHideToast}
        />
        <div className="flex-container space-between pad">
          <Button
            classes="button"
            style={{ marginLeft: '0px' }}
            click_event={showAddApplicationCommentPopup}
          >
            Add Comment
          </Button>
        </div>

        <Popup
          title={'Add a comment'}
          classes="cash-repayment"
          active={input.showPopup.value}
        >
          <DataCard noPadding={true}>
            <TextArea
              classes="text-area textAreaHeight"
              placeholder="Add Comment"
              type="text"
              name="comment"
              value={comment}
              onChange={updateCommentInput}
            />
            <div className="flex-container space-between pad">
              <Button
                click_event={addCommentToApplication}
                classes="right blue"
              >
                Add Comment
              </Button>
              <Button click_event={hideAddApplicationCommentPopup}>
                Cancel
              </Button>
            </div>
          </DataCard>
        </Popup>
      </div>
    </Fragment>
  );
};

export default AddApplicationComment;
