import { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import {
  CUSTOM_ROLE_UPDATE_MUTATION,
  CUSTOM_ROLE_DELETE_MUTATION,
  GET_AVAILABLE_ROLES_AND_PERMISSIONS,
} from './queries';
import { FormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';

const editCategory = 'EDIT_PERMISSIONS';
const viewCategory = 'VIEW_PERMISSIONS';

const useMutateClientCustomRole = ({
  roles = [],
  permissions = [],
  defaultSelectedRole,
}) => {
  const data = {
    viewPermissions: useKeyPair([]),
    editPermissions: useKeyPair([]),
    roleInView: useKeyPair(defaultSelectedRole || roles[0]),
    roleDescription: useKeyPair(''),
    showToast: useKeyPair(false),
    showNotificationPopup: useKeyPair(false),
    showDeleteNotificationPopup: useKeyPair(false),
    showConfirmedDeleteNotification: useKeyPair(false),
    showConfirmedUpdateNotification: useKeyPair(false),
    showUpdateNotificationPopup: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    roleName: FormInput(),
  };

  const history = useHistory();

  const permissionInputs = {};

  permissions.forEach(p => {
    permissionInputs[p.id] = FormInput(false, { type: 'checkbox' });
  });

  useEffect(() => {
    const editPermissions = [];
    const viewPermissions = [];

    permissions.forEach(p => {
      if (p.category === editCategory) editPermissions.push(p);
      if (p.category === viewCategory) viewPermissions.push(p);
    });

    data.viewPermissions.setValue(viewPermissions);
    data.editPermissions.setValue(editPermissions);
  }, [permissions]);

  const getRoleById = id => roles.find(r => r.id === id);
  const getPermissionById = id => permissions.find(p => p.id === id);

  const getInputPermissionObject = () => {
    const selectedPermissions = [];

    Object.entries(permissionInputs).forEach(
      ([permissionId, inputInfo]) =>
        inputInfo.value &&
        selectedPermissions.push(getPermissionById(permissionId).name),
    );

    return selectedPermissions;
  };

  useEffect(() => {
    if (data.roleInView.value) {
      const selectedRole = data.roleInView.value;
      inputs.roleName.setValue(selectedRole.name);
      data.roleDescription.setValue(selectedRole.description);

      // set all permissions to false
      permissions.forEach(p => {
        permissionInputs[p.id].setValue(false);
      });

      selectedRole.permissions.forEach(p => {
        permissionInputs[p.id].setValue(true);
      });
    }
  }, [data.roleInView.value]);

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 2500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const [runCustomRoleDeleteMutation] = useMutation(
    CUSTOM_ROLE_DELETE_MUTATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Deleted');
        data.showDeleteNotificationPopup.setValue(false);

        handleHideToast();
        history.push('/settings/roles');
      },
      onError,
    },
  );

  const [runCustomRoleUpdateMutation] = useMutation(
    CUSTOM_ROLE_UPDATE_MUTATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Updated');
        data.showUpdateNotificationPopup.setValue(false);
        handleHideToast();
        history.push('/settings/roles');
      },
      onError,
    },
  );

  const handleCustomRoleUpdate = async e => {
    e.preventDefault();

    const selectedPermissions = getInputPermissionObject();
    const roleId = data.roleInView.value.id;

    if (
      !inputs.roleName.value ||
      !selectedPermissions ||
      selectedPermissions.length < 1
    ) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please fill all required input');

      handleHideToast();
      return;
    }

    const variables = {
      id: roleId,
      name: inputs.roleName.value,
      permissions: selectedPermissions,
    };

    await runCustomRoleUpdateMutation({
      variables: {
        input: {
          ...variables,
        },
      },
      refetchQueries: () => [{ query: GET_AVAILABLE_ROLES_AND_PERMISSIONS }],
    });
  };

  const handleDeleteToggle = async e => {
    e.preventDefault();

    data.showDeleteNotificationPopup.setValue(
      !data.showDeleteNotificationPopup.value,
    );
  };

  const handleUpdateToggle = async e => {
    e.preventDefault();

    data.showUpdateNotificationPopup.setValue(
      !data.showUpdateNotificationPopup.value,
    );
  };

  const handleCustomRoleDelete = async e => {
    e.preventDefault();

    const roleInView = data.roleInView.value;

    data.showDeleteNotificationPopup.setValue(true);

    if (roleInView.id) {
      await runCustomRoleDeleteMutation({
        variables: {
          input: {
            id: roleInView.id,
          },
        },
        refetchQueries: () => [{ query: GET_AVAILABLE_ROLES_AND_PERMISSIONS }],
      });
    }
  };

  const handleFormSubmission = async e => {
    e.preventDefault();
  };

  const handleOnClickRole = roleId => {
    const selectedRole = getRoleById(roleId);
    data.roleInView.setValue(selectedRole);
  };

  return {
    handleFormSubmission,
    handleCustomRoleUpdate,
    handleCustomRoleDelete,
    handleOnClickRole,
    handleHideToast,
    handleDeleteToggle,
    handleUpdateToggle,
    form: inputs,
    permissionInputs,
    data,
  };
};

export { useMutateClientCustomRole };
