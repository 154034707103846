import React from 'react';
import PropTypes from 'prop-types';
import './_NewCheckBox.scss';

const NewCheckBox = ({ label, classes, ...restProps }) => {
  return (
    <div>
      <label className={'new-checkbox'}>
        <input
          className={`${classes == null ? '' : classes}`}
          type="checkbox"
          {...restProps}
        />
        {label && label.length > 0 && (
          <span className="checkbox__label">{label}</span>
        )}
      </label>
    </div>
  );
};

NewCheckBox.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
};

export default NewCheckBox;
