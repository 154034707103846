import React, { Fragment } from 'react';
import { DataCard, Table } from '../../../../components';
import { nairaAmount } from '../../../../lib/Utils';

const SpendAnalysisTab = ({ spendAnalysis }) => {
  const {
    spendOnTransfers,
    atmWithdrawalsSpend,
    airtime,
    bills,
    posSpend,
    averageRecurringExpense,
    bankCharges,
    cableTv,
    clubsAndBars,
    gambling,
    hasRecurringExpense,
    internationalTransactionsSpend,
    religiousGiving,
    ussdTransactions,
    utilitiesAndInternet,
    webSpend,
    totalExpenses,
  } = spendAnalysis || {};
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Table>
            <div className="table__body">
              {spendAnalysis != null && Object.keys(spendAnalysis).length ? (
                <ul>
                  <li>
                    <span className="decide-analysis-span">Spend Analysis</span>
                  </li>
                  <li>
                    <span>Spend on Transfers</span>
                    <span className="right-text">
                      {spendOnTransfers != null
                        ? nairaAmount(spendOnTransfers)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Spend on ATM Withdrawals</span>
                    <span className="right-text">
                      {atmWithdrawalsSpend != null
                        ? nairaAmount(atmWithdrawalsSpend)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Spend on Airtime</span>
                    <span className="right-text">
                      {airtime != null ? nairaAmount(airtime) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Spend on Bills</span>
                    <span className="right-text">
                      {bills != null ? nairaAmount(bills) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Spend on POS</span>
                    <span className="right-text">
                      {posSpend != null ? nairaAmount(posSpend) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Average Recurring Expense</span>
                    <span className="right-text">
                      {averageRecurringExpense != null
                        ? nairaAmount(averageRecurringExpense)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Bank Charges</span>
                    <span className="right-text">
                      {bankCharges != null ? nairaAmount(bankCharges) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>CableTv</span>
                    <span className="right-text">
                      {cableTv != null ? nairaAmount(cableTv) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Clubs And Bars</span>
                    <span className="right-text">
                      {clubsAndBars != null ? nairaAmount(clubsAndBars) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Gambling</span>
                    <span className="right-text">
                      {gambling != null ? nairaAmount(gambling) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Recurring Expense</span>
                    <span className="right-text">
                      {hasRecurringExpense != null ? hasRecurringExpense : '__'}
                    </span>
                  </li>
                  <li>
                    <span>International Transactions Spend</span>
                    <span className="right-text">
                      {internationalTransactionsSpend != null
                        ? nairaAmount(internationalTransactionsSpend)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Religious Giving</span>
                    <span className="right-text">
                      {religiousGiving != null
                        ? nairaAmount(religiousGiving)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>USSD Transactions</span>
                    <span className="right-text">
                      {ussdTransactions != null
                        ? nairaAmount(ussdTransactions)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Utilities And Internet</span>
                    <span className="right-text">
                      {utilitiesAndInternet != null
                        ? nairaAmount(utilitiesAndInternet)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Spend on Web</span>
                    <span className="right-text">
                      {webSpend != null ? nairaAmount(webSpend) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Total Expenses</span>
                    <span className="right-text">
                      {totalExpenses != null
                        ? nairaAmount(totalExpenses)
                        : '__'}
                    </span>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default SpendAnalysisTab;
