import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './_DateRange.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRange = ({ startDate, endDate, startDateInput, endDateInput }) => {
  const dateRangeValues = {
    sDate: startDateInput,
    eDate: endDateInput,
  };

  const handleStartDate = e => {
    startDate(e);
  };

  const handleEndDate = e => {
    endDate(e);
  };

  const handleChangeRaw = e => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <DatePicker
        selected={dateRangeValues.sDate}
        selectsStart
        startDate={dateRangeValues.sDate}
        endDate={dateRangeValues.eDate}
        onChange={handleStartDate}
        placeholderText={'Start Date'}
        dateFormat="dd/MM/yyyy"
        onChangeRaw={handleChangeRaw}
      />

      <DatePicker
        selected={dateRangeValues.eDate}
        selectsEnd
        startDate={dateRangeValues.sDate}
        endDate={dateRangeValues.eDate}
        onChange={handleEndDate}
        placeholderText={'End Date'}
        dateFormat="dd/MM/yyyy"
        onChangeRaw={handleChangeRaw}
      />
    </Fragment>
  );
};

DateRange.propTypes = {
  startDate: PropTypes.func,
  endDate: PropTypes.func,
};

export default DateRange;
