import { gql } from 'apollo-boost';

export const EDIT_REPAYMENT_MUTATION = gql`
  mutation MP_EditRepaymentMutation(
    $portfolioId: ID!
    $repayments: [EditRepaymentDataInput]!
  ) {
    editRepayment(
      input: { portfolioId: $portfolioId, repayments: $repayments }
    ) {
      status
      validation {
        repaymentId
        errorMessage
        index
      }
    }
  }
`;
