import React from 'react';
import { Button, Close, Modal } from '../../../../../../components';
import moment from 'moment';
import { nairaAmount } from '../../../../../../lib/Utils';

export const SaveRepaymentModal = ({
  isOpen,
  toggleModal,
  saveRepayment,
  repayments,
}) => (
  <Modal
    title="Preview Repayment Breakdown"
    classes="repayment-date-modal save-repayment-modal"
    active={isOpen}
  >
    <Close className="close-icon" onClick={toggleModal} />

    <div
      className="content center"
      style={{ fontSize: '1.7rem', lineHeight: '2', textAlign: 'center' }}
    >
      Please, preview your changes to the repayment schedule before saving
    </div>

    <ul className="save-repayment-preview">
      <li className="save-repayment-preview-row">
        <div className="save-repayment-preview__header">Principal Amount</div>
        <div className="save-repayment-preview__header">Interest Amount</div>
        <div className="save-repayment-preview__header">Late Fee</div>
        <div className="save-repayment-preview__header">Due Date</div>
      </li>

      {repayments.map((item, index) => (
        <li key={index} className="save-repayment-preview-row">
          <div>{nairaAmount(item?.newPrincipalAmount)}</div>
          <div>{nairaAmount(item?.newInterestAmount)}</div>
          <div>{nairaAmount(item?.newLateFee)}</div>
          <div>{moment(item?.newDueDate).format('ll')}</div>
        </li>
      ))}
    </ul>

    <div className="repayment-double-buttons" style={{ marginTop: '5rem' }}>
      <div
        style={{
          gap: '40px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Button
          type="button"
          classes="cancel"
          style={{ flex: 1 }}
          click_event={toggleModal}
        >
          Cancel
        </Button>

        <Button
          classes="secondary blue save"
          type="button"
          style={{ flex: 1 }}
          click_event={saveRepayment}
        >
          Save Changes
        </Button>
      </div>
    </div>
  </Modal>
);
