import { withRouter } from 'react-router-dom';
import { DISBURSE_LOAN_TO_BANK } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { PORTFOLIO_QUERY } from '../graphql/queries';

const DisburseLoanToBank = ({ render, portfolio }) => {
  const disburseLoanInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showPopup: useCustomState(false),
    comment: useCustomState(),
    message: useCustomState(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      disburseLoanInput.showToast.setValue(false);
    }, 4000);
  };

  const handleDisburseLoanToBank = (e, disburseLoanToBankMutation) => {
    e.preventDefault();

    disburseLoanToBankMutation({
      variables: {
        portfolioId: portfolio.id,
      },
      refetchQueries: () => [
        {
          query: PORTFOLIO_QUERY,
          variables: { portfolioNumber: portfolio.portfolioNumber },
        },
      ],
    });
  };

  const [disburseLoanToBankMutation, { loading }] = useMutation(
    DISBURSE_LOAN_TO_BANK,
    {
      async onCompleted() {
        disburseLoanInput.showToast.setValue(true);
        disburseLoanInput.status.setValue('Success');
        disburseLoanInput.content.setValue('Loan Disbursed');
        handleHideToast();
      },

      onError(error) {
        disburseLoanInput.showToast.setValue(true);
        disburseLoanInput.status.setValue('Failed');
        disburseLoanInput.content.setValue(trimGraphQLError(error.message));
        handleHideToast();
      },
    },
  );

  return render({
    handleHideToast,
    handleApproveReviewApplication: e =>
      handleDisburseLoanToBank(e, disburseLoanToBankMutation),
    input: disburseLoanInput,
    disburseLoanLoader: loading,
  });
};

export default withRouter(DisburseLoanToBank);
