import { useMutation } from '@apollo/client';
import { EnableLoanProductMutationVariables } from '@/__generated/graphql';
import { ENABLE_LOAN_PRODUCT } from '@/graphql/mutations/loan-products';
import { GET_LOAN_PRODUCTS } from '@/graphql/queries/loan-products';

export const useEnableLoanProduct = () => {
  const [mutate, { data, loading, error }] = useMutation(ENABLE_LOAN_PRODUCT, {
    update: (cache, { data: { policyEnable } }) => {
      const queryResult = cache?.readQuery({ query: GET_LOAN_PRODUCTS });
      const viewer = queryResult?.viewer;

      if (viewer) {
        const updatedPolicies = viewer.account.policies.nodes.map(policy => {
          if (policy.id === policyEnable?.recordId) {
            return { ...policy, status: policyEnable?.status };
          }
          return policy;
        });

        cache.writeQuery({
          query: GET_LOAN_PRODUCTS,

          data: {
            viewer: {
              ...viewer,
              account: {
                ...viewer.account,
                policies: {
                  ...viewer.account.policies,
                  nodes: updatedPolicies,
                },
              },
            },
          },
        });
      }
    },
  });

  const enableLoanProduct = (id: EnableLoanProductMutationVariables['id']) => {
    mutate({
      variables: {
        id,
      },
    });
  };

  return {
    enableLoanProduct,
    enableLoanProductError: error,
    enableLoanProductLoading: loading,
    enableLoanProductData: data?.policyEnable,
  };
};
