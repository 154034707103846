import { CUSTOMER_BVN_DETAILS } from '@/graphql/queries/customers/customerBvndetails';
import { useQuery } from '@apollo/client';

export const useGetCustomerBvnDetails = (bvn: string) => {
  const shouldQuery = bvn && bvn?.length == 11 && bvn !== null && bvn !== '';

  const { data, loading, error } = useQuery(CUSTOMER_BVN_DETAILS, {
    variables: {
      bvn,
    },
    notifyOnNetworkStatusChange: true,
    onError: () => {},
    errorPolicy: 'all',
    skip: !shouldQuery,
  });

  return {
    customerBvnDetailsError: error,
    customerBvnDetailsLoading: loading,
    customerBvnDetailsResponse: data?.getNewBvnDetails,
  };
};
