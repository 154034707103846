import React from 'react';
import moment from 'moment';
import {
  DataCard,
  Table,
  Button,
  Toast,
  Export,
  EmptyScreen,
} from '../../components';
import { ActionableCustomerApplications } from '../../Providers';
import { Link } from 'react-router-dom';
import {
  reduceStringLength,
  removeUnderscore,
  nairaAmount,
} from '../../lib/Utils';
import LoanSearch from './Search';
import LoanNavs from './LoanNavs';
import { loan_types } from '../../config/config';
import cx from 'classnames';
import './_Loans.scss';

const headers = {
  applicationHeader: [
    'Status',
    'Application ID',
    'Email',
    'Name',
    'Requested Amount',
    'Repayment Amount',
    'Requested Date',
  ],
};

const Portfolios = () => (
  <>
    <ActionableCustomerApplications
      render={({
        data,
        actionableApplicationsInput,
        actionableApplicationsPagination,
        actionableApplicationsReset,
        actionableApplicationsSearchQuery,
        actionableApplicationsExportToCsv,
        actionableApplicationsHideToast,
        handleStartDate,
        handleEndDate,
        handleRepaymentStartDate,
        handleRepaymentEndDate,
      }) => (
        <div className="layout-main ">
          <LoanNavs />

          <Toast
            title="Loans"
            content={actionableApplicationsInput.content.value}
            classes={actionableApplicationsInput.status.value}
            active={actionableApplicationsInput.showToast.value}
            clickEvent={actionableApplicationsHideToast}
          />
          <LoanSearch
            input={actionableApplicationsInput}
            resetAction={actionableApplicationsReset}
            searchAction={actionableApplicationsSearchQuery}
            type={loan_types.actionableApplications}
            handleLoanObjStartDate={handleStartDate}
            handleLoanObjEndDate={handleEndDate}
            handleRepaymentStartDate={handleRepaymentStartDate}
            handleRepaymentEndDate={handleRepaymentEndDate}
          />

          {data &&
          data.viewer &&
          data.viewer.account &&
          data.viewer.account.applicableCustomerApplications &&
          data.viewer.account.applicableCustomerApplications.nodes.length >
            0 ? (
            <div className="loans">
              <Button
                classes="right handle-export"
                click_event={actionableApplicationsExportToCsv}
              >
                <Export />
                <span className="export-csv">Export CSV</span>
              </Button>
              <DataCard noPadding={false}>
                <Table>
                  <div className="table__head">
                    {headers.applicationHeader.map((header, key) => (
                      <span
                        key={key}
                        className={cx({
                          Status: header,
                          'checked-width': header === 'Status',
                        })}
                      >
                        {' '}
                        {header}{' '}
                      </span>
                    ))}
                  </div>
                  <div className="table__body applications-table">
                    {data &&
                      data.viewer &&
                      data.viewer.account &&
                      data.viewer.account.applicableCustomerApplications &&
                      data.viewer.account.applicableCustomerApplications.nodes.map(
                        (customerApplication, key) => (
                          <ul key={key}>
                            <li>
                              <Link
                                to={`/v1/applications/${customerApplication.applicationNumber}/${customerApplication.user.email}`}
                              >
                                <span
                                  className="tooltip checked-width"
                                  data-title={removeUnderscore(
                                    customerApplication.status.name,
                                  )}
                                >
                                  <span
                                    className={`status fixed ${
                                      customerApplication.status.name.toLowerCase() ||
                                      ''
                                    }`}
                                  >
                                    {(() => {
                                      const statusName =
                                        customerApplication.status.name;
                                      switch (statusName) {
                                        case 'APPROVED':
                                          return 'APP';
                                        case 'DENIED':
                                          return 'DEN';
                                        case 'PENDING':
                                          return 'PEN';
                                        case 'UNDER_REVIEW':
                                          return 'REV';
                                        case 'ABANDONED':
                                          return 'ABA';
                                        default:
                                          return 'PEN';
                                      }
                                    })()}
                                  </span>
                                </span>
                                <span>
                                  <div className="first-td">
                                    {reduceStringLength(
                                      customerApplication.applicationNumber,
                                      12,
                                    )}
                                  </div>
                                </span>
                                <span>{customerApplication.user.email}</span>
                                <span>{`${customerApplication.user.firstName} ${customerApplication.user.lastName}`}</span>
                                <span>
                                  {nairaAmount(customerApplication.amount)}
                                </span>
                                <span>
                                  {nairaAmount(customerApplication.fullAmount)}
                                </span>
                                <span>
                                  {moment(customerApplication.createdAt).format(
                                    'lll',
                                  )}
                                </span>
                              </Link>
                            </li>
                          </ul>
                        ),
                      )}
                  </div>
                </Table>
              </DataCard>
              {data &&
                data.viewer &&
                data.viewer.account &&
                data.viewer.account.applicableCustomerApplications &&
                data.viewer.account.applicableCustomerApplications.pageInfo
                  .hasNextPage && (
                  <Button
                    classes={'secondary center load-more'}
                    click_event={actionableApplicationsPagination}
                  >
                    Load more
                  </Button>
                )}
            </div>
          ) : (
            <DataCard noPadding={false}>
              <EmptyScreen message="No Applications Yet" />
            </DataCard>
          )}
        </div>
      )}
    />
  </>
);

export default Portfolios;
