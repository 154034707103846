import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import {
  CUSTOM_ROLE_CREATE_MUTATION,
  GET_AVAILABLE_ROLES_AND_PERMISSIONS,
} from './queries';
import { FormInput, useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';

const MutateCreateCustomRole = ({ permissions = [], accountUser = {} }) => {
  const data = {
    isEditMode: useKeyPair(!!accountUser.id),
    showToast: useKeyPair(false),
    showNotificationPopup: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    name: useFormInput(),
  };

  const history = useHistory();

  const permissionInputs = {};

  for (let i = 0; i < permissions.length; i++) {
    permissionInputs[permissions[i].id] = FormInput(false, {
      type: 'checkbox',
    });
  }

  const getPermissionById = id => permissions.find(p => p.id === id);

  const getInputPermissionObject = () => {
    const selectedPermissions = [];

    Object.entries(permissionInputs).forEach(
      ([permissionId, inputInfo]) =>
        inputInfo.value &&
        selectedPermissions.push(getPermissionById(permissionId).name),
    );

    return selectedPermissions;
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 5000);
  };

  const handleHideNewToast = () => {
    setTimeout(() => {
      data.showNotificationPopup.setValue(false);
    }, 5000);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const getMutationVariables = () => {
    const permissions = getInputPermissionObject();

    if (!inputs.name.value || !permissions || permissions.length < 1) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please fill all required input');

      handleHideToast();
      return;
    }

    return {
      name: inputs.name.value,
      permissions,
    };
  };

  const handleCreateCustomRole = async e => {
    e.preventDefault();

    const variables = getMutationVariables();

    if (variables) {
      await createCustomRoleMutation({
        variables: {
          input: {
            ...variables,
          },
        },
        refetchQueries: () => [{ query: GET_AVAILABLE_ROLES_AND_PERMISSIONS }],
      });
    }
  };

  const [createCustomRoleMutation, { loading, error }] = useMutation(
    CUSTOM_ROLE_CREATE_MUTATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Created');
        data.showNotificationPopup.setValue(true);

        handleHideNewToast();
        history.push('/settings/roles');
      },
      onError,
    },
  );

  return {
    handleHideToast,
    loading,
    error,
    form: inputs,
    permissionInputs,
    data,
    handleCreateCustomRole,
  };
};

export { MutateCreateCustomRole };
