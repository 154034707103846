/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Card,
  CheckBoxGroup,
  Input,
  NewCheckBox,
  RadioGroup,
  NewRadio,
  ReactSelect,
} from '../../components';

const FormInputs = ({
  type = 'text',
  nigeriaStates = [],
  section,
  form = {},
  approvedEmploymentStatuses,
  underReviewEmploymentStatuses,
  handleWorkVerificationChange,
  workVerificationStatus,
  handleStateOfOriginChange,
  prefilledStatesApp,
  prefilledStatesUnr,
  handleBankHasMbsSupportChange,
  bankHasMbsSupportStatus,
  noHitStatus,
  handleNoHitStatusChange,
  sweepStatus,
  handleSweepChange,
  inflowOutFlowRateStatus,
  handleInflowOutFlowRateChange,
  salaryEarnerBSPStatus,
  handleSalaryEarnerBSPChange,
  approvedFirstCentralDelinquency,
  underReviewFirstCentralDelinquency,
  approvedCrcDelinquency,
  underReviewCrcDelinquency,
  pdfBankStatementDetailsMatchStatus,
  handlePdfBankStatementDetailsMatchChange,
  approvedBlacklistStatuses,
  underReviewBlacklistStatuses,
  approvedCreditRegistryDelinquency,
  underReviewCreditRegistryDelinquency,
  approvedBvnNinMatch,
  underReviewBvnNinMatch,
}) => {
  return (
    <>
      {type === 'age' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum age"
                  amountField={true}
                  {...form[`minAge`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum age"
                  amountField={true}
                  {...form[`maxAge`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'stateOfResidency' && section === 'app' && (
        <div className="form-fields">
          <Card>
            <div className="multi-select controlled-multi-select">
              <ReactSelect
                maxMenuHeight="150px"
                name="statesApp"
                value={prefilledStatesApp}
                options={nigeriaStates}
                isMulti
                onChange={selectOption =>
                  handleStateOfOriginChange({ section, value: selectOption })
                }
              />
            </div>
          </Card>
        </div>
      )}
      {type === 'stateOfResidency' && section === 'unr' && (
        <div className="form-fields">
          <Card>
            <div className="multi-select controlled-multi-select">
              <ReactSelect
                maxMenuHeight="150px"
                name="statesUnr"
                value={prefilledStatesUnr}
                options={nigeriaStates}
                isMulti
                onChange={selectOption =>
                  handleStateOfOriginChange({ section, value: selectOption })
                }
              />
            </div>
          </Card>
        </div>
      )}
      {type === 'employmentStatus' && section === 'app' && (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="Unemployed"
              label="Unemployed"
              {...approvedEmploymentStatuses.unemployed.input}
            />
            <NewCheckBox
              value="Self Employed"
              label="Self Employed"
              {...approvedEmploymentStatuses.self_employed.input}
            />
            <NewCheckBox
              value="Employed Part Time"
              label="Employed Part Time"
              {...approvedEmploymentStatuses.employed_part_time.input}
            />
            <NewCheckBox
              value="Employed Full Time"
              label="Employed Full Time"
              {...approvedEmploymentStatuses.employed.input}
            />
          </CheckBoxGroup>
        </Card>
      )}
      {type === 'employmentStatus' && section === 'unr' && (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="Unemployed"
              label="Unemployed"
              {...underReviewEmploymentStatuses.unemployed.input}
            />
            <NewCheckBox
              value="Self Employed"
              label="Self Employed"
              {...underReviewEmploymentStatuses.self_employed.input}
            />
            <NewCheckBox
              value="Employed Part Time"
              label="Employed Part Time"
              {...underReviewEmploymentStatuses.employed_part_time.input}
            />
            <NewCheckBox
              value="Employed Full Time"
              label="Employed Full Time"
              {...underReviewEmploymentStatuses.employed.input}
            />
          </CheckBoxGroup>
        </Card>
      )}
      {type === 'isWorkEmailConfirmed' && section === 'app' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_isWorkEmailConfirmed`}
              label="Yes"
              value="yes"
              checked={workVerificationStatus.approved === 'yes'}
              onChange={() =>
                handleWorkVerificationChange({ section, value: 'yes' })
              }
            />
            <NewRadio
              name={`${section}_isWorkEmailConfirmed`}
              label="No"
              value="no"
              checked={workVerificationStatus.approved === 'no'}
              onChange={() =>
                handleWorkVerificationChange({ section, value: 'no' })
              }
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'isWorkEmailConfirmed' && section === 'unr' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_isWorkEmailConfirmed`}
              label="Yes"
              value="yes"
              checked={workVerificationStatus.underReview === 'yes'}
              onChange={() =>
                handleWorkVerificationChange({ section, value: 'yes' })
              }
            />
            <NewRadio
              name={`${section}_isWorkEmailConfirmed`}
              label="No"
              value="no"
              checked={workVerificationStatus.underReview === 'no'}
              onChange={() =>
                handleWorkVerificationChange({ section, value: 'no' })
              }
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'dtiRatio' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum Ratio"
                  {...form[`minDTIRatio`].input}
                  name="minDTIRatio"
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum Ratio"
                  {...form[`maxDTIRatio`].input}
                  name="maxDTIRatio"
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'dtiRatioBS' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum ratio"
                  {...form[`minDTIRatioBs`].input}
                  name="minDTIRatioBs"
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum ratio"
                  {...form[`maxDTIRatioBs`].input}
                  name="maxDTIRatioBs"
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'bouncedCheckCount' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum count"
                  {...form[`minBouncedCheckCount`].input}
                  name="minBouncedCheckCount"
                  data-set={section}
                  amountField={true}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum count"
                  {...form[`maxBouncedCheckCount`].input}
                  name="maxBouncedCheckCount"
                  data-set={section}
                  amountField={true}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'percentageBetting' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Min % Betting"
                  {...form[`minPercentageBetting`].input}
                  name="minPercentageBetting"
                  data-set={section}
                  amountField={true}
                />
              </Card>
            </div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Max % Betting"
                  {...form[`maxPercentageBetting`].input}
                  name="maxPercentageBetting"
                  data-set={section}
                  amountField={true}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'netIncome' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="text"
                  placeholder="Min Net Income"
                  {...form[`minNetIncome`].input}
                  name="minNetIncome"
                  data-set={section}
                />
              </Card>
            </div>
            <div className="form-fields">
              <Card>
                <Input
                  type="text"
                  placeholder="Max Net Income"
                  {...form[`maxNetIncome`].input}
                  name="maxNetIncome"
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'netIncomeBS' ? (
        <Card>
          <div className="form-fields">
            <Card>
              <Input
                type="text"
                placeholder="Min NetIncome BS"
                {...form[`minNetIncomeBS`].input}
                name="minNetIncomeBS"
                data-set={section}
              />
            </Card>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'loanAmount' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum Loan Amount"
                  {...form[`minLoanAmount`].input}
                  name="minLoanAmount"
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum Loan Amount"
                  {...form[`maxLoanAmount`].input}
                  name="maxLoanAmount"
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'loanDuration' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum Loan Duration"
                  {...form[`minLoanDuration`].input}
                  name="minLoanDuration"
                  data-set={section}
                  amountField={true}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum Loan Duration"
                  {...form[`maxLoanDuration`].input}
                  name="maxLoanDuration"
                  data-set={section}
                  amountField={true}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'accountsInBadStanding' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum accounts in bad standing"
                  {...form[`minAccountsInBadStanding`].input}
                  data-set={section}
                  amountField={true}
                />
              </Card>
            </div>
            <div className="arrow"></div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum accounts in bad standing"
                  {...form[`maxAccountsInBadStanding`].input}
                  data-set={section}
                  amountField={true}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'tenorBS' ? (
        <Card>
          <div className="form-fields">
            <Card>
              <Input
                type="number"
                placeholder="Bank Statement Tenor"
                name="minTenorBs"
                {...form[`minTenorBs`].input}
                data-set={section}
                amountField={true}
              />
            </Card>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'totalDebitBS' ? (
        <Card>
          <div className="form-fields">
            <Card>
              <Input
                type="number"
                placeholder="Bank Statement Total Debit"
                name="minTotalDebitBs"
                {...form[`minTotalDebitBs`].input}
                data-set={section}
              />
            </Card>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'otiRatioBS' ? (
        <Card>
          <div className="form-fields">
            <Card>
              <Input
                type="number"
                placeholder="Bank Statement Outflow to Inflow Ratio"
                name="maxOTIRatioBs"
                {...form[`maxOTIRatioBs`].input}
                data-set={section}
                amountField={true}
              />
            </Card>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'percentageBettingBS' ? (
        <Card>
          <div className="form-fields">
            <Card>
              <Input
                type="number"
                placeholder="Percentage of Income used for Gambling"
                name="maxPercentageBettingBS"
                {...form[`maxPercentageBettingBS`].input}
                data-set={section}
                amountField={true}
              />
            </Card>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'bankHasMbsSupport' && section === 'app' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_bankHasMbsSupport`}
              label="Yes"
              value="yes"
              checked={bankHasMbsSupportStatus.approved === 'yes'}
              onChange={() =>
                handleBankHasMbsSupportChange({ section, value: 'yes' })
              }
            />
            <NewRadio
              name={`${section}_bankHasMbsSupport`}
              label="No"
              value="no"
              checked={bankHasMbsSupportStatus.approved === 'no'}
              onChange={() =>
                handleBankHasMbsSupportChange({ section, value: 'no' })
              }
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'bankHasMbsSupport' && section === 'unr' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_bankHasMbsSupport`}
              label="Yes"
              value="yes"
              checked={bankHasMbsSupportStatus.underReview === 'yes'}
              onChange={() =>
                handleBankHasMbsSupportChange({ section, value: 'yes' })
              }
            />
            <NewRadio
              name={`${section}_bankHasMbsSupport`}
              label="No"
              value="no"
              checked={bankHasMbsSupportStatus.underReview === 'no'}
              onChange={() =>
                handleBankHasMbsSupportChange({ section, value: 'no' })
              }
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'noHit' && section === 'app' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_noHit`}
              label="Yes"
              value="yes"
              checked={noHitStatus.approved === 'yes'}
              onChange={() =>
                handleNoHitStatusChange({ section, value: 'yes' })
              }
            />
            <NewRadio
              name={`${section}_noHit`}
              label="No"
              value="no"
              checked={noHitStatus.approved === 'no'}
              onChange={() => handleNoHitStatusChange({ section, value: 'no' })}
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'noHit' && section === 'unr' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_noHit`}
              label="Yes"
              value="yes"
              checked={noHitStatus.underReview === 'yes'}
              onChange={() =>
                handleNoHitStatusChange({ section, value: 'yes' })
              }
            />
            <NewRadio
              name={`${section}_noHit`}
              label="No"
              value="no"
              checked={noHitStatus.underReview === 'no'}
              onChange={() => handleNoHitStatusChange({ section, value: 'no' })}
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'averageSalaryBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum average salary"
                  {...form[`minAverageSalaryBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum average salary"
                  {...form[`maxAverageSalaryBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'averageOtherIncomeBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum average other income"
                  {...form[`minAverageOtherIncomeBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum average other income"
                  {...form[`maxAverageOtherIncomeBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'numberOfSalaryPaymentsBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum number of salary payments"
                  {...form[`minNumberOfSalaryPaymentsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum number of salary payments"
                  {...form[`maxNumberOfSalaryPaymentsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'numberOfOtherIncomePaymentsBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum number of other income payments"
                  {...form[`minNumberOfOtherIncomePaymentsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum number of other income payments"
                  {...form[`maxNumberOfOtherIncomePaymentsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'netAverageMonthlyEarningsBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum average monthly earning"
                  {...form[`minNetAverageMonthlyEarningsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum average monthly earning"
                  {...form[`maxNetAverageMonthlyEarningsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'averageBalanceBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum average balance"
                  {...form[`minAverageBalanceBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum average balance"
                  {...form[`maxAverageBalanceBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'totalExpensesBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum total expenses"
                  {...form[`minTotalExpensesBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum total expenses"
                  {...form[`maxTotalExpensesBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'inflowOutFlowRateBSP' && section === 'app' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_isCashFlowPositiveBSP`}
              label="Yes"
              value="yes"
              checked={inflowOutFlowRateStatus.approved === 'yes'}
              onChange={() =>
                handleInflowOutFlowRateChange({ section, value: 'yes' })
              }
            />
            <NewRadio
              name={`${section}_isCashFlowPositiveBSP`}
              label="No"
              value="no"
              checked={inflowOutFlowRateStatus.approved === 'no'}
              onChange={() =>
                handleInflowOutFlowRateChange({ section, value: 'no' })
              }
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'inflowOutFlowRateBSP' && section === 'unr' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_isCashFlowPositiveBSP`}
              label="Yes"
              value="yes"
              checked={inflowOutFlowRateStatus.underReview === 'yes'}
              onChange={() =>
                handleInflowOutFlowRateChange({ section, value: 'yes' })
              }
            />
            <NewRadio
              name={`${section}_isCashFlowPositiveBSP`}
              label="No"
              value="no"
              checked={inflowOutFlowRateStatus.underReview === 'no'}
              onChange={() =>
                handleInflowOutFlowRateChange({ section, value: 'no' })
              }
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'loanAmountBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum loan amount"
                  {...form[`minLoanAmountBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum loan amount"
                  {...form[`maxLoanAmountBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'loanRepaymentsBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum loan repayment"
                  {...form[`minLoanRepaymentsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum loan repayment"
                  {...form[`maxLoanRepaymentsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'loanToInflowRateBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum loan to inflow rate"
                  {...form[`minLoanToInflowRate`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum loan to inflow rate"
                  {...form[`maxLoanToInflowRate`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'loanRepaymentToInflowRateBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum loan repayment to inflow rate"
                  {...form[`minLoanRepaymentToInflowRate`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum loan repayment to inflow rate"
                  {...form[`maxLoanRepaymentToInflowRate`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'gamblingRateBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum gambling rate"
                  {...form[`minGamblingRateBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum gambling rate"
                  {...form[`maxGamblingRateBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'accountSweepBSP' && section === 'app' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_accountSweepBSP`}
              label="Yes"
              value="yes"
              checked={sweepStatus.approved === 'yes'}
              onChange={() => handleSweepChange({ section, value: 'yes' })}
            />
            <NewRadio
              name={`${section}_accountSweepBSP`}
              label="No"
              value="no"
              checked={sweepStatus.approved === 'no'}
              onChange={() => handleSweepChange({ section, value: 'no' })}
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'accountSweepBSP' && section === 'unr' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_accountSweepBSP`}
              label="Yes"
              value="yes"
              checked={sweepStatus.underReview === 'yes'}
              onChange={() => handleSweepChange({ section, value: 'yes' })}
            />
            <NewRadio
              name={`${section}_accountSweepBSP`}
              label="No"
              value="no"
              checked={sweepStatus.underReview === 'no'}
              onChange={() => handleSweepChange({ section, value: 'no' })}
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'totalCreditTurnoverBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum total credit turnover"
                  {...form[`minTotalCreditTurnoverBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum total credit turnover"
                  {...form[`maxTotalCreditTurnoverBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'totalDebitTurnoverBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum total debit turnover"
                  {...form[`minTotalDebitTurnoverBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum total debit turnover"
                  {...form[`maxTotalDebitTurnoverBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'averageMonthlyCreditsBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum average monthly credit"
                  {...form[`minAverageMonthlyCreditsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum average monthly credit"
                  {...form[`maxAverageMonthlyCreditsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'averageMonthlyDebitsBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum average monthly debit"
                  {...form[`minAverageMonthlyDebitsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum average monthly debit"
                  {...form[`maxAverageMonthlyDebitsBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'balanceAtStatementEndBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum balance at statement end"
                  {...form[`minBalanceAtStatementEndBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum balance at statement end"
                  {...form[`maxBalanceAtStatementEndBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'debitToIncomeRatioBSP' ? (
        <Card>
          <div className="flex-container double-col">
            <div className="is-between">is between</div>
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Minimum Debt-to-Income-Ratio"
                  {...form[`minDTIRatioBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
            <div className="arrow" />
            <div className="form-fields">
              <Card>
                <Input
                  type="number"
                  placeholder="Maximum Debt-to-Income-Ratio"
                  {...form[`maxDTIRatioBSP`].input}
                  data-set={section}
                />
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
      {type === 'salaryEarnerBSP' && section === 'app' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_salaryEarnerBSP`}
              label="Yes"
              value="yes"
              checked={salaryEarnerBSPStatus.approved === 'yes'}
              onChange={() =>
                handleSalaryEarnerBSPChange({ section, value: 'yes' })
              }
            />
            <NewRadio
              name={`${section}_salaryEarnerBSP`}
              label="No"
              value="no"
              checked={salaryEarnerBSPStatus.approved === 'no'}
              onChange={() =>
                handleSalaryEarnerBSPChange({ section, value: 'no' })
              }
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'salaryEarnerBSP' && section === 'unr' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_salaryEarnerBSP`}
              label="Yes"
              value="yes"
              checked={salaryEarnerBSPStatus.underReview === 'yes'}
              onChange={() =>
                handleSalaryEarnerBSPChange({ section, value: 'yes' })
              }
            />

            <NewRadio
              name={`${section}_salaryEarnerBSP`}
              label="No"
              value="no"
              checked={salaryEarnerBSPStatus.underReview === 'no'}
              onChange={() =>
                handleSalaryEarnerBSPChange({ section, value: 'no' })
              }
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'firstCentralDelinquency' && section === 'app' ? (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="Yes"
              label="Yes - Number of accounts in bad standing is more than 0"
              {...approvedFirstCentralDelinquency
                .account_in_standing_greater_than_zero.input}
            />
            <div>
              <NewCheckBox
                value="No"
                label="No - Number of accounts in bad standing is equal to 0"
                {...approvedFirstCentralDelinquency
                  .account_in_standing_equal_to_zero.input}
              />
              <NewCheckBox
                value="Not Hit"
                label="No hit - response is noHit"
                {...approvedFirstCentralDelinquency.notHit.input}
              />
            </div>
          </CheckBoxGroup>
        </Card>
      ) : (
        <></>
      )}
      {type === 'firstCentralDelinquency' && section === 'unr' ? (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="Yes"
              label="Yes - Number of accounts in bad standing is more than 0"
              {...underReviewFirstCentralDelinquency
                .account_in_standing_greater_than_zero.input}
            />
            <div>
              <NewCheckBox
                value="No"
                label="No - Number of accounts in bad standing is equal to 0"
                {...underReviewFirstCentralDelinquency
                  .account_in_standing_equal_to_zero.input}
              />
              <NewCheckBox
                value="Not Hit"
                label="No hit - response is noHit"
                {...underReviewFirstCentralDelinquency.notHit.input}
              />
            </div>
          </CheckBoxGroup>
        </Card>
      ) : (
        <></>
      )}
      {type === 'crcDelinquency' && section === 'app' ? (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="Yes"
              label="Yes - Delinquency count is more than 0"
              {...approvedCrcDelinquency.delinquency_count_is_more_than_zero
                .input}
            />
            <NewCheckBox
              value="No"
              label="No - Delinquency count is equal to 0"
              {...approvedCrcDelinquency.delinquency_count_is_equal_to_zero
                .input}
            />
            <NewCheckBox
              value="No_Hit"
              label="No hit - response is noHit"
              {...approvedCrcDelinquency.noHit.input}
            />
          </CheckBoxGroup>
        </Card>
      ) : (
        <></>
      )}
      {type === 'crcDelinquency' && section === 'unr' ? (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="Yes"
              label="Yes - Delinquency count is more than 0"
              {...underReviewCrcDelinquency.delinquency_count_is_more_than_zero
                .input}
            />
            <NewCheckBox
              value="No"
              label="No - Delinquency count is equal to 0"
              {...underReviewCrcDelinquency.delinquency_count_is_equal_to_zero
                .input}
            />
            <NewCheckBox
              value="No_Hit"
              label="No hit - response is noHit"
              {...underReviewCrcDelinquency.noHit.input}
            />
          </CheckBoxGroup>
        </Card>
      ) : (
        <></>
      )}
      {type === 'pdfBankStatementDetailsMatch' && section === 'app' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_pdfBankStatementDetailsMatch`}
              label="Yes"
              value="yes"
              checked={pdfBankStatementDetailsMatchStatus.approved === 'yes'}
              onChange={() =>
                handlePdfBankStatementDetailsMatchChange({
                  section,
                  value: 'yes',
                })
              }
            />
            <NewRadio
              name={`${section}_pdfBankStatementDetailsMatch`}
              label="No"
              value="no"
              checked={pdfBankStatementDetailsMatchStatus.approved === 'no'}
              onChange={() =>
                handlePdfBankStatementDetailsMatchChange({
                  section,
                  value: 'no',
                })
              }
            />
          </RadioGroup>
        </Card>
      )}
      {type === 'pdfBankStatementDetailsMatch' && section === 'unr' && (
        <Card>
          <RadioGroup>
            <NewRadio
              name={`${section}_pdfBankStatementDetailsMatch`}
              label="Yes"
              value="yes"
              checked={pdfBankStatementDetailsMatchStatus.underReview === 'yes'}
              onChange={() =>
                handlePdfBankStatementDetailsMatchChange({
                  section,
                  value: 'yes',
                })
              }
            />
            <NewRadio
              name={`${section}_pdfBankStatementDetailsMatch`}
              label="No"
              value="no"
              checked={pdfBankStatementDetailsMatchStatus.underReview === 'no'}
              onChange={() =>
                handlePdfBankStatementDetailsMatchChange({
                  section,
                  value: 'no',
                })
              }
            />
          </RadioGroup>
        </Card>
      )}

      {type === 'blacklistStatus' && section === 'app' && (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="Not Blacklisted"
              label="Not Blacklisted"
              {...approvedBlacklistStatuses.not_blacklisted.input}
            />
          </CheckBoxGroup>
        </Card>
      )}

      {type === 'blacklistStatus' && section === 'unr' && (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="Blacklisted By Me"
              label="Blacklisted By Me"
              {...underReviewBlacklistStatuses.blacklisted_by_me.input}
            />
            <NewCheckBox
              value="Blacklisted By Other Merchants"
              label="Blacklisted By Other Merchants"
              {...underReviewBlacklistStatuses.blacklisted_by_other_merchants
                .input}
            />
          </CheckBoxGroup>
        </Card>
      )}
      {type === 'creditRegistry' && section === 'app' ? (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="PERFORMING"
              label="Yes - Number of accounts performing is more than 0"
              {...approvedCreditRegistryDelinquency?.PERFORMING.input}
            />
            <NewCheckBox
              value="NON_PERFORMING"
              label="No - Number of accounts not performing is more than 0"
              {...approvedCreditRegistryDelinquency?.NON_PERFORMING.input}
            />
            <NewCheckBox
              value="NO_HIT"
              label="No hit - response is noHit"
              {...approvedCreditRegistryDelinquency?.NO_HIT.input}
            />
          </CheckBoxGroup>
        </Card>
      ) : (
        <></>
      )}

      {type === 'creditRegistry' && section === 'unr' ? (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="PERFORMING"
              label="Yes - Number of accounts performing is more than 0"
              {...underReviewCreditRegistryDelinquency?.PERFORMING?.input}
            />
            <NewCheckBox
              value="NON_PERFORMING"
              label="No - Number of accounts not performing is more than 0"
              {...underReviewCreditRegistryDelinquency?.NON_PERFORMING?.input}
            />
            <NewCheckBox
              value="NO_HIT"
              label="No hit - response is noHit"
              {...underReviewCreditRegistryDelinquency?.NO_HIT?.input}
            />
          </CheckBoxGroup>
        </Card>
      ) : (
        <></>
      )}

      {type === 'bvnNinMatch' && section === 'app' ? (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="FIRSTNAME_MATCH"
              label="First name match"
              {...approvedBvnNinMatch?.FIRSTNAME_MATCH?.input}
            />
            <NewCheckBox
              value="LASTNAME_MATCH"
              label="Last name match"
              {...approvedBvnNinMatch?.LASTNAME_MATCH?.input}
            />
            <NewCheckBox
              value="DOB_MATCH"
              label="Date of birth match"
              {...approvedBvnNinMatch?.DOB_MATCH?.input}
            />
          </CheckBoxGroup>
        </Card>
      ) : (
        <></>
      )}

      {type === 'bvnNinMatch' && section === 'unr' ? (
        <Card>
          <CheckBoxGroup>
            <NewCheckBox
              value="FIRSTNAME_MATCH"
              label="First name match"
              {...underReviewBvnNinMatch?.FIRSTNAME_MATCH?.input}
            />
            <NewCheckBox
              value="NON_PERFORMING"
              label="Last name match"
              {...underReviewBvnNinMatch?.LASTNAME_MATCH?.input}
            />
            <NewCheckBox
              value="DOB_MATCH"
              label="Date of birth match"
              {...underReviewBvnNinMatch?.DOB_MATCH?.input}
            />
          </CheckBoxGroup>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default FormInputs;
