import { Query } from '@apollo/react-components';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connection_limit } from '../config/config';
import { COLLECTIONS_TRANSACTIONS_QUERY } from '../graphql/queries';

const CollectionsTableProvider = ({ render, setFilterParams }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchMoreCollectionsWallet = (e, fetchMore, data) => {
    e.preventDefault();
    setIsLoading(true);
    fetchMore({
      variables: {
        cursor:
          data.getCollections.account.wallets.feeWallet.transactions.pageInfo
            .endCursor,
        ...setFilterParams,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoading(false);
        const newNodes =
          fetchMoreResult.getCollections.account.wallets.feeWallet.transactions
            .nodes;
        const pageInfo =
          fetchMoreResult.getCollections.account.wallets.feeWallet.transactions
            .pageInfo;
        const oldData =
          prev.getCollections.account.wallets.feeWallet.transactions.nodes;
        return newNodes.length
          ? {
              getCollections: {
                __typename: prev.getCollections.__typename,
                account: {
                  __typename: prev.getCollections.account.__typename,
                  wallets: {
                    __typename: prev.getCollections.account.wallets.__typename,
                    feeWallet: {
                      __typename:
                        prev.getCollections.account.wallets.feeWallet
                          .__typename,
                      transactions: {
                        __typename:
                          prev.getCollections.account.wallets.feeWallet
                            .transactions.__typename,
                        nodes: [...oldData, ...newNodes],
                        pageInfo,
                      },
                    },
                  },
                },
              },
            }
          : prev;
      },
    });
  };

  return (
    <Query
      query={COLLECTIONS_TRANSACTIONS_QUERY}
      variables={{ first: connection_limit.value }}
    >
      {({ data, fetchMore }) => {
        return render({
          collectionsTableData: data,
          isLoading,
          handlePagination: e =>
            handleFetchMoreCollectionsWallet(e, fetchMore, data),
        });
      }}
    </Query>
  );
};

export default withRouter(CollectionsTableProvider);
