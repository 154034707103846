import React, { Fragment } from 'react';
import { DataCard, Table } from '../../../../components';
import {
  nairaAmount,
  getWeekOfMonth,
  getTransactionRange,
} from '../../../../lib/Utils';

const TransactionPatternAnalysisTab = ({ transactionPatternAnalysis }) => {
  const {
    highestMAWOCredit,
    highestMAWODebit,
    MAWWZeroBalanceInAccount,
    recurringExpense,
    transactionsLess10000,
    transactionsBetween10000And100000,
    transactionsBetween100000And500000,
    NODWBalanceLess5000,
    transactionsGreater500000,
    mostFrequentBalanceRange,
    lastDateOfCredit,
    lastDateOfDebit,
    mostFrequentTransactionRange,
  } = transactionPatternAnalysis || {};
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Table>
            <div className="table__body">
              {transactionPatternAnalysis &&
              transactionPatternAnalysis != null &&
              Object.keys(transactionPatternAnalysis).length ? (
                <ul>
                  <li>
                    <span className="decide-analysis-span">
                      Transaction Pattern Analysis
                    </span>
                  </li>
                  <li>
                    <span>Highest Month and Week of Credit</span>
                    <span className="right-text">
                      {highestMAWOCredit != null
                        ? `${highestMAWOCredit.week_of_month}${getWeekOfMonth(
                            highestMAWOCredit.week_of_month,
                          )} week, ${highestMAWOCredit.month}`
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Highest Month and Week of Debit</span>
                    <span className="right-text">
                      {highestMAWODebit != null
                        ? `${highestMAWODebit.week_of_month}${getWeekOfMonth(
                            highestMAWODebit.week_of_month,
                          )} week, ${highestMAWODebit.month}`
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Month and Week with Zero Balance</span>
                    <div className="transactionPatternData">
                      {MAWWZeroBalanceInAccount != null &&
                      MAWWZeroBalanceInAccount.length
                        ? MAWWZeroBalanceInAccount.map((item, idx) => (
                            <>
                              <p key={idx}>{`${
                                item.week_of_month
                              }${getWeekOfMonth(item.week_of_month)} week, ${
                                item.month
                              }`}</p>
                            </>
                          ))
                        : '__'}
                    </div>
                  </li>
                  <li>
                    <span>Recurring Expenses</span>
                    <div className="transactionPatternData">
                      {recurringExpense != null && recurringExpense.length
                        ? recurringExpense.map((item, idx) => (
                            <>
                              <p key={idx}>{`${nairaAmount(item.amount)} ${
                                item.description
                              }`}</p>
                            </>
                          ))
                        : '__'}
                    </div>
                  </li>
                  <li>
                    <span>Transactions Less Than N10,000</span>
                    <span className="right-text">
                      {transactionsLess10000 != null
                        ? transactionsLess10000
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Transactions N10,000 - N100,000</span>
                    <span className="right-text">
                      {transactionsBetween10000And100000 != null
                        ? transactionsBetween10000And100000
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Transactions N100,000 - N500,000</span>
                    <span className="right-text">
                      {transactionsBetween100000And500000 != null
                        ? transactionsBetween100000And500000
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Transactions Greater Than N500,000</span>
                    <span className="right-text">
                      {transactionsGreater500000 != null
                        ? transactionsGreater500000
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>{`Number of Days with Low Balance (Balance < N5,000)`}</span>
                    <span className="right-text">
                      {NODWBalanceLess5000 != null ? NODWBalanceLess5000 : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Most Frequent Balance Range</span>
                    <span className="right-text">
                      {mostFrequentBalanceRange != null
                        ? `Balance ${getTransactionRange(
                            mostFrequentBalanceRange,
                          )}`
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Most Frequent Transaction Range</span>
                    <span className="right-text">
                      {mostFrequentTransactionRange != null
                        ? `Transaction ${getTransactionRange(
                            mostFrequentTransactionRange,
                          )}`
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Last Date Of Credit</span>
                    <span className="right-text">
                      {lastDateOfCredit != null ? lastDateOfCredit : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Last Date of Debit</span>
                    <span className="right-text">
                      {lastDateOfDebit != null ? lastDateOfDebit : '__'}
                    </span>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default TransactionPatternAnalysisTab;
