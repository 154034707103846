import PropTypes from 'prop-types';
import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink } from 'react-router-dom';
import { removeHyphen } from '../../lib/Utils';
import './_BreadCrumbs.scss';

const BreadCrumbs = ({ breadcrumbs, unlinklast }) => {
  const backlink = unlinklast ? 2 : 1;

  return (
    <nav className="bread-crumbs">
      {breadcrumbs.map(({ breadcrumb, match }, index) => {
        const isHidden = index === 0;

        return (
          <div className={`bread-crumb ${!isHidden}`} key={match.url}>
            {index < breadcrumbs.length - backlink ? (
              !isHidden ? (
                <NavLink exact to={match.url || ''}>
                  {breadcrumb}
                </NavLink>
              ) : (
                <span className="hiddenNavStep">''</span>
              )
            ) : (
              <span className="transform">
                {removeHyphen(breadcrumb.props.children)}
              </span>
            )}
          </div>
        );
      })}
    </nav>
  );
};

BreadCrumbs.propTypes = {
  unlinklast: PropTypes.bool,
};

export default withBreadcrumbs()(BreadCrumbs);
