import React, { Fragment } from 'react';
import Collapse, { Panel } from 'rc-collapse';
import { Loader, Modal } from '../../../../components';
import {
  CircleCheckIcon,
  CircleDashIcon,
  CircleXIcon,
  Close,
} from '../../../../components/Icons';
import 'rc-collapse/assets/index.css';
import 'react-web-tabs/dist/react-web-tabs.css';

const RuleBreakdown = ({
  ruleBreakdownData,
  ruleBreakdownLoading,
  hideChangeRuleBreakDownPopup,
  inputs,
}) => {
  return (
    <Fragment>
      <Modal
        title={'Rule Breakdown'}
        classes="rule-breakdown-popup"
        active={inputs.showChangeRuleBreakDownPopup.value}
      >
        <Close
          className="close-icon"
          onClick={e => hideChangeRuleBreakDownPopup(e)}
        />

        {ruleBreakdownLoading ? (
          <Loader loadingText="loading..." />
        ) : (
          <div className="decision-container">
            {ruleBreakdownData && (
              <Collapse accordion={true}>
                {ruleBreakdownData.ruleBreakDown &&
                  ruleBreakdownData.ruleBreakDown.length > 0 &&
                  ruleBreakdownData.ruleBreakDown.map((rule, key) => (
                    <Panel header={rule.policy.name} key={key}>
                      <div key={key} className="rule-decision">
                        <h6>Approve Rules</h6>
                        {rule &&
                          rule.approve.map((approves, key) => (
                            <ul
                              key={key}
                              className="rule-decision__item-holder"
                            >
                              <li className="rule-decision__item">
                                <div className="rule-decision__result">
                                  {approves.pass != null ? (
                                    approves.pass === 'true' ? (
                                      <CircleCheckIcon />
                                    ) : (
                                      <CircleXIcon />
                                    )
                                  ) : (
                                    <CircleDashIcon />
                                  )}
                                </div>
                                <div className="rule-decision__result-text">
                                  <strong>{approves.typeName} -</strong>{' '}
                                  {approves.message}
                                </div>
                              </li>
                            </ul>
                          ))}
                      </div>
                      <div className="rule-decision">
                        <h6>Manual Review Rules</h6>
                        {rule &&
                          rule.manualReview.map((review, key) => (
                            <ul
                              key={key}
                              className="rule-decision__item-holder"
                            >
                              <li className="rule-decision__item">
                                <div className="rule-decision__result">
                                  {review.pass != null ? (
                                    review.pass === 'true' ? (
                                      <CircleCheckIcon />
                                    ) : (
                                      <CircleXIcon />
                                    )
                                  ) : (
                                    <CircleDashIcon />
                                  )}
                                </div>
                                <div className="rule-decision__result-text">
                                  <strong>{review.typeName} -</strong>{' '}
                                  {review.message}
                                </div>
                              </li>
                            </ul>
                          ))}
                      </div>
                    </Panel>
                  ))}
              </Collapse>
            )}
          </div>
        )}
      </Modal>
    </Fragment>
  );
};

export default RuleBreakdown;
