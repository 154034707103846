import React from 'react';

const Plus = props => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
    <circle
      cx="15"
      cy="15"
      r="14.25"
      fill="white"
      stroke="#0077FF"
      strokeWidth="1.5"
    />
    <line
      x1="15.3816"
      y1="8.75"
      x2="15.3816"
      y2="21.25"
      stroke="#0077FF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="21.25"
      y1="15.3816"
      x2="8.75"
      y2="15.3816"
      stroke="#0077FF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default Plus;
