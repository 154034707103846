import React from 'react';
import { Mutation } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { MAKE_MANUAL_REPAYMENT_MUTATION } from '../graphql/mutations';
import { GET_BANKS, PORTFOLIO_QUERY } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import { Loader } from '../components';
import { useQuery } from '@apollo/react-hooks';
import {
  removeCommas,
  trimGraphQLError,
  roundNumberToPrecision,
} from '../lib/Utils';

const MakeManualRepayment = ({ render, match: { params }, portfolio }) => {
  const { bankId } = params;

  const { data } = useQuery(GET_BANKS, {
    variables: {
      id: bankId,
    },
  });

  let banks;

  if (data) {
    banks = data.getBanks;
  }

  const makeManualRepaymentInput = {
    amount: useCustomState(),
    bankId: useCustomState(),
    accountNumber: useCustomState(),
    reference: useCustomState(),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showPopup: useCustomState(false),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      makeManualRepaymentInput.showToast.setValue(false);
    }, 2500);
  };

  const showManualRepaymentPopup = e => {
    e.preventDefault();

    makeManualRepaymentInput.showPopup.setValue(true);
  };

  const hideManualRepaymentPopup = e => {
    e.preventDefault();

    makeManualRepaymentInput.showPopup.setValue(false);
  };

  const handleMakeManualRepayment = async (e, handleRepaymentMutation) => {
    e.preventDefault();

    if (
      !makeManualRepaymentInput.amount.value ||
      !makeManualRepaymentInput.bankId.value ||
      !makeManualRepaymentInput.accountNumber.value ||
      !makeManualRepaymentInput.reference.value
    ) {
      makeManualRepaymentInput.showToast.setValue(true);
      makeManualRepaymentInput.status.setValue('Failed');
      makeManualRepaymentInput.content.setValue(
        'Please fill all required input',
      );

      handleHideToast();

      return;
    }

    await handleRepaymentMutation({
      variables: {
        portfolioId: portfolio.id,
        amount: parseFloat(
          roundNumberToPrecision(
            removeCommas(makeManualRepaymentInput.amount.value),
            2,
          ),
        ),
        bankId: makeManualRepaymentInput.bankId.value,
        accountNumber: makeManualRepaymentInput.accountNumber.value,
        reference: makeManualRepaymentInput.reference.value,
      },
      refetchQueries: () => [
        {
          query: PORTFOLIO_QUERY,
          variables: {
            portfolioNumber: portfolio.portfolioNumber,
            transactionWhere: {
              type_in: ['Manual Repayment', 'Repayment'],
            },
          },
        },
      ],
    });

    makeManualRepaymentInput.showPopup.setValue(false);
  };

  const isNumber = () => {
    let accountNumber = document.querySelector('.account-number');

    accountNumber.addEventListener('keypress', function (event) {
      this.value = this.value.replace(/[^0-9.]/g, '');
      if (event.which < 48 || event.which > 57) {
        event.preventDefault();
      }
    });
  };

  return (
    <Mutation
      mutation={MAKE_MANUAL_REPAYMENT_MUTATION}
      onCompleted={({ makeManualRepayment: { success } }) => {
        if (success) {
          makeManualRepaymentInput.showToast.setValue(true);
          makeManualRepaymentInput.status.setValue('Success');
          makeManualRepaymentInput.content.setValue('Approved');

          handleHideToast();
        }
      }}
      onError={error => {
        makeManualRepaymentInput.showToast.setValue(true);
        makeManualRepaymentInput.status.setValue('Failed');
        makeManualRepaymentInput.content.setValue(
          trimGraphQLError(error.message),
        );

        handleHideToast();
      }}
    >
      {(handleRepaymentMutation, { loading }) => {
        if (loading) {
          return <Loader />;
        }

        return render({
          handleHideToast,
          showManualRepaymentPopup: showManualRepaymentPopup,
          hideManualRepaymentPopup: hideManualRepaymentPopup,
          handleMakeManualRepayment: e =>
            handleMakeManualRepayment(e, handleRepaymentMutation),
          input: makeManualRepaymentInput,
          banks,
          isNumber,
          loading,
        });
      }}
    </Mutation>
  );
};

export default withRouter(MakeManualRepayment);
