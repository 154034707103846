import { Alert, Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { FullPageLoader } from '@/components/FullPageLoader';
import { processGraphQLError } from '@/utils/processGraphQLError';
import { useDashboardReportUrl } from '@/services/dashboard';


const ReportsLayout = () => {
const { dashboardReportUrl, dashboardReportUrlError, dashboardReportUrlLoading } =
useDashboardReportUrl();


  return (
    <Stack spacing={4} flex={1} as={"div"} border={"0"}>
      {dashboardReportUrlLoading && <FullPageLoader />}
      {dashboardReportUrlError && (
        <Alert status="success">
          {processGraphQLError(dashboardReportUrlError)}
        </Alert>
      )}

      {dashboardReportUrl && (
        <Flex
          flex={1}
          as="iframe"
          bg={"white"}
          src={`${dashboardReportUrl?.iframeUrl}#bordered=false&titled=true`}
        >
        </Flex>
      )}
    </Stack>
  );
};

export default ReportsLayout;
