import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { REVERSE_REPAYMENT_TRANSACTION_MUTATION } from '../../graphql/mutations';
import { trimGraphQLError } from '../../lib/Utils';
import { useEffect } from 'react';
import Constants from '../../lib/Constants';
import { PORTFOLIO_QUERY } from '../../graphql/queries';

const useReverseRepaymentTransaction = ({
  portfolioNumber,
  handleReverseTransaction,
}) => {
  const [error, setError] = useState();

  const [mutate, { data, loading: reverseRepaymentTransactionIsLoading }] =
    useMutation(REVERSE_REPAYMENT_TRANSACTION_MUTATION, {
      onError: err => {
        setError(err);
      },
      refetchQueries: () => [
        {
          query: PORTFOLIO_QUERY,
          variables: {
            portfolioNumber,
            transactionWhere: {
              type_in: ['Manual Repayment', 'Repayment', 'Reversal'],
            },
          },
        },
      ],
    });

  const triggerReverseRepaymentTransaction = ({ transactionId }) => {
    mutate({
      variables: {
        transactionId,
      },
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(
        trimGraphQLError(error?.message) ||
          Constants.errorMessages.NETWORK_ERROR,
      );
    }

    return () => toast.dismiss();
  }, [error]);

  useEffect(() => {
    if (data?.reverseRepaymentTransaction?.success) {
      toast.success('Repayment was reversed successfully');
      handleReverseTransaction();
    }

    return () => toast.dismiss();
  }, [data]);

  return {
    reverseRepaymentTransactionIsLoading,
    triggerReverseRepaymentTransaction,
  };
};

export default useReverseRepaymentTransaction;
