import { gql } from 'apollo-boost';

export const RULEBREAKDOWN_QUERY = gql`
  query MP_RuleBreakDown($applicationId: ID!) {
    ruleBreakDown(input: { applicationId: $applicationId }) {
      policy {
        id
        name
      }
      ruleSet {
        id
        name
      }
      approve {
        typeName
        message
        pass
      }
      manualReview {
        typeName
        message
        pass
      }
    }
  }
`;
