import React from 'react';
import './_Table.scss';
import PropTypes from 'prop-types';

const Table = props => {
  return (
    <div className={'table'}>
      {props.tableHeaders === '' || props.tableHeaders == null ? (
        ''
      ) : (
        <div className={'table__head'}>
          {props.tableHeaders.map(val => (
            <span>{val}</span>
          ))}
        </div>
      )}
      {props.children}
    </div>
  );
};

Table.propTypes = {
  tableHeaders: PropTypes.array,
};

export default Table;
