import { useMutation } from '@apollo/client';
import { DisableLoanProductMutationVariables } from '@/__generated/graphql';
import { DISABLE_LOAN_PRODUCT } from '@/graphql/mutations/loan-products';
import { GET_LOAN_PRODUCTS } from '@/graphql/queries/loan-products';

export const useDisableLoanProduct = () => {
  const [mutate, { data, loading, error }] = useMutation(DISABLE_LOAN_PRODUCT, {
    update: (cache, { data: { policyDisable } }) => {
      const { viewer } = cache.readQuery({ query: GET_LOAN_PRODUCTS });

      const updatedPolicies = viewer.account.policies.nodes.map(policy => {
        if (policy.id === policyDisable?.recordId) {
          return { ...policy, status: policyDisable?.status };
        }

        return policy;
      });

      cache.writeQuery({
        query: GET_LOAN_PRODUCTS,

        data: {
          viewer: {
            ...viewer,
            account: {
              ...viewer.account,
              policies: {
                ...viewer.account.policies,
                nodes: updatedPolicies,
              },
            },
          },
        },
      });
    },
  });

  const disableLoanProduct = (
    id: DisableLoanProductMutationVariables['id'],
  ) => {
    mutate({
      variables: {
        id,
      },
    });
  };

  return {
    disableLoanProduct,
    disableLoanProductError: error,
    disableLoanProductLoading: loading,
    disableLoanProductData: data?.policyDisable,
  };
};
