import moment from 'moment';
import cx from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { Alert, MoreOptions, Table } from '../../../../components';
import { capitalizeInitialLetter, nairaAmount } from '../../../../lib/Utils';
import ReverseTransactionPopup from '../Popups/ReverseTransaction';
import { useCallback } from 'react';
import useReverseRepaymentTransaction from '../../../../Providers-new/ReverseRepaymentTransaction/useReverseRepaymentTransaction';

const headers = {
  repaymentsHeader: ['Status', 'Type', 'Amount', 'Action By', 'Date Time'],
  loanHistory: ['Old Status', 'New Status', 'Comment', 'Created On'],
};

const TransactionsTab = ({ portfolioData }) => {
  const [repaymentFilterParam, setRepaymentFilterParam] = useState('default');
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [reverseTransactionPopup, setReverseTransactionPopup] = useState(false);
  const [reversalAmount, setReversalAmount] = useState('');
  const [transactionId, setTransactionId] = useState('');

  const handleReverseTransaction = useCallback(transaction => {
    setReversalAmount(transaction?.amount);
    setTransactionId(transaction?.id);
    setReverseTransactionPopup(prev => !prev);
  }, []);

  const {
    reverseRepaymentTransactionIsLoading,
    triggerReverseRepaymentTransaction,
  } = useReverseRepaymentTransaction({
    portfolioNumber: portfolioData?.portfolio?.portfolioNumber,
    handleReverseTransaction,
    reverseTransactionPopup,
  });

  const filterTransactions = filterParam => {
    switch (filterParam) {
      case 'success':
      case 'reversed':
      case 'Manual Repayment':
      case 'Repayment':
        setFilteredTransactions(
          portfolioData?.portfolio?.transactions?.nodes?.filter(
            item =>
              item?.type?.name === filterParam || item?.status === filterParam,
          ),
        );
        break;

      case 'all':
        setFilteredTransactions(portfolioData?.portfolio?.transactions?.nodes);
        break;
      default:
        setFilteredTransactions(portfolioData?.portfolio?.transactions?.nodes);
    }
  };

  useEffect(() => {
    filterTransactions(repaymentFilterParam);
  }, [repaymentFilterParam, portfolioData]);

  return (
    <Fragment>
      <section className="repayment-top-buttons">
        <section className="filter-repayments">
          <label>Filter:</label>

          <section className="filter-repayments-select">
            <select
              onChange={e => setRepaymentFilterParam(e?.target?.value)}
              defaultValue="default"
            >
              <option value="default">Default</option>
              <option value="all">All</option>
              <option value="Repayment">Repayment</option>
              <option value="Manual Repayment">Manual Repayment</option>
              <option value="success">Success</option>
              <option value="reversed">Reversed</option>
            </select>
          </section>
        </section>
      </section>
      {filteredTransactions.length > 0 ? (
        <Table>
          <div className="table__head">
            {headers.repaymentsHeader.map((header, key) => (
              <span
                key={key}
                className={cx({
                  'action-by': header === 'Action By',
                  header,
                })}
              >
                {header}
              </span>
            ))}
          </div>

          <div className="table__body">
            <ul>
              {filteredTransactions.map((transaction, key) => (
                <li key={key}>
                  <span>
                    <em
                      className={`transaction-tag transaction-tag__${transaction.status}`}
                    >
                      {capitalizeInitialLetter(transaction.status)}
                    </em>
                  </span>
                  <span>{transaction?.type?.name}</span>
                  <span className="amount">
                    {nairaAmount(transaction.amount)}
                  </span>
                  <span>{transaction?.paymentMethod?.name || 'N/A'}</span>
                  <span className="date">
                    {moment(transaction.createdAt).format('lll')}
                  </span>
                  <div
                    className={`${
                      (transaction?.status === 'reversed' ||
                        transaction?.type?.name === 'Reversal') &&
                      'opacity-none'
                    } moreOptions-button`}
                  >
                    <span className="checked-width">
                      <MoreOptions id={key}>
                        {!(
                          transaction?.status === 'reversed' ||
                          transaction?.type?.name === 'Reversal'
                        ) && (
                          <span
                            onClick={() =>
                              handleReverseTransaction(transaction)
                            }
                          >
                            Reverse
                          </span>
                        )}
                      </MoreOptions>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Table>
      ) : (
        <section style={{ marginTop: '32px' }}>
          <Alert
            message={`There are currently no ${repaymentFilterParam} transactions on this portfolio.`}
          />
        </section>
      )}
      {reverseTransactionPopup && (
        <ReverseTransactionPopup
          handleReverseTransaction={handleReverseTransaction}
          reversalAmount={reversalAmount}
          transactionId={transactionId}
          reverseRepaymentTransactionIsLoading={
            reverseRepaymentTransactionIsLoading
          }
          triggerReverseRepaymentTransaction={
            triggerReverseRepaymentTransaction
          }
          portfolioNumber={portfolioData?.portfolio?.portfolioNumber}
          reverseTransactionPopup={reverseTransactionPopup}
        />
      )}
    </Fragment>
  );
};

export default TransactionsTab;
