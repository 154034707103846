import { SET_MANUAL_REVIEW_APPLICATION_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { APPLICATIONS_QUERY } from '../graphql/queries';

const SetManualReviewApplication = ({ application }) => {
  const input = {
    comment: useCustomState('Moved To Manual Review'),
    content: useCustomState(),
    status: useCustomState(),
    showToast: useCustomState(false),
    showManualReviewPopup: useCustomState(false),
  };

  const showManualReviewPopup = e => {
    e.preventDefault();

    input.showManualReviewPopup.setValue(true);
  };

  const hideManualReviewPopup = e => {
    e.preventDefault();

    input.showManualReviewPopup.setValue(false);
  };

  const handleHideToast = () => {
    setTimeout(() => {
      input.showToast.setValue(false);
    }, 3000);
  };

  const [runMutation, { loading }] = useMutation(
    SET_MANUAL_REVIEW_APPLICATION_MUTATION,
    {
      async onCompleted() {
        input.status.setValue('Success');
        input.showToast.setValue(true);
        input.content.setValue('Application Status Changed');
        handleHideToast();
        input.showManualReviewPopup.setValue(
          !input.showManualReviewPopup.value,
        );
      },

      onError(error) {
        input.status.setValue('Failed');
        input.showToast.setValue(true);
        input.content.setValue(trimGraphQLError(error.message));
        handleHideToast();
      },
    },
  );

  const handleSetManualReviewApplication = async e => {
    e.preventDefault();

    await runMutation({
      variables: {
        input: {
          applicationId: application.id,
          comment: input.comment.value,
        },
      },
      refetchQueries: () => [
        {
          query: APPLICATIONS_QUERY,
          variables: {
            applicationNumber: application.applicationNumber,
            skipAccountAttributes: true,
          },
        },
      ],
    });
  };

  return {
    loading,
    input,
    handleSetManualReviewApplicationToast: handleHideToast,
    handleSetManualReviewApplication,
    showManualReviewPopup,
    hideManualReviewPopup,
  };
};

export default SetManualReviewApplication;
