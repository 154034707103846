import cx from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import {
  BreadCrumbSection,
  Button,
  CustomerDetailsSideBar,
  DataCard,
  EmptyScreen,
  Export,
  Table,
  Modal,
  Close,
  KycUnverified,
} from '../../components';
import {
  capitalizeInitialLetter,
  convertObjToKeyValueArr,
  formatLoanDuration,
  getLoanDurationName,
  insertSpaceBeforeCapitalLetter,
  isValidUrl,
  nairaAmount,
  numberWithCommas,
  reduceStringLength,
  removeUnderscore,
  splitString,
  unCamelize,
} from '../../lib/Utils';
import {
  CustomerLoanDetailsProvider,
  UrlFromS3Provider,
} from '../../Providers';
import { ErrorBoundary } from 'react-error-boundary';
import BlacklistedUser from '../../components/BlacklistedUser/BlacklistedUser';
import { BlacklistReports } from './BlacklistReports';

const headers = {
  portfolioHeader: [
    'Loan ID',
    'Amount (N)',
    'Duration',
    'Total (N)',
    'Disbursed (N)',
    'Paid (N)',
    'Created',
  ],
  applicationHeader: [
    'Status',
    'Application ID',
    'Amount (N)',
    'Duration',
    'Interest',
    'Total',
    'Created',
  ],
};

const govtIDs = ['international', 'voter', 'national', 'driver'];

const CustomerDetails = () => {
  const location = useLocation();

  return (
    <CustomerLoanDetailsProvider
      render={({
        data,
        input,
        handleExportToCsvApplications,
        handleExportToCsvPortfolios,
        handleExportToCsvCustomers,
        abbreviateApplicationStatus,
        businessAttributes,
        handleShowEligibilityModal,
        handleHideEligibilityModal,
        refetch,
        latestApplicationFormData,
      }) => {
        const hasCustomer = data?.customer;

        const kycInformation = hasCustomer?.user?.kycInformation?.data;

        const kycInformationData = kycInformation?.['Customer Information'];

        const kycInformationKeys =
          kycInformationData && Object.keys(kycInformationData);

        const hasUser = hasCustomer && data.customer?.user;

        const hasUserMetaData =
          hasCustomer && hasUser && data.customer.user?.userMetadata;
        const betaLoanAccountDetails =
          (hasUserMetaData &&
            data.customer.user?.userMetadata.find(
              item => item.name === 'betaLoanAccountNumber',
            )) ||
          {};
        const hasBetaLoanAccountNumber =
          // eslint-disable-next-line no-prototype-builtins
          betaLoanAccountDetails?.hasOwnProperty('name');
        const isGraduatedLoan =
          hasCustomer &&
          data?.customer?.applications?.nodes.map(
            item => item.policy != null && item.policy.loanType === 'GRADUATED',
          );

        const hasCustomerGraduatedAmounts =
          hasCustomer &&
          hasUser &&
          data.customer.user?.userMetadata.find(
            meta => meta.name === 'customerGraduatedAmounts',
          );

        const customerGraduatedAmountsData = hasCustomerGraduatedAmounts?.value
          ? JSON.parse(hasCustomerGraduatedAmounts.value)
          : [];

        const hasSelfie =
          hasUserMetaData &&
          hasUserMetaData.find(metadata => metadata.name === 'selfie');

        const customFiles =
          latestApplicationFormData &&
          Object.values(latestApplicationFormData).flatMap(
            section => section.files,
          );
        const customUploads = customFiles
          ? data.customer?.user?.userMetadata.filter(item1 =>
              customFiles.some(item2 => item2 && item2?.name === item1.name),
            )
          : [];

        return (
          <Fragment>
            <BreadCrumbSection />

            <CustomerDetailsSideBar>
              <article className="customer-info">
                <span className="cover-image" />
                {hasCustomer &&
                (isValidUrl(hasUser.bvnStatus?.image) ||
                  isValidUrl(hasSelfie?.value)) ? (
                  <>
                    {isValidUrl(hasUser.bvnStatus?.image) ? (
                      <img
                        alt=""
                        name={`${data.customer?.user?.firstName}'s BVN Image`}
                        src={hasUser.bvnStatus?.image}
                        className="avatar-img"
                      />
                    ) : (
                      <img
                        alt=""
                        name={`${data.customer?.user?.firstName}'s Selfie`}
                        src={hasSelfie.value}
                        className="avatar-img"
                        style={{
                          objectFit: 'contain',
                          border: '2px solid rgba(0, 0, 0, 0.2)',
                        }}
                      />
                    )}
                  </>
                ) : (
                  <div className="avatar">
                    {hasCustomer &&
                    data?.customer?.user?.firstName &&
                    data.customer?.user?.lastName
                      ? `${data?.customer?.user?.firstName
                          ?.charAt(0)
                          ?.toUpperCase()}${data.customer?.user?.lastName
                          ?.charAt(0)
                          ?.toUpperCase()}`
                      : `N/A`}
                  </div>
                )}
                {hasCustomer ? (
                  <h3>
                    {data?.customer?.user?.firstName &&
                    data.customer?.user?.lastName
                      ? `${data?.customer?.user?.firstName} ${data.customer?.user?.lastName}`
                      : 'N/A'}
                  </h3>
                ) : (
                  <h3>...</h3>
                )}

                {!data?.customer?.user.isBvnVerified ? <KycUnverified /> : null}

                {data?.customer?.user?.blacklistDetails && (
                  <BlacklistedUser
                    blacklistDetails={data?.customer?.user?.blacklistDetails}
                    customerName={data?.customer?.name}
                  />
                )}
              </article>

              <article className="detail-section">
                <h6>Personal Information</h6>
                {data?.customer && (
                  <ul>
                    <li>
                      <span>Customer ID</span>
                      <span>{data?.customer?.name}</span>
                    </li>
                    <li>
                      <span>Contact Email</span>
                      <span>{data?.customer?.user?.email}</span>
                    </li>
                  </ul>
                )}
              </article>

              <article className="detail-section">
                <h6>Bank Information</h6>
                <Fragment>
                  <ul>
                    {data?.customer?.bankAccounts?.map((bankAccount, idx) => (
                      <li key={idx}>
                        <span>{bankAccount?.bank?.name}</span>
                        <span>{bankAccount?.accountNumber}</span>
                      </li>
                    ))}
                    {hasUserMetaData && hasBetaLoanAccountNumber ? (
                      <li>
                        <span>FCMB Beta Account Number</span>
                        <span>{betaLoanAccountDetails?.value}</span>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </Fragment>

                <h6>Card Information</h6>
                <Fragment>
                  <ul>
                    {hasCustomer && data?.customer?.cards?.length > 0
                      ? data?.customer?.cards?.map((card, key) => (
                          <li key={key}>
                            <span>{card?.maskedPan}</span>
                            <span>{card?.expiryDate}</span>
                          </li>
                        ))
                      : ''}
                  </ul>
                </Fragment>
              </article>
            </CustomerDetailsSideBar>

            <main className="layout-customerdetails">
              <div className="layout-customerdetails--header">
                {hasCustomer ? (
                  <h5>
                    {data?.customer?.user?.firstName ||
                    data?.customer?.user?.lastName
                      ? `${
                          data?.customer?.user?.firstName ||
                          data?.customer?.user?.lastName
                        }'s Loans`
                      : `User's Loan`}
                  </h5>
                ) : (
                  <h5>...</h5>
                )}
              </div>

              <ErrorBoundary
                fallback={
                  <DataCard noPadding={false}>
                    <EmptyScreen message="Error Fetching Customers" />
                  </DataCard>
                }
              >
                <DataCard noPadding={true}>
                  <Tabs
                    defaultTab={
                      location?.hash === '#blacklist-reports'
                        ? 'blacklistReports'
                        : null
                    }
                  >
                    <TabList>
                      <Tab tabFor="applications" className={'tab-title'}>
                        Applications
                      </Tab>
                      <Tab tabFor="portfolios" className={'tab-title'}>
                        Loan
                      </Tab>
                      <Tab
                        tabFor="customer-information"
                        className={'tab-title'}
                      >
                        Customer Information
                      </Tab>
                      {latestApplicationFormData && (
                        <Tab tabFor="extra-information" className={'tab-title'}>
                          Custom Form Data
                        </Tab>
                      )}
                      {businessAttributes && (
                        <Tab tabFor="businessInfo" className={'tab-title'}>
                          Business Information
                        </Tab>
                      )}
                      <Tab tabFor="supportingDocuments" className={'tab-title'}>
                        Supporting Documents
                      </Tab>
                      <Tab tabFor="blacklistReports" className={'tab-title'}>
                        Blacklist
                      </Tab>
                    </TabList>
                    <TabPanel tabId="applications">
                      {hasCustomer &&
                      data?.customer?.applications?.nodes.length > 0 ? (
                        <Fragment>
                          <Button
                            classes="button-export right handle-export"
                            click_event={handleExportToCsvApplications}
                          >
                            <Export />
                            <span className="export-csv">Export CSV</span>
                          </Button>
                          <DataCard noPadding={false}>
                            <Table>
                              <div className="table__head">
                                {headers.applicationHeader.map(
                                  (header, key) => (
                                    <span
                                      key={key}
                                      className={cx({
                                        Status: header,
                                        'checked-width': header === 'Status',
                                      })}
                                    >
                                      {' '}
                                      {header}{' '}
                                    </span>
                                  ),
                                )}
                              </div>
                              <div className="table__body">
                                {hasCustomer &&
                                  data?.customer?.applications?.nodes?.map(
                                    (application, key) => (
                                      <ul key={key}>
                                        <Link
                                          to={`/v1/applications/${application.applicationNumber}/${application.user.email}`}
                                        >
                                          <li>
                                            <span className="checked-width">
                                              <span
                                                data-title={
                                                  application?.status?.name ===
                                                  'DISBURSED'
                                                    ? 'ACTIVE'
                                                    : removeUnderscore(
                                                        application?.status
                                                          ?.name,
                                                      )
                                                }
                                                className={`tooltip status fixed ${
                                                  application?.status?.name?.toLowerCase() ||
                                                  ''
                                                }`}
                                              >
                                                {abbreviateApplicationStatus(
                                                  application?.status?.name,
                                                )}
                                              </span>
                                            </span>
                                            <span>
                                              <div className="first-td">
                                                {reduceStringLength(
                                                  application?.applicationNumber,
                                                  12,
                                                )}
                                              </div>
                                            </span>
                                            <span>
                                              {nairaAmount(application?.amount)}
                                            </span>
                                            {application.policy !== null ? (
                                              <span>{`${formatLoanDuration(
                                                application.loanDuration,
                                                application.policy,
                                              )} ${getLoanDurationName(
                                                application.policy.durationType,
                                              )}`}</span>
                                            ) : (
                                              <span>N/A</span>
                                            )}
                                            <span>
                                              {nairaAmount(
                                                application.chargesAmount,
                                              )}
                                            </span>
                                            <span>
                                              {nairaAmount(
                                                application.fullAmount,
                                              )}
                                            </span>
                                            <span>
                                              {moment(
                                                application.createdAt,
                                              ).format('lll')}
                                            </span>
                                          </li>
                                        </Link>
                                      </ul>
                                    ),
                                  )}
                              </div>
                            </Table>
                          </DataCard>
                        </Fragment>
                      ) : (
                        <DataCard noPadding={false}>
                          <EmptyScreen
                            noPadding={true}
                            message={'No Application Details Yet'}
                          />
                        </DataCard>
                      )}
                    </TabPanel>
                    <TabPanel tabId="portfolios">
                      {hasCustomer &&
                      data?.customer?.portfolios?.nodes.length > 0 ? (
                        <Fragment>
                          <Button
                            classes="button-export right handle-export"
                            click_event={handleExportToCsvPortfolios}
                          >
                            <Export />
                            <span className="export-csv">Export CSV</span>
                          </Button>
                          <DataCard noPadding={false}>
                            <Table>
                              <div className="table__head">
                                {headers?.portfolioHeader?.map(
                                  (header, key) => (
                                    <span key={key}> {header} </span>
                                  ),
                                )}
                              </div>
                              <div className="table__body">
                                {hasCustomer &&
                                  data?.customer?.portfolios?.nodes?.map(
                                    (portfolio, idx) => (
                                      <ul key={idx}>
                                        <Link
                                          to={`/loans/${portfolio.portfolioNumber}/${portfolio.user.email}`}
                                        >
                                          <li>
                                            <span>
                                              <div className="first-td">
                                                {reduceStringLength(
                                                  portfolio?.portfolioNumber,
                                                  12,
                                                )}
                                              </div>
                                            </span>
                                            <span>
                                              {nairaAmount(portfolio.amount)}
                                            </span>
                                            {portfolio.policy !== null ? (
                                              <span>{`${formatLoanDuration(
                                                portfolio.loanDuration,
                                                portfolio.policy,
                                              )} ${getLoanDurationName(
                                                portfolio.policy.durationType,
                                              )}`}</span>
                                            ) : (
                                              <span>N/A</span>
                                            )}
                                            <span>
                                              {nairaAmount(
                                                portfolio.fullAmount,
                                              )}
                                            </span>
                                            <span>
                                              {nairaAmount(
                                                portfolio.amountDisbursed,
                                              )}
                                            </span>
                                            <span>
                                              {nairaAmount(
                                                portfolio.amountPaid,
                                              )}
                                            </span>
                                            <span>
                                              {moment(
                                                portfolio.createdAt,
                                              ).format('lll')}
                                            </span>
                                          </li>
                                        </Link>
                                      </ul>
                                    ),
                                  )}
                              </div>
                            </Table>
                          </DataCard>
                        </Fragment>
                      ) : (
                        <DataCard noPadding={false}>
                          <EmptyScreen
                            noPadding={true}
                            message={'No Loan Details Yet'}
                          />
                        </DataCard>
                      )}
                    </TabPanel>
                    <TabPanel tabId="customer-information">
                      <div className="information--content">
                        {hasCustomer && (
                          <>
                            <Button
                              classes="right handle-export"
                              click_event={handleExportToCsvCustomers}
                            >
                              <Export />
                              <span className="export-csv">Export CSV</span>
                            </Button>
                            <Table>
                              <div className="table__body">
                                <ul>
                                  <li>
                                    <span>Name</span>
                                    <span className="right-text">
                                      {data.customer?.user?.firstName &&
                                      data.customer?.user?.lastName
                                        ? `${data.customer?.user?.firstName} ${data.customer?.user?.lastName}`
                                        : 'N/A'}
                                    </span>
                                  </li>
                                  <li>
                                    <span>Email</span>
                                    <span className="right-text">
                                      {data.customer?.user?.email}
                                    </span>
                                  </li>
                                  <li>
                                    <span>Phone</span>
                                    <span className="right-text">
                                      {data.customer?.user?.phone}
                                    </span>
                                  </li>
                                  {data.customer?.bvn ? (
                                    <>
                                      <li>
                                        <span>Bvn</span>
                                        <span className="right-text">
                                          {data.customer?.bvn?.number}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Bvn: First Name</span>
                                        <span className="right-text">
                                          {data.customer?.bvn?.firstName}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Bvn: Last Name</span>
                                        <span className="right-text">
                                          {data.customer?.bvn?.lastName}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Bvn: Phone Number</span>
                                        <span className="right-text">
                                          {data.customer?.bvn?.phone}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Bvn: Date of Birth</span>
                                        <span className="right-text">
                                          {moment(
                                            data.customer?.bvn?.dateOfBirth, ['DD-MM-YYYY', 'DD-MMMM-YYYY']
                                        ).format('ll')}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Bvn: Gender</span>
                                        <span className="right-text">
                                          {businessAttributes
                                            ? businessAttributes.gender
                                            : data.customer?.bvn?.gender}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Bvn: Image</span>
                                        <span className="right-text">
                                          {hasUser.bvnStatus &&
                                          hasUser.bvnStatus?.image !==
                                            'false' ? (
                                            <a
                                              href={hasUser.bvnStatus?.image}
                                              rel="noopener noreferrer"
                                              target="_blank"
                                              download={`${data.customer?.bvn?.firstName} 's BVN Image`}
                                            >
                                              Download BVN Image
                                            </a>
                                          ) : (
                                            'N/A'
                                          )}
                                        </span>
                                      </li>
                                    </>
                                  ) : null}
                                  {hasCustomer &&
                                    hasUser &&
                                    data.customer?.user?.userMetadata.map(
                                      (user, key) => (
                                        <li key={key}>
                                          <span>
                                            {(() => {
                                              const name = user?.name;

                                              switch (name) {
                                                case 'selfie':
                                                  return 'Photo';
                                                case 'refereeWorkPlace':
                                                  return 'Referee Address';
                                                case 'governmentId':
                                                  return 'Government ID';
                                                case 'betaLoanAccountNumber':
                                                  return 'FCMB Beta Account Number';
                                                default:
                                                  return capitalizeInitialLetter(
                                                    insertSpaceBeforeCapitalLetter(
                                                      user?.name,
                                                    ),
                                                  );
                                              }
                                            })()}
                                          </span>
                                          <span
                                            className="right-text"
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {(() => {
                                              const name = user?.name;
                                              switch (name) {
                                                case 'workId':
                                                  return (
                                                    <a
                                                      href={user.value}
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                      download={`${user.name} Work Id`}
                                                    >
                                                      View
                                                    </a>
                                                  );
                                                case 'governmentId':
                                                  return (
                                                    <a
                                                      href={user.value}
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                      download={`${user.name} Government Id`}
                                                    >
                                                      View
                                                    </a>
                                                  );
                                                case 'selfie':
                                                  return (
                                                    <a
                                                      href={user.value}
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                      download={`${user.name} Selfie`}
                                                    >
                                                      View
                                                    </a>
                                                  );
                                                case 'workStartDate':
                                                  return user.value
                                                    ? moment(user.value, ['DD-MM-YYYY', 'DD-MMMM-YYYY']).format(
                                                        'll',
                                                      )
                                                    : '';
                                                case 'dateOfBirth':
                                                  return user?.value
                                                    ? moment(
                                                        user?.value,
                                                        ['DD-MM-YYYY', 'DD-MMMM-YYYY']).format('ll')
                                                    : '';
                                                case 'customerGraduatedAmounts':
                                                  return user.value ? (
                                                    <Button
                                                      click_event={
                                                        handleShowEligibilityModal
                                                      }
                                                      classes="edit-processing-fee"
                                                    >
                                                      View List
                                                    </Button>
                                                  ) : (
                                                    ''
                                                  );
                                                default:
                                                  return /undefined/.test(
                                                    user?.value,
                                                  )
                                                    ? 'N/A'
                                                    : user?.value;
                                              }
                                            })()}
                                          </span>
                                        </li>
                                      ),
                                    )}
                                  {kycInformationKeys ? (
                                    <>
                                      {kycInformationKeys?.map(key => {
                                        if (key === 'files') {
                                          const filterFiles =
                                            kycInformationData[key]?.filter(
                                              val =>
                                                !!val?.fileDetails?.url &&
                                                ![
                                                  'selfie',
                                                  'workId',
                                                  'governmentId',
                                                ].includes(val?.name),
                                            );
                                          return filterFiles.map(file => (
                                            <li key={file?.name}>
                                              <span>
                                                {capitalizeInitialLetter(
                                                  unCamelize(file.name),
                                                )}
                                              </span>
                                              <UrlFromS3Provider
                                                file={file?.fileDetails}
                                              >
                                                {({
                                                  exportFromS3,
                                                  loading,
                                                }) => (
                                                  <span className="right-text">
                                                    <a
                                                      onClick={exportFromS3}
                                                      disabled={loading}
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      View
                                                    </a>
                                                  </span>
                                                )}
                                              </UrlFromS3Provider>
                                            </li>
                                          ));
                                        } else {
                                          return typeof kycInformationData[
                                            key
                                          ] == 'string' ? (
                                            <li>
                                              <span>
                                                {capitalizeInitialLetter(
                                                  unCamelize(key),
                                                )}
                                              </span>
                                              <span className="right-text">
                                                {kycInformationData[key]}
                                              </span>
                                            </li>
                                          ) : (
                                            <></>
                                          );
                                        }
                                      })}
                                    </>
                                  ) : null}
                                  {isGraduatedLoan ? (
                                    <>
                                      <Modal
                                        title={'Customer Graduated Amounts'}
                                        classes="cash-repayment"
                                        active={
                                          input.showEligibilityModal?.value
                                        }
                                      >
                                        <Close
                                          className="close-icon"
                                          onClick={handleHideEligibilityModal}
                                        />

                                        <div className="customerEligibilityWrapper">
                                          <div className="customerEligibilityContent">
                                            <span className="">Amount</span>
                                            <span className="">Paid</span>
                                          </div>

                                          {customerGraduatedAmountsData.map(
                                            (customerGraduatedAmounts, idx) => (
                                              <div
                                                key={idx}
                                                className="customerEligibilityContent_item"
                                              >
                                                <p className="amountSpan">
                                                  {`NGN ${numberWithCommas(
                                                    customerGraduatedAmounts?.amount,
                                                  )}`}
                                                </p>
                                                <p
                                                  className={
                                                    customerGraduatedAmounts?.paid
                                                      ? 'customerGraduatedPaidAmount'
                                                      : 'customerGraduatedUnpaidAmount'
                                                  }
                                                >
                                                  {customerGraduatedAmounts?.paid
                                                    ? 'True'
                                                    : 'False'}
                                                </p>
                                              </div>
                                            ),
                                          )}
                                        </div>
                                      </Modal>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {businessAttributes && (
                                    <>
                                      <li>
                                        <span>LASSRA ID</span>
                                        <span className="right-text">
                                          {businessAttributes?.lassraId}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Marital Status</span>
                                        <span className="right-text">
                                          {data.customer?.maritalStatus}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Personal Tax ID NO</span>
                                        <span className="right-text">
                                          {businessAttributes?.taxIdNo}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Highest Educational Level</span>
                                        <span className="right-text">
                                          {
                                            businessAttributes?.highestEducationalLevel
                                          }
                                        </span>
                                      </li>
                                      <li>
                                        <span>Senatorial District</span>
                                        <span className="right-text">
                                          {
                                            businessAttributes?.senatorialDistrict
                                          }
                                        </span>
                                      </li>
                                      <li>
                                        <span>Local Goverment Area</span>
                                        <span className="right-text">
                                          {businessAttributes?.lga}
                                        </span>
                                      </li>
                                      <li>
                                        <span>LCDA</span>
                                        <span className="right-text">
                                          {businessAttributes?.lcda}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Type of Residence</span>
                                        <span className="right-text">
                                          {businessAttributes?.residenceType}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Selected Loan Category</span>
                                        <span className="right-text">
                                          {businessAttributes?.loanProduct}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Has Registered Business ? </span>
                                        <span className="right-text">
                                          {businessAttributes?.hasBusiness}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Cooperative Membership</span>
                                        <span className="right-text">
                                          {businessAttributes.cooperativeMember &&
                                          businessAttributes.cooperativeMember ===
                                            'true'
                                            ? 'Yes'
                                            : 'No'}
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          Willing to join a Business Cluster or
                                          Cooperative
                                        </span>
                                        <span className="right-text">
                                          {businessAttributes.WillingToJoinCooperative &&
                                          businessAttributes.WillingToJoinCooperative ===
                                            'true'
                                            ? 'Yes'
                                            : 'No'}
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          How long have you been a Member ?
                                        </span>
                                        <span className="right-text">
                                          {
                                            businessAttributes?.durationInCooperative
                                          }
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          Cooperative or Association Name
                                        </span>
                                        <span className="right-text">
                                          {businessAttributes?.cooperativeName}
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          Cooperative or Association Address
                                        </span>
                                        <span className="right-text">
                                          {
                                            businessAttributes?.cooperativeAddress
                                          }
                                        </span>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </div>
                            </Table>
                          </>
                        )}
                      </div>
                    </TabPanel>

                    <TabPanel tabId="extra-information">
                      <div className="custom--form">
                        {latestApplicationFormData && (
                          <>
                            <Table>
                              <div className="table__body">
                                {Object.keys(latestApplicationFormData)?.map(
                                  formTitle => {
                                    const formContent = convertObjToKeyValueArr(
                                      latestApplicationFormData[formTitle],
                                    );
                                    return (
                                      <>
                                        {formTitle !== 'path' &&
                                          formTitle !== 'step' && (
                                            <h6>{formTitle}</h6>
                                          )}
                                        <ul>
                                          {formContent.map(
                                            (formValue, formKey) => {
                                              return (
                                                <>
                                                  {(!Array.isArray(
                                                    formValue[1],
                                                  ) ||
                                                    (formValue[1].length > 0 &&
                                                      formValue[1].every(
                                                        item =>
                                                          typeof item ===
                                                          'string',
                                                      ))) && (
                                                    <li key={formKey}>
                                                      <span>
                                                        {capitalizeInitialLetter(
                                                          insertSpaceBeforeCapitalLetter(
                                                            formValue[0],
                                                          ),
                                                        )}
                                                      </span>
                                                      <span className="right-text">
                                                        {moment(
                                                          formValue[1],
                                                          moment.ISO_8601,
                                                          true,
                                                        ).isValid()
                                                          ? moment(
                                                              formValue[1],
                                                            ).format('lll')
                                                          : Array.isArray(
                                                              formValue[1],
                                                            )
                                                          ? formValue[1].join(
                                                              ',',
                                                            )
                                                          : capitalizeInitialLetter(
                                                              formValue[1],
                                                            )}
                                                      </span>
                                                    </li>
                                                  )}
                                                </>
                                              );
                                            },
                                          )}
                                        </ul>
                                      </>
                                    );
                                  },
                                )}
                                {customUploads.length > 0 && (
                                  <>
                                    <h6>Uploads</h6>
                                    <ul>
                                      {customUploads.map(upload => (
                                        <li key={upload.id}>
                                          <span>
                                            {capitalizeInitialLetter(
                                              insertSpaceBeforeCapitalLetter(
                                                upload.name,
                                              ),
                                            )}
                                          </span>
                                          <span className="right-text">
                                            <a
                                              href={upload.value}
                                              rel="noopener noreferrer"
                                              target="_blank"
                                              download={`${upload.name} Government Id`}
                                            >
                                              View
                                            </a>
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  </>
                                )}
                              </div>
                            </Table>
                          </>
                        )}
                      </div>
                    </TabPanel>

                    <TabPanel tabId="businessInfo">
                      <div className="information--content">
                        {businessAttributes && (
                          <div className="table__body">
                            <ul>
                              <li>
                                <span>Company Name</span>
                                <span className="right-text">
                                  {businessAttributes?.companyName}
                                </span>
                              </li>
                              <li>
                                <span>Company Address</span>
                                <span className="right-text">
                                  {businessAttributes?.companyAddress}
                                </span>
                              </li>
                              <li>
                                <span>Local Govt Area</span>
                                <span className="right-text">
                                  {businessAttributes?.companyLga}
                                </span>
                              </li>
                              <li>
                                <span>Type of Business</span>
                                <span className="right-text">
                                  {businessAttributes?.typeOfBusiness}
                                </span>
                              </li>
                              <li>
                                <span>CAC Registration No</span>
                                <span className="right-text">
                                  {businessAttributes?.cacRegNo}
                                </span>
                              </li>
                              <li>
                                <span>Company Tax Identification Number</span>
                                <span className="right-text">
                                  {businessAttributes?.companyTaxId}
                                </span>
                              </li>
                              <li>
                                <span>Business Start Date</span>
                                <span className="right-text">
                                  {businessAttributes?.businessStartDate}
                                </span>
                              </li>
                              <li>
                                <span>Business Sector</span>
                                <span className="right-text">
                                  {businessAttributes?.businessSector}
                                </span>
                              </li>
                              <li>
                                <span>No. of employees</span>
                                <span className="right-text">
                                  {businessAttributes?.noOfEmployees}
                                </span>
                              </li>
                              {businessAttributes?.employees &&
                                businessAttributes?.employees.map(
                                  (employee, idx) => (
                                    <Fragment key={idx}>
                                      <li>
                                        <span>Employee Name</span>
                                        <span className="right-text">
                                          {employee?.employeeName}
                                        </span>
                                      </li>
                                      <li>
                                        <span>Employee Tax ID</span>
                                        <span className="right-text">
                                          {employee?.employeeTaxId}
                                        </span>
                                      </li>
                                    </Fragment>
                                  ),
                                )}
                              <li>
                                <span>Total Monthly Turnover</span>
                                <span className="right-text">
                                  {businessAttributes?.monthlyTurnover}
                                </span>
                              </li>
                              <li>
                                <span>Total Monthly Expenses</span>
                                <span className="right-text">
                                  {businessAttributes?.monthlyExpenses}
                                </span>
                              </li>
                              <li>
                                <span>Total Monthly Savings</span>
                                <span className="right-text">
                                  {businessAttributes?.monthlySavings}
                                </span>
                              </li>
                              <li>
                                <span>Expected Total Monthly Turnover</span>
                                <span className="right-text">
                                  {businessAttributes?.expectedMonthlyTurnover}
                                </span>
                              </li>
                              <li>
                                <span>Expected Total Monthly Expenses</span>
                                <span className="right-text">
                                  {businessAttributes?.expectedMonthlyExpenses}
                                </span>
                              </li>
                              <li>
                                <span>Expected Total Monthly Savings</span>
                                <span className="right-text">
                                  {businessAttributes?.expectedMonthlySavings}
                                </span>
                              </li>
                              <li>
                                <span>Expected No. of employees</span>
                                <span className="right-text">
                                  {businessAttributes?.expectedNewHires}
                                </span>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </TabPanel>

                    {data?.customer?.user?.supportingDocument && (
                      <TabPanel tabId="supportingDocuments">
                        <div className="information--content">
                          <div className="table__body">
                            <ul>
                              {data.customer.user.supportingDocument.map(
                                (document, key) =>
                                  govtIDs.includes(
                                    splitString(document.documentName)[0],
                                  ) ? (
                                    <Fragment key={key}>
                                      <li>
                                        <span>Government-Issued ID type</span>
                                        <span className="right-text">
                                          {(() => {
                                            const documentName = splitString(
                                              document?.documentName,
                                            )[0];
                                            switch (documentName) {
                                              case 'international':
                                                return 'International Passport';
                                              case 'voter':
                                                return "Voter's Card";
                                              case 'national':
                                                return 'National ID';
                                              case 'driver':
                                                return "Driver's License";
                                              default:
                                                return 'Additional Documents';
                                            }
                                          })()}
                                        </span>
                                      </li>
                                      <li key={key}>
                                        <span>Government-Issued ID Number</span>
                                        <span className="right-text">
                                          {
                                            splitString(
                                              document?.documentName,
                                            )[2]
                                          }
                                        </span>
                                      </li>
                                      <li key={key}>
                                        <span> Government-Issued ID</span>

                                        <UrlFromS3Provider file={document.file}>
                                          {({ exportFromS3, loading }) => (
                                            <span className="right-text">
                                              <Button
                                                classes="link"
                                                click_event={exportFromS3}
                                                disabled={loading}
                                              >
                                                View File
                                              </Button>
                                            </span>
                                          )}
                                        </UrlFromS3Provider>
                                      </li>
                                    </Fragment>
                                  ) : (
                                    <li>
                                      <span>
                                        {(() => {
                                          const documentName = splitString(
                                            document?.documentName,
                                          )[0];
                                          switch (documentName) {
                                            case 'WorkId':
                                              return 'Work ID';
                                            case 'RentOffer':
                                              return 'Rent Offer';
                                            case 'GovtIssuedID':
                                              return 'Government Issued ID';
                                            case 'trainingCert':
                                              return 'Training Cert';
                                            case 'taxIdCert':
                                              return 'Tax ID Cert';
                                            case 'lassraId':
                                              return 'Lassra ID';
                                            case 'passportPhoto':
                                              return 'Passport Photographer';
                                            case 'tinCert':
                                              return 'Tin Certificate';
                                            case 'memArt':
                                              return 'Memorandum and articles of association';
                                            case 'cacCertForm':
                                              return 'CAC certificate';
                                            case 'govtId':
                                              return 'Government issued ID';
                                            default:
                                              return 'Additional Documents';
                                          }
                                        })()}
                                      </span>
                                      <UrlFromS3Provider file={document.file}>
                                        {({ exportFromS3, loading }) => (
                                          <span className="right-text">
                                            <Button
                                              classes="link"
                                              click_event={exportFromS3}
                                              disabled={loading}
                                            >
                                              View File
                                            </Button>
                                          </span>
                                        )}
                                      </UrlFromS3Provider>
                                    </li>
                                  ),
                              )}
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                    )}
                    <TabPanel tabId="blacklistReports">
                      <BlacklistReports
                        blacklistDetails={
                          data?.customer?.user?.blacklistDetails
                        }
                        refetch={refetch}
                        userId={data?.customer?.user?.id}
                      />
                    </TabPanel>
                  </Tabs>
                </DataCard>
              </ErrorBoundary>
            </main>
          </Fragment>
        );
      }}
    />
  );
};

export default CustomerDetails;
