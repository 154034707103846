import React, { Fragment } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import {
  SpendAnalysisTab,
  CashFlowAnalysisTab,
  IncomeAnalysisTab,
  BehaviouralAnalysisTab,
  TransactionPatternAnalysisTab,
  DetectedAccountDetailsTab,
} from '..';
import { useClientInfo } from '../../../../Providers-new/Client';

const DecideBankStatementAnalysisTab = ({ decideBankStatementAnalysis }) => {
  const { clientInfo } = useClientInfo();
  return (
    <Fragment>
      <Tabs>
        <TabList>
          <Tab tabFor={'cash-flow-analysis'}>Cash Flow Analysis</Tab>
          <Tab tabFor={'income-analysis'}>Income Analysis</Tab>
          <Tab tabFor={'spend-analysis'}>Spend Analysis</Tab>
          <Tab tabFor={'behavioural-analysis'}>Behavioural Analysis</Tab>
          <Tab tabFor={'transaction-pattern-analysis'}>
            Transaction Pattern Analysis
          </Tab>
          {(clientInfo.requiresDecidePdfAnalysis ||
            decideBankStatementAnalysis?.accountDetails) && (
            <Tab tabFor={'detected-account-details'}>
              Detected Account Details
            </Tab>
          )}
        </TabList>

        <TabPanel tabId={'cash-flow-analysis'}>
          <CashFlowAnalysisTab
            cashFlowAnalysis={decideBankStatementAnalysis.cashFlowAnalysis}
          />
        </TabPanel>

        <TabPanel tabId={'income-analysis'}>
          <IncomeAnalysisTab
            incomeAnalysis={decideBankStatementAnalysis.incomeAnalysis}
          />
        </TabPanel>

        <TabPanel tabId={'spend-analysis'}>
          <SpendAnalysisTab
            spendAnalysis={decideBankStatementAnalysis.spendAnalysis}
          />
        </TabPanel>

        <TabPanel tabId={'behavioural-analysis'}>
          <BehaviouralAnalysisTab
            behaviouralAnalysis={
              decideBankStatementAnalysis.behaviouralAnalysis
            }
          />
        </TabPanel>

        <TabPanel tabId={'transaction-pattern-analysis'}>
          <TransactionPatternAnalysisTab
            transactionPatternAnalysis={
              decideBankStatementAnalysis.transactionPatternAnalysis
            }
          />
        </TabPanel>

        <TabPanel tabId={'detected-account-details'}>
          <DetectedAccountDetailsTab
            detectedAccountDetails={decideBankStatementAnalysis.accountDetails}
          />
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default DecideBankStatementAnalysisTab;
