import React from 'react';
import { withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { useCustomState } from '../lib/CustomHooks';
import { CHANGE_PASSWORD_MUTATION } from '../graphql/mutations';
import { Loader } from '../components';
import { trimGraphQLError } from '../lib/Utils';

const ChangePassword = ({ render }) => {
  const changePwdInput = {
    oldPassword: useCustomState(),
    newPassword: useCustomState(),
    confirmNewPassword: useCustomState(),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  const handleChangePassword = (e, changePwdMutation) => {
    e.preventDefault();

    if (
      !changePwdInput.oldPassword.value ||
      !changePwdInput.newPassword.value ||
      !changePwdInput.confirmNewPassword.value
    ) {
      changePwdInput.showToast.setValue(true);
      changePwdInput.status.setValue('Failed');
      changePwdInput.content.setValue('Please fill all required fields');

      return;
    }

    changePwdMutation({
      variables: {
        oldPassword: changePwdInput.oldPassword.value,
        newPassword: changePwdInput.newPassword.value,
      },
    });
  };

  const handleHideToast = e => {
    e.preventDefault();

    changePwdInput.showToast.setValue(false);
  };

  return (
    <Mutation
      mutation={CHANGE_PASSWORD_MUTATION}
      onCompleted={({ changePassword: { token } }) => {
        if (token != null || token !== 'undefined') {
          localStorage.setItem('api-key', token);
        }
      }}
      onError={error => {
        changePwdInput.showToast.setValue(true);
        changePwdInput.status.setValue('Failed');
        changePwdInput.content.setValue(trimGraphQLError(error.message));
      }}
    >
      {(changePwdMutation, { loading }) => {
        if (loading) {
          return <Loader />;
        }

        return render({
          onSubmit: e => handleChangePassword(e, changePwdMutation),
          input: changePwdInput,
          handleHideToast,
        });
      }}
    </Mutation>
  );
};

export default withRouter(ChangePassword);
