import React, { useEffect, useState } from 'react';
import { BreadCrumbSection, Loader } from '../../components';
import { OriginateFormBuilder } from '@indicina1/originate-form-builder';
import { Box, Button, ButtonGroup, ChakraProvider } from '@chakra-ui/react';
import { Toast } from '../../components';
import { nanoid } from 'nanoid';
import { useKycConfig } from '../../Providers-new/Configuration';

const KycConfigurations = () => {
  const {
    handleUpdateAttributes,
    kycConfiguration,
    loading,
    handleHideToast,
    data,
  } = useKycConfig();

  const kycTabs = [
    {
      id: nanoid(),
      name: 'Customer Information',
      builders: [
        {
          id: 'Y89m4vwiREmrB41zkW_vR',
          title: 'Dropdown',
          type: 'dropdown',
          badge: '',
          body: 'Dropdown with multiple options',
          subtitle: [],
          sort: 16,
          required: true,
          options: [
            {
              id: 'TfeVdtbTbNCMArUf8Lvk2',
              option: 'Single',
              linked: false,
              linkedForm: '',
            },
            {
              id: '_hw-WVU0soLWK7VXnLJPK',
              option: 'Married',
              linked: false,
              linkedForm: '',
            },
            {
              id: 'u9qWPafBbtdZVPQ64BX-M',
              option: 'Divorced',
              linked: false,
              linkedForm: '',
            },
          ],
          instruction: 'Marital Status',
          error: '',
        },
        {
          id: 'IJ_1QFCmBm-i0CseZlh1j',
          title: 'Dropdown',
          type: 'dropdown',
          badge: '',
          body: 'Dropdown with multiple options',
          subtitle: [],
          sort: 16,
          required: true,
          options: [
            {
              id: 'TfeVdtbTbNCMArUf8Lvk2',
              option: 'Male',
              linked: false,
              linkedForm: '',
            },
            {
              id: 'pSWRImEoiGV_S6BKhcxBT',
              option: 'Female',
              linked: false,
              linkedForm: '',
            },
          ],
          instruction: 'Gender',
          error: '',
        },
        {
          id: '9Fv8u3u5qDafA_soDahJe',
          title: 'Numeric',
          type: 'number',
          badge: '',
          body: 'Numeric inputs',
          subtitle: [],
          sort: 5,
          required: true,
          instruction: 'House Number',
          error: '',
        },
        {
          id: 'ODney9p0LhVbC-7ZGdRVf',
          title: 'Text',
          type: 'text',
          badge: '',
          body: 'Single line text inputs or multiple lines',
          subtitle: [],
          sort: 1,
          required: true,
          instruction: 'Residential Address',
          error: '',
        },
        {
          id: 'DUzavM5F9VAWB7-KtFaLB',
          title: 'Select State',
          badge: '',
          body: 'Select state',
          subtitle: [],
          sort: 12,
          type: 'select',
          required: true,
          instruction: 'Select State',
          error: '',
        },
      ],
      error: '',
      linkedToOption: false,
    },
    {
      id: nanoid(),
      name: 'Minimum Requirement',
      builders: [
        {
          id: nanoid(),
          instruction: 'List All minimum reuirements',
          title: 'List',
          type: 'list',
          badge: '',
          body: 'List of minimum requirements',
          subtitle: [],
          sort: 1,
          required: true,
          options: [
            { id: nanoid(), option: 'Be at least 18 years of age' },
            { id: nanoid(), option: 'A valid BVN' },
            { id: nanoid(), option: 'A personal email account' },
            { id: nanoid(), option: 'A personal bank account' },
            {
              id: nanoid(),
              option: 'A Debit Card with at least 3 months Validity',
            },
          ],
        },
      ],
      error: '',
      linkedToOption: false,
    },
  ];

  const [tabs, setTabs] = useState(kycTabs);

  const handleSubmit = data => {
    handleUpdateAttributes({ form: data[0], minimumRequirements: data[1] });
  };

  useEffect(() => {
    if (
      kycConfiguration &&
      kycConfiguration?.form &&
      kycConfiguration?.minimumRequirements &&
      Object.keys(kycConfiguration.form).length > 0 &&
      Object.keys(kycConfiguration.minimumRequirements).length > 0
    ) {
      const formatedForm = JSON.parse(
        JSON.stringify([
          kycConfiguration?.form,
          kycConfiguration?.minimumRequirements,
        ]),
      );
      setTabs(formatedForm);
    }
  }, [kycConfiguration]);

  return (
    <>
      <BreadCrumbSection />
      <ChakraProvider>
        {loading && <Loader loadingText="loading..." />}
        <Toast
          title="KYC Configurations"
          content={data && data.content && data.content.value}
          classes={data && data.content && data.status.value}
          active={data && data.content && data.showToast.value}
          clickEvent={handleHideToast}
        />
        <div style={{ scale: '130%', marginTop: '120px' }}>
          <Box
            width={'100vw'}
            display="flex"
            flexDirection={'column'}
            alignItems={'center'}
            bg="white"
          >
            <Box width={'60%'}>
              <OriginateFormBuilder
                onSubmit={handleSubmit}
                defaultTabs={tabs}
                kyc={true}
                key={nanoid()}
              >
                <ButtonGroup width={'100%'} mt="3" spacing={10} mb={3}>
                  <ButtonGroup width={'100%'} spacing={10}>
                    <Button
                      px={10}
                      ml="auto"
                      type="submit"
                      colorScheme={'blue'}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </ButtonGroup>
              </OriginateFormBuilder>
            </Box>
          </Box>
        </div>
      </ChakraProvider>
    </>
  );
};

export default KycConfigurations;
