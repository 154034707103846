import { CustomSkeleton } from '@/components/CustomSkeleton';
import { Input, NumericInput } from '@/components/forms';
import {
  Avatar,
  Box,
  Flex,
  SimpleGrid,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react';
import { join } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewCustomerDetailsFormFields } from '../../../forms.type';

type Props = {
  customerBvnDetailsLoading: boolean;
  customerBvnDetailsResponse: any;
};

const BvnDetails = ({
  customerBvnDetailsLoading,
  customerBvnDetailsResponse,
}: Props) => {
  const [isLg] = useMediaQuery('(min-width: 991px)');
  const { image, firstName, lastName, phone, gender } =
    customerBvnDetailsResponse || {};

  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<NewCustomerDetailsFormFields>();

  const bvnValue = watch('bvn');

  useEffect(() => {
    if (bvnValue && bvnValue.length === 11) {
      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('phone', phone);
      setValue('gender', gender);
    }
  }, [bvnValue, firstName, lastName, phone, gender, setValue]);

  return (
    <Stack gap={4}>
      <Flex flex={1} gap={4} flexDirection={isLg ? 'row' : 'column'}>
        <CustomSkeleton isLoaded={!customerBvnDetailsLoading}>
          <Box pos="relative">
            <Avatar
              bg="blue.500"
              color="white"
              borderRadius={0}
              h={'185px'}
              w={'180px'}
              size={isLg ? '2xl' : 'xl'}
              src={image !== 'false' && image}
              name={join([firstName, lastName], ' ')}
            />
          </Box>
        </CustomSkeleton>

        <Flex
          gap={2}
          flex={1}
          alignItems="flex-start"
          flexDir="column"
          w="100%"
        >
          <Flex
            flexDir="column"
            flex={1}
            gap={1}
            justifyContent="space-between"
            w="100%"
          >
            {customerBvnDetailsLoading ? (
              <>
                {Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <Flex gap={2} flexDir="column" key={index}>
                      <CustomSkeleton
                        as="div"
                        h={6}
                        w="100%"
                        isLoaded={!customerBvnDetailsLoading}
                      />
                      <CustomSkeleton
                        as="div"
                        h={10}
                        w="100%"
                        isLoaded={!customerBvnDetailsLoading}
                      />
                    </Flex>
                  ))}
              </>
            ) : (
              <>
                <Input
                  label="Surname"
                  w="100%"
                  isReadOnly
                  {...register('lastName')}
                  defaultValue={lastName}
                  opacity={0.4}
                  cursor="not-allowed"
                />
                <Input
                  label="First Name"
                  w="100%"
                  isReadOnly
                  {...register('firstName')}
                  defaultValue={firstName}
                  opacity={0.4}
                  cursor="not-allowed"
                />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>

      {customerBvnDetailsLoading ? (
        <SimpleGrid columns={isLg ? 2 : 1} spacing={10}>
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <Flex gap={2} flexDir="column" key={index}>
                <CustomSkeleton
                  as="div"
                  h={6}
                  w="100%"
                  isLoaded={!customerBvnDetailsLoading}
                />
                <CustomSkeleton
                  as="div"
                  h={10}
                  w="100%"
                  isLoaded={!customerBvnDetailsLoading}
                />
              </Flex>
            ))}
        </SimpleGrid>
      ) : (
        <SimpleGrid columns={isLg ? 2 : 1} spacing={10}>
          <Controller
            control={control}
            name="phone"
            rules={{
              minLength: {
                value: 10,
                message: 'Phone number must be 10 digits',
              },
              maxLength: {
                value: 10,
                message: 'Phone number must be 10 digits',
              },
              required: 'BVN is required',
            }}
            defaultValue={phone}
            render={({ field: { onChange, onBlur, name, value } }) => (
              <NumericInput
                type="tel"
                name={name}
                value={value}
                maxLength={10}
                onBlur={onBlur}
                allowLeadingZeros
                onChange={onChange}
                label="Phone No."
                thousandSeparator={false}
                errorMessage={errors?.phone?.message}
                isInvalid={!!errors?.phone?.message}
              />
            )}
          />
          <Input label="Email" w="100%" type="email" {...register('email')} />
          <Input
            label="Gender"
            w="100%"
            isReadOnly
            opacity={0.4}
            cursor="not-allowed"
            {...register('gender')}
            defaultValue={gender}
          />
          <Input label="House Address" w="100%" {...register('address')} />
          <Input
            label="Local Government"
            w="100%"
            {...register('localGovernment')}
          />
        </SimpleGrid>
      )}
    </Stack>
  );
};

export default BvnDetails;
