import React from 'react';

const LogoutIcon = props => (
  <svg width={10} height={12} fill="none" {...props}>
    <path
      d="M4.259 11.408a.592.592 0 00-.593-.593H1.185v-9.63h2.481a.592.592 0 100-1.185H0v12h3.666a.592.592 0 00.593-.592z"
      fill="#EB5757"
    />
    <path
      d="M9.13 6.367c.011-.016.023-.03.035-.045a.578.578 0 00.03-.053c0-.016.015-.03.023-.048a.594.594 0 00.02-.065c0-.014 0-.03.012-.043a.593.593 0 00.012-.111.593.593 0 00-.012-.11c0-.016 0-.03-.012-.044a.594.594 0 00-.02-.065c0-.016-.016-.032-.024-.048a.58.58 0 00-.03-.053c-.009-.016-.023-.03-.035-.045-.011-.016-.017-.026-.027-.036l-2.518-2.72a.592.592 0 10-.868.805L7.31 5.408H3.283a.592.592 0 000 1.184h4.028L5.716 8.314a.592.592 0 00.868.805l2.518-2.724c.012 0 .02-.016.027-.028z"
      fill="#EB5757"
    />
  </svg>
);

export default LogoutIcon;
