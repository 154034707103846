import { gql } from 'apollo-boost';

export const UPDATE_REPAYMENT_DUE_DATE = gql`
  mutation MP_UpdateRepaymentDueDate($input: UpdateRepaymentDueDateInput!) {
    updateRepaymentDueDate(input: $input) {
      repayment {
        id
        amountPaid
        interestPortion
        principalPortion
        principalBalance
        totalPayment
        lateFee
        repaymentDate
        dueDate
        status {
          name
        }
        isRemitaRepayment
        outstandingPayment
      }
    }
  }
`;
