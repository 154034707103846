import { useQuery } from '@apollo/react-hooks';

import { GET_AVAILABLE_ROLES_AND_PERMISSIONS } from './queries';

const useRolesAndPermissions = () => {
  let output = {
    roles: [],
    permissions: [],
  };

  const qResult = useQuery(GET_AVAILABLE_ROLES_AND_PERMISSIONS);
  const { data, loading, error } = qResult;

  if (!loading && data && data.availableRoles && data.availablePermissions) {
    output = {
      roles: data.availableRoles,
      permissions: data.availablePermissions,
    };
  }

  return {
    loading,
    error,
    ...output,
    result: qResult,
  };
};

export { useRolesAndPermissions };
