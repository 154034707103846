import React from 'react';
import { withRouter } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import { GET_AVAILABLE_ROLES } from '../graphql/queries';
import { Loader } from '../components';

const AppUserRoles = ({ render }) => {
  return (
    <Query
      query={GET_AVAILABLE_ROLES}
      notifyOnNetworkStatusChange={true}
      fetchPolicy={'network-only'}
    >
      {({ loading, data }) => {
        if (loading) return <Loader />;

        return render({
          roles: data.availableRoles,
        });
      }}
    </Query>
  );
};

export default withRouter(AppUserRoles);
