import { gql } from 'apollo-boost';

export const GET_AVAILABLE_ROLES = gql`
  query MP_GetAvailableRoles {
    availableRoles(where: {}) {
      id
      name
      description
      permissions {
        id
        name
        description
        category
      }
    }
  }
`;
