import React from 'react';
import moment from 'moment';
import { reduceStringLength } from '../../../../lib/Utils';
import { Fragment } from 'react/cjs/react.production.min';
import { Button, DataCard, EmptyScreen, Loader } from '../../../../components';

const LoanHistoryTab = ({
  portfolioData,
  handlePagination,
  loanHistoryLoading,
}) => {
  return (
    <Fragment>
      <div className="timeline-body">
        <div className="timeline-item">
          {portfolioData?.portfolio?.histories?.nodes?.length ? (
            portfolioData?.portfolio?.histories?.nodes?.map(
              (historyData, key) => (
                <ul>
                  <li key={key}>
                    <div className="timeline-content">
                      <p>
                        {historyData.comment != null
                          ? reduceStringLength(historyData.comment)
                          : 'N/A'}{' '}
                      </p>
                      <div className="history_info">
                        <p className="info">
                          <span className="title">Date:</span>
                          {moment(historyData.updatedAt).format('llll')}
                        </p>
                        <p className="info">
                          <span className="title">Action by:</span>
                          {historyData?.actionBy?.firstName ||
                          historyData?.actionBy?.lastName
                            ? `${historyData?.actionBy?.lastName} ${historyData?.actionBy?.firstName}`
                            : 'System'}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              ),
            )
          ) : (
            <DataCard>
              <EmptyScreen message="No Loan History" />
            </DataCard>
          )}
        </div>
      </div>
      {portfolioData?.portfolio?.histories?.pageInfo?.endCursor &&
        (loanHistoryLoading ? (
          <Loader />
        ) : (
          <Button classes="center" click_event={handlePagination}>
            Load More
          </Button>
        ))}
    </Fragment>
  );
};

export default LoanHistoryTab;
