import React, { Fragment } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import {
  BreadCrumbSection,
  Button,
  CustomerDetailsSideBar,
  DataCard,
  EmptyScreen,
  Input,
  Select,
  Toast,
} from '../../components';
import {
  Sidebar,
  MakeManualRepaymentPopup,
  UpdateExternallyDisbursedLoan,
  CloseOutLoan,
  AdvanceApplicationWorkFlow,
  PortfolioDetailsTab,
  ChangeRepaymentDate,
  RepaymentBreakdownTab,
  LoanHistoryTab,
  SupportingDocumentsTab,
  MandateHistoryTab,
  CloseOutLoanBeforeDisbursement,
  TransactionsTab,
  DisburseLoanToBankFlow,
} from './PortfolioDetailsSubFolder/index';

import { useClientInfo } from '../../Providers-new/Client';

import { PortfolioDetailsProvider } from '../../Providers';

import { useMutateUpdateRepaymentDueDate } from '../../Providers-new/EnhancedRepayment';

import { useMutateCloseOutLoan } from '../../Providers-new/CloseOutLoan';

import { ErrorBoundary } from 'react-error-boundary';
import './_Loans.scss';
import Constants from '../../lib/Constants';
import RecovaMandateHistoryTab from './PortfolioDetailsSubFolder/Tabs/RecovaMandateHistoryTab';
import { useQuery } from '@apollo/react-hooks';
import { capitalizeInitialLetter, removeUnderscore } from '../../lib/Utils';
import { GET_ENUM } from '../../Providers-new/EnumTypes';

const PortfolioDetails = ({
  portfolioData = {},
  error,
  customerName,
  facilityInfo,
  isVisible,
  refetch,
  handleLoanHistoryPagination,
  loanHistoryLoading,
  currentTab,
  setCurrentTab,
  runSearchQuery,
  loanHistoryInput,
}) => {
  const repaymentId = portfolioData?.portfolio?.repayments[0]?.id;
  const salaryDay = portfolioData?.portfolio?.application?.credit?.salaryDay;
  const nextDueDate = portfolioData?.portfolio?.repayments?.find(
    repayment => repayment.status.name === 'PENDING',
  );

  const { data: portfolioStatus } = useQuery(GET_ENUM, {
    variables: {
      type: 'PortfolioStatus',
    },
  });

  let portfolioStatuses = [];

  if (portfolioStatus) portfolioStatuses = portfolioStatus.enumValues;

  const {
    handleModalToggle,
    error: updateRepaymentError,
    data: updateRepaymentData,
    handleUpdateRepaymentDueDate,
    startDate,
    setStartDate,
    repayDate,
    handleRepayDate,
    handleHideToast: UpdateRepaymentToast,
  } = useMutateUpdateRepaymentDueDate({
    repaymentId,
    salaryDay,
  });

  const {
    handleHideToast: closeOutLoanToast,
    loading: closeOutLoanLoading,
    handleModalToggle: closeOutLoanToggle,
    data: closeOutLoanData,
    error: closeOutLoanError,
    handleCloseOutLoan,
  } = useMutateCloseOutLoan({
    portfolio: portfolioData.portfolio,
  });

  const loanCategoryId =
    portfolioData?.portfolio?.application?.loanCategory?.id;

  const { clientInfo: { requiresExternalDisbursement } = {} } = useClientInfo({
    privateFields: false,
    loanCategoryId,
  });

  const applicationMeta = portfolioData?.portfolio?.application?.meta?.length
    ? portfolioData.portfolio.application.meta.map(item => item.document)
    : [];
  const applicationMetaKey = portfolioData?.portfolio?.application?.meta?.length
    ? portfolioData.portfolio.application.meta.map(item => ({
        document: item.document,
        key: item.key,
      }))
    : [];

  const hasApplicationMeta = applicationMeta.length >= 1;

  const repaymentDates = portfolioData?.portfolio?.repayments?.filter(
    item => item.repaymentDate,
  );

  const validateDisburseMethod = () => {
    return portfolioData?.portfolio?.application?.approvalWorkflow?.nextStep ? (
      <AdvanceApplicationWorkFlow portfolioData={portfolioData} />
    ) : (
      <DisburseLoanToBankFlow portfolioData={portfolioData} />
    );
  };

  if (error || updateRepaymentError || closeOutLoanError) {
    return (
      <Fragment>
        <BreadCrumbSection unlinklast={true} />
        <Toast
          title="Portfolio Details"
          content={Constants.errorMessages.NETWORK_ERROR}
          classes="Failed"
          active={true}
        />
        <main className="layout-customerdetails">
          <CustomerDetailsSideBar>
            <Sidebar error={error} />
          </CustomerDetailsSideBar>
          <DataCard noPadding={true}>
            <EmptyScreen message="Error Fetching Loan Details" />
          </DataCard>
        </main>
      </Fragment>
    );
  }

  return (
    <>
      <Fragment>
        <BreadCrumbSection unlinklast={true} />
        <CustomerDetailsSideBar>
          <Sidebar
            portfolioData={portfolioData}
            isVisible={isVisible}
            facilityInfo={facilityInfo}
            customerName={customerName}
            error={error}
            refetch={refetch}
          />
        </CustomerDetailsSideBar>
        <main className="layout-customerdetails">
          <ErrorBoundary
            fallback={
              <DataCard noPadding={true}>
                <EmptyScreen message="Error Fetching Loan Details" />
              </DataCard>
            }
          >
            <div className="layout-customerdetails--header">
              {isVisible
                ? portfolioData?.portfolio && (
                    <h5>
                      {facilityInfo?.facilityName
                        ? `${facilityInfo?.facilityName}'s Loan`
                        : "User's Loan"}
                    </h5>
                  )
                : portfolioData?.portfolio && (
                    <h5>
                      {portfolioData?.portfolio?.user?.firstName
                        ? `${portfolioData?.portfolio?.user?.firstName}'s Loan`
                        : `User's Loan`}
                    </h5>
                  )}
              {portfolioData?.portfolio?.status &&
              (portfolioData.portfolio.status.name === 'DISBURSED' ||
                portfolioData.portfolio.status.name === 'OVERDUE') ? (
                <MakeManualRepaymentPopup
                  portfolioData={portfolioData}
                  closeOutLoanToggle={closeOutLoanToggle}
                />
              ) : (
                ''
              )}

              {portfolioData?.portfolio?.status &&
              (portfolioData.portfolio.status.name === 'PENDING_DISBURSEMENT' ||
                portfolioData.portfolio.status.name ===
                  'PENDING_MANUAL_DISBURSEMENT')
                ? (requiresExternalDisbursement === 'true' ||
                    requiresExternalDisbursement) && (
                    <UpdateExternallyDisbursedLoan
                      portfolioData={portfolioData}
                    />
                  )
                : ''}

              <CloseOutLoanBeforeDisbursement portfolioData={portfolioData} />

              <CloseOutLoan
                closeOutLoanToast={closeOutLoanToast}
                closeOutLoanLoading={closeOutLoanLoading}
                closeOutLoanToggle={closeOutLoanToggle}
                closeOutLoanData={closeOutLoanData}
                handleCloseOutLoan={handleCloseOutLoan}
              />

              {portfolioData?.portfolio?.status.name ===
                'PENDING_MANUAL_DISBURSEMENT' && validateDisburseMethod()}
            </div>

            <ChangeRepaymentDate
              portfolioData={portfolioData}
              handleModalToggle={handleModalToggle}
              updateRepaymentData={updateRepaymentData}
              handleUpdateRepaymentDueDate={handleUpdateRepaymentDueDate}
              startDate={startDate}
              setStartDate={setStartDate}
              repayDate={repayDate}
              handleRepayDate={handleRepayDate}
              UpdateRepaymentToast={UpdateRepaymentToast}
            />

            <DataCard noPadding={true}>
              <Tabs
                onChange={tabId => {
                  setCurrentTab(tabId);
                }}
                defaultTab={currentTab}
              >
                <TabList>
                  <Tab tabFor="portfolio-details" className={'tab-title'}>
                    Loan Details
                  </Tab>
                  {portfolioData?.portfolio?.repayments?.length > 0 && (
                    <Tab tabFor="repayment-breakdown" className={'tab-title'}>
                      Repayment Breakdown
                    </Tab>
                  )}
                  <Tab tabFor="portfolio-transactions" className={'tab-title'}>
                    Transactions
                  </Tab>
                  {portfolioData?.portfolio?.histories?.nodes.length > 0 && (
                    <Tab tabFor="loan-history" className={'tab-title'}>
                      Loan History
                    </Tab>
                  )}
                  {portfolioData?.portfolio?.debitMandate?.history != null && (
                    <Tab tabFor="mandate-history" className={'tab-title'}>
                      Mandate History
                    </Tab>
                  )}

                  {portfolioData?.portfolio?.debitMandateMeta?.histories.nodes
                    .length > 0 && (
                    <Tab
                      tabFor="recova-mandate-history"
                      className={'tab-title'}
                    >
                      Mandate History
                    </Tab>
                  )}

                  <Tab tabFor={'supporting-documents'}>
                    Supporting Documents
                  </Tab>
                </TabList>

                <TabPanel tabId="portfolio-details">
                  <div className="information--content">
                    {portfolioData?.portfolio && (
                      <PortfolioDetailsTab
                        portfolioData={portfolioData}
                        nextDueDate={nextDueDate}
                      />
                    )}
                  </div>
                </TabPanel>
                <TabPanel tabId="repayment-breakdown">
                  <DataCard>
                    <div className="repayment-breakdown">
                      <RepaymentBreakdownTab
                        portfolioData={portfolioData}
                        repaymentDates={repaymentDates}
                        refetch={refetch}
                      />
                    </div>
                  </DataCard>
                </TabPanel>
                <TabPanel tabId="portfolio-transactions">
                  <DataCard>
                    <div className="portfolio-transactions">
                      <TransactionsTab
                        portfolioData={portfolioData}
                        repaymentDates={repaymentDates}
                        refetch={refetch}
                      />
                    </div>
                  </DataCard>
                </TabPanel>
                <TabPanel tabId="loan-history">
                  <div className="history-timeline">
                    <DataCard>
                      <div className="loan-history-search">
                        <Input
                          label="Search by Comment"
                          placeholder="Comment"
                          {...loanHistoryInput.searchParams.commentLike.input}
                        />

                        <Select
                          label="Search by Old Portfolio Status"
                          {...loanHistoryInput.searchParams.oldPortfolioStatus
                            .input}
                        >
                          <option value="">All</option>
                          {portfolioStatuses.map((status, index) => (
                            <option key={index} value={status.name}>
                              {status.name === 'DISBURSED'
                                ? 'Active'
                                : removeUnderscore(
                                    capitalizeInitialLetter(status.name),
                                  )}
                            </option>
                          ))}
                        </Select>

                        <Select
                          label="Search by New Portfolio Status"
                          {...loanHistoryInput.searchParams.newPortfolioStatus
                            .input}
                        >
                          <option value="">All</option>
                          {portfolioStatuses.map((status, index) => (
                            <option key={index} value={status.name}>
                              {status.name === 'DISBURSED'
                                ? 'Active'
                                : removeUnderscore(
                                    capitalizeInitialLetter(status.name),
                                  )}
                            </option>
                          ))}
                        </Select>
                        <Button click_event={runSearchQuery}>Search</Button>
                      </div>

                      <div className="timeline timeline-search">
                        <LoanHistoryTab
                          portfolioData={portfolioData}
                          handlePagination={handleLoanHistoryPagination}
                          loanHistoryLoading={loanHistoryLoading}
                          setCurrentTab={setCurrentTab}
                        />
                      </div>
                    </DataCard>
                  </div>
                </TabPanel>

                <TabPanel tabId="mandate-history">
                  <MandateHistoryTab portfolioData={portfolioData} />
                </TabPanel>

                {portfolioData?.portfolio?.debitMandateMeta?.histories.nodes
                  .length > 0 && (
                  <TabPanel tabId="recova-mandate-history">
                    <RecovaMandateHistoryTab
                      histories={
                        portfolioData?.portfolio?.debitMandateMeta?.histories
                      }
                    />
                  </TabPanel>
                )}

                <TabPanel tabId={'supporting-documents'}>
                  <div className="supporting-doc">
                    <SupportingDocumentsTab
                      hasApplicationMeta={hasApplicationMeta}
                      applicationMeta={applicationMeta}
                      portfolioData={portfolioData}
                      applicationMetaKey={applicationMetaKey}
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </DataCard>
          </ErrorBoundary>
        </main>
      </Fragment>
    </>
  );
};

const PortfolioDetailsWrapper = () => (
  <PortfolioDetailsProvider
    render={({
      portfolioData,
      error,
      customerName,
      portfolioInterest,
      toggleApproveOfflineRepaymentPopup,
      toggleDeclineOfflineRepaymentPopup,
      handleHideToast,
      input,
      isVisible,
      facilityInfo,
      refetch,
      handlePagination,
      loanHistoryLoading,
      currentTab,
      setCurrentTab,
      runSearchQuery,
      loanHistoryInput,
    }) => (
      <PortfolioDetails
        portfolioData={portfolioData}
        error={error}
        customerName={customerName}
        portfolioInterest={portfolioInterest}
        toggleApproveOfflineRepaymentPopup={toggleApproveOfflineRepaymentPopup}
        toggleDeclineOfflineRepaymentPopup={toggleDeclineOfflineRepaymentPopup}
        handleHideToast={handleHideToast}
        input={input}
        isVisible={isVisible}
        facilityInfo={facilityInfo}
        refetch={refetch}
        handleLoanHistoryPagination={handlePagination}
        loanHistoryLoading={loanHistoryLoading}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        runSearchQuery={runSearchQuery}
        loanHistoryInput={loanHistoryInput}
      />
    )}
  />
);

export default PortfolioDetailsWrapper;
