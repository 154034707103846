import { gql } from 'apollo-boost';

export const UPLOAD_SUPPORTING_DOCUMENT_AND_SAVE_TO_APPLICATION_META_MUTATION = gql`
  mutation MP_UploadSupportingDocumentAndSaveToApplicationMetaMutation(
    $userId: ID!
    $documentName: String!
    $file: Upload!
    $applicationId: ID!
  ) {
    uploadSupportingDocumentAndSaveToApplicationMeta(
      input: {
        userId: $userId
        file: $file
        documentName: $documentName
        applicationId: $applicationId
      }
    ) {
      id
      documentName
      file {
        key
        bucket
      }
      status
    }
  }
`;
