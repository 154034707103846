import React, { Fragment } from 'react';
import {
  Button,
  Input,
  Loader,
  Notification,
  Popup,
  Toast,
} from '../../../../components';
import { CompleteIcon } from '../../../../components/Icons';

const ModifyInterestRate = ({
  data,
  handleHideApplicationInterestRateToast,
  modifyApplicationInterestRateLoader,
  handleApplicationInterestRateModalToggle,
  modifyApplicationInterestRateDataInput,
  handleModifyApplicationInterestRate,
}) => {
  return (
    <div>
      <Fragment>
        <div className="moratorium-popup">
          {modifyApplicationInterestRateLoader ? (
            <Loader loadingText="loading..." />
          ) : (
            <Fragment>
              <Toast
                title="Update Interest Rate"
                content={modifyApplicationInterestRateDataInput.content.value}
                clickEvent={handleHideApplicationInterestRateToast}
                active={modifyApplicationInterestRateDataInput.showToast.value}
                classes={modifyApplicationInterestRateDataInput.status.value}
              />
              {modifyApplicationInterestRateDataInput.showNotificationPopup
                .value && (
                <Notification>
                  <div className="notification_icon-holder">
                    <CompleteIcon />
                  </div>
                  <h5 className={'center-text'}>
                    <p>
                      You have successfully updated the interest for application{' '}
                      <b>{data.application.applicationNumber}</b>. The repayment
                      breakdown has been recalculated and your customer has been
                      notified.
                    </p>
                  </h5>
                </Notification>
              )}

              <Popup
                title={'Update Interest Rate'}
                classes="cash-repayment"
                active={modifyApplicationInterestRateDataInput.showModal.value}
              >
                <form onSubmit={handleModifyApplicationInterestRate}>
                  <div className="cash-repayment-table">
                    <Input
                      label={'Interest Rate'}
                      className="number-input"
                      type="number"
                      placeholder={'Enter new Interest rate'}
                      required
                      {...modifyApplicationInterestRateDataInput
                        .interestRatePercent.input}
                    />

                    <div className="duration-aside">%</div>

                    <div className="moratorium-button-container">
                      <div className="cancel">
                        <Button
                          click_event={handleApplicationInterestRateModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <Button classes="secondary" type="submit">
                        Update
                      </Button>
                    </div>
                  </div>
                </form>
              </Popup>
            </Fragment>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default ModifyInterestRate;
