/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Table } from '../../../../components';
import {
  capitalizeInitialLetter,
  getLastElement,
  nairaAmount,
  sumColumn,
} from '../../../../lib/Utils';
import EditRepayment from './EditRepayment';

const headers = {
  repaymentsHeader: [
    'Status',
    'Principal',
    'Interest',
    'Late Fee',
    'Outstanding',
    'Due Date',
    'Paid',
    'Repayment Date',
  ],
  loanHistory: ['Old Status', 'New Status', 'Comment', 'Created On'],
};

const RepaymentBreakdownTab = ({ portfolioData, repaymentDates }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editRepaymentErrorMessage, setEditRepaymentErrorMessage] =
    useState('');
  const [editRepaymentSuccessMessage, setEditRepaymentSuccessMessage] =
    useState(false);
  const [repaymentFilterParam, setRepaymentFilterParam] = useState('default');
  const [filteredRepayment, setFilteredRepayment] = useState([]);

  useEffect(() => {
    if (portfolioData?.portfolio?.repayments) {
      const canEdit = portfolioData?.portfolio?.repayments?.some(
        repayment => repayment.status.name === 'PENDING',
      );

      setIsEditable(canEdit);
    }
  }, [portfolioData]);

  const toggleEditTable = () => setIsEditing(props => !props);

  useEffect(() => {
    if (editRepaymentErrorMessage) {
      setTimeout(() => {
        setEditRepaymentErrorMessage('');
      }, 4000);
    }
  }, [editRepaymentErrorMessage]);

  useEffect(() => {
    if (editRepaymentSuccessMessage) {
      setTimeout(() => {
        setEditRepaymentSuccessMessage(false);
      }, 4000);
    }
  }, [editRepaymentSuccessMessage]);

  const filterRepayments = filterParam => {
    switch (filterParam) {
      case 'paid':
      case 'cancelled':
      case 'pending':
      case 'suspended':
        setFilteredRepayment(
          portfolioData?.portfolio?.repayments.filter(
            item => item.status.name.toLowerCase() === filterParam,
          ),
        );
        break;

      case 'all':
        setFilteredRepayment(portfolioData?.portfolio?.repayments);
        break;
      default:
        setFilteredRepayment(
          portfolioData?.portfolio?.repayments.filter(
            item =>
              item.status.name.toLowerCase() === 'paid' ||
              item.status.name.toLowerCase() === 'pending' ||
              item.status.name.toLowerCase() === 'suspended',
          ),
        );
    }
  };

  useEffect(() => {
    filterRepayments(repaymentFilterParam);
  }, [repaymentFilterParam, portfolioData]);

  return (
    <Fragment>
      {portfolioData?.portfolio && isEditing ? (
        <EditRepayment
          toggleEditTable={toggleEditTable}
          portfolio={portfolioData?.portfolio}
        />
      ) : (
        <Fragment>
          <section className="repayment-top-buttons">
            <section className="filter-repayments">
              <label>Filter:</label>

              <section className="filter-repayments-select">
                <select
                  onChange={e => setRepaymentFilterParam(e?.target?.value)}
                  defaultValue="default"
                >
                  <option value="default">Default</option>
                  <option value="all">All</option>
                  <option value="paid">Paid</option>
                  <option value="pending">Pending</option>
                  <option value="suspended">Suspended</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </section>
            </section>

            {isEditable && portfolioData && (
              <section className="edit-repayment-buttons">
                <Button onClick={toggleEditTable}>Edit Repayment</Button>
              </section>
            )}
          </section>
          {filteredRepayment.length > 0 ? (
            <Table>
              <div className="table__head">
                {headers.repaymentsHeader.map((header, key) => (
                  <span key={key}>{header}</span>
                ))}
              </div>

              <div className="table__body">
                <ul>
                  {filteredRepayment.map((repaymentData, key) => (
                    <li key={key}>
                      <span>
                        <em
                          className={`repayment-tag repayment-tag__${repaymentData.status.name.toLowerCase()}`}
                        >
                          {capitalizeInitialLetter(repaymentData.status.name)}
                        </em>
                      </span>
                      <span>{nairaAmount(repaymentData.principalPortion)}</span>
                      <span>{nairaAmount(repaymentData.interestPortion)}</span>
                      <span>
                        <>{nairaAmount(repaymentData.lateFee)}</>
                      </span>
                      <span>
                        {nairaAmount(repaymentData.outstandingPayment)}
                      </span>
                      <span>{moment(repaymentData.dueDate).format('ll')}</span>
                      <span>{nairaAmount(repaymentData.amountPaid)}</span>
                      <span>
                        {repaymentData.repaymentDate === null
                          ? 'N/A'
                          : moment(repaymentData.repaymentDate).format('ll')}
                      </span>
                    </li>
                  ))}

                  {repaymentFilterParam === 'default' && (
                    <li className="table__footer">
                      <span>Total</span>
                      <span>
                        {nairaAmount(
                          sumColumn(
                            portfolioData.portfolio.repayments.filter(
                              el =>
                                el.status.name.toLowerCase() !== 'cancelled' &&
                                el.status.name.toLowerCase() !== 'suspended',
                            ),
                            'principalPortion',
                          ),
                        )}
                      </span>
                      <span>
                        {nairaAmount(
                          sumColumn(
                            portfolioData.portfolio.repayments.filter(
                              el =>
                                el.status.name.toLowerCase() !== 'cancelled' &&
                                el.status.name.toLowerCase() !== 'suspended',
                            ),
                            'interestPortion',
                          ),
                        )}
                      </span>
                      <span>
                        {nairaAmount(
                          sumColumn(
                            portfolioData.portfolio.repayments.filter(
                              el =>
                                el.status.name.toLowerCase() !== 'cancelled' &&
                                el.status.name.toLowerCase() !== 'suspended',
                            ),
                            'lateFee',
                          ),
                        )}
                      </span>
                      <span>
                        {nairaAmount(
                          sumColumn(
                            portfolioData.portfolio.repayments.filter(
                              el =>
                                el.status.name.toLowerCase() !== 'cancelled' &&
                                el.status.name.toLowerCase() !== 'suspended',
                            ),
                            'outstandingPayment',
                          ),
                        )}
                      </span>
                      <span>
                        {moment(
                          getLastElement(
                            portfolioData.portfolio.repayments.filter(
                              el =>
                                el.status.name.toLowerCase() !== 'cancelled' &&
                                el.status.name.toLowerCase() !== 'suspended',
                            ),
                            'dueDate',
                          ),
                        ).format('ll')}
                      </span>
                      <span>
                        {nairaAmount(
                          sumColumn(
                            portfolioData.portfolio.repayments.filter(
                              el =>
                                el.status.name.toLowerCase() !== 'cancelled' &&
                                el.status.name.toLowerCase() !== 'suspended',
                            ),
                            'amountPaid',
                          ),
                        )}
                      </span>
                      <span>
                        {repaymentDates.length
                          ? moment(
                              getLastElement(
                                repaymentDates,
                                'repaymentDate',
                                true,
                              ),
                            ).format('ll')
                          : 'N/A'}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </Table>
          ) : (
            <section style={{ marginTop: '32px' }}>
              <Alert
                message={`There are currently no ${repaymentFilterParam} repayments on this portfolio.`}
              />
            </section>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default RepaymentBreakdownTab;
