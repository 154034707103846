import * as React from 'react';

const Close = props => (
  <svg
    width={props.width || 31}
    height={props.height || 31}
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.906 15.536l12.7-12.95a1.5 1.5 0 00-2.14-2.1l-12.68 12.93L2.586.466a1.5 1.5 0 10-2.1 2.14l13.18 12.93-13.18 12.93a1.5 1.5 0 002.1 2.14l13.2-12.95 12.68 12.93a1.5 1.5 0 002.14-2.1l-12.7-12.95z"
      fill={props.color ? props.color : '#A5ADB6'}
      fillOpacity={props.opacity ? props.opacity : 0.5}
    />
  </svg>
);

export default Close;
