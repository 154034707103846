const getClientVariable = () => {
  const clientUrl = window.location.hostname;
  const urlSections = clientUrl.split('.');
  const slug = urlSections[0].replace(/(^\w+:|^)\/\//, '');
  const lastUrlSection = urlSections[urlSections.length - 1];

  if (
    urlSections.includes('originate') ||
    urlSections.includes('indicina') ||
    lastUrlSection === 'local'
  ) {
    return {
      slug,
    };
  }

  return { clientUrl };
};

const config = {
  baseUrl: `${process.env.REACT_APP_BASE_URL}graphql`,
  wsBaseUrl: `${process.env.REACT_APP_WS_BASE_URL}graphql`,
  slug: getClientVariable().slug,
  clientUrl: getClientVariable().clientUrl,
  appPortal: process.env.REACT_APP_PORTAL || 'development',
};

export default config;
