import React from 'react';
import { Button, Close, Modal } from '../../../../../../components';

export const SaveWithoutChanges = ({ showModal, toggleModal, save }) => (
  <Modal
    title="Save form without changes"
    classes="repayment-date-modal"
    active={showModal}
  >
    <Close className="close-icon" onClick={toggleModal} />

    <div
      className="content center"
      style={{ fontSize: '1.7rem', lineHeight: '2', textAlign: 'center' }}
    >
      Are you sure you want to save the form without any change?
    </div>

    <div className="repayment-double-buttons" style={{ marginTop: '5rem' }}>
      <div
        style={{
          gap: '40px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Button
          type="button"
          classes="cancel"
          style={{ flex: 1 }}
          click_event={toggleModal}
        >
          Cancel
        </Button>

        <Button
          classes="secondary blue save"
          type="button"
          style={{ flex: 1 }}
          click_event={save}
        >
          Yes, Save
        </Button>
      </div>
    </div>
  </Modal>
);
