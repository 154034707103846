import { gql } from 'apollo-boost';

export const fragments = {
  portfolio: gql`
    fragment PortfolioDetailsOffline on Portfolio {
      id
      amount
      status {
        name
      }
    }
  `,
};

export const APPROVE_OFFLINE_REPAYMENT = gql`
  mutation MP_ApproveOfflineRepayment($input: MutateOfflineRepaymentInput!) {
    approveOfflineRepayment(input: $input) {
      id
      amount
      status
      portfolio {
        ...PortfolioDetails
      }
    }
  }

  ${fragments.portfolio}
`;

export const DECLINE_OFFLINE_REPAYMENT = gql`
  mutation MP_DeclineOfflineRepayment($input: MutateOfflineRepaymentInput!) {
    declineOfflineRepayment(input: $input) {
      id
      amount
      status
      portfolio {
        ...PortfolioDetails
      }
    }
  }

  ${fragments.portfolio}
`;
