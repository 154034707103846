import { Icon, IconProps } from '@chakra-ui/icon';
import React from 'react';

const ArrowLeftIcon = ({ ...props }: IconProps) => {
  return (
    <Icon w={6} h={6} fill="gray.500" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4.293 11.293a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414l-6-6a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11.707 5.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414l6-6a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

export default ArrowLeftIcon;
