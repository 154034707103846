import { Query } from '@apollo/react-components';
import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader } from '../components';
import { connection_limit } from '../config/config';
import { CUSTOMERS_VIEW_QUERY } from '../graphql/queries';
import Constants from '../lib/Constants';
import { useCustomState } from '../lib/CustomHooks';
import { exportToCsv, normalizeDateTime, trimGraphQLError } from '../lib/Utils';
import { toast } from 'react-toastify';

const Customers = ({ render }) => {
  const customersInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    email: useCustomState(),
    name: useCustomState(),
    customerId: useCustomState(),
    phone: useCustomState(),
    createdAtLt: useCustomState(),
    createdAtGt: useCustomState(),
    facilityName: useCustomState(),
    isSearchPerformed: useCustomState(false),
  };
  const [errMsg, setErrMsg] = useState();
  useEffect(() => {
    if (errMsg) {
      toast.error(
        trimGraphQLError(
          errMsg?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
    return () => toast.dismiss();
  }, [errMsg]);

  let exportCsvData;

  const setSearchParams = () => {
    return {
      email: customersInput.email.value || undefined,
      phone: customersInput.phone.value
        ? parseInt(customersInput.phone.value, 10).toString()
        : undefined,
      name: customersInput.name.value
        ? customersInput.name.value.trim()
        : undefined,
      customerId: customersInput.customerId.value || undefined,
      createdAtLt: customersInput.createdAtLt.value || undefined,
      createdAtGt: customersInput.createdAtGt.value || undefined,
      facilityName: customersInput.facilityName.value || undefined,
      first: connection_limit.value,
    };
  };

  const ExportCsvData = () => {
    let isSearchPerformed = customersInput.isSearchPerformed.value || false;

    const response = useLazyQuery(CUSTOMERS_VIEW_QUERY, {
      fetchPolicy: 'no-cache',
      variables: isSearchPerformed ? setSearchParams() : null,
    });

    if (response?.data?.views) {
      exportCsvData = response.data;
    }
  };

  ExportCsvData();

  const handleHideToast = e => {
    e.preventDefault();

    customersInput.showToast.setValue(false);
  };

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    customersInput.isSearchPerformed.setValue(true);
    refetch(setSearchParams());
  };

  const handleReset = async (e, reFetch) => {
    e.preventDefault();

    customersInput.email.setValue('');
    customersInput.phone.setValue('');
    customersInput.name.setValue('');
    customersInput.customerId.setValue('');
    customersInput.createdAtLt.setValue('');
    customersInput.createdAtGt.setValue('');
    customersInput.facilityName.setValue('');
    customersInput.isSearchPerformed.setValue(false);

    reFetch({
      email: undefined,
      phone: undefined,
      name: undefined,
      customerId: undefined,
      createdAtLt: undefined,
      createdAtGt: undefined,
      facilityName: undefined,
      isSearchPerformed: undefined,
    });
  };

  const handleExportToCsv = (e, data) => {
    if (data == null && exportCsvData == null) {
      customersInput.showToast.setValue(true);
      customersInput.status.setValue('Failed');
      customersInput.content.setValue(Constants.errorMessages.NO_EXPORT_DATA);

      setTimeout(() => {
        customersInput.showToast.setValue(false);
      }, 2000);
    }

    const {
      views: {
        customers: { nodes },
      },
    } = exportCsvData || data;

    exportToCsv(nodes, Constants.csvNames.CUSTOMERS);
  };

  let isFacility = localStorage.getItem('slug') === 'heliumhealth';

  const handleStartDate = e => {
    customersInput.createdAtGt.setValue(normalizeDateTime(e));
  };

  const handleEndDate = e => {
    customersInput.createdAtLt.setValue(normalizeDateTime(e, true));
  };

  return (
    <Query
      query={CUSTOMERS_VIEW_QUERY}
      variables={{ first: connection_limit.value, facilityName: isFacility }}
      notifyOnNetworkStatusChange={true}
    >
      {({ data, loading, error, refetch }) => {
        if (loading) {
          return <Loader />;
        }

        errMsg && setErrMsg(error);

        return render({
          data,
          error,
          input: customersInput,
          handleHideToast,
          handleExportToCsv: e => handleExportToCsv(e, data),
          runSearchQuery: e => handleRefetch(e, refetch),
          handleReset: e => handleReset(e, refetch),
          handleStartDate,
          handleEndDate,
          setSearchParams: setSearchParams(),
        });
      }}
    </Query>
  );
};

export default withRouter(Customers);
