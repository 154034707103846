import {
  ColorProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  Skeleton,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface SelectProps extends ChakraSelectProps {
  label?: ReactNode;
  isLoading?: boolean;
  errorMessage?: string;
  helperText?: ReactNode;
  placeholderColor?: ColorProps['color'];
}

const Select = forwardRef(
  (
    {
      id,
      name,
      label,
      isLoading,
      isDisabled,
      isRequired,
      isReadOnly,
      helperText,
      errorMessage,
      placeholderColor,
      ...restProps
    }: SelectProps,
    ref,
  ) => {
    return (
      <FormControl
        id={id || name}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        isInvalid={!!errorMessage}
      >
        {label && <FormLabel>{label}</FormLabel>}

        <Skeleton isLoaded={!isLoading}>
          <ChakraSelect
            ref={ref}
            name={name}
            borderRadius={0}
            isDisabled={isDisabled}
            isRequired={isRequired}
            isReadOnly={isReadOnly}
            isInvalid={!!errorMessage}
            _invalid={{
              color: placeholderColor,
            }}
            _valid={{
              color: 'red.500',
            }}
            _checked={{
              color: 'red.500',
            }}
            {...restProps}
          />
        </Skeleton>

        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);

export default Select;
