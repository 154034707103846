import { useQuery } from '@apollo/react-hooks';

import { CLIENT_INFO_QUERY, PRIVATE_CLIENT_INFO_QUERY } from './queries';
import { clientIdentifier } from '../../Providers/OriginateClient';
import { useFormInput } from '../Form';

const useClientInfo = ({ privateFields = false, loanCategoryId } = {}) => {
  let initData = {
    clientInfo: {},
  };

  const inputs = {
    name: useFormInput(),
    policyId: useFormInput(),
    isSearchPerformed: useFormInput(false),
  };

  const handleReset = async (e, refetch) => {
    e.preventDefault();

    inputs.name.setValue('');
    inputs.policyId.setValue('');

    refetch({
      name: undefined,
      policyId: undefined,
      first: undefined,
    });
  };

  const setSearchParams = () => {
    return {
      name: inputs.name.value ? inputs.name.value.trim() : undefined,
      policyId: inputs.policyId.value || undefined,
      first: undefined,
    };
  };

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    inputs.isSearchPerformed.setValue(true);
    refetch(setSearchParams());
  };

  const qResult = useQuery(
    privateFields ? PRIVATE_CLIENT_INFO_QUERY : CLIENT_INFO_QUERY,
    {
      variables: {
        [clientIdentifier[0]]: clientIdentifier[1],
        loanCategoryId,
      },
    },
  );
  let { data = initData, loading, error, refetch } = qResult;

  return {
    loading,
    error,
    ...data,
    result: qResult,
    ruleSetInputs: inputs,
    handleReset: e => handleReset(e, refetch),
    runSearchQuery: e => handleRefetch(e, refetch),
  };
};

export { useClientInfo };
