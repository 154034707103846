import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom-v6';
import { ReportsLayout } from './layout';

const Dashboard = () => {
  return (
    <Routes>
      <Route path="/" element={<ReportsLayout />}>
      </Route>
    </Routes>
  );
};

export default Dashboard;
