import * as React from 'react';

const CircleXIcon = props => (
  <svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
    <circle cx={14} cy={14} r={14} fill="#F40A0A" fillOpacity={0.03} />
    <path d="M8 10l10 10M8 20l10-10" stroke="#C1292E" />
  </svg>
);

export default CircleXIcon;
