import React, { useState } from 'react';
import {
  Card,
  DataCard,
  Button,
  Loader,
  Toast,
  UploadMerchantFile,
  Input,
} from '../../../components';
import { useUploadConfigurations } from '../../../Providers-new/Configuration';
import { isValidUrl } from '../../../lib/Utils';
import config from '@/lib/config';

const Uploads = () => {
  const [type, setType] = useState('');

  const {
    handleUpdateAttributes,
    loading,
    inputs,
    data,
    logoUrl,
    faviconUrl,
    handleHideToast,
    handleUploadAttribute,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    howToApplyVideoLink,
    cookiesPolicyUrl,
  } = useUploadConfigurations(type, setType);

  const isAbsolutePath = url => {
    if (url?.includes('https') || url?.includes('http')) {
      return url;
    } else {
      return `//${url}`;
    }
  };

  let isDisabled = false;

  if (
    (inputs.tsandCsUrlLink.value.length > 0 &&
      !isValidUrl(inputs.tsandCsUrlLink.input.value, true)) ||
    (inputs.policyUrlLink.value.length > 0 &&
      !isValidUrl(inputs.policyUrlLink.input.value, true)) ||
    (inputs.howToApplyVideoLink.value.length > 0 &&
      !isValidUrl(inputs.howToApplyVideoLink.input.value, true)) ||
    (inputs.cookiesPolicyUrl.value.length > 0 &&
      !isValidUrl(inputs.cookiesPolicyUrl.input.value, true))
  ) {
    isDisabled = true;
  }

  return (
    <div className="merchant-upload">
      {loading && <Loader loadingText="loading..." />}
      <Toast
        title="Upload File"
        content={data && data.content && data.content.value}
        classes={data && data.content && data.status.value}
        active={data && data.content && data.showToast.value}
        clickEvent={handleHideToast}
      />

      <DataCard>
        <div className="flex-container space-between upload">
          <div className="form-desc">
            <Card title="Uploads" />
          </div>

          <div className="form-fields">
            <div className="grid-container spacing">
              <div className="form-fields">
                <Card title="Merchant Logo (max 10MB)" />
                <UploadMerchantFile
                  acceptedTypes={['image/jpeg', 'image/png']}
                  handleUploadAttribute={handleUploadAttribute}
                  name="logo"
                  setType={setType}
                />
                {logoUrl ? (
                  <a
                    href={logoUrl}
                    className="url-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Logo
                  </a>
                ) : null}
              </div>
              <div className="form-fields">
                <Card title="Merchant Favicon (max 10MB)" />
                <UploadMerchantFile
                  acceptedTypes={['image/jpeg', 'image/png']}
                  handleUploadAttribute={handleUploadAttribute}
                  name="favicon"
                  setType={setType}
                />
                {faviconUrl ? (
                  <a
                    href={faviconUrl}
                    className="url-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Favicon
                  </a>
                ) : null}
              </div>
              <div className="form-fields">
                <Card title="Terms & Conditions Document (.pdf max 10MB)" />
                <UploadMerchantFile
                  acceptedTypes={['application/pdf']}
                  handleUploadAttribute={handleUploadAttribute}
                  name="tsAndCs"
                  setType={setType}
                />
                {termsAndConditionsUrl ? (
                  <div className="view">
                    <a
                      href={isAbsolutePath(termsAndConditionsUrl)}
                      className="url-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Terms & conditions
                    </a>
                  </div>
                ) : null}
              </div>

              <div className="form-fields">
                <Card title="Privacy Policy Document (.pdf max 10MB)" />
                <UploadMerchantFile
                  acceptedTypes={['application/pdf']}
                  handleUploadAttribute={handleUploadAttribute}
                  name="policy"
                  setType={setType}
                />
                {privacyPolicyUrl ? (
                  <div className="view">
                    <a
                      href={isAbsolutePath(privacyPolicyUrl)}
                      className="url-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Privacy Policy
                    </a>
                  </div>
                ) : null}
              </div>

              <div className="form-fields">
                {howToApplyVideoLink ? (
                  <>
                    <Card title="How to apply video URL" />
                    <div className="view">
                      <a
                        href={isAbsolutePath(howToApplyVideoLink)}
                        className="url-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View How To Apply Video
                      </a>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="form-fields">
                {cookiesPolicyUrl ? (
                  <>
                    <Card title="Cookies Policy URL" />
                    <div className="view">
                      <a
                        href={isAbsolutePath(cookiesPolicyUrl)}
                        className="url-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Cookies Policy URL
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="divider"></div>
            <div className="grid-container">
              <div className="form-fields">
                <Card title="Terms & Conditions Document Link" />
                <Input
                  placeholder="Enter Terms & conditions Url"
                  type="url"
                  required
                  {...inputs.tsandCsUrlLink.input}
                  errorMessage={
                    inputs.tsandCsUrlLink.value.length > 0 &&
                    !isValidUrl(inputs.tsandCsUrlLink.input.value, true)
                      ? 'Please ensure that the terms and conditions URL is valid'
                      : ''
                  }
                />
              </div>
              <div className="form-fields">
                <Card title="Privacy Policy Document Link" />
                <Input
                  placeholder="Enter Privacy Policy Url"
                  type="url"
                  required
                  {...inputs.policyUrlLink.input}
                  errorMessage={
                    inputs.policyUrlLink.value.length > 0 &&
                    !isValidUrl(inputs.policyUrlLink.input.value, true)
                      ? 'Please ensure that the privacy policy URL is valid'
                      : ''
                  }
                />
              </div>
            </div>
            <div className="divider"></div>
            <div className="grid-container">
              <div className="form-fields">
                <Card title="How To Apply Video URL" />
                <Input
                  placeholder="Enter How To Apply Video URL"
                  type="url"
                  {...inputs.howToApplyVideoLink.input}
                  errorMessage={
                    inputs.howToApplyVideoLink.value.length > 0 &&
                    !isValidUrl(inputs.howToApplyVideoLink.input.value, true)
                      ? 'Please ensure that the video URL is valid'
                      : ''
                  }
                />
              </div>

              <div className="form-fields">
                <Card title="Cookies Policy URL" />
                <Input
                  placeholder="Cookies Policy URL"
                  type="url"
                  {...inputs.cookiesPolicyUrl.input}
                  errorMessage={
                    inputs.cookiesPolicyUrl.value.length > 0 &&
                    !isValidUrl(inputs.cookiesPolicyUrl.input.value, true)
                      ? 'Please ensure that the cookies policy URL is valid'
                      : ''
                  }
                />
              </div>
            </div>
            <div className="flex-container">
              <Button
                classes="secondary center"
                type="submit"
                disabled={isDisabled}
                onClick={e => handleUpdateAttributes(e)}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </DataCard>
    </div>
  );
};

export default Uploads;
