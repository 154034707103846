import { graphql } from '@/__generated/gql';

export const ENABLE_LOAN_PRODUCT = graphql(/* GraphQL */ `
  mutation EnableLoanProduct($id: ID!) {
    policyEnable(id: $id) {
      recordId
      status
    }
  }
`);
