import { gql } from 'apollo-boost';

export const UNASSIGN_APPLICATION_MUTATION = gql`
  mutation MP_UnassignApplicationMutation($applicationId: ID!) {
    unassignApplication(input: { applicationId: $applicationId }) {
      success
      message
    }
  }
`;
