import React, { Fragment } from 'react';
import {
  DataCard,
  Table,
  EmptyScreen,
  BreadCrumbSection,
  Button,
  Loader,
  Toast,
  Modal,
  Close,
} from '../../components';
import cx from 'classnames';
import {
  useGetLoanCategories,
  useMutateEnableLoanCategory,
  useMutateDisableLoanCategory,
} from '../../Providers-new/LoanGroup';
import MoreOptions from '../../components/MoreOptions/MoreOptions';
import { NavLink } from 'react-router-dom';
import Search from './Search';
import './_Styles.scss';
import { useQuery } from '@apollo/react-hooks';
import { GET_ACTIVE_POLICIES } from '../../Providers-new/LoanGroup/queries';
import { ErrorBoundary } from 'react-error-boundary';

const headers = {
  categoryHeader: [
    'Status',
    'Category',
    'Description',
    'Required Attributes',
    '',
  ],
};

const LoanGroups = () => {
  const { loading: loanCategoryLoading, result: loanCategoryResults } =
    useGetLoanCategories();

  let loanCategories = loanCategoryResults?.data
    ? loanCategoryResults.data?.getLoanCategories?.categories
    : [];

  const { data: activePoliciesData } = useQuery(GET_ACTIVE_POLICIES);

  let policies = [];
  activePoliciesData?.policies.map(item =>
    policies.push({ id: item.id, value: item.name, label: item.name }),
  );

  const {
    enableLoanCategoryLoading,
    handleLoanGroupEnableToast,
    handleHideEnableLoanGroupToggle,
    handleEnableLoanGroup,
    enableLoanCategoryData,
  } = useMutateEnableLoanCategory();

  const {
    disableLoanCategoryLoading,
    handleLoanGroupDisableToast,
    disableLoanCategoryData,
    handleDisableLoanGroup,
    handleHideDisableLoanGroupToggle,
  } = useMutateDisableLoanCategory();

  return (
    <>
      <Fragment>
        {loanCategoryLoading && <Loader loadingText="loading..." />}
        <BreadCrumbSection>
          <a
            className="button secondary"
            href="/loan-categories/create-loan-category"
          >
            Create New Category
          </a>
        </BreadCrumbSection>
        <div className="layout-main">
          <Search />
          {enableLoanCategoryLoading ? (
            <Loader loadingText="loading...." />
          ) : (
            <>
              <Toast
                title={`Enable Selected Loan Category`}
                classes={enableLoanCategoryData.status.value}
                content={enableLoanCategoryData.content.value}
                active={enableLoanCategoryData.showToast.value}
                clickEvent={handleLoanGroupEnableToast}
              />

              <Modal
                title={'Enable Loan Category'}
                active={enableLoanCategoryData.showEnableLoanGroupPopup.value}
              >
                <Close
                  className="close-icon"
                  onClick={e => handleHideEnableLoanGroupToggle(e)}
                />
                <div className="content center">
                  {' '}
                  Are you sure you want to enable{' '}
                  <span className="caption">
                    {enableLoanCategoryData.selectedLoanGroupData.value.name ||
                      ' this '}
                  </span>{' '}
                  category?
                </div>
                <div className="actions">
                  <div className="flex-container center">
                    <Button
                      classes="secondary"
                      click_event={e =>
                        handleEnableLoanGroup(
                          e,
                          enableLoanCategoryData.selectedLoanGroupData.value,
                        )
                      }
                    >
                      Enable
                    </Button>
                  </div>
                </div>
              </Modal>
            </>
          )}
          {disableLoanCategoryLoading ? (
            <Loader loadingText="loading...." />
          ) : (
            <>
              <Toast
                title={`Disable Selected Loan Category`}
                classes={disableLoanCategoryData.status.value}
                content={disableLoanCategoryData.content.value}
                active={disableLoanCategoryData.showToast.value}
                clickEvent={handleLoanGroupDisableToast}
              />

              <Modal
                title={'Disable Loan Category'}
                active={disableLoanCategoryData.showDisableLoanGroupPopup.value}
              >
                <Close
                  className="close-icon"
                  onClick={e => handleHideDisableLoanGroupToggle(e)}
                />
                <div className="content center">
                  {' '}
                  Are you sure you want to disable{' '}
                  <span className="caption">
                    {disableLoanCategoryData.selectedLoanGroupData.value.name ||
                      ' this '}
                  </span>{' '}
                  category?
                </div>
                <div className="actions">
                  <div className="flex-container center">
                    <Button
                      classes="secondary"
                      click_event={e =>
                        handleDisableLoanGroup(
                          e,
                          disableLoanCategoryData.selectedLoanGroupData.value,
                        )
                      }
                    >
                      Disable
                    </Button>
                  </div>
                </div>
              </Modal>
            </>
          )}
          <div className="flex-container space-between">
            <h4>All Loan Categories</h4>
          </div>
          {loanCategories ? (
            <ErrorBoundary
              fallback={
                <DataCard noPadding={false}>
                  <EmptyScreen message="Error Fetching Loan Categories" />
                </DataCard>
              }
            >
              <Fragment>
                <section>
                  <DataCard noPadding={false}>
                    <Table>
                      <div className="table__head">
                        {headers.categoryHeader.map((header, key) => (
                          <span
                            key={key}
                            className={cx({
                              Status: header,
                              'checked-width':
                                header === 'Status' || header === '',
                            })}
                          >
                            {' '}
                            {header}{' '}
                          </span>
                        ))}
                      </div>
                      <div className="table__body">
                        {loanCategories?.length > 0 &&
                          loanCategories.map((category, key) => (
                            <ul key={`${key}${category.id}`}>
                              <li>
                                <span
                                  className="tooltip checked-width"
                                  data-title={category.status}
                                >
                                  <span
                                    className={`status fixed ${
                                      category.status.toLowerCase() || ''
                                    }`}
                                  >
                                    {(() => {
                                      const statusName = category.status;
                                      switch (statusName) {
                                        case 'ACTIVE':
                                          return 'ACT';
                                        case 'DISABLED':
                                          return 'DIS';
                                        default:
                                          return 'DIS';
                                      }
                                    })()}
                                  </span>
                                </span>
                                <span>{category.name}</span>
                                <span>{category.description}</span>
                                <span>
                                  {category.attribute
                                    ? category.attribute.name
                                    : 'N/A'}
                                </span>
                                <span className="checked-width">
                                  <MoreOptions id={category.id}>
                                    <NavLink
                                      to={`/loan-categories/update/${category.name}`}
                                    >
                                      Edit
                                    </NavLink>
                                    {category.status === 'DISABLED' && (
                                      <Button
                                        classes="link dark-text"
                                        click_event={e =>
                                          handleHideEnableLoanGroupToggle(
                                            e,
                                            category,
                                          )
                                        }
                                      >
                                        Enable
                                      </Button>
                                    )}
                                    {category.status === 'ACTIVE' && (
                                      <Button
                                        classes="link dark-text"
                                        click_event={e =>
                                          handleHideDisableLoanGroupToggle(
                                            e,
                                            category,
                                          )
                                        }
                                      >
                                        Disable
                                      </Button>
                                    )}
                                  </MoreOptions>
                                </span>
                              </li>
                            </ul>
                          ))}
                      </div>
                    </Table>
                  </DataCard>
                </section>
              </Fragment>
            </ErrorBoundary>
          ) : (
            <DataCard noPadding={true}>
              <EmptyScreen message="No Loan Groups Created Yet" />
            </DataCard>
          )}
        </div>
      </Fragment>
    </>
  );
};

export default LoanGroups;
