import { useMutation } from '@apollo/client';

import { SET_APPLICATION_BANK_ACCOUNT } from '@/graphql/mutations/bank';
import { APPLICATION_DETAILS } from '@/graphql/queries/applications/applicationDetails';
import { ApplicationBankStageInput } from '@/__generated/graphql';

export const useSetApplicationBankAccount = () => {
  const [mutate, { data, loading, error }] = useMutation(
    SET_APPLICATION_BANK_ACCOUNT,
    {
      refetchQueries: ({ data }) => [
        {
          query: APPLICATION_DETAILS,
          variables: {
            applicationNumber:
              data?.setApplicationBankAccount.application.applicationNumber,
          },
        },
      ],
      // awaitRefetchQueries: true,
      errorPolicy: 'all',
      onError: () => {},
    },
  );

  const setApplicationBankAccount = ({
    applicationId,
    accountBankId,
    customerAccountId,
  }: ApplicationBankStageInput) => {
    mutate({
      variables: {
        input: {
          applicationId,
          accountBankId,
          customerAccountId,
        },
      },
    });
  };

  return {
    setApplicationBankAccount,
    setApplicationBankAccountError: error,
    setApplicationBankAccountLoading: loading,
    setApplicationBankAccountResponse: data?.setApplicationBankAccount,
  };
};
