import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CheckBox,
  Close,
  Modal,
  NewCheckBox,
  TextArea,
  Toast,
  WhitelistUser,
} from '../index';
import { useMutation } from '@apollo/react-hooks';
import { BLACKLIST_CUSTOMER_MUTATION } from '../../graphql/mutations/blacklistCustomer';
import './_BlacklistUser.scss';
import { Loader } from '../';
import { trimGraphQLError, normalizeDateTime } from '../../lib/Utils';
import ReactDatePicker from 'react-datepicker';
import { Flex, Spacer, Text } from '@chakra-ui/react';

const BlacklistUser = ({ blacklistDetails, userId, refetch }) => {
  const { isInternalBlacklist } = blacklistDetails || {};
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [loader, setLoader] = useState(false);
  const [reason, setReason] = useState('');
  const [hasDuration, setHasDuration] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [blacklistCustomer, { data, loading, error }] = useMutation(
    BLACKLIST_CUSTOMER_MUTATION,
  );

  const toggleModal = () => setShowModal(showModal => setShowModal(!showModal));

  useEffect(() => {
    if (data) {
      refetch();

      setTimeout(() => {
        toggleModal();
        setLoader(false);
        setSuccessToast(true);
      }, 3000);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toggleModal();

      setErrorToast(true);
    }
  }, [error]);

  useEffect(() => {
    if (loading) {
      setLoader(true);
    }
  }, [loading]);

  useEffect(() => {
    if (errorToast) {
      handleHidErrorToast();
    }
  }, [errorToast]);

  useEffect(() => {
    if (successToast) {
      handleHideSuccessToast();
    }
  }, [successToast]);

  useEffect(() => {
    if (!hasDuration) {
      setExpiryDate(null);
    }
  }, [hasDuration]);

  const handleHidErrorToast = () => {
    setTimeout(() => {
      setErrorToast(false);
    }, 2000);
  };

  const handleHideSuccessToast = () => {
    setTimeout(() => {
      setSuccessToast(false);
    }, 2000);
  };

  const blacklist = e => {
    e.preventDefault();

    if (!reason) return;

    if (hasDuration && !expiryDate) return;

    blacklistCustomer({
      variables: {
        userId,
        reason,
        expiryDate,
      },
    });
  };

  return (
    <>
      {isInternalBlacklist ? (
        <WhitelistUser
          refetch={refetch}
          userId={userId}
          blacklistDetails={blacklistDetails}
        />
      ) : (
        <section className="blacklist-user">
          <Button classes="red solid" click_event={toggleModal}>
            Blacklist Customer
          </Button>
        </section>
      )}

      {loader && <Loader />}

      {errorToast && (
        <Toast
          title={trimGraphQLError(error?.message)}
          classes={'fail'}
          active={true}
        />
      )}

      {successToast && (
        <Toast
          title="Customer Blacklisted"
          classes={'successful'}
          active={true}
        />
      )}

      <Modal
        title="Blacklist Reason"
        classes="repayment-date-modal"
        active={showModal}
      >
        <Close className="close-icon" onClick={toggleModal} />
        <form onSubmit={blacklist}>
          <TextArea
            required
            defaultValue={reason}
            onChange={e => setReason(e?.target?.value)}
          />

          <Flex>
            <div className="form-fields">
              <Flex alignItems={'baseline'} justify={'space-between'}>
                <Text fontSize="15px" px="5">
                  Set expiry date (Optional)
                </Text>
                <ReactDatePicker
                  onChange={date => setExpiryDate(date)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Expiry Date"
                  startDate={new Date()}
                  minDate={new Date()}
                  selected={expiryDate ? normalizeDateTime(expiryDate) : null}
                />
              </Flex>
            </div>
            <Spacer />
          </Flex>
          <div className="repayment-double-buttons">
            <Button classes="cancel" click_event={toggleModal}>
              Cancel
            </Button>
            <Button classes="secondary blue save" type="submit">
              Blacklist
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default BlacklistUser;
