import { useMutation } from '@apollo/react-hooks';
import { CLOSE_OUT_LOAN } from './queries';
import { useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { PORTFOLIO_QUERY } from '../../graphql/queries';

const useMutateCloseOutLoan = ({ portfolio }) => {
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3000);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
    handleModalToggle();
  };

  const handleCloseOutLoan = async e => {
    e.preventDefault();

    await closeOutLoan({
      variables: {
        portfolioId: portfolio.id,
      },
      refetchQueries: () => [
        {
          query: PORTFOLIO_QUERY,
          variables: { portfolioNumber: portfolio.portfolioNumber },
        },
      ],
    });
  };

  const handleModalToggle = () => {
    data.showModal.setValue(!data.showModal.value);
  };

  const [closeOutLoan, { loading }] = useMutation(CLOSE_OUT_LOAN, {
    async onCompleted() {
      data.showToast.setValue(true);
      data.status.setValue('Success');
      data.content.setValue('Loan has been closed successfully');
      data.showModal.setValue(false);

      handleHideToast();
    },
    onError,
  });

  return {
    handleHideToast,
    loading,
    handleModalToggle,
    data,
    handleCloseOutLoan,
  };
};

export { useMutateCloseOutLoan };
