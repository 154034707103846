import * as React from 'react';

const HelpIcon = props => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 16.802H9.6v-1.6h1.6v-3.2H9.6v-1.6H12a.8.8 0 0 1 .8.8v4h1.6v1.6ZM12 7.2a.8.8 0 1 1 0 1.6.8.8 0 0 1 0-1.6Zm0-4.8c-5.294 0-9.6 4.306-9.6 9.6s4.306 9.6 9.6 9.6 9.6-4.306 9.6-9.6-4.306-9.6-9.6-9.6Z"
      fill="#697275"
    />
  </svg>
);

export default HelpIcon;
