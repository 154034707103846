import React from 'react';
import { Mutation } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { REJECT_MANUAL_REVIEW_APPLICATION_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { Loader } from '../components';
import { trimGraphQLError } from '../lib/Utils';

const RejectManualReviewApplication = ({ render, applicationId, history }) => {
  const rejectManualReviewApplicationPrompt = {
    message: useCustomState(),
    otherMessage: useCustomState(),
    disableOthers: useCustomState(true),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showPopup: useCustomState(false),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      rejectManualReviewApplicationPrompt.showToast.setValue(false);
    }, 2500);
  };

  const handleRejectReviewApplication = (e, handleRejectMutation) => {
    e.preventDefault();

    if (!rejectManualReviewApplicationPrompt.message.value) {
      rejectManualReviewApplicationPrompt.showToast.setValue(true);
      rejectManualReviewApplicationPrompt.status.setValue('Failed');
      rejectManualReviewApplicationPrompt.content.setValue(
        'Please select a reason for rejection ',
      );

      handleHideToast();

      return;
    }

    if (
      !rejectManualReviewApplicationPrompt.otherMessage.value &&
      rejectManualReviewApplicationPrompt.message.value === 'Other Reasons'
    ) {
      rejectManualReviewApplicationPrompt.showToast.setValue(true);
      rejectManualReviewApplicationPrompt.status.setValue('Failed');
      rejectManualReviewApplicationPrompt.content.setValue(
        'Please enter a reason in comment box ',
      );
      handleHideToast();
      return;
    }

    const { message: rejectReason, otherMessage } =
      rejectManualReviewApplicationPrompt;
    const rejectMessage =
      rejectReason.value != null && rejectReason.value.length > 0
        ? rejectReason.value + ', '
        : '';

    handleRejectMutation({
      variables: {
        applicationId,
        message:
          rejectMessage +
          `${
            otherMessage.value != null ? 'Comments: ' + otherMessage.value : ''
          }`,
      },
    });
  };

  const showRejectManualApplicationPopup = e => {
    e.preventDefault();

    rejectManualReviewApplicationPrompt.showPopup.setValue(true);
  };

  const hideRejectManualApplicationPopup = () => {
    rejectManualReviewApplicationPrompt.showPopup.setValue(false);
  };

  const handleCheckBoxInput = () => {
    let checked = document.querySelectorAll(
      'input[type=checkbox].manualreviewcheckbox:checked',
    );
    let values = Array.prototype.slice
      .call(checked, 0)
      .map(function (checkbox) {
        return checkbox.value;
      });
    if (checked.length) {
      rejectManualReviewApplicationPrompt.message.setValue(values.toString());
    } else rejectManualReviewApplicationPrompt.message.setValue(false);
  };

  return (
    <Mutation
      mutation={REJECT_MANUAL_REVIEW_APPLICATION_MUTATION}
      onCompleted={({ rejectManualReviewApplication: { approved } }) => {
        if (!approved) {
          rejectManualReviewApplicationPrompt.showToast.setValue(true);
          rejectManualReviewApplicationPrompt.status.setValue('Success');
          rejectManualReviewApplicationPrompt.content.setValue(
            'Application Declined',
          );
          setTimeout(() => {
            window.location.href = history.location.pathname;
          }, 1500);
        }
      }}
      onError={error => {
        rejectManualReviewApplicationPrompt.showToast.setValue(true);
        rejectManualReviewApplicationPrompt.status.setValue('Failed');
        rejectManualReviewApplicationPrompt.content.setValue(
          trimGraphQLError(error.message),
        );
        handleHideToast();
      }}
    >
      {(handleRejectMutation, { loading }) => {
        if (loading) {
          return <Loader />;
        }

        return render({
          handleHideToast,
          handleRejectReviewApplication: e =>
            handleRejectReviewApplication(e, handleRejectMutation),
          applicationId,
          input: rejectManualReviewApplicationPrompt,
          showRejectManualApplicationPopup,
          hideRejectManualApplicationPopup,
          handleCheckBoxInput,
        });
      }}
    </Mutation>
  );
};

export default withRouter(RejectManualReviewApplication);
