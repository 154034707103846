import moment from 'moment-timezone';
import React, { Fragment } from 'react';
import { DataCard, Table } from '../../../../components';
import { nairaAmount, ratioToPercent } from '../../../../lib/Utils';

const CashFlowAnalysisTab = ({ cashFlowAnalysis }) => {
  const {
    accountActivity,
    averageCredits,
    averageDebits,
    totalCreditTurnover,
    totalDebitTurnover,
    averageBalance,
    netAverageMonthlyEarnings,
    noOfTransactingMonths,
    monthPeriod,
    yearInStatement,
    firstDay,
    lastDay,
    closingBalance,
  } = cashFlowAnalysis || {};
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Table>
            <div className="table__body">
              {cashFlowAnalysis && Object.keys(cashFlowAnalysis).length ? (
                <ul>
                  <li>
                    <span className="decide-analysis-span">
                      Cash Flow Analysis
                    </span>
                  </li>
                  <li>
                    <span>Account Activity</span>
                    <span className="right-text">
                      {accountActivity != null
                        ? ratioToPercent(accountActivity)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Average Credits</span>
                    <span className="right-text">
                      {averageCredits != null
                        ? nairaAmount(averageCredits)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Average Debits</span>
                    <span className="right-text">
                      {averageDebits != null
                        ? nairaAmount(averageDebits)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Total Credit Turnover</span>
                    <span className="right-text">
                      {totalCreditTurnover != null
                        ? nairaAmount(totalCreditTurnover)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Total Debit Turnover</span>
                    <span className="right-text">
                      {totalDebitTurnover != null
                        ? nairaAmount(totalDebitTurnover)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Average Balance</span>
                    <span className="right-text">
                      {averageBalance != null
                        ? nairaAmount(averageBalance)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Net Average Monthly Earnings</span>
                    <span className="right-text">
                      {netAverageMonthlyEarnings != null
                        ? nairaAmount(netAverageMonthlyEarnings)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Duration in Statement</span>
                    <span className="right-text">
                      {noOfTransactingMonths != null
                        ? `${noOfTransactingMonths} Months`
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>The period in Statement</span>
                    <span className="right-text">
                      {monthPeriod != null ? monthPeriod : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Year</span>
                    <span className="right-text">
                      {yearInStatement != null ? yearInStatement : '__'}
                    </span>
                  </li>
                  <li>
                    <span>First Day in Statement</span>
                    <span className="right-text">
                      {firstDay != null ? moment(firstDay).format('ll') : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Last Day in Statement</span>
                    <span className="right-text">
                      {lastDay != null ? moment(lastDay).format('ll') : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Balance at Statement End</span>
                    <span className="right-text">
                      {closingBalance != null
                        ? nairaAmount(closingBalance)
                        : '__'}
                    </span>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default CashFlowAnalysisTab;
