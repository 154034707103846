import React from 'react';
import './_EmptyScreen.scss';
import PropTypes from 'prop-types';
import EmptyIcon from '../Icons/EmptyIcon';

const EmptyScreen = props => {
  return (
    <div
      className={`empty-screen ${props.noPadding === true ? 'no-padding' : ''}`}
    >
      <EmptyIcon />
      <span className="empty-screen__message">{props.message}</span>
    </div>
  );
};

EmptyScreen.propTypes = {
  noPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  message: PropTypes.string,
};

export default EmptyScreen;
