import { useMutation } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  generatePassword,
  trimGraphQLError,
  validateEmail,
} from '../../lib/Utils';
import { FormInput, useKeyPair } from '../Form';
import {
  SEND_CLIENT_USER_INVITATION_MUTATION,
  UPDATE_CLIENT_ACCOUNT_USER_MUTATION,
} from './queries';
import { USER_MANAGEMENT_QUERY } from '../../graphql/queries';
import { connection_limit } from '../../config/config';

const useMutateClientAccountUser = ({
  roles = [],
  permissions = [],
  accountUser = {},
  clientId,
}) => {
  const history = useHistory();

  const data = {
    defaultPermissions: useKeyPair([]),
    otherPermissions: useKeyPair([]),
    roleDescription: useKeyPair(''),
    isEditMode: useKeyPair(!!accountUser.id),
    showToast: useKeyPair(false),
    showNotificationPopup: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const [selectedRoles, updateSelectedRoles] = useState([]);

  const inputs = {
    emailAddress: FormInput(),
    role: FormInput('', { type: 'select' }),
  };

  const permissionInputs = {};

  permissions.forEach(p => {
    permissionInputs[p.id] = FormInput(false, { type: 'checkbox' });
  });

  const getInputRoleObject = () => roles.find(r => r.id === inputs.role.value);

  useEffect(() => {
    if (accountUser.id) {
      data.isEditMode.setValue(true);
      const { user, roles = [], permissions = [] } = accountUser;

      if (roles[0] && permissions.length > 0) {
        permissions.forEach(p => {
          if (!roles[0].permissions.find(rp => rp.id === p.id)) {
            permissionInputs[p.id].setValue(true);
          }
        });
      }

      setTimeout(() => {
        inputs.emailAddress.setValue(user.email);
        if (roles[0]) {
          inputs.role.setValue(roles[0].id);
          data.roleDescription.setValue(roles[0].description);
        }
      }, 200);
    }
  }, [accountUser.id]);

  // on role changed
  useEffect(() => {
    const rolePerms = [];
    const others = [];

    const role = getInputRoleObject();
    if (role) {
      permissions.forEach(p => {
        if (role.permissions.find(rp => rp.id === p.id)) {
          rolePerms.push(p);
          permissionInputs[p.id].setValue(true);
        } else {
          others.push(p);
          if (
            accountUser.id &&
            accountUser.permissions.find(aup => aup.id === p.id)
          ) {
            permissionInputs[p.id].setValue(true);
          } else {
            permissionInputs[p.id].setValue(false);
          }
        }
      });
      data.roleDescription.setValue(role.description);
      data.defaultPermissions.setValue(rolePerms);
      data.otherPermissions.setValue(others);
    }
  }, [inputs.role.value]);

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 5000);
  };

  const handleHideNewToast = () => {
    setTimeout(() => {
      data.showNotificationPopup.setValue(false);
    }, 5000);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const [runCreateClientAccountUserMutation, { loading, error }] = useMutation(
    SEND_CLIENT_USER_INVITATION_MUTATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Created');
        data.showNotificationPopup.setValue(true);

        handleHideNewToast();
        setTimeout(() => {
          history.push('/settings/user-management');
        }, 4000);
      },
      onError,
    },
  );

  const [runUpdateClientAccountUserMutation] = useMutation(
    UPDATE_CLIENT_ACCOUNT_USER_MUTATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Updated');
        data.showNotificationPopup.setValue(true);

        handleHideNewToast();
        history.push('/user-management');
      },
      onError,
    },
  );

  const getMutationVariables = () => {
    if (!inputs.emailAddress.value || selectedRoles.length < 1) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please fill all required input');

      handleHideToast();
      return;
    }

    if (!validateEmail(inputs.emailAddress.value)) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Invalid Email Address.');

      handleHideToast();
      return;
    }

    return {
      email: inputs.emailAddress.value,
      roles: selectedRoles.map(r => r.value),
    };
  };

  const handleCreateClientAccountUser = async e => {
    e.preventDefault();

    const variables = getMutationVariables();

    if (variables) {
      await runCreateClientAccountUserMutation({
        variables: {
          input: {
            clientId,
            ...variables,
          },
        },
        refetchQueries: () => [
          {
            query: USER_MANAGEMENT_QUERY,
            variables: { clientId: clientId, first: connection_limit.value },
          },
        ],
      });
    }
  };

  const handleUpdateClientAccountUser = async e => {
    e.preventDefault();

    const variables = getMutationVariables();

    if (variables) {
      await runUpdateClientAccountUserMutation({
        variables: {
          input: {
            clientId,
            userId: accountUser.user.id,
            ...variables,
          },
        },
      });
    }
  };

  const populatePasswordField = e => {
    e.preventDefault();

    inputs.password.setValue(generatePassword(15));
  };

  return {
    populatePasswordField,
    handleCreateClientAccountUser,
    handleUpdateClientAccountUser,
    handleHideToast,
    loading,
    error,
    form: inputs,
    permissionInputs,
    data,
    selectedRoles,
    updateSelectedRoles,
  };
};

export { useMutateClientAccountUser };
