import { useLazyQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { trimGraphQLError } from '../lib/Utils';
import Constants from '../lib/Constants';
import { CUSTOMER_BY_ID_QUERY } from '../graphql/queries/portfoliosQuery';

const useGetCustomerById = () => {
  const [customerId, setCustomerId] = useState();
  const [customerData, setCustomerData] = useState(null);
  const [fetchCustomer, { loading, error, data }] = useLazyQuery(
    CUSTOMER_BY_ID_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (data) {
      setCustomerData(data.portfolio);
    }
  }, [data]);

  const handleSearch = e => {
    e.preventDefault();
    fetchCustomer({
      variables: {
        customerId,
        transactionWhere: {
          type_contains: 'Repayment',
        },
      },
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(
        trimGraphQLError(
          error?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }

    return () => toast.dismiss();
  }, [error]);

  return {
    customerId,
    setCustomerId,
    customerData,
    loading,
    error,
    handleSearch,
    setCustomerData,
  };
};

export { useGetCustomerById };
