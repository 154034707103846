import { useQuery } from '@apollo/react-hooks';
import { CREDIT_POLICIES_QUERY } from '../../graphql/queries';

const useGetPolicy = () => {
  let output = {
    policyItems: [],
  };

  const pResult = useQuery(CREDIT_POLICIES_QUERY);
  const { data, loading, error } = pResult;

  if (!loading && data && data.viewer && data.viewer.account) {
    let policy = data.viewer.account.policies;
    output = {
      policyItems: policy,
    };
  }

  return {
    loading,
    error,
    ...output,
    result: pResult,
  };
};

export { useGetPolicy };
