import React, { Fragment } from 'react';
import { Button, Toast, Loader } from '../../../../components/index';
import { DisburseLoanToBank } from '../../../../Providers/index';

const DisburseLoanToBankFlow = ({ portfolioData }) => {
  return (
    <Fragment>
      <div className="manual-approval">
        <DisburseLoanToBank
          portfolio={portfolioData.portfolio}
          render={({
            input,
            disburseLoanLoader,
            handleApproveReviewApplication,
            handleHideToast,
          }) => (
            <Fragment>
              {disburseLoanLoader && <Loader loadingText="loading..." />}
              <Toast
                title="Disburse Loan"
                content={input.content.value}
                classes={input.status.value}
                active={input.showToast.value}
                clickEvent={handleHideToast}
              />
              <Button
                classes="right green"
                click_event={handleApproveReviewApplication}
              >
                Disburse Loan
              </Button>
            </Fragment>
          )}
        />
      </div>
    </Fragment>
  );
};

export default DisburseLoanToBankFlow;
