import { useMutation } from '@apollo/react-hooks';
import { SET_APPLICATION_BANK_ACCOUNT } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { APPLICATIONS_QUERY } from '../graphql/queries';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Constants from '../lib/Constants';

const useSetApplicationBankAccount = ({ application }) => {
  const data = {
    showToast: useCustomState(false),
    showModal: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    if (errorMsg) {
      toast.error(
        trimGraphQLError(
          errorMsg?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
    return () => toast.dismiss();
  }, [errorMsg]);

  const accountBankId = application?.account?.bankAccounts[0]?.id;
  const customerAccountId = application?.account?.id;

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const handleModalToggle = () => {
    data.showModal.setValue(!data.showModal.value);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
    handleModalToggle();
  };

  const handleSetApplicationBankAccount = async e => {
    e.preventDefault();

    await setApplicationBankAccount({
      variables: {
        input: {
          applicationId: application.id,
          accountBankId: accountBankId,
          customerAccountId: customerAccountId,
        },
      },
      refetchQueries: () => [
        {
          query: APPLICATIONS_QUERY,
          variables: {
            applicationNumber: application.applicationNumber,
            skipAccountAttributes: true,
          },
        },
      ],
    });
  };

  const [
    setApplicationBankAccount,
    { loading: setApplicationBankAccountLoading, error },
  ] = useMutation(SET_APPLICATION_BANK_ACCOUNT, {
    async onCompleted() {
      data.showToast.setValue(true);
      data.status.setValue('Success');
      data.content.setValue('Default Bank Account Set Successfully');
      data.showModal.setValue(false);

      handleHideToast();
      window.location.reload();
    },
    onError,
  });

  error && setErrorMsg(error);

  return {
    setApplicationBankAccountToast: handleHideToast,
    setApplicationBankAccountLoading,
    setApplicationBankAccountData: data,
    handleSetApplicationBankAccount: (e, repaymentId) =>
      handleSetApplicationBankAccount(e, repaymentId),
    setApplicationBankAccountToggle: handleModalToggle,
    setApplicationBankAccountError: error,
  };
};

export default useSetApplicationBankAccount;
