import { Text } from '@chakra-ui/react';
import React from 'react';
import {
  Button,
  Card,
  DataCard,
  Loader,
  Toast,
  CheckBox,
} from '../../../components';
import { useUpdateNotificationPrefrences } from '../../../Providers-new/Configuration';

function NotificationPreferences() {
  const {
    handleUpdateNotificationPreferences,
    loading,
    inputs,
    data,
    cisLoading,
    handleHideToast,
  } = useUpdateNotificationPrefrences();

  return (
    <div className="notification-preferences">
      {(loading || cisLoading) && <Loader loadingText="loading..." />}
      <Toast
        title="Update Notification Prefrences"
        content={data.content.value}
        classes={data.status.value}
        active={data.showToast.value}
        clickEvent={handleHideToast}
      />

      <DataCard>
        <div className="flex-container space-between notification-preferences">
          <div className="form-desc">
            <Card title="Notification Preferences" />
          </div>

          <div className="form-fields">
            <div className="form-fields">
              <Card title="Notify customer when application has been approved" />
              <CheckBoxGroup
                sms={inputs.customerApplicationApprovalSms}
                email={inputs.customerApplicationApprovalEmail}
              />
            </div>
            <div className="divider"></div>
            <div className="form-fields">
              <Card title="Notify customer when application has been denied" />
              <CheckBoxGroup
                sms={inputs.customerApplicationDeniedSms}
                email={inputs.customerApplicationDeniedEmail}
              />
            </div>
            <div className="divider"></div>
            <div className="form-fields">
              <Card title="Notify customer when application is under review" />
              <CheckBoxGroup email={inputs.customerApplicationReviewEmail} />
            </div>
            <div className="divider"></div>
            <div className="form-fields">
              <Card title="Notify customer when loan has been disbursed" />
              <CheckBoxGroup email={inputs.customerLoanDisbursedEmail} />
            </div>
            <div className="divider"></div>
            <div className="form-fields">
              <Card title="Notify customer when loan repayment has been made" />
              <CheckBoxGroup email={inputs.customerLoanRepaymentEmail} />
            </div>
            <div className="flex-container">
              <Button
                click_event={handleUpdateNotificationPreferences}
                classes="secondary space-left"
                type="submit"
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </DataCard>
    </div>
  );
}

export default NotificationPreferences;

const CheckBoxGroup = ({ sms, email }) => {
  return (
    <div className="notification-preferences grid-container">
      {sms && (
        <div className="flex-container spacing">
          <CheckBox
            {...sms.input}
            onChange={e => sms.setValue(e.target.checked)}
            checked={sms.value}
          />
          <Text fontSize="md" px="5">
            SMS
          </Text>
        </div>
      )}

      <div className="flex-container spacing">
        <CheckBox
          {...email.input}
          onChange={e => email.setValue(e.target.checked)}
          checked={email.value}
        />
        <Text fontSize="md" px="5">
          Email
        </Text>
      </div>
    </div>
  );
};
