import React, { Fragment } from 'react';
import { Button, DataCard, Export, Table } from '../../../../components';
import { capitalizeInitialLetter } from '../../../../lib/Utils';

const CreditRegistryReportTab = ({
  data,
  handleExportToCsvCreditRegistryReport,
}) => {
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Button
            classes="right"
            click_event={handleExportToCsvCreditRegistryReport}
          >
            <Export />
            <span className="export-csv">Export CSV</span>
          </Button>
          <Table
            tableHeaders={[
              'Account Owner Registry ID',
              'Name',
              'Is Performing',
            ]}
          >
            <div className="table__body">
              <ul>
                {data &&
                  data.application.credit.creditRegistryReport.result.data?.CreditPerformances?.map(
                    (report, idx) => (
                      <li key={idx}>
                        <span>{report.AccountOwnerRegistryID}</span>
                        <span>{report.Name}</span>
                        <span>
                          {capitalizeInitialLetter(`${report.IsPerforming}`)}
                        </span>
                      </li>
                    ),
                  )}
              </ul>
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default CreditRegistryReportTab;
