import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ATTRIBUTES, UPLOAD_ATTRIBUTE } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { CLIENT_INFO_QUERY } from '../Client/queries';
import { clientIdentifier } from '../../Providers/OriginateClient';
import { useClientInfo } from '../Client';
import { useEffect } from 'react';

const useUploadConfigurations = (type, setType) => {
  const { clientInfo, loading: ciLoading } = useClientInfo({
    privateFields: false,
  });

  const sessionToken = localStorage.getItem('api-key');

  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    tsandCsUrlLink: useFormInput(),
    policyUrlLink: useFormInput(),
    howToApplyVideoLink: useFormInput(),
    cookiesPolicyUrl: useFormInput(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const payload = url => {
    switch (type) {
      case 'logo':
        return { logoUrl: url };
      case 'favicon':
        return { faviconUrl: url };
      case 'tsandCs':
        return { termsAndConditionsUrl: url };
      case 'policy':
        return { privacyPolicyUrl: url };
      case 'howToApply':
        return { howToApplyVideoLink: url };
      case 'cookiesPolicy':
        return { cookiesPolicyUrl: url };
      default:
        return {};
    }
  };

  const urlPayload = {
    termsAndConditionsUrl: inputs?.tsandCsUrlLink?.value,
    privacyPolicyUrl: inputs?.policyUrlLink?.value,
    howToApplyVideoLink: inputs?.howToApplyVideoLink?.value,
    cookiesPolicyUrl: inputs?.cookiesPolicyUrl?.value,
  };

  const handleUploadAttribute = async (e, file, isPublic) => {
    e.preventDefault();

    const data = await mutate({
      variables: {
        input: { file, name: file?.name, isPublic },
      },
      refetchQueries: () => [
        {
          query: CLIENT_INFO_QUERY,
          variables: { [clientIdentifier[0]]: clientIdentifier[1] },
        },
      ],
    });

    if (data) {
      handleUploadAttributeData(e, data.data.uploadToS3.file.url);
    }
  };

  const handleUpdateAttributes = async e => {
    e.preventDefault();

    await requestUpdateAttributes({
      variables: {
        input: { accountId: clientInfo?.clientId, ...urlPayload },
        token: sessionToken,
      },
      refetchQueries: () => [
        {
          query: CLIENT_INFO_QUERY,
          variables: { [clientIdentifier[0]]: clientIdentifier[1] },
        },
      ],
    });
  };

  const handleUploadAttributeData = async (e, url) => {
    e.preventDefault();

    const data = await payload(url);
    await requestUpdateAttributes({
      variables: {
        input: { accountId: clientInfo?.clientId, ...data },
        token: sessionToken,
      },
      refetchQueries: () => [
        {
          query: CLIENT_INFO_QUERY,
          variables: { [clientIdentifier[0]]: clientIdentifier[1] },
        },
      ],
    });
    setType('');
  };

  const [requestUpdateAttributes, { loading }] = useMutation(
    UPDATE_ATTRIBUTES,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Attributes Updated Successfully');
        data.showModal.setValue(false);

        handleHideToast();
      },
      onError,
    },
  );

  const [mutate, { loading: uploadLoading }] = useMutation(UPLOAD_ATTRIBUTE, {
    async onCompleted() {
      data.showToast.setValue(true);
      data.status.setValue('Success');
      data.content.setValue('File Uploaded Successfully');
      data.showModal.setValue(false);

      handleHideToast();
    },
    onError,
  });

  useEffect(() => {
    const setInputValues = () => {
      if (clientInfo?.termsAndConditionsUrl) {
        inputs.tsandCsUrlLink.setValue(clientInfo?.termsAndConditionsUrl);
      }
      if (clientInfo?.privacyPolicyUrl) {
        inputs.policyUrlLink.setValue(clientInfo?.privacyPolicyUrl);
      }
      if (clientInfo?.howToApplyVideoLink) {
        inputs.howToApplyVideoLink.setValue(clientInfo?.howToApplyVideoLink);
      }
      if (clientInfo?.cookiesPolicyUrl) {
        inputs.cookiesPolicyUrl.setValue(clientInfo?.cookiesPolicyUrl);
      }
    };

    setInputValues();
  }, [clientInfo]);

  return {
    handleUpdateAttributes,
    loading: loading || ciLoading || uploadLoading,
    inputs,
    data,
    handleHideToast,
    handleUploadAttribute,
    logoUrl: clientInfo?.logoUrl,
    faviconUrl: clientInfo?.faviconUrl,
    termsAndConditionsUrl: clientInfo?.termsAndConditionsUrl,
    privacyPolicyUrl: clientInfo?.privacyPolicyUrl,
    howToApplyVideoLink: clientInfo?.howToApplyVideoLink,
    cookiesPolicyUrl: clientInfo?.cookiesPolicyUrl,
  };
};

export { useUploadConfigurations };
