import React from 'react';
import { NavLink } from 'react-router-dom';

const LiquidityManagementNavs = () => {
  return (
    <nav className="loans-menu">
      <NavLink to="/liquidity-management" exact>
        Disbursements
      </NavLink>
      <NavLink to="/liquidity-management/interest-wallet" exact>
        Interest
      </NavLink>
      <NavLink to="/liquidity-management/collections-wallet" exact>
        Collections
      </NavLink>
      <NavLink to="/liquidity-management/manual-repayments" exact>
        Manual Repayments
      </NavLink>
    </nav>
  );
};

export default LiquidityManagementNavs;
