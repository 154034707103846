import React from 'react';

const CompleteIcon = props => (
  <svg width={90} height={90} fill="none" {...props}>
    <path
      d="M82.5 41.55V45a37.5 37.5 0 1 1-22.237-34.275"
      stroke="#07F"
      strokeWidth={3.103}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.5 15L45 52.538l-11.25-11.25"
      stroke="#07F"
      strokeWidth={3.103}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CompleteIcon;
