import { RULEBREAKDOWN_QUERY } from '../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { useCustomState } from '../lib/CustomHooks';

const useRuleBreakDownProvider = applicationId => {
  const inputs = {
    showChangeRuleBreakDownPopup: useCustomState(false),
  };

  const showChangeRuleBreakDownPopup = e => {
    e.preventDefault();

    inputs.showChangeRuleBreakDownPopup.setValue(true);
  };

  const hideChangeRuleBreakDownPopup = e => {
    e.preventDefault();

    inputs.showChangeRuleBreakDownPopup.setValue(false);
  };

  const qrResult = useQuery(RULEBREAKDOWN_QUERY, {
    variables: applicationId,
  });
  const { data, loading, error } = qrResult;

  return {
    loading,
    error,
    data,
    result: qrResult,
    inputs,
    showChangeRuleBreakDownPopup: e => showChangeRuleBreakDownPopup(e),
    hideChangeRuleBreakDownPopup: e => hideChangeRuleBreakDownPopup(e),
  };
};

export { useRuleBreakDownProvider };
