import { useState } from 'react';

export const useFormInput = (initialValue = '', opts = {}) => {
  const { type, errorMessage: initialErrorMessage } = opts;
  const [value, setValue] = useState(initialValue);
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);

  let input = {
    onChange: e => setValue(e.target.value),
    value,
    errormessage: errorMessage,
  };

  if (type === 'checkbox') {
    input = {
      onChange: e => setValue(e.target.checked),
      checked: value,
      errormessage: errorMessage,
    };
  }

  if (type === 'select') {
    input = {
      onChange: e => {
        setValue(e.target.value);
      },
      value: value,
    };
  }

  if (type === 'radio') {
    input = optionValue => ({
      onChange: e => setValue(e.target.value),
      checked: optionValue === value,
      errormessage: errorMessage,
      defaultChecked: opts.isDefaultChecked(optionValue, initialValue),
    });
  }
  return {
    input,
    value,
    setValue,
    initialValue,
    setErrorMessage,
    opts,
  };
};

export const resetInputs = (inputs = []) =>
  inputs.forEach(input => {
    input.setValue(input.initialValue || '');
    input.setErrorMessage(input.opts.errorMessage);
  });

export const useFlashMessage = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);

  const flashError = (newValue, duration = 3000) => {
    setValue(newValue);
    setTimeout(() => setValue(''), duration);
  };

  return [value, flashError, setValue];
};

export const useKeyPair = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);
  return { value, setValue };
};

export const FormInput = (initialValue = '', opts = {}) => {
  const { type, errorMessage: initialErrorMessage } = opts;
  const [value, setValue] = useState(initialValue);
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);

  let input = {
    onChange: e => setValue(e.target.value),
    value,
    errormessage: errorMessage,
  };

  if (type === 'checkbox') {
    input = {
      onChange: e => setValue(e.target.checked),
      checked: value,
      errormessage: errorMessage,
    };
  }

  if (type === 'select') {
    input = {
      onChange: e => {
        setValue(e.target.value);
      },
      value: value,
    };
  }

  if (type === 'radio') {
    input = optionValue => ({
      onChange: e => setValue(e.target.value),
      checked: optionValue === value,
      errormessage: errorMessage,
      defaultChecked: opts.isDefaultChecked(optionValue, initialValue),
    });
  }
  return {
    input,
    value,
    setValue,
    initialValue,
    setErrorMessage,
    opts,
  };
};
