import { graphql } from '@/__generated/gql';

export const GET_DASHBOARD_REPORT_URL = graphql(/* GraphQL */ `
  query GetDashboardReportUrl {
    getDashboardReportUrl {
      iframeUrl
      dashboard
    }
  }
`);
