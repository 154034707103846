import React, { Fragment } from 'react';
import { Button, DataCard, Export, Table } from '../../../../components';
import moment from 'moment';
import { numberWithCommas } from '../../../../lib/Utils';

const CrcNanoReportScoreTab = ({ data, handleExportToCsvCrcScoreReport }) => {
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Button classes="right" click_event={handleExportToCsvCrcScoreReport}>
            <Export />
            <span className="export-csv">Export CSV</span>
          </Button>
          <Table>
            <div className="table__body">
              {data &&
              data.application &&
              data.application.credit &&
              data.application.credit.crcScoreReport ? (
                <ul>
                  <li>
                    <span>Total No Of Delinqent Credit Facilities</span>
                    <span className="right-text">
                      {data.application.credit.crcScoreReport.result != null
                        ? numberWithCommas(
                            data.application.credit.crcScoreReport.result
                              .totalNoOfDelinqCreditFacilities,
                          )
                        : 'N/A'}
                    </span>
                  </li>
                  {data.application.credit.crcScoreReport.result != null &&
                  data.application.credit.crcScoreReport.result.notHit ===
                    true ? (
                    <li>
                      <span>No Hit</span>
                      <span className="right-text">No Hit</span>
                    </li>
                  ) : (
                    ''
                  )}
                  <li>
                    <span>Last Reported Date</span>
                    <span className="right-text">
                      {data.application.credit.crcScoreReport.result != null
                        ? moment(
                            data.application.credit.crcScoreReport.result
                              .lastReportedDate,
                          ).format('ll')
                        : 'N/A'}
                    </span>
                  </li>
                </ul>
              ) : (
                ''
              )}
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default CrcNanoReportScoreTab;
