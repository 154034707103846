import { useBankList } from '@/services/bank';
import { processGraphQLError } from '@/utils/processGraphQLError';
import { startCase, toLower } from 'lodash';
import React from 'react';
import { MultiSelect, MultiSelectProps } from '../MultiSelect';

export const SelectBank = ({
  isInvalid,
  errorMessage,
  placeholder,
  ...props
}: MultiSelectProps) => {
  const { bankList, bankListError, bankListLoading } = useBankList();

  const error = bankListError && processGraphQLError(bankListError);

  return (
    <MultiSelect
      isLoading={bankListLoading}
      isInvalid={isInvalid || !!error}
      errorMessage={errorMessage || error}
      placeholder={placeholder || '-- Select bank --'}
      {...props}
      options={bankList?.map(bank => ({
        label: startCase(toLower(bank.name)),
        value: bank.id,
      }))}
    />
  );
};

export default SelectBank;
