import * as React from 'react';

const UploadIcon = () => (
  <svg
    width="100%"
    height="100%"
    version="1.1"
    viewBox="0 0 24 24"
    fill="none"
    style={{
      shapeRendering: 'geometricPrecision',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
  >
    <path
      d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 
      21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
      style={{
        stroke: '#06192F',
        strokeWidth: '2',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
    />
    <path
      d="M17 8L12 3L7 8"
      style={{
        stroke: '#06192F',
        strokeWidth: '2',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
    />
    <path
      d="M12 3V15"
      style={{
        stroke: '#06192F',
        strokeWidth: '2',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
    />
  </svg>
);

export default UploadIcon;
