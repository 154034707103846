import { graphql } from '@/__generated/gql';

export const CREATE_CUSTOMER = graphql(/* GraphQL */ `
  mutation CreateCustomerMp($input: CustomerCreateMpInput!) {
    createCustomerMp(input: $input) {
      token
      account {
        name
        id
      }
    }
  }
`);
