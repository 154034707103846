import { POLICY_DISABLE_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { CREDIT_POLICIES_QUERY } from '../graphql/queries';

const PolicyDisableProvider = () => {
  const policyDisableInput = {
    policyStatus: useCustomState(),
    policyId: useCustomState(),
    content: useCustomState(),
    status: useCustomState(),
    showToast: useCustomState(false),
    selectedPolicyData: useCustomState({}),
    isDisablePolicyPopupVisible: useCustomState(false),
  };

  const toggleDisablePolicyPopup = (e, data = {}) => {
    e.preventDefault();

    policyDisableInput.isDisablePolicyPopupVisible.setValue(
      !policyDisableInput.isDisablePolicyPopupVisible.value,
    );
    policyDisableInput.selectedPolicyData.setValue(data);
  };

  const handlePolicyDisable = async (e, policyData = {}) => {
    e.preventDefault();

    if (!(policyData && policyData.id)) {
      policyDisableInput.showToast.setValue(true);
      policyDisableInput.content.setValue(
        `Selected Loan Product ${policyData.name || ''} cannot be disabled `,
      );
      handleHideToast(e);
      return;
    }

    await handlePolicyDisableMutation({
      variables: {
        id: policyData.id,
      },
      refetchQueries: () => [{ query: CREDIT_POLICIES_QUERY }],
    });
  };

  const handleHideToast = () => {
    setTimeout(() => {
      policyDisableInput.showToast.setValue(false);
    }, 4000);
  };

  const [handlePolicyDisableMutation, { loading }] = useMutation(
    POLICY_DISABLE_MUTATION,
    {
      async onCompleted() {
        policyDisableInput.status.setValue('Success');
        policyDisableInput.showToast.setValue(true);
        policyDisableInput.content.setValue(
          'Loan Product Disabled Successfully',
        );
        handleHideToast();

        policyDisableInput.isDisablePolicyPopupVisible.setValue(
          !policyDisableInput.isDisablePolicyPopupVisible.value,
        );
      },

      onError(error) {
        policyDisableInput.status.setValue('Failed');
        policyDisableInput.showToast.setValue(true);
        policyDisableInput.content.setValue(trimGraphQLError(error.message));
        handleHideToast();
      },
    },
  );

  return {
    loading,
    policyDisableInput: policyDisableInput,
    handleHideToast: e => handleHideToast(e),
    handlePolicyDisable: (e, policyData) => handlePolicyDisable(e, policyData),
    toggleDisablePolicyPopup: (e, selectedPolicy) =>
      toggleDisablePolicyPopup(e, selectedPolicy),
  };
};

export default PolicyDisableProvider;
