import React, { useEffect, useState } from 'react';
import { Query } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { CREDIT_POLICIES_DETAILS_QUERY } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import { Loader } from '../components';
import { connection_limit } from '../config/config';
import { toast } from 'react-toastify';
import { trimGraphQLError } from '../lib/Utils';
import Constants from '../lib/Constants';

const CreditPolicyDetails = ({ render, match: { params } }) => {
  const { policyId } = params;
  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    if (errorMsg) {
      toast.error(
        trimGraphQLError(
          errorMsg?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
    return () => toast.dismiss();
  }, [errorMsg]);

  const policyDetailsPrompt = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  const handleHideToast = e => {
    e.preventDefault();

    policyDetailsPrompt.showToast.setValue(false);
  };

  const applicationPagination = (e, fetchMore, data) => {
    e.preventDefault();

    fetchMore({
      variables: {
        cursor:
          data.viewer.account.policies.nodes[0].applications.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newNodes =
          fetchMoreResult.viewer.account.policies.nodes[0].applications.nodes;
        const pageInfo =
          fetchMoreResult.viewer.account.policies.nodes[0].applications
            .pageInfo;

        return newNodes.length
          ? {
              viewer: {
                __typename: prev.viewer.__typename,
                account: {
                  __typename: prev.viewer.account.__typename,
                  policies: {
                    __typename: prev.viewer.account.policies.__typename,
                    nodes: [
                      {
                        __typename:
                          prev.viewer.account.policies.nodes[0].__typename,
                        applications: {
                          __typename:
                            prev.viewer.account.policies.nodes[0].applications
                              .__typename,
                          nodes: [
                            ...prev.viewer.account.policies.nodes[0]
                              .applications.nodes,
                            ...newNodes,
                          ],
                          pageInfo,
                        },
                      },
                    ],
                  },
                },
              },
            }
          : prev;
      },
    });
  };

  const portfolioPagination = (e, fetchMore, data) => {
    e.preventDefault();

    fetchMore({
      variables: {
        cursor:
          data.viewer.account.policies.nodes[0].portfolios.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newNodes =
          fetchMoreResult.viewer.account.policies.nodes[0].portfolios.nodes;
        const pageInfo =
          fetchMoreResult.viewer.account.policies.nodes[0].portfolios.pageInfo;

        return newNodes.length
          ? {
              viewer: {
                __typename: prev.viewer.__typename,
                account: {
                  __typename: prev.viewer.account.__typename,
                  policies: {
                    __typename: prev.viewer.account.policies.__typename,
                    nodes: [
                      {
                        __typename:
                          prev.viewer.account.policies.nodes[0].__typename,
                        applications: {
                          __typename:
                            prev.viewer.account.policies.nodes[0].portfolios
                              .__typename,
                          nodes: [
                            ...prev.viewer.account.policies.nodes[0].portfolios
                              .nodes,
                            ...newNodes,
                          ],
                          pageInfo,
                        },
                      },
                    ],
                  },
                },
              },
            }
          : prev;
      },
    });
  };

  return (
    <Query
      query={CREDIT_POLICIES_DETAILS_QUERY}
      variables={{ policyId: policyId, first: connection_limit.value }}
      notifyOnNetworkStatusChange={true}
    >
      {({ loading, error, data, fetchMore }) => {
        if (loading) {
          return <Loader />;
        }
        error && setErrorMsg(error);

        return render({
          policyDetails: data,
          error,
          handleHideToast,
          applicationPagination: e => applicationPagination(e, fetchMore, data),
          portfolioPagination: e => portfolioPagination(e, fetchMore, data),
        });
      }}
    </Query>
  );
};

export default withRouter(CreditPolicyDetails);
