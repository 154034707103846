import React from 'react';
import { Query } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { CLIENT_INFO_QUERY } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import Constants from '../lib/Constants';
import { Loader, MerchantNotFound, MaintenancePage } from '../components';

export const clientIdentifier = (() => {
  let hostname = window.location.hostname.toLowerCase();

  const hostnameSections = hostname.split('.');
  const first = hostnameSections[0];
  const last = hostnameSections[hostnameSections.length - 1];
  const result = first.replace(/(^\w+:|^)\/\//, '');

  let slug;

  if (result.includes(first)) {
    slug = hostnameSections[0];
  } else {
    slug = result;
  }

  if (last === 'local') {
    return ['slug', slug];
  }

  if (!hostname.includes('originate') && !hostname.includes('indicina')) {
    return ['clientUrl', hostname];
  }

  return ['slug', slug];
})();

const OriginateClient = ({ render }) => {
  const useOriginateClientPrompt = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  return (
    <Query
      query={CLIENT_INFO_QUERY}
      variables={{
        [clientIdentifier[0]]: clientIdentifier[1],
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loader />;
        }

        if (error) {
          useOriginateClientPrompt.showToast.setValue(true);
          useOriginateClientPrompt.status.setValue('Failed');
          useOriginateClientPrompt.content.setValue(
            Constants.errorMessages.NETWORK_ERROR,
          );

          if (error.message === 'GraphQL error: Cannot find merchant account') {
            return <MerchantNotFound />;
          } else {
            return <MaintenancePage />;
          }
        }

        return render({
          clientId: data,
        });
      }}
    </Query>
  );
};

export default withRouter(OriginateClient);
