import { CUSTOMER_BLACKLIST } from '@/graphql/mutations/customer';
import { CUSTOMER_DETAILS } from '@/graphql/queries/customers';
import { BlacklistCustomerInput } from '@/__generated/graphql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';

export const useCustomerBlacklist = (name: string) => {
  const toast = useToast();
  const [mutate, { data, loading, error }] = useMutation(CUSTOMER_BLACKLIST, {
    refetchQueries: () => [
      {
        query: CUSTOMER_DETAILS,
        variables: {
          input: {
            name,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onError: () => {},
    onCompleted: () => {
      toast({
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        title: 'Customer blacklisted successfully',
      });
    },
  });

  const blacklistCustomer = ({
    expiryDate,
    reason,
    userId,
  }: BlacklistCustomerInput) => {
    mutate({
      variables: {
        input: {
          expiryDate,
          reason,
          userId,
        },
      },
    });
  };

  return {
    customerBlacklistError: error,
    customerBlacklistLoading: loading,
    customerBlacklistData: data,
    blacklistCustomer,
    successResponse: data?.blacklistCustomer?.reason,
  };
};
