import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const ReactSelect = props => {
  if (props.isMulti) {
    return (
      <Select
        {...props}
        options={[props.allOption, ...props.options]}
        onChange={selected => {
          if (
            selected &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return props.onChange(props.options);
          }
          return props.onChange(selected);
        }}
      />
    );
  }

  return <Select {...props} />;
};

ReactSelect.propTypes = {
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
};

ReactSelect.defaultProps = {
  allOption: {
    label: 'Select all',
    value: '*',
  },
};

export default ReactSelect;
