import React, { Fragment } from 'react';
import {
  DataCard,
  Card,
  Button,
  Input,
  BreadCrumbSection,
  Loader,
  Toast,
} from '../../components';
import './_UserManagement.scss';
import { UseUpdateClientAccountUserDetails } from '../../Providers';

const EditUser = () => (
  <>
    <UseUpdateClientAccountUserDetails
      render={({
        data,
        form,
        handleUpdateUserDetails,
        handleHideToast,
        loading,
      }) => (
        <Fragment>
          {loading && <Loader loadingText="loading..." />}
          <BreadCrumbSection />
          <Toast
            title="Create User"
            content={data.content.value}
            classes={data.status.value}
            active={data.showToast.value}
            clickEvent={handleHideToast}
          />
          <form>
            <div className="form-card top-border  edit-user">
              <DataCard>
                <section className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Basic Information">
                      Define basic information
                    </Card>
                  </div>

                  <div className="form-fields">
                    <div className="flex-container">
                      <div className="form-main">
                        <Card title="Email Address" required>
                          <Input
                            placeholder="shola@indicina.com"
                            required
                            type="email"
                            {...form.email.input}
                            disabled
                          />
                        </Card>
                      </div>
                      <div className="form-sub">
                        <Card title="First Name" required>
                          <Input
                            placeholder="First Name"
                            required
                            type="text"
                            {...form.firstName.input}
                          />
                        </Card>
                        <Card title="Last Name" required>
                          <Input
                            placeholder="Last Name"
                            required
                            type="text"
                            {...form.lastName.input}
                          />
                        </Card>
                      </div>
                    </div>
                  </div>
                </section>
              </DataCard>

              <DataCard>
                <section className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Password">Secure your account</Card>
                  </div>

                  <div className="form-fields">
                    <div className="flex-container">
                      <div className="form-main">
                        <Card title="Old Password" required>
                          <Input
                            placeholder="Enter your old password"
                            required
                            type="password"
                            {...form.oldPassword.input}
                          />
                        </Card>
                      </div>
                      <div className="form-sub">
                        <Card title="New Password" required>
                          <Input
                            placeholder="Enter your new password"
                            required
                            type="password"
                            {...form.password.input}
                          />
                        </Card>
                        <Card title="Confirm Password" required>
                          <Input
                            placeholder="Confirm  new password"
                            required
                            type="password"
                            {...form.confirmPassword.input}
                          />
                        </Card>
                      </div>
                    </div>
                  </div>
                </section>
              </DataCard>

              <div className="flex-container center">
                <Button
                  classes="secondary"
                  type="submit"
                  click_event={handleUpdateUserDetails}
                >
                  Edit User
                </Button>
              </div>
            </div>
          </form>
        </Fragment>
      )}
    />
  </>
);

export default EditUser;
