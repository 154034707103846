import { gql } from 'apollo-boost';

export const fragments = {
  application: gql`
    fragment ApplicationDetails on Application {
      id
    }
  `,

  mbsFeedback: gql`
    fragment MbsFeedbackDetails on MbsFeedback {
      requestId
      status
      feedback
      message
    }
  `,
};

export const REQUEST_SUPPORTING_DOCUMENT = gql`
  mutation MP_RequestSupportingDocument(
    $input: RequestSupportingDocumentInput!
  ) {
    requestSupportingDocument(input: $input) {
      success
      mbsFeedback {
        ...MbsFeedbackDetails
      }
      application {
        ...ApplicationDetails
      }
    }
  }

  ${fragments.mbsFeedback}
  ${fragments.application}
`;

export const REVIEW_SUPPORTING_DOCUMENT = gql`
  mutation MP_ReviewSupportingDocument(
    $input: ReviewSupportingDocumentsInput!
  ) {
    reviewSupportingDocuments(input: $input) {
      success
      mbsFeedback {
        ...MbsFeedbackDetails
      }
      application {
        ...ApplicationDetails
      }
    }
  }

  ${fragments.mbsFeedback}
  ${fragments.application}
`;

export const UPDATE_APPLICATION_DETAILS = gql`
  mutation MP_UpdateApplicationDetails($input: UpdateApplicationDetailsInput!) {
    updateApplicationDetails(input: $input) {
      updated
      application {
        ...ApplicationDetails
      }
    }
  }

  ${fragments.application}
`;

export const MODIFY_PROCESSING_FEE = gql`
  mutation MP_ModifyProcessingFee($input: ModifyProcessingFeeInput!) {
    modifyProcessingFee(input: $input) {
      success
      mbsFeedback {
        ...MbsFeedbackDetails
      }
      application {
        ...ApplicationDetails
      }
    }
  }
  ${fragments.mbsFeedback}
  ${fragments.application}
`;

export const APPLY_MORATORIUM_ON_APPLICATION = gql`
  mutation MP_ApplyMoratoriumOnApplication(
    $input: ApplyMoratoriumOnApplicationInput!
  ) {
    applyMoratoriumOnApplication(input: $input) {
      success
    }
  }
`;

export const UPDATE_APPLICATION_LOAN_DURATION = gql`
  mutation MP_UpdateApplicationLoanDuration(
    $input: UpdateApplicationLoanDurationInput!
  ) {
    updateApplicationLoanDuration(input: $input) {
      updated
    }
  }
`;

export const MODIFY_APPLICATION_INTEREST_RATE = gql`
  mutation MP_ModifyApplicationInterestRate(
    $input: ModifyApplicationInterestRateInput!
  ) {
    modifyApplicationInterestRate(input: $input) {
      success
      mbsFeedback {
        ...MbsFeedbackDetails
      }
      application {
        ...ApplicationDetails
      }
    }
  }
  ${fragments.mbsFeedback}
  ${fragments.application}
`;
