import React, { useState } from 'react';
import cx from 'classnames';

import { BreadCrumbSection } from '../../components';
import MerchantConfiguration from './Configurations/MerchantConfiguration';
import Uploads from './Configurations/Uploads';
import NotificationAddress from './Configurations/NotificationAddress';
import Integrations from './Configurations/Integrations';
import NotificationPreferences from './Configurations/NotificationPreferences';

const Configurations = () => {
  const [isActive, setIsActive] = useState(1);
  const list = [
    {
      id: 1,
      name: 'Details',
    },
    {
      id: 2,
      name: 'Uploads',
    },
    {
      id: 3,
      name: 'Notification Address',
    },
    {
      id: 4,
      name: 'Notification Preferences',
    },
    {
      id: 5,
      name: '3rd Party Credentials',
    },
  ];

  const showRoleColItems = () => {
    return list.map(item => (
      <div
        key={`${item.id}-${item.name}`}
        onClick={() => setIsActive(item.id || 1)}
        className={cx({
          'items ': item.id,
          is_selected: item.id === isActive,
        })}
      >
        {item.name}
      </div>
    ));
  };

  return (
    <>
      <BreadCrumbSection />

      <div className="form-card top-border role-listing config">
        <aside className={'role-item-col'}>
          <div className="roles-holder">{showRoleColItems()}</div>
        </aside>
        <div className={'role-info'}>
          {isActive === 1 ? (
            <MerchantConfiguration />
          ) : isActive === 2 ? (
            <Uploads />
          ) : isActive === 3 ? (
            <NotificationAddress />
          ) : isActive === 4 ? (
            <NotificationPreferences />
          ) : (
            <Integrations />
          )}
        </div>
      </div>
    </>
  );
};

export default Configurations;
