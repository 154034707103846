import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboardIcon, ToggleVisibilityIcon } from '../Icons';
import copy from 'copy-to-clipboard';
import { Input } from '../index';
import './_ToggleVisibilityAndCopy.scss';

const ToggleVisibilityAndCopy = ({ ...restProps }) => {
  const copyTextInput = document.getElementById('secretKeyInput');
  const [showCopiedIcon, setShowCopiedIcon] = useState(false);

  const copyText = () => {
    copy(copyTextInput.value);
    setShowCopiedIcon(prev => !prev);
    setTimeout(() => setShowCopiedIcon(prev => !prev), 2000);
  };

  const [visible, setVisible] = useState(false);

  const toggleShow = () => {
    setVisible(!visible);
  };

  return (
    <div className="toggle-visibility">
      <Input
        type={visible ? 'text' : 'password'}
        id="secretKeyInput"
        {...restProps}
      />

      <div className="copy-icon">
        {showCopiedIcon ? (
          <div aria-label="Copied">✅</div>
        ) : (
          <CopyToClipboardIcon onClick={copyText} />
        )}
      </div>

      {!visible ? (
        <ToggleVisibilityIcon onClick={toggleShow} />
      ) : (
        <ToggleVisibilityIcon color={'#d3d4d8'} onClick={toggleShow} />
      )}
    </div>
  );
};

ToggleVisibilityAndCopy.propTypes = {
  placeholder: PropTypes.string,
};

export default ToggleVisibilityAndCopy;
