import React from 'react';
import './_DataCard.scss';
import PropTypes from 'prop-types';

const DataCard = props => {
  return (
    <div
      className={`data-card ${props.noPadding === true ? 'no-padding' : ''}`}
    >
      {props.title == null ? (
        ''
      ) : (
        <h4 className={'data-card_title'}>{props.title}</h4>
      )}
      <div className={'data-card_content'}>{props.children}</div>
    </div>
  );
};

DataCard.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.string,
  noPadding: PropTypes.bool,
};

export default DataCard;
