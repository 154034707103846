import React from 'react';
import PropTypes from 'prop-types';
import './_CheckBox.scss';

const CheckBox = ({ classes, label, ...restProps }) => {
  return (
    <label>
      <input
        className={`${classes == null ? '' : classes}`}
        type="checkbox"
        {...restProps}
      />
      {label && label.length > 0 && (
        <span className="checkbox__label">{label}</span>
      )}
    </label>
  );
};

CheckBox.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
};

export default CheckBox;
