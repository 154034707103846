import { BenefitsIcon } from '@/icons/BenefitsIcon';
import { BusinessIcon } from '@/icons/BusinessIcon';
import { FinancialsIcon } from '@/icons/FinancialsIcon';
import { SummitIcon } from '@/icons/SummitIcon';
import { Stack, StackDivider } from '@chakra-ui/layout';
import React from 'react';
import { CreateEditLoanProductLayoutType } from '../../types';
import { LoanProductStepperItem } from '../LoanProductStepperItem';
import { useParams } from 'react-router-dom-v6';
import { EditIcon } from '@/icons/EditIcon';

const LoanProductStepper = ({
  actionType,
}: CreateEditLoanProductLayoutType) => {
  const { '*': currentStep } = useParams();
  return (
    <Stack
      p={4}
      direction="row"
      justifyContent="stretch"
      divider={<StackDivider />}
      spacing={4}
      bg="white"
    >
      <LoanProductStepperItem
        actionType={actionType}
        label="Product Details"
        icon={BusinessIcon}
        url=""
        shouldHighlight
      />
      <LoanProductStepperItem
        actionType={actionType}
        label="Financials"
        url="financials"
        icon={FinancialsIcon}
        shouldHighlight={
          currentStep === 'financials' ||
          currentStep === 'loan-attributes' ||
          currentStep === 'workflows' ||
          currentStep === 'form-builder'
        }
      />
      <LoanProductStepperItem
        actionType={actionType}
        label="Form Builder"
        url="form-builder"
        icon={EditIcon}
        shouldHighlight={
          currentStep === 'loan-attributes' ||
          currentStep === 'workflows' ||
          currentStep === 'form-builder'
        }
      />
      <LoanProductStepperItem
        actionType={actionType}
        label="Loan Attributes"
        url="loan-attributes"
        icon={BenefitsIcon}
        shouldHighlight={
          currentStep === 'loan-attributes' || currentStep === 'workflows'
        }
      />
      <LoanProductStepperItem
        actionType={actionType}
        label="Workflows"
        url="workflows"
        icon={SummitIcon}
        shouldHighlight={currentStep === 'workflows'}
      />
    </Stack>
  );
};

export default LoanProductStepper;
