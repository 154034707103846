import { DELETE_LOAN_PRODUCT } from '@/graphql/mutations//loan-products';
import { GET_LOAN_PRODUCTS } from '@/graphql/queries/loan-products';
import { DeleteLoanProductMutationVariables } from '@/__generated/graphql';
import { useMutation } from '@apollo/client';

export const useDeleteLoanProduct = () => {
  const [mutate, { data, loading, error }] = useMutation(DELETE_LOAN_PRODUCT, {
    update: (cache, { data: { policyDelete } }) => {
      const { viewer } = cache.readQuery({ query: GET_LOAN_PRODUCTS });

      const updatedPolicies = viewer.account.policies.nodes.filter(
        policy => policy.id !== policyDelete?.policy.id,
      );

      cache.writeQuery({
        query: GET_LOAN_PRODUCTS,
        data: {
          viewer: {
            ...viewer,
            account: {
              ...viewer.account,
              policies: {
                ...viewer.account.policies,
                nodes: updatedPolicies,
              },
            },
          },
        },
      });
    },
  });

  const deleteLoanProduct = (id: DeleteLoanProductMutationVariables['id']) => {
    mutate({
      variables: {
        id,
      },
    });
  };

  return {
    deleteLoanProduct,
    deleteLoanProductError: error,
    deleteLoanProductLoading: loading,
    deleteLoanProductData: data?.policyDelete,
  };
};
