/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {
  DataCard,
  Card,
  Button,
  Toast,
  Input,
  Select,
  BreadCrumbSection,
  Loader,
  EmptyScreen,
} from '../../components';
import { CreatePolicyProvider } from '../../Providers';

import './_Styles.scss';

const CreatePolicy = () => (
  <>
    <Fragment>
      <BreadCrumbSection />

      {
        <CreatePolicyProvider
          render={({
            enumData,
            error,
            handleCreatePolicy,
            policyInput,
            handleHideToast,
            loading,
            loanDescription,
          }) => {
            if (error) {
              return (
                <Fragment>
                  <div className="form-card top-border">
                    <DataCard noPadding={true}>
                      <EmptyScreen message="Error Fetching Loan Products" />
                    </DataCard>
                  </div>
                </Fragment>
              );
            }
            return (
              <form onSubmit={handleCreatePolicy}>
                {loading && <Loader />}
                <div className="form-card top-border">
                  <DataCard>
                    <div className="flex-container space-between">
                      <div className="form-desc">
                        <Card title="Basic Information">
                          Define basic information
                        </Card>
                      </div>
                      <div className="form-fields flex-container space-between">
                        <Card title="Policy Name" required>
                          <Input
                            placeholder="Enter Name"
                            required
                            type="text"
                            {...policyInput?.name?.input}
                          />
                        </Card>

                        <Card>
                          <Select
                            label={'Select Repayment Type'}
                            required
                            {...policyInput?.repaymentType?.input}
                          >
                            <option disabled value="">
                              -- Select Repayment Type --
                            </option>
                            {enumData &&
                              enumData['durationType'] &&
                              enumData['durationType']?.map((item, key) => (
                                <option key={key} value={item?.name}>
                                  {item?.name}
                                </option>
                              ))}
                          </Select>
                        </Card>

                        <Card>
                          <Select
                            label={'Select Duration Type'}
                            required
                            {...policyInput?.durationType?.input}
                          >
                            <option disabled value="">
                              -- Select Duration Type --
                            </option>
                            {enumData &&
                              enumData['durationType'] &&
                              enumData['durationType']?.map((item, key) => (
                                <option key={key} value={item?.name}>
                                  {item?.name}
                                </option>
                              ))}
                          </Select>
                        </Card>
                      </div>
                    </div>
                  </DataCard>

                  <DataCard>
                    <div className="flex-container space-between">
                      <div className="form-desc">
                        <Card title="Loan Information">
                          This is where you define loan related criterial. This
                          is helps set a limit as to what the loan terms would
                          be.
                        </Card>
                      </div>

                      <div className="form-fields">
                        <div className="flex-container double-col">
                          <div className="form-fields">
                            <Card title="Minimum Loan Amount (NGN):">
                              <NumberFormat
                                thousandSeparator={true}
                                placeholder="e.g 5,000"
                                className="input"
                                {...policyInput?.minLoanAmount?.input}
                              />
                            </Card>

                            <Card
                              title={`Minimum Loan Term (${loanDescription(
                                policyInput?.durationType?.input?.value,
                              )}):`}
                            >
                              <Input
                                type="number"
                                placeholder="e.g 1"
                                {...policyInput?.minLoanDuration?.input}
                              />
                            </Card>
                          </div>
                          <div className="form-fields">
                            <Card title="Maximum Loan Amount (NGN):">
                              <NumberFormat
                                thousandSeparator={true}
                                placeholder="e.g 15,000"
                                className="input"
                                {...policyInput?.maxLoanAmount?.input}
                              />
                            </Card>

                            <Card
                              title={`Maximum Loan Term (${loanDescription(
                                policyInput?.durationType?.input?.value,
                              )}):`}
                            >
                              <Input
                                type="number"
                                placeholder="e.g 6"
                                {...policyInput?.maxLoanDuration?.input}
                              />
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DataCard>

                  <DataCard>
                    <div className="flex-container space-between">
                      <div className="form-desc">
                        <Card title="Policy Rules">
                          This is where you define this Policy’s acceptance
                          criteria.
                        </Card>
                      </div>

                      <div className="form-fields">
                        <div className="flex-container double-col">
                          <div className="form-fields sections">
                            <Card title="Late Repayment Rate:">
                              <Input
                                placeholder="Rate"
                                {...policyInput?.lateRepaymentTypeName?.input}
                              />
                              <Select
                                label={'Select Calculation Type'}
                                required
                                {...policyInput?.lateRepaymentTypeCalcBy?.input}
                              >
                                <option disabled value="">
                                  -- Select Calculation Type --
                                </option>
                                {enumData &&
                                  enumData['rateCalcBy'] &&
                                  enumData['rateCalcBy']?.map((item, key) => (
                                    <option key={key} value={item?.name}>
                                      {item?.name}
                                    </option>
                                  ))}
                              </Select>
                              <Input
                                placeholder="123"
                                label={'Calculation Type Value'}
                                required
                                {...policyInput?.lateRepaymentTypeValue?.input}
                              />
                            </Card>
                          </div>
                          <div className="form-fields sections">
                            <Card title="Interest Rate:">
                              <Input
                                placeholder="Rate"
                                {...policyInput?.interestRateName?.input}
                              />
                              <Select
                                label={'Select Calculation Type'}
                                required
                                {...policyInput?.interestRateCalcBy?.input}
                              >
                                <option disabled value="">
                                  -- Select Calculation Type --
                                </option>
                                {enumData &&
                                  enumData['rateCalcBy'] &&
                                  enumData['rateCalcBy']?.map((item, key) => (
                                    <option key={key} value={item?.name}>
                                      {item?.name}
                                    </option>
                                  ))}
                              </Select>
                              <Input
                                placeholder="123"
                                label={'Calculation Type Value'}
                                required
                                {...policyInput?.interestRateValue?.input}
                              />
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DataCard>

                  <Toast
                    title="Create New Policy"
                    content={policyInput?.content?.value}
                    active={policyInput?.showToast?.value}
                    clickEvent={handleHideToast}
                  />

                  <div className="flex-container justify-end">
                    <Link to="/policy">
                      <Button classes="primary" type="submit">
                        View Policies
                      </Button>
                    </Link>
                    <Button classes="secondary" type="submit">
                      Create Policy
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        />
      }
    </Fragment>
  </>
);

export default CreatePolicy;
