import { useQuery } from '@apollo/client';

import { GET_LOAN_PRODUCT_DETAILS } from '@/graphql/queries/loan-products';

export const useGetLoanProductDetails = (policyId: string) => {
  const { data, loading, error, refetch } = useQuery(GET_LOAN_PRODUCT_DETAILS, {
    variables: {
      id: policyId,
    },
  });

  return {
    refetchProductDetails: refetch,
    loanProductDetailsError: error,
    loanProductDetailsLoading: loading,
    loanProductDetails: data?.policyById,
  };
};
