import { graphql } from '@/__generated/gql';

export const DELETE_LOAN_PRODUCT = graphql(/* GraphQL */ `
  mutation DeleteLoanProduct($id: ID!) {
    policyDelete(id: $id) {
      success
      policy {
        id
        name
      }
    }
  }
`);
