import { CREATE_LOAN_PRODUCT } from '@/graphql/mutations/loan-products/createProduct';
import { GET_LOAN_PRODUCTS } from '@/graphql/queries/loan-products';
import { CreateLoanProductMutationVariables } from '@/__generated/graphql';
import { useMutation } from '@apollo/client';

export const useCreateLoanProduct = () => {
  const [mutate, { data, loading, error }] = useMutation(CREATE_LOAN_PRODUCT, {
    update: (cache, { data: { policyCreate } }) => {
      const queryResult = cache.readQuery({ query: GET_LOAN_PRODUCTS });
      const viewer = queryResult?.viewer;

      if (viewer) {
        const updatedPolicies = {
          ...viewer.account.policies,
          nodes: policyCreate?.record
            ? [policyCreate.record, ...viewer.account.policies.nodes]
            : viewer.account.policies.nodes,
          totalCount: policyCreate?.record
            ? viewer.account.policies.totalCount + 1
            : viewer.account.policies.totalCount,
        };

        cache.writeQuery({
          query: GET_LOAN_PRODUCTS,
          data: {
            viewer: {
              ...viewer,
              account: {
                ...viewer.account,
                policies: updatedPolicies,
              },
            },
          },
        });
      }
    },
  });

  const createProduct = ({
    name,
    loanType,
    description,
    durationType,
    maxLoanDuration,
    minLoanDuration,
    maxLoanAmount,
    minLoanAmount,
    startDate,
    endDate,
    interestRate,
    graduatedLoanCycles,
    interestCalculationMethod,
  }: CreateLoanProductMutationVariables['input']) => {
    mutate({
      variables: {
        input: {
          name,
          loanType,
          description,
          durationType,
          maxLoanDuration,
          minLoanDuration,
          maxLoanAmount,
          minLoanAmount,
          startDate,
          endDate,
          interestRate,
          graduatedLoanCycles,
          interestCalculationMethod,
        },
      },
    });
  };

  return {
    createProduct,
    createProductError: error,
    createProductLoading: loading,
    productId: data?.policyCreate?.recordId,
  };
};
