import { useQuery } from '@apollo/react-hooks';

import { GET_POLICY_RULE_TYPES } from './queries';

const useGetPolicyRuleTypes = () => {
  let output = {
    policyRuleTypes: [],
  };

  const qResult = useQuery(GET_POLICY_RULE_TYPES);
  const { data, loading, error } = qResult;

  if (!loading && data) {
    output = {
      policyRuleTypes: data,
    };
  }

  return {
    loading,
    error,
    ...output,
    result: qResult,
  };
};

export { useGetPolicyRuleTypes };
