import React from 'react';

const PortfolioIcon = () => (
  <svg
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4029 3.75429H18.7714V1.87715C18.7714 0.840426 17.931 0 16.8943 0H9.38572C8.34901 0 7.50858 0.840426 7.50858 1.87715V3.75429H1.87714C0.840426 3.75429 0 4.59472 0 5.63144V21.2743C0 22.311 0.840426 23.1515 1.87714 23.1515H24.4029C25.4396 23.1515 26.28 22.311 26.28 21.2743V5.63144C26.28 4.59472 25.4396 3.75429 24.4029 3.75429ZM9.38573 1.87803H16.8943V3.75518H9.38573V1.87803ZM24.4029 5.63232V10.3314C24.2577 10.3297 24.1142 10.3618 23.9836 10.4253C21.4704 11.5903 18.84 12.4839 16.1372 13.0908C15.8585 12.3688 15.1653 11.8918 14.3914 11.8895H11.8886C11.095 11.8917 10.3886 12.3927 10.124 13.1409C7.37908 12.5605 4.71486 11.6486 2.18997 10.4253C2.08242 10.372 1.96554 10.3401 1.84583 10.3314V5.63232H24.4029ZM14.3914 13.7666V15.6438H11.8886V13.7666H14.3914ZM1.87714 21.2755V12.3841C4.47633 13.5873 7.20588 14.4859 10.0114 15.0622V15.6441C10.0114 16.6808 10.8519 17.5212 11.8886 17.5212H14.3914C15.4282 17.5212 16.2686 16.6808 16.2686 15.6441V14.9683C19.0876 14.4185 21.8224 13.5013 24.4029 12.2402V21.2755H1.87714Z"
      fill="#0077FF"
    />
  </svg>
);

export default PortfolioIcon;
