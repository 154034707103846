import React, { useEffect } from 'react';
import config from '../../config/config';
import { useClientInfo } from '../../Providers-new/Client';

const ProdCamp = () => {
  const { clientInfo } = useClientInfo();
  const { firstName, lastName, email } = localStorage;

  useEffect(() => {
    if (config.web.app_portal === 'live' && clientInfo?.name) {
      window.ProdCamp('initNPSWidget');

      window.ProdCamp('initFeedbackWidget', {
        email,
        lastName,
        firstName,
        company: {
          name: clientInfo?.name,
        },
      });
    }
  }, [clientInfo]);

  return <></>;
};
export default ProdCamp;
