import { useQuery } from '@apollo/react-hooks';

import { GET_LOAN_PRODUCTS } from './queries';

const useGetLoanProducts = () => {
  let output = {
    loanProducts: [],
  };

  const qResult = useQuery(GET_LOAN_PRODUCTS);
  const { data, loading, error } = qResult;

  if (!loading && data) {
    output = {
      loanProducts: data,
    };
  }

  return {
    loading,
    error,
    ...output,
    result: qResult,
  };
};

export { useGetLoanProducts };
