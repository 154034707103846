import { Icon, IconProps } from '@chakra-ui/icon';
import React from 'react';

export const EditIcon = ({ ...props }: IconProps) => (
  <Icon w={6} h={6} fill="gray.500" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      d="M3.879 6.879A3 3 0 016 6h3a1 1 0 010 2H6a1 1 0 00-1 1v9a1 1 0 001 1h9a1 1 0 001-1v-3a1 1 0 112 0v3a3 3 0 01-3 3H6a3 3 0 01-3-3V9a3 3 0 01.879-2.121z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M16.793 2.793a3.121 3.121 0 014.414 4.414l-8.5 8.5A1 1 0 0112 16H9a1 1 0 01-1-1v-3a1 1 0 01.293-.707l8.5-8.5zM19 3.879c-.297 0-.583.118-.793.328L10 12.414V14h1.586l8.207-8.207A1.121 1.121 0 0019 3.879z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M15.293 4.293a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </Icon>
);
