import { baseRoutes } from '@/routes/routes';
import { useEnableLoanProduct } from '@/services/loan-products';
import { processGraphQLError } from '@/utils/processGraphQLError';
import { PublicPolicyStatus } from '@/__generated/graphql';
import {
  Button,
  Flex,
  Link as ChakraLink,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom-v6';
import { FormsProps } from '../../forms/forms.types';
import { loanProductRoutes } from '../../routes';

const LoanProductCreated = ({ defaultValues }: FormsProps) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const toast = useToast();

  const {
    enableLoanProduct,
    enableLoanProductData,
    enableLoanProductError,
    enableLoanProductLoading,
  } = useEnableLoanProduct();

  const handleEnableProduct = () => {
    enableLoanProduct(productId);
  };

  useEffect(() => {
    if (enableLoanProductError) {
      toast.closeAll();

      toast({
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
        description: startCase(processGraphQLError(enableLoanProductError)),
        title: startCase('Error'),
      });
    }
  }, [enableLoanProductError]);

  useEffect(() => {
    if (enableLoanProductData?.recordId) {
      toast.closeAll();

      toast({
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        title: startCase('Product has been enabled successfully'),
      });

      navigate(loanProductRoutes.list);
    }
  }, [enableLoanProductData]);

  return (
    <Flex flex={1} alignItems="center" justifyContent="center">
      <Stack
        py={10}
        px={6}
        w="2xl"
        bg="white"
        maxW="100%"
        spacing={10}
        alignItems="center"
        justifyContent="center"
        borderRadius="lg"
        boxShadow="xl"
      >
        {/* <Flex>
          <Flex
            w={32}
            h={32}
            bg="green.50"
            alignItems="center"
            borderRadius="full"
            p={{ base: 6, lg: 8 }}
            justifyContent="center"
          >
            <CheckIcon stroke="green.500" w={12} h={12} />
          </Flex>
        </Flex> */}

        <Stack spacing={4}>
          <Text
            textAlign="center"
            maxW="lg"
            fontWeight="medium"
            color="gray.600"
            lineHeight="7"
            fontSize="lg"
          >
            Your loan product{' '}
            <Text as="span" fontWeight="semibold">
              {defaultValues.name}
            </Text>{' '}
            has been updated.
          </Text>

          <Stack spacing={4} alignItems="center">
            {defaultValues.status !== PublicPolicyStatus.Active && (
              <Button
                px={10}
                onClick={handleEnableProduct}
                isLoading={enableLoanProductLoading}
              >
                Activate loan product
              </Button>
            )}

            <Flex justifyContent="center">
              <ChakraLink
                as={Link}
                to={baseRoutes.loanProducts}
                _hover={{ textDecoration: 'none' }}
                pointerEvents={enableLoanProductLoading ? 'none' : null}
              >
                <Button
                  as="div"
                  cursor="pointer"
                  variant={
                    defaultValues.status === PublicPolicyStatus.Active
                      ? 'solid'
                      : 'ghost'
                  }
                >
                  View all loan products
                </Button>
              </ChakraLink>
            </Flex>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default LoanProductCreated;
