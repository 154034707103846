import { withRouter } from 'react-router-dom';
import { COMPLETE_APPLICATION_REVIEW } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';

const CompleteApplicationReview = ({ render, history, applicationId }) => {
  const completeApplicationReviewPrompt = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showPopup: useCustomState(false),
    comment: useCustomState(),
    message: useCustomState(),
  };

  const handleHideToast = e => {
    e.preventDefault();

    setTimeout(() => {
      completeApplicationReviewPrompt.showToast.setValue(false);
    }, 3500);
  };

  const handleCompleteApplication = (e, completeApplicationReviewMutation) => {
    e.preventDefault();

    const { comment: message } = completeApplicationReviewPrompt;

    completeApplicationReviewMutation({
      variables: {
        applicationId,
        comment: message.value,
      },
    });
  };

  const showCompleteApplicationReviewPopup = e => {
    e.preventDefault();

    completeApplicationReviewPrompt.showPopup.setValue(
      !completeApplicationReviewPrompt.showPopup.value,
    );
  };

  const [completeApplicationReviewMutation, { loading }] = useMutation(
    COMPLETE_APPLICATION_REVIEW,
    {
      async onCompleted() {
        completeApplicationReviewPrompt.showToast.setValue(true);
        completeApplicationReviewPrompt.status.setValue('Success');
        completeApplicationReviewPrompt.content.setValue(
          'Application Reviewed',
        );
        completeApplicationReviewPrompt.showPopup.setValue(
          !completeApplicationReviewPrompt.showPopup.value,
        );
        setTimeout(() => {
          window.location.href = history.location.pathname;
        }, 1000);
      },

      onError(error) {
        completeApplicationReviewPrompt.showToast.setValue(true);
        completeApplicationReviewPrompt.status.setValue('Failed');
        completeApplicationReviewPrompt.content.setValue(
          trimGraphQLError(error.message),
        );
      },
    },
  );

  return render({
    handleHideToast,
    handleCompleteApplication: e =>
      handleCompleteApplication(e, completeApplicationReviewMutation),
    input: completeApplicationReviewPrompt,
    applicationId,
    showCompleteApplicationReviewPopup,
    completeReviewLoader: loading,
  });
};

export default withRouter(CompleteApplicationReview);
