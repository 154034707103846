import { baseRoutes } from '@/routes/routes';

const baseUrl = baseRoutes.loanProducts;

export const loanProductRoutes = {
  list: `${baseUrl}`,
  create: `${baseUrl}/create`,
  view: `${baseUrl}/:productId`,
  edit: `${baseUrl}/edit`,
};
