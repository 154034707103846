import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  CREATE_CLIENT_ACCOUNT_USER_MUTATION,
  UPDATE_CLIENT_ACCOUNT_USER_MUTATION,
} from '../graphql/mutations';
import { USER_MANAGEMENT_QUERY } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import {
  addUnderscore,
  trimGraphQLError,
  generatePassword,
  validateEmail,
} from '../lib/Utils';
import { useMutation, useQuery } from '@apollo/react-hooks';
import OriginateClient from './OriginateClient';

const CreateClientAccountUserProvider = ({
  render,
  match: { params },
  history,
}) => {
  let userEmail = params.userEmail || null;
  let userId = params.userId || null;
  const [setEditMode] = useState(false);
  const [userSpecificPermissionsIds, setUserSpecificPermissionsIds] = useState(
    [],
  );

  const createClientAccountUserInput = {
    firstName: useCustomState(),
    lastName: useCustomState(),
    emailAddress: useCustomState(),
    phoneNumber: useCustomState(),
    password: useCustomState(),
    roles: useCustomState(''),
    permissionList: useCustomState(),
    selectedRole: useCustomState(),
    selectedRoleDescription: useCustomState(),
    showToast: useCustomState(false),
    showNotificationPopup: useCustomState(false),
    isEditMode: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  useEffect(() => {
    if (params && params.userId) {
      setEditMode(true);
      createClientAccountUserInput.isEditMode.setValue(true);
    }
  });

  const { data: userDetails = [] } = useQuery(USER_MANAGEMENT_QUERY, {
    variables: {
      clientId: userId || 0,
      email: userEmail || '',
    },
  });

  const updateFields = data => {
    if (data) {
      const { user, roles = [], permissions = [] } = data;

      const initialUserSpecificPerms = [];
      if (roles[0] && permissions.length > 0) {
        permissions.forEach(p => {
          if (!roles[0].permissions.find(rp => rp.id === p.id)) {
            initialUserSpecificPerms.push(p.id);
          }
        });
        setUserSpecificPermissionsIds(initialUserSpecificPerms);
      }

      setTimeout(() => {
        createClientAccountUserInput.firstName.setValue(user.firstName);
        createClientAccountUserInput.lastName.setValue(user.lastName);
        createClientAccountUserInput.emailAddress.setValue(user.email);
        createClientAccountUserInput.phoneNumber.setValue(user.phone);
        // TMP fix
        roles[0] &&
          createClientAccountUserInput.selectedRole.setValue(
            JSON.stringify(roles[0]),
          );
        roles[0] &&
          createClientAccountUserInput.selectedRoleDescription.setValue(
            roles[0].description,
          );
        // TMP fix
      }, 500);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.clientAccountUsersXn) {
      let data = userDetails.clientAccountUsersXn.nodes.filter(
        item => item.user.id === userId && item.user.email === userEmail,
      ) || [{}];
      updateFields(data[0]);
      createClientAccountUserInput.isEditMode.setValue(false);
    }
  }, [userDetails]);

  const getSelectedRolePermissionIds = () => {
    let result = [];
    const selectedPermission =
      createClientAccountUserInput.selectedRole.input.value !== ''
        ? JSON.parse(createClientAccountUserInput.selectedRole.input.value)
            .permissions
        : [];

    if (selectedPermission.length)
      selectedPermission.map(item => result.push(item.id));

    return result;
  };

  const getSelectedRolePermissions = () => {
    let result = [];
    let selectedPermission = [];

    if (createClientAccountUserInput.selectedRole.input.value !== '') {
      const parsedValue = JSON.parse(
        createClientAccountUserInput.selectedRole.input.value,
      );
      selectedPermission = parsedValue.permissions;
      createClientAccountUserInput.selectedRoleDescription.setValue(
        parsedValue.description,
      );
    }

    if (selectedPermission.length >= 1) result = selectedPermission;

    return result;
  };

  const getFilteredRolePermissions = () => {
    let result = [];
    const selectedPermission =
      createClientAccountUserInput.permissionList.input.value !== ''
        ? createClientAccountUserInput.permissionList.input.value
        : [];

    if (selectedPermission.length) {
      result = selectedPermission.filter(
        item => !getSelectedRolePermissionIds().includes(item.id),
      );
    }

    // TMP fix
    if (userSpecificPermissionsIds.length > 0) {
      result = result.map(p => ({
        ...p,
        isChecked: userSpecificPermissionsIds.indexOf(p.id) > -1,
      }));
    }
    // TMP fixs

    return result;
  };

  const handlePrivilegesCheckBoxInput = () => {
    let result = [];
    let checkedBoxes = document.querySelectorAll(
      'input[type=checkbox].privileges_checkbox:checked',
    );

    checkedBoxes.forEach(item => {
      result.push(item.value);
    });

    return result;
  };

  const handleHideToast = () => {
    setTimeout(() => {
      createClientAccountUserInput.showToast.setValue(false);
    }, 2500);
  };

  const handleHideNewToast = () => {
    setTimeout(() => {
      createClientAccountUserInput.showNotificationPopup.setValue(false);
    }, 4000);
  };

  const handleCreateClientAccountUser = async (
    e,
    handleCreateClientAccountUserMutation,
    clientId,
  ) => {
    e.preventDefault();

    const selectedPermissions = handlePrivilegesCheckBoxInput().toString();
    const selectedRole = addUnderscore(
      JSON.parse(
        createClientAccountUserInput.selectedRole.input.value,
      ).name.trim(),
    );

    if (
      !createClientAccountUserInput.firstName.value ||
      !createClientAccountUserInput.lastName.value ||
      !createClientAccountUserInput.emailAddress.value ||
      !createClientAccountUserInput.phoneNumber.value ||
      selectedPermissions === '' ||
      selectedRole === ''
    ) {
      createClientAccountUserInput.showToast.setValue(true);
      createClientAccountUserInput.status.setValue('Failed');
      createClientAccountUserInput.content.setValue(
        'Please fill all required input',
      );

      handleHideToast(e);
      return;
    }

    if (!validateEmail(createClientAccountUserInput.emailAddress.value)) {
      createClientAccountUserInput.showToast.setValue(true);
      createClientAccountUserInput.status.setValue('Failed');
      createClientAccountUserInput.content.setValue('Invalid Email Address.');

      handleHideToast(e);
      return;
    }

    if (createClientAccountUserInput.phoneNumber.value.length < 10) {
      createClientAccountUserInput.showToast.setValue(true);
      createClientAccountUserInput.status.setValue('Failed');
      createClientAccountUserInput.content.setValue('Invalid Phone Number.');

      handleHideToast(e);
      return;
    }

    await handleCreateClientAccountUserMutation({
      variables: {
        clientId: clientId.clientInfo.clientId,
        firstName: createClientAccountUserInput.firstName.value,
        lastName: createClientAccountUserInput.lastName.value,
        email: createClientAccountUserInput.emailAddress.value,
        password: createClientAccountUserInput.password.value,
        roles: selectedRole,
        permissions: selectedPermissions.split(','),
        phone: createClientAccountUserInput.phoneNumber.value,
      },
    });
  };

  const handleUpdateClientAccountUser = async (
    e,
    handleUpdateClientAccountUserMutation,
    clientId,
  ) => {
    e.preventDefault();

    const selectedPermissions = handlePrivilegesCheckBoxInput().toString();
    const selectedRole = addUnderscore(
      JSON.parse(
        createClientAccountUserInput.selectedRole.input.value,
      ).name.trim(),
    );

    if (
      !createClientAccountUserInput.firstName.value ||
      !createClientAccountUserInput.lastName.value ||
      !createClientAccountUserInput.emailAddress.value ||
      !createClientAccountUserInput.phoneNumber.value ||
      selectedPermissions === '' ||
      selectedRole === ''
    ) {
      createClientAccountUserInput.showToast.setValue(true);
      createClientAccountUserInput.status.setValue('Failed');
      createClientAccountUserInput.content.setValue(
        'Please fill all required input',
      );

      handleHideToast(e);
      return;
    }

    if (!validateEmail(createClientAccountUserInput.emailAddress.value)) {
      createClientAccountUserInput.showToast.setValue(true);
      createClientAccountUserInput.status.setValue('Failed');
      createClientAccountUserInput.content.setValue('Invalid Email Address.');

      handleHideToast(e);
      return;
    }

    if (createClientAccountUserInput.phoneNumber.value.length < 10) {
      createClientAccountUserInput.showToast.setValue(true);
      createClientAccountUserInput.status.setValue('Failed');
      createClientAccountUserInput.content.setValue('Invalid Phone Number.');

      handleHideToast(e);
      return;
    }

    await handleUpdateClientAccountUserMutation({
      variables: {
        clientId: clientId.clientInfo.clientId,
        userId: userId,
        firstName: createClientAccountUserInput.firstName.value,
        lastName: createClientAccountUserInput.lastName.value,
        email: createClientAccountUserInput.emailAddress.value,
        roles: selectedRole,
        permissions: selectedPermissions.split(','),
        phone: createClientAccountUserInput.phoneNumber.value,
      },
    });
  };

  const [handleUpdateClientAccountUserMutation] = useMutation(
    UPDATE_CLIENT_ACCOUNT_USER_MUTATION,
    {
      async onCompleted() {
        createClientAccountUserInput.showToast.setValue(true);
        createClientAccountUserInput.status.setValue('Success');
        createClientAccountUserInput.content.setValue('Updated');
        createClientAccountUserInput.showNotificationPopup.setValue(true);

        handleHideNewToast();
        history.push('/user-management');
      },

      onError(error) {
        createClientAccountUserInput.showToast.setValue(true);
        createClientAccountUserInput.status.setValue('Failed');
        createClientAccountUserInput.content.setValue(
          trimGraphQLError(error.message),
        );
        handleHideToast();
      },
    },
  );

  const populatePasswordField = e => {
    e.preventDefault();

    createClientAccountUserInput.password.setValue(generatePassword(15));
  };

  const [handleCreateClientAccountUserMutation, { loading, error }] =
    useMutation(CREATE_CLIENT_ACCOUNT_USER_MUTATION, {
      async onCompleted() {
        createClientAccountUserInput.showToast.setValue(true);
        createClientAccountUserInput.status.setValue('Success');
        createClientAccountUserInput.content.setValue('Created');
        createClientAccountUserInput.showNotificationPopup.setValue(true);

        handleHideNewToast();
        history.push('/user-management');
      },

      onError(error) {
        createClientAccountUserInput.showToast.setValue(true);
        createClientAccountUserInput.status.setValue('Failed');
        createClientAccountUserInput.content.setValue(
          trimGraphQLError(error.message),
        );
        handleHideToast();
      },
    });

  return (
    <OriginateClient
      render={({ clientId }) =>
        render({
          loading,
          error,
          accountUserInput: createClientAccountUserInput,
          handleHideToast: e => handleHideToast(e),
          handleCreateClientAccountUser: e =>
            handleCreateClientAccountUser(
              e,
              handleCreateClientAccountUserMutation,
              clientId,
            ),
          handleUpdateClientAccountUser: e =>
            handleUpdateClientAccountUser(
              e,
              handleUpdateClientAccountUserMutation,
              clientId,
              userId,
            ),
          selectedRolePermissionIds: () => getSelectedRolePermissionIds(),
          handlePrivilegesCheckBoxInput: () => handlePrivilegesCheckBoxInput(),
          filteredRolePermissions: () => getFilteredRolePermissions(),
          selectedRolePermissions: () => getSelectedRolePermissions(),
          populatePasswordField: e => populatePasswordField(e),
        })
      }
    />
  );
};

export default withRouter(CreateClientAccountUserProvider);
