import React from 'react';
import PropTypes from 'prop-types';
import './_Modal.scss';
import { Close } from '../index';
import { useCustomState } from '../../lib/CustomHooks';

const Modal = props => {
  const isOpen = useCustomState(true);

  const handleCloseIcon = e => {
    e.preventDefault();
    isOpen.setValue(!props.active);
  };

  return (
    isOpen.value && (
      <section
        className={`modal ${props.classes ? props.classes : ''} ${
          props.active ? 'active' : ''
        }`}
      >
        <div className="modal__holder">
          <div
            className={`modal__content ${
              props.noPadding === true ? 'no-padding' : ''
            }`}
          >
            <div className="modal__content-top">
              <h4 className={'modal__content-title'}>{props.title}</h4>
              <span>
                {props.closeIcon && <Close onClick={e => handleCloseIcon(e)} />}
              </span>
            </div>
            <div className={'data-card_content'}>{props.children}</div>
          </div>
        </div>
      </section>
    )
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  closeIcon: PropTypes.bool,
  active: PropTypes.bool,
  classes: PropTypes.string,
};

export default Modal;
