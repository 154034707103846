import React, { Fragment } from 'react';
import { Button, DataCard, Export, Table } from '../../../../components';

const UploadedBankStatementTab = ({ data }) => {
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Table>
            <div className="table__body">
              {data &&
                data.application.uploadedBankStatement &&
                data.application.uploadedBankStatement.url !== null && (
                  <ul>
                    <li>
                      <span className="download_button">Bank Statement</span>
                      <span>
                        <Button classes="download_button right">
                          <Export />
                          <span>
                            <a
                              href={data.application.uploadedBankStatement.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              download={data.application.applicationNumber}
                            >
                              {' '}
                              View Bank Statement
                            </a>
                          </span>
                        </Button>
                      </span>
                    </li>
                    {data &&
                      data.application.uploadedBankStatement &&
                      data.application.uploadedBankStatement.password !=
                        null && (
                        <li>
                          <span className="download_button">Password</span>
                          <span className="download_button right-text">
                            {' '}
                            <b>
                              {data.application.uploadedBankStatement.password}
                            </b>
                          </span>
                        </li>
                      )}
                  </ul>
                )}
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default UploadedBankStatementTab;
