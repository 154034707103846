import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ATTRIBUTES } from './queries';
import { CLIENT_INFO_QUERY } from '../Client/queries';
import { clientIdentifier } from '../../Providers/OriginateClient';
import { useClientInfo } from '../Client';
import { useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';

const useKycConfig = () => {
  const sessionToken = localStorage.getItem('api-key');
  const { clientInfo, loading: ciLoading } = useClientInfo({});
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const handleUpdateAttributes = async kycConfiguration => {
    await requestUpdateAttributes({
      variables: {
        input: { accountId: clientInfo.clientId, kycConfiguration },
        token: sessionToken,
      },
      refetchQueries: () => [
        {
          query: CLIENT_INFO_QUERY,
          variables: { [clientIdentifier[0]]: clientIdentifier[1] },
        },
      ],
    });
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const onError = error => {
    let formError = null;

    if (
      error?.message ===
      'Network error: Response not successful: Received status code 400'
    ) {
      formError = 'All fields are required.';
    }

    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(formError || trimGraphQLError(error.message));

    handleHideToast();
  };

  const [requestUpdateAttributes, { loading }] = useMutation(
    UPDATE_ATTRIBUTES,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Updated Kyc Configurations Successfully');
        data.showModal.setValue(false);
        handleHideToast();
      },
      onError,
    },
  );

  return {
    handleUpdateAttributes,
    loading,
    ciLoading,
    kycConfiguration: clientInfo.kycConfiguration,
    data,
    handleHideToast,
  };
};

export { useKycConfig };
