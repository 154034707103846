import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import CloseIcon from '../Icons/CloseIcon';
import WaitIcon from '../Icons/WaitIcon';
import './_Alert.scss';

const Alert = ({ warning, error, message, canClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleHideAlert = () => setIsOpen(false);

  return (
    <Fragment>
      {isOpen && (
        <div className={`alert ${error ? 'error' : warning ? 'warning' : ''}`}>
          <div className="alert__holder">
            <span className="wait-icon">
              <WaitIcon />
            </span>

            <span className="message">{message}</span>

            {canClose && (
              <span className="close-icon">
                <CloseIcon onClick={handleHideAlert} />
              </span>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

Alert.propTypes = {
  message: PropTypes.string,
  clickEvent: PropTypes.func,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  canClose: PropTypes.bool,
};

export default Alert;
