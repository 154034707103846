import { ApolloError } from '@apollo/client';

export const processGraphQLError = (error: ApolloError): string => {
  if (error.networkError) {
    return 'An error occurred while fetching data.';
  } else if (error.graphQLErrors.length) {
    const graphqlError = error.graphQLErrors[0];
    return graphqlError.message ?? 'An error occurred while fetching data.';
  } else {
    return 'An error occurred while fetching data.';
  }
};
