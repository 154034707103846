import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { RULE_SET_ENABLE_MUTATION } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { PRIVATE_CLIENT_INFO_QUERY } from '../Client/queries';
import { clientIdentifier } from '../../Providers/OriginateClient';

const useMutateRuleSetActive = () => {
  const data = {
    showToast: useKeyPair(false),
    showNotificationPopup: useKeyPair(false),
    showDelRuleSetPopup: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    selectedRule: useFormInput(),
  };

  const history = useHistory();

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 5000);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const handleRuleSetEnable = async e => {
    e.preventDefault();

    const selectedRule =
      inputs.selectedRule.value === 'none' ? null : inputs.selectedRule.value;

    await clientRuleSetEnable({
      variables: {
        input: { id: selectedRule.id },
      },
      refetchQueries: () => [
        {
          query: PRIVATE_CLIENT_INFO_QUERY,
          variables: { [clientIdentifier[0]]: clientIdentifier[1] },
        },
      ],
    });
  };

  const handleRuleSetEnableToggle = async (e, selectedRule = null) => {
    e.preventDefault();

    if (selectedRule) inputs.selectedRule.setValue(selectedRule);
    data.showDelRuleSetPopup.setValue(!data.showDelRuleSetPopup.value);
  };

  const [clientRuleSetEnable, { loading: delLoading }] = useMutation(
    RULE_SET_ENABLE_MUTATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Rule Activated');
        data.showDelRuleSetPopup.setValue(false);

        handleHideToast();
        history.push('/settings/loan-rules');
      },
      onError,
    },
  );

  return {
    handleSetActiveToast: handleHideToast,
    delLoading,
    form: inputs,
    setActiveData: data,
    handleRuleSetEnableToggle,
    handleRuleSetEnable,
  };
};

export { useMutateRuleSetActive };
