import React from 'react';
import { Query } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { USER_MANAGEMENT_QUERY } from '../graphql/queries';
import { Loader } from '../components';
import OriginateClient from './OriginateClient';
import { useCustomState } from '../lib/CustomHooks';
import Constants from '../lib/Constants';
import { connection_limit } from '../config/config';

const UserManagementProvider = ({ render }) => {
  const userManagementInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    name: useCustomState(),
    email: useCustomState(),
    createdAtLt: useCustomState(),
    roles: useCustomState(),
    createdAtGt: useCustomState(),
    isSearchPerformed: useCustomState(false),
  };

  const setSearchParams = () => {
    return {
      email: userManagementInput.email.value || undefined,
      roles: userManagementInput.roles.value || undefined,
      name: userManagementInput.name.value
        ? userManagementInput.name.value.trim()
        : undefined,
      createdAtLt: userManagementInput.createdAtLt.value || undefined,
      createdAtGt: userManagementInput.createdAtGt.value || undefined,
      first: undefined,
    };
  };

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    userManagementInput.isSearchPerformed.setValue(true);
    refetch(setSearchParams());
  };

  const handleReset = async (e, refetch) => {
    e.preventDefault();

    userManagementInput.email.setValue('');
    userManagementInput.roles.setValue('');
    userManagementInput.name.setValue('');
    userManagementInput.createdAtLt.setValue('');
    userManagementInput.createdAtGt.setValue('');
    userManagementInput.isSearchPerformed.setValue(false);

    refetch({
      email: undefined,
      roles: undefined,
      name: undefined,
      createdAtLt: undefined,
      createdAtGt: undefined,
    });
  };

  const handleStartDate = e => {
    userManagementInput.createdAtLt.setValue(e);
  };

  const handleEndDate = e => {
    userManagementInput.createdAtGt.setValue(e);
  };

  return (
    <OriginateClient
      render={({ clientId }) => (
        <Query
          query={USER_MANAGEMENT_QUERY}
          variables={{
            clientId: clientId.clientInfo.clientId,
            first: connection_limit.value,
          }}
          notifyOnNetworkStatusChange={true}
          fetchPolicy={'network-only'}
        >
          {({ loading, error, data, userId, refetch }) => {
            if (loading) {
              return <Loader />;
            }

            if (error) {
              userManagementInput.showToast.setValue(true);
              userManagementInput.status.setValue('Failed');
              userManagementInput.content.setValue(
                Constants.errorMessages.NETWORK_ERROR,
              );
            }
            if (data) {
              userId = data.clientAccountUsersXn.nodes.map(item => {
                return item.user.id;
              });
            }

            return render({
              data,
              userManagementInput,
              userId: userId,
              handleReset: e => handleReset(e, refetch),
              runSearchQuery: e => handleRefetch(e, refetch),
              handleStartDate,
              handleEndDate,
            });
          }}
        </Query>
      )}
    />
  );
};

export default withRouter(UserManagementProvider);
