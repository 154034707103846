import React, { useState } from 'react';
import { Button, Input, Select, Table } from '../../components';
import NumberFormat from 'react-number-format';
import { nairaAmount } from '../../lib/Utils';
import { useGetBanks } from '../../Providers-new/Portfolios/useGetBanks';
import moment from 'moment';

const RepaymentForm = ({
  customerData,
  setRepaymentStage,
  makeManualRepayment,
  portfolioId,
}) => {
  const {
    user: { firstName, lastName },
    portfolioNumber,
    amount,
    chargesAmount,
    fullAmount,
    amountPaid,
    transactions,
  } = customerData;

  const lastSuccessfulRepayment = transactions?.nodes
    ?.filter(transaction => transaction.status === 'success')
    .shift();

  const { banks } = useGetBanks();

  const handleSubmitRepayment = e => {
    e.preventDefault();
    makeManualRepayment({ ...formData, portfolioId });
  };

  const [formData, setFormData] = useState({
    amount: '',
    bankId: '',
    accountNumber: '',
    reference: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <form>
      <div>
        <Table>
          <div className="table__body">
            <ul>
              <li>
                <span className="cashrepayment-left-text">Customer Name</span>
                <span className="cashrepayment-right-text">{`${firstName} ${lastName}`}</span>
              </li>
              <li>
                <span className="cashrepayment-left-text">Loan ID</span>
                <span className="cashrepayment-right-text">
                  {portfolioNumber}
                </span>
              </li>
              <li>
                <span className="cashrepayment-left-text">Loan Amount</span>
                <span className="cashrepayment-right-text">
                  {nairaAmount(amount)}
                </span>
              </li>
              <li>
                <span className="cashrepayment-left-text">Interest</span>
                <span className="cashrepayment-right-text">
                  {nairaAmount(chargesAmount)}
                </span>
              </li>
              <li>
                <span className="cashrepayment-left-text">Total Amount</span>
                <span className="cashrepayment-right-text">
                  {nairaAmount(fullAmount)}
                </span>
              </li>
              <li>
                <span className="cashrepayment-left-text">Paid Amount</span>
                <span className="cashrepayment-right-text">
                  {nairaAmount(amountPaid)}
                </span>
              </li>
              <li>
                <span className="cashrepayment-left-text">
                  Outstanding Amount
                </span>
                <span className="cashrepayment-right-text">
                  {nairaAmount(fullAmount - amountPaid)}
                </span>
              </li>
            </ul>
          </div>
        </Table>
      </div>

      <div className="manual-approval__amount__section">
        <div className="payment-input">
          <label htmlFor="payment-amount" className="input_label">
            Payment Amount
          </label>
          <NumberFormat
            id="payment-amount"
            thousandSeparator={true}
            allowLeadingZeros={false}
            className="input"
            placeholder="Amount"
            allowNegative={false}
            name="amount"
            onChange={handleChange}
          />
          {lastSuccessfulRepayment && (
            <li className="list-style-none">
              <span>Last Repayment: </span>
              <span className="">
                {nairaAmount(lastSuccessfulRepayment?.amount)}{' '}
              </span>
              <span className="">
                on{' '}
                {moment(transactions?.nodes[0]?.createdAt).format('ddd, LLL')}{' '}
              </span>
            </li>
          )}
        </div>

        <Select
          defaultValue=""
          label={'Receiving Bank'}
          onChange={handleChange}
          name="bankId"
        >
          <option disabled value="">
            -- Select a bank --
          </option>
          {banks &&
            banks.map((bank, index) => (
              <option key={index} value={bank.id}>
                {bank.name}
              </option>
            ))}
        </Select>
        <Input
          type="tel"
          classes="account-number"
          label="Account Number"
          placeholder="Account Number"
          maxLength={10}
          minLength={10}
          numberField
          allowZeros
          onChange={handleChange}
          name="accountNumber"
        />
        <Input
          type="text"
          label="Reference"
          placeholder="Reference"
          onChange={handleChange}
          name="reference"
        />

        <div className="v2-modal-button-wrapper">
          <Button
            classes="primary flex-grow"
            click_event={() => setRepaymentStage(prev => prev - Number(1))}
          >
            BACK
          </Button>

          <Button
            classes="secondary flex-grow"
            disabled={
              !formData?.amount ||
              !formData?.bankId ||
              !(formData?.accountNumber.length === Number(10)) ||
              !(formData?.reference.length > Number(3))
            }
            click_event={handleSubmitRepayment}
          >
            MAKE REPAYMENT
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RepaymentForm;
