import { gql } from 'apollo-boost';

export const GET_PRESIGNED_URL_FROM_S3_QUERY = gql`
  query GetPresignedUrl($bucket: String!, $key: String!, $url: URL) {
    getPresignedUrlFromS3(bucket: $bucket, key: $key, url: $url) {
      dataUrl
      status
    }
  }
`;
