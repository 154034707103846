import React, { Fragment } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import cx from 'classnames';

import {
  DataCard,
  Card,
  Button,
  CheckBox,
  CheckBoxGroup,
  Input,
  BreadCrumbSection,
  Toast,
  Notification,
  Loader,
  Modal,
  Close,
} from '../../components';
import { CompleteIcon } from '../../components/Icons';

import {
  useMutateClientCustomRole,
  useRolesAndPermissions,
} from '../../Providers-new/UserManagement';
import { useClientInfo } from '../../Providers-new/Client';

import './_UserManagement.scss';

const Roles = ({ roles, permissions, clientInfo }) => {
  const {
    data,
    form,
    handleHideToast,
    handleOnClickRole,
    handleCustomRoleDelete,
    handleCustomRoleUpdate,
    handleDeleteToggle,
    handleUpdateToggle,
    handleFormSubmission,
    permissionInputs,
  } = useMutateClientCustomRole({
    roles,
    permissions,
    clientId: clientInfo.clientId,
  });

  const isCustomRole = data.roleInView.value.isCustom;

  const showRoleColItems = () => {
    if (roles && roles.length > 0) {
      return roles.map((item, index) => (
        <div
          key={`${index}-${item.name}`}
          onClick={() => handleOnClickRole(item.id)}
          className={cx({
            'items ': item.id,
            is_selected: item.id === data.roleInView.value.id,
          })}
        >
          {item.name}
        </div>
      ));
    }

    return <div className={'items'} />;
  };

  return (
    <>
      <BreadCrumbSection>
        <NavLink
          to="/settings/roles/create-new-role"
          className="button secondary"
        >
          Create New Role
        </NavLink>
      </BreadCrumbSection>
      <Fragment>
        {data.showNotificationPopup.value && (
          <Notification title="Success">
            <div className="notification_icon-holder">
              <CompleteIcon />
            </div>
            <h5 className={'center-text'}>
              {data.isEditMode.value
                ? 'User Information Now Updated'
                : 'User Created Successfully'}
            </h5>
          </Notification>
        )}

        {data.showConfirmedDeleteNotification.value && (
          <Notification title="Success">
            <div className="notification_icon-holder">
              <CompleteIcon />
            </div>
            <h5 className={'center-text'}>Role Deleted Successfully</h5>
          </Notification>
        )}

        {data.showConfirmedUpdateNotification.value && (
          <Notification title="Success">
            <div className="notification_icon-holder">
              <CompleteIcon />
            </div>
            <h5 className={'center-text'}>Role Updated Successfully</h5>
          </Notification>
        )}

        {/* popup to handle deletion of selected role */}
        <Fragment>
          <Toast
            title="Delete Role"
            content={data.content.value}
            classes={data.status.value}
            active={data.showToast.value}
            clickEvent={handleHideToast}
          />
          <Modal
            title={'Delete Role'}
            active={data.showDeleteNotificationPopup.value}
          >
            <Close
              className="close-icon"
              onClick={e => handleDeleteToggle(e)}
            />
            <div className="content center">
              Are you sure you want to delete this Role?
            </div>
            <div className="actions">
              <div className="flex-container center">
                <Button
                  classes="secondary"
                  click_event={handleCustomRoleDelete}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal>
        </Fragment>

        {/* popup to handle edit of selected role */}
        <Fragment>
          <Toast
            title="Update Role"
            content={data.content.value}
            classes={data.status.value}
            active={data.showToast.value}
            clickEvent={handleHideToast}
          />
          <Modal
            title={'Update Role'}
            active={data.showUpdateNotificationPopup.value}
          >
            <Close className="close-icon" onClick={handleUpdateToggle} />
            <div className="content center">
              Are you sure you want to update this Role?
            </div>
            <div className="actions">
              <div className="flex-container center">
                <Button
                  classes="secondary"
                  click_event={handleCustomRoleUpdate}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal>
        </Fragment>

        <form onSubmit={handleFormSubmission}>
          <div className="form-card top-border role-listing">
            <aside className={'role-item-col'}>
              <div className="roles-holder">{showRoleColItems()}</div>
            </aside>

            <div className={'role-info'}>
              <DataCard>
                <div className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Basic Information">
                      Enter the name of the role
                    </Card>
                  </div>

                  <div className="form-fields">
                    <div className="flex-container">
                      <div className="form-fields">
                        <Card title="Name of the Role">
                          <Input
                            placeholder="Role Name"
                            required
                            readOnly={!isCustomRole}
                            type="text"
                            {...form.roleName.input}
                          />
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </DataCard>

              <DataCard>
                <div className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Privileges">What they can do</Card>
                  </div>

                  <div className="form-fields">
                    {data.viewPermissions.value &&
                      data.viewPermissions.value.length > 1 && (
                        <Card title="View Permissions">
                          <CheckBoxGroup>
                            {data.viewPermissions.value &&
                              data.viewPermissions.value.map(item => {
                                return (
                                  <label className={'default'} key={item.name}>
                                    {isCustomRole ? (
                                      <CheckBox
                                        classes="privileges_checkbox"
                                        label={item.description}
                                        id={item.id}
                                        value={item.name}
                                        {...permissionInputs[item.id].input}
                                      />
                                    ) : (
                                      <CheckBox
                                        classes="readonly-chk"
                                        label={item.description}
                                        id={item.id}
                                        value={item.name}
                                        disabled
                                        {...permissionInputs[item.id].input}
                                      />
                                    )}
                                  </label>
                                );
                              })}
                          </CheckBoxGroup>
                        </Card>
                      )}
                    {data.editPermissions.value &&
                      data.editPermissions.value.length >= 1 && (
                        <Card title="Edit Permissions">
                          <CheckBoxGroup>
                            {data.editPermissions.value.map(item => {
                              return (
                                <label className={'default'} key={item.name}>
                                  {isCustomRole ? (
                                    <CheckBox
                                      classes="privileges_checkbox"
                                      label={item.description}
                                      id={item.id}
                                      value={item.name}
                                      {...permissionInputs[item.id].input}
                                    />
                                  ) : (
                                    <CheckBox
                                      classes="readonly-chk"
                                      label={item.description}
                                      id={item.id}
                                      value={item.name}
                                      disabled
                                      {...permissionInputs[item.id].input}
                                    />
                                  )}
                                </label>
                              );
                            })}
                          </CheckBoxGroup>
                        </Card>
                      )}
                  </div>
                </div>
              </DataCard>

              <div className="flex-container center">
                {isCustomRole ? (
                  <Fragment>
                    <Button
                      classes="primary space-right"
                      type="submit"
                      click_event={handleUpdateToggle}
                    >
                      Update
                    </Button>
                    <Button
                      classes="primary secondary"
                      type="submit"
                      click_event={handleDeleteToggle}
                    >
                      Delete
                    </Button>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    </>
  );
};

const RoleWrapper = () => {
  const {
    loading,
    roles: qRoles,
    permissions: qPermissions,
  } = useRolesAndPermissions();
  const { loading: ciLoading, result } = useClientInfo();

  if (loading || ciLoading) return <Loader />;
  return (
    <Roles
      roles={qRoles}
      permissions={qPermissions}
      clientInfo={result.data.clientInfo}
    />
  );
};

export default withRouter(RoleWrapper);
