import { gql } from 'apollo-boost';

export const MP_APPLICATION_VIEW_QUERY = gql`
  query MP_ApplicationsQuery(
    $cursor: ConnectionCursor
    $first: ConnectionLimitInt
    $id: String
    $email: String
    $name: String
    $status: String
    $amountLt: Float
    $amountGt: Float
    $repaymentAmountLt: Float
    $repaymentAmountGt: Float
    $applicationDateLt: DateTime
    $applicationDateGt: DateTime
    $repaymentDateLt: DateTime
    $repaymentDateGt: DateTime
    $approvalWorkflow: MpApplicationWorkflowWhereInput
    $productId: ID
    $loanCategoryId: ID
  ) {
    mp {
      applications(
        first: $first
        after: $cursor
        orderBy: createdAt_DESC
        where: {
          applicationNumber: $id
          status: $status
          amount_lte: $amountGt
          amount_gte: $amountLt
          fullAmount_lte: $repaymentAmountGt
          fullAmount_gte: $repaymentAmountLt
          createdAt_lt: $applicationDateLt
          createdAt_gte: $applicationDateGt
          dateOfRepayment_lt: $repaymentDateLt
          dateOfRepayment_gte: $repaymentDateGt
          customer: { email_contains: $email, fullName_contains: $name }
          approvalWorkflow: $approvalWorkflow
          product: { id: $productId }
          loanCategory: { id: $loanCategoryId }
        }
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        nodes {
          id
          applicationId
          status {
            name
          }
          email
          fullName
          requestedAmount
          showCheckbox
          repaymentAmount {
            isBadge
            level
            value
          }
          requestedDate
          submittedAt
          policy {
            id
            name
          }
          approvalWorkflow {
            nextStep {
              approvalType
              roles {
                name
              }
            }
            workflow {
              id
              name
            }
          }
          loanCategory {
            id
            name
          }
        }
        edges {
          cursor
        }
      }
    }
  }
`;

export const ADD_APPLICATION_COMMENT_MUTATION = gql`
  mutation MP_AddApplicationComment($applicationId: ID!, $comment: String!) {
    addApplicationComment(
      input: { applicationId: $applicationId, comment: $comment }
    ) {
      success
      application {
        id
        history {
          id
          newStatus {
            name
          }
          comment
          createdAt
          actionBy {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
