import { useMutation } from '@apollo/react-hooks';
import { UPDATE_REPAYMENT_DUE_DATE } from './queries';
import { useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { useState } from 'react';
import addMonths from 'date-fns/addMonths';

const useMutateUpdateRepaymentDueDate = ({ repaymentId, salaryDay }) => {
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const [startDate, setStartDate] = useState(null);

  const [repayDate, updateRepayDate] = useState({ selectedDate: '' });

  const handleRepayDate = value => {
    updateRepayDate({ selectedDate: value });
  };

  if (repayDate.selectedDate.value === 'no' && startDate) {
    setStartDate('');
  }

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3000);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
    handleModalToggle();
  };

  const handleUpdateRepaymentDueDate = async e => {
    e.preventDefault();

    let today = new Date();

    let newSalaryDate = new Date();
    newSalaryDate.setDate(salaryDay);

    if (!repayDate.selectedDate.value) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please select an option');

      handleHideToast();
      return;
    }

    if (repayDate.selectedDate.value === 'yes' && startDate === null) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please select a repayment date');

      handleHideToast();
      return;
    }

    if (newSalaryDate < today) {
      newSalaryDate = addMonths(newSalaryDate, 1);
    }

    await updateRepaymentDueDate({
      variables: {
        input: {
          repaymentId: repaymentId,
          newDate:
            repayDate.selectedDate.value === 'yes' || salaryDay === 0
              ? startDate
              : newSalaryDate,
        },
      },
    });
  };

  const handleModalToggle = () => {
    data.showModal.setValue(!data.showModal.value);
  };

  const [updateRepaymentDueDate, { loading, error }] = useMutation(
    UPDATE_REPAYMENT_DUE_DATE,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Repayment Date Updated');
        data.showModal.setValue(false);

        handleHideToast();
      },
      onError,
    },
  );

  return {
    handleHideToast,
    loading,
    handleModalToggle,
    error,
    data,
    handleUpdateRepaymentDueDate,
    startDate,
    setStartDate,
    repayDate,
    updateRepayDate,
    handleRepayDate,
  };
};

export { useMutateUpdateRepaymentDueDate };
