import React, { Fragment } from 'react';
import {
  Button,
  Input,
  Loader,
  Modal,
  Notification,
  Plus,
  TextArea,
  Toast,
} from '../../../../components';
import { Close, CloseButton, CompleteIcon } from '../../../../components/Icons';

const RequestSupportingDoc = ({
  requestSupportingDocData,
  handleModalToggle,
  requestSupportingDocLoader,
  requestSupportingDocInput,
  handleRequestSupportingDocument,
  handleHideToast,
  addNewStep,
  stepState,
  handleStepChange,
  removeStep,
}) => {
  return (
    <Fragment>
      {requestSupportingDocLoader ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          <Toast
            title="Request Supporting Document"
            content={requestSupportingDocData.content.value}
            clickEvent={handleHideToast}
            active={requestSupportingDocData.showToast.value}
            classes={requestSupportingDocData.status.value}
          />
          {requestSupportingDocData.showNotificationPopup.value && (
            <Notification>
              <div className="notification_icon-holder">
                <CompleteIcon />
              </div>
              <h5 className={'center-text'}>
                {
                  'The customer has been notified of your request for new documents'
                }
              </h5>
            </Notification>
          )}
          <Modal
            title="Request for new document"
            active={requestSupportingDocData.showModal.value}
            classes="request-supp-doc-modal"
          >
            <Close className="close-icon" onClick={handleModalToggle} />

            <form onSubmit={handleRequestSupportingDocument}>
              {stepState.length < 1 && (
                <div className={'required center-text'}>
                  It's required to add a document
                </div>
              )}
              {stepState.length >= 1 &&
                stepState.map((val, idx) => {
                  const titleId = `title-${idx}`;

                  return (
                    <div className="document-card" key={idx}>
                      <Input
                        label={`Document Title ${idx + 1}`}
                        name={titleId}
                        data-idx={idx}
                        data-set="title"
                        id={titleId}
                        required
                        value={stepState[idx].title}
                        onChange={handleStepChange}
                      />
                      <div className="close-btn">
                        <CloseButton onClick={() => removeStep(idx)} />
                      </div>
                    </div>
                  );
                })}
              <div className="add-new-doc" onClick={addNewStep}>
                <Plus />
                <span>Add New Document</span>
              </div>
              <TextArea
                label="Message:"
                required
                rows={5}
                {...requestSupportingDocInput.message.input}
              />

              <Button type="submit" classes="secondary center send-req">
                Send Request
              </Button>
            </form>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default RequestSupportingDoc;
