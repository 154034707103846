import { gql } from 'apollo-boost';

export const GET_APPLICATION_WORKFLOW_SUMMARY = gql`
  query applicationWorkflowSummary {
    applicationWorkflowSummary {
      currentStep
      nextStep
      PreviousStep
    }
  }
`;
