import React from 'react';
import { Query } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { CUSTOMER_PORTFOLIOS_QUERY } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import Constants from '../lib/Constants';
import { removeCommas, normalizeDateTime } from '../lib/Utils';
import { Loader } from '../components';
import { connection_limit } from '../config/config';

const Loans = ({ render }) => {
  const loanInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    searchParams: {
      id: useCustomState(),
      status: useCustomState(),
      email: useCustomState(),
      name: useCustomState(),
      amountLt: useCustomState(),
      amountGt: useCustomState(),
      repaymentAmountLt: useCustomState(),
      repaymentAmountGt: useCustomState(),
      loanDateLt: useCustomState(),
      loanDateGt: useCustomState(),
      repaymentDateLt: useCustomState(),
      repaymentDateGt: useCustomState(),
      isSearchPerformed: useCustomState(false),
    },
  };

  const setSearchParams = searchParams => {
    const {
      id,
      status,
      email,
      name,
      amountLt,
      amountGt,
      repaymentAmountLt,
      repaymentAmountGt,
      loanDateLt,
      loanDateGt,
      repaymentDateLt,
      repaymentDateGt,
    } = searchParams;

    return {
      id: id.value ? id.value : undefined,
      status: status.value ? status.value : undefined,
      email: email.value ? email.value : undefined,
      name: name.value ? name.value : undefined,
      amountLt: amountLt.value
        ? parseFloat(removeCommas(amountLt.value))
        : undefined,
      amountGt: amountGt.value
        ? parseFloat(removeCommas(amountGt.value))
        : undefined,
      repaymentAmountLt: repaymentAmountLt.value
        ? parseFloat(removeCommas(repaymentAmountLt.value))
        : undefined,
      repaymentAmountGt: repaymentAmountGt.value
        ? parseFloat(removeCommas(repaymentAmountGt.value))
        : undefined,
      loanDateLt: loanDateLt.value || undefined,
      loanDateGt: loanDateGt.value || undefined,
      repaymentDateLt: repaymentDateLt.value || undefined,
      repaymentDateGt: repaymentDateGt.value || undefined,
      first: connection_limit.value,
    };
  };

  let isVisible;

  const handleLoanStartDate = e => {
    loanInput.searchParams.loanDateGt.setValue(normalizeDateTime(e));
  };

  const handleLoanEndDate = e => {
    loanInput.searchParams.loanDateLt.setValue(normalizeDateTime(e, true));
  };

  const handleRepaymentStartDate = e => {
    loanInput.searchParams.repaymentDateGt.setValue(normalizeDateTime(e));
  };

  const handleRepaymentEndDate = e => {
    loanInput.searchParams.repaymentDateLt.setValue(normalizeDateTime(e, true));
  };

  return (
    <Query
      query={CUSTOMER_PORTFOLIOS_QUERY}
      variables={{ first: connection_limit.value, skipAccountAttributes: true }}
      notifyOnNetworkStatusChange={true}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />;

        if (error) {
          loanInput.showToast.setValue(true);
          loanInput.status.setValue('Failed');
          loanInput.content.setValue(Constants.errorMessages.NETWORK_ERROR);
        }

        if (data) {
          isVisible = localStorage.getItem('slug') === 'heliumhealth';
        }

        return render({
          loanData: data,
          loanInput,
          isVisible,
          handleLoanStartDate,
          handleLoanEndDate,
          handleRepaymentStartDate,
          handleRepaymentEndDate,
          setFilterParams: setSearchParams,
        });
      }}
    </Query>
  );
};

export default withRouter(Loans);
