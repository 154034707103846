import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { UPDATE_APPROVAL_WORKFLOW } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useClientInfo, queries } from '../Providers-new/Client';
import { clientIdentifier } from './OriginateClient';

const { PRIVATE_CLIENT_INFO_QUERY } = queries;

const ApprovalWorkflow = ({ render }) => {
  const { clientInfo, loading: ciLoading } = useClientInfo({
    privateFields: true,
  });

  const { clientId, approvalWorkflow } = clientInfo;

  const approvalWorkflowInput = {
    value: useCustomState(approvalWorkflow),
    showToast: useCustomState(false),
    content: useCustomState(),
  };

  const setApprovalValue = e => {
    approvalWorkflowInput.value.setValue(e.target.value);
  };

  const handleHideToast = () => {
    setTimeout(() => {
      approvalWorkflowInput.showToast.setValue(false);
    }, 2500);
  };

  const handleWorkflowUpdate = async (e, handleWorkflowUpdateMutation) => {
    e.preventDefault();

    if (!approvalWorkflowInput.value.input.value) {
      approvalWorkflowInput.showToast.setValue(true);
      approvalWorkflowInput.content.setValue('Kindly select a value');

      handleHideToast(e);
      return;
    }

    await handleWorkflowUpdateMutation({
      variables: {
        clientId,
        value: approvalWorkflowInput.value.input.value,
      },
    });
  };

  const [handleWorkflowUpdateMutation, { loading, error }] = useMutation(
    UPDATE_APPROVAL_WORKFLOW,
    {
      async onCompleted() {
        approvalWorkflowInput.showToast.setValue(true);
        approvalWorkflowInput.content.setValue(
          'Workflow Updated Successfully.',
        );

        handleHideToast();
      },

      onError(error) {
        approvalWorkflowInput.showToast.setValue(true);
        approvalWorkflowInput.content.setValue(trimGraphQLError(error.message));
        handleHideToast();
      },
      update: cache => {
        const variables = { [clientIdentifier[0]]: clientIdentifier[1] };

        const { clientInfo: oldCI } = cache.readQuery({
          query: PRIVATE_CLIENT_INFO_QUERY,
          variables,
        });

        const clientInfo = {
          ...oldCI,
          approvalWorkflow: approvalWorkflowInput.value.input.value,
        };

        cache.writeQuery({
          query: PRIVATE_CLIENT_INFO_QUERY,
          data: { clientInfo },
          variables,
        });
      },
    },
  );

  return render({
    loading: loading || ciLoading,
    error,
    handleWorkflowUpdate: e =>
      handleWorkflowUpdate(e, handleWorkflowUpdateMutation),
    approvalWorkflowInput,
    handleHideToast,
    setApprovalValue: e => setApprovalValue(e),
    clientApprovalWorkflow: approvalWorkflow,
  });
};

export default withRouter(ApprovalWorkflow);
