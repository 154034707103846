import {
  Button,
  ButtonProps,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { Link as ReactLink } from 'react-router-dom-v6';
import { CreateEditActionType } from '../../types';

interface LoanProductStepperItemProps extends ButtonProps {
  url: string;
  label: string;
  icon?: FunctionComponent;
  actionType: CreateEditActionType;
  shouldHighlight: boolean;
}

const LoanProductStepperItem = ({
  url,
  icon,
  label,
  actionType,
  shouldHighlight,
  ...props
}: LoanProductStepperItemProps) => {
  return (
    <Button
      to={actionType === 'edit' ? url : null}
      flex={1}
      outline="none"
      spacing={4}
      display="flex"
      as={actionType === 'edit' ? ReactLink : null}
      variant="unstyled"
      color="gray.700"
      h="auto"
      _hover={{
        display: 'flex',
        color: 'gray.700',
        textDecoration: 'none',
        borderColor: 'blue.200',
      }}
      {...props}
    >
      <HStack spacing={4} flex={1}>
        <Flex
          h={14}
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          transitionDuration="slow"
          w={{ base: '100%', md: 14 }}
          bg={shouldHighlight ? 'blue.500' : 'blue.50'}
        >
          <Icon
            w={6}
            h={6}
            as={icon}
            stroke={shouldHighlight ? 'white' : 'gray.500'}
            transitionDuration="slow"
          />
        </Flex>

        <VStack
          display={{ base: 'none', md: 'flex' }}
          alignItems="flex-start"
          spacing={0.5}
        >
          <Text fontWeight="medium" fontSize="sm" color="gray.500">
            Step
          </Text>

          <Text
            display={{ base: 'none', lg: 'block' }}
            fontWeight="semibold"
            color="gray.600"
          >
            {label}
          </Text>
        </VStack>
      </HStack>
    </Button>
  );
};

export default LoanProductStepperItem;
