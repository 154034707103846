import React from 'react';

const Export = props => (
  <svg width={16} height={16} fill="none" {...props}>
    <g
      clipPath="url(#prefix__clip0)"
      stroke="#07F"
      strokeWidth={0.9}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15.059 10v2.667c0 .353-.151.692-.42.943-.27.25-.634.39-1.014.39H3.585c-.38 0-.745-.14-1.014-.39s-.42-.59-.42-.943V10M5.02 6.667L8.605 10l3.585-3.333M8.605 10V2" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h15.976v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Export;
