import React, { Fragment } from 'react';
import {
  Button,
  DataCard,
  EmptyScreen,
  Input,
  Loader,
  Select,
} from '../../../../components';
import {
  removeUnderscore,
  reduceStringLength,
  capitalizeInitialLetter,
} from '../../../../lib/Utils';
import moment from 'moment';
import { AddApplicationComment } from '../index';

const ApplicationHistoryTab = ({
  data,
  showChangeRuleBreakDownPopup,
  applicationHistoryPagination,
  applicationHistoryLoading,
  runSearchQuery,
  applicationHistoryInput,
  applicationStatuses,
}) => {
  return (
    <Fragment>
      <div className="history-timeline">
        <DataCard noPadding={false}>
          <div className="loan-history-search">
            <Input
              label="Search by Comment"
              placeholder="Comment"
              {...applicationHistoryInput.searchParams.commentLike.input}
            />

            <Select
              label="Search by Old Application Status"
              {...applicationHistoryInput.searchParams.oldApplicationStatus
                .input}
            >
              <option value="">All</option>
              {applicationStatuses.map((status, index) => (
                <option key={index} value={status.name}>
                  {removeUnderscore(capitalizeInitialLetter(status.name))}
                </option>
              ))}
            </Select>

            <Select
              label="Search by New Application Status"
              {...applicationHistoryInput.searchParams.newApplicationStatus
                .input}
            >
              <option value="">All</option>
              {applicationStatuses.map((status, index) => (
                <option key={index} value={status.name}>
                  {removeUnderscore(capitalizeInitialLetter(status.name))}
                </option>
              ))}
            </Select>
            <Button click_event={runSearchQuery}>Search</Button>
          </div>
          <div className="timeline">
            <span>
              <Button classes="link" click_event={showChangeRuleBreakDownPopup}>
                View Rule Breakdown
              </Button>
            </span>
            <div className="timeline-body">
              <div className="timeline-item">
                <ul>
                  <li>
                    <AddApplicationComment data={data} />
                  </li>
                  {data?.application?.histories?.nodes?.length ? (
                    data?.application?.histories?.nodes?.map(
                      (historyData, key) => (
                        <li key={key}>
                          <div className="timeline-content">
                            <p>
                              {historyData?.comment
                                ? reduceStringLength(historyData.comment)
                                : 'N/A'}{' '}
                            </p>
                            <div className="history_info">
                              {historyData.createdAt ? (
                                <p className="info">
                                  <span className="title">Date:</span>
                                  {moment(historyData.createdAt).format('lll')}
                                </p>
                              ) : (
                                ''
                              )}

                              <p className="info">
                                <span className="title">Action by:</span>
                                {historyData?.actionBy?.firstName ||
                                historyData?.actionBy?.lastName
                                  ? `${historyData?.actionBy?.firstName} ${historyData?.actionBy?.lastName}`
                                  : 'System'}
                              </p>

                              <p className="info">
                                <span className="title">Status:</span>
                                {historyData?.newStatus?.name
                                  ? removeUnderscore(historyData.newStatus.name)
                                  : 'PENDING'}
                              </p>
                            </div>
                          </div>
                        </li>
                      ),
                    )
                  ) : (
                    <DataCard>
                      <EmptyScreen message="No Application History" />
                    </DataCard>
                  )}
                </ul>
              </div>
            </div>

            {data?.application?.histories?.pageInfo?.endCursor &&
              (applicationHistoryLoading ? (
                <Loader />
              ) : (
                <Button
                  classes="center"
                  click_event={applicationHistoryPagination}
                >
                  Load More
                </Button>
              ))}
          </div>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default ApplicationHistoryTab;
