import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './_HeaderProfile.scss';
import { DropDown, CaretDown } from '../../../components';
import { LogoutIcon } from '../../Icons';

const HeaderProfile = () => {
  const dropdownItems = [
    { text: 'Logout', link: '/logout', icon: <LogoutIcon /> },
  ];

  return (
    <div className="header-profile">
      <DropDown
        dropdownTrigger={
          <div className="header-profile__name">
            {localStorage.getItem('firstName') != null ? (
              <span>
                {localStorage.getItem('lastName')}{' '}
                {localStorage.getItem('firstName')}
              </span>
            ) : (
              <span>Welcome User</span>
            )}
            <span className="icon">
              <CaretDown />
            </span>
          </div>
        }
        dropdownContent={
          <div className={'header-profile__dropdown'}>
            {dropdownItems.map((item, key) => (
              <Link key={key} to={item.link} data-testid="logout-button">
                {item.icon ? item.icon : ''}
                <span>{item.text}</span>
              </Link>
            ))}
          </div>
        }
      />
    </div>
  );
};

export default withRouter(HeaderProfile);
