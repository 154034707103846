import React from 'react';

const ExternalIcon = ({ fill = 'currentColor', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      fill="fill"
    >
      <path
        fillRule="evenodd"
        d="M4.833 10.034a.5.5 0 00.936.257l.601-1.066a3.5 3.5 0 013.57-1.741l.1.015c.017.452.043.903.075 1.355l.046.62c.036.505.599.787 1.025.514a13.088 13.088 0 003.583-3.393l.304-.423a.5.5 0 000-.583l-.304-.424a13.088 13.088 0 00-3.583-3.392.668.668 0 00-1.025.514l-.046.62c-.026.364-.048.728-.065 1.092h-.678a4.5 4.5 0 00-4.499 4.392l-.04 1.643zm5.255-3.54a4.5 4.5 0 00-4.2 1.66 3.5 3.5 0 013.484-3.155h1.159a.5.5 0 00.5-.483c.017-.512.044-1.024.082-1.536l.003-.05a12.088 12.088 0 012.84 2.819l.095.131-.095.132a12.088 12.088 0 01-2.84 2.819l-.003-.05a39.801 39.801 0 01-.089-1.73.5.5 0 00-.425-.48l-.51-.076z"
        clipRule="evenodd"
      />
      <path d="M13.094 11.607a29.64 29.64 0 00.167-2.021.271.271 0 01.091-.19c.242-.214.477-.436.704-.666.085-.087.234-.026.232.095-.021.968-.088 1.936-.2 2.899a2.927 2.927 0 01-2.582 2.555c-2.312.258-4.7.258-7.012 0a2.927 2.927 0 01-2.581-2.555 30.614 30.614 0 010-7.113 2.927 2.927 0 012.581-2.555 31.78 31.78 0 014.64-.174c.13.005.22.133.204.262a1.576 1.576 0 00-.008.082l-.035.47a.201.201 0 01-.207.185 30.781 30.781 0 00-4.482.169c-.887.099-1.597.799-1.7 1.677a29.614 29.614 0 000 6.88 1.927 1.927 0 001.7 1.678c2.237.25 4.551.25 6.788 0a1.927 1.927 0 001.7-1.678z" />
    </svg>
  );
};

export default ExternalIcon;
