import React, { Fragment } from 'react';
import { Button, DataCard, Export, Table } from '../../../../components';

const CreditBureauScoreReportTab = ({
  data,
  handleExportToCsvCreditBureauScoreReport,
}) => {
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Button
            classes="right"
            click_event={handleExportToCsvCreditBureauScoreReport}
          >
            <Export />
            <span className="export-csv">Export CSV</span>
          </Button>
          <Table>
            <div className="table__body">
              <ul>
                <li>
                  <span>Number Of Accounts In Bad Standing</span>
                  <span className="right-text">
                    {data.application.credit.creditBureauScoreReport.result
                      .numberOfAccountsInBadStanding
                      ? data.application.credit.creditBureauScoreReport.result
                          .numberOfAccountsInBadStanding
                      : 0}
                  </span>
                </li>
              </ul>
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default CreditBureauScoreReportTab;
