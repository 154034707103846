import React, { Fragment } from 'react';
import { DataCard, Table } from '../../../../components';

const DetectedAccountDetailsTab = ({ detectedAccountDetails }) => {
  const { accountName, accountNumber } = detectedAccountDetails || {};
  const accountNameDisplay = accountName
    ? `${accountName.value.toUpperCase()} — ${
        accountName.confidence * 100
      }% accuracy`
    : '__';

  const accountNumberDisplay = accountNumber
    ? `${accountNumber.value} — ${accountNumber.confidence * 100}% accuracy`
    : '__';

  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Table>
            <div className="table__body">
              {detectedAccountDetails &&
              detectedAccountDetails != null &&
              Object.keys(detectedAccountDetails).length ? (
                <ul>
                  <li>
                    <span className="decide-analysis-span">
                      Account Details
                    </span>
                  </li>
                  <li>
                    <span>Account Name</span>
                    <span className="right-text">{accountNameDisplay}</span>
                  </li>
                  <li>
                    <span>Account Number</span>
                    <span className="right-text">{accountNumberDisplay}</span>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default DetectedAccountDetailsTab;
