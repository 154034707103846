import { Icon, IconProps } from '@chakra-ui/icon';
import React from 'react';

const SummitIcon = ({ ...props }: IconProps) => {
  return (
    <Icon
      w={6}
      h={6}
      fill="none"
      stroke="gray.500"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 14l4 6h6L12 4 3 20h6l4-6"
      />
    </Icon>
  );
};

export default SummitIcon;
