import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format-5';

interface InputProps extends NumericFormatProps {
  label?: ReactNode;
  isLoading?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isReadOnly?: boolean;
  helperText?: ReactNode;
  isAmountInput?: boolean;
  isPercentInput?: boolean;
}

const NumericInput = forwardRef(
  (
    {
      id,
      name,
      label,
      isLoading,
      isInvalid,
      isDisabled,
      isRequired,
      isReadOnly,
      helperText,
      errorMessage,
      isAmountInput,
      isPercentInput,
      ...restProps
    }: InputProps,
    ref,
  ) => {
    return (
      <FormControl
        id={id || name}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        isInvalid={!!isInvalid || !!errorMessage}
      >
        <FormLabel>{label}</FormLabel>

        <Skeleton isLoaded={!isLoading}>
          <InputGroup>
            {(isAmountInput || isPercentInput) && (
              <InputLeftElement
                pointerEvents="none"
                color="gray.400"
                fontWeight="semibold"
              >
                <Text
                  flex={1}
                  textAlign="center"
                  borderRight="1px solid"
                  borderColor="gray.300"
                >
                  {isPercentInput && '%'}
                  {isAmountInput && '₦'}
                </Text>
              </InputLeftElement>
            )}

            <NumericFormat
              ref={ref}
              borderRadius={0}
              thousandSeparator
              allowNegative={false}
              allowLeadingZeros={false}
              pl={isAmountInput || isPercentInput ? 12 : null}
              isInvalid={!!isInvalid || !!errorMessage}
              {...restProps}
              customInput={ChakraInput}
            />
          </InputGroup>
        </Skeleton>

        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);

export default NumericInput;
