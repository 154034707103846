import React, { Fragment } from 'react';
import { Button, DataCard, Export, Table } from '../../../../components';
import {
  numberWithCommas,
  roundDoublePrecisionWithCommas,
} from '../../../../lib/Utils';

const headers = ['Principal Amount', 'Tenor (in months)', 'DSR'];

const cashflow_header = [
  'Gross Expense',
  'Gross Inflow',
  'Weighted Expense',
  'Weighted Inflow',
];

const ModelResponseTab = ({
  handleExportToCsvModelResponse,
  modelResults,
  offers,
  cashflows,
  estimations,
  reasonCodes,
  risk,
}) => {
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Button classes="right" click_event={handleExportToCsvModelResponse}>
            <Export />
            <span className="export-csv">Export Full Response</span>
          </Button>
          <Table>
            <div className="modelResCont">
              <ul>
                {modelResults.map((modelResultsData, idx) => (
                  <li key={idx}>
                    <div className="modelRiskRes">
                      <span>Risk</span>
                      <span>{risk ? risk : 'Not Available'}</span>
                    </div>
                    <div className="modelResData">
                      <table className="modelResData__table">
                        <h4>{offers.length ? 'Offers' : ''}</h4>
                        <tbody>
                          <tr>
                            <td></td>
                            {offers &&
                              offers.length !== 0 &&
                              headers.map((header, idx) => (
                                <th key={idx}>{header}</th>
                              ))}
                          </tr>

                          {offers &&
                            offers.length !== 0 &&
                            offers.map(offerArr =>
                              offerArr.map((offer, idx) => (
                                <tr key={idx}>
                                  <td colSpan="1">
                                    {roundDoublePrecisionWithCommas(
                                      offer.offer,
                                    )}
                                  </td>
                                  <td>{numberWithCommas(offer.principal)}</td>
                                  <td>{offer.mTenor}</td>
                                  <td>
                                    {offer.dsr === 'N/A'
                                      ? 'N/A'
                                      : `${offer.dsr}%`}
                                  </td>
                                </tr>
                              )),
                            )}
                        </tbody>
                      </table>
                    </div>
                    {reasonCodes && reasonCodes.length !== 0 && (
                      <Fragment>
                        <h4>Reason Code</h4>
                        <div className="modelRiskRes">
                          <span>Reason Code</span>
                          <span>{reasonCodes}</span>
                        </div>
                      </Fragment>
                    )}
                    <div className="modelResData">
                      <table className="modelResData__table">
                        <h4>{cashflows.length ? 'Cashflow' : ''}</h4>
                        <tbody>
                          <tr>
                            <td></td>
                            {cashflows &&
                              cashflows.length !== 0 &&
                              cashflow_header.map((headers, idx) => (
                                <th key={idx}>{headers}</th>
                              ))}
                          </tr>

                          {cashflows &&
                            cashflows.length !== 0 &&
                            cashflows.map(cashflowArr =>
                              cashflowArr.map((cashflow, idx) => (
                                <tr key={idx}>
                                  <td colSpan="1">{`Month ${cashflow.month}`}</td>
                                  <td>
                                    {numberWithCommas(cashflow.grossExpense)}
                                  </td>
                                  <td>
                                    {numberWithCommas(cashflow.grossInflow)}
                                  </td>
                                  <td>
                                    {numberWithCommas(cashflow.weightedExpense)}
                                  </td>
                                  <td>
                                    {numberWithCommas(cashflow.weightedInflow)}
                                  </td>
                                </tr>
                              )),
                            )}
                        </tbody>
                      </table>
                    </div>
                    {estimations &&
                      estimations.length !== 0 &&
                      estimations.map(estimationsArr =>
                        estimationsArr.map((estimation, idx) => (
                          <div className="table__body" key={idx}>
                            <ul>
                              <h4>{estimations.length ? 'Estimations' : ''}</h4>
                              <li>
                                <span>
                                  Estimated Next Month Average Credit Amount
                                </span>
                                <span className="right-text">
                                  {numberWithCommas(
                                    estimation[
                                      'estimated Next Month Average Credit Amount'
                                    ],
                                  )}
                                </span>
                              </li>
                              <li>
                                <span>
                                  Estimated Next Month Average Debit Amount
                                </span>
                                <span className="right-text">
                                  {numberWithCommas(
                                    estimation[
                                      'estimated Next Month Average Debit Amount'
                                    ],
                                  )}
                                </span>
                              </li>
                              <li>
                                <span>
                                  Estimated Next Month Maximum Credit Amount
                                </span>
                                <span className="right-text">
                                  {numberWithCommas(
                                    estimation[
                                      'estimated Next Month Maximum Credit Amount'
                                    ],
                                  )}
                                </span>
                              </li>
                              <li>
                                <span>
                                  Estimated Next Month Maximum Debit Amount
                                </span>
                                <span className="right-text">
                                  {numberWithCommas(
                                    estimation[
                                      'estimated Next Month Maximum Debit Amount'
                                    ],
                                  )}
                                </span>
                              </li>
                              <li>
                                <span>
                                  Estimated Next Month Minimum Credit Amount
                                </span>
                                <span className="right-text">
                                  {numberWithCommas(
                                    estimation[
                                      'estimated Next Month Maximum Credit Amount'
                                    ],
                                  )}
                                </span>
                              </li>
                              <li>
                                <span>
                                  Estimated Next Month Minimum Debit Amount
                                </span>
                                <span className="right-text">
                                  {numberWithCommas(
                                    estimation[
                                      'estimated Next Month Maximum Debit Amount'
                                    ],
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        )),
                      )}
                  </li>
                ))}
              </ul>
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default ModelResponseTab;
