import { gql } from 'apollo-boost';

export const DELETE_SUPPORTING_DOCUMENT_MUTATION = gql`
  mutation MP_DeleteSupportingDocumentMutation(
    $applicationId: String!
    $key: String!
    $bucket: String!
  ) {
    deleteApplicationDocument(
      input: { applicationId: $applicationId, key: $key, bucket: $bucket }
    ) {
      success
    }
  }
`;
