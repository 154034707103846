import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import LiquidityManagement from '../../Providers/LiquidityManagement';
import { DisburseTableProvider } from '../../Providers';
import {
  Button,
  DataCard,
  EmptyScreen,
  Export,
  Loader,
  Table,
  Toast,
} from '../../components';
import {
  capitalizeInitialLetter,
  nairaAmount,
  reduceStringLength,
} from '../../lib/Utils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Search from './Search';
import LiquidityManagementNavs from './LiquidityManagementNavs';

const headers = ['ID', 'Amount', 'Status', 'Type', 'Created At'];

const disbursementWallet = () => {
  const {
    data,
    loading,
    input,
    handleHideToast,
    handleDisbursementWalletReset,
    searchQuery,
    handleDisbursementWalletStartDate,
    handleDisbursementWalletEndDate,
    handleExportToCsv,
    setFilterParams,
  } = LiquidityManagement();

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="layout-main">
      <LiquidityManagementNavs />
      <Toast
        title="Liquidity Management"
        content={input.content.value}
        classes={input.status.value}
        active={input.showToast.value}
        clickEvent={handleHideToast}
      />
      <Search
        input={input}
        resetAction={handleDisbursementWalletReset}
        searchAction={searchQuery}
        type="Disbursement Wallet"
        handleStartDate={handleDisbursementWalletStartDate}
        handleEndDate={handleDisbursementWalletEndDate}
      />
      {data &&
      Object.keys(data).length > 0 &&
      data.getWallets.account.wallets &&
      data.getWallets.account.wallets.disbursementWallet &&
      data.getWallets.account.wallets.disbursementWallet.transactions &&
      data.getWallets.account.wallets.disbursementWallet.transactions?.nodes
        .length > 0 ? (
        <ErrorBoundary
          fallback={
            <DataCard noPadding={true}>
              <EmptyScreen message="Error Fetching transaction" />
            </DataCard>
          }
        >
          <div className="loans">
            <Button
              classes="right handle-export"
              click_event={handleExportToCsv}
            >
              <Export />
              <span className="export-csv">Export CSV</span>
            </Button>

            <DisburseTableProvider
              setFilterParams={setFilterParams}
              render={({ isLoading, handlePagination, disburseTableData }) => (
                <section>
                  <DataCard noPadding={false}>
                    <Table>
                      <div className="table__head">
                        {headers.map((header, idx) => (
                          <span key={idx}>{header}</span>
                        ))}
                      </div>
                      <div className="table__body">
                        {disburseTableData &&
                          disburseTableData.getWallets &&
                          disburseTableData.getWallets.account.wallets.disbursementWallet.transactions.nodes.map(
                            (transaction, idx) => (
                              <ul key={idx}>
                                <Link
                                  to={`/loans/${transaction.portfolio.portfolioNumber}/${transaction.portfolio.user.email}`}
                                >
                                  <li>
                                    <span>
                                      <div className="first-td">
                                        {reduceStringLength(
                                          transaction.portfolio.portfolioNumber,
                                          15,
                                        ).toUpperCase()}
                                      </div>
                                    </span>
                                    <span>
                                      {nairaAmount(
                                        transaction.portfolio.amountDisbursed,
                                      )}
                                    </span>
                                    <span>
                                      {capitalizeInitialLetter(
                                        transaction.status,
                                      )}
                                    </span>
                                    <span>{transaction.type.name}</span>
                                    <span>
                                      {moment(transaction.createdAt).format(
                                        'lll',
                                      )}
                                    </span>
                                  </li>
                                </Link>
                              </ul>
                            ),
                          )}
                      </div>
                    </Table>
                  </DataCard>

                  {disburseTableData.getWallets.account.wallets
                    .disbursementWallet.transactions.pageInfo.hasNextPage &&
                    (isLoading ? (
                      <Loader />
                    ) : (
                      <Button
                        classes={'secondary center activate-policy'}
                        click_event={handlePagination}
                      >
                        Load more
                      </Button>
                    ))}
                </section>
              )}
            />
          </div>
        </ErrorBoundary>
      ) : (
        <DataCard noPadding={true}>
          <EmptyScreen message="No Transactions Yet" />
        </DataCard>
      )}
    </div>
  );
};

export default disbursementWallet;
