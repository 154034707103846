import { gql } from 'apollo-boost';

export const LOGIN_MUTATION = gql`
  mutation MP_LoginMutation(
    $email: String!
    $password: String!
    $clientId: ID!
  ) {
    login(
      input: {
        email: $email
        password: $password
        clientId: $clientId
        asClient: true
      }
    ) {
      token
      user {
        id
        status
        firstName
        lastName
      }
    }
  }
`;

export const TRIGGER_PASSWORD_RESET_MUTATION = gql`
  mutation MP_TriggerPasswordResetMutation(
    $clientId: ID!
    $email: String!
    $asClient: Boolean!
  ) {
    triggerPasswordReset(
      input: { clientId: $clientId, email: $email, asClient: $asClient }
    ) {
      ok
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation MP_ResetPasswordMutation($resetToken: String!, $password: String!) {
    resetPassword(input: { resetToken: $resetToken, password: $password }) {
      ok
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation MP_ChangePasswordMutation(
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      input: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      token
    }
  }
`;
