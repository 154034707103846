import React, { Fragment } from 'react';
import {
  DataCard,
  Table,
  Button,
  Toast,
  EmptyScreen,
  BreadCrumbSection,
  MoreOptions,
  Modal,
  Close,
  Loader,
} from '../../components';
import cx from 'classnames';
import {
  CreditPolicyProvider,
  PolicyDeleteProvider,
  PolicyDisableProvider,
  PolicyEnableProvider,
  CreditPolicyTableProvider,
} from '../../Providers';
import { NavLink, Link } from 'react-router-dom';
import { formatPolicyMinMaxAmount } from '../../lib/Utils';
import Search from './Search';
import { ErrorBoundary } from 'react-error-boundary';

import './_Styles.scss';

const headers = {
  policyHeader: [
    'Status',
    'Name',
    'Loan Range',
    'Validity Period',
    'Approval WorkFlow',
    'Loan Type',
    'Repayment Frequency',
    '',
  ],
};

const CreditPolicy = ({
  policyData,
  policiesInput,
  loanHideToast,
  policySearchQuery,
  handleReset,
}) => {
  const {
    handlePolicyDelete,
    policyDeleteInput,
    handleDeleteHideToast,
    policyDeleteLoading,
    toggleDeletePolicyPopup,
  } = PolicyDeleteProvider();

  const {
    policyEnableInput,
    handlePolicyEnable,
    handleEnableHideToast,
    policyEnableLoading,
    toggleEnablePolicyPopup,
  } = PolicyEnableProvider();

  const {
    policyDisableInput,
    handlePolicyDisable,
    handleDisableHideToast,
    policyDisableLoading,
    toggleDisablePolicyPopup,
  } = PolicyDisableProvider();

  return (
    <>
      <Fragment>
        <BreadCrumbSection>
          <a
            data-testid="new-rule"
            className="button"
            href="/settings/loan-rules/create"
          >
            Create New Rule
          </a>
          <a
            data-testid="newloanproduct"
            className="button secondary"
            href="/loan-products/create-loan-product"
          >
            Create New Product
          </a>
        </BreadCrumbSection>
        <div className="layout-main">
          <Toast
            title="All Policies"
            content={policiesInput?.content?.value}
            classes={policiesInput?.status?.value}
            active={policiesInput?.showToast?.value}
            clickEvent={loanHideToast}
          />
          <Search
            input={policiesInput}
            resetAction={handleReset}
            searchAction={policySearchQuery}
          />

          {policyDeleteLoading ? (
            <Loader loadingText="loading..." />
          ) : (
            <Fragment>
              <Toast
                title={`Delete Selected Loan Product`}
                classes={policyDeleteInput?.status?.value}
                content={policyDeleteInput?.content?.value}
                active={policyDeleteInput?.showToast?.value}
                clickEvent={handleDeleteHideToast}
              />

              <Modal
                title={'Delete Loan Product'}
                classes="cash-repayment actions-policy-popup"
                active={policyDeleteInput?.isDeletePolicyPopupVisible?.value}
              >
                <Close
                  className="close-icon"
                  onClick={e => toggleDeletePolicyPopup(e)}
                />
                <div className="content center">
                  {' '}
                  Are you sure you want to delete{' '}
                  <span className="caption">
                    {policyDeleteInput?.selectedPolicyData?.value?.name ||
                      ' this '}
                  </span>{' '}
                  product?
                </div>
                <div className="actions">
                  <div className="flex-container center">
                    <Button
                      classes="secondary"
                      click_event={e =>
                        handlePolicyDelete(
                          e,
                          policyDeleteInput?.selectedPolicyData?.value,
                        )
                      }
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </Modal>
            </Fragment>
          )}

          {policyEnableLoading ? (
            <Loader loadingText="loading..." />
          ) : (
            <Fragment>
              <Toast
                title={`Enable Selected Loan Product`}
                classes={policyEnableInput?.status?.value}
                content={policyEnableInput?.content?.value}
                active={policyEnableInput?.showToast?.value}
                clickEvent={handleEnableHideToast}
              />
              <Modal
                title={'Enable Loan Product'}
                classes="cash-repayment actions-policy-popup"
                active={policyEnableInput?.isEnablePolicyPopupVisible?.value}
              >
                <Close
                  className="close-icon"
                  onClick={e => toggleEnablePolicyPopup(e)}
                />
                <div className="content center">
                  {' '}
                  Do you want to enable{' '}
                  <span className="caption">
                    {policyEnableInput?.selectedPolicyData?.value?.name ||
                      ' this '}
                  </span>{' '}
                  product?
                </div>
                <div className="actions">
                  <div className="flex-container center">
                    <Button
                      classes="secondary"
                      click_event={e =>
                        handlePolicyEnable(
                          e,
                          policyEnableInput?.selectedPolicyData?.value,
                        )
                      }
                    >
                      Enable
                    </Button>
                  </div>
                </div>
              </Modal>
            </Fragment>
          )}

          {policyDisableLoading ? (
            <Loader loadingText="loading..." />
          ) : (
            <Fragment>
              <Toast
                title={`Disable Selected Loan Product`}
                classes={policyDisableInput?.status?.value}
                content={policyDisableInput?.content?.value}
                active={policyDisableInput?.showToast?.value}
                clickEvent={handleDisableHideToast}
              />
              <Modal
                title={'Disable Loan Product'}
                classes="cash-repayment actions-policy-popup"
                active={policyDisableInput?.isDisablePolicyPopupVisible?.value}
              >
                <Close
                  className="close-icon"
                  onClick={e => toggleDisablePolicyPopup(e)}
                />
                <div className="content center">
                  {' '}
                  Do you want to disable{' '}
                  <span className="caption">
                    {policyDisableInput?.selectedPolicyData?.value?.name ||
                      ' this '}
                  </span>{' '}
                  product?
                </div>

                <div className="actions">
                  <div className="flex-container center">
                    <Button
                      classes="secondary"
                      click_event={e =>
                        handlePolicyDisable(
                          e,
                          policyDisableInput?.selectedPolicyData?.value,
                        )
                      }
                    >
                      Disable
                    </Button>
                  </div>
                </div>
              </Modal>
            </Fragment>
          )}

          <div className="flex-container space-between">
            <h4>All Loan Products</h4>
          </div>
          {policyData?.viewer?.account?.policies?.nodes?.length > 0 ? (
            <ErrorBoundary
              fallback={
                <DataCard noPadding={false}>
                  <EmptyScreen message="Error Fetching Loans" />
                </DataCard>
              }
            >
              <Fragment>
                <CreditPolicyTableProvider
                  render={({
                    creditPolicyTableData,
                    isLoading,
                    handlePagination,
                  }) => (
                    <section>
                      <DataCard noPadding={false}>
                        <Table>
                          <div className="table__head">
                            {headers.policyHeader.map((header, key) => (
                              <span
                                key={key}
                                className={cx({
                                  Status: header,
                                  'checked-width':
                                    header === 'Status' || header === '',
                                })}
                              >
                                {' '}
                                {header}{' '}
                              </span>
                            ))}
                          </div>
                          <div className="table__body">
                            {creditPolicyTableData?.viewer?.account?.policies?.nodes.map(
                              (policy, key) => (
                                <ul key={key}>
                                  <Link
                                    to={{
                                      pathname: `/loan-products/${policy.id}`,
                                      name: `${
                                        policy.name ? policy.name : policy.id
                                      }`,
                                    }}
                                  >
                                    <li>
                                      <span
                                        className="tooltip checked-width"
                                        data-title={policy.status}
                                      >
                                        <span
                                          className={`status fixed ${
                                            policy.status.toLowerCase() || ''
                                          }`}
                                        >
                                          {(() => {
                                            const statusName = policy.status;
                                            switch (statusName) {
                                              case 'ACTIVE':
                                                return 'ACT';
                                              case 'DRAFT':
                                                return 'DRA';
                                              case 'DELETED':
                                                return 'DEL';
                                              case 'DISABLED':
                                                return 'DIS';
                                              default:
                                                return 'PEN';
                                            }
                                          })()}
                                        </span>
                                      </span>
                                      <span>{policy.name}</span>
                                      <span>
                                        {formatPolicyMinMaxAmount(policy)}
                                      </span>
                                      <span>{`${
                                        policy.minLoanDuration !== null
                                          ? policy.minLoanDuration
                                          : 'N/A'
                                      } - ${
                                        policy.maxLoanDuration !== null
                                          ? policy.maxLoanDuration
                                          : 'N/A'
                                      }`}</span>
                                      <span>
                                        {policy.approvalWorkflow
                                          ? policy.approvalWorkflow.name
                                          : 'N/A'}
                                      </span>
                                      <span>{policy.loanType}</span>
                                      <span>{policy.repaymentType}</span>
                                      <span className="checked-width">
                                        {!policy.isDefault && (
                                          <MoreOptions id={policy.id}>
                                            <NavLink
                                              to={`/loan-products/update?productName=${encodeURIComponent(
                                                policy.name,
                                              )}`}
                                            >
                                              Edit Product
                                            </NavLink>
                                            {policy.status === 'ACTIVE' && (
                                              <Fragment>
                                                <Button
                                                  classes="link dark-text"
                                                  click_event={e =>
                                                    toggleDisablePolicyPopup(
                                                      e,
                                                      policy,
                                                    )
                                                  }
                                                >
                                                  Disable
                                                </Button>
                                              </Fragment>
                                            )}
                                            {policy.status === 'DISABLED' && (
                                              <Button
                                                classes="link dark-text"
                                                click_event={e =>
                                                  toggleEnablePolicyPopup(
                                                    e,
                                                    policy,
                                                  )
                                                }
                                              >
                                                Enable
                                              </Button>
                                            )}
                                            {policy.status === 'DRAFT' && (
                                              <Fragment>
                                                <Button
                                                  classes="link dark-text"
                                                  click_event={e =>
                                                    toggleEnablePolicyPopup(
                                                      e,
                                                      policy,
                                                    )
                                                  }
                                                >
                                                  Enable
                                                </Button>
                                                <Button
                                                  classes="link dark-text"
                                                  click_event={e =>
                                                    toggleDisablePolicyPopup(
                                                      e,
                                                      policy,
                                                    )
                                                  }
                                                >
                                                  Disable
                                                </Button>
                                              </Fragment>
                                            )}
                                            <Button
                                              classes="link dark-text"
                                              click_event={e =>
                                                toggleDeletePolicyPopup(
                                                  e,
                                                  policy,
                                                )
                                              }
                                            >
                                              Delete
                                            </Button>
                                          </MoreOptions>
                                        )}
                                      </span>
                                    </li>
                                  </Link>
                                </ul>
                              ),
                            )}
                          </div>
                        </Table>
                      </DataCard>

                      {creditPolicyTableData.viewer.account.policies.pageInfo
                        .hasNextPage &&
                        (isLoading ? (
                          <Loader />
                        ) : (
                          <Button
                            classes={'secondary center activate-policy'}
                            click_event={handlePagination}
                          >
                            Load more
                          </Button>
                        ))}
                    </section>
                  )}
                />
              </Fragment>
            </ErrorBoundary>
          ) : (
            <DataCard noPadding={true}>
              <EmptyScreen message="No Policies Created Yet" />
            </DataCard>
          )}
        </div>
      </Fragment>
    </>
  );
};

const CreditPolicyWrapper = () => (
  <CreditPolicyProvider
    render={({
      policyData,
      error,
      policiesInput,
      loanHideToast,
      policyPagination,
      policySearchQuery,
      handleReset,
      toggleDeletePolicyPopup,
      toggleEnablePolicyPopup,
      toggleDisablePolicyPopup,
    }) => (
      <CreditPolicy
        policyData={policyData}
        error={error}
        policiesInput={policiesInput}
        loanHideToast={loanHideToast}
        policyPagination={policyPagination}
        policySearchQuery={policySearchQuery}
        handleReset={handleReset}
        toggleDeletePolicyPopup={toggleDeletePolicyPopup}
        toggleEnablePolicyPopup={toggleEnablePolicyPopup}
        toggleDisablePolicyPopup={toggleDisablePolicyPopup}
      />
    )}
  />
);

export default CreditPolicyWrapper;
