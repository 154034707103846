import React from 'react';
import HelpIcon from '../Icons/HelpIcon';
import { Link } from 'react-router-dom';
import './_BlacklistedUser.scss';

const BlacklistedUser = ({ blacklistDetails, customerName }) => {
  const { isInternalBlacklist, blacklisted } = blacklistDetails || {};

  return (
    <>
      {blacklisted && (
        <section className="blacklisted-user">
          <Link
            to={`/customers/${encodeURIComponent(
              customerName,
            )}#blacklist-reports`}
            title="View Blacklist Report"
          >
            <section
              className={`blacklisted-badge ${
                isInternalBlacklist ? 'blacklisted__internal' : ''
              }`}
            >
              Blacklisted
            </section>
          </Link>

          <aside className="blacklisted-user-tooltip">
            <HelpIcon />

            <section className="top">
              {isInternalBlacklist ? (
                <span>
                  This customer has been blacklisted.
                  <Link
                    to={`/customers/${encodeURIComponent(
                      customerName,
                    )}#blacklist-reports`}
                  >
                    View blacklist reports
                  </Link>
                </span>
              ) : (
                <span>
                  This customer has one or multiple blacklist reports on
                  originate.{' '}
                  <Link
                    to={`/customers/${encodeURIComponent(
                      customerName,
                    )}#blacklist-reports`}
                  >
                    View blacklist reports
                  </Link>
                </span>
              )}
              <i></i>
            </section>
          </aside>
        </section>
      )}
    </>
  );
};
export default BlacklistedUser;
