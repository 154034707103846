import React, { Fragment } from 'react';
import { Button, Loader, Popup, Select, Toast } from '../../../../components';
import { Link } from 'react-router-dom';
import { CreditPolicyProvider } from '../../../../Providers';
import BlacklistedUser from '../../../../components/BlacklistedUser/BlacklistedUser';

const SideBar = ({
  data,
  isVisible,
  changePolicyLoading,
  facilityInfo,
  customerName,
  applicationPolicyInput,
  handleChangeApplicationPolicy,
  handleChangeApplicationPolicyToast,
  handleSetManualReviewApplication,
  handleSetManualReviewApplicationToast,
  hideChangePolicyPopup,
  hideManualReviewPopup,
  getChangeDescription,
  getSetManualReviewText,
  setManualReviewInput,
  setManualReviewLoading,
  showChangePolicyPopup,
  missingApplicationStepMessage,
}) => {
  return (
    <Fragment>
      <article className="customer-info">
        <span className="cover-image" />
        {data?.application?.user ? (
          <div className="avatar">
            {data.application.user?.firstName?.charAt(0)?.toUpperCase()}
            {data.application.user?.lastName?.charAt(0)?.toUpperCase()}
          </div>
        ) : (
          <div className="avatar">...</div>
        )}
        {data?.application?.user ? (
          <h3>{`${data.application.user?.firstName} ${data.application.user?.lastName}`}</h3>
        ) : (
          <h3>...</h3>
        )}

        {data?.application?.user?.blacklistDetails && (
          <BlacklistedUser
            blacklistDetails={data?.application?.user?.blacklistDetails}
            customerName={customerName}
          />
        )}
      </article>
      <article className="detail-section">
        {isVisible ? (
          <Link
            to={`/facilities/${encodeURIComponent(facilityInfo?.accountName)}`}
          >
            <Button classes="green center view-customerdetails">
              View Facility Details
            </Button>
          </Link>
        ) : (
          <Link to={`/customers/${encodeURIComponent(customerName)}`}>
            <Button classes="green center view-customerdetails">
              View Customer Details{' '}
            </Button>
          </Link>
        )}
        <h6>Personal Information</h6>
        {
          <ul>
            <li>
              <span>Customer ID</span>
              <span>{customerName || '...'}</span>
            </li>

            <li>
              <span>Contact Email</span>
              <span>{data.application?.user?.email || '...'}</span>
            </li>
          </ul>
        }
      </article>

      {changePolicyLoading ? (
        <Loader loadingText="loading..." />
      ) : (
        <CreditPolicyProvider
          render={({ policyData }) => (
            <Fragment>
              <Popup
                title={'Change Loan Policy'}
                classes="cash-repayment"
                active={applicationPolicyInput?.showPolicyPopup?.value}
              >
                <Toast
                  title="Change Application Policy"
                  content={applicationPolicyInput?.content?.value}
                  active={applicationPolicyInput?.showToast?.value}
                  classes={applicationPolicyInput?.status?.value}
                  clickEvent={handleChangeApplicationPolicyToast}
                />
                <div className="show-policy-popup">
                  {data?.application?.policy && (
                    <div className="range_from">
                      <span>From:</span>
                      <span>{data.application.policy?.name}</span>
                    </div>
                  )}
                  <Select
                    label={'Select new policy:'}
                    required
                    {...applicationPolicyInput?.policyId?.input}
                  >
                    <option disabled value="">
                      -- Select New Policy --
                    </option>
                    {policyData?.viewer?.account?.policies?.nodes?.map(
                      (item, key) => (
                        <option key={key} value={item?.id}>
                          {item?.name}
                        </option>
                      ),
                    )}
                  </Select>
                  <div>
                    {applicationPolicyInput?.policyId?.input !== '' &&
                      getChangeDescription(policyData)}
                  </div>
                  {applicationPolicyInput?.policyChangeDesc?.input?.value && (
                    <div className="sections detail-section">
                      <ul className="listing">
                        <li>
                          <span>Repayment Type:</span>
                          <span>
                            {
                              applicationPolicyInput.policyChangeDesc.input
                                .value?.repaymentType
                            }
                          </span>
                        </li>
                        <li>
                          <span>Duration Type:</span>
                          <span>
                            {
                              applicationPolicyInput.policyChangeDesc.input
                                .value?.durationType
                            }
                          </span>
                        </li>
                        <li>
                          <span>Duration:</span>
                          <span>
                            {applicationPolicyInput.policyChangeDesc.input.value
                              ?.minLoanDuration || 'N/A'}{' '}
                            -{' '}
                            {applicationPolicyInput.policyChangeDesc.input.value
                              ?.maxLoanDuration || 'N/A'}
                          </span>
                        </li>
                        <li>
                          <span>Status:</span>
                          <span>
                            {
                              applicationPolicyInput.policyChangeDesc.input
                                .value?.status
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="flex-container space-between">
                    <Button click_event={hideChangePolicyPopup}>Cancel</Button>
                    <Button
                      click_event={e =>
                        handleChangeApplicationPolicy(e, {
                          policyId:
                            applicationPolicyInput?.policyId?.input?.value,
                          applicationId:
                            applicationPolicyInput?.applicationId?.input?.value,
                        })
                      }
                      classes="secondary"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              </Popup>
            </Fragment>
          )}
        />
      )}

      {setManualReviewLoading ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          {setManualReviewLoading && <Loader loadingText="loading..." />}

          <Toast
            title="Set Manual Review"
            content={setManualReviewInput?.content?.value}
            active={setManualReviewInput?.showToast?.value}
            clickEvent={handleSetManualReviewApplicationToast}
          />
          <Popup
            title={'Move to Manual Review'}
            classes="cash-repayment"
            active={setManualReviewInput?.showManualReviewPopup?.value}
          >
            <div className="show-policy-popup">
              <div className="range_from">
                <span>
                  {getSetManualReviewText(data?.application?.status?.name)}
                </span>
              </div>

              <div className="flex-container space-between">
                <Button click_event={hideManualReviewPopup}>Cancel</Button>
                <Button
                  click_event={handleSetManualReviewApplication}
                  classes="secondary"
                >
                  Update Status
                </Button>
              </div>
            </div>
          </Popup>
        </Fragment>
      )}

      <article className="detail-section">
        {missingApplicationStepMessage
          ?.split(',')
          ?.includes('Pending Application Bank Account') &&
        data?.application?.account?.bankAccounts?.length > 0 ? (
          ' '
        ) : (
          <Fragment>
            <h6>Policy Information</h6>
            {data?.application && (
              <ul>
                <li>
                  <span>
                    {data?.application?.policy ? (
                      <Link
                        to={`/loan-products/${data.application.policy?.id}`}
                      >
                        <Button classes="link">
                          {data.application.policy?.name}
                        </Button>
                      </Link>
                    ) : (
                      'No policy selected'
                    )}
                  </span>
                  <span>
                    <Button
                      classes="btn-small"
                      click_event={showChangePolicyPopup}
                    >
                      {data.application?.policy ? 'Change' : 'Apply'} Loan
                      Product
                    </Button>
                  </span>
                </li>
              </ul>
            )}
          </Fragment>
        )}
      </article>
      {/* <article className="detail-section">
        {!data?.application?.loanCategory ? (
          ' '
        ) : (
          <Fragment>
            <h6>Loan Category Information</h6>
            <ul>
              <li>
                <span> Category Name </span>
                <span>{data?.application?.loanCategory?.name}</span>
              </li>
            </ul>
          </Fragment>
        )}
      </article> */}
      <article className="detail-section">
        {!data?.application?.approvalWorkflow ? (
          ' '
        ) : (
          <Fragment>
            <h6>Approval Work Flow Information</h6>
            <ul>
              <li>
                <span> Approval Work Flow Name </span>
                <span>
                  {data?.application?.approvalWorkflow?.workflow?.name}
                </span>
              </li>
              <li>
                <span> Approval Work Flow Step </span>
                <span>
                  {data?.application?.approvalWorkflow?.nextStep
                    ? data.application.approvalWorkflow.nextStep?.approvalType
                    : 'N/A'}
                </span>
              </li>
            </ul>
          </Fragment>
        )}
      </article>
      <article className="detail-section">
        {!data?.application?.bankAccount ? (
          ' '
        ) : (
          <Fragment>
            <h6>Bank Information</h6>
            <ul>
              <li>
                <span> Bank Name </span>
                <span>{data?.application?.bankAccount?.bank?.name}</span>
              </li>
              <li>
                <span> Account Name </span>
                <span>{data?.application?.bankAccount?.accountName}</span>
              </li>
            </ul>
          </Fragment>
        )}
      </article>
    </Fragment>
  );
};

export default SideBar;
