import { Query } from '@apollo/react-components';
import { useQuery } from '@apollo/react-hooks';
import flatten from 'flat';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Loader } from '../components';
import { APPLICATION_QUERY, CUSTOMERS_QUERY } from '../graphql/queries';
import Constants from '../lib/Constants';
import { useCustomState } from '../lib/CustomHooks';
import { deepCopy, exportToCsv, trimGraphQLError } from '../lib/Utils';
import { useClientInfo } from '../Providers-new/Client';
import { toast } from 'react-toastify';
import { connection_limit } from '../config/config';

const STEP_MESSAGES = {
  SET_APPLICATION_BANK_ACCOUNT: 'Pending Application Bank Account',
  INITIATE_BANK_STATEMENT_REQUEST: 'Pending Bank Statement Step',
  COMPLETE_BANK_STATEMENT_REQUEST: 'Pending Bank Statement Result',
  GENERATE_CREDIT_SCORE: ' Pending Credit Model Result',
  GET_DECIDE_RESULT: 'Pending Decide Bank Statement Analysis Results',
  GET_CREDIT_BUREAU_REPORT: 'Pending Credit Bureau Report',
  COMPLETE_EXTERNAL_BANK_STATEMENT_REQUEST: 'Pending Bank Statement Result',
  GET_CREDIT_REGISTRY_REPORT: 'Pending Credit Registry Report',
  GET_CRC_REPORT: 'Pending CRC Report',
  GET_CRC_MICRO_REPORT: 'Pending CRC Micro Report',
  GET_CRC_BASIC_PREMIUM_REPORT: 'Pending CRC Basic Premium Report',
  GET_OKRA_TRANSACTIONS: 'Pending Okra Transaction',
  UPLOAD_BANK_STATEMENT: 'Pending Bank Statement Upload',
};

const getMissingApplicationStepMessage = stepArr => {
  const stepMessageArr = [];
  stepArr.forEach(
    step => STEP_MESSAGES[step] && stepMessageArr.push(STEP_MESSAGES[step]),
  );

  return stepMessageArr.length > 0
    ? stepMessageArr.join(', ')
    : 'Application is in Progress';
};

const ApplicationDetails = ({ render, match: { params } }) => {
  const { applicationNumber, customerEmail } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('application-information');

  const applicationHistoryInput = {
    searchParams: {
      actionBy: useCustomState(),
      commentLike: useCustomState(),
      newApplicationStatus: useCustomState(null),
      oldApplicationStatus: useCustomState(null),
    },
    isSearchPerformed: useCustomState(false),
  };

  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    if (errorMsg) {
      toast.error(
        trimGraphQLError(
          errorMsg?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
    return () => toast.dismiss();
  }, [errorMsg]);

  const { data: customerData } = useQuery(CUSTOMERS_QUERY, {
    variables: {
      email: customerEmail,
    },
    fetchPolicy: 'network-only',
  });

  const { clientInfo } = useClientInfo({
    privateFields: true,
  });

  let customerName;
  let facilityInfo;

  if (customerData && customerData.viewer) {
    customerName = customerData.viewer.account.customers.nodes[0].name;
  }

  if (
    customerData &&
    customerData.viewer &&
    customerData.viewer.account.customers.nodes[0].accountAttribute.length
  ) {
    facilityInfo =
      customerData.viewer.account.customers.nodes[0].accountAttribute[0].data;
  }

  const applicationDetailsPrompt = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  const handleHideToast = e => {
    e.preventDefault();

    applicationDetailsPrompt.showToast.setValue(false);
  };

  const reFetch = async (refetch, searchParams) => {
    refetch(setSearchParams(searchParams));
  };

  const setSearchParams = searchParams => {
    const {
      actionBy,
      commentLike,
      newApplicationStatus,
      oldApplicationStatus,
    } = searchParams;

    return {
      actionBy: actionBy.value ? actionBy.value : undefined,
      commentLike: commentLike.value ? commentLike.value : undefined,
      newApplicationStatus: newApplicationStatus.value
        ? newApplicationStatus.value
        : undefined,
      oldApplicationStatus: oldApplicationStatus.value
        ? oldApplicationStatus.value
        : undefined,
      first: connection_limit.value,
    };
  };

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    applicationHistoryInput.isSearchPerformed.setValue(true);
    await reFetch(refetch, applicationHistoryInput.searchParams);
  };

  const handleFetchMore = (e, fetchMore, data) => {
    e.preventDefault();

    setIsLoading(true);
    fetchMore({
      variables: {
        cursor: data.application.histories.pageInfo.endCursor,
        ...setSearchParams,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoading(false);
        setCurrentTab('activities');
        const newNodes = fetchMoreResult.application.histories.nodes;
        const pageInfo = fetchMoreResult.application.histories.pageInfo;

        return {
          application: {
            id: prev.application.id,
            __typename: prev.application.__typename,
            histories: {
              __typename: prev.application.histories.__typename,
              nodes: [...prev.application.histories.nodes, ...newNodes],
              pageInfo,
            },
          },
        };
      },
    });
  };

  const handleExportToCsv = (e, data) => {
    e.preventDefault();
    let fullname = '';

    if (!data || typeof data === 'undefined') {
      applicationDetailsPrompt.showToast.setValue(true);
      applicationDetailsPrompt.status.setValue('Failed');
      applicationDetailsPrompt.content.setValue(
        Constants.errorMessages.NO_EXPORT_DATA,
      );

      setTimeout(() => {
        applicationDetailsPrompt.showToast.setValue(false);
      }, 2000);
    }

    const {
      application: {
        credit: {
          bankStatement: { Details },
        },
      },
    } = data;

    if (
      data &&
      data.application.credit.bankStatement &&
      data.application.credit.bankStatement.status === 'success'
    ) {
      fullname = `${data.application.user.firstName || ''}_${data.application.user.lastName || ''
        }`;
    }

    exportToCsv(Details, `${fullname}_${Constants.csvNames.MY_BANK_STATEMENT}`);
  };

  return (
    <Query
      query={APPLICATION_QUERY}
      variables={{
        applicationNumber: applicationNumber,
        where: {
          commentLike: applicationHistoryInput.searchParams.commentLike.value,
          actionBy: applicationHistoryInput.searchParams.actionBy.value,
          newApplicationStatus: applicationHistoryInput.searchParams
            .newApplicationStatus.value
            ? applicationHistoryInput.searchParams.newApplicationStatus.value
            : null,
          oldApplicationStatus: applicationHistoryInput.searchParams
            .oldApplicationStatus.value
            ? applicationHistoryInput.searchParams.oldApplicationStatus.value
            : null,
        },
        first: connection_limit.value,
      }}
    >
      {({ loading, error, data, refetch, fetchMore }) => {
        if (loading || (!loading && !error && !data)) {
          return <Loader />;
        }

        error && setErrorMsg(error);

        let isVisible, isBusiness;

        if (data) {
          isVisible = localStorage.getItem('slug') === 'heliumhealth';
          isBusiness = localStorage.getItem('slug') === 'lsetf';
        }

        // Missing application steps logic

        const missingStepsArr = [];

        if (data && data.application) {
          const { requiredSteps, completedSteps } = data.application;

          for (const val of requiredSteps) {
            if (!completedSteps.includes(val)) missingStepsArr.push(val);
          }
        }

        //model results logic

        let modelResults, decideBankStatementAnalysis;

        if (data && data.application) {
          const { credit } = data.application;

          modelResults = credit && credit.modelResults;
          decideBankStatementAnalysis =
            credit && credit.decideBankStatementAnalysis;
        }

        const modelName = [];
        const modelResponseData = [];
        const bankStatementAnalysisData = [];

        modelResults &&
          modelResults.forEach(
            ({ name, modelResponse, bankStatementAnalysis = {} }) => {
              modelName.push(name);
              modelResponseData.push(modelResponse);
              if (
                bankStatementAnalysis &&
                bankStatementAnalysis.bsp_status === 'success'
              )
                bankStatementAnalysisData.push(bankStatementAnalysis);
            },
          );

        const handleExportToCsvBankStatementAnalysis = (e, data) => {
          e.preventDefault();
          let csvData;

          if (data == null) {
            applicationDetailsPrompt.showToast.setValue(true);
            applicationDetailsPrompt.status.setValue('Failed');
            applicationDetailsPrompt.content.setValue(
              Constants.errorMessages.NO_EXPORT_DATA,
            );

            setTimeout(() => {
              applicationDetailsPrompt.showToast.setValue(false);
            }, 2000);

            return;
          }

          csvData = deepCopy(
            bankStatementAnalysisData.map(item => {
              if (item.mbs.status === 'success') {
                return item.mbs.flat;
              } else {
                return item.okra.flat;
              }
            }),
          );

          exportToCsv(csvData, `${Constants.csvNames.BANK_STATEMENT_ANALYSIS}`);
        };

        const handleExportToOkraSummary = (e, data) => {
          //  e.preventDefault();
          if (data == null) {
            applicationDetailsPrompt.showToast.setValue(true);
            applicationDetailsPrompt.status.setValue('Failed');
            applicationDetailsPrompt.content.setValue(
              Constants.errorMessages.NO_EXPORT_DATA,
            );

            setTimeout(() => {
              applicationDetailsPrompt.showToast.setValue(false);
            }, 2000);

            return;
          }

          const {
            application: {
              credit: { okraSummary },
            },
          } = data;

          const csvData = okraSummary.transactions;

          if (!csvData) {
            applicationDetailsPrompt.showToast.setValue(true);
            applicationDetailsPrompt.status.setValue('Failed');
            applicationDetailsPrompt.content.setValue(
              Constants.errorMessages.NO_EXPORT_DATA,
            );

            setTimeout(() => {
              applicationDetailsPrompt.showToast.setValue(false);
            }, 2000);

            return;
          }

          exportToCsv(
            csvData,
            `${Constants.csvNames.OKRA_SUMMARY} TRANSACTIONS`,
          );
        };

        const handleExportToCsvCrcScoreReport = (e, data) => {
          let csvData;

          if (data == null) {
            applicationDetailsPrompt.showToast.setValue(true);
            applicationDetailsPrompt.status.setValue('Failed');
            applicationDetailsPrompt.content.setValue(
              Constants.errorMessages.NO_EXPORT_DATA,
            );

            setTimeout(() => {
              applicationDetailsPrompt.showToast.setValue(false);
            }, 2000);

            return;
          }

          const {
            application: {
              credit: { crcScoreReport, crcMicroScoreReport },
            },
          } = data;

          const csvDataObj = deepCopy({
            ...crcScoreReport,
            ...crcMicroScoreReport,
          });

          csvData = [{ ...csvDataObj }];

          const filterCsvData = () => {
            const newCsvData = [];
            for (let node of csvData) {
              const nanoItems = {};
              const microItems = {};

              nanoItems.Total_No_Of_Delinqent_Credit_Facilities = node.result
                ? node.result.totalNoOfDelinqCreditFacilities != null
                  ? node.result.totalNoOfDelinqCreditFacilities
                  : '__'
                : '__';

              nanoItems.No_Hit = node.result
                ? node.result.notHit === true || node.result.noHit === true
                  ? 'No Hit'
                  : ''
                : '__';

              nanoItems.Last_Reported_Date = node.result
                ? node.result.lastReportedDate
                  ? moment(node.result.lastReportedDate).format('ll')
                  : '__'
                : '__';

              microItems.Total_Number_Of_Credit_Facilities = node.result
                ? node.result.totalNumberOfCreditFacilities != null
                  ? node.result.totalNumberOfCreditFacilities
                  : '__'
                : '__';

              microItems.Sanctioned_Amount = node.result
                ? node.result.sanctionedAmount
                  ? node.result.sanctionedAmount
                  : '__'
                : '__';

              microItems.Total_Outstanding_On_Loans = node.result
                ? node.result.totalOutstandingOnLoans != null
                  ? node.result.totalOutstandingOnLoans
                  : '__'
                : '__';

              microItems.Total_Overdue_On_Loans = node.result
                ? node.result.totalDueOnLoans
                  ? node.result.totalDueOnLoans
                  : '__'
                : '__';

              microItems.Number_of_Days_in_Delinquency = node.result
                ? node.result.numberOfDaysInDelinquency != null
                  ? node.result.numberOfDaysInDelinquency
                  : '__'
                : '__';

              microItems.Number_of_Open_Credit_Facility = node.result
                ? node.result.noOfOpenCreditFacilities != null
                  ? node.result.noOfOpenCreditFacilities
                  : '__'
                : '__';

              clientInfo.requiresCrcReport
                ? newCsvData.push(nanoItems)
                : newCsvData.push({ ...nanoItems, ...microItems });
            }

            csvData = newCsvData;
          };

          filterCsvData();

          exportToCsv(
            csvData,
            `${Constants.csvNames.CREDIT_BUREAU_SCORE_REPORT}`,
          );
        };

        const handleExportToCsvCreditBureauScoreReport = (e, data) => {
          e.preventDefault();
          let csvData;

          if (data == null) {
            applicationDetailsPrompt.showToast.setValue(true);
            applicationDetailsPrompt.status.setValue('Failed');
            applicationDetailsPrompt.content.setValue(
              Constants.errorMessages.NO_EXPORT_DATA,
            );

            setTimeout(() => {
              applicationDetailsPrompt.showToast.setValue(false);
            }, 2000);

            return;
          }

          const {
            application: {
              credit: { creditBureauScoreReport },
            },
          } = data;

          const csvDataObj = deepCopy(creditBureauScoreReport.result);

          csvData = [{ ...csvDataObj }];

          const filterCsvData = () => {
            const newCsvData = [];
            for (let node of csvData) {
              const items = {};

              items.Number_Of_Accounts_In_Bad_Standing = node.result
                ? node.result.numberOfAccountsInBadStanding != null
                  ? node.result.numberOfAccountsInBadStanding
                  : 'N/A'
                : // eslint-disable-next-line no-constant-condition
                '__' || node.numberOfAccountsInBadStanding != null
                  ? node.numberOfAccountsInBadStanding
                  : 'N/A';

              newCsvData.push(items);
            }
            csvData = newCsvData;
          };

          filterCsvData();

          exportToCsv(
            csvData,
            `${Constants.csvNames.CREDIT_BUREAU_SCORE_REPORT}`,
          );
        };

        const handleExportToCsvModelResponse = (e, data) => {
          e.preventDefault();
          let csvData;

          if (data == null) {
            applicationDetailsPrompt.showToast.setValue(true);
            applicationDetailsPrompt.status.setValue('Failed');
            applicationDetailsPrompt.content.setValue(
              Constants.errorMessages.NO_EXPORT_DATA,
            );

            setTimeout(() => {
              applicationDetailsPrompt.showToast.setValue(false);
            }, 2000);

            return;
          }

          csvData = deepCopy(modelResponseData.map(a => flatten(a)));

          exportToCsv(csvData, `${Constants.csvNames.MODEL_RESPONSE}`);
        };

        const handleExportToCsvCreditRegistryReport = (e, data) => {
          e.preventDefault();
          let csvData;

          if (data == null) {
            applicationDetailsPrompt.showToast.setValue(true);
            applicationDetailsPrompt.status.setValue('Failed');
            applicationDetailsPrompt.content.setValue(
              Constants.errorMessages.NO_EXPORT_DATA,
            );

            setTimeout(() => {
              applicationDetailsPrompt.showToast.setValue(false);
            }, 2000);

            return;
          }

          const {
            application: {
              credit: { creditRegistryReport },
            },
          } = data;

          csvData =
            creditRegistryReport.result &&
              creditRegistryReport.result.data?.CreditPerformances
              ? deepCopy(creditRegistryReport.result.data?.CreditPerformances)
              : [];

          exportToCsv(csvData, `${Constants.csvNames.CREDIT_REGISTRY_REPORT}`);
        };

        return render({
          data,
          error,
          refetch,
          input: applicationDetailsPrompt,
          handleHideToast,
          customerName,
          facilityInfo,
          isVisible,
          isBusiness,
          handleExportToCsv: e => handleExportToCsv(e, data),
          handleExportToCsvCrcScoreReport: e =>
            handleExportToCsvCrcScoreReport(e, data),
          handleExportToCsvCreditBureauScoreReport: e =>
            handleExportToCsvCreditBureauScoreReport(e, data),
          handleExportToCsvModelResponse: e =>
            handleExportToCsvModelResponse(e, data),
          handleExportToCsvBankStatementAnalysis: e =>
            handleExportToCsvBankStatementAnalysis(e, data),
          handleExportToCsvCreditRegistryReport: e =>
            handleExportToCsvCreditRegistryReport(e, data),
          modelResults,
          modelName,
          modelResponseData,
          bankStatementAnalysisData,
          missingApplicationStepMessage:
            getMissingApplicationStepMessage(missingStepsArr),
          handleExportToOkraSummary: e => handleExportToOkraSummary(e, data),
          decideBankStatementAnalysis,
          handlePagination: e => handleFetchMore(e, fetchMore, data),
          applicationHistoryLoading: isLoading,
          runSearchQuery: e => handleRefetch(e, refetch),
          applicationHistoryInput,
          currentTab,
          setCurrentTab,
        });
      }}
    </Query>
  );
};

export default withRouter(ApplicationDetails);
