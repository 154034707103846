import { gql } from 'apollo-boost';

export const UPDATE_EXTERNALLY_DISBURSED_LOAN_MUTATION = gql`
  mutation MP_UpdateExternallyDisbursedLoan($portfolioId: ID!) {
    updateExternallyDisbursedLoan(input: { portfolioId: $portfolioId }) {
      success
      message
      portfolio {
        id
      }
    }
  }
`;
