import { useQuery } from '@apollo/react-hooks';

import { CLIENT_ACCOUNT_USER_QUERY } from './queries';

const useClientAcountUser = ({ variables }) => {
  const qResult = useQuery(CLIENT_ACCOUNT_USER_QUERY, {
    variables,
    fetchPolicy: 'network-only',
  });
  const { data, loading, error } = qResult;

  return {
    loading,
    error,
    clientAccountUsers: loading || !data ? [] : data.clientAccountUsers.nodes,
    result: qResult,
  };
};

export { useClientAcountUser };
