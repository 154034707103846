import React from 'react';
import { CustomerDetailsForm } from '../../forms';

const CustomerDetails = () => {
  return (
    <div>
      <CustomerDetailsForm />
    </div>
  );
};

export default CustomerDetails;
