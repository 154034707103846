import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Select, SideBar } from '../../components';

const Search = ({ resetAction, searchAction }) => {
  return (
    <SideBar>
      <section>
        <h4 className={'sidebar-header'}>Search Parameter</h4>

        <Input label="Category Name" placeholder="Enter Name" />

        <Select label="Status">
          <option disabled value="">
            -Select Status-
          </option>
        </Select>

        <div>
          <Button classes={'reset'} click_event={resetAction}>
            Reset
          </Button>
          <Button classes={'secondary right'} click_event={searchAction}>
            Search
          </Button>
        </div>
      </section>
    </SideBar>
  );
};

Search.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  resetAction: PropTypes.func,
  searchAction: PropTypes.func,
};

export default Search;
