import { useQuery } from '@apollo/react-hooks';

import { GET_AVAILABLE_ROLES } from './queries';

const useRoles = () => {
  let output = {
    roles: [],
  };

  const qResult = useQuery(GET_AVAILABLE_ROLES);
  const { data, loading, error } = qResult;

  if (!loading && data && data.availableRoles) {
    output = {
      roles: data.availableRoles,
    };
  }

  return {
    loading,
    error,
    ...output,
    result: qResult,
  };
};

export { useRoles };
