import React from 'react';
import { Query } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { USER_MANAGEMENT_QUERY } from '../graphql/queries';
import { Loader } from '../components';
import OriginateClient from './OriginateClient';
import { useCustomState } from '../lib/CustomHooks';
import Constants from '../lib/Constants';

const UserManagementProvider = ({ render, match: { params } }) => {
  const { email } = params;

  const userManagementInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  return (
    <OriginateClient
      render={({ clientId }) => (
        <Query
          query={USER_MANAGEMENT_QUERY}
          variables={{ clientId: clientId.clientInfo.clientId, email: email }}
          notifyOnNetworkStatusChange={true}
          fetchPolicy={'network-only'}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <Loader />;
            }

            if (error) {
              userManagementInput.showToast.setValue(true);
              userManagementInput.status.setValue('Failed');
              userManagementInput.content.setValue(
                Constants.errorMessages.NETWORK_ERROR,
              );
            }

            return render({
              data,
            });
          }}
        </Query>
      )}
    />
  );
};

export default withRouter(UserManagementProvider);
