import { gql } from 'apollo-boost';

export const GET_BANKS = gql`
  query MP_GetBanks {
    getBanks {
      id
      name
      code
    }
  }
`;
