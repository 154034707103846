import { gql } from 'apollo-boost';

export const fragments = {
  application: gql`
    fragment ApplicationDetails on Application {
      id
      amount
      status {
        name
      }
    }
  `,
};

export const APPROVE_MANUAL_REVIEW_APPLICATION_MUTATION = gql`
  mutation MP_ApproveManualReviewApplicationMutation(
    $applicationId: ID!
    $comment: String
  ) {
    approveManualReviewApplication(
      input: { applicationId: $applicationId, comment: $comment }
    ) {
      approved
      application {
        ...ApplicationDetails
      }
    }
  }

  ${fragments.application}
`;

export const REJECT_MANUAL_REVIEW_APPLICATION_MUTATION = gql`
  mutation MP_RejectManualReviewApplication(
    $applicationId: ID!
    $message: String
  ) {
    rejectManualReviewApplication(
      input: { applicationId: $applicationId, message: $message }
    ) {
      approved
      application {
        ...ApplicationDetails
      }
    }
  }

  ${fragments.application}
`;

export const APPROVE_MANUAL_REVIEW_APPLICATIONS_MUTATION = gql`
  mutation MP_ApproveManualReviewApplicationsMutation($applications: [ID!]!) {
    approveManualReviewApplications(input: { applications: $applications }) {
      data {
        approved
        application {
          ...ApplicationDetails
        }
      }
      errors {
        code
        message
      }
    }
  }

  ${fragments.application}
`;

export const REJECT_MANUAL_REVIEW_APPLICATIONS_MUTATION = gql`
  mutation MP_RejectManualReviewApplications(
    $applications: [ID!]!
    $message: String
  ) {
    rejectManualReviewApplications(
      input: { applications: $applications, message: $message }
    ) {
      data {
        approved
        application {
          ...ApplicationDetails
        }
        warnings {
          code
          message
        }
      }
      errors {
        code
        message
      }
    }
  }

  ${fragments.application}
`;
