import { useMutation } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { useClientInfo } from '../Client';

import { GET_MERCHANT_ATTRIBUTES_BY_SLUG } from './queries';

const useGetAttributesBySlug = () => {
  const sessionToken = localStorage.getItem('api-key');

  const { clientInfo, loading: ciLoading } = useClientInfo({
    privateFields: false,
  });

  const [getMerchantAttributes, { loading, error, data }] = useMutation(
    GET_MERCHANT_ATTRIBUTES_BY_SLUG,
  );

  useEffect(() => {
    getMerchantAttributes({
      variables: {
        input: { slug: clientInfo && clientInfo.slug },
        token: sessionToken,
      },
    });
  }, [clientInfo.slug, sessionToken, getMerchantAttributes]);

  const merchantAttributes =
    data?.neutron?.getMerchantAttributesBySlug?.accountAttributes;

  return {
    loading,
    error,
    ciLoading,
    data,
    merchantAttributes,
  };
};

export { useGetAttributesBySlug };
