import React from 'react';
import { Button, Input } from '../../components';

const CustomerSearchForm = ({
  setCustomerId,
  customerId,
  customerData,
  setCustomerData,
  setRepaymentStage,
  handleSearch,
  setCashRepaymentModal,
}) => {
  const handleChange = e => {
    setCustomerData(null);
    setCustomerId(e.target.value);
  };
  return (
    <>
      <Input
        onChange={handleChange}
        required
        value={customerId}
        label="Search Customer ID"
      />

      {customerData && (
        <div className="customer-data">
          <span className="customer-data__text">
            {`${customerData?.user?.firstName} ${customerData?.user?.lastName} (${customerData?.user?.email})`}
          </span>
        </div>
      )}

      <div className="v2-modal-button-wrapper">
        <Button
          classes="primary flex-grow"
          click_event={() => setCashRepaymentModal(prev => !prev)}
        >
          CANCEL
        </Button>

        {customerData ? (
          <Button
            classes="secondary flex-grow"
            click_event={() => setRepaymentStage(prev => prev + Number(1))}
          >
            PROCEED TO PAYMENT
          </Button>
        ) : (
          <Button
            disabled={!customerId?.length}
            classes="secondary flex-grow"
            click_event={handleSearch}
          >
            SEARCH
          </Button>
        )}
      </div>
    </>
  );
};

export default CustomerSearchForm;
