import { Query } from '@apollo/react-components';
import moment from 'moment';
import React from 'react';
import { Loader } from '../components';
import { connection_limit } from '../config/config';
import { CREDIT_POLICIES_QUERY } from '../graphql/queries';
import Constants from '../lib/Constants';
import { useCustomState } from '../lib/CustomHooks';
import { deepCopy, exportToCsv, nairaAmount, removeCommas } from '../lib/Utils';

const CreditPolicy = ({ render }) => {
  const policiesInput = {
    showToast: useCustomState(false),
    content: useCustomState(),
    status: useCustomState(),
    name: useCustomState(),
    minLoanAmount: useCustomState(),
    maxLoanAmount: useCustomState(),
    minLoanDuration: useCustomState(),
    maxLoanDuration: useCustomState(),
    durationType: useCustomState(),
    repaymentType: useCustomState(),
  };

  const creditPolicyPrompt = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  const handleHideToast = e => {
    e.preventDefault();

    creditPolicyPrompt.showToast.setValue(false);
  };

  const handleReset = async (e, reFetch) => {
    e.preventDefault();

    policiesInput.name.setValue('');
    policiesInput.status.setValue('');
    policiesInput.minLoanAmount.setValue('');
    policiesInput.maxLoanAmount.setValue('');
    policiesInput.minLoanDuration.setValue('');
    policiesInput.maxLoanDuration.setValue('');
    policiesInput.durationType.setValue('');
    policiesInput.repaymentType.setValue('');

    reFetch({
      name: undefined,
      status: undefined,
      minLoanAmount: undefined,
      maxLoanAmount: undefined,
      minLoanDuration: undefined,
      maxLoanDuration: undefined,
      durationType: undefined,
      repaymentType: undefined,
    });
  };

  const handleExportToCsv = (e, data) => {
    if (!data || typeof data === 'undefined') {
      policiesInput.showToast.setValue(true);
      policiesInput.status.setValue('Failed');
      policiesInput.content.setValue(Constants.errorMessages.NO_EXPORT_DATA);

      setTimeout(() => {
        policiesInput.showToast.setValue(false);
      }, 2000);
    }

    const {
      viewer: {
        account: { policies },
      },
    } = data;
    const csvData = deepCopy(policies);

    // eslint-disable-next-line array-callback-return
    csvData.map(policy => {
      policy.amountRange = `"${
        policy.minLoanAmount !== null
          ? nairaAmount(policy.minLoanAmount)
          : nairaAmount(0.0)
      } - ${
        policy.maxLoanAmount !== null
          ? nairaAmount(policy.maxLoanAmount)
          : nairaAmount(0.0)
      }"`;
      policy.duration = `"${
        policy.minLoanDuration !== null ? policy.minLoanDuration : 'N/A'
      } - ${policy.maxLoanDuration !== null ? policy.maxLoanDuration : 'N/A'}"`;
      policy.createdAt = `"${moment(policy.createdAt).format('llll')}"`;

      delete policy.maxLoanAmount;
      delete policy.minLoanAmount;
      delete policy.maxLoanDuration;
      delete policy.minLoanDuration;
    });

    exportToCsv(csvData, Constants.csvNames.POLICIES);
  };

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    refetch({
      name: policiesInput.name.value || undefined,
      status: policiesInput.status.value || undefined,
      durationType: policiesInput.durationType.value || undefined,
      repaymentType: policiesInput.repaymentType.value || undefined,
      minLoanAmount: policiesInput.minLoanAmount.value
        ? parseFloat(removeCommas(policiesInput.minLoanAmount.value))
        : undefined,
      maxLoanAmount: policiesInput.maxLoanAmount.value
        ? parseFloat(removeCommas(policiesInput.maxLoanAmount.value))
        : undefined,
      minLoanDuration: policiesInput.minLoanDuration.value
        ? parseInt(removeCommas(policiesInput.minLoanDuration.value))
        : undefined,
      maxLoanDuration: policiesInput.maxLoanDuration.value
        ? parseInt(removeCommas(policiesInput.maxLoanDuration.value))
        : undefined,
    });
  };

  return (
    <Query
      query={CREDIT_POLICIES_QUERY}
      notifyOnNetworkStatusChange={true}
      fetchPolicy={'network-only'}
      variables={{ first: connection_limit.value }}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loader loadingText={'loading...'} />;

        return render({
          policyData: data,
          error,
          policiesInput,
          toastValues: creditPolicyPrompt,
          handleHideToast,
          handleReset: e => handleReset(e, refetch),
          policySearchQuery: e => handleRefetch(e, refetch),
          handleExportToCsv: e => handleExportToCsv(e, data),
        });
      }}
    </Query>
  );
};

export default CreditPolicy;
