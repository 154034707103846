import React from 'react';

const DashedPlus = props => (
  <svg width={45} height={45} viewBox="0 0 45 45" fill="none" {...props}>
    <circle
      cx={22.5}
      cy={22.5}
      r={22}
      fill="#F2F8FF"
      stroke="#4C5C6D"
      strokeDasharray="5 5"
    />
    <path
      stroke="#4C5C6D"
      strokeLinecap="round"
      d="M22.5 11.5v22M11.5 22.5h22"
    />
  </svg>
);

export default DashedPlus;
