import React from 'react';
import TableauReport from 'react-tableau-report';
import { BreadCrumbSection } from '../../components';
import { OriginateClient } from '../../Providers';
import './_Reports.scss';

const ReportsWrapper = () => (
  <OriginateClient
    render={({ clientId }) => {
      return <Reports clientId={clientId} />;
    }}
  />
);

const Reports = props => {
  const {
    clientId: {
      clientInfo: { tableauURL },
    },
  } = props;

  const options = {
    height: window.screen.height,
    width: window.screen.width,
    hideTabs: false,
    hideToolbar: false,
    toolbarPosition: 'TOP',
    tableauSoftwareErrorCode: 'INTERNAL_ERROR',
    message: 'An error occured',
  };

  const _tableauURL = tableauURL != null ? tableauURL : '';

  return (
    <>
      <BreadCrumbSection />

      <section className="reports-wrapper">
        <section className="report-container">
          <div className="tableau-container">
            {_tableauURL ? (
              <TableauReport url={_tableauURL} options={options} />
            ) : (
              <>
                <p>
                  Not Authorized to view this page.
                  <br />
                  <span>
                    You must have access to the tableau dashboard to view this
                    page.
                  </span>
                </p>
              </>
            )}
          </div>
        </section>
      </section>
    </>
  );
};

export default ReportsWrapper;
