import React, { Fragment } from 'react';
import {
  Button,
  Input,
  Loader,
  NewCheckBox,
  Notification,
  Popup,
  Select,
  Toast,
} from '../../../../components';
import { CompleteIcon } from '../../../../components/Icons';
import { getLoanDurationName } from '../../../../lib/Utils';
import moment from 'moment';

const ApplyMoratoriumOnApplication = ({
  data,
  applyMoratoriumOnApplicationData,
  handleModalApplyMoratoriumToggle,
  applyMoratoriumOnApplicationLoader,
  applyMoratoriumOnApplicationInput,
  handleApplyMoratoriumOnApplication,
  handleHideApplyMoratoriumOnApplication,
  repaymentValue,
  handleMoratoriumCheckBoxInput,
  moratoriumCheckbox,
  repaymentBreakdown,
}) => {
  return (
    <Fragment>
      <div className="moratorium-popup">
        {applyMoratoriumOnApplicationLoader ? (
          <Loader loadingText="loading..." />
        ) : (
          <Fragment>
            <Toast
              title="Set Moratorium Period"
              content={applyMoratoriumOnApplicationData.content.value}
              clickEvent={handleHideApplyMoratoriumOnApplication}
              active={applyMoratoriumOnApplicationData.showToast.value}
              classes={applyMoratoriumOnApplicationData.status.value}
            />
            {applyMoratoriumOnApplicationData.showNotificationPopup.value && (
              <Notification>
                <div className="notification_icon-holder">
                  <CompleteIcon />
                </div>
                <h5 className={'center-text'}>
                  {moratoriumCheckbox !== false ? (
                    <p>
                      You have successfully waived all moratorium periods for
                      loan <b>{data.application.applicationNumber}</b> and your
                      customer has been notified.
                    </p>
                  ) : (
                    <p>
                      You have successfully suspended{' '}
                      {applyMoratoriumOnApplicationInput.numberOfPeriods.value}{' '}
                      {repaymentValue()} for loan{' '}
                      <b>{data.application.applicationNumber}</b> and your
                      customer has been notified.
                    </p>
                  )}
                </h5>
              </Notification>
            )}

            <Popup
              title={'Set Moratorium Period'}
              classes="cash-repayment"
              active={applyMoratoriumOnApplicationData.showModal.value}
            >
              <form onSubmit={handleApplyMoratoriumOnApplication}>
                <div className="moratorium-text-container">
                  <p className="moratorium-text">
                    When you suspend repayment for a period of time, the
                    repayment breakdown will be recalculated and your customer
                    will be notified.
                  </p>
                  <p className="current-repayment">
                    <span>Next Repayment Date:</span>{' '}
                    {moment(
                      repaymentBreakdown && repaymentBreakdown[0].dueDate,
                    ).format('ll')}
                  </p>
                </div>

                <div className="cash-repayment-table">
                  {data.application.policy !== null && (
                    <Select label="Duration Type" required disabled>
                      <option value="">
                        {data.application.policy.durationType}
                      </option>
                    </Select>
                  )}
                  <Input
                    label={'Enter Period of time'}
                    numberField={true}
                    placeholder={'Enter Period of time'}
                    required
                    {...applyMoratoriumOnApplicationInput.numberOfPeriods.input}
                    disabled={moratoriumCheckbox}
                  />
                  {data.application.policy !== null && (
                    <div className="duration-aside">
                      {getLoanDurationName(
                        data.application.policy.durationType,
                      ).toLowerCase()}
                    </div>
                  )}
                  <div className="moratoriumcheckbox-container">
                    <NewCheckBox
                      {...applyMoratoriumOnApplicationInput.wavieMoratorium
                        .value}
                      onChange={handleMoratoriumCheckBoxInput}
                      classes="moratoriumcheckbox"
                      label={'Waive all moratorium periods'}
                      disabled={
                        applyMoratoriumOnApplicationInput.numberOfPeriods.value
                      }
                    />
                  </div>

                  <div className="moratorium-button-container">
                    <div className="cancel">
                      <Button click_event={handleModalApplyMoratoriumToggle}>
                        Cancel
                      </Button>
                    </div>
                    <Button classes="secondary" type="submit">
                      Suspend
                    </Button>
                  </div>
                </div>
              </form>
            </Popup>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ApplyMoratoriumOnApplication;
