import { PageTitle } from '@/components/PageTitle';
import { Flex, Grid } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom-v6';

const NewCustomerPageTitle = () => {
  return (
    <Grid gap={4} h="100%" gridTemplateRows="max-content 1fr">
      <PageTitle showBackButton title={'New Customer'} />
    </Grid>
  );
};

export default NewCustomerPageTitle;
