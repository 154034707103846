import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  BreadCrumbSection,
  Button,
  Card,
  DataCard,
  Input,
  Select as AppSelect,
  Notification,
  Toast,
  Loader,
} from '../../components';
import { Plus, Close, CompleteIcon } from '../../components/Icons';
import FormInputs from './FormInputs';
import { formatForReactSelect } from '../../Providers-new/LoanRules/constants';

const NewRuleForm = ({
  form,
  data,
  nigeriaStates,
  appRuleTypes,
  loanRuleInView,
  handleCreateCustomRule,
  handleUpdateCustomRule,
  createCustomRuleLoading,
  updateCustomRuleLoading,
  ...other_props
}) => {
  const isEditMode = data.isEditMode.value;
  const { unrStepState, addNewUnrStep, removeUnrStep, handleSelectChangeUnr } =
    other_props;
  const { stepState, addNewStep, removeStep, handleSelectChange } = other_props;
  const { approvedEmploymentStatuses, underReviewEmploymentStatuses } =
    other_props;

  const { approvedBlacklistStatuses, underReviewBlacklistStatuses } =
    other_props;

  const {
    approvedFirstCentralDelinquency,
    underReviewFirstCentralDelinquency,
  } = other_props;

  const { approvedCrcDelinquency, underReviewCrcDelinquency } = other_props;

  const {
    approvedCreditRegistryDelinquency,
    underReviewCreditRegistryDelinquency,
  } = other_props;

  const { approvedBvnNinMatch, underReviewBvnNinMatch } = other_props;

  const [loanProductList] = useState([]);
  const [prefilledLoanProductList, updatePrefilledState] = useState([]);
  const [stateOfOrigin] = useState({ approved: [], underReview: [] });
  const [prefilledStatesApp, updatePrefilledStatesApp] = useState([]);
  const [prefilledStatesUnr, updatePrefilledStatesUnr] = useState([]);
  const [workVerificationStatus, updateworkVerificationStatus] = useState({
    approved: '',
    underReview: '',
  });
  const [bankHasMbsSupportStatus, updateBankHasMbsSupportStatus] = useState({
    approved: '',
    underReview: '',
  });

  const [inflowOutFlowRateStatus, updateInflowOutFlowRateStatus] = useState({
    approved: '',
    underReview: '',
  });
  const [sweepStatus, updateSweepStatus] = useState({
    approved: '',
    underReview: '',
  });
  const [salaryEarnerBSPStatus, updateSalaryEarnerBSPStatus] = useState({
    approved: '',
    underReview: '',
  });

  const [
    pdfBankStatementDetailsMatchStatus,
    updatePdfBankStatementDetailsMatchStatus,
  ] = useState({
    approved: '',
    underReview: '',
  });

  useEffect(() => {
    if (loanRuleInView) {
      const { approve, manualReview, policies } = loanRuleInView;
      const formattedData = formatForReactSelect(policies);
      updatePrefilledState(formattedData);

      if (approve && approve.length > 0) {
        // eslint-disable-next-line array-callback-return
        approve.map(({ data, type }) => {
          const { name } = type;

          if (name === 'stateOfResidency') {
            const appStateVal = formatForReactSelect(data.states);
            updatePrefilledStatesApp([...appStateVal]);
          }

          if (name === 'isWorkEmailConfirmed') {
            const approvedValue =
              data.isWorkEmailConfirmed === true
                ? 'yes'
                : data.isWorkEmailConfirmed === false
                  ? 'no'
                  : undefined;
            updateworkVerificationStatus(prevState => ({
              ...prevState,
              approved: approvedValue,
            }));
          }

          if (name === 'bankHasMbsSupport') {
            const approvedValue =
              data.bankHasMbsSupport === true
                ? 'yes'
                : data.bankHasMbsSupport === false
                  ? 'no'
                  : undefined;
            updateBankHasMbsSupportStatus(prevState => ({
              ...prevState,
              approved: approvedValue,
            }));
          }

          if (name === 'inflowOutFlowRateBSP') {
            const approvedValue =
              data.isCashFlowPositiveBSP === true
                ? 'yes'
                : data.isCashFlowPositiveBSP === false
                  ? 'no'
                  : undefined;
            updateInflowOutFlowRateStatus(prevState => ({
              ...prevState,
              approved: approvedValue,
            }));
          }

          if (name === 'accountSweepBSP') {
            const approvedValue =
              data.accountSweepBSP === true
                ? 'yes'
                : data.accountSweepBSP === false
                  ? 'no'
                  : undefined;
            updateSweepStatus(prevState => ({
              ...prevState,
              approved: approvedValue,
            }));
          }

          if (name === 'inflowOutFlowRateBSP') {
            const approvedValue =
              data.isCashFlowPositiveBSP === true
                ? 'yes'
                : data.isCashFlowPositiveBSP === false
                  ? 'no'
                  : undefined;
            updateInflowOutFlowRateStatus(prevState => ({
              ...prevState,
              approved: approvedValue,
            }));
          }

          if (name === 'salaryEarnerBSP') {
            const approvedValue =
              data.salaryEarnerBSP === true
                ? 'yes'
                : data.salaryEarnerBSP === false
                  ? 'no'
                  : undefined;
            updateSalaryEarnerBSPStatus(prevState => ({
              ...prevState,
              approved: approvedValue,
            }));
          }

          if (name === 'pdfBankStatementDetailsMatch') {
            const approvedValue =
              data.pdfBankStatementDetailsMatch === true
                ? 'yes'
                : data.pdfBankStatementDetailsMatch === false
                  ? 'no'
                  : undefined;
            updatePdfBankStatementDetailsMatchStatus(prevState => ({
              ...prevState,
              approved: approvedValue,
            }));
          }
        });
      }

      if (manualReview && manualReview.length > 0) {
        // eslint-disable-next-line array-callback-return
        manualReview.map(({ data, type }) => {
          const { name } = type;

          if (name === 'stateOfResidency') {
            const unrStateVal = formatForReactSelect(data.states);
            updatePrefilledStatesUnr([...unrStateVal]);
          }

          if (name === 'isWorkEmailConfirmed') {
            const underReviewValue =
              data.isWorkEmailConfirmed === true
                ? 'yes'
                : data.isWorkEmailConfirmed === false
                  ? 'no'
                  : undefined;
            updateworkVerificationStatus(prevState => ({
              ...prevState,
              underReview: underReviewValue,
            }));
          }

          if (name === 'bankHasMbsSupport') {
            const underReviewValue =
              data.bankHasMbsSupport === true
                ? 'yes'
                : data.bankHasMbsSupport === false
                  ? 'no'
                  : undefined;
            updateBankHasMbsSupportStatus(prevState => ({
              ...prevState,
              underReview: underReviewValue,
            }));
          }

          if (name === 'inflowOutFlowRateBSP') {
            const approvedValue =
              data.isCashFlowPositiveBSP === true
                ? 'yes'
                : data.isCashFlowPositiveBSP === false
                  ? 'no'
                  : undefined;
            updateInflowOutFlowRateStatus(prevState => ({
              ...prevState,
              underReview: approvedValue,
            }));
          }

          if (name === 'accountSweepBSP') {
            const underReviewValue =
              data.accountSweepBSP === true
                ? 'yes'
                : data.accountSweepBSP === false
                  ? 'no'
                  : undefined;
            updateSweepStatus(prevState => ({
              ...prevState,
              underReview: underReviewValue,
            }));
          }

          if (name === 'salaryEarnerBSP') {
            const underReviewValue =
              data.salaryEarnerBSP === true
                ? 'yes'
                : data.salaryEarnerBSP === false
                  ? 'no'
                  : undefined;
            updateSalaryEarnerBSPStatus(prevState => ({
              ...prevState,
              underReview: underReviewValue,
            }));
          }

          if (name === 'pdfBankStatementDetailsMatch') {
            const underReviewValue =
              data.pdfBankStatementDetailsMatch === true
                ? 'yes'
                : data.pdfBankStatementDetailsMatch === false
                  ? 'no'
                  : undefined;
            updatePdfBankStatementDetailsMatchStatus(prevState => ({
              ...prevState,
              underReview: underReviewValue,
            }));
          }
        });
      }
    }
  }, [loanRuleInView]);

  const handleWorkVerificationChange = params => {
    const { section, value } = params;

    section === 'app'
      ? updateworkVerificationStatus({
        ...workVerificationStatus,
        approved: value,
      })
      : updateworkVerificationStatus({
        ...workVerificationStatus,
        underReview: value,
      });
  };

  const handleBankHasMbsSupportChange = params => {
    const { section, value } = params;
    section === 'app'
      ? updateBankHasMbsSupportStatus({
        ...bankHasMbsSupportStatus,
        approved: value,
      })
      : updateBankHasMbsSupportStatus({
        ...bankHasMbsSupportStatus,
        underReview: value,
      });
  };

  const handleInflowOutFlowRateChange = params => {
    const { section, value } = params;
    section === 'app'
      ? updateInflowOutFlowRateStatus({
        ...inflowOutFlowRateStatus,
        approved: value,
      })
      : updateInflowOutFlowRateStatus({
        ...inflowOutFlowRateStatus,
        underReview: value,
      });
  };

  const handleSweepChange = params => {
    const { section, value } = params;
    section === 'app'
      ? updateSweepStatus({
        ...sweepStatus,
        approved: value,
      })
      : updateSweepStatus({
        ...sweepStatus,
        underReview: value,
      });
  };

  const handleSalaryEarnerBSPChange = params => {
    const { section, value } = params;
    section === 'app'
      ? updateSalaryEarnerBSPStatus({
        ...salaryEarnerBSPStatus,
        approved: value,
      })
      : updateSalaryEarnerBSPStatus({
        ...salaryEarnerBSPStatus,
        underReview: value,
      });
  };

  const handlePdfBankStatementDetailsMatchChange = params => {
    const { section, value } = params;
    section === 'app'
      ? updatePdfBankStatementDetailsMatchStatus({
        ...pdfBankStatementDetailsMatchStatus,
        approved: value,
      })
      : updatePdfBankStatementDetailsMatchStatus({
        ...pdfBankStatementDetailsMatchStatus,
        underReview: value,
      });
  };

  const handleStateOfOriginChange = params => {
    const { section, value } = params;

    section === 'app'
      ? updatePrefilledStatesApp(value)
      : updatePrefilledStatesUnr(value);
  };

  let responseParams = {
    workVerificationStatus: { ...workVerificationStatus },
    loanProductList: loanProductList.length >= 1 ? [...loanProductList] : [],
    prefilledLoanProductList:
      prefilledLoanProductList && prefilledLoanProductList.length >= 1
        ? [...prefilledLoanProductList]
        : [],
    prefilledStatesApp:
      prefilledStatesApp && prefilledStatesApp.length >= 1
        ? [...prefilledStatesApp]
        : [],
    prefilledStatesUnr:
      prefilledStatesUnr && prefilledStatesUnr.length >= 1
        ? [...prefilledStatesUnr]
        : [],
    bankHasMbsSupportStatus: { ...bankHasMbsSupportStatus },
    inflowOutFlowRateStatus: { ...inflowOutFlowRateStatus },
    sweepStatus: { ...sweepStatus },
    salaryEarnerBSPStatus: { ...salaryEarnerBSPStatus },
    pdfBankStatementDetailsMatchStatus: {
      ...pdfBankStatementDetailsMatchStatus,
    },
  };

  return (
    <>
      <BreadCrumbSection />
      {data.showNotificationPopup.value && (
        <Notification title="Success">
          <div className="notification_icon-holder">
            <CompleteIcon />
          </div>
          <h5 className={'center-text'}>
            {data.isEditMode.value
              ? 'Custom Rule Now Updated'
              : 'Rule Created Successfully'}
          </h5>
        </Notification>
      )}

      <Toast
        title="Loan Rule Set"
        content={data.content.value}
        classes={data.status.value}
        active={data.showToast.value}
      />

      <div className="loan_rules_wrapper step-one">
        <form
          onSubmit={e =>
            data.isEditMode.value
              ? handleUpdateCustomRule(e, responseParams)
              : handleCreateCustomRule(e, responseParams)
          }
        >
          <div className="form-card top-border">
            <DataCard>
              <div className="flex-container space-between">
                <div className="form-desc">
                  <Card title="Rule details">
                    Define the basic metadata which will be associated with the
                    rule
                  </Card>
                </div>
                <div className="form-fields">
                  <Card title="Name" required>
                    <Input
                      placeholder="name of rule"
                      {...form.name.input}
                      required
                    />
                  </Card>
                </div>
              </div>
            </DataCard>

            <DataCard>
              <div>
                <div className="form-desc">
                  <Card title="Custom Rules">
                    This section creates the application rules applicable for
                    each application step.
                  </Card>
                </div>
                <div className="form-fields toggle-field-section">
                  <Card title="Approved">
                    <div className="form-fields">
                      {stepState.length >= 1 &&
                        stepState.map((val, idx) => {
                          const editModeVal =
                            isEditMode && val.stepLabel
                              ? val.stepLabel
                              : val.stepType;
                          const stepType_defaultValue = val
                            ? editModeVal
                            : 'default';
                          const stepTypeId = `stepType-${idx}`;
                          return (
                            <div className="line-item" key={`stepType_${idx}`}>
                              <div
                                className="remove-step"
                                onClick={() => removeStep(idx, val)}
                              >
                                <Close
                                  height={'10px'}
                                  opacity={0.9}
                                  color={'#000000'}
                                />
                              </div>
                              <div
                                className="flex-container line-item__form"
                                key={`${idx}-`}
                              >
                                <div className="form-fields">
                                  <Card>
                                    <div className="flex-container">
                                      <AppSelect
                                        className="stepType"
                                        onChange={handleSelectChange}
                                        id={stepTypeId}
                                        data-idx={idx}
                                        name={stepTypeId}
                                      >
                                        <option
                                          defaultValue={stepType_defaultValue}
                                        >
                                          {stepType_defaultValue}
                                        </option>
                                        {appRuleTypes &&
                                          appRuleTypes.map(
                                            (item, key) =>
                                              item.status === 'ACTIVE' && (
                                                <option
                                                  key={`${item.name}_${key}`}
                                                  value={item.id}
                                                >
                                                  {item.label}
                                                </option>
                                              ),
                                          )}
                                      </AppSelect>

                                      <FormInputs
                                        type={val.stepType}
                                        nigeriaStates={nigeriaStates}
                                        section="app"
                                        form={form.approved}
                                        approvedEmploymentStatuses={
                                          approvedEmploymentStatuses
                                        }
                                        handleWorkVerificationChange={
                                          handleWorkVerificationChange
                                        }
                                        workVerificationStatus={
                                          workVerificationStatus
                                        }
                                        stateOfOrigin={stateOfOrigin}
                                        handleStateOfOriginChange={
                                          handleStateOfOriginChange
                                        }
                                        prefilledStatesApp={prefilledStatesApp}
                                        bankHasMbsSupportStatus={
                                          bankHasMbsSupportStatus
                                        }
                                        handleBankHasMbsSupportChange={
                                          handleBankHasMbsSupportChange
                                        }
                                        sweepStatus={sweepStatus}
                                        handleSweepChange={handleSweepChange}
                                        inflowOutFlowRateStatus={
                                          inflowOutFlowRateStatus
                                        }
                                        handleInflowOutFlowRateChange={
                                          handleInflowOutFlowRateChange
                                        }
                                        salaryEarnerBSPStatus={
                                          salaryEarnerBSPStatus
                                        }
                                        handleSalaryEarnerBSPChange={
                                          handleSalaryEarnerBSPChange
                                        }
                                        approvedFirstCentralDelinquency={
                                          approvedFirstCentralDelinquency
                                        }
                                        approvedCrcDelinquency={
                                          approvedCrcDelinquency
                                        }
                                        handlePdfBankStatementDetailsMatchChange={
                                          handlePdfBankStatementDetailsMatchChange
                                        }
                                        pdfBankStatementDetailsMatchStatus={
                                          pdfBankStatementDetailsMatchStatus
                                        }
                                        approvedBlacklistStatuses={
                                          approvedBlacklistStatuses
                                        }
                                        approvedCreditRegistryDelinquency={
                                          approvedCreditRegistryDelinquency
                                        }
                                        approvedBvnNinMatch={
                                          approvedBvnNinMatch
                                        }
                                      />
                                    </div>
                                  </Card>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <div className="form-fields add-more">
                        <div
                          className="flex-container add-new-step"
                          onClick={() => addNewStep()}
                        >
                          <Plus /> Add new rule
                        </div>
                      </div>
                    </div>
                  </Card>

                  <Card title="Under Review">
                    <div className="form-fields">
                      {unrStepState.length >= 1 &&
                        unrStepState.map((item, idx) => {
                          const editModeVal =
                            isEditMode && item.stepLabel
                              ? item.stepLabel
                              : item.stepType;
                          const unrStepType_defaultValue = item
                            ? editModeVal
                            : 'default';
                          const unrStepTypeId = `stepType-${idx}`;

                          return (
                            <div key={unrStepTypeId} className="line-item">
                              <div
                                className="remove-step"
                                onClick={() => removeUnrStep(idx, item)}
                              >
                                <Close
                                  height={'10px'}
                                  opacity={0.9}
                                  color={'#000000'}
                                />
                              </div>
                              <div
                                className="flex-container line-item__form"
                                key={`${idx}-`}
                              >
                                <div className="form-fields">
                                  <Card>
                                    <div className="flex-container">
                                      <AppSelect
                                        className="stepType"
                                        onChange={handleSelectChangeUnr}
                                        id={unrStepTypeId}
                                        data-idx={idx}
                                        name={unrStepTypeId}
                                      >
                                        <option
                                          defaultValue={
                                            unrStepType_defaultValue
                                          }
                                        >
                                          {unrStepType_defaultValue}
                                        </option>
                                        {appRuleTypes &&
                                          appRuleTypes.map(
                                            (item, key) =>
                                              item.status === 'ACTIVE' && (
                                                <option
                                                  key={`${item.name}_${key}`}
                                                  value={item.id}
                                                >
                                                  {item.label}
                                                </option>
                                              ),
                                          )}
                                      </AppSelect>

                                      <FormInputs
                                        type={item.stepType}
                                        nigeriaStates={nigeriaStates}
                                        section="unr"
                                        form={form.underReview}
                                        underReviewEmploymentStatuses={
                                          underReviewEmploymentStatuses
                                        }
                                        handleWorkVerificationChange={
                                          handleWorkVerificationChange
                                        }
                                        workVerificationStatus={
                                          workVerificationStatus
                                        }
                                        stateOfOrigin={stateOfOrigin}
                                        handleStateOfOriginChange={
                                          handleStateOfOriginChange
                                        }
                                        prefilledStatesUnr={prefilledStatesUnr}
                                        bankHasMbsSupportStatus={
                                          bankHasMbsSupportStatus
                                        }
                                        handleBankHasMbsSupportChange={
                                          handleBankHasMbsSupportChange
                                        }
                                        sweepStatus={sweepStatus}
                                        handleSweepChange={handleSweepChange}
                                        inflowOutFlowRateStatus={
                                          inflowOutFlowRateStatus
                                        }
                                        handleInflowOutFlowRateChange={
                                          handleInflowOutFlowRateChange
                                        }
                                        salaryEarnerBSPStatus={
                                          salaryEarnerBSPStatus
                                        }
                                        handleSalaryEarnerBSPChange={
                                          handleSalaryEarnerBSPChange
                                        }
                                        underReviewFirstCentralDelinquency={
                                          underReviewFirstCentralDelinquency
                                        }
                                        underReviewCrcDelinquency={
                                          underReviewCrcDelinquency
                                        }
                                        handlePdfBankStatementDetailsMatchChange={
                                          handlePdfBankStatementDetailsMatchChange
                                        }
                                        pdfBankStatementDetailsMatchStatus={
                                          pdfBankStatementDetailsMatchStatus
                                        }
                                        underReviewBlacklistStatuses={
                                          underReviewBlacklistStatuses
                                        }
                                        underReviewCreditRegistryDelinquency={
                                          underReviewCreditRegistryDelinquency
                                        }
                                        underReviewBvnNinMatch={
                                          underReviewBvnNinMatch
                                        }
                                      />
                                    </div>
                                  </Card>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <div className="form-fields add-more">
                        <div
                          className="flex-container add-new-step"
                          onClick={() => addNewUnrStep()}
                        >
                          <Plus /> Add new rule
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </DataCard>
          </div>
          <div className="action_button container_wrapper flex-container space-between">
            <Link to="/settings/loan-rules">
              <Button classes="primary">Cancel</Button>
            </Link>
            {createCustomRuleLoading || updateCustomRuleLoading ? (
              <Loader loadingText="loading..." />
            ) : (
              <Button classes="secondary" type="submit">
                {data.isEditMode.value ? 'Update Changes' : 'Create New Rule'}
              </Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default NewRuleForm;
