import React from 'react';
import './_Toggle.scss';

const Toggle = ({ ...restProps }) => {
  return (
    <label className="toggle">
      <input type="checkbox" {...restProps} />
      <span className="slider" />
    </label>
  );
};

export default Toggle;
