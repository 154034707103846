import { gql } from 'apollo-boost';

export const FETCH_FROM_S3_QUERY = gql`
  query MP_FetchFromS3($bucket: String!, $key: String!) {
    fetchFromS3(bucket: $bucket, key: $key) {
      dataUrl
      status
    }
  }
`;
