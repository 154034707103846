import { gql } from 'apollo-boost';

export const SET_APPLICATION_BANK_ACCOUNT = gql`
  mutation MP_SetApplicationBankAccount($input: ApplicationBankStageInput!) {
    setApplicationBankAccount(input: $input) {
      success
      mbsFeedback {
        status
        message
      }
    }
  }
`;
