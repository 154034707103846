import React, { Fragment } from 'react';
import { DataCard } from '../../../../components';
import {
  capitalizeInitialLetter,
  removeUnderscore,
  reduceStringLength,
  removeHyphen,
} from '../../../../lib/Utils';
import moment from 'moment';

const TracesTab = ({ data }) => {
  return (
    <Fragment>
      <div className="history-timeline">
        <DataCard noPadding={false}>
          <div className="timeline">
            <div className="timeline-body">
              <div className="timeline-item">
                <ul>
                  {data &&
                    data?.application &&
                    data?.application?.traces?.nodes?.map((tracesData, key) => (
                      <li key={key}>
                        <div className="timeline-content">
                          <p>
                            {tracesData?.comment
                              ? removeUnderscore(
                                  reduceStringLength(tracesData.comment),
                                )
                              : 'N/A'}{' '}
                          </p>
                          <div className="history_info">
                            {tracesData.createdAt ? (
                              <p className="info">
                                <span className="title">Date:</span>
                                {moment(tracesData.createdAt).format('lll')}
                              </p>
                            ) : (
                              ''
                            )}

                            <p className="info">
                              <span className="title">Page:</span>
                              {capitalizeInitialLetter(
                                removeHyphen(tracesData?.page),
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default TracesTab;
