import { gql } from 'apollo-boost';

export const UPDATE_ATTRIBUTES = gql`
  mutation MP_UpdateAttributes($input: AttributesInput!, $token: String!) {
    neutron(token: $token) {
      updateAttributes(input: $input) {
        success
        accountId
        updateAttributeErrors
      }
    }
  }
`;

export const GET_MERCHANT_ATTRIBUTES_BY_SLUG = gql`
  mutation MP_GetMerchantAttributesBySlug(
    $input: GetMerchantAttributesBySlugInput!
    $token: String!
  ) {
    neutron(token: $token) {
      getMerchantAttributesBySlug(input: $input) {
        success
        accountAttributes {
          id
          attribute {
            id
            name
            default
            options
            required
            type
            isOverwritable
            data
            category
            label
          }
          value
          data
        }
      }
    }
  }
`;

export const UPLOAD_ATTRIBUTE = gql`
  mutation MP_UploadToS3($input: S3Input!) {
    uploadToS3(input: $input) {
      file {
        url
      }
      s3FileId
      status
    }
  }
`;
