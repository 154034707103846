import { gql } from 'apollo-boost';

export const UPDATE_ATTRIBUTES = gql`
  mutation MP_UpdateAttributes($input: AttributesInput!, $token: String!) {
    neutron(token: $token) {
      updateAttributes(input: $input) {
        success
        accountId
        updateAttributeErrors
      }
    }
  }
`;
