import { graphql } from '@/__generated/gql';

export const CUSTOMER_SUMMARY = graphql(/* GraphQL */ `
  query CustomerSummary {
    customerSummary {
      aged
      middleAged
      youngAdults
      total
    }
  }
`);
