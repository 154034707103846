import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PRESIGNED_URL_FROM_S3_QUERY } from '../graphql/queries';

const UrlFromS3Provider = ({ children, file }) => {
  const { bucket, key, url } = file;

  const [openFile, { loading, error, data }] = useLazyQuery(
    GET_PRESIGNED_URL_FROM_S3_QUERY,
    {
      variables: { bucket, key, url },
      fetchPolicy: 'network-only',
      onCompleted() {
        if (data) {
          const { getPresignedUrlFromS3 } = data;
          props.getPresignedUrlFromS3 = getPresignedUrlFromS3;
          props.fileUrl = getPresignedUrlFromS3?.dataUrl;
          const url = getPresignedUrlFromS3?.dataUrl;
          const newTab = window.open();
          newTab.location.href = url;
        }
      },
    },
  );

  const exportFromS3 = () => {
    openFile({
      variables: { bucket, key },
    });
  };

  const props = {
    loading,
    error,
    exportFromS3,
    fileUrl: '',
  };

  return children(props);
};

export default UrlFromS3Provider;
