import { CHANGE_APPLICATION_POLICY_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { APPLICATION_QUERY } from '../graphql/queries';

const ChangeApplicationPolicyProvider = ({ application }) => {
  const applicationPolicyInput = {
    applicationId: useCustomState(),
    policyId: useCustomState(),
    comments: useCustomState(),
    content: useCustomState(),
    status: useCustomState(),
    showToast: useCustomState(false),
    showPolicyPopup: useCustomState(false),
    policyChangeDesc: useCustomState(),
  };

  const [handleChangeApplicationPolicyMutation, { loading }] = useMutation(
    CHANGE_APPLICATION_POLICY_MUTATION,
    {
      async onCompleted() {
        applicationPolicyInput.status.setValue('Success');
        applicationPolicyInput.showToast.setValue(true);
        applicationPolicyInput.content.setValue('Policy Changed');
        handleHideToast();
        setTimeout(() => {
          applicationPolicyInput.showPolicyPopup.setValue(false);
        }, 3500);
      },

      onError(error) {
        applicationPolicyInput.status.setValue('Failed');
        applicationPolicyInput.showToast.setValue(true);
        applicationPolicyInput.content.setValue(
          trimGraphQLError(error.message),
        );
        handleHideToast();
      },
    },
  );

  const getChangeDescription = policyData => {
    let policies = policyData && policyData?.viewer?.account?.policies?.nodes;

    if (applicationPolicyInput.policyId.input.value) {
      const selectedPolicyId = applicationPolicyInput.policyId.input.value;
      policies = policies?.filter(item => item.id === selectedPolicyId)[0];
      applicationPolicyInput.policyChangeDesc.setValue(policies);
    }

    if (application && !applicationPolicyInput.applicationId.value) {
      applicationPolicyInput.applicationId.setValue(application.id);
      return;
    }

    return;
  };

  const handleHideToast = () => {
    setTimeout(() => {
      applicationPolicyInput.showToast.setValue(false);
    }, 3000);
  };

  const showChangePolicyPopup = e => {
    e.preventDefault();

    applicationPolicyInput.showPolicyPopup.setValue(true);
  };

  const hideChangePolicyPopup = () => {
    applicationPolicyInput.showPolicyPopup.setValue(false);
  };

  const handleChangeApplicationPolicy = async (
    e,
    data,
    handleChangeApplicationPolicyMutation,
  ) => {
    e.preventDefault();

    if (!data || !data.policyId || !data.applicationId) {
      applicationPolicyInput.showToast.setValue(true);
      applicationPolicyInput.content.setValue('Select a Policy');
      handleHideToast(e);
      return;
    }

    await handleChangeApplicationPolicyMutation({
      variables: {
        policyId: data ? data.policyId : '',
        applicationId: data ? data.applicationId : '',
      },
      refetchQueries: () => [
        {
          query: APPLICATION_QUERY,
          variables: { applicationNumber: application.applicationNumber },
        },
      ],
    });
  };

  return {
    loading,
    applicationPolicyInput,
    handleHideToast,
    handleChangeApplicationPolicy: (e, data) =>
      handleChangeApplicationPolicy(
        e,
        data,
        handleChangeApplicationPolicyMutation,
      ),
    showChangePolicyPopup: e => showChangePolicyPopup(e),
    hideChangePolicyPopup: e => hideChangePolicyPopup(e),
    getChangeDescription: policyData => getChangeDescription(policyData),
  };
};

export default ChangeApplicationPolicyProvider;
