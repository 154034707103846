import { gql } from 'apollo-boost';

export const EXPORT_TO_CSV = gql`
  query exportToCsv($input: ExportToCsvInput!) {
    exportToCsv(input: $input) {
      dataUrl
      mimeType
    }
  }
`;
