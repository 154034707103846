import { useMutation } from '@apollo/client';

import { MP_SET_APPLICATION_BANK_ACCOUNT } from '@/graphql/mutations/bank';
import { APPLICATION_DETAILS } from '@/graphql/queries/applications/applicationDetails';
import { MpSetApplicationBankAccountInput } from '@/__generated/graphql';

export const useMpSetApplicationBankAccount = () => {
  const [mutate, { data, loading, error }] = useMutation(
    MP_SET_APPLICATION_BANK_ACCOUNT,
    {
      refetchQueries: ({ data }) => [
        {
          query: APPLICATION_DETAILS,
          variables: {
            applicationNumber:
              data.mpSetApplicationBankAccount.applicationNumber,
          },
        },
      ],
      awaitRefetchQueries: true,
      errorPolicy: 'all',
      onError: () => {},
    },
  );

  const mpSetApplicationBankAccount = ({
    applicationId,
    bankAccountId,
    newBankAccount,
  }: MpSetApplicationBankAccountInput) => {
    mutate({
      variables: {
        input: {
          applicationId,
          bankAccountId,
          newBankAccount,
        },
      },
    });
  };

  return {
    mpSetApplicationBankAccount,
    mpSetApplicationBankAccountError: error,
    mpSetApplicationBankAccountLoading: loading,
    mpSetApplicationBankAccountResponse: data?.mpSetApplicationBankAccount,
  };
};
