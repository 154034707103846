import React, { Fragment } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import { ApplicationHistoryTab, TracesTab } from '..';
import { useQuery } from '@apollo/react-hooks';
import { GET_ENUM } from '../../../../Providers-new/EnumTypes';

const ActivitiesTab = ({
  data,
  applicationHistory,
  showChangeRuleBreakDownPopup,
  applicationHistoryPagination,
  applicationHistoryLoading,
  runSearchQuery,
  applicationHistoryInput,
}) => {
  const { data: applicationStatus } = useQuery(GET_ENUM, {
    variables: {
      type: 'ApplicationStatus',
    },
  });

  let applicationStatuses = [];

  if (applicationStatus) applicationStatuses = applicationStatus.enumValues;

  return (
    <Fragment>
      <Tabs>
        <TabList>
          <Tab tabFor={'application-history'}>History</Tab>
          <Tab tabFor={'traces'}>Traces</Tab>
        </TabList>

        <TabPanel tabId={'application-history'}>
          <ApplicationHistoryTab
            data={data}
            applicationHistory={applicationHistory}
            showChangeRuleBreakDownPopup={showChangeRuleBreakDownPopup}
            applicationHistoryPagination={applicationHistoryPagination}
            applicationHistoryLoading={applicationHistoryLoading}
            runSearchQuery={runSearchQuery}
            applicationHistoryInput={applicationHistoryInput}
            applicationStatuses={applicationStatuses}
          />
        </TabPanel>

        <TabPanel tabId={'traces'}>
          <TracesTab data={data} />
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default ActivitiesTab;
