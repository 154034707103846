import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { OriginateLogo } from '../Icons/';
import HeaderNavs from './headernavs/HeaderNavs';
import HeaderProfile from './headerProfile/HeaderProfile';
import './_Header.scss';

const Header = () => {
  return (
    <Fragment>
      <header className={'header'}>
        <div className="logo">
          {
            <NavLink to="/v1/applications">
              <OriginateLogo />
            </NavLink>
          }
        </div>
        <HeaderNavs />
        <HeaderProfile />
      </header>
    </Fragment>
  );
};

export default withRouter(Header);
