import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ATTRIBUTES } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { useEffect } from 'react';
import { useClientInfo } from '../Client';
import { useGetAttributesBySlug } from './useGetAttributesBySlug';
import { tr } from 'date-fns/locale';

const useUpdateNotificationPrefrences = () => {
  const sessionToken = localStorage.getItem('api-key');

  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    customerApplicationApprovalSms: useFormInput(),
    customerApplicationDeniedSms: useFormInput(),
    customerApplicationReviewSms: useFormInput(),
    customerLoanDisbursedSms: useFormInput(),
    customerLoanRepaymentSms: useFormInput(),
    customerApplicationApprovalEmail: useFormInput(),
    customerApplicationDeniedEmail: useFormInput(),
    customerApplicationReviewEmail: useFormInput(),
    customerLoanDisbursedEmail: useFormInput(),
    customerLoanRepaymentEmail: useFormInput(),
  };

  const payload = {
    customerNotificationPreference: {
      applicationApproved: {
        email: inputs.customerApplicationApprovalEmail.value || false,
        sms: inputs.customerApplicationApprovalSms.value || false,
      },
      applicationDenied: {
        email: inputs.customerApplicationDeniedEmail.value || false,
        sms: inputs.customerApplicationDeniedSms.value || false,
      },
      applicationReview: {
        email: inputs.customerApplicationReviewEmail.value || false,
        sms: inputs.customerApplicationReviewSms.value || false,
      },
      loanDisbursed: {
        email: inputs.customerLoanDisbursedEmail.value || false,
        sms: inputs.customerLoanDisbursedSms.value || false,
      },
      loanRepayment: {
        email: inputs.customerLoanRepaymentEmail.value || false,
        sms: inputs.customerLoanRepaymentSms.value || false,
      },
    },
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const { clientInfo, loading: cisLoading } = useClientInfo({
    privateFields: false,
  });

  useEffect(() => {
    const notificationPrefrence = clientInfo?.customerNotificationPreference;

    const setInputValues = () => {
      if (!notificationPrefrence) return;

      const fields = [
        {
          category: 'applicationApproved',
          type: 'sms',
          input: 'customerApplicationApprovalSms',
        },
        {
          category: 'applicationApproved',
          type: 'email',
          input: 'customerApplicationApprovalEmail',
        },
        {
          category: 'applicationDenied',
          type: 'sms',
          input: 'customerApplicationDeniedSms',
        },
        {
          category: 'applicationDenied',
          type: 'email',
          input: 'customerApplicationDeniedEmail',
        },
        {
          category: 'applicationReview',
          type: 'sms',
          input: 'customerApplicationReviewSms',
        },
        {
          category: 'applicationReview',
          type: 'email',
          input: 'customerApplicationReviewEmail',
        },
        {
          category: 'loanDisbursed',
          type: 'sms',
          input: 'customerLoanDisbursedSms',
        },
        {
          category: 'loanDisbursed',
          type: 'email',
          input: 'customerLoanDisbursedEmail',
        },
        {
          category: 'loanRepayment',
          type: 'sms',
          input: 'customerLoanRepaymentSms',
        },
        {
          category: 'loanRepayment',
          type: 'email',
          input: 'customerLoanRepaymentEmail',
        },
      ];

      fields.forEach(({ category, type, input }) => {
        if (notificationPrefrence[category]?.[type]) {
          inputs[input].setValue(notificationPrefrence[category][type]);
        }
      });
    };
    setInputValues();
  }, [clientInfo]);

  const handleUpdateNotificationPreferences = async e => {
    e.preventDefault();

    await requestUpdateNotificationPreferences({
      variables: {
        input: {
          accountId: clientInfo.clientId,
          ...payload,
        },
        token: sessionToken,
      },
    });
  };

  const [requestUpdateNotificationPreferences, { loading }] = useMutation(
    UPDATE_ATTRIBUTES,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue(
          'Updated Customer Notification Prefrence Successfully',
        );
        data.showModal.setValue(false);

        handleHideToast();
      },
      onError,
    },
  );

  return {
    handleUpdateNotificationPreferences,
    loading,
    cisLoading,
    inputs,
    data,
    handleHideToast,
  };
};

export { useUpdateNotificationPrefrences };
