import { Query } from '@apollo/react-components';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connection_limit } from '../config/config';
import { TRANSACTIONS_QUERY } from '../graphql/queries';
import Constants from '../lib/Constants';

const DisburseTableProvider = ({ render, setFilterParams }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchMoreDisburseWallet = (e, fetchMore, data) => {
    e.preventDefault();
    setIsLoading(true);
    fetchMore({
      variables: {
        cursor:
          data.getWallets.account.wallets.disbursementWallet.transactions
            .pageInfo.endCursor,
        first: connection_limit.value,
        ...setFilterParams,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoading(false);
        const newNodes =
          fetchMoreResult.getWallets.account.wallets.disbursementWallet
            .transactions.nodes;
        const pageInfo =
          fetchMoreResult.getWallets.account.wallets.disbursementWallet
            .transactions.pageInfo;
        const oldData =
          prev.getWallets.account.wallets.disbursementWallet.transactions.nodes;
        return newNodes.length
          ? {
              getWallets: {
                __typename: prev.getWallets.__typename,
                account: {
                  __typename: prev.getWallets.account.__typename,
                  wallets: {
                    __typename: prev.getWallets.account.wallets.__typename,
                    disbursementWallet: {
                      __typename:
                        prev.getWallets.account.wallets.disbursementWallet
                          .__typename,
                      transactions: {
                        __typename:
                          prev.getWallets.account.wallets.disbursementWallet
                            .transactions.__typename,
                        nodes: [...oldData, ...newNodes],
                        pageInfo,
                      },
                    },
                  },
                },
              },
            }
          : prev;
      },
    });
  };

  return (
    <Query
      query={TRANSACTIONS_QUERY}
      variables={{
        first: connection_limit.value,
        disbursementWalletType: Constants.DISBURSEMENT_TRANSACTION_TYPE,
      }}
    >
      {({ data, fetchMore }) => {
        return render({
          disburseTableData: data,
          isLoading,
          handlePagination: e =>
            handleFetchMoreDisburseWallet(e, fetchMore, data),
        });
      }}
    </Query>
  );
};

export default withRouter(DisburseTableProvider);
