import React from 'react';
import './_Login.scss';
import { Input, Button, OriginateLogo, Toast } from '../../components/';
import { Link, withRouter } from 'react-router-dom';
import { LoginProvider } from '../../Providers';

const Login = () => (
  <>
    <LoginProvider
      render={({ onSubmit, input }) => (
        <section className={'login_page'}>
          <section className={'login_logo'}>
            <OriginateLogo />
          </section>
          <main className={'login_holder'}>
            <h3 className={'center-text'}>Login</h3>
            <Toast
              title="Login"
              content={input.content.value}
              classes={input.status.value}
              active={input.showToast.value}
            />
            <form onSubmit={onSubmit}>
              <Input
                data-testid="email-field"
                type="email"
                label="Email"
                placeholder="company@mail.com"
                {...input.email.input}
              />
              <Input
                data-testid="password-field"
                type="password"
                label="Password"
                placeholder="**********"
                {...input.password.input}
              />
              <Button
                data-testid="login-button"
                classes={'block secondary'}
                type="submit"
              >
                Login to your account
              </Button>

              <div className="login_link">
                <Link to="/trigger-reset-password">Forgot Password?</Link>
              </div>
            </form>
          </main>
        </section>
      )}
    />
  </>
);

export default withRouter(Login);
