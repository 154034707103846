import { overrides } from '@/lib/theme';
import { extendTheme } from '@chakra-ui/react';
import { CalendarDefaultTheme } from '@uselessdev/datepicker';

export const calendarTheme = extendTheme(CalendarDefaultTheme, {
  ...overrides,
  components: {
    Calendar: {
      parts: ['calendar'],

      baseStyle: {
        calendar: {
          borderRadius: 0,
        },
      },
    },

    CalendarDay: {
      baseStyle: {
        fontWeight: 'normal',
      },

      variants: {
        selected: {
          bgColor: 'blue.400',
          color: 'white',

          _hover: {
            bgColor: 'blue.300',
          },
        },
        range: {
          bgColor: 'blue.200',
          color: 'white',

          _hover: {
            bgColor: 'blue.100',
          },

          _disabled: {
            _hover: {
              bgColor: 'blue.300',
            },
          },
        },
        today: {
          bgColor: 'gray.100',
          _hover: {
            bgColor: 'blue.100',
          },
        },
      },
    },
  },
});
