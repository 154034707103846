import React, { Fragment } from 'react';
import { AdvanceApplicationWorkFlowStep } from '../../../../Providers';
import {
  Button,
  DataCard,
  Loader,
  Modal,
  TextArea,
  Toast,
} from '../../../../components';
import { Close } from '../../../../components/Icons';
import { capitalizeInitialLetter } from '../../../../lib/Utils';

const AdvanceApplicationWorkFlowSteps = ({ data }) => {
  return (
    <Fragment>
      <AdvanceApplicationWorkFlowStep
        application={data.application}
        render={({
          input,
          completeReviewLoader,
          showCompleteApplicationReviewPopup,
          handleCompleteApplication,
          handleHideToast,
          handleHidePopup,
        }) => (
          <Fragment>
            {completeReviewLoader && <Loader loadingText="loading..." />}
            <Modal
              title={capitalizeInitialLetter(
                data.application.approvalWorkflow.nextStep.approvalType,
              )}
              classes="complete-review-popup"
              active={input.showPopup.value}
            >
              <Close className="close-icon" onClick={e => handleHidePopup(e)} />

              <DataCard noPadding={true}>
                <span className="optional">(optional)</span>
                <TextArea
                  cols={1}
                  rows={7}
                  label="Add Comment"
                  placeholder="Comments"
                  {...input.comment.input}
                />
                {data.application.approvalWorkflow.nextStep.approvalType ===
                'DISBURSE'
                  ? data.application &&
                    data.application.status &&
                    data.application.reviewFeedbacks &&
                    data.application.reviewFeedbacks.find(
                      feedback =>
                        feedback.reviewType === 'OFFER_LETTER_REQUEST' &&
                        feedback.status !== 'PENDING',
                    ) &&
                    data.application.status.name !== 'APPROVED' && (
                      <Button
                        classes={`complete-review ${data.application.approvalWorkflow.nextStep.approvalType.toLowerCase()}`}
                        click_event={handleCompleteApplication}
                      >
                        {' '}
                        Disburse Loan
                      </Button>
                    )
                  : data.application.status.name !== 'APPROVED' && (
                      <Button
                        classes={`complete-review ${data.application.approvalWorkflow.nextStep.approvalType.toLowerCase()}`}
                        click_event={handleCompleteApplication}
                      >
                        {(() => {
                          const approvalType =
                            data.application.approvalWorkflow.nextStep
                              .approvalType;

                          switch (approvalType) {
                            case 'REVIEW':
                              return 'Complete Review';
                            case 'APPROVE':
                              return 'Approve Loan';
                            default:
                              return '';
                          }
                        })()}
                      </Button>
                    )}
              </DataCard>
            </Modal>
            <Toast
              title={`${capitalizeInitialLetter(
                data.application.approvalWorkflow.nextStep.approvalType,
              )} Loan`}
              content={input.content.value}
              classes={input.status.value}
              active={input.showToast.value}
              clickEvent={handleHideToast}
            />
            {data.application.approvalWorkflow.nextStep.approvalType ===
            'DISBURSE'
              ? data.application &&
                data.application.status &&
                data.application.reviewFeedbacks &&
                data.application.reviewFeedbacks.find(
                  feedback =>
                    feedback.reviewType === 'OFFER_LETTER_REQUEST' &&
                    feedback.status !== 'PENDING',
                ) &&
                data.application.status.name !== 'APPROVED' && (
                  <Button
                    classes={`right ${data.application.approvalWorkflow.nextStep.approvalType.toLowerCase()}`}
                    click_event={showCompleteApplicationReviewPopup}
                  >
                    {' '}
                    Disburse Loan{' '}
                  </Button>
                )
              : data.application.status.name !== 'APPROVED' && (
                  <Button
                    classes={`right ${data.application.approvalWorkflow.nextStep.approvalType.toLowerCase()}`}
                    click_event={showCompleteApplicationReviewPopup}
                  >
                    {(() => {
                      const approvalType =
                        data.application.approvalWorkflow.nextStep.approvalType;

                      switch (approvalType) {
                        case 'REVIEW':
                          return 'Complete Review';
                        case 'APPROVE':
                          return 'Approve Loan';
                        default:
                          return '';
                      }
                    })()}
                  </Button>
                )}
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default AdvanceApplicationWorkFlowSteps;
