import { useMutation } from '@apollo/react-hooks';
import { REVIEW_SUPPORTING_DOCUMENT } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { APPLICATION_QUERY } from '../../graphql/queries';

const useMutateReviewSupportingDocuments = ({ application }) => {
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
    showNotificationPopup: useKeyPair(false),
  };

  const inputs = {
    documentId: useFormInput(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const handleHideNotification = () => {
    setTimeout(() => {
      data.showNotificationPopup.setValue(false);
    }, 4000);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
    handleModalToggle();
  };

  const handleReviewSupportingDocument = async e => {
    e.preventDefault();

    const documentRequestId = inputs.documentId.value;

    await reviewSupportingDocument({
      variables: {
        input: {
          documentRequestId: documentRequestId,
        },
      },
      refetchQueries: () => [
        {
          query: APPLICATION_QUERY,
          variables: { applicationNumber: application.applicationNumber },
        },
      ],
    });
  };

  const handleModalToggle = async (e, requestId) => {
    if (requestId) inputs.documentId.setValue(requestId);
    data.showModal.setValue(!data.showModal.value);
  };

  const [reviewSupportingDocument, { loading }] = useMutation(
    REVIEW_SUPPORTING_DOCUMENT,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Supporting Document Requested');
        data.showModal.setValue(false);
        data.showNotificationPopup.setValue(true);

        handleHideToast();
        handleHideNotification();
      },
      onError,
    },
  );

  return {
    handleHideToast,
    loading,
    handleModalToggle,
    data,
    inputs,
    handleReviewSupportingDocument,
  };
};

export { useMutateReviewSupportingDocuments };
