import React from 'react';
import { Link } from 'react-router-dom';
import { DataCard } from '../../components';
import Header from '../Header/Header';
import './_Layout.scss';

const NotFound = ({ header }) => {
  const authentication = localStorage.getItem('api-key');

  return (
    <section className="layout">
      {authentication && header ? <Header /> : null}

      <section className="not-found">
        <DataCard>
          <h1>404</h1>
          <h3>Page not found</h3>

          {!authentication ? (
            <Link to="/" className="button">
              Return to Login
            </Link>
          ) : (
            <Link to="/loans" className="button">
              Return Home
            </Link>
          )}
        </DataCard>
      </section>
    </section>
  );
};

export default NotFound;
