import { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useCustomState } from '../lib/CustomHooks';
import { UPLOAD_SUPPORTING_DOCUMENT_AND_SAVE_TO_APPLICATION_META_MUTATION } from '../graphql/mutations';
import { trimGraphQLError } from '../lib/Utils';

const UploadSupportingDocument = ({
  render,
  applicationId,
  fileName,
  user,
  userId,
  setFileName,
}) => {
  const [uploadDocumentError, setUploadDocumentError] = useState(null);
  const [documentDetails, setDocumentDetails] = useState({});
  const [uploadDocumentSuccess, setUploadDocumentSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const data = {
    showUploadPopup: useCustomState(false),
    showSuccessPopup: useCustomState(false),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };
  const history = useHistory();

  const [
    mutate,
    { data: uploadSupportingDocument, loading: uploadDocumentLoading },
  ] = useMutation(
    UPLOAD_SUPPORTING_DOCUMENT_AND_SAVE_TO_APPLICATION_META_MUTATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ uploadSupportingDocumentAndSaveToApplicationMeta }) => {
        if (uploadSupportingDocumentAndSaveToApplicationMeta.status === true) {
          setUploadDocumentSuccess(
            'You have successfully uploaded the following documents',
          );
          hideUploadPopup();
          handleSuccessPopup();
        } else {
          data.showToast.setValue(true);
          data.status.setValue('Failed');
          data.content.setValue(
            'There was an error uploading the document, please try again.',
          );
          handleHideToast();
        }
      },
      onError: error => {
        const errorMessage = error.message
          ? trimGraphQLError(error.message)
          : {};
        setUploadDocumentError(
          typeof errorMessage === 'string'
            ? errorMessage
            : 'There was an error uploading the document, please try again',
        );
      },
    },
  );

  const bytesToSize = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';

    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;

    return `${(bytes / 1000 ** i).toFixed(0)} ${sizes[i]}`;
  };

  const uploadDocument = useCallback(
    async (selectedFile, documentName, userId, applicationId) => {
      if (!selectedFile) {
        setUploadDocumentError('Please select a document for upload');
      } else {
        const { validity, file } = selectedFile.fileDetails;
        validity &&
          mutate({
            variables: { file, documentName, userId, applicationId },
          });
      }
    },
    [],
  );

  let userFirstName = '';
  let userLastName = '';
  if (user) {
    userFirstName = user.firstName;
    userLastName = user.lastName;
  }

  const handleUpload = useCallback(() => {
    const documentName = `${fileName}_${userFirstName}_${userLastName}`;
    uploadDocument(documentDetails, documentName, userId, applicationId);
  }, [documentDetails, fileName]);

  let ext = '';
  if (uploadSupportingDocument) {
    ext =
      uploadSupportingDocument.uploadSupportingDocumentAndSaveToApplicationMeta.file.key
        .split('.')
        .pop();
  }

  const handleDocumentPreview = useCallback((e, setDocumentDetails) => {
    const { validity, files } = e.target;
    const file = files[0];
    const reader = new FileReader();

    setUploadDocumentError('');

    if (file && file.size > 10000000) {
      setUploadDocumentError('Please, upload a file lower than 10 MB');
      return;
    }

    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setDocumentDetails({
          url: reader.result,
          fileDetails: { file, validity: validity.valid },
          fileSize: bytesToSize(file.size),
        });
      };
    }
  }, []);

  const handlePreview = e => {
    handleDocumentPreview(e, setDocumentDetails);
  };

  const handleUploadPopup = e => {
    e.preventDefault();
    data.showUploadPopup.setValue(true);
  };

  const hideUploadPopup = () => {
    data.showUploadPopup.setValue(false);
    setDocumentDetails({});
  };

  const handleSuccessPopup = () => {
    data.showSuccessPopup.setValue(true);
  };

  const handleSuccessClose = async e => {
    e.preventDefault();
    data.showSuccessPopup.setValue(false);
    setFileName('');
    setIsLoading(true);
    history.go(0);
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  return render({
    data,
    documentDetails,
    handleUpload,
    handlePreview,
    uploadDocumentLoading,
    uploadDocumentError,
    uploadDocumentSuccess,
    handleUploadPopup,
    isLoading,
    hideUploadPopup,
    handleSuccessClose,
    handleSuccessPopup,
    ext,
  });
};

export default withRouter(UploadSupportingDocument);
