import { POLICY_ENABLE_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { CREDIT_POLICIES_QUERY } from '../graphql/queries';

const PolicyEnableProvider = () => {
  const policyEnableInput = {
    policyId: useCustomState(),
    content: useCustomState(),
    status: useCustomState(),
    showToast: useCustomState(false),
    selectedPolicyData: useCustomState({}),
    isEnablePolicyPopupVisible: useCustomState(false),
  };

  const toggleEnablePolicyPopup = (e, data = {}) => {
    e.preventDefault();

    policyEnableInput.isEnablePolicyPopupVisible.setValue(
      !policyEnableInput.isEnablePolicyPopupVisible.value,
    );
    policyEnableInput.selectedPolicyData.setValue(data);
  };

  const handlePolicyEnable = async (e, policyData = {}) => {
    e.preventDefault();

    if (!policyData && !policyData.id) {
      policyEnableInput.showToast.setValue(true);
      policyEnableInput.content.setValue(
        `Selected Loan Product ${policyData.name || ''} cannot be enabled `,
      );
      handleHideToast(e);
      return;
    }

    await handlePolicyEnableMutation({
      variables: {
        id: policyData.id,
      },
      refetchQueries: () => [{ query: CREDIT_POLICIES_QUERY }],
    });
  };

  const handleHideToast = () => {
    setTimeout(() => {
      policyEnableInput.showToast.setValue(false);
    }, 4000);
  };

  const [handlePolicyEnableMutation, { loading }] = useMutation(
    POLICY_ENABLE_MUTATION,
    {
      async onCompleted() {
        policyEnableInput.status.setValue('Success');
        policyEnableInput.showToast.setValue(true);
        policyEnableInput.content.setValue('Loan Product Enabled Successfully');
        handleHideToast();

        policyEnableInput.isEnablePolicyPopupVisible.setValue(
          !policyEnableInput.isEnablePolicyPopupVisible.value,
        );
      },

      onError(error) {
        policyEnableInput.status.setValue('Failed');
        policyEnableInput.showToast.setValue(true);
        policyEnableInput.content.setValue(trimGraphQLError(error.message));
        handleHideToast();
      },
    },
  );

  return {
    loading,
    policyEnableInput: policyEnableInput,
    handleHideToast: e => handleHideToast(e),
    handlePolicyEnable: (e, policyData) => handlePolicyEnable(e, policyData),
    toggleEnablePolicyPopup: (e, selectedPolicy) =>
      toggleEnablePolicyPopup(e, selectedPolicy),
  };
};

export default PolicyEnableProvider;
