import React from 'react';
import {
  Button,
  Card,
  DataCard,
  Input,
  CheckBox,
  Loader,
  Toast,
} from '../../../components';
import { Text } from '@chakra-ui/react';
import { useUpdateNotificationConfig } from '../../../Providers-new/Configuration';

const NotificationAddress = () => {
  const {
    data,
    loading,
    ciLoading,
    handleUpdateNotificationAttributes,
    handleHideToast,
    inputs,
    handleEmailVerificationCodeCheckBoxInput,
    checkboxEmailVerificationCode,
  } = useUpdateNotificationConfig();
  return (
    <>
      {(loading || ciLoading) && <Loader loadingText="loading..." />}
      <Toast
        title="Update Configurations"
        content={data.content.value}
        classes={data.status.value}
        active={data.showToast.value}
        clickEvent={handleHideToast}
      />

      <DataCard>
        <div className="flex-container space-between">
          <div className="form-desc">
            <Card title="Notification Address" />
          </div>

          <div className="form-fields">
            <div className="grid-container">
              <div className="form-fields">
                <Card title="Merchant Application Approved" />
                <Input
                  {...inputs.merchantApplicationApproved.input}
                  placeholder="Enter value"
                  required
                />
              </div>

              <div className="form-fields">
                <Card title="Merchant Application Denied" />
                <Input
                  {...inputs.merchantApplicationDenied.input}
                  placeholder="Enter value"
                  required
                />
              </div>

              <div className="form-fields">
                <Card title="Merchant Application Review" />
                <Input
                  {...inputs.merchantApplicationReview.input}
                  placeholder="Enter value"
                  required
                />
              </div>

              <div className="form-fields">
                <Card title="Merchant Loan Disbursed" />
                <Input
                  {...inputs.merchantLoanDisbursed.input}
                  placeholder="Enter value"
                  required
                />
              </div>

              <div className="form-fields">
                <Card title="Merchant Loan Repayment" />
                <Input
                  {...inputs.merchantLoanRepayment.input}
                  placeholder="Enter value"
                  required
                />
              </div>
            </div>

            <div className="flex-container spacing">
              <CheckBox
                {...inputs.emailVerificationCode.input}
                checked={checkboxEmailVerificationCode}
                onChange={handleEmailVerificationCodeCheckBoxInput}
              />
              <Text fontSize="md" px="5">
                Require Email Verification Code
              </Text>
            </div>

            <div className="flex-container">
              <Button
                click_event={handleUpdateNotificationAttributes}
                classes="secondary space-left"
                type="submit"
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </DataCard>
    </>
  );
};

export default NotificationAddress;
