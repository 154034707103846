import { useMutation } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { trimGraphQLError } from '../../lib/Utils';
import { useFormInput, useKeyPair } from '../Form';
import { useHistory } from 'react-router-dom';
import {
  CREATE_CUSTOM_APPROVAL_WORKFLOW,
  GET_APPROVAL_WORKFLOWS,
  UPDATE_CUSTOM_APPROVAL_WORKFLOW,
} from './queries';

const useMutateCreateCustomApprovalWorkflow = (workflowId, workflowList) => {
  const data = {
    showToast: useKeyPair(false),
    showNotificationPopup: useKeyPair(false),
    showWorkflowPopup: useKeyPair(false),
    showUpdatedNotificationPopup: useKeyPair(false),
    isEditMode: useKeyPair(!!workflowId),
    editModeId: useKeyPair(''),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    workflowFieldName: useFormInput(),
    workflowFieldDesc: useFormInput(),
    workflowFieldRole: useFormInput(),
    workflowFieldApprovalType: useFormInput(),
    formSteps: useFormInput(),
  };

  const history = useHistory();

  const approvalWorkflowId = workflowId || 0;
  const blankStep = {
    approvalType: '-- Select One --',
    roles: '-- Select One --',
  };
  const [stepState, setStepState] = useState([{ ...blankStep }]);

  const addNewStep = () => {
    setStepState([...stepState, { ...blankStep }]);
  };

  const removeStep = id => {
    const newStepState = stepState.filter((item, idx) => idx !== id);
    setStepState([...newStepState]);
  };

  const getRoleById = (roleId, roles) => {
    const { name } = roles.find(({ id }) => id === roleId) || {
      name: '-- Select One --',
    };
    return name;
  };

  const handleStepChange = e => {
    const updatedSteps = [...stepState];
    updatedSteps[e.target.dataset.idx][e.target.className] = e.target.value;
    setStepState(updatedSteps);
  };

  useEffect(() => {
    if (approvalWorkflowId) {
      let approvalWorkflowNodes = workflowList.approvalWorkflows.nodes || [];
      let approvalWorkflows =
        approvalWorkflowNodes.filter(
          item => item.id === approvalWorkflowId,
        )[0] || {};
      let blankStep = [];
      inputs.workflowFieldName.setValue(approvalWorkflows.name || '');
      inputs.workflowFieldDesc.setValue(approvalWorkflows.description || '');
      const workflowSteps = approvalWorkflows.steps;
      // eslint-disable-next-line array-callback-return
      workflowSteps.map(item => {
        blankStep.push({
          approvalType: item.approvalType,
          roles: item.roles.length > 0 && item.roles[0].id,
        });
      });
      setStepState([...blankStep]);
    }
  }, []);

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 5000);
  };

  const handleHideNewToast = () => {
    setTimeout(() => {
      data.showNotificationPopup.setValue(false);
    }, 5000);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const getMutationVariables = () => {
    let params = {};

    if (
      !inputs.workflowFieldName.value ||
      !inputs.workflowFieldDesc.value ||
      stepState.length < 1
    ) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue(
        'Please fill name and add a minimum of 1 approval step',
      );

      handleHideToast();
      return;
    }

    params = {
      name: inputs.workflowFieldName.value,
      description: inputs.workflowFieldDesc.value,
      steps: [...stepState],
    };

    if (workflowId) params.id = workflowId;

    return params;
  };

  const handleCreateCustomApprovalWorkflow = async e => {
    e.preventDefault();

    const variables = getMutationVariables();

    if (variables) {
      await customApprovalWorkflowCreate({
        variables: {
          input: variables,
        },
        refetchQueries: () => [{ query: GET_APPROVAL_WORKFLOWS }],
      });
    }
  };

  const [customApprovalWorkflowCreate, { loading, error }] = useMutation(
    CREATE_CUSTOM_APPROVAL_WORKFLOW,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Approval Workflow Created');
        data.showNotificationPopup.setValue(true);

        handleHideNewToast();
        setTimeout(() => {
          history.push('/settings/approval-workflows');
        }, 4000);
      },
      onError,
    },
  );

  const handleUpdateCustomApprovalWorkflow = async e => {
    e.preventDefault();

    const variables = getMutationVariables();

    if (variables) {
      await customApprovalWorkflowUpdate({
        variables: {
          input: variables,
        },
        refetchQueries: () => [{ query: GET_APPROVAL_WORKFLOWS }],
      });
    }
  };

  const [customApprovalWorkflowUpdate] = useMutation(
    UPDATE_CUSTOM_APPROVAL_WORKFLOW,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Workflow Updated');
        data.showNotificationPopup.setValue(true);

        handleHideNewToast();
        setTimeout(() => {
          history.push('/settings/approval-workflows');
        }, 4000);
      },
      onError,
    },
  );

  return {
    handleHideToast,
    formLoading: loading,
    error,
    inputs,
    data,
    handleCreateCustomApprovalWorkflow,
    handleUpdateCustomApprovalWorkflow,
    removeStep,
    stepState,
    addNewStep,
    handleStepChange,
    getRoleById,
  };
};

export { useMutateCreateCustomApprovalWorkflow };
