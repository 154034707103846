import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import { APPLICATION_QUERY } from '../../graphql/queries';
import { useCustomState } from '../../lib/CustomHooks';
import { trimGraphQLError } from '../../lib/Utils';
import { useFormInput, useKeyPair } from '../Form';
import { APPLY_MORATORIUM_ON_APPLICATION } from './queries';

const useMutateApplyMoratoriumOnApplication = ({ application }) => {
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
    showNotificationPopup: useKeyPair(false),
  };

  const inputs = {
    numberOfPeriods: useFormInput(),
    wavieMoratorium: useCustomState(),
  };
  const [checkboxState, setCheckboxState] = useState(false);
  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const handleHideNotification = () => {
    setTimeout(() => {
      data.showNotificationPopup.setValue(false);
    }, 3500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
    handleModalToggle();
  };

  const repaymentValue = () => {
    if (parseInt(inputs.numberOfPeriods.value) === 1) {
      return 'repayment';
    } else if (parseInt(inputs.numberOfPeriods.value) > 1) {
      return 'repayments';
    } else {
      return 'repayment';
    }
  };

  const handleApplyMoratoriumOnApplication = async e => {
    e.preventDefault();

    if (!inputs.numberOfPeriods.value && checkboxState === false) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue(
        'Please enter a moratorium periods or waive all moratorium periods.',
      );

      handleHideToast();
      return;
    }

    await applyMoratoriumOnApplication({
      variables: {
        input: {
          applicationId: application.id,
          numberOfPeriods:
            parseInt(inputs.numberOfPeriods.value) ||
            parseInt(inputs.wavieMoratorium.value),
        },
      },
      refetchQueries: () => [
        {
          query: APPLICATION_QUERY,
          variables: { applicationNumber: application.applicationNumber },
        },
      ],
    });
  };

  const handleModalToggle = () => {
    data.showModal.setValue(!data.showModal.value);
    inputs.numberOfPeriods.setValue('');
    inputs.wavieMoratorium.setValue();
    setCheckboxState(false);
  };

  const handleMoratoriumCheckBoxInput = e => {
    const checked = e.target.checked;
    setCheckboxState(checked);
    if (checked) {
      inputs.wavieMoratorium.setValue(parseInt(0));
    }
    if (!checked) {
      inputs.wavieMoratorium.setValue();
      setCheckboxState(false);
    }
  };

  const [applyMoratoriumOnApplication, { loading }] = useMutation(
    APPLY_MORATORIUM_ON_APPLICATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue(' Moratorium Period Updated');
        data.showModal.setValue(false);
        data.showNotificationPopup.setValue(true);
        handleHideToast();
        handleHideNotification();
      },
      onError,
    },
  );

  return {
    handleHideToast,
    loading,
    handleModalToggle,
    data,
    inputs,
    handleApplyMoratoriumOnApplication,
    repaymentValue,
    handleMoratoriumCheckBoxInput,
    checkboxState,
  };
};

export { useMutateApplyMoratoriumOnApplication };
