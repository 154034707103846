import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  CREATE_LOAN_CATEGORY,
  GET_ACTIVE_POLICIES,
  UPDATE_LOAN_CATEGORY,
  GET_LOAN_CATEGORIES,
} from './queries';
import { useKeyPair } from '../Form';
import {
  trimGraphQLError,
  getDifference,
  filterOutNullSupportingDocuments,
} from '../../lib/Utils';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useGetLoanCategoryAttribute } from './useGetLoanCategoryAttributes';
import { GET_ENUM } from '../../graphql/queries';
import { toast } from 'react-toastify';
import Constants from '../../lib/Constants';

const useMutateCreateLoanGroup = ({
  categoryName,
  categoryInView,
  formData: inputs,
}) => {
  const data = {
    showToast: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
    allAttributesData: useKeyPair([]),
  };

  // eslint-disable-next-line no-unused-vars
  const [repaymentServices, setRepaymentServices] = useState();
  const [repaymentServiceAttributeId, setRepaymentServiceAttributeId] =
    useState();

  const handleRepaymentServicesChange = useCallback(data => {
    setRepaymentServices(data);
  }, []);

  const blankStep = {
    documentName: null,
    description: null,
    supportingDocLevel: null,
  };
  const [stepState, setStepState] = useState([{ ...blankStep }]);

  const { data: supportingDocLevel = [] } = useQuery(GET_ENUM, {
    variables: {
      type: 'SupportingDocLevels',
    },
  });

  const addNewStep = () => {
    setStepState([...stepState, { ...blankStep }]);
  };

  const removeStep = id => {
    const newStepState = stepState.filter((item, idx) => idx !== id);
    setStepState([...newStepState]);
  };

  const handleStepChange = e => {
    const updatedSteps = [...stepState];

    updatedSteps[e.target.dataset.idx][e.target.getAttribute('data-set')] =
      e.target.value;

    setStepState(updatedSteps);
  };

  const { value: isEditMode } = useKeyPair(!!categoryName);

  const { loading: getAllAttributeLoading, result: getAllAttributesData } =
    useGetLoanCategoryAttribute();

  let loanCategoryAttributeData = getAllAttributesData.data
    ? getAllAttributesData &&
      getAllAttributesData.data &&
      getAllAttributesData.data.getLoanCategoryAttributes &&
      getAllAttributesData.data.getLoanCategoryAttributes.attributes
    : [];

  useEffect(() => {
    if (loanCategoryAttributeData.length) {
      const findRepaymentServicesAttribute = loanCategoryAttributeData.find(
        item => item?.name === 'repaymentServices',
      );

      setRepaymentServiceAttributeId(findRepaymentServicesAttribute?.id);
    }
  }, [loanCategoryAttributeData]);

  const [supportingDocumentId, updateSupportingDocumentId] = useState('');
  const [supportingDoc, setsupportingDoc] = useState('');

  useEffect(() => {
    data?.allAttributesData?.value?.map(item => {
      return (
        item.name === 'requiredSupportingDocuments' && setsupportingDoc(item.id)
      );
    });
  }, [data]);

  const handleChange = (e, idx) => {
    if (idx) updateSupportingDocumentId(idx);

    let documentNames = [];
    let repaymentServices = [];
    let otherAttributesResult = [];
    let unSelectedResult = [];
    let supportingDocsCheckboxes = document.querySelectorAll(
      'input[type=checkbox].supporting-docs:checked',
    );
    let otherAttributesCheckboxes = document.querySelectorAll(
      'input[type=checkbox].other-docs:checked',
    );
    let repaymentServiceCheckboxes = document.querySelectorAll(
      'input[type=checkbox].repayment-services:checked',
    );

    supportingDocsCheckboxes.forEach(item => {
      documentNames.push({
        documentName: item.value,
      });
    });

    repaymentServiceCheckboxes.forEach(item => {
      repaymentServices.push({
        name: item.value,
      });
    });

    stepState.map(item =>
      documentNames.push({
        documentName: item.documentName,
        description: item.description,
        supportingDocLevel: 'USER_LEVEL',
      }),
    );

    let supportingDocuments = [
      {
        id: supportingDocumentId || supportingDoc,
        value: 'true',
        data:
          documentNames.length && documentNames[0].documentName
            ? JSON.stringify(filterOutNullSupportingDocuments(documentNames))
            : [],
      },
    ];

    otherAttributesCheckboxes.forEach(item => {
      otherAttributesResult.push({
        id: item.id,
        value: 'true',
        data: null,
      });
    });

    const repaymentServiceAttribute = {
      id: repaymentServiceAttributeId,
      value: 'true',
      data: repaymentServices.length ? JSON.stringify(repaymentServices) : [],
    };

    const unCheckedData = getDifference(
      loanCategoryAttributeData,
      otherAttributesResult,
    );

    unCheckedData.forEach(item => {
      if (!item.data) {
        unSelectedResult.push({
          id: item.id,
          value: 'false',
          data: null,
        });
      }
    });

    let mergedArray = [
      ...unSelectedResult,
      ...otherAttributesResult,
      repaymentServiceAttribute,
    ];

    if (documentNames.length > 0) {
      mergedArray = [...mergedArray, ...supportingDocuments];
    }

    return mergedArray;
  };

  useEffect(() => {
    const allAttributesData = [];
    loanCategoryAttributeData.forEach(p => {
      allAttributesData.push(p);
    });

    data.allAttributesData.setValue(allAttributesData);
  }, [loanCategoryAttributeData]);

  const getStructuredData = data => {
    const response = [];
    if (data && data.length >= 1) {
      data.map(item => response.push(item.id));
    }

    return response;
  };

  const { loading: activePoliciesLoading, data: activePoliciesData } =
    useQuery(GET_ACTIVE_POLICIES);

  const history = useHistory();

  let activePolicies = [];
  let prefilledPolicies = [];

  activePoliciesData &&
    activePoliciesData.policies.map(item =>
      activePolicies.push({
        value: item.name,
        label: item.name,
        id: item.id,
      }),
    );
  inputs &&
    inputs.products.map(item =>
      prefilledPolicies.push({
        value: item.name,
        label: item.name,
        id: item.id,
      }),
    );

  const [prefilledLoanProducts, updatePrefilledLoanProducts] = useState([]);
  const policies = [...activePolicies, ...prefilledPolicies];

  useEffect(() => {
    if (inputs.products.length)
      updatePrefilledLoanProducts([...prefilledPolicies]);
  }, [inputs]);

  const handleChangeLoanProducts = value => {
    updatePrefilledLoanProducts(value);
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3000);
  };

  const [createLoanCategory, { loading, error }] = useMutation(
    CREATE_LOAN_CATEGORY,
    {
      onCompleted: () => {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Loan group created successfully');

        handleHideToast();
        history.push('/loan-categories');
        window.location.reload();
      },
      onError: err => {
        data.showToast.setValue(true);
        data.status.setValue('Failed');
        data.content.setValue(trimGraphQLError(err.message));

        handleHideToast();
      },
    },
  );

  useEffect(() => {
    if (error) {
      toast.error(
        trimGraphQLError(
          error?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
    return () => toast.dismiss();
  }, [error]);

  const [updateLoanCategory, { loading: isUpdateLoanCategoryLoading }] =
    useMutation(UPDATE_LOAN_CATEGORY, {
      onCompleted: ({ updateLoanCategory: { comments } }) => {
        if (!comments.length || comments[0] === 'no loan products passed') {
          data.showToast.setValue(true);
          data.status.setValue('Success');
          data.content.setValue('Loan Category Updated');

          handleHideToast();
          history.push('/loan-categories');
          window.location.reload();
        }
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue(comments.join('\r\n\n'));

        handleHideToast();
      },
      onError: err => {
        data.showToast.setValue(true);
        data.status.setValue('Failed');
        data.content.setValue(trimGraphQLError(err.message));

        handleHideToast();
      },
    });

  const getMutationVariables = () => {
    const selectedAttributes = handleChange();

    if (!inputs.description || !inputs.name) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please fill all required input.');

      handleHideToast();
      return;
    }

    if (prefilledLoanProducts) {
      return {
        name: inputs.name,
        description: inputs.description,
        products: getStructuredData(prefilledLoanProducts),
        attributes: selectedAttributes,
      };
    } else {
      return {
        name: inputs.name,
        description: inputs.description,
        attributes: selectedAttributes,
      };
    }
  };

  const handleCreateLoanGroup = async () => {
    const variables = getMutationVariables();

    if (variables) {
      await createLoanCategory({
        variables: {
          input: {
            ...variables,
          },
        },
        refetchQueries: () => [
          {
            query: GET_LOAN_CATEGORIES,
          },
        ],
        awaitRefetchQueries: true,
      });
    }
  };

  const handleUpdateLoanGroup = async () => {
    const data = getMutationVariables();

    if (data != undefined || data != null) {
      await updateLoanCategory({
        variables: {
          input: {
            loanCategoryId: categoryInView && data ? categoryInView.id : '',
            data: { ...data },
          },
        },
        refetchQueries: () => [
          {
            query: GET_LOAN_CATEGORIES,
          },
        ],
        awaitRefetchQueries: true,
      });
    }
  };

  const handleSumbitLoanGroup = () => {
    isEditMode ? handleUpdateLoanGroup() : handleCreateLoanGroup();
  };

  return {
    handleHideToast,
    loading,
    activePoliciesLoading,
    error,
    data,
    isUpdateLoanCategoryLoading,
    handleCreateLoanGroup,
    prefilledLoanProducts,
    policies,
    handleChangeLoanProducts,
    handleUpdateLoanGroup,
    isEditMode,
    handleSumbitLoanGroup,
    loanCategoryAttributeData,
    handleChange,
    getAllAttributeLoading,
    removeStep,
    stepState,
    addNewStep,
    handleStepChange,
    supportingDocLevel,
    handleRepaymentServicesChange,
  };
};

export { useMutateCreateLoanGroup };
