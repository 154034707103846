import React, { Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import cx from 'classnames';

import {
  BreadCrumbSection,
  Button,
  Loader,
  Modal,
  Toast,
  MoreOptions,
} from '../../components';
import { Plus, Close } from '../../components/Icons';

import {
  useGetApprovalWorkflows,
  useMutateUpdateApprovalWorkflow,
} from '../../Providers-new/ApprovalWorkflow';
import { useClientInfo } from '../../Providers-new/Client';

import './_UserManagement.scss';

const ApprovalWorkflow = ({ clientInfo, approvalWorkflowList }) => {
  const activeStepId = clientInfo.approvalWorkflow || 'none';

  const {
    data,
    loading,
    handleHideToast,
    handleHideWorkflowToggle,
    handleHideDeleteWorkflowToggle,
    handleApprovalWorkflowSwitch,
    handleApprovalWorkflowDelete,
  } = useMutateUpdateApprovalWorkflow({
    approvalWorkflowList,
  });

  const workflowList = approvalWorkflowList.nodes || [];

  return (
    <>
      <BreadCrumbSection />
      {loading && <Loader />}
      <Fragment>
        {/* popup to handle update of of selected workflow */}
        <Modal
          title={'Update Approval Workflow'}
          active={data.showWorkflowPopup.value}
        >
          <Close
            className="close-icon"
            onClick={e => handleHideWorkflowToggle(e)}
          />
          <div className="content center">
            Are you sure you want to switch to this type of Approval Workflow?
          </div>
          <div className="actions">
            <div className="flex-container center">
              <Toast
                title="Switch Approval Workflow"
                content={data.content.value}
                classes={data.status.value}
                active={data.showToast.value}
                clickEvent={handleHideToast}
              />
              <Button
                classes="secondary"
                click_event={e => handleApprovalWorkflowSwitch(e)}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>

        {/* popup to handle delete of of selected workflow */}
        <Fragment>
          <Toast
            title="Delete Approval Workflow"
            content={data.content.value}
            classes={data.status.value}
            active={data.showToast.value}
            clickEvent={handleHideToast}
          />
          <Modal
            title={'Delete Selected Approval Workflow'}
            active={data.showDelWorkflowPopup.value}
          >
            <Close
              className="close-icon"
              onClick={e => handleHideDeleteWorkflowToggle(e)}
            />
            <div className="content center">
              Are you sure you want to delete this Approval Workflow?
            </div>
            <div className="actions">
              <div className="flex-container center">
                <Button
                  classes="secondary"
                  click_event={handleApprovalWorkflowDelete}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal>
        </Fragment>

        <div className="form-card approval-workflow">
          <div className={'approval-workflow__wrapper wrap'}>
            {workflowList &&
              workflowList.map((item, key) => {
                const isCustom = item.isCustom;
                const isActive = item.id === activeStepId;
                return (
                  <div
                    key={`${key}-${item.id}`}
                    className={cx({
                      'flexStart approval-workflow__card': item.id,
                      active: isActive,
                    })}
                  >
                    {!isActive && (
                      <MoreOptions>
                        <div
                          onClick={e => handleHideWorkflowToggle(e, item.id)}
                        >
                          Set Active
                        </div>
                        {isCustom && (
                          <Link
                            to={`/settings/approval-workflows/${item.name}`}
                          >
                            Edit
                          </Link>
                        )}
                        {isCustom && !isActive && (
                          <div
                            onClick={e =>
                              handleHideDeleteWorkflowToggle(e, item.id)
                            }
                          >
                            Delete
                          </div>
                        )}
                      </MoreOptions>
                    )}
                    {isActive && (
                      <MoreOptions>
                        <Link to={`/settings/approval-workflows/${item.name}`}>
                          Details
                        </Link>
                      </MoreOptions>
                    )}
                    <div className={'title'}>{item.title || item.name}</div>
                    <div className={'desc'}>{item.description}</div>
                  </div>
                );
              })}

            <Link
              to={'/settings/approval-workflows/create-custom-workflow'}
              className={'approval-workflow__card custom flexCenter'}
            >
              <Plus />
              <div className={'title'}>Create custom workflow</div>
            </Link>
          </div>
        </div>
      </Fragment>
    </>
  );
};

const ApprovalWorkflowWrapper = () => {
  const { loading, result } = useClientInfo({ privateFields: true });
  const { loading: qLoading, result: qResult } = useGetApprovalWorkflows();

  if (loading || qLoading) return <Loader />;
  return (
    <ApprovalWorkflow
      clientInfo={result.data.clientInfo}
      approvalWorkflowList={qResult.data.approvalWorkflows}
    />
  );
};

export default withRouter(ApprovalWorkflowWrapper);
