import React, { Fragment } from 'react';
import {
  Button,
  Input,
  Loader,
  Modal,
  NewRadio,
  Notification,
  Toast,
} from '../../../../components';
import { Close, CompleteIcon } from '../../../../components/Icons';

const UpdateApplicationLoanAmount = ({
  applicationLoanAmountData,
  handleHideApplicationLoanAmountToast,
  updateApplicationLoanAmountLoader,
  handleApplicationLoanAmountModalToggle,
  handleUpdateApplicationLoanAmount,
  handleLoanAmountCheckBoxInput,
  handleModifyRequestedAmountCheck,
  modifyRequestedAmountCheck,
  loanAmountCheckboxState,
  setDecideLoanAmount,
  decideAffordabilityLoanAmount,
  decideLoanAmount,
  hasDecideBankStatementAnalysisData,
}) => {
  return (
    <Fragment>
      {updateApplicationLoanAmountLoader ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          <Toast
            title="Update Loan Amount"
            content={applicationLoanAmountData.content.value}
            clickEvent={handleHideApplicationLoanAmountToast}
            active={applicationLoanAmountData.showToast.value}
            classes={applicationLoanAmountData.status.value}
          />
          {applicationLoanAmountData.showNotificationPopup.value && (
            <Notification>
              <div className="notification_icon-holder">
                <CompleteIcon />
              </div>
              <h5 className={'center-text'}>
                {
                  'The Loan amount has been modified and the customer has been notified of this change.'
                }
              </h5>
            </Notification>
          )}

          <Modal
            title={'Update Requested Amount'}
            classes="cash-repayment"
            active={applicationLoanAmountData.showModal.value}
          >
            <Close
              className="close-icon"
              onClick={handleApplicationLoanAmountModalToggle}
            />
            <div>
              <div className="updateLoanAmountWrapper">
                {hasDecideBankStatementAnalysisData ? (
                  <NewRadio
                    name="affordabilityAmount"
                    label="Set as Expected Amount"
                    id="EXPECTED_AMOUNT"
                    checked={
                      modifyRequestedAmountCheck.checked.id ===
                      'EXPECTED_AMOUNT'
                    }
                    onChange={() =>
                      handleModifyRequestedAmountCheck({
                        id: 'EXPECTED_AMOUNT',
                      })
                    }
                    onClick={() =>
                      setDecideLoanAmount(decideAffordabilityLoanAmount)
                    }
                  />
                ) : (
                  ''
                )}
                <NewRadio
                  name="loanAmount"
                  label="Loan Amount"
                  id="LOAN_AMOUNT"
                  checked={
                    modifyRequestedAmountCheck.checked.id === 'LOAN_AMOUNT'
                  }
                  onChange={() =>
                    handleModifyRequestedAmountCheck({
                      id: 'LOAN_AMOUNT',
                    })
                  }
                />

                {modifyRequestedAmountCheck.checked.id === 'EXPECTED_AMOUNT' ? (
                  <Input
                    label="Expected Loan Amount"
                    type="text"
                    value={+decideLoanAmount}
                    onChange={handleLoanAmountCheckBoxInput}
                    {...applicationLoanAmountData.decideAffordabilityAmount
                      .value}
                    disabled={
                      loanAmountCheckboxState ||
                      modifyRequestedAmountCheck.checked.id == null
                    }
                  />
                ) : (
                  <Input
                    label="New Loan Amount"
                    placeholder="Enter new loan amount"
                    amountField={true}
                    {...applicationLoanAmountData.loanAmount.input}
                    disabled={
                      loanAmountCheckboxState ||
                      modifyRequestedAmountCheck.checked.id == null
                    }
                  />
                )}
                <div className="loanAmount-aside">NGN</div>
              </div>
              <div className="processing-fee-button">
                <Button click_event={handleApplicationLoanAmountModalToggle}>
                  Cancel
                </Button>
                <Button
                  classes="secondary"
                  click_event={handleUpdateApplicationLoanAmount}
                >
                  Update
                </Button>
              </div>
            </div>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UpdateApplicationLoanAmount;
