import { gql } from 'apollo-boost';

export const fragments = {};

export const CLIENT_INFO_QUERY = gql`
  query MP_ClientInfoQuery(
    $slug: String
    $clientUrl: String
    $loanCategoryId: ID
  ) {
    clientInfo(
      input: {
        slug: $slug
        clientUrl: $clientUrl
        loanCategoryId: $loanCategoryId
      }
    ) {
      clientId
      name
      slug
      logoUrl
      clientAdminUrl
      clientTheme {
        primaryColor
        secondaryColor
      }
      clientAppUrl
      addCardCharge
      contactDetails {
        email
        phone
      }
      requiresRemitaMandate
      clientWebhookUrl
      secretKey
      enableWebhooksConfig
      requiredSupportingDocuments {
        description
        documentName
      }
      requiresOkraTransactions
      requiresCrcReport
      requiresCrcMicroReport
      useLoanGroups
      requiresDecidePdfAnalysis
      requiresExternalDisbursement
      termsAndConditionsUrl
      privacyPolicyUrl
      faviconUrl
      howToApplyVideoLink
      cookiesPolicyUrl
      kycConfiguration {
        minimumRequirements
        form
      }
      customerNotificationPreference {
        applicationApproved {
          sms
          email
        }
        applicationDenied {
          sms
          email
        }
        applicationReview {
          sms
          email
        }
        loanDisbursed {
          sms
          email
        }
        loanRepayment {
          sms
          email
        }
      }
    }
  }
`;

export const PRIVATE_CLIENT_INFO_QUERY = gql`
  query MP_PrivateClientInfoQuery(
    $slug: String
    $clientUrl: String
    $name: String
    $policyId: [ID!]
    $loanCategoryId: ID
  ) {
    clientInfo(
      input: {
        slug: $slug
        clientUrl: $clientUrl
        loanCategoryId: $loanCategoryId
      }
    ) {
      clientId
      clientWebhookUrl
      secretKey
      enableWebhooksConfig
      requiresOkraTransactions
      approvalWorkflow
      requiresRemitaMandate
      requiresCrcReport
      requiresCrcMicroReport
      useLoanGroups
      requiresExternalDisbursement
      ruleSets(
        first: 100
        where: { name_contains: $name, policyId_in: $policyId }
      ) {
        nodes {
          id
          name
          description
          status
          approve {
            data
            type {
              name
              label
              status
            }
          }
          manualReview {
            data
            type {
              name
              label
              status
            }
          }
          policies {
            id
            name
          }
        }
        totalCount
      }
    }
  }
`;
