import { CUSTOMER_DETAILS } from '@/graphql/queries/customers/customerDetails';
import { useQuery } from '@apollo/client';

export const useCustomerDetails = (name: string) => {
  const { data, loading, error, refetch, networkStatus } = useQuery(
    CUSTOMER_DETAILS,
    {
      variables: {
        input: {
          name,
        },
      },
      notifyOnNetworkStatusChange: true,
      onError: () => {},
      errorPolicy: 'all',
    },
  );

  const customerDetailsInitialLoading = networkStatus === 1; // networkStatus 1 indicates initial loading

  return {
    customerDetailsError: error,
    customerDetailsLoading: loading,
    customerDetailsResponse: data?.customer,
    refetchCustomerDetails: refetch,
    customerDetailsInitialLoading,
  };
};
