import { Query } from '@apollo/react-components';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connection_limit } from '../config/config';
import OriginateClient from './OriginateClient';
import { USER_MANAGEMENT_QUERY } from '../graphql/queries';

const UserManagementTableProvider = ({ render }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchMore = (e, fetchMore, data) => {
    e.preventDefault();
    setIsLoading(true);
    fetchMore({
      variables: {
        cursor: data.clientAccountUsersXn.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoading(false);
        const newNodes = fetchMoreResult.clientAccountUsersXn.nodes;
        const pageInfo = fetchMoreResult.clientAccountUsersXn.pageInfo;

        return newNodes.length
          ? {
              clientAccountUsersXn: {
                __typename: prev.clientAccountUsersXn.__typename,
                nodes: [...prev.clientAccountUsersXn.nodes, ...newNodes],
                pageInfo,
              },
            }
          : prev;
      },
    });
  };

  return (
    <OriginateClient
      render={({ clientId }) => (
        <Query
          query={USER_MANAGEMENT_QUERY}
          variables={{
            clientId: clientId.clientInfo.clientId,
            first: connection_limit.value,
          }}
        >
          {({ data, fetchMore }) => {
            return render({
              userManagementTableData: data,
              isLoading,
              handlePagination: e => handleFetchMore(e, fetchMore, data),
            });
          }}
        </Query>
      )}
    />
  );
};

export default withRouter(UserManagementTableProvider);
