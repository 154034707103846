import React, { useEffect, useState } from 'react';
import { Query } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { CUSTOMER_BY_PARAMS_QUERY } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import Constants from '../lib/Constants';
import { deepCopy, exportToCsv, trimGraphQLError } from '../lib/Utils';
import { Loader } from '../components';
import moment from 'moment';
import { toast } from 'react-toastify';

const CustomerDetails = ({ render, match: { params } }) => {
  const [errMsg, setErrMsg] = useState();
  const { name, customerPhone } = params;

  useEffect(() => {
    if (errMsg) {
      toast.error(
        trimGraphQLError(
          errMsg?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
  }, [errMsg]);

  const customerDetailsPrompt = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showEligibilityModal: useCustomState(false),
  };

  const handleHideToast = e => {
    e.preventDefault();

    customerDetailsPrompt.showToast.setValue(false);
  };

  const handleExportToCsvApplications = (e, data) => {
    e.preventDefault();

    if (!data || typeof data === 'undefined') {
      customerDetailsPrompt.showToast.setValue(true);
      customerDetailsPrompt.status.setValue('Failed');
      customerDetailsPrompt.content.setValue(
        Constants.errorMessages.NO_EXPORT_DATA,
      );

      setTimeout(() => {
        customerDetailsPrompt.showToast.setValue(false);
      }, 2000);

      return;
    }

    const {
      customer: {
        applications: { nodes },
      },
    } = data;

    const csvData = deepCopy(nodes);

    // eslint-disable-next-line array-callback-return
    csvData.map(node => {
      node.status = node.status.name;
      node.email = `${node.user.email}`;
      node.createdAt = `"${moment(node.createdAt).format('llll')}"`;
      node.dateOfRepayment = `"${moment(node.dateOfRepayment).format('llll')}"`;

      delete node.user;
    });

    exportToCsv(csvData, Constants.csvNames.APPLICATIONS);
  };

  let attributes, businessAttributes, latestApplicationFormData;

  const handleExportToCsvPortfolios = (e, data) => {
    e.preventDefault();

    if (!data || typeof data === 'undefined') {
      customerDetailsPrompt.showToast.setValue(true);
      customerDetailsPrompt.status.setValue('Failed');
      customerDetailsPrompt.content.setValue(
        Constants.errorMessages.NO_EXPORT_DATA,
      );

      setTimeout(() => {
        customerDetailsPrompt.showToast.setValue(false);
      }, 2000);

      return;
    }

    const {
      customer: {
        portfolios: { nodes },
      },
    } = data;

    const csvData = deepCopy(nodes);

    // eslint-disable-next-line array-callback-return
    csvData.map(node => {
      node.status = node.status.name;
      node.createdAt = `"${moment(node.createdAt).format('ll')}"`;
      node.dateOfRepayment = `"${moment(node.dateOfRepayment).format('ll')}"`;
    });

    exportToCsv(csvData, Constants.csvNames.LOANS);
  };

  const handleExportToCsvCustomers = (e, data) => {
    e.preventDefault();

    if (!data || typeof data === 'undefined') {
      customerDetailsPrompt.showToast.setValue(true);
      customerDetailsPrompt.status.setValue('Failed');
      customerDetailsPrompt.content.setValue(
        Constants.errorMessages.NO_EXPORT_DATA,
      );

      setTimeout(() => {
        customerDetailsPrompt.showToast.setValue(false);
      }, 2000);

      return;
    }

    const {
      customer: { user },
    } = data;

    const csvDataObj = deepCopy(user);

    const csvData = [{ ...csvDataObj }];

    // eslint-disable-next-line array-callback-return
    csvData.map(node => {
      node.firstName = `${node.firstName || 'NA'}`;
      node.lastName = `${node.lastName || 'NA'}`;
      node.phone = `${node.phone || 'NA'}`;
      node.email = `${node.email || 'NA'}`;
      node.BVN = `${node.bvnStatus.bvn}`;
      node.BVN_Phone = `${node.bvnStatus.phone}`;
      node.DOB = `"${moment(node.bvnStatus.dateOfBirth).format('ll')}"`;
      node.userMetadata.map(
        userData =>
          (node[userData.name] = `${
            userData.name === 'workStartDate' && userData.value !== ''
              ? moment(userData.value).format('ll')
              : userData.value || 'NA'
          }`),
      );
      node.supportingDocument.map(
        userDocument =>
          (node[userDocument.documentName] = `${userDocument.fileUrl || 'NA'}`),
      );

      delete node.isEmailConfirmed;
      delete node.isPhoneConfirmed;
      delete node.userMetadata;
      delete node.supportingDocument;
      delete node.__typename;
      delete node.bvnStatus;
    });

    exportToCsv(csvData, Constants.csvNames.CUSTOMERS);
  };

  const handleShowEligibilityModal = () => {
    customerDetailsPrompt.showEligibilityModal.setValue(true);
  };

  const handleHideEligibilityModal = () => {
    customerDetailsPrompt.showEligibilityModal.setValue(false);
  };

  const abbreviateApplicationStatus = status => {
    switch (status) {
      case 'APPROVED':
        return 'APP';
      case 'DENIED':
        return 'DEN';
      case 'PENDING':
        return 'PEN';
      case 'UNDER_REVIEW':
        return 'REV';
      case 'ABANDONED':
        return 'ABA';
      case 'AWAITING_FEEDBACK':
        return 'AWF';
      default:
        return 'PEN';
    }
  };

  return (
    <Query query={CUSTOMER_BY_PARAMS_QUERY} variables={{ name: name }}>
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return <Loader />;
        }
        error && setErrMsg(error?.message);
        if (data) {
          const attributeData = data.customer.accountAttribute.filter(
            attribute => attribute.attribute.name === 'facilityInformation',
          );
          attributes = attributeData.map(attribute => attribute.data)[0];

          const businessData = data.customer.accountAttribute.filter(
            attribute => attribute.attribute.name === 'businessInformation',
          );
          businessAttributes = businessData.map(attribute => attribute.data)[0];

          const propertyInfo = data.customer.accountAttribute.find(
            attr => attr.attribute.name === 'rentalInformation',
          );
          data.propertyInfo = propertyInfo ? propertyInfo.data : null;
          latestApplicationFormData =
            data.latestApplicationFormData?.data &&
            Object.keys(data.latestApplicationFormData.data) &&
            data.latestApplicationFormData.data;
        }

        return render({
          data,
          error,
          input: customerDetailsPrompt,
          handleHideToast,
          handleExportToCsvApplications: e =>
            handleExportToCsvApplications(e, data),
          handleExportToCsvPortfolios: e =>
            handleExportToCsvPortfolios(e, data),
          handleExportToCsvCustomers: e => handleExportToCsvCustomers(e, data),
          customerPhone,
          abbreviateApplicationStatus: status =>
            abbreviateApplicationStatus(status),
          attributes,
          businessAttributes,
          handleShowEligibilityModal,
          handleHideEligibilityModal,
          refetch,
          latestApplicationFormData,
        });
      }}
    </Query>
  );
};

export default withRouter(CustomerDetails);
