import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  removeCommas,
  roundNumberToPrecision,
  trimGraphQLError,
} from '../../lib/Utils';
import { toast } from 'react-toastify';
import Constants from '../../lib/Constants';
import { MAKE_MANUAL_REPAYMENT_MUTATION } from '../../graphql/mutations';
import { PORTFOLIO_QUERY } from '../../graphql/queries';

const useMutateManualRepayment = ({
  portfolioNumber,
  setCashRepaymentModal,
}) => {
  const [error, setError] = useState();
  const [mutate, { data, loading }] = useMutation(
    MAKE_MANUAL_REPAYMENT_MUTATION,
    {
      refetchQueries: () => [
        {
          query: PORTFOLIO_QUERY,
          variables: {
            portfolioNumber: portfolioNumber,
            transactionWhere: {
              type_in: ['Manual Repayment', 'Repayment'],
            },
          },
        },
      ],
      onError: err => {
        setError(err);
      },
    },
  );

  const makeManualRepayment = ({
    accountNumber,
    amount,
    bankId,
    portfolioId,
    reference,
  }) => {
    mutate({
      variables: {
        accountNumber,
        amount: parseFloat(roundNumberToPrecision(removeCommas(amount), 2)),
        bankId,
        portfolioId,
        reference,
      },
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(
        trimGraphQLError(error?.message) ||
          Constants.errorMessages.NETWORK_ERROR,
      );
    }

    return () => toast.dismiss();
  }, [error]);

  useEffect(() => {
    if (data?.makeManualRepayment?.success) {
      toast.success('Repayment succcessful');
      setCashRepaymentModal(prev => !prev);
    }

    return () => toast.dismiss();
  }, [data]);

  return {
    response: data,
    error,
    loading,
    makeManualRepayment,
  };
};

export { useMutateManualRepayment };
