import {
  Heading,
  Stack,
  StackDivider,
  StackProps,
  Text,
} from '@chakra-ui/layout';
import React, { forwardRef, ReactNode } from 'react';

type FormWrapperProps = {
  title: string;
  description: string;
  children?: ReactNode;
} & StackProps;

const FormWrapper = forwardRef<HTMLDivElement, FormWrapperProps>(
  ({ title, description, children, ...rest }, ref) => {
    return (
      <Stack
        as="form"
        spacing={6}
        bg="white"
        p={6}
        divider={<StackDivider />}
        ref={ref}
        {...rest}
      >
        <Stack spacing={1}>
          <Heading as="h3" fontSize="lg" fontWeight="semibold">
            {title}
          </Heading>

          <Text color="gray.500">{description}</Text>
        </Stack>

        {children}
      </Stack>
    );
  },
);

FormWrapper.displayName = 'FormWrapper';

export default FormWrapper;
