import { ArrowLeftIcon } from '@/icons/ArrowLeftIcon';
import { Flex, Heading, IconButton } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom-v6';
import { Head } from '../Head';

type PageTitleProps = {
  title: string;
  description?: string;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  showBackButton?: boolean;
};

const PageTitle = ({
  title,
  description,
  leftSection,
  rightSection,
  showBackButton,
}: PageTitleProps) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Flex
        gap={2}
        flex={1}
        zIndex={2}
        bg="gray.50"
        flexDir={{
          base: 'column',
          md: !leftSection ? 'row' : undefined,
          lg: 'row',
        }}
        alignItems={{
          base: 'flex-start',
          md: !leftSection ? 'center' : undefined,
          lg: 'center',
        }}
      >
        <Flex gap={2} flexDir={{ base: 'column', sm: 'row' }}>
          <Flex gap={2}>
            {showBackButton && (
              <IconButton
                variant="ghost"
                aria-label="Go Back"
                onClick={handleGoBack}
                icon={<ArrowLeftIcon w={6} h={6} fill="gray.600" />}
              />
            )}

            <Heading
              m={0}
              as="h1"
              noOfLines={1}
              fontSize="2xl"
              lineHeight={10}
              fontWeight="medium"
            >
              {title}
            </Heading>
          </Flex>

          {leftSection ? <Flex alignItems="center">{leftSection}</Flex> : null}
        </Flex>

        {rightSection ? (
          <Flex
            alignItems="center"
            ml={{ md: !leftSection ? 'auto' : undefined, lg: 'auto' }}
          >
            {rightSection}
          </Flex>
        ) : null}
      </Flex>

      <Head title={title} description={description} />
    </>
  );
};

export default PageTitle;
