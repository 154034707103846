import { withRouter } from 'react-router-dom';
import { CHANGE_CLIENT_USER_ROLE_MUTATION } from '../graphql/mutations';
import { GET_ENUM } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation, useQuery } from '@apollo/react-hooks';

const ChangeClientUserRoleProvider = ({ render, userId }) => {
  const { data } = useQuery(GET_ENUM, {
    variables: {
      type: 'ClientUserRoles',
    },
  });

  let actions = [];
  if (data) {
    actions = data.enumValues;
  }

  const changeClientUserRoleInput = {
    userId: useCustomState(),
    roles: useCustomState(),
    showPopup: useCustomState(false),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      changeClientUserRoleInput.showToast.setValue(false);
    }, 2500);
  };

  const showChangeClientUserRolePopup = e => {
    e.preventDefault();

    changeClientUserRoleInput.showPopup.setValue(true);
  };

  const hideChangeClientUserRolePopup = e => {
    e.preventDefault();

    changeClientUserRoleInput.showPopup.setValue(false);
  };

  const handleChangeClientUserRole = async (
    e,
    handleChangeClientUserRoleMutation,
  ) => {
    e.preventDefault();

    if (!changeClientUserRoleInput.roles.value)
      if (!changeClientUserRoleInput.userId.value) {
        changeClientUserRoleInput.showToast.setValue(true);
        changeClientUserRoleInput.status.setValue('Failed');
        changeClientUserRoleInput.content.setValue('Please select a role');

        handleHideToast();

        return;
      }

    await handleChangeClientUserRoleMutation({
      variables: {
        userId,
        roles: changeClientUserRoleInput.roles.value,
      },
    });

    changeClientUserRoleInput.showPopup.setValue(false);
  };

  const [handleChangeClientUserRoleMutation, { loading }] = useMutation(
    CHANGE_CLIENT_USER_ROLE_MUTATION,
    {
      async onCompleted() {
        changeClientUserRoleInput.showToast.setValue(true);
        changeClientUserRoleInput.status.setValue('Success');
        changeClientUserRoleInput.content.setValue('Role Changed');
      },

      onError(error) {
        changeClientUserRoleInput.showToast.setValue(true);
        changeClientUserRoleInput.status.setValue('Failed');
        changeClientUserRoleInput.content.setValue(
          trimGraphQLError(error.message),
        );

        handleHideToast();
      },
    },
  );

  return render({
    loading,
    handleHideToast: e => handleHideToast(e),
    showChangeClientUserRolePopup,
    hideChangeClientUserRolePopup,
    handleChangeClientUserRole: e =>
      handleChangeClientUserRole(e, handleChangeClientUserRoleMutation),
    input: changeClientUserRoleInput,
    enumData: actions,
  });
};

export default withRouter(ChangeClientUserRoleProvider);
