import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Select, SideBar } from '../../components';
import NumberFormat from 'react-number-format';
import { useQuery } from '@apollo/react-hooks';
import { GET_ENUM } from '../../graphql/queries';
import { capitalizeInitialLetter, removeUnderscore } from '../../lib/Utils';

const Search = ({ input, resetAction, searchAction }) => {
  const { data } = useQuery(GET_ENUM, {
    variables: {
      type: 'PublicPolicyStatus',
    },
  });

  let statuses = [];
  if (data) statuses = data.enumValues;

  const { data: repaymentTypeData } = useQuery(GET_ENUM, {
    variables: {
      type: 'RepaymentType',
    },
  });

  let repayment = [];
  if (repaymentTypeData) repayment = repaymentTypeData.enumValues;

  return (
    <SideBar>
      <section>
        <h4 className={'sidebar-header'}>Search Parameter</h4>

        <Input
          data-testid="product-name"
          label="Product Name"
          placeholder="Enter Name"
          {...input.name.input}
        />

        <Select {...input.status.input} label="Status" data-testid="status">
          <option disabled value="">
            -Select Status-
          </option>
          {statuses.map((status, index) => (
            <option key={index} value={status.value}>
              {capitalizeInitialLetter(status.name)}
            </option>
          ))}
        </Select>

        <span className="sidebar__label">Loan Range</span>
        <div className={'filter-range'}>
          <NumberFormat
            data-testid="minloanamount"
            thousandSeparator={true}
            className="filter-input"
            placeholder="₦0.00"
            {...input.minLoanAmount.input}
          />
          <NumberFormat
            data-testid="maxloanamount"
            thousandSeparator={true}
            className="filter-input"
            placeholder="₦20,000.00"
            {...input.maxLoanAmount.input}
          />
        </div>

        <span className="sidebar__label">Validity Period</span>
        <div className={'filter-range'}>
          <NumberFormat
            data-testid="min-validity"
            thousandSeparator={true}
            className="filter-input"
            placeholder="0"
            {...input.minLoanDuration.input}
          />
          <NumberFormat
            data-testid="max-validity"
            thousandSeparator={true}
            className="filter-input"
            placeholder="2"
            {...input.maxLoanDuration.input}
          />
        </div>

        <Select
          {...input.repaymentType.input}
          label="Repayment Frequency"
          data-testid="repayment-frequency"
        >
          <option>- Select Repayment Frequency -</option>
          {repayment &&
            repayment.map((item, key) => (
              <option key={key} value={item.name}>
                {capitalizeInitialLetter(removeUnderscore(item.name))}
              </option>
            ))}
        </Select>

        <div>
          <Button
            classes={'reset'}
            click_event={resetAction}
            data-testid="reset-button"
          >
            Reset
          </Button>
          <Button
            classes={'secondary right'}
            click_event={searchAction}
            data-testid="search-button"
          >
            Search
          </Button>
        </div>
      </section>
    </SideBar>
  );
};

Search.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  resetAction: PropTypes.func,
  searchAction: PropTypes.func,
};

export default Search;
