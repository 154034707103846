import React, { useState } from 'react';
import { UseUserDetails } from '../../../../Providers';
import { Button, Loader, ModalV2, Select } from '../../../../components';
import { Close } from '../../../../components/Icons';

const AssignApplication = ({
  openAssignAndUnassignApplicationModal,
  handleAssignAndUnassignApplicationToggle,
  assignApplication,
  unassignApplication,
  assignApplicationIsLoading,
  unAssignApplicationIsLoading,
  applicationId,
  assignedMerchantUser,
}) => {
  const [merchantUser, setMerchantUser] = useState(assignedMerchantUser);
  const handleSubmit = () => {
    if (merchantUser === 'unassigned') {
      unassignApplication({ applicationId });
      setMerchantUser(null);
    } else {
      assignApplication({ applicationId, assignTo: merchantUser });
    }
  };

  return (
    <>
      {assignApplicationIsLoading || unAssignApplicationIsLoading ? (
        <Loader loadingText="Loading..." />
      ) : (
        <ModalV2
          title="Update Assigned Loan Officer"
          classes="v2-modal"
          active={openAssignAndUnassignApplicationModal}
        >
          <Close
            className="close-icon"
            onClick={handleAssignAndUnassignApplicationToggle}
          />
          <UseUserDetails
            render={({ data }) => (
              <Select
                onChange={e => setMerchantUser(e.target.value)}
                required
                value={merchantUser}
                placeholder="Loan Officer"
                label="Loan Officer"
              >
                {!assignedMerchantUser && (
                  <option value="" selected>
                    Select Loan Officer
                  </option>
                )}
                {assignedMerchantUser && (
                  <option value="unassigned">Unassigned</option>
                )}
                {data?.clientAccountUsersXn?.nodes?.map(accountUser => (
                  <>
                    <option key={accountUser?.id} value={accountUser?.id}>
                      {`${accountUser?.user?.firstName} ${accountUser?.user?.lastName}`}
                    </option>
                  </>
                ))}
              </Select>
            )}
          />

          <div className="v2-modal-button-wrapper">
            <Button
              classes="primary flex-grow"
              click_event={handleAssignAndUnassignApplicationToggle}
            >
              Cancel
            </Button>

            <Button
              disabled={!merchantUser?.length}
              classes="secondary flex-grow"
              click_event={handleSubmit}
            >
              Update
            </Button>
          </div>
        </ModalV2>
      )}
    </>
  );
};

export default AssignApplication;
