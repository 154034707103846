import { gql } from 'apollo-boost';

export const CREATE_LOAN_CATEGORY = gql`
  mutation MP_CreateLoanCategory($input: CreateLoanCategoryInput!) {
    createLoanCategory(input: $input) {
      id
      name
      status
    }
  }
`;

export const GET_LOAN_CATEGORIES = gql`
  query MP_GetLoanCategories {
    getLoanCategories {
      categories {
        id
        name
        description
        status
        createdAt
        durationType {
          name
        }
        updatedAt
        loanCategoryAttributes {
          id
          data
          value
          attribute {
            id
            name
            required
            data
          }
        }
        products {
          id
          name
          loanType
        }
      }
    }
  }
`;

export const GET_LOAN_CATEGORIES_DROPDOWN = gql`
  query MP_GetLoanCategories {
    getLoanCategories {
      categories {
        id
        name
      }
    }
  }
`;

export const UNLINK_PRODUCT_FROM_LOAN_CATEGORY = gql`
  mutation MP_UnlinkProductFromLoanCategory(
    $input: UnlinkProductFromLoanCategoryInput!
  ) {
    unlinkProductFromLoanCategory(input: $input) {
      success
      comments
    }
  }
`;

export const GET_ACTIVE_POLICIES = gql`
  query MP_policies {
    policies {
      id
      name
      status
      minLoanAmount
      maxLoanAmount
      minLoanDuration
      maxLoanDuration
      durationType
    }
  }
`;

export const UPDATE_LOAN_CATEGORY = gql`
  mutation MP_UpdateLoanCategory($input: UpdateLoanCategory!) {
    updateLoanCategory(input: $input) {
      success
      comments
    }
  }
`;

export const ENABLE_LOAN_CATEGORY = gql`
  mutation MP_EnableLoanCategory($input: ToggleLoanCategoryStateInput!) {
    enableLoanCategory(input: $input) {
      success
    }
  }
`;

export const DISABLE_LOAN_CATEGORY = gql`
  mutation MP_DisableLoanCategory($input: ToggleLoanCategoryStateInput!) {
    disableLoanCategory(input: $input) {
      success
    }
  }
`;

export const GET_LOAN_CATEGORY_ATTRIBUTES = gql`
  query MP_GetLoanCategoryAttributes {
    getLoanCategoryAttributes {
      success
      attributes {
        id
        name
        type
        data
        isOverwritable
      }
    }
  }
`;
