import React, { Fragment } from 'react';
import { Button, DataCard, Loader, Popup } from '../../../../components/index';
import { nairaAmount } from '../../../../lib/Utils';

const ReverseTransactionPopup = ({
  portfolioNumber,
  reversalAmount,
  transactionId,
  reverseTransactionPopup,
  handleReverseTransaction,
  reverseRepaymentTransactionIsLoading,
  triggerReverseRepaymentTransaction,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    triggerReverseRepaymentTransaction({
      transactionId,
    });
  };

  if (reverseRepaymentTransactionIsLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <div className="reverse-transaction">
        <Fragment>
          <Popup
            title={'Reverse Transaction'}
            classes="cash-repayment"
            active={reverseTransactionPopup}
          >
            <DataCard noPadding={true}>
              <p>
                Are you sure you want to reverse{' '}
                <span> {nairaAmount(reversalAmount)}</span> for <br /> Portfolio
                Number: <span>{portfolioNumber}</span> ?
              </p>
              <form onSubmit={handleSubmit}>
                <div className="buttonGroup paystack-repayment-button-group">
                  <Button onClick={handleReverseTransaction}>CANCEL</Button>

                  <Button classes="secondary" type="submit">
                    CONFIRM REVERSAL
                  </Button>
                </div>
              </form>
            </DataCard>
          </Popup>
        </Fragment>
      </div>
    </Fragment>
  );
};

export default ReverseTransactionPopup;
