import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { MODIFY_PROCESSING_FEE } from './queries';
import { useFormInput } from '../Form';
import { removeCommas, trimGraphQLError } from '../../lib/Utils';
import { APPLICATION_QUERY } from '../../graphql/queries';
import { useCustomState } from '../../lib/CustomHooks';

const ModifyProcessingFee = ({ application }) => {
  const modifyProcessingFeeDataInput = {
    showToast: useCustomState(false),
    showModal: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showNotificationPopup: useCustomState(false),
    processingFeeFixed: useFormInput(''),
    processingFeePercent: useFormInput(),
    wavieProcessingFee: useCustomState(),
  };
  const [checkboxState, setCheckboxState] = useState(false);
  const [processingFeeCheck, updateProcessingFeeCheck] = useState({
    checked: '',
  });

  let handleProcessingFeeCheck = value => {
    updateProcessingFeeCheck({ checked: value });
  };

  const [
    modifyApplicationProcessingFee,
    { loading: updateProcessingFeeLoader },
  ] = useMutation(MODIFY_PROCESSING_FEE, {
    onCompleted: () => {
      modifyProcessingFeeDataInput.showToast.setValue(true);
      modifyProcessingFeeDataInput.status.setValue('Success');
      modifyProcessingFeeDataInput.content.setValue('Processing Fee Updated');
      modifyProcessingFeeDataInput.showModal.setValue(false);
      modifyProcessingFeeDataInput.showNotificationPopup.setValue(true);

      handleHideProcessingFeeToast();
      handleHideNotification();
    },
    onError: error => {
      modifyProcessingFeeDataInput.showToast.setValue(true);
      modifyProcessingFeeDataInput.status.setValue('Failed');
      modifyProcessingFeeDataInput.content.setValue(
        trimGraphQLError(error.message),
      );

      handleHideProcessingFeeToast();
      handleProcessingFeeModalToggle();
    },
  });

  const handleHideProcessingFeeToast = () => {
    setTimeout(() => {
      modifyProcessingFeeDataInput.showToast.setValue(false);
    }, 3500);
  };

  const handleHideNotification = () => {
    setTimeout(() => {
      modifyProcessingFeeDataInput.showNotificationPopup.setValue(false);
    }, 3500);
  };

  const handleUpdateProcessingFee = e => {
    e.preventDefault();

    if (
      !(
        modifyProcessingFeeDataInput.processingFeeFixed.value ||
        modifyProcessingFeeDataInput.processingFeePercent.value
      ) &&
      checkboxState === false
    ) {
      modifyProcessingFeeDataInput.showToast.setValue(true);
      modifyProcessingFeeDataInput.status.setValue('Failed');
      modifyProcessingFeeDataInput.content.setValue('Please input a value');

      handleHideProcessingFeeToast();
      return;
    }

    modifyApplicationProcessingFee({
      variables: {
        input: {
          applicationId: application.id,
          processingFee:
            parseFloat(
              removeCommas(
                modifyProcessingFeeDataInput.processingFeeFixed.value,
              ),
            ) ||
            parseFloat(
              modifyProcessingFeeDataInput.processingFeePercent.value,
            ) ||
            parseFloat(modifyProcessingFeeDataInput.wavieProcessingFee.value),
          calcBy: processingFeeCheck.checked.value,
        },
      },
      refetchQueries: () => [
        {
          query: APPLICATION_QUERY,
          variables: { applicationNumber: application.applicationNumber },
        },
      ],
      awaitRefetchQueries: true,
    });

    modifyProcessingFeeDataInput.processingFeeFixed.setValue('');
    modifyProcessingFeeDataInput.processingFeePercent.setValue('');
    modifyProcessingFeeDataInput.wavieProcessingFee.setValue();
    updateProcessingFeeCheck({ checked: '' });
  };

  const handleProcessingFeeModalToggle = () => {
    modifyProcessingFeeDataInput.showModal.setValue(
      !modifyProcessingFeeDataInput.showModal.value,
    );
    modifyProcessingFeeDataInput.processingFeeFixed.setValue('');
    modifyProcessingFeeDataInput.processingFeePercent.setValue('');
    modifyProcessingFeeDataInput.wavieProcessingFee.setValue();
    setCheckboxState(false);
    updateProcessingFeeCheck({ checked: '' });
  };

  const handleCheckBoxInput = e => {
    const isChecked = e.target.checked;
    setCheckboxState(isChecked);
    if (isChecked) {
      modifyProcessingFeeDataInput.wavieProcessingFee.setValue(parseFloat(0));
    }
    if (!isChecked) {
      modifyProcessingFeeDataInput.wavieProcessingFee.setValue();
      setCheckboxState(false);
    }
  };

  return {
    handleHideProcessingFeeToast,
    updateProcessingFeeLoader,
    handleProcessingFeeModalToggle,
    modifyProcessingFeeDataInput,
    handleUpdateProcessingFee,
    handleCheckBoxInput,
    checkboxState,
    handleProcessingFeeCheck,
    processingFeeCheck,
    updateProcessingFeeCheck,
  };
};

export { ModifyProcessingFee };
