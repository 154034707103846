import React, { useEffect } from 'react';
import './_Layout.scss';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Page } from '../../components';
import {
  Login,
  NewPassword,
  ResetPassword,
  ResetPasswordComplete,
  ResetPasswordConfirmation,
  TriggerResetPassword,
} from '../../containers/Login';
import { AcceptInvite } from '../../containers/Invitation';
import config from '../../config/config';
import Favicons from '../Head/Favicon';

const Layout = () => {
  useEffect(() => {
    if (
      config.web.node_env === 'staging' ||
      config.web.node_env === 'production'
    ) {
      if (window.location.protocol !== 'https:') {
        window.location.href = `https:${window.location.href.substring(
          window.location.protocol.length,
        )}`;
      }
    }
  });

  return (
    localStorage.getItem('api-key') == null && (
      <section className={`layout`}>
        <Favicons />
        <Switch>
          <Route
            path="/login"
            exact
            render={props => (
              <Page {...props} component={Login} title="Login" />
            )}
          />
          <Route
            path="/trigger-reset-password"
            exact
            render={props => (
              <Page
                {...props}
                component={TriggerResetPassword}
                title="Trigger Reset Password"
              />
            )}
          />
          <Route
            path="/reset-password/:resetToken"
            exact
            render={props => (
              <Page
                {...props}
                component={ResetPassword}
                title="Reset Password"
              />
            )}
          />
          <Route
            path="/reset-password-confirmation"
            exact
            render={props => (
              <Page
                {...props}
                component={ResetPasswordConfirmation}
                title="Check your email"
              />
            )}
          />
          <Route
            path="/new-password"
            exact
            render={props => (
              <Page
                {...props}
                component={NewPassword}
                title="Create New Password"
              />
            )}
          />
          <Route
            path="/password-reset-complete"
            exact
            render={props => (
              <Page
                {...props}
                component={ResetPasswordComplete}
                title="Congratulations, your password has been reset"
              />
            )}
          />
          <Route
            path="/accept-invite/:invitationToken"
            exact
            render={props => (
              <Page {...props} component={AcceptInvite} title="Accept Invite" />
            )}
          />
        </Switch>
      </section>
    )
  );
};

export default withRouter(Layout);
