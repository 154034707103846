import { gql } from 'apollo-boost';

export const MP_PORTFOLIO_VIEW_QUERY = gql`
  query MP_LoansQuery(
    $cursor: ConnectionCursor
    $first: ConnectionLimitInt
    $id: String
    $email: String
    $name: String
    $status: String
    $amountLt: Float
    $amountGt: Float
    $repaymentAmountLt: Float
    $repaymentAmountGt: Float
    $loanDateLt: DateTime
    $loanDateGt: DateTime
    $repaymentDateLt: DateTime
    $repaymentDateGt: DateTime
  ) {
    mp {
      portfolios(
        first: $first
        after: $cursor
        orderBy: createdAt_DESC
        where: {
          portfolioNumber: $id
          status: $status
          amount_lte: $amountGt
          amount_gte: $amountLt
          fullAmount_lte: $repaymentAmountGt
          fullAmount_gte: $repaymentAmountLt
          createdAt_lt: $loanDateLt
          createdAt_gte: $loanDateGt
          dateOfRepayment_lt: $repaymentDateLt
          dateOfRepayment_gte: $repaymentDateGt
          customer: { email_contains: $email, fullName_contains: $name }
        }
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        nodes {
          id
          status {
            name
          }
          email
          fullName
          amount
          portfolioNumber
          fullAmount
          dateOfRepayment
          finalDateOfRepayment
          customerId
        }
        edges {
          cursor
        }
      }
    }
  }
`;
