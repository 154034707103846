import React, { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import {
  BreadCrumbSection,
  Button,
  CustomerSummary,
  DataCard,
  Loader,
  NotFound,
  Page,
  Popup,
  Toast,
  InterestWallet,
  DisbursementWallet,
  CollectionsWallet,
  EmptyScreen,
} from '../../components';
import { nairaAmount } from '../../lib/Utils';
import {
  UpdateStandardWalletBalance,
  LiquidityManagementSummary,
} from '../../Providers';
import ManualRepayments from './ManualRepayments';
import { ErrorBoundary } from 'react-error-boundary';

const LiquidityManagement = () => {
  const { data, loading } = LiquidityManagementSummary();
  return loading ? (
    <Loader />
  ) : (
    <>
      <div>
        <BreadCrumbSection />

        <main className="layout-main">
          <div className="wallet-title">
            <h5>Account Wallet Summary</h5>
            <section>
              <UpdateStandardWalletBalance
                render={({
                  input,
                  handleHideToast,
                  standardWalletBalanceLoader,
                  handleUpdateStandardWalletBalance,
                  showUpdateStandardWalletBalancePopup,
                  hideUpdateStandardWalletBalancePopup,
                }) => (
                  <Fragment>
                    {standardWalletBalanceLoader && <Loader />}
                    <Toast
                      title="Update Wallet Balance"
                      content={input.content.value}
                      classes={input.status.value}
                      active={input.showToast.value}
                      clickEvent={handleHideToast}
                    />
                    <Button
                      classes="blue"
                      click_event={showUpdateStandardWalletBalancePopup}
                    >
                      Top Up Wallet Balance
                    </Button>
                    <Popup
                      title={
                        'Are you sure you want to top up your wallet balance?'
                      }
                      classes="cash-repayment"
                      active={input.showPopup.value}
                    >
                      <DataCard noPadding={true}>
                        <div className="wallet-popup-button">
                          <Button
                            click_event={handleUpdateStandardWalletBalance}
                            classes="blue"
                          >
                            Accept
                          </Button>
                          <Button
                            click_event={hideUpdateStandardWalletBalancePopup}
                          >
                            Cancel
                          </Button>
                        </div>
                      </DataCard>
                    </Popup>
                  </Fragment>
                )}
              />
            </section>
          </div>

          <CustomerSummary title="Account Wallet" classes={'four-grid'}>
            {data && data.getWalletSummary && (
              <Fragment>
                <div>
                  <h5>Wallet Balance</h5>
                  <h3>
                    {nairaAmount(
                      data.getWalletSummary.account.wallets.disbursementWallet
                        .amount,
                    )}
                  </h3>
                </div>
                <div>
                  <h5>Total Disbursements</h5>
                  <h3>
                    {nairaAmount(
                      data.getWalletSummary.account.wallets.walletSummary
                        .totalDisbursment,
                    )}
                  </h3>
                </div>
                <div>
                  <h5>Total Interest</h5>
                  <h3>
                    {nairaAmount(
                      data.getWalletSummary.account.wallets.walletSummary
                        .totalInterest,
                    )}
                  </h3>
                </div>
                <div>
                  <h5>Total Collections</h5>
                  <h3>
                    {nairaAmount(
                      data.getWalletSummary.account.wallets.walletSummary
                        .totalCollection,
                    )}
                  </h3>
                </div>
              </Fragment>
            )}
          </CustomerSummary>
        </main>
        <ErrorBoundary
          fallback={
            <DataCard noPadding={true} showDialog>
              <EmptyScreen message="Error Fetching transaction" />
            </DataCard>
          }
        >
          <Fragment>
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <Page
                    {...props}
                    component={DisbursementWallet}
                    title="Liquidity Management"
                  />
                )}
              />
              <Route
                exact
                path="*"
                render={props => (
                  <Page {...props} component={NotFound} title="Not Found" />
                )}
              />
            </Switch>
          </Fragment>
        </ErrorBoundary>
      </div>
    </>
  );
};
export default withRouter(LiquidityManagement);
