import React, { Fragment } from 'react';
import { withRouter, NavLink, Link } from 'react-router-dom';
import cx from 'classnames';
import { useClientInfo } from '../../Providers-new/Client';
import {
  useMutateRuleSetDelete,
  useMutateRuleSetActive,
  useMutateRuleSetInActive,
} from '../../Providers-new/LoanRules';
import {
  BreadCrumbSection,
  Button,
  DataCard,
  EmptyScreen,
  Input,
  Loader,
  SideBar,
  Table,
  Modal,
  Toast,
  Close,
  MoreOptions,
  Select,
} from '../../components';

import './_Styles.scss';
import { useGetPolicy } from '../../Providers-new/UserManagement/useGetPolicy';

const LoanRules = ({
  tableHeader,
  ruleSets,
  handleReset,
  ruleSetInputs,
  runSearchQuery,
  policyList,
}) => {
  const {
    handleSetDeleteToast,
    handleHideDeleteRuleSetToggle,
    handleRuleSetDelete,
    setDeleteData,
    setDeleteForm,
  } = useMutateRuleSetDelete();
  const {
    handleSetActiveToast,
    handleRuleSetEnableToggle,
    handleRuleSetEnable,
    setActiveData,
    form,
  } = useMutateRuleSetActive();
  const {
    handleSetInActiveToast,
    handleRuleSetDisableToggle,
    handleRuleSetDisable,
    setInActiveData,
    setInactiveForm,
  } = useMutateRuleSetInActive();

  return (
    <>
      <BreadCrumbSection>
        <a className="button secondary" href="/settings/loan-rules/create">
          Create New Rule
        </a>
      </BreadCrumbSection>
      <Fragment>
        <SideBar>
          <section>
            <h4 className={'sidebar-header'}>Search Parameter</h4>

            <form>
              <Input
                data-testid="rulesearch-rulename"
                label="Rule Name"
                placeholder="Enter Product Name"
                {...ruleSetInputs.name.input}
              />
              <Select
                label="Assigned To"
                {...ruleSetInputs.policyId.input}
                data-testid="rulesearch-assignedTo"
              >
                <option disabled value="">
                  --Select Assigned To--
                </option>
                {policyList &&
                  policyList.map((status, key) => (
                    <option key={key} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </Select>
              <div>
                <Button click_event={handleReset} data-testid="reset-button">
                  Reset
                </Button>
                <Button
                  data-testid="search-button"
                  classes={'right secondary'}
                  click_event={runSearchQuery}
                >
                  Search
                </Button>
              </div>
            </form>
          </section>
        </SideBar>

        {/* popup to handle delete of of selected rule set */}
        <Fragment>
          <Toast
            title="Delete Rule Set"
            content={setDeleteData.content.value}
            classes={setDeleteData.status.value}
            active={setDeleteData.showToast.value}
            clickEvent={handleSetDeleteToast}
          />
          <Modal
            title={'Delete Selected Rule Set'}
            active={setDeleteData.showDelRuleSetPopup.value}
          >
            <Close
              className="close-icon"
              onClick={e => handleHideDeleteRuleSetToggle(e)}
            />
            <div className="content center">
              {' '}
              Are you sure you want to delete this -{' '}
              <span className="caption">
                {setDeleteForm.selectedRule.value.name}{' '}
              </span>{' '}
              - Rule?
            </div>
            <div className="actions">
              <div className="flex-container center">
                <Button classes="secondary" click_event={handleRuleSetDelete}>
                  Delete
                </Button>
              </div>
            </div>
          </Modal>
        </Fragment>

        {/* popup to handle set enable of selected rule set */}
        <Fragment>
          <Toast
            title="Activate Selected Rule Set"
            content={setActiveData.content.value}
            classes={setActiveData.status.value}
            active={setActiveData.showToast.value}
            clickEvent={handleSetActiveToast}
          />
          <Modal
            title={'Activate Selected Rule Set'}
            active={setActiveData.showDelRuleSetPopup.value}
          >
            <Close
              className="close-icon"
              onClick={e => handleRuleSetEnableToggle(e)}
            />
            <div className="content center">
              {' '}
              Are you sure you want to activate this -{' '}
              <span className="caption">{form.selectedRule.value.name} </span> -
              Rule?
            </div>
            <div className="actions">
              <div className="flex-container center">
                <Button classes="secondary" click_event={handleRuleSetEnable}>
                  Set Active
                </Button>
              </div>
            </div>
          </Modal>
        </Fragment>

        {/* popup to handle set disable of selected rule set */}
        <Fragment>
          <Toast
            title="Disable Selected Rule Set"
            content={setInActiveData.content.value}
            classes={setInActiveData.status.value}
            active={setInActiveData.showToast.value}
            clickEvent={handleSetInActiveToast}
          />
          <Modal
            title={'Disable Selected Rule Set'}
            active={setInActiveData.showDelRuleSetPopup.value}
          >
            <Close
              className="close-icon"
              onClick={e => handleRuleSetDisableToggle(e)}
            />
            <div className="content center">
              {' '}
              Are you sure you want to disable this -{' '}
              <span className="caption">
                {setInactiveForm.selectedRule.value.name}{' '}
              </span>{' '}
              - Rule?
            </div>
            <div className="actions">
              <div className="flex-container center">
                <Button classes="secondary" click_event={handleRuleSetDisable}>
                  Disable
                </Button>
              </div>
            </div>
          </Modal>
        </Fragment>

        <main className={'layout-main'}>
          <div className="loan_rules_wrapper">
            {ruleSets && ruleSets.length > 0 ? (
              <Fragment>
                <DataCard noPadding={false}>
                  <Table>
                    <div className="table__head">
                      {tableHeader.map((item, key) => (
                        <span
                          key={`${item}_${key}`}
                          className={cx({
                            'checked-width': item === 'Status' || item === '',
                          })}
                        >
                          {' '}
                          {item}{' '}
                        </span>
                      ))}
                    </div>
                    <div className="table__body">
                      {ruleSets &&
                        ruleSets.length > 0 &&
                        ruleSets.map((ruleSet, key) => (
                          <ul key={`${key}${ruleSet.id}`}>
                            <li className="table_line">
                              <Link to="#">
                                <span className="checked-width">
                                  <span
                                    className={`status fixed ${
                                      ruleSet.status.toLowerCase() || ''
                                    }`}
                                  >
                                    {ruleSet.status}
                                  </span>
                                </span>
                                <span>{ruleSet.name} </span>
                                <span>
                                  {ruleSet.policies.length >= 1 &&
                                    ruleSet.policies.map((policy, idx) => (
                                      <span
                                        key={`${policy.id}_${idx}`}
                                        className="ruleSet_policies"
                                      >
                                        {policy.name}
                                        {ruleSet.policies.length !== idx + 1
                                          ? ','
                                          : ''}
                                      </span>
                                    ))}
                                  {ruleSet.policies.length < 1 && (
                                    <span>None</span>
                                  )}
                                </span>
                              </Link>
                              <span className="action_button checked-width">
                                <MoreOptions>
                                  <NavLink
                                    to={`/settings/loan-rules/${encodeURIComponent(
                                      ruleSet.name,
                                    )}`}
                                  >
                                    Edit
                                  </NavLink>
                                  {ruleSet.status !== 'ACTIVE' ? (
                                    <div
                                      onClick={e =>
                                        handleRuleSetEnableToggle(e, ruleSet)
                                      }
                                    >
                                      Set Active
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {ruleSet.status === 'ACTIVE' ? (
                                    <div
                                      onClick={e =>
                                        handleRuleSetDisableToggle(e, ruleSet)
                                      }
                                    >
                                      Disable
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div
                                    onClick={e =>
                                      handleHideDeleteRuleSetToggle(e, ruleSet)
                                    }
                                  >
                                    Delete
                                  </div>
                                </MoreOptions>
                              </span>
                            </li>
                          </ul>
                        ))}
                    </div>
                  </Table>
                </DataCard>
              </Fragment>
            ) : (
              <DataCard noPadding={true}>
                <EmptyScreen message="No RuleSet Record Found" />
              </DataCard>
            )}
          </div>
        </main>
      </Fragment>
    </>
  );
};

const LoanRulesWrapper = () => {
  const tHeader = ['Status', 'Name', 'Assigned to', ''];
  const { loading, result, handleReset, ruleSetInputs, runSearchQuery } =
    useClientInfo({ privateFields: true });
  const { loading: pLoading, result: pResult } = useGetPolicy();

  if (loading || pLoading) return <Loader />;

  let ruleSets = result.data ? result.data.clientInfo.ruleSets : {};

  ruleSets =
    Object.entries(ruleSets).length === 0 || ruleSets.totalCount === 0
      ? []
      : ruleSets.nodes;

  let policyList =
    pResult.data && pResult.data.viewer ? pResult.data.viewer.account : [];

  return (
    <LoanRules
      tableHeader={tHeader}
      loading={loading}
      ruleSets={ruleSets}
      handleReset={handleReset}
      ruleSetInputs={ruleSetInputs}
      runSearchQuery={runSearchQuery}
      policyList={policyList.policies ? policyList.policies.nodes : []}
    />
  );
};

export default withRouter(LoanRulesWrapper);
