import React from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import { useMutatePolicyCreate } from '../../Providers-new/UserManagement';
import { useGetApprovalWorkflows } from '../../Providers-new/ApprovalWorkflow';
import { useClientInfo } from '../../Providers-new/Client';
import { Loader } from '../../components';
import { useQuery } from '@apollo/react-hooks';
import { GET_ENUM } from '../../Providers-new/EnumTypes';
import { withRouter } from 'react-router-dom';
import RegularLoans from './RegularLoans';
import RepaymentDetails from './RepaymentDetails';
import { INTEREST_CALC_METHOD_QUERY } from '../../Providers-new/UserManagement/queries';
import { useGetPolicy } from '../../Providers-new/UserManagement/useGetPolicy';
import { getQueryParam } from '../../lib/Utils';

const steps = [{ id: 'regular_loans' }, { id: 'repayment_details' }];

const defaultData = {
  name: '',
  minLoanAmount: '',
  maxLoanAmount: '',
  durationType: '',
  minLoanDuration: '',
  maxLoanDuration: '',
  interestRate: { name: '', calcBy: '', value: '' },
  ruleSetIds: '',
  approvalWorkflowId: '',
  repaymentType: '',
  processingFee: { name: '', calcBy: '', value: '' },
  lateFeeCalculation: {
    lateFeeChargeOn: '',
    lateFeeFrequency: '',
    penaltyDelay: '',
    interestCalculationMethod: '',
    rate: { name: '', calcBy: '', value: '' },
  },
  allowEarlyRepayment: false,
  offerLetterEnabled: false,
  maxLateFeeDuration: '',
  requiresDebitMandate: false,
  validityInDays: '',
};

const CreateLoanProduct = ({
  loanTypes,
  nigeriaStates,
  approvalWorkflowList,
  ruleSets,
  lateFeeCharge,
  lateFeeFreq,
  productName,
  policyList,
  rateCalcByData,
  repaymentTypeData,
  interestCalcMethodData,
  processingCalcby,
  productId,
  loanDurationType,
  loanDescription,
  requiresRemitaMandate,
}) => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const {
    loading,
    data: policyCreateData,
    error,
    handleStartDate,
    handleEndDate,
    handleCreatePolicy,
    inputs: policyCreateInput,
    cycleState,
    addNewStep,
    removeStep,
    handleCycleChange,
    handleValidityPeriod,
    handleProcessingFeeCheck,
    processingFeeCheck,
    updateProcessingFeeCheck,
    handleUpdatePolicy,
    policyLoadingUpdate,
    validityPeriod,
    handleLoanType,
    loanTypeValue,
    loanTypeSetValue,
    setOptInForLateFees,
    optInForLateFees,
  } = useMutatePolicyCreate({ productName, productId, policyList, formData });

  const props = {
    formData,
    setForm,
    navigation,
    nigeriaStates,
    loanTypes,
    approvalWorkflowList,
    ruleSets,
    lateFeeCharge,
    lateFeeFreq,
    cycleState,
    addNewStep,
    removeStep,
    handleCycleChange,
    rateCalcByData,
    repaymentTypeData,
    handleCreatePolicy,
    handleStartDate,
    handleEndDate,
    policyCreateData,
    error,
    policyCreateInput,
    handleValidityPeriod,
    handleProcessingFeeCheck,
    processingFeeCheck,
    updateProcessingFeeCheck,
    interestCalcMethodData,
    handleUpdatePolicy,
    processingCalcby,
    loading,
    policyLoadingUpdate,
    validityPeriod,
    loanDurationType,
    loanDescription,
    handleLoanType,
    loanTypeSetValue,
    loanTypeValue,
    requiresRemitaMandate,
    setOptInForLateFees,
    optInForLateFees,
  };

  switch (id) {
    case 'regular_loans':
      return <RegularLoans {...props} />;
    case 'repayment_details':
      return <RepaymentDetails {...props} />;
    default:
      return null;
  }
};

const LoanProductWrapper = (render = {}) => {
  let productName = render.match.params.productName
    ? render.match.params.productName
    : null;

  if (!productName) {
    productName = getQueryParam('productName');
  }

  const { loading: qLoading, result: qResult } = useGetApprovalWorkflows();

  const { loading: clientInfoLoading, result: clientInfoResult } =
    useClientInfo({
      privateFields: true,
    });

  const requiresRemitaMandate =
    clientInfoResult?.data?.clientInfo.requiresRemitaMandate;

  const { loading: loanTypesLoading, data: loanTypes } = useQuery(GET_ENUM, {
    variables: {
      type: 'LoanTypes',
    },
  });

  const { loading: lateFeeChargeLoading, data: lateFeeCharge } = useQuery(
    GET_ENUM,
    {
      variables: {
        type: 'LateFeeChargOn',
      },
    },
  );

  const { loading: lateFeeFreqLoading, data: lateFeeFreq } = useQuery(
    GET_ENUM,
    {
      variables: {
        type: 'LateFeeFrequency',
      },
    },
  );

  const { loading: rateCalcByLoading, data: rateCalcByData } = useQuery(
    GET_ENUM,
    {
      variables: {
        type: 'RateCalcBy',
      },
    },
  );

  const { loading: repaymentTypeLoading, data: repaymentTypeData } = useQuery(
    GET_ENUM,
    {
      variables: {
        type: 'RepaymentType',
      },
    },
  );

  const { loading: durationTypeLoading, data: loanDurationType } = useQuery(
    GET_ENUM,
    {
      variables: {
        type: 'DurationType',
      },
    },
  );

  const { loading: pLoading, result: pResult } = useGetPolicy();

  const { loading: interestCalcMethodLoading, data: interestCalcMethodData } =
    useQuery(INTEREST_CALC_METHOD_QUERY);

  if (
    loanTypesLoading ||
    qLoading ||
    clientInfoLoading ||
    lateFeeChargeLoading ||
    lateFeeFreqLoading ||
    rateCalcByLoading ||
    repaymentTypeLoading ||
    interestCalcMethodLoading ||
    pLoading ||
    durationTypeLoading
  )
    return <Loader />;
  const approvalWorkflowList = qResult?.data?.approvalWorkflows?.nodes || [];

  let policyList = pResult?.data ? pResult?.data?.viewer?.account : [];

  let ruleSets = clientInfoResult.data
    ? clientInfoResult.data.clientInfo.ruleSets
    : {};

  ruleSets =
    Object.entries(ruleSets).length === 0 || ruleSets.totalCount === 0
      ? []
      : ruleSets.nodes;

  let actions = [];
  if (loanTypes) actions = loanTypes.enumValues;

  let lateFeeChargeType = [];
  if (lateFeeCharge) lateFeeChargeType = lateFeeCharge.enumValues;

  let lateFeeFreqDuration = [];
  if (lateFeeFreq) lateFeeFreqDuration = lateFeeFreq.enumValues;

  let rateCalcBy = [];
  if (rateCalcByData) rateCalcBy = rateCalcByData.enumValues;

  let repaymentType = [];
  if (repaymentTypeData) repaymentType = repaymentTypeData.enumValues;

  let duration = [];
  if (loanDurationType) duration = loanDurationType.enumValues;

  let pol = policyList?.policies ? policyList.policies.nodes : null;
  let productInView =
    pol != null ? pol.find(item => item.name === productName) : null;
  let productId = productInView ? productInView.id : null;

  let processingCalcby = productInView?.processingFee?.calcBy;

  const loanDescription = data => {
    let response;

    switch (data) {
      case 'WEEKLY':
        response = 'Weeks';
        break;
      case 'MONTHLY':
        response = 'Months';
        break;
      case 'ANNUALLY':
        response = 'Years';
        break;
      default:
        response = 'Days';
    }
    return response;
  };

  return (
    <CreateLoanProduct
      loanTypes={actions}
      lateFeeCharge={lateFeeChargeType}
      lateFeeFreq={lateFeeFreqDuration}
      approvalWorkflowList={approvalWorkflowList}
      ruleSets={ruleSets}
      productName={productName}
      productId={productId}
      policyList={policyList.policies ? policyList.policies.nodes : []}
      rateCalcByData={rateCalcBy}
      repaymentTypeData={repaymentType}
      loanDurationType={duration}
      interestCalcMethodData={interestCalcMethodData}
      processingCalcby={processingCalcby}
      loanDescription={loanDescription}
      requiresRemitaMandate={requiresRemitaMandate}
    />
  );
};

export default withRouter(LoanProductWrapper);
