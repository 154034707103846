import React from 'react';
import PropTypes from 'prop-types';
import './_CheckBoxGroup.scss';

const CheckBoxGroup = ({ classes, children }) => {
  return (
    <div className={`checkbox-group ${classes == null ? '' : classes}`}>
      {children}
    </div>
  );
};

CheckBoxGroup.propTypes = {
  classes: PropTypes.string,
};

export default CheckBoxGroup;
