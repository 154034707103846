import moment from 'moment';
import React, { Fragment } from 'react';
import { DataCard } from '../../../../components';
import { removeUnderscoreAndCapitalizeInitialLetters } from '../../../../lib/Utils';

const RecovaMandateHistoryTab = ({ histories }) => {
  const historyNodes = [...histories.nodes];

  return (
    <Fragment>
      <div className="history-timeline">
        <DataCard>
          <div className="timeline">
            <div className="timeline-body">
              <div className="timeline-item">
                <ul>
                  {historyNodes?.map((history, index) => (
                    <li key={index}>
                      <div className="timeline-content">
                        <p>{history.comment || 'N/A'}</p>
                        <div className="history_info">
                          <p className="info">
                            <span className="title">Date:</span>
                            {moment(history.updatedAt).format('llll')}
                          </p>
                          <p className="info">
                            <span className="title">Status:</span>
                            {removeUnderscoreAndCapitalizeInitialLetters(
                              history.newStatus.name,
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default RecovaMandateHistoryTab;
