import { gql } from 'apollo-boost';

export const GET_ALL_PERMISSIONS = gql`
  query MP_GetAllPermissions {
    availablePermissions(where: {}) {
      id
      name
      description
      category
    }
  }
`;
