import { gql } from 'apollo-boost';

/**
 * 1. I added variable type name to allow reuse of the query. Deleted the fixed name, ClientUserRoles, that was being passed.
 * 2. I think it's also important to note that not all types will have enum values
 * @type {any}
 */
export const GET_ENUM = gql`
  query MP_GetEnum($type: Enum!) {
    enumValues(type: $type) {
      name
    }
  }
`;

export const GET_STATUS_ENUM = gql`
  query MP_GetStatusEnum($type1: Enum!, $type2: Enum!) {
    portfolioEnum: enumValues(type: $type1) {
      name
    }
    applicationEnum: enumValues(type: $type2) {
      name
    }
  }
`;
