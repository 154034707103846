import * as React from 'react';

function ApiIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)" fill="#07F">
        <path d="M23.53 11.531h-2.062v-.904a.469.469 0 00-.355-.455l-1.389-.346L19 8.076l.736-1.226a.469.469 0 00-.07-.573l-1.942-1.942a.469.469 0 00-.573-.07L15.924 5l-1.75-.726-.347-1.388a.469.469 0 00-.454-.355h-.905V.469a.469.469 0 00-.937 0v2.063h-.905a.469.469 0 00-.454.355l-.346 1.388-1.751.726-1.226-.737a.469.469 0 00-.573.07L4.334 6.278a.469.469 0 00-.07.573L5 8.075l-.725 1.751-1.388.346a.469.469 0 00-.356.455v.904H.47a.469.469 0 100 .938H2.53v.904c0 .215.147.403.356.455l1.388.346L5 15.925l-.736 1.226a.469.469 0 00.07.573l1.942 1.942a.469.469 0 00.573.07l1.226-.737 1.75.726.347 1.388c.052.209.24.355.455.355h.904v2.063a.469.469 0 00.937 0v-2.063h.905a.469.469 0 00.455-.355l.345-1.388L15.925 19l1.225.737c.184.11.42.082.573-.07l1.942-1.942a.469.469 0 00.07-.573L19 15.925l.726-1.751 1.388-.346a.469.469 0 00.355-.455v-.904h2.063a.469.469 0 100-.938zm-3 1.476l-1.262.314a.469.469 0 00-.32.276l-.906 2.188a.469.469 0 00.031.42l.67 1.115-1.424 1.424-1.114-.67a.469.469 0 00-.421-.031l-2.188.907a.469.469 0 00-.275.32l-.315 1.261h-2.013l-.315-1.262a.469.469 0 00-.275-.32l-2.188-.906a.469.469 0 00-.42.031l-1.115.67-1.424-1.424.67-1.114a.469.469 0 00.03-.421l-.906-2.188a.469.469 0 00-.32-.276l-1.261-.314v-2.014l1.261-.314a.469.469 0 00.32-.275l.907-2.188a.469.469 0 00-.031-.421l-.67-1.114L6.68 5.257l1.114.67a.469.469 0 00.421.03l2.188-.906a.469.469 0 00.275-.32l.315-1.262h2.013l.315 1.262a.469.469 0 00.275.32l2.188.906a.469.469 0 00.42-.03l1.115-.67 1.424 1.424-.67 1.114a.469.469 0 00-.03.42l.906 2.189c.057.137.175.239.32.275l1.262.314v2.014z" />
        <path d="M14.067 6.366a.469.469 0 00-.323.88A5.081 5.081 0 0117.062 12a5.068 5.068 0 01-5.063 5.062A5.068 5.068 0 016.937 12 5.069 5.069 0 019.95 7.37a.469.469 0 10-.38-.857A6.007 6.007 0 006 12c0 3.308 2.691 6 6 6 3.308 0 6-2.692 6-6 0-2.507-1.581-4.77-3.933-5.634z" />
        <path d="M12.024 6H12a.469.469 0 100 .938h.023A.469.469 0 0012.024 6zM15.265 10.135a.469.469 0 00-.469.47v2.731a.469.469 0 10.938 0v-2.732a.469.469 0 00-.469-.469zM12.992 10.135h-.966a.469.469 0 00-.469.47v2.731a.469.469 0 00.938 0v-.69h.497c.654 0 1.186-.531 1.186-1.185v-.14c0-.654-.532-1.186-1.186-1.186zm.248 1.326a.249.249 0 01-.248.248h-.497v-.636h.497c.137 0 .248.111.248.248v.14zM11.055 13.223L9.852 10.42a.469.469 0 00-.861 0l-1.203 2.804a.469.469 0 10.861.37l.772-1.8.773 1.8a.469.469 0 10.861-.37zM22.406 0a1.596 1.596 0 00-1.408 2.339l-1.794 1.793a.469.469 0 10.663.663l1.793-1.793A1.595 1.595 0 0024 1.594C24 .715 23.285 0 22.406 0zm0 2.25a.657.657 0 11.002-1.314.657.657 0 01-.002 1.314zM4.795 19.205a.469.469 0 00-.663 0l-1.793 1.793A1.596 1.596 0 000 22.406c0 .879.715 1.593 1.594 1.593a1.595 1.595 0 001.408-2.338l1.793-1.793a.469.469 0 000-.663zm-3.201 3.857a.657.657 0 11.001-1.314.657.657 0 01-.001 1.314zM19.868 19.205a.472.472 0 00-.8.331.472.472 0 00.468.469.47.47 0 00.332-.8zM22.406 20.812c-.269 0-.523.068-.745.186l-.792-.792a.469.469 0 00-.663.663l.792.792A1.596 1.596 0 0022.407 24c.878 0 1.593-.714 1.593-1.593s-.715-1.594-1.594-1.594zm0 2.25a.657.657 0 11.002-1.314.657.657 0 01-.002 1.314zM4.795 4.132L3.002 2.34A1.595 1.595 0 001.594 0C.714 0 0 .716 0 1.594a1.595 1.595 0 002.339 1.409l1.793 1.792a.467.467 0 00.663 0 .469.469 0 000-.663zM1.594 2.251A.657.657 0 111.595.937a.657.657 0 01-.001 1.314z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ApiIcon;
