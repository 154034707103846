import { CUSTOMER_SUMMARY } from '@/graphql/queries/customers';
import { useQuery } from '@apollo/client';

export const useCustomerSummary = () => {
  const { data, loading, error } = useQuery(CUSTOMER_SUMMARY, {
    notifyOnNetworkStatusChange: true,
    onError: () => {},
  });

  return {
    customerSummaryError: error,
    customerSummaryLoading: loading,
    customerSummary: data?.customerSummary,
  };
};
