import React, { Fragment } from 'react';
import {
  Button,
  Toast,
  Loader,
  Modal,
  Close,
} from '../../../../components/index';

const CloseOutLoan = ({
  closeOutLoanToast,
  closeOutLoanLoading,
  closeOutLoanToggle,
  closeOutLoanData,
  handleCloseOutLoan,
}) => {
  return (
    <Fragment>
      {closeOutLoanLoading ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          <Toast
            title="Close out Loan"
            content={closeOutLoanData.content.value}
            clickEvent={closeOutLoanToast}
            active={closeOutLoanData.showToast.value}
            classes={closeOutLoanData.status.value}
          />

          <Modal
            title={'Close Out Loan'}
            classes="repayment-date-modal"
            active={closeOutLoanData.showModal.value}
          >
            <Close className="close-icon" onClick={closeOutLoanToggle} />
            <form onSubmit={handleCloseOutLoan}>
              <p className="close-out-loan_text">
                You are attempting to close out the loan. Are you sure you want
                to close out the loan?
              </p>

              <div className="repayment-double-buttons">
                <Button classes="cancel" click_event={closeOutLoanToggle}>
                  Cancel
                </Button>
                <Button classes="secondary blue save" type="submit">
                  Update
                </Button>
              </div>
            </form>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CloseOutLoan;
