import React from 'react';
import { withRouter } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import { GET_ALL_PERMISSIONS } from '../graphql/queries';
import { Loader } from '../components';

const AppPermissionList = ({ render }) => {
  return (
    <Query
      query={GET_ALL_PERMISSIONS}
      notifyOnNetworkStatusChange={true}
      fetchPolicy={'network-only'}
    >
      {({ loading, data }) => {
        if (loading) return <Loader />;

        return render({
          permissionList: data.availablePermissions,
        });
      }}
    </Query>
  );
};

export default withRouter(AppPermissionList);
