import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useState } from 'react';
import { MP_PORTFOLIO_VIEW_QUERY } from './queries';
import { connection_limit } from '../../config/config';
import { useCustomState } from '../../lib/CustomHooks';
import {
  normalizeDateTime,
  removeCommas,
  trimGraphQLError,
} from '../../lib/Utils';
import { toast } from 'react-toastify';
import Constants from '../../lib/Constants';

const usePortfoliosView = () => {
  let output = {
    portfolios: [],
  };
  const [isLoading, setIsLoading] = useState(false);

  const persistedPortfolioInput =
    JSON.parse(localStorage.getItem('portfolioInput')) || {};

  const {
    id,
    status,
    email,
    name,
    amountLt,
    amountGt,
    repaymentAmountLt,
    repaymentAmountGt,
    loanDateLt,
    loanDateGt,
    repaymentDateLt,
    repaymentDateGt,
  } = persistedPortfolioInput;

  //reformat persisted dates
  if (loanDateLt && loanDateLt.value) {
    loanDateLt.value = normalizeDateTime(loanDateLt.value);
  }
  if (loanDateGt && loanDateGt.value) {
    loanDateGt.value = normalizeDateTime(loanDateGt.value);
  }
  if (repaymentDateLt && repaymentDateLt.value) {
    repaymentDateLt.value = normalizeDateTime(repaymentDateLt.value);
  }
  if (repaymentDateGt && repaymentDateGt.value) {
    repaymentDateGt.value = normalizeDateTime(repaymentDateGt.value);
  }

  const portfolioInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    searchParams: {
      id: useCustomState(id?.value || ''),
      status: useCustomState(status?.value || ''),
      email: useCustomState(email?.value || ''),
      name: useCustomState(name?.value || ''),
      amountLt: useCustomState(amountLt?.value || ''),
      amountGt: useCustomState(amountGt?.value || ''),
      repaymentAmountLt: useCustomState(repaymentAmountLt?.value || ''),
      repaymentAmountGt: useCustomState(repaymentAmountGt?.value || ''),
      loanDateLt: useCustomState(loanDateLt?.value || ''),
      loanDateGt: useCustomState(loanDateGt?.value || ''),
      repaymentDateLt: useCustomState(repaymentDateLt?.value || ''),
      repaymentDateGt: useCustomState(repaymentDateGt?.value || ''),
      isSearchPerformed: useCustomState(false),
    },
  };

  const setSearchParams = searchParams => {
    const {
      id,
      status,
      email,
      name,
      amountLt,
      amountGt,
      repaymentAmountLt,
      repaymentAmountGt,
      loanDateLt,
      loanDateGt,
      repaymentDateLt,
      repaymentDateGt,
    } = searchParams;

    return {
      id: id.value ? id.value : undefined,
      status: status.value ? status.value : undefined,
      email: email.value ? email.value : undefined,
      name: name.value ? name.value : undefined,
      amountLt: amountLt.value
        ? parseFloat(removeCommas(amountLt.value))
        : undefined,
      amountGt: amountGt.value
        ? parseFloat(removeCommas(amountGt.value))
        : undefined,
      repaymentAmountLt: repaymentAmountLt.value
        ? parseFloat(removeCommas(repaymentAmountLt.value))
        : undefined,
      repaymentAmountGt: repaymentAmountGt.value
        ? parseFloat(removeCommas(repaymentAmountGt.value))
        : undefined,
      loanDateLt: loanDateLt.value || undefined,
      loanDateGt: loanDateGt.value || undefined,
      repaymentDateLt: repaymentDateLt.value || undefined,
      repaymentDateGt: repaymentDateGt.value || undefined,
      first: connection_limit.value,
    };
  };

  const handleHideToast = e => {
    e.preventDefault();

    portfolioInput.showToast.setValue(false);
  };

  const qParams = persistedPortfolioInput.id
    ? setSearchParams(persistedPortfolioInput)
    : {};

  const qResult = useQuery(MP_PORTFOLIO_VIEW_QUERY, {
    variables: { first: connection_limit.value, ...qParams },
  });
  const { data, loading, error, fetchMore, refetch } = qResult;

  useEffect(() => {
    if (error) {
      toast.error(
        trimGraphQLError(
          error?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
  }, [error]);

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    portfolioInput.searchParams.isSearchPerformed.setValue(true);

    reFetch(refetch, portfolioInput.searchParams);
  };

  const handleReset = async (e, reFetch) => {
    e.preventDefault();

    const { searchParams } = portfolioInput;

    searchParams.id.setValue('');
    searchParams.status.setValue('');
    searchParams.email.setValue('');
    searchParams.name.setValue('');
    searchParams.amountLt.setValue('');
    searchParams.amountGt.setValue('');
    searchParams.repaymentAmountLt.setValue('');
    searchParams.repaymentAmountGt.setValue('');
    searchParams.loanDateLt.setValue('');
    searchParams.loanDateGt.setValue('');
    searchParams.repaymentDateLt.setValue('');
    searchParams.repaymentDateGt.setValue('');
    searchParams.isSearchPerformed.setValue(false);

    reFetch({
      id: undefined,
      status: undefined,
      email: undefined,
      name: undefined,
      amountLt: undefined,
      amountGt: undefined,
      repaymentAmountLt: undefined,
      repaymentAmountGt: undefined,
      loanDateLt: undefined,
      loanDateGt: undefined,
      repaymentDateLt: undefined,
      repaymentDateGt: undefined,
      first: connection_limit.value,
    });

    localStorage.removeItem('portfolioInput');
  };

  const reFetch = async (refetch, searchParams) => {
    refetch(setSearchParams(searchParams));
    localStorage.setItem('portfolioInput', JSON.stringify(searchParams));
  };

  const handleFetchMore = (e, fetchMore, data) => {
    e.preventDefault();

    setIsLoading(true);
    fetchMore({
      variables: {
        cursor: data.mp.portfolios.pageInfo.endCursor,
        ...setSearchParams(portfolioInput.searchParams),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoading(false);
        const newNodes = fetchMoreResult.mp.portfolios.nodes;
        const pageInfo = fetchMoreResult.mp.portfolios.pageInfo;

        return newNodes.length
          ? {
              mp: {
                id: prev.mp.id,
                __typename: prev.mp.__typename,
                portfolios: {
                  __typename: prev.mp.portfolios.__typename,
                  nodes: [...prev.mp.portfolios.nodes, ...newNodes],
                  pageInfo,
                },
              },
            }
          : prev;
      },
    });
  };

  const handleLoanStartDate = e => {
    portfolioInput.searchParams.loanDateGt.setValue(normalizeDateTime(e));
  };

  const handleLoanEndDate = e => {
    portfolioInput.searchParams.loanDateLt.setValue(normalizeDateTime(e, true));
  };

  const handleRepaymentStartDate = e => {
    portfolioInput.searchParams.repaymentDateGt.setValue(normalizeDateTime(e));
  };

  const handleRepaymentEndDate = e => {
    portfolioInput.searchParams.repaymentDateLt.setValue(
      normalizeDateTime(e, true),
    );
  };

  return {
    loading,
    error,
    ...output,
    result: qResult,
    portfolioInput,
    portfolioData: loading || !data ? [] : data.mp,
    handlePagination: e => handleFetchMore(e, fetchMore, data),
    isLoading,
    portfolioHideToast: e => handleHideToast(e),
    portfolioReset: e => handleReset(e, refetch),
    portfolioSearchQuery: e => handleRefetch(e, refetch),
    handleLoanStartDate,
    handleLoanEndDate,
    handleRepaymentStartDate,
    handleRepaymentEndDate,
    setFilterParams: setSearchParams,
  };
};

export { usePortfoliosView };
