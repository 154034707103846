const config = {
  web: {
    port: process.env.PORT,
    node_env: process.env.NODE_ENV || 'development',
    app_portal: process.env.REACT_APP_PORTAL || 'development',
    version: process.env.REACT_APP_IMAGE_TAG || 'latest',
  },
  ignite: {
    base_url: process.env.REACT_APP_BASE_URL,
    client_id: process.env.REACT_APP_CLIENT_ID,
  },
  ga: {
    base_url: process.env.REACT_APP_GA_URL,
    view_id: process.env.REACT_APP_GA_VIEW_ID,
    client_id: process.env.REACT_APP_GA_CLIENT_ID,
    client_secret: process.env.REACT_APP_GA_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_GA_REDIRECT_URI,
  },
  loan_types: {
    loan: 'Loan',
    application: 'Application',
    actionableApplications: 'Actionable Applications',
  },
  connection_limit: {
    value: 15,
  },
  wallet_type: {
    disbursement: 'DISBURSEMENT_WALLET',
    interest: 'INTEREST_WALLET',
    collection: 'COLLECTIONS',
  },
};

module.exports = config;
