import * as React from 'react';

const SuccessIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
    fill="none"
    version="1.1"
    style={{
      shapeRendering: 'geometricPrecision',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
  >
    <circle cx="40" cy="40" r="40" fill="#0077FF" />
    <path
      d="M21.0908 44.7273L29.8181 53.4545L59.2726 24"
      style={{
        stroke: 'white',
        strokeWidth: '3',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
    />
  </svg>
);

export default SuccessIcon;
