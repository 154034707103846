import { graphql } from '@/__generated/gql';

export const GET_LOAN_PRODUCT_DETAILS = graphql(/* GraphQL */ `
  query GetPolicyById($id: ID!) {
    policyById(id: $id) {
      id
      name
      status
      loanType
      description
      durationType
      minLoanAmount
      maxLoanAmount
      loanCategoryId
      scoreCard
      requiresScoreCard
      minLoanDuration
      maxLoanDuration
      maxLateFeeDuration
      applicationForm
      interestCalculationMethod {
        name
      }
      interestRate {
        calcBy
        value
      }
      graduatedLoanCycles {
        amount
        interestRate
        maxLoanDuration
        repaymentCount
      }
      approvalWorkflow {
        id
      }
      baseRuleSet {
        id
      }
      lateFeeCalculation {
        lateFeeChargeOn
        lateFeeFrequency
        penaltyDelay
        rate {
          calcBy
          name
          value
        }
      }
      processingFee {
        calcBy
        name
        value
      }
      processingRefundFee {
        calcBy
        name
        value
      }
      repaymentType
      allowEarlyRepayment
      offerLetterEnabled
      validityInDays
      processingFeeDebitMethod
      preQualificationId
      processingFeeName
      repaymentInterval
      requiresPreQualificationId
      scoreCardConfig {
        scoreCardId
        preQualificationId
        approvalConfig {
          onFail
          onPass
        }
      }
    }
  }
`);
