import React from 'react';

const SuccessfulIcon = props => (
  <svg width={82} height={82} fill="none" {...props}>
    <path
      d="M57.282 23.227L34.454 46.056M57.282 23.227L42.755 64.733l-8.301-18.677-18.678-8.301 41.506-14.528z"
      stroke="#07F"
      strokeWidth={2.104}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={41} cy={41} r={39.176} stroke="#07F" strokeWidth={2.353} />
  </svg>
);

export default SuccessfulIcon;
