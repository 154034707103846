import React from 'react';
import { Query } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { PORTFOLIO_SUMMARY_QUERY } from '../graphql/queries';
import { Loader } from '../components';

const PortfolioSummary = ({ render }) => {
  return (
    <Query query={PORTFOLIO_SUMMARY_QUERY}>
      {({ loading, data }) => {
        if (loading) return <Loader />;

        return render({
          portfolioSummaryData: data,
        });
      }}
    </Query>
  );
};

export default withRouter(PortfolioSummary);
