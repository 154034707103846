import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { OriginateLogo, SuccessfulIcon } from '../../components/Icons/';
import './_Login.scss';

const ResetPasswordConfirmation = () => (
  <section className={'login_page'}>
    <section className={'login_logo'}>
      <OriginateLogo />
    </section>

    <main className={'login_holder'}>
      <h3 className={'center-text'}>Please check your inbox</h3>

      <div className={'login_icon-holder'}>
        <SuccessfulIcon />
      </div>

      <h5 className={'center-text'}>
        A confirmation email has been sent to your inbox, please follow the
        instructions there.
      </h5>

      <div className="login_link">
        <Link to="/login">Return to Login →</Link>
      </div>
    </main>
  </section>
);

export default withRouter(ResetPasswordConfirmation);
