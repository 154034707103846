import { Query } from '@apollo/react-components';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connection_limit } from '../config/config';
import { CUSTOMERS_VIEW_QUERY } from '../graphql/queries';

const CustomerTableProvider = ({ render, setSearchParams }) => {
  const [isLoading, setIsLoading] = useState(false);
  let isFacility = localStorage.getItem('slug') === 'heliumhealth';

  const handleFetchMore = (e, fetchMore, data) => {
    e.preventDefault();
    setIsLoading(true);
    fetchMore({
      variables: {
        nextCursor: data.views && data.views.customers.pageInfo.nextCursor,
        ...setSearchParams,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoading(false);
        const newNodes = fetchMoreResult.views.customers.nodes;
        const pageInfo = fetchMoreResult.views.customers.pageInfo;

        return newNodes.length
          ? {
              views: {
                __typename: prev.views.__typename,
                customers: {
                  __typename: prev.views.customers.__typename,
                  nodes: [...prev.views.customers.nodes, ...newNodes],
                  pageInfo,
                },
              },
            }
          : prev;
      },
    });
  };

  return (
    <Query
      query={CUSTOMERS_VIEW_QUERY}
      variables={{ first: connection_limit.value, facilityName: isFacility }}
    >
      {({ data, fetchMore }) => {
        return render({
          customerTableData: data,
          isLoading,
          handlePagination: e => handleFetchMore(e, fetchMore, data),
        });
      }}
    </Query>
  );
};

export default withRouter(CustomerTableProvider);
