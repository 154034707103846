import * as React from 'react';

function CopyToClipboardIcon(props) {
  return (
    <svg
      width={41}
      height={34}
      viewBox="0 0 41 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 0h39a2 2 0 012 2v30a2 2 0 01-2 2H0V0z" fill="#F2F4F8" />
      <path
        d="M25.5 15.625h-5.625c-.69 0-1.25.56-1.25 1.25V22.5c0 .69.56 1.25 1.25 1.25H25.5c.69 0 1.25-.56 1.25-1.25v-5.625c0-.69-.56-1.25-1.25-1.25z"
        stroke="#A5ADB6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.125 19.375H15.5a1.25 1.25 0 01-1.25-1.25V12.5a1.25 1.25 0 011.25-1.25h5.625a1.25 1.25 0 011.25 1.25v.625"
        stroke="#A5ADB6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CopyToClipboardIcon;
