import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { REQUEST_SUPPORTING_DOCUMENT } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { APPLICATION_QUERY } from '../../graphql/queries';

const useMutateRequestSupportingDocuments = ({ application }) => {
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
    showNotificationPopup: useKeyPair(false),
  };

  const inputs = {
    message: useFormInput(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const handleHideNotification = () => {
    setTimeout(() => {
      data.showNotificationPopup.setValue(false);
    }, 3500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
    handleModalToggle();
  };

  const getMutationVariables = () => {
    let supportingDocData = [];
    stepState.map(item =>
      supportingDocData.push({
        title: item.title,
        message: inputs.message.value,
      }),
    );

    return supportingDocData;
  };

  const handleRequestSupportingDocument = async e => {
    e.preventDefault();

    const variables = getMutationVariables();

    if (variables) {
      await requestSupportingDocument({
        variables: {
          input: {
            applicationId: application.id,
            documentRequests: variables,
          },
        },
        refetchQueries: () => [
          {
            query: APPLICATION_QUERY,
            variables: { applicationNumber: application.applicationNumber },
          },
        ],
      });
    }
  };

  const blankStep = {
    title: '',
  };

  const [stepState, setStepState] = useState([{ ...blankStep }]);

  const addNewStep = () => {
    setStepState([...stepState, { ...blankStep }]);
  };

  const removeStep = id => {
    const newStepState = stepState.filter((item, idx) => idx !== id);
    setStepState([...newStepState]);
  };

  const handleStepChange = e => {
    const updatedSteps = [...stepState];

    updatedSteps[e.target.dataset.idx][e.target.getAttribute('data-set')] =
      e.target.value;

    setStepState(updatedSteps);
  };

  const handleModalToggle = () => {
    data.showModal.setValue(!data.showModal.value);
  };

  const [requestSupportingDocument, { loading }] = useMutation(
    REQUEST_SUPPORTING_DOCUMENT,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Supporting Document Requested');
        data.showModal.setValue(false);
        data.showNotificationPopup.setValue(true);

        handleHideToast();
        handleHideNotification();
      },
      onError,
    },
  );

  return {
    handleHideToast,
    loading,
    handleModalToggle,
    data,
    inputs,
    handleRequestSupportingDocument,
    addNewStep,
    stepState,
    handleStepChange,
    removeStep,
  };
};

export { useMutateRequestSupportingDocuments };
