import { gql } from 'apollo-boost';

export const fragments = {
  permissions: gql`
    fragment PermissionDetails on Permission {
      id
      name
      description
      category
    }
  `,
  user: gql`
    fragment UserDetails on User {
      id
      email
      lastName
      firstName
    }
  `,
  role: gql`
    fragment RoleDetails on Role {
      id
      name
      description
      permissions {
        id
        name
        description
        category
      }
    }
  `,
};

export const USER_MANAGEMENT_QUERY = gql`
  query MP_UserManagementQuery(
    $clientId: ID!
    $first: ConnectionLimitInt
    $cursor: ConnectionCursor
    $name: String
    $email: String
    $createdAtLt: DateTime
    $createdAtGt: DateTime
    $roles: ID
  ) {
    clientAccountUsersXn(
      first: $first
      after: $cursor
      orderBy: createdAt_DESC
      where: {
        clientId: $clientId
        fullName_contains: $name
        email_contains: $email
        createdAt_gte: $createdAtLt
        createdAt_lte: $createdAtGt
        roles_some: $roles
      }
    ) @connection(key: "clientAccountUsersXn") {
      nodes {
        id
        user {
          ...UserDetails
        }
        roles {
          ...RoleDetails
        }
        status
        permissions {
          ...PermissionDetails
        }
        createdAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }

  ${fragments.user}
  ${fragments.role}
  ${fragments.permissions}
`;

export const USER_MANAGEMENT_DROPDOWN_QUERY = gql`
  query MP_UserManagementQuery($clientId: ID!, $first: ConnectionLimitInt) {
    clientAccountUsersXn(first: $first, where: { clientId: $clientId })
      @connection(key: "clientAccountUsersXn") {
      nodes {
        id
        user {
          id
          email
        }
      }
    }
  }
`;

export const USER_PERMISSION_LIST_QUERY = gql`
  query MP_UserPermissionListQuery(
    $clientId: ID!
    $first: ConnectionLimitInt
    $cursor: ConnectionCursor
    $email: String
  ) {
    clientAccountUsersXn(
      first: $first
      after: $cursor
      orderBy: createdAt_DESC
      where: { clientId: $clientId, email_contains: $email }
    ) @connection(key: "clientAccountUsersXn2") {
      nodes {
        id
        status
        permissions {
          ...PermissionDetails
        }
        createdAt
      }
      totalCount
    }
  }

  ${fragments.permissions}
`;
