import React from 'react';
import './_Notification.scss';
import PropTypes from 'prop-types';

const Notification = props => {
  return (
    <div className="notification">
      <div className="notification_holder">
        <h3 className="notification_title">{props.title}</h3>
        <div>{props.children}</div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
};

export default Notification;
