import { CHANGE_CLIENT_USER_ROLE_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_AVAILABLE_ROLES_AND_PERMISSIONS } from '../Providers-new/UserManagement/queries';
import { USER_MANAGEMENT_QUERY } from '../graphql/queries';
import { connection_limit } from '../config/config';
import { useClientInfo } from '../Providers-new/Client';
import { useState } from 'react';

const UpdateClientUserRoleProvider = () => {
  const { clientInfo, loading: ciLoading } = useClientInfo({
    privateFields: true,
  });

  const { clientId } = clientInfo;
  const [selectedRoles, updateSelectedRoles] = useState([]);

  const updateRoleInput = {
    roleId: useCustomState(),
    showPopup: useCustomState(false),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showEditRolePopup: useCustomState(false),
    selectedUser: useCustomState(),
    selectedUserRoles: useCustomState([]),
  };

  const toggleEditRolePopup = (userInfo = {}, roles = []) => {
    updateRoleInput.showEditRolePopup.setValue(
      !updateRoleInput.showEditRolePopup.value,
    );
    updateRoleInput.selectedUser.setValue(userInfo);
    updateSelectedRoles(
      roles.map(i => ({
        value: i.id,
        label: i.name,
      })),
    );
  };

  const handleHideToast = () => {
    setTimeout(() => {
      updateRoleInput.showToast.setValue(false);
    }, 3000);
  };

  const qResult = useQuery(GET_AVAILABLE_ROLES_AND_PERMISSIONS);
  const { data } = qResult;

  let userRoles = [];
  if (data) {
    userRoles = data.availableRoles;
  }

  const handleUserRoleUpdate = async (e, userInfo, updateUserRoleMutation) => {
    e.preventDefault();

    if (selectedRoles.length < 1) {
      updateRoleInput.showToast.setValue(true);
      updateRoleInput.status.setValue('Failed');
      updateRoleInput.content.setValue('Please Select a Role from the list');
      handleHideToast();

      return;
    }

    await updateUserRoleMutation({
      variables: {
        userId: userInfo.id,
        roles: selectedRoles.map(r => r.value),
      },
      refetchQueries: () => [
        {
          query: USER_MANAGEMENT_QUERY,
          variables: { clientId: clientId, first: connection_limit.value },
        },
      ],
    });
  };
  const [updateUserRoleMutation, { loading, error }] = useMutation(
    CHANGE_CLIENT_USER_ROLE_MUTATION,
    {
      async onCompleted() {
        updateRoleInput.showToast.setValue(true);
        updateRoleInput.status.setValue('Success');
        updateRoleInput.content.setValue('Role Updated');

        handleHideToast();
        updateRoleInput.showEditRolePopup.setValue(
          !updateRoleInput.showEditRolePopup.value,
        );
      },

      onError(error) {
        updateRoleInput.showToast.setValue(true);
        updateRoleInput.status.setValue('Failed');
        updateRoleInput.content.setValue(trimGraphQLError(error.message));

        handleHideToast();
      },
    },
  );

  return {
    error,
    loading: loading || ciLoading,
    handleHideToast: e => handleHideToast(e),
    handleUserRoleUpdate: (e, userInfo) =>
      handleUserRoleUpdate(e, userInfo, updateUserRoleMutation),
    updateRoleInput,
    toggleEditRolePopup: (userId, roles) => toggleEditRolePopup(userId, roles),
    userRoles,
    selectedRoles,
    updateSelectedRoles,
  };
};
export default UpdateClientUserRoleProvider;
