import { graphql } from '@/__generated/gql';

export const CUSTOMER_BLACKLIST = graphql(/* GraphQL */ `
  mutation BlacklistCustomer($input: BlacklistCustomerInput!) {
    blacklistCustomer(input: $input) {
      id
      reason
    }
  }
`);
