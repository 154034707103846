import { useMutation } from '@apollo/react-hooks';
import { MODIFY_APPLICATION_INTEREST_RATE } from './queries';
import { useFormInput } from '../Form';
import {
  APPLICATION_QUERY,
  GET_REPAYMENTBREAKDOWN_QUERY,
} from '../../graphql/queries';
import { useCustomState } from '../../lib/CustomHooks';

const useMutateModifyApplicationInterestRate = ({
  application,
  policyId,
  principalAmount,
  duration,
  moratoriumPeriod,
}) => {
  const modifyApplicationInterestRateDataInput = {
    showToast: useCustomState(false),
    showModal: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showNotificationPopup: useCustomState(false),
    interestRatePercent: useFormInput(),
  };

  const [
    modifyApplicationInterestRate,
    { loading: modifyApplicationInterestRateLoader },
  ] = useMutation(MODIFY_APPLICATION_INTEREST_RATE, {
    onCompleted: () => {
      modifyApplicationInterestRateDataInput.showToast.setValue(true);
      modifyApplicationInterestRateDataInput.status.setValue('Success');
      modifyApplicationInterestRateDataInput.content.setValue(
        'Interest Rate Updated',
      );
      modifyApplicationInterestRateDataInput.showModal.setValue(false);
      modifyApplicationInterestRateDataInput.showNotificationPopup.setValue(
        true,
      );

      handleHideApplicationInterestRateToast();
      handleHideNotification();
    },
  });

  const handleHideApplicationInterestRateToast = () => {
    setTimeout(() => {
      modifyApplicationInterestRateDataInput.showToast.setValue(false);
    }, 3500);
  };

  const handleHideNotification = () => {
    setTimeout(() => {
      modifyApplicationInterestRateDataInput.showNotificationPopup.setValue(
        false,
      );
    }, 3500);
  };

  const handleModifyApplicationInterestRate = e => {
    e.preventDefault();

    if (!modifyApplicationInterestRateDataInput.interestRatePercent.value) {
      modifyApplicationInterestRateDataInput.showToast.setValue(true);
      modifyApplicationInterestRateDataInput.status.setValue('Failed');
      modifyApplicationInterestRateDataInput.content.setValue(
        'Please input a value',
      );

      handleHideApplicationInterestRateToast();
      return;
    }

    modifyApplicationInterestRate({
      variables: {
        input: {
          applicationId: application.id,
          interestRate: parseFloat(
            modifyApplicationInterestRateDataInput.interestRatePercent.value,
          ),
          calcBy: 'PERCENTAGE',
        },
      },
      refetchQueries: () => [
        {
          query: APPLICATION_QUERY,
          variables: { applicationNumber: application.applicationNumber },
        },
        {
          query: GET_REPAYMENTBREAKDOWN_QUERY,
          variables: {
            policyId: policyId ? policyId : '',
            applicationId: application.id ? application.id : '',
            principalAmount: principalAmount ? principalAmount : '',
            duration: duration ? duration : '',
            moratoriumPeriod: moratoriumPeriod ? moratoriumPeriod : null,
          },
        },
      ],
      awaitRefetchQueries: true,
    });

    modifyApplicationInterestRateDataInput.interestRatePercent.setValue('');
  };

  const handleApplicationInterestRateModalToggle = () => {
    modifyApplicationInterestRateDataInput.showModal.setValue(
      !modifyApplicationInterestRateDataInput.showModal.value,
    );
    modifyApplicationInterestRateDataInput.interestRatePercent.setValue('');
  };

  return {
    handleHideApplicationInterestRateToast,
    modifyApplicationInterestRateLoader,
    handleApplicationInterestRateModalToggle,
    modifyApplicationInterestRateDataInput,
    handleModifyApplicationInterestRate,
  };
};

export { useMutateModifyApplicationInterestRate };
