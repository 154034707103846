import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Box,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import {
  Button,
  Card,
  DataCard,
  Input,
  CheckBox,
  Loader,
  Toast,
  NewRadio,
} from '../../../components';
import { useUpdateIntegrationConfig } from '../../../Providers-new/Configuration';

const Integrations = () => {
  const {
    data,
    loading,
    ciLoading,
    handleUpdateIntegrationAttributes,
    isDisabled,
    checkboxPartialDebitState,
    handlePartialDebitCheckBoxInput,
    handleHideToast,
    inputs,
    handleRadioChange,
    bvnDefaultValue,
    bvnServices,
  } = useUpdateIntegrationConfig();

  return (
    <>
      {(loading || ciLoading) && <Loader loadingText="loading..." />}
      <Toast
        title="Update Configurations"
        content={data.content.value}
        classes={data.status.value}
        active={data.showToast.value}
        clickEvent={handleHideToast}
      />

      <DataCard>
        <div className="flex-container space-between">
          <div className="form-desc">
            <Card title="Integrations" />
          </div>

          <div className="form-fields">
            <Accordion
              allowToggle
              defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
              allowMultiple
            >
              <AccordionItem>
                <h2>
                  <AccordionButton
                    borderX="none"
                    borderTop="none"
                    borderBottom="2px solid"
                    py="8px"
                    borderBottomColor="#D6DDEB"
                    bg="transparent"
                    _expanded={{ bg: 'transparent' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      BVN Provider
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {bvnServices?.map(item => (
                    <Stack pt={2} direction="column" gap={8} key={item.name}>
                      <NewRadio
                        id={item.name}
                        value={item.name}
                        defaultChecked={
                          bvnDefaultValue[0] === item.name ||
                          (bvnDefaultValue[0] == null && item.name === 'VFD')
                        }
                        name="bvnServices"
                        onChange={e => handleRadioChange(e, item.name)}
                        label={
                          item.name === 'VFD'
                            ? 'BVN 1.0'
                            : 'BVN (with Igree) 2.0'
                        }
                      />
                    </Stack>
                  ))}
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    borderX="none"
                    borderTop="none"
                    borderBottom="2px solid"
                    py="8px"
                    borderBottomColor="#D6DDEB"
                    bg="transparent"
                    _expanded={{ bg: 'transparent' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      Paystack
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="grid-container">
                    <div className="form-fields">
                      <Input
                        {...inputs.paystackPublicKey.input}
                        label="Public Key"
                        placeholder="Enter value"
                        required={inputs.paystackSecretKey.value !== ''}
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.paystackSecretKey.input}
                        label="Secret Key"
                        placeholder="Enter value"
                        required={inputs.paystackPublicKey.value !== ''}
                        type="password"
                      />
                    </div>
                  </div>

                  <div className="flex-container spacing">
                    <CheckBox
                      {...inputs.paystackEnablePartialDebit.input}
                      checked={checkboxPartialDebitState}
                      onChange={handlePartialDebitCheckBoxInput}
                    />
                    <Text fontSize="md" px="5">
                      Partial Debit
                    </Text>
                  </div>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem my="20px">
                <h2>
                  <AccordionButton
                    borderX="none"
                    borderTop="none"
                    borderBottom="2px solid"
                    py="8px"
                    borderBottomColor="#D6DDEB"
                    bg="transparent"
                    _expanded={{ bg: 'transparent' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      Decide
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="grid-container">
                    <div className="form-fields">
                      <Input
                        {...inputs.decideClientKey.input}
                        placeholder="Enter value"
                        label="Client Key"
                        required={inputs.decideClientSecret.value !== ''}
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.decideClientSecret.input}
                        placeholder="Enter value"
                        required={inputs.decideClientKey.value !== ''}
                        label="Client Secret"
                        type="password"
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.decideClientSlug.input}
                        placeholder="Enter value"
                        label="Client Slug"
                        required={false}
                      />
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem my="20px">
                <h2>
                  <AccordionButton
                    borderX="none"
                    borderTop="none"
                    borderBottom="2px solid"
                    py="8px"
                    borderBottomColor="#D6DDEB"
                    bg="transparent"
                    _expanded={{ bg: 'transparent' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      MBS
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="grid-container">
                    <div className="form-fields">
                      <Input
                        {...inputs.mbsClientId.input}
                        placeholder="Enter value"
                        label="Client ID"
                        required={inputs.mbsClientSecret.value !== ''}
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.mbsClientSecret.input}
                        placeholder="Enter value"
                        label="Client Secret"
                        required={inputs.mbsClientId.value !== ''}
                        type="password"
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.mbsExternalBankStatementTenor.input}
                        placeholder="Enter value"
                        label="External Bank Statement Tenor"
                      />
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem my="20px">
                <h2>
                  <AccordionButton
                    borderX="none"
                    borderTop="none"
                    borderBottom="2px solid"
                    py="8px"
                    borderBottomColor="#D6DDEB"
                    bg="transparent"
                    _expanded={{ bg: 'transparent' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      Remita
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="grid-container">
                    <div className="form-fields">
                      <Input
                        {...inputs.remitaMerchantId.input}
                        placeholder="Enter value"
                        label="Merchant ID"
                        required={
                          inputs.remitaMerchantApiKey.value !== '' ||
                          inputs.remitaMerchantToken.value !== '' ||
                          inputs.remitaServiceTypeId.value !== ''
                        }
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.remitaMerchantApiKey.input}
                        placeholder="Enter value"
                        label="Merchant Api Key"
                        required={
                          inputs.remitaMerchantId.value !== '' ||
                          inputs.remitaMerchantToken.value !== '' ||
                          inputs.remitaServiceTypeId.value !== ''
                        }
                        type="password"
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.remitaMerchantToken.input}
                        placeholder="Enter value"
                        label="Merchant Token"
                        required={
                          inputs.remitaMerchantId.value !== '' ||
                          inputs.remitaMerchantApiKey.value !== '' ||
                          inputs.remitaServiceTypeId.value !== ''
                        }
                        type="password"
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.remitaServiceTypeId.input}
                        placeholder="Enter value"
                        label="Service Type ID"
                        required={
                          inputs.remitaMerchantId.value !== '' ||
                          inputs.remitaMerchantApiKey.value !== '' ||
                          inputs.remitaMerchantToken.value !== ''
                        }
                      />
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem my="20px">
                <h2>
                  <AccordionButton
                    borderX="none"
                    borderTop="none"
                    borderBottom="2px solid"
                    py="8px"
                    borderBottomColor="#D6DDEB"
                    bg="transparent"
                    _expanded={{ bg: 'transparent' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      First Central Credit Bureau
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="grid-container">
                    <div className="form-fields">
                      <Input
                        {...inputs.creditBureauUsername.input}
                        placeholder="Enter value"
                        label="Username"
                        required={inputs.creditBureauPassword.value !== ''}
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.creditBureauPassword.input}
                        placeholder="Enter value"
                        label="Password"
                        required={inputs.creditBureauUsername.value !== ''}
                        type="password"
                      />
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem my="20px">
                <h2>
                  <AccordionButton
                    borderX="none"
                    borderTop="none"
                    borderBottom="2px solid"
                    py="8px"
                    borderBottomColor="#D6DDEB"
                    bg="transparent"
                    _expanded={{ bg: 'transparent' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      Credit Registry
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="grid-container">
                    <div className="form-fields">
                      <Input
                        {...inputs.creditRegistrySubscriberId.input}
                        placeholder="Enter value"
                        label="Subscriber ID"
                        required={
                          inputs.creditRegistryEmail.value !== '' ||
                          inputs.creditRegistryPassword.value !== ''
                        }
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.creditRegistryEmail.input}
                        placeholder="Enter value"
                        label="Email"
                        required={
                          inputs.creditRegistrySubscriberId.value !== '' ||
                          inputs.creditRegistryPassword.value !== ''
                        }
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.creditRegistryPassword.input}
                        placeholder="Enter value"
                        label="Password"
                        required={
                          inputs.creditRegistrySubscriberId.value !== '' ||
                          inputs.creditRegistryEmail.value !== ''
                        }
                        type="password"
                      />
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem my="20px">
                <h2>
                  <AccordionButton
                    borderX="none"
                    borderTop="none"
                    borderBottom="2px solid"
                    py="8px"
                    borderBottomColor="#D6DDEB"
                    bg="transparent"
                    _expanded={{ bg: 'transparent' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      CRC
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="grid-container">
                    <div className="form-fields">
                      <Input
                        {...inputs.crcStrUserId.input}
                        placeholder="Enter value"
                        label="User ID"
                        required={inputs.crcStrPassword.value !== ''}
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.crcStrPassword.input}
                        placeholder="Enter value"
                        label="Password"
                        required={inputs.crcStrUserId.value !== ''}
                        type="password"
                      />
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem my="20px">
                <h2>
                  <AccordionButton
                    borderX="none"
                    borderTop="none"
                    borderBottom="2px solid"
                    py="8px"
                    borderBottomColor="#D6DDEB"
                    bg="transparent"
                    _expanded={{ bg: 'transparent' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="600"
                      fontSize="16px"
                    >
                      Okra
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="grid-container">
                    <div className="form-fields">
                      <Card title="Validity Period" />
                      <Input
                        {...inputs.okraValidityPeriod.input}
                        placeholder="Enter value"
                        required
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.okraWidgetUrl.input}
                        placeholder="Enter value"
                        label="Widget URL"
                        required={
                          inputs.okraPrivateKey.value !== '' ||
                          inputs.okraPublicKey.value !== ''
                        }
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.okraPublicKey.input}
                        placeholder="Enter value"
                        label="Public Key"
                        required={
                          inputs.okraPrivateKey.value !== '' ||
                          inputs.okraClientToken.value !== ''
                        }
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.okraPrivateKey.input}
                        placeholder="Enter value"
                        label="Private Key"
                        required={
                          inputs.okraClientToken.value !== '' ||
                          inputs.okraPublicKey.value !== ''
                        }
                        type="password"
                      />
                    </div>

                    <div className="form-fields">
                      <Input
                        {...inputs.okraClientToken.input}
                        placeholder="Enter value"
                        label="Token"
                        required={
                          inputs.okraPrivateKey.value !== '' ||
                          inputs.okraPublicKey.value !== ''
                        }
                        type="password"
                      />
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <div className="flex-container">
              <Button
                click_event={handleUpdateIntegrationAttributes}
                disabled={isDisabled}
                classes="secondary space-left"
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </DataCard>
    </>
  );
};

export default Integrations;
