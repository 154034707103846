import React, { Fragment } from 'react';
import { Button, Loader, Modal, Toast } from '../../../../components';
import { Close } from '../../../../components/Icons';

const SetApplicationBankAcc = ({
  setApplicationBankAccountData,
  setApplicationBankAccountLoading,
  setApplicationBankAccountToast,
  setApplicationBankAccountToggle,
  handleSetApplicationBankAccount,
}) => {
  return (
    <Fragment>
      {setApplicationBankAccountLoading ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          <Toast
            title="Set Application Bank Account"
            content={setApplicationBankAccountData.content.value}
            clickEvent={setApplicationBankAccountToast}
            active={setApplicationBankAccountData.showToast.value}
            classes={setApplicationBankAccountData.status.value}
          />
          <Modal
            title={'Set Application Bank Account'}
            classes="repayment-date-modal"
            active={setApplicationBankAccountData.showModal.value}
          >
            <Close
              className="close-icon"
              onClick={setApplicationBankAccountToggle}
            />
            <form onSubmit={handleSetApplicationBankAccount}>
              <p>
                Are you sure you want to set the application’s bank account as
                the default bank account.
              </p>

              <div className="repayment-double-buttons">
                <Button
                  classes="cancel"
                  click_event={setApplicationBankAccountToggle}
                >
                  Cancel
                </Button>
                <Button classes="secondary blue save" type="submit">
                  Yes
                </Button>
              </div>
            </form>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default SetApplicationBankAcc;
