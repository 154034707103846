import React from 'react';
import { ExternalLinkIcon } from '../Icons';
import './_KycUnverified.scss';

const KycUnverified = () => {
  return (
    <span className="kyc-unverified">
      <span className="kyc-unverified__toggle">
        BVN Unverified
        <a
          href="https://developers.indicina.co/docs/bvn-update-faqs"
          target="_blank"
          rel="noreferrer"
        >
          More details <ExternalLinkIcon />
        </a>
      </span>
      <span className="kyc-unverified__hover">
        BVN for this user has not been verified{' '}
        <span className="kyc-unverified__hover__link">
          <a
            href="https://developers.indicina.co/docs/bvn-update-faqs"
            target="_blank"
            rel="noreferrer"
          >
            learn more <ExternalLinkIcon fill="#3c4d60" />
          </a>
        </span>
      </span>
    </span>
  );
};
export default KycUnverified;
