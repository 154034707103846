import React, { useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Loader } from '../../components';

const Logout = () => {
  const client = useApolloClient();

  const handleLogout = useCallback(async () => {
    window.location.href = '/';
    localStorage.clear();
    await client.resetStore();
  }, [client]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return <Loader />;
};

export default Logout;
