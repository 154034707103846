import { gql } from 'apollo-boost';

export const CREATE_CLIENT_ACCOUNT_USER_MUTATION = gql`
  mutation MP_CreateClientAccountUserMutation(
    $clientId: ID!
    $email: String!
    $password: String
    $phone: String!
    $firstName: String
    $lastName: String
    $permissions: [PermissionName!]
    $roles: [ClientUserRoles!]
  ) {
    createClientAccountUser(
      input: {
        clientId: $clientId
        email: $email
        password: $password
        phone: $phone
        firstName: $firstName
        lastName: $lastName
        permissions: $permissions
        roles: $roles
      }
    ) {
      token
      user {
        id
        firstName
        lastName
        email
        status
      }
    }
  }
`;
