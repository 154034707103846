import { DELETE_CLIENT_USER_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { USER_MANAGEMENT_QUERY } from '../graphql/queries';
import { connection_limit } from '../config/config';
import { useClientInfo } from '../Providers-new/Client';

const DeleteClientUserProvider = () => {
  const { clientInfo, loading: ciLoading } = useClientInfo({
    privateFields: true,
  });

  const { clientId } = clientInfo;

  const deleteClientUserInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showDeleteUserPopup: useCustomState(false),
    selectedUserId: useCustomState(),
  };

  const toggleDeleteUserPopup = (userInfo = {}) => {
    deleteClientUserInput.showDeleteUserPopup.setValue(
      !deleteClientUserInput.showDeleteUserPopup.value,
    );
    deleteClientUserInput.selectedUserId.setValue(userInfo);
  };

  const handleHideToast = () => {
    setTimeout(() => {
      deleteClientUserInput.showToast.setValue(false);
    }, 2500);
  };

  const handleDeleteClientUser = async (
    e,
    userId,
    handleDeleteClientUserMutation,
  ) => {
    e.preventDefault();

    await handleDeleteClientUserMutation({
      variables: {
        userId,
      },
      refetchQueries: () => [
        {
          query: USER_MANAGEMENT_QUERY,
          variables: { clientId: clientId, first: connection_limit.value },
        },
      ],
    });
  };

  const [handleDeleteClientUserMutation, { loading }] = useMutation(
    DELETE_CLIENT_USER_MUTATION,
    {
      async onCompleted() {
        deleteClientUserInput.showToast.setValue(true);
        deleteClientUserInput.status.setValue('Success');
        deleteClientUserInput.content.setValue('User Successfully Deleted');

        handleHideToast();
        deleteClientUserInput.showDeleteUserPopup.setValue(
          !deleteClientUserInput.showDeleteUserPopup.value,
        );
      },

      onError(error) {
        deleteClientUserInput.showToast.setValue(true);
        deleteClientUserInput.status.setValue('Failed');
        deleteClientUserInput.content.setValue(trimGraphQLError(error.message));

        handleHideToast();
      },
    },
  );

  return {
    loading: loading || ciLoading,
    handleHideToast: e => handleHideToast(e),
    handleDeleteClientUser: (e, userId) =>
      handleDeleteClientUser(e, userId, handleDeleteClientUserMutation),
    deleteClientUserInput,
    toggleDeleteUserPopup: userId => toggleDeleteUserPopup(userId),
  };
};

export default DeleteClientUserProvider;
