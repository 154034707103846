import cx from 'classnames';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  DataCard,
  EmptyScreen,
  Export,
  Table,
  Toast,
  Loader,
  ModalV2,
  Close,
  MoreOptions,
} from '../../components';
import { loan_types } from '../../config/config';
import {
  nairaAmount,
  reduceStringLength,
  removeUnderscore,
  removeCommas,
} from '../../lib/Utils';
import { ExportToCSV } from '../../Providers';
import LoanNavs from './LoanNavs';
import LoanSearch from './Search';
import './_Loans.scss';
import { usePortfoliosView } from '../../Providers-new/Portfolios';
import { ErrorBoundary } from 'react-error-boundary';
import { useGetCustomerById } from '../../Providers/useGetCustomerById';
import CustomerSearchForm from './CustomerSearchForm';
import RepaymentForm from './RepaymentForm';
import { useMutateManualRepayment } from '../../Providers-new/Portfolios/useMutateManualRepayment';

const headers = {
  loanHeader: [
    'Status',
    'Loan ID',
    "Applicant's Email",
    'Full Name',
    'Loan Amount',
    'Repayment Amount',
    'Due Date',
  ],
};

const Portfolios = () => {
  const [cashRepaymentModal, setCashRepaymentModal] = useState(false);
  const [repaymentStage, setRepaymentStage] = useState(0);

  const {
    customerId,
    setCustomerId,
    customerData,
    handleSearch,
    loading: queryCustomerByIdIsLoading,
    setCustomerData,
  } = useGetCustomerById();

  const { loading: manualRepaymentIsLoading, makeManualRepayment } =
    useMutateManualRepayment({
      portfolioNumber: customerData?.portfolioNumber,
      setCashRepaymentModal,
    });

  const {
    loading,
    portfolioInput,
    portfolioData,
    handlePagination,
    isLoading,
    portfolioHideToast,
    portfolioReset,
    portfolioSearchQuery,
    handleLoanStartDate,
    handleLoanEndDate,
    handleRepaymentStartDate,
    handleRepaymentEndDate,
  } = usePortfoliosView();

  const setSearchParams = searchParams => {
    const {
      id,
      status,
      email,
      name,
      amountLt,
      amountGt,
      repaymentAmountLt,
      repaymentAmountGt,
      loanDateLt,
      loanDateGt,
      repaymentDateLt,
      repaymentDateGt,
    } = searchParams;

    return {
      portfolioNumber: id.value ? id.value : undefined,
      status: status.value ? status.value : undefined,
      amount_lte: amountLt.value
        ? parseFloat(removeCommas(amountLt.value))
        : undefined,
      amount_gte: amountGt.value
        ? parseFloat(removeCommas(amountGt.value))
        : undefined,
      fullAmount_lte: repaymentAmountLt.value
        ? parseFloat(removeCommas(repaymentAmountLt.value))
        : undefined,
      fullAmount_gte: repaymentAmountGt.value
        ? parseFloat(removeCommas(repaymentAmountGt.value))
        : undefined,
      createdAt_lt: loanDateLt.value || undefined,
      createdAt_gte: loanDateGt.value || undefined,
      dateOfRepayment_lt: repaymentDateLt.value || undefined,
      dateOfRepayment_gte: repaymentDateGt.value || undefined,
      customer: {
        email_contains: email.value ? email.value : undefined,
        fullName_contains: name.value ? name.value : undefined,
      },
    };
  };

  const handleRowCashRepayment = customerId => {
    setCustomerId(customerId);
    setCustomerData(null);
    setRepaymentStage(0);
    setCashRepaymentModal(prev => !prev);
  };

  const handleCashRepaymentModal = () => {
    setCustomerId('');
    setRepaymentStage(0);
    setCustomerData(null);
    setCashRepaymentModal(prev => !prev);
  };

  if (loading || queryCustomerByIdIsLoading || manualRepaymentIsLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="layout-main ">
        <LoanNavs />
        <Toast
          title="Loans"
          content={portfolioInput.content.value}
          classes={portfolioInput.status.value}
          active={portfolioInput.showToast.value}
          clickEvent={portfolioHideToast}
        />
        <LoanSearch
          input={portfolioInput}
          resetAction={portfolioReset}
          searchAction={portfolioSearchQuery}
          type={loan_types.loan}
          handleLoanObjStartDate={handleLoanStartDate}
          handleLoanObjEndDate={handleLoanEndDate}
          handleRepaymentStartDate={handleRepaymentStartDate}
          handleRepaymentEndDate={handleRepaymentEndDate}
        />
        {portfolioData &&
        portfolioData.portfolios &&
        portfolioData.portfolios.nodes.length > 0 ? (
          <ErrorBoundary
            fallback={
              <DataCard noPadding={false}>
                <EmptyScreen message="Error Fetching Loans" />
              </DataCard>
            }
          >
            <div className="loans">
              <ExportToCSV exportType="PORTFOLIO_CSV_EXPORT" fileName="LOANS">
                {({ inputs, handleHideToast, exportToCsv }) => (
                  <Fragment>
                    <Toast
                      title="Export CSV"
                      content={inputs.content.value}
                      classes={inputs.status.value}
                      active={inputs.showToast.value}
                      clickEvent={handleHideToast}
                    />

                    <div className="table-buttons-wrapper">
                      <Button
                        classes="secondary handle-export cash-repayment-button"
                        click_event={handleCashRepaymentModal}
                      >
                        <span className="export-csv">Cash Repayment</span>
                      </Button>
                      <Button
                        classes="right handle-export export-button"
                        click_event={() => {
                          exportToCsv({
                            portfolio: setSearchParams(
                              portfolioInput.searchParams,
                            ),
                          });
                        }}
                      >
                        <Export />
                        <span className="export-csv">Export CSV</span>
                      </Button>
                    </div>
                  </Fragment>
                )}
              </ExportToCSV>
              <section>
                <DataCard noPadding={false}>
                  <Table>
                    <div className="table__head loans-section">
                      {headers.loanHeader.map((header, key) => (
                        <span
                          key={key}
                          className={cx({
                            Status: header,
                            'checked-width': header === 'Status',
                            'loan-header': header === 'Loan ID',
                            'due-date': header === 'Due Date',
                          })}
                        >
                          {header}
                        </span>
                      ))}
                    </div>
                    <div className="table__body portfolios-table">
                      {portfolioData &&
                        portfolioData.portfolios &&
                        portfolioData.portfolios.nodes.map((portfolio, key) => (
                          <ul key={key}>
                            <li>
                              <Link
                                to={`/loans/${portfolio.portfolioNumber}/${portfolio.email}`}
                              >
                                {portfolio.status && portfolio.status.name && (
                                  <span
                                    className="tooltip checked-width"
                                    data-title={
                                      portfolio.status.name === 'DISBURSED'
                                        ? 'ACTIVE'
                                        : removeUnderscore(
                                            portfolio.status.name,
                                          )
                                    }
                                  >
                                    <span
                                      className={`status fixed ${
                                        portfolio.status.name.toLowerCase() ||
                                        ''
                                      }`}
                                    >
                                      {(() => {
                                        let statusName = portfolio.status.name;
                                        switch (statusName) {
                                          case 'PENDING_DISBURSEMENT':
                                            return 'PEN';
                                          case 'DISBURSING':
                                            return 'DIS';
                                          case 'DISBURSED':
                                            return 'ACT';
                                          case 'CLOSED':
                                            return 'CLO';
                                          case 'OVERDUE':
                                            return 'OVR';
                                          default:
                                            return 'PEN';
                                        }
                                      })()}
                                    </span>
                                  </span>
                                )}
                                <span>
                                  <div className="first-td">
                                    {reduceStringLength(
                                      portfolio.portfolioNumber,
                                      12,
                                    )}
                                  </div>
                                </span>
                                <span>{portfolio.email}</span>
                                <span>{portfolio.fullName}</span>
                                <span>{nairaAmount(portfolio.amount)}</span>
                                <span>{nairaAmount(portfolio.fullAmount)}</span>
                                <span>
                                  {portfolio.dateOfRepayment === null
                                    ? 'N/A'
                                    : moment(portfolio.dateOfRepayment).format(
                                        'll',
                                      )}
                                </span>
                              </Link>
                              <div
                                className={`${
                                  portfolio.status.name !== 'OVERDUE' &&
                                  portfolio.status.name !== 'DISBURSED' &&
                                  'opacity-none'
                                } moreOptions-button`}
                              >
                                {
                                  <MoreOptions id={key}>
                                    {(portfolio.status.name === 'OVERDUE' ||
                                      portfolio.status.name ===
                                        'DISBURSED') && (
                                      <span
                                        onClick={() =>
                                          handleRowCashRepayment(
                                            portfolio?.customerId,
                                          )
                                        }
                                      >
                                        Cash Repayment
                                      </span>
                                    )}
                                  </MoreOptions>
                                }
                              </div>
                            </li>
                          </ul>
                        ))}
                    </div>
                  </Table>
                </DataCard>

                {portfolioData &&
                  portfolioData.portfolios.pageInfo.hasNextPage &&
                  (isLoading ? (
                    <Loader />
                  ) : (
                    <Button
                      classes={'secondary center load-more'}
                      click_event={handlePagination}
                    >
                      Load more
                    </Button>
                  ))}
              </section>
            </div>
          </ErrorBoundary>
        ) : (
          <DataCard noPadding={false}>
            <EmptyScreen message="No Loans Yet" />
          </DataCard>
        )}
      </div>

      <ModalV2
        title="Make Cash Repayment"
        classes="v2-modal"
        active={cashRepaymentModal}
      >
        <Close
          className="close-icon"
          onClick={() => setCashRepaymentModal(prev => !prev)}
        />

        {repaymentStage === Number(0) && (
          <CustomerSearchForm
            setRepaymentStage={setRepaymentStage}
            setCustomerId={setCustomerId}
            customerId={customerId}
            customerData={customerData}
            setCustomerData={setCustomerData}
            handleSearch={handleSearch}
            setCashRepaymentModal={setCashRepaymentModal}
          />
        )}

        {repaymentStage === Number(1) && (
          <RepaymentForm
            customerData={customerData}
            setRepaymentStage={setRepaymentStage}
            makeManualRepayment={makeManualRepayment}
            portfolioId={customerData?.id}
          />
        )}
      </ModalV2>
    </>
  );
};

export default Portfolios;
