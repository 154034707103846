import { gql } from 'apollo-boost';

export const SET_MANUAL_REVIEW_APPLICATION_MUTATION = gql`
  mutation MP_SetManualReviewApplication($input: ApplicationStageInput!) {
    setManualReviewApplication(input: $input) {
      application {
        id
        status {
          name
        }
      }
    }
  }
`;
