import { Icon, IconProps } from '@chakra-ui/icon';
import React from 'react';

const BenefitsIcon = ({ ...props }: IconProps) => {
  return (
    <Icon
      w={6}
      h={6}
      fill="none"
      stroke="gray.500"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 8H4a1 1 0 00-1 1v2a1 1 0 001 1h16a1 1 0 001-1V9a1 1 0 00-1-1zM12 8v13"
      ></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 12v7a2 2 0 01-2 2H7a2 2 0 01-2-2v-7M7.5 8a2.5 2.5 0 110-5c.965-.017 1.91.451 2.713 1.343C11.015 5.235 11.638 6.51 12 8c.362-1.49.985-2.765 1.787-3.657.803-.892 1.748-1.36 2.713-1.343a2.5 2.5 0 010 5"
      ></path>
    </Icon>
  );
};

export default BenefitsIcon;
