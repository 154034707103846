import { useMutation } from '@apollo/react-hooks';
import { DELETE_SUPPORTING_DOCUMENT_MUTATION } from '../../graphql/mutations/deleteSupportingDocument';

const useDeleteSupportingDocument = () => {
  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const handleDeleteSupportingDocument = async ({
    key,
    bucket,
    application,
  }) => {
    await deleteSupportingDocument({
      variables: {
        applicationId: application.id,
        key,
        bucket,
      },
    });
  };

  const [deleteSupportingDocument, { loading, data, error }] = useMutation(
    DELETE_SUPPORTING_DOCUMENT_MUTATION,
  );

  return {
    handleHideToast,
    loading,
    data,
    error,
    handleDeleteSupportingDocument,
  };
};

export { useDeleteSupportingDocument };
