import React from 'react';
import './_CustomerSummary.scss';
import PropTypes from 'prop-types';

const CustomerSummary = props => {
  return (
    <div className="customer-summary">
      <div className="customer-summary__header">
        <h5>{props.title}</h5>
      </div>
      <div
        className={`customer-summary__content ${
          props.classes == null ? '' : props.classes
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

CustomerSummary.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
};

export default CustomerSummary;
