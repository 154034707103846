import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { removeUnderscore, capitalizeInitialLetter } from '../../lib/Utils';

import {
  DataCard,
  Card,
  Button,
  CheckBox,
  CheckBoxGroup,
  Input,
  BreadCrumbSection,
  Toast,
  Notification,
  Loader,
} from '../../components';
import { CompleteIcon } from '../../components/Icons';

import {
  MutateCreateCustomRole,
  useRolesAndPermissions,
} from '../../Providers-new/UserManagement';
import { useClientInfo } from '../../Providers-new/Client';

import './_UserManagement.scss';

const CreateRoles = () => {
  const { clientInfo } = useClientInfo();
  const { permissions } = useRolesAndPermissions();
  const userEmail = localStorage.getItem('email') || null;
  const perms = permissions ? permissions : [];
  const groupedPerms = [];

  if (!userEmail || typeof userEmail === 'undefined') {
    window.location.href = '/logout';
  }

  const {
    data,
    form,
    permissionInputs,
    handleHideToast,
    handleCreateCustomRole,
  } = MutateCreateCustomRole({
    permissions,
    clientId: clientInfo.clientId,
    userEmail,
  });

  const getGroupedPerms = () => {
    // eslint-disable-next-line array-callback-return
    perms.map(item => {
      if (item.category && !groupedPerms.includes(item.category)) {
        groupedPerms.push(item.category);
      }
    });
  };

  getGroupedPerms();

  return (
    <>
      <BreadCrumbSection />
      <Fragment>
        <Toast
          title="Create Role"
          content={data.content.value}
          classes={data.status.value}
          active={data.showToast.value}
          clickEvent={handleHideToast}
        />

        {data.showNotificationPopup.value && (
          <Notification title="Success">
            <div className="notification_icon-holder">
              <CompleteIcon />
            </div>
            <h5 className={'center-text'}>
              {data.isEditMode.value
                ? 'Role Now Updated'
                : 'Role Created Successfully'}
            </h5>
          </Notification>
        )}

        <form onSubmit={handleCreateCustomRole}>
          <div className="form-card top-border user-creation">
            <DataCard>
              <div className="flex-container space-between">
                <div className="form-desc">
                  <Card title="Basic Information">
                    Enter the name of the role
                  </Card>
                </div>

                <div className="form-fields">
                  <div className="flex-container">
                    <div className="form-fields">
                      <Card title="Name of the Role" required>
                        <Input
                          placeholder="Loan Officer"
                          required
                          type="text"
                          {...form.name.input}
                        />
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </DataCard>

            <DataCard>
              <div className="flex-container space-between">
                <div className="form-desc">
                  <Card title="Privileges">What they can do</Card>
                </div>

                <div className="form-fields">
                  <Card required>
                    {groupedPerms &&
                      groupedPerms.length >= 1 &&
                      groupedPerms.reverse().map(perms => {
                        return (
                          <Fragment key={perms}>
                            <div className={'group-title'}>
                              {perms
                                ? capitalizeInitialLetter(
                                    removeUnderscore(perms),
                                  )
                                : ''}
                            </div>
                            <div>
                              <CheckBoxGroup>
                                {permissions &&
                                  permissions.map(
                                    (item, index) =>
                                      item.category === perms && (
                                        <label key={`${index}-${item.name}`}>
                                          <CheckBox
                                            label={item.description}
                                            id={item.id}
                                            {...permissionInputs[item.id]
                                              ?.input}
                                          />
                                        </label>
                                      ),
                                  )}
                              </CheckBoxGroup>
                            </div>
                          </Fragment>
                        );
                      })}
                  </Card>
                </div>
              </div>
            </DataCard>

            <div className="flex-container center">
              <Button classes="secondary" type="submit">
                Create New Role
              </Button>
            </div>
          </div>
        </form>
      </Fragment>
    </>
  );
};

const CreateRoleWrapper = ({ match: { params } }) => {
  const { loading } = useRolesAndPermissions();
  const { loading: ciLoading } = useClientInfo();

  if (loading || ciLoading) return <Loader />;
  return <CreateRoles params={params} />;
};

export default withRouter(CreateRoleWrapper);
