import React from 'react';
import './_Login.scss';
import { Link } from 'react-router-dom';
import { CompleteIcon, OriginateLogo } from '../../components/Icons/';

const ResetPasswordComplete = () => {
  return (
    <section className={'login_page'}>
      <section className={'login_logo'}>
        <OriginateLogo />
      </section>

      <main className={'login_holder'}>
        <h3 className={'center-text'}>Congratulations!</h3>

        <div className={'login_icon-holder'}>
          <CompleteIcon />
        </div>

        <h5 className={'center-text'}>Password reset complete!</h5>

        <div className={'login_navlink'}>
          <div className="login_link">
            <Link to="/login">Return to Login →</Link>
          </div>
        </div>
      </main>
    </section>
  );
};

export default ResetPasswordComplete;
