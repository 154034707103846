import { useEffect, useState } from 'react';
import { Query } from '@apollo/react-components';
import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Loader } from '../components';
import { connection_limit } from '../config/config';
import { APPLICABLE_CUSTOMER_APPLICATIONS_QUERY } from '../graphql/queries';
import Constants from '../lib/Constants';
import { useCustomState } from '../lib/CustomHooks';
import {
  deepCopy,
  exportToCsv,
  removeCommas,
  trimGraphQLError,
} from '../lib/Utils';
import { toast } from 'react-toastify';

const ApplicableCustomerApplications = ({ render }) => {
  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    if (errorMsg) {
      toast.error(
        trimGraphQLError(
          errorMsg?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
    return () => toast.dismiss();
  }, [errorMsg]);

  let exportCsvData = {};

  const applicableApplicationsInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    searchParams: {
      id: useCustomState(),
      status: useCustomState(),
      email: useCustomState(),
      name: useCustomState(),
      amountLt: useCustomState(),
      amountGt: useCustomState(),
      repaymentAmountLt: useCustomState(),
      repaymentAmountGt: useCustomState(),
      applicationDateLt: useCustomState(),
      applicationDateGt: useCustomState(),
      repaymentDateLt: useCustomState(),
      repaymentDateGt: useCustomState(),
      isSearchPerformed: useCustomState(false),
    },
  };

  const setSearchParams = searchParams => {
    const {
      id,
      status,
      email,
      name,
      amountLt,
      amountGt,
      repaymentAmountLt,
      repaymentAmountGt,
      applicationDateLt,
      applicationDateGt,
      repaymentDateLt,
      repaymentDateGt,
    } = searchParams;

    return {
      id: id.value ? id.value : undefined,
      status: status.value ? status.value : undefined,
      email: email.value ? email.value : undefined,
      name: name.value ? name.value : undefined,
      amountLt: amountLt.value
        ? parseFloat(removeCommas(amountLt.value))
        : undefined,
      amountGt: amountGt.value
        ? parseFloat(removeCommas(amountGt.value))
        : undefined,
      repaymentAmountLt: repaymentAmountLt.value
        ? parseFloat(removeCommas(repaymentAmountLt.value))
        : undefined,
      repaymentAmountGt: repaymentAmountGt.value
        ? parseFloat(removeCommas(repaymentAmountGt.value))
        : undefined,
      applicationDateLt: applicationDateLt.value || undefined,
      applicationDateGt: applicationDateGt.value || undefined,
      repaymentDateLt: repaymentDateLt.value || undefined,
      repaymentDateGt: repaymentDateGt.value || undefined,
      first: undefined,
    };
  };

  const ExportCsvData = () => {
    const { searchParams } = applicableApplicationsInput;
    let isSearchPerformed =
      applicableApplicationsInput.searchParams.isSearchPerformed.value || false;

    const response = useLazyQuery(APPLICABLE_CUSTOMER_APPLICATIONS_QUERY, {
      fetchPolicy: 'no-cache',
      variables: isSearchPerformed ? setSearchParams(searchParams) : null,
    });

    if (response && response.data && response.data.viewer) {
      exportCsvData = response.data;
    }
  };

  ExportCsvData();

  const handleHideToast = e => {
    e.preventDefault();

    applicableApplicationsInput.showToast.setValue(false);
  };

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    applicableApplicationsInput.searchParams.isSearchPerformed.setValue(true);
    reFetch(refetch, applicableApplicationsInput.searchParams);
  };

  const handleReset = async (e, reFetch) => {
    e.preventDefault();

    const { searchParams } = applicableApplicationsInput;

    searchParams.id.setValue('');
    searchParams.id.setValue('');
    searchParams.status.setValue('');
    searchParams.email.setValue('');
    searchParams.name.setValue('');
    searchParams.amountLt.setValue('');
    searchParams.amountGt.setValue('');
    searchParams.repaymentAmountLt.setValue('');
    searchParams.repaymentAmountGt.setValue('');
    searchParams.applicationDateLt.setValue('');
    searchParams.applicationDateGt.setValue('');
    searchParams.repaymentDateLt.setValue('');
    searchParams.repaymentDateGt.setValue('');
    searchParams.isSearchPerformed.setValue(false);

    reFetch({
      id: undefined,
      status: undefined,
      email: undefined,
      name: undefined,
      amountLt: undefined,
      amountGt: undefined,
      repaymentAmountLt: undefined,
      repaymentAmountGt: undefined,
      applicationDateLt: undefined,
      applicationDateGt: undefined,
      repaymentDateLt: undefined,
      repaymentDateGt: undefined,
      first: connection_limit.value,
    });
  };

  const reFetch = async (refetch, searchParams) => {
    refetch(setSearchParams(searchParams));
  };

  const handleExportToCsv = () => {
    if (!exportCsvData || typeof exportCsvData === 'undefined') {
      applicableApplicationsInput.showToast.setValue(true);
      applicableApplicationsInput.status.setValue('Failed');
      applicableApplicationsInput.content.setValue(
        Constants.errorMessages.NO_EXPORT_DATA,
      );

      setTimeout(() => {
        applicableApplicationsInput.showToast.setValue(false);
      }, 2000);
    }

    const {
      viewer: {
        account: {
          applicableCustomerApplications: { nodes },
        },
      },
    } = exportCsvData;
    let csvData = deepCopy(nodes);

    const filterCsvData = () => {
      const newCsvData = [];
      for (let node of csvData) {
        const items = {};
        items.Customer_Name = `${node.user.firstName} ${node.user.lastName}`;
        items.Customer_Email = node.user.email || '';
        items.Application_ID = node.applicationNumber;
        items.Application_Status =
          node.status && node.status.name ? node.status.name : '';
        items.Requested_Amount = node.amount;
        items.Interest_Amount = node.chargesAmount;
        items.Repayment_Amount = node.fullAmount;
        items.Requested_Date = `"${moment(node.createdAt).format('llll')}"`;
        items.Requested_Duration = node.loanDuration;
        items.Repayment_Date = `"${moment(node.dateOfRepayment).format(
          'llll',
        )}"`;

        newCsvData.push(items);
      }

      csvData = newCsvData;
    };

    filterCsvData();

    exportToCsv(csvData, Constants.csvNames.APPLICABLE_APPLICATIONS);
  };

  const handleStartDate = e => {
    applicableApplicationsInput.searchParams.applicationDateLt.setValue(e);
  };

  const handleEndDate = e => {
    applicableApplicationsInput.searchParams.applicationDateGt.setValue(e);
  };

  const handleRepaymentStartDate = e => {
    applicableApplicationsInput.searchParams.repaymentDateGt.setValue(e);
  };

  const handleRepaymentEndDate = e => {
    applicableApplicationsInput.searchParams.repaymentDateLt.setValue(e);
  };

  return (
    <Query
      query={APPLICABLE_CUSTOMER_APPLICATIONS_QUERY}
      variables={{ first: connection_limit.value }}
      notifyOnNetworkStatusChange={true}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loader />;
        error && setErrorMsg(error);

        return render({
          data,
          error,
          applicableApplicationsInput,
          applicableApplicationsHideToast: e => handleHideToast(e),
          applicableApplicationsSearchQuery: e => handleRefetch(e, refetch),
          applicableApplicationsExportToCsv: e => handleExportToCsv(e),
          applicableApplicationsReset: e => handleReset(e, refetch),
          handleStartDate,
          handleEndDate,
          handleRepaymentStartDate,
          handleRepaymentEndDate,
        });
      }}
    </Query>
  );
};

export default withRouter(ApplicableCustomerApplications);
