import React from 'react';
import { NavLink } from 'react-router-dom';

const LoanNavs = () => {
  return (
    <nav className="loans-menu">
      <NavLink to="/v1/applications" exact data-testid="subtab-applications">
        Applications
      </NavLink>
      <NavLink to="/loans" exact data-testid="subtab-loans">
        Loans
      </NavLink>
    </nav>
  );
};

export default LoanNavs;
