import Papa from 'papaparse';
import moment from 'moment-timezone';
import { parseISO } from 'date-fns';

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const convertObjectArrayToCsv = (data, fileName, showHeaders) => {
  fileName = `${fileName}_${Math.floor(Date.now() / 1000)}`;

  if (data) {
    const csvData = Papa.unparse(data, {
      header: showHeaders,
    });

    if (checkIfBrowserIsIE()) {
      const iEWindow = window.open();
      iEWindow.document.write(`sep=,\r\n${csvData}`);
      iEWindow.document.close();
      iEWindow.document.execCommand('SaveAs', true, `${fileName}.csv`);
      iEWindow.close();
    } else {
      const uri = `data:application/csv;charset=utf-8,${encodeURI(csvData)}`;
      const link = document.createElement('a');
      link.href = uri;
      link.style = 'visibility:hidden';
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const normalizeDateTime = (e, endDate = false) => {
  return endDate
    ? parseISO(
        moment(e).endOf('day').tz('Africa/Lagos').format(`YYYY-MM-DD HH:mm:ss`),
      )
    : parseISO(moment(e).tz('Africa/Lagos').format(`YYYY-MM-DD HH:mm:ss`));
};

export const capitalizeInitialLetter = str => {
  if (typeof str !== 'string') {
    return str;
  }

  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
};

const checkIfBrowserIsIE = () => {
  const userAgent = window.navigator.userAgent;
  const iE = userAgent.indexOf('MSIE ');

  return !!(
    iE > 0 ||
    !!navigator.userAgent.match(/Trident/) ||
    navigator.userAgent.match(/rv:11/)
  );
};

export const exportToCsv = (data, name, showHeaders = true) => {
  if (data == null || data.length === 0) {
    return;
  }

  convertObjectArrayToCsv(data, name, showHeaders);
};

export const removeHyphen = e => e.replace(/-/g, ' ');

export const reduceStringLength = (e, length) => {
  if (e && e != null && typeof e !== 'undefined') {
    return e.substr(0, length);
  }

  return '';
};

export const unCamelize = str => {
  return str
    .replace(/(?=[A-Z][a-z]|ID)/g, ' ')
    .replace(/^./, first => first.toUpperCase())
    .replace(/'S/, second => second.toLowerCase());
};

export const insertSpaceBeforeCapitalLetter = e =>
  e.replace(/([A-Z])/g, ' $1').trim();

export const insertSpaceBeforeTwoConsecutiveCapitalLetters = e =>
  e
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

export const roundNumberToPrecision = (num, precision) => {
  const mult = Math.pow(10, precision || 0);
  return Math.round(num * mult) / mult;
};

export const roundWithDoublePrecision = num =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const roundDoublePrecisionWithCommas = num =>
  numberWithCommas(Math.round((num + Number.EPSILON) * 100) / 100);

export const removeUnderscore = e => e?.replace(/_/g, ' ');
export const addUnderscore = e => e?.replace(/ /g, '_');

export const numberWithCommas = e => {
  return e === null || typeof e === 'undefined'
    ? 0
    : e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const nairaAmount = e => {
  e = e != null && typeof e === 'number' ? e : 0;
  return '₦' + numberWithCommas(e.toFixed(2));
};

export const daysOnTable = e => {
  return e.toString() + ' days';
};

export const addDateToFileName = e => {
  return e + Math.floor(Date.now() / 1000);
};

export const trimGraphQLError = e => e?.replace('GraphQL error: ', '');

export const removeCommas = e => {
  const hasComma = /,/;

  return hasComma.test(e) ? e.replace(/,/g, '') : e;
};

export const deepCopy = obj => JSON.parse(JSON.stringify(obj));

export const generatePassword = length => {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~`!@#$%^&*()_+-={}[]:';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const formatPolicyMinMaxAmount = policy => {
  let text = `${
    policy.minLoanAmount !== null
      ? nairaAmount(policy.minLoanAmount)
      : nairaAmount(0.0)
  } - ${
    policy.maxLoanAmount !== null
      ? nairaAmount(policy.maxLoanAmount)
      : nairaAmount(0.0)
  }`;
  if (
    policy.loanType &&
    policy.loanType === 'GRADUATED' &&
    policy.graduatedLoanCycles.length > 0
  ) {
    let min = policy.graduatedLoanCycles[0].amount;
    let max = policy.graduatedLoanCycles[0].amount;
    policy.graduatedLoanCycles.forEach(cycle => {
      if (cycle.amount < min) min = cycle.amount;
      if (cycle.amount > max) max = cycle.amount;
    });
    text = `${min !== null ? nairaAmount(min) : nairaAmount(0.0)} - ${
      max !== null ? nairaAmount(max) : nairaAmount(0.0)
    }`;
  }

  return text;
};

export const sumColumn = (array, value) => {
  if (array.length)
    return array
      .filter(item => Object.keys(item).includes(value))
      .map(item => item[value])
      .reduce((sum, current) => sum + current);
};

export const subtractColumn = (array, value1, value2) => {
  if (array.length) {
    const columnResult = array
      .map(item => sumColumn(array, value1) - item[value2])
      .pop();
    if (columnResult <= 0) return 0;
    return columnResult;
  }
};

export const splitString = e => {
  const value = e.split('_');
  return value;
};

export const formatLoanDuration = (loanDuration, policy) => {
  if (policy.durationType === 'ANNUALLY') {
    if (+loanDuration < 365) return 'less than 1';
    const getDaysInYear = loanDuration / 365;
    const durationInYears = getDaysInYear.toFixed(0);
    return durationInYears;
  }

  if (policy.durationType === 'MONTHLY') {
    if (+loanDuration < 28) return 'less than 1';
    const getDaysInMonth = loanDuration / 30;
    const durationInMonths = getDaysInMonth.toFixed(0);
    return durationInMonths;
  }

  if (policy.durationType === 'WEEKLY') {
    if (+loanDuration < 7) return 'less than 1';
    const getDaysInWeeks = loanDuration / 7;
    const durationInWeeks = getDaysInWeeks.toFixed(0);
    return durationInWeeks;
  }

  return loanDuration;
};

export const getLastElement = (array, value) => {
  if (array.length) {
    return array
      .slice(-1)
      .map(item => item[value])
      .toString();
  }
  return 'N/A';
};

export const removeUnderscoreAndCapitalizeInitialLetters = str =>
  capitalizeInitialLetter(str.replace(/_/g, ' '));

export const removeNumbers = str => removeHyphen(str.replace(/[0-9]/g, ''));

export const getLoanDurationName = durationName => {
  switch (durationName) {
    case 'DAILY':
      return 'days';
    case 'WEEKLY':
      return 'weeks';
    case 'MONTHLY':
      return 'months';
    case 'ANNUALLY':
      return 'years';
    case 'ONE_OFF':
      return 'day';
    default:
      return 'days';
  }
};

export const getWeekOfMonth = weekOfMonth => {
  switch (weekOfMonth) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getTransactionRange = transactionRange => {
  switch (transactionRange) {
    case '< 10,000':
      return 'Less Than N10,000';
    case '10,000 - 100,000':
      return 'N10,000 - N100,000';
    case '100,000 - 500,000':
      return 'N100,000 - N500,000';
    case '> 500,000':
      return 'Greater Than N500,000';
    default:
      return '';
  }
};

export const ratioToPercent = number => {
  return typeof number === 'number' ? `${+number * 100}%` : number;
};

export const getQueryParam = param => {
  const query = new URLSearchParams(window.location.search);
  return query ? query.get(param) : null;
};

export const renameSupportingDocuments = item => {
  switch (item) {
    case 'Requires Work I D Upload':
      return 'Requires Work ID Upload';
    case 'Requires Bank Statement':
      return 'Requires MBS';
    case 'Allow bank selection on Statement Upload':
      return 'Allow bank selection on Statement Upload';
    case 'Requires Bank Statement Upload':
      return 'Requires PDF Statement Upload';
    case 'Requires Referee':
      return 'Requires Guarantor';
    case 'Requires Credit Registry Report':
      return 'Requires Credit Registry isPerforming Report';
    case 'Requires Credit Bureau Report':
      return 'Requires First Central Prime Report';
    case 'Requires Okra Transactions':
      return 'Requires Okra Bank Statement';
    case 'Requires Decide':
      return 'Requires Decide Bank Statement Analysis';
    case 'Requires Remita Mandate':
      return 'Requires Remita Direct Debit';
    case 'No Card Implementation':
      return 'Skip Card Tokenization';
    default:
      return item;
  }
};

export const filterOutRequiredSupportingDocuments = arr => {
  return arr.filter(t => t.name !== 'requiredSupportingDocuments');
};

export const getDifference = (arr1, arr2) => {
  return filterOutRequiredSupportingDocuments(arr1).filter(t => {
    return !arr2.some(x => {
      return t.id === x.id;
    });
  });
};

export const isValidUrl = (urlString, useRegex = false) => {
  const validateUrlWithRegex = () => {
    const validateUrl = () => {
      // Regex pattern for validating a URL
      const regex =
        /^(?:(?:http|https):\/\/)?(?:\S+(?::\S*)?@)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?::\d{2,5})?(?:\/\S*)?$/i;
      return regex.test(urlString);
    };

    try {
      return Boolean(validateUrl(urlString));
    } catch (e) {
      return false;
    }
  };

  const validateUrlWithURLConstructor = () => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  return useRegex ? validateUrlWithRegex() : validateUrlWithURLConstructor();
};

export const filterOutNullSupportingDocuments = arr => {
  return arr.filter(t => t.documentName !== null);
};

export const convertObjToKeyValueArr = (obj = {}) => {
  return Object.keys(obj).map(key => [key, obj[key]]);
};

export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;

  return `${(bytes / 1000 ** i).toFixed(0)} ${sizes[i]}`;
};
