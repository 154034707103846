import React from 'react';

const ProfileIcon = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4999 13.4634C14.5909 13.4634 17.1096 10.4454 17.1096 6.73171C17.1096 3.01805 14.5909 0 11.4999 0C8.40892 0 5.89014 3.01805 5.89014 6.73171C5.89014 10.4454 8.41453 13.4634 11.4999 13.4634ZM11.5 1.68373C13.671 1.68373 15.4268 3.92763 15.4268 6.73251C15.4268 9.53739 13.671 11.7813 11.5 11.7813C9.32902 11.7813 7.57317 9.53739 7.57317 6.73251C7.57317 3.92763 9.32902 1.68373 11.5 1.68373Z"
      fill="#0077FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6329 12.9022C16.1682 12.8557 15.7538 13.1948 15.7073 13.6595C15.6608 14.1243 15.9999 14.5387 16.4646 14.5851C19.0743 14.8918 21.0961 17.0126 21.2778 19.6339H1.7222C1.92105 17.0202 3.93411 14.9086 6.53537 14.5851C7.00009 14.5387 7.33916 14.1243 7.29268 13.6595C7.24621 13.1948 6.8318 12.8557 6.36707 12.9022C6.30537 12.9022 0 13.6203 0 20.4698C0 20.9345 0.376736 21.3112 0.841463 21.3112H22.1585C22.6233 21.3112 23 20.9345 23 20.4698C23 13.6203 16.6946 12.9022 16.6329 12.9022Z"
      fill="#0077FF"
    />
  </svg>
);

export default ProfileIcon;
