import React from 'react';
import PropTypes from 'prop-types';
import './_Select.scss';

// eslint-disable-next-line
const Select = React.forwardRef(
  (
    {
      label,
      children,
      errorMessage,
      description,
      warning,
      required,
      ...restProps
    },
    ref,
  ) => {
    return (
      <label className={'input_group'}>
        {label == null ? (
          ''
        ) : (
          <span className={'input_label'}>
            {label}
            {required && <span className="required">*</span>}
          </span>
        )}
        <div className={'wrapper'}>
          <select className={'select'} ref={ref} {...restProps}>
            {children}
          </select>
          {description == null ? (
            ''
          ) : (
            <span className={'input_description'}>{description}</span>
          )}
          {warning == null ? (
            ''
          ) : (
            <span className={'input_warning'}>{warning}</span>
          )}
          {errorMessage && (
            <span className={'input__error'}>{errorMessage}</span>
          )}
        </div>
      </label>
    );
  },
);

Select.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  warning: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default Select;
