import { useMutation } from '@apollo/react-hooks';
import { ADD_APPLICATION_COMMENT_MUTATION } from './queries';
import { useCustomState } from '../../lib/CustomHooks';
import { trimGraphQLError } from '../../lib/Utils';

export const useAddApplicationComment = ({
  application,
  comment,
  setComment,
}) => {
  const addApplicationCommentInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    message: useCustomState(),
    comment: useCustomState(),
    showPopup: useCustomState(false),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      addApplicationCommentInput.showToast.setValue(false);
    }, 3000);
  };

  const [addApplicationComment, { loading }] = useMutation(
    ADD_APPLICATION_COMMENT_MUTATION,
    {
      onCompleted: () => {
        addApplicationCommentInput.showToast.setValue(true);
        addApplicationCommentInput.status.setValue('Success');
        addApplicationCommentInput.content.setValue('Added New Comment');
        handleHideToast();
        hideAddApplicationCommentPopup();
        setComment('');
      },

      onError: error => {
        addApplicationCommentInput.showToast.setValue(true);
        addApplicationCommentInput.status.setValue('Failed');
        addApplicationCommentInput.content.setValue(
          trimGraphQLError(error.message),
        );
        handleHideToast();
      },
    },
  );

  const addCommentToApplication = async e => {
    e.preventDefault();

    await addApplicationComment({
      variables: {
        applicationId: application.id,
        comment,
      },
    });
  };

  const showAddApplicationCommentPopup = e => {
    e.preventDefault();
    addApplicationCommentInput.showPopup.setValue(true);
  };

  const hideAddApplicationCommentPopup = () => {
    addApplicationCommentInput.showPopup.setValue(false);
    setComment('');
  };

  return {
    loading,
    handleHideToast,
    hideAddApplicationCommentPopup,
    showAddApplicationCommentPopup,
    input: addApplicationCommentInput,
    addCommentToApplication,
  };
};
