import { withRouter } from 'react-router-dom';
import { CHANGE_CLIENT_USER_MUTATION } from '../graphql/mutations';
import { GET_ENUM } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation, useQuery } from '@apollo/react-hooks';

const ChangeClientUserStatusProvider = ({ render, userId }) => {
  const { data } = useQuery(GET_ENUM, {
    variables: {
      type: 'UserActions',
    },
  });

  let actions = [];
  if (data) {
    actions = data.enumValues;
  }

  const changeClientUserStatusInput = {
    userId: useCustomState(),
    action: useCustomState(),
    showPopup: useCustomState(false),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      changeClientUserStatusInput.showToast.setValue(false);
    }, 2500);
  };

  const showChangeClientUserStatusPopup = e => {
    e.preventDefault();

    changeClientUserStatusInput.showPopup.setValue(true);
  };

  const hideChangeClientUserStatusPopup = e => {
    e.preventDefault();

    changeClientUserStatusInput.showPopup.setValue(false);
  };

  const handleChangeClientUserStatus = async (
    e,
    handleChangeClientUserStatusMutation,
  ) => {
    e.preventDefault();

    if (!changeClientUserStatusInput.action.value)
      if (!changeClientUserStatusInput.userId.value) {
        changeClientUserStatusInput.showToast.setValue(true);
        changeClientUserStatusInput.status.setValue('Failed');
        changeClientUserStatusInput.content.setValue('Please select an action');

        handleHideToast();

        return;
      }

    await handleChangeClientUserStatusMutation({
      variables: {
        userId,
        action: changeClientUserStatusInput.action.value,
      },
    });

    changeClientUserStatusInput.showPopup.setValue(false);
  };

  const [handleChangeClientUserStatusMutation, { loading }] = useMutation(
    CHANGE_CLIENT_USER_MUTATION,
    {
      async onCompleted() {
        changeClientUserStatusInput.showToast.setValue(true);
        changeClientUserStatusInput.status.setValue('Success');
        changeClientUserStatusInput.content.setValue('Changed');
      },

      onError(error) {
        changeClientUserStatusInput.showToast.setValue(true);
        changeClientUserStatusInput.status.setValue('Failed');
        changeClientUserStatusInput.content.setValue(
          trimGraphQLError(error.message),
        );

        handleHideToast();
      },
    },
  );

  return render({
    loading,
    handleHideToast: e => handleHideToast(e),
    showChangeClientUserStatusPopup,
    hideChangeClientUserStatusPopup,
    handleChangeClientUserStatus: e =>
      handleChangeClientUserStatus(e, handleChangeClientUserStatusMutation),
    input: changeClientUserStatusInput,
    enumData: actions,
  });
};

export default withRouter(ChangeClientUserStatusProvider);
