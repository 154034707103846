import React from 'react';

const EmptyIcon = props => (
  <svg width={56} height={75} fill="none" {...props}>
    <path
      d="M54.841 19.327c.676.577.966 1.346.87 2.211v49.424c0 1.73-1.352 3.076-3.09 3.076H3.09c-1.738 0-3.09-1.346-3.09-3.076V3.076C0 1.347 1.352 0 3.09 0h30.993c.869 0 1.641.288 2.22.865l18.538 18.462zM37.172 10.48v7.98h8.014l-8.014-7.98zM24.814 6.058H6.179v61.73h43.352V24.615H34.083c-1.738 0-3.09-1.346-3.09-3.076V12.21c0-3.365-2.8-6.153-6.18-6.153zm-1.255 40.096L13.324 35.96c-1.255-1.153-1.255-3.076 0-4.326 1.159-1.25 3.09-1.25 4.345 0l10.234 10.192 10.235-10.192c1.159-1.25 3.09-1.25 4.345 0 1.255 1.153 1.255 3.076 0 4.326L32.248 46.154l10.235 10.288c1.255 1.154 1.255 3.077 0 4.327-1.159 1.25-3.09 1.25-4.345 0L27.903 50.577 17.67 60.673c-1.159 1.25-3.09 1.25-4.345 0-1.255-1.154-1.255-3.077 0-4.327L23.56 46.154z"
      fill="#D6DEE4"
    />
  </svg>
);

export default EmptyIcon;
