import { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ATTRIBUTES } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { useClientInfo } from '../Client';
import { trimGraphQLError } from '../../lib/Utils';
import { PRIVATE_CLIENT_INFO_QUERY } from '../Client/queries';
import { clientIdentifier } from '../../Providers/OriginateClient';
import { useHistory } from 'react-router-dom';

const useUpdateAttributes = () => {
  const history = useHistory();
  const sessionToken = localStorage.getItem('api-key');
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const { clientInfo, loading: ciLoading } = useClientInfo({
    privateFields: true,
  });

  const { clientId, clientWebhookUrl, secretKey } = clientInfo;

  const inputs = {
    liveSecretKey: useFormInput(),
    livePublicKey: useFormInput(),
    liveCallbackUrl: useFormInput(),
    liveWebhookUrl: useFormInput(),
    testPublicKey: useFormInput(),
    testSecretKey: useFormInput(),
    testCallbackUrl: useFormInput(),
    testWebhookUrl: useFormInput(),
  };

  useEffect(() => {
    if (clientWebhookUrl) {
      inputs.liveWebhookUrl.setValue(clientWebhookUrl);
    }
    if (secretKey) {
      inputs.liveSecretKey.setValue(secretKey);
    }
  }, [clientWebhookUrl, secretKey]);

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const handleUpdateAttributes = async e => {
    e.preventDefault();

    await requestUpdateAttributes({
      variables: {
        input: {
          accountId: clientId,
          clientWebhookUrl: inputs.liveWebhookUrl.value || null,
        },
        token: sessionToken,
      },
      refetchQueries: () => [
        {
          query: PRIVATE_CLIENT_INFO_QUERY,
          variables: { [clientIdentifier[0]]: clientIdentifier[1] },
        },
      ],
    });
  };

  const [requestUpdateAttributes, { loading }] = useMutation(
    UPDATE_ATTRIBUTES,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Updated Attribute Successfully');
        data.showModal.setValue(false);

        handleHideToast();
        setTimeout(() => {
          history.push('/settings');
        }, 4000);
      },
      onError,
    },
  );

  return {
    handleUpdateAttributes,
    loading,
    ciLoading,
    clientInfo,
    inputs,
    data,
    handleHideToast,
  };
};

export { useUpdateAttributes };
