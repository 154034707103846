import { useQuery } from '@apollo/react-hooks';
import { USER_PERMISSION_LIST_QUERY } from '../../graphql/queries';
import { useClientInfo } from '../../Providers-new/Client';

export const getUserFullDetails = () => {
  let response = [];
  const userEmail = localStorage.getItem('email') || null;

  const { result } = useClientInfo({ privateFields: true });

  const { data: userInfo = {} } = useQuery(USER_PERMISSION_LIST_QUERY, {
    variables: {
      clientId: result.data ? result.data.clientInfo.clientId : 0,
      email: userEmail,
    },
    fetchPolicy: 'network-only',
  });

  if (userInfo.clientAccountUsersXn) {
    response = userInfo.clientAccountUsersXn.nodes[0];
  }

  return response;
};

export const hasPermissions = (permissionName, userPermissions) =>
  !!userPermissions.find(p => permissionName.includes(p.name));
