import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, InMemoryCache } from 'apollo-boost';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';
import config from '../config/config';

const httpLink = createUploadLink({
  uri: `${config.ignite.base_url}graphql`,
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  const apiKey = localStorage.getItem('api-key');

  if (apiKey != null && typeof apiKey !== 'undefined') {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${apiKey}`,
      },
    };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const ApolloRequestClient = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloRequestClient;
