import moment from 'moment';
import React from 'react';
import { BlacklistUser } from '../../../components';
import { CheckIcon } from '../../../components/Icons';
import './_BlacklistReports.scss';

export const BlacklistReports = ({ blacklistDetails, refetch, userId }) => {
  const { reports } = blacklistDetails || {};

  return (
    <section className="blacklist-reports">
      <section className="blacklist-reports__buttons">
        <BlacklistUser
          refetch={refetch}
          userId={userId}
          blacklistDetails={blacklistDetails}
        />
      </section>

      {reports?.length > 0 && (
        <table className="blacklist-reports__table">
          <thead>
            <tr>
              <th className="align-left">Blacklist Reason</th>
              <th className="align-left medium">Blacklisted at</th>
              <th>BVN</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Expires at</th>
            </tr>
          </thead>

          <tbody>
            {reports.map(item => (
              <tr key={item?.id}>
                <td className="align-left full">
                  <section className="blacklist-reports__cell">
                    {item?.reason}
                  </section>
                </td>
                <td className="align-left">
                  <section className="blacklist-reports__cell medium">
                    {moment(item?.createdAt).format('lll')}
                  </section>
                </td>
                <td>
                  <section className="blacklist-reports__cell">
                    {item?.isSameBvn && <CheckIcon />}
                  </section>
                </td>
                <td>
                  <section className="blacklist-reports__cell">
                    {item?.isSamePhone && <CheckIcon />}
                  </section>
                </td>
                <td>
                  <section className="blacklist-reports__cell">
                    {item?.isSameEmail && <CheckIcon />}
                  </section>
                </td>
                <td>
                  <section className="blacklist-reports__cell">
                    {item?.expiryDate
                      ? new Date(item.expiryDate).toDateString()
                      : 'N/A'}
                  </section>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};
