import { graphql } from '@/__generated/gql';

export const GET_BANKS = graphql(/* GraphQL */ `
  query GetBanks {
    getBanks {
      name
      id
      code
    }
  }
`);
