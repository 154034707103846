const checkBoxStatus = [
  { id: 'employed', value: 'Employed Full Time' },
  { id: 'employed_part_time', value: 'Employed Part Time' },
  { id: 'unemployed', value: 'Unemployed' },
  { id: 'self_employed', value: 'Self Employed' },
];

const firstCentralCheckBoxStatus = [
  { id: 'account_in_standing_greater_than_zero', value: 'Yes' },
  { id: 'account_in_standing_equal_to_zero', value: 'No' },
  { id: 'notHit', value: 'Not Hit' },
];

const radioButtonStatus = [
  { id: 'yes', value: 'Yes' },
  { id: 'no', value: 'No' },
];

const crcCheckBoxStatus = [
  { id: 'delinquency_count_is_more_than_zero', value: 'Yes' },
  { id: 'delinquency_count_is_equal_to_zero', value: 'No' },
  { id: 'noHit', value: 'No_Hit' },
];

export const clientRulesInput = [
  'minAge',
  'maxAge',
  'states',
  'employmentStatuses',
  'isWorkEmailConfirmed',
  'maxDTIRatio',
  'minDTIRatio',
  'minDTIRatioBs',
  'maxDTIRatioBs',
  'minBouncedCheckCount',
  'maxBouncedCheckCount',
  'minPercentageBetting',
  'maxPercentageBetting',
  'minNetIncome',
  'maxNetIncome',
  'minNetIncomeBS',
  'minLoanAmount',
  'maxLoanAmount',
  'minLoanDuration',
  'maxLoanDuration',
  'minAccountsInBadStanding',
  'maxAccountsInBadStanding',
  'minTenorBs',
  'minTotalDebitBs',
  'maxOTIRatioBs',
  'maxPercentageBettingBS',
  'bankHasMbsSupport',
  'minAverageSalaryBSP',
  'maxAverageSalaryBSP',
  'minAverageOtherIncomeBSP',
  'maxAverageOtherIncomeBSP',
  'minNumberOfSalaryPaymentsBSP',
  'maxNumberOfSalaryPaymentsBSP',
  'minNumberOfOtherIncomePaymentsBSP',
  'maxNumberOfOtherIncomePaymentsBSP',
  'minAverageBalanceBSP',
  'maxAverageBalanceBSP',
  'minNetAverageMonthlyEarningsBSP',
  'maxNetAverageMonthlyEarningsBSP',
  'minTotalExpensesBSP',
  'maxTotalExpensesBSP',
  'isCashFlowPositiveBSP',
  'minLoanAmountBSP',
  'maxLoanAmountBSP',
  'minLoanRepaymentsBSP',
  'maxLoanRepaymentsBSP',
  'minLoanToInflowRate',
  'maxLoanToInflowRate',
  'minLoanRepaymentToInflowRate',
  'maxLoanRepaymentToInflowRate',
  'minGamblingRateBSP',
  'maxGamblingRateBSP',
  'accountSweepBSP',
  'minTotalCreditTurnoverBSP',
  'maxTotalCreditTurnoverBSP',
  'minTotalDebitTurnoverBSP',
  'maxTotalDebitTurnoverBSP',
  'minAverageMonthlyCreditsBSP',
  'maxAverageMonthlyCreditsBSP',
  'minAverageMonthlyDebitsBSP',
  'maxAverageMonthlyDebitsBSP',
  'minBalanceAtStatementEndBSP',
  'maxBalanceAtStatementEndBSP',
  'minDTIRatioBSP',
  'maxDTIRatioBSP',
  'salaryEarnerBSP',
  'firstCentralDelinquency',
  'crcDelinquency',
  'blacklistStatus',
  'creditRegistry',
  'bvnNinMatch',
];

export const approvedChkBoxStatuses = [...checkBoxStatus];
export const underReviewChkBoxStatuses = [...checkBoxStatus];

export const approvedRadioBtnStatuses = [...radioButtonStatus];
export const underReviewRadioBtnStatuses = [...radioButtonStatus];

export const approvedFirstCentralCheckBoxStatuses = [
  ...firstCentralCheckBoxStatus,
];
export const underReviewFirstCentralCheckBoxStatuses = [
  ...firstCentralCheckBoxStatus,
];
export const approvedCrcCheckBoxStatuses = [...crcCheckBoxStatus];
export const underReviewCrcCheckBoxStatuses = [...crcCheckBoxStatus];

export const approvedBlacklistStatusCheckbox = [
  { id: 'not_blacklisted', value: 'Not Blacklisted' },
];

export const underReviewBlacklistStatusCheckbox = [
  { id: 'blacklisted_by_me', value: 'Blacklisted By Me' },
  {
    id: 'blacklisted_by_other_merchants',
    value: 'Blacklisted By Other Merchants',
  },
];

export const approvedCreditRegistryCheckboxStatuses = [
  { id: 'PERFORMING', value: 'PERFORMING' },
  { id: 'NON_PERFORMING', value: 'NON_PERFORMING' },
  { id: 'NO_HIT', value: 'NO_HIT' },
];

export const underReviewCreditRegistryCheckboxStatuses = [
  { id: 'PERFORMING', value: 'PERFORMING' },
  { id: 'NON_PERFORMING', value: 'NON_PERFORMING' },
  { id: 'NO_HIT', value: 'NO_HIT' },
];

export const approvedBvnNinMatchCheckboxStatuses = [
  { id: 'FIRSTNAME_MATCH', value: 'FIRSTNAME_MATCH' },
  { id: 'LASTNAME_MATCH', value: 'LASTNAME_MATCH' },
  { id: 'DOB_MATCH', value: 'DOB_MATCH' },
];

export const underReviewBvnNinMatchCheckboxStatuses = [
  { id: 'FIRSTNAME_MATCH', value: 'FIRSTNAME_MATCH' },
  { id: 'LASTNAME_MATCH', value: 'LASTNAME_MATCH' },
  { id: 'DOB_MATCH', value: 'DOB_MATCH' },
];

export const formatForReactSelect = data => {
  let response = [];
  if (data && data.length >= 1) {
    data.map(item =>
      response.push({
        value: item.id ? item.id : item,
        label: item.name ? item.name : item,
      }),
    );
  }

  return response;
};
