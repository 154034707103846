import React, { Fragment } from 'react';
import {
  DataCard,
  Card,
  Button,
  Input,
  BreadCrumbSection,
  Loader,
  Toast,
  ToggleVisibilityAndCopy,
} from '../../components';
import './_UserManagement.scss';
import { useUpdateAttributes } from '../../Providers-new/Webhooks';

const WebHooks = () => {
  const {
    loading,
    ciLoading,
    inputs,
    data,
    handleHideToast,
    handleUpdateAttributes,
  } = useUpdateAttributes();

  return (
    <>
      <Fragment>
        {loading || (ciLoading && <Loader loadingText="loading..." />)}
        <BreadCrumbSection />
        <Toast
          title="Update Attributes"
          content={data.content.value}
          classes={data.status.value}
          active={data.showToast.value}
          clickEvent={handleHideToast}
        />
        <form>
          <div className="form-card top-border webhooks">
            <DataCard>
              <section className="flex-container space-between">
                <div className="form-desc">
                  <Card title="API Configuration"></Card>
                </div>

                <div className="form-fields">
                  <div className="flex-container">
                    <div className="form-main">
                      <Card title="Secret Key">
                        <ToggleVisibilityAndCopy
                          placeholder="Secret key"
                          disabled
                          {...inputs.liveSecretKey.input}
                        />
                      </Card>
                      <Card title="Webhook URL" required>
                        <Input
                          placeholder="Webhook URL"
                          required
                          type="text"
                          {...inputs.liveWebhookUrl.input}
                        />
                      </Card>
                    </div>
                  </div>
                </div>
              </section>
              <Button
                classes="right secondary"
                click_event={handleUpdateAttributes}
              >
                Save Changes
              </Button>
            </DataCard>
          </div>
        </form>
      </Fragment>
    </>
  );
};

export default WebHooks;
