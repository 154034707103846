import React from 'react';
import { Query } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { CUSTOMER_SUMMARY_QUERY } from '../graphql/queries';
import { Loader } from '../components';

const CustomerSummary = ({ render }) => {
  return (
    <Query query={CUSTOMER_SUMMARY_QUERY}>
      {({ loading, data }) => {
        if (loading) return <Loader />;

        return render({
          customerSummaryData: data,
        });
      }}
    </Query>
  );
};

export default withRouter(CustomerSummary);
