import { Icon, IconProps } from '@chakra-ui/icon';
import React from 'react';

const FinancialsIcon = ({ ...props }: IconProps) => {
  return (
    <Icon
      w={6}
      h={6}
      fill="none"
      stroke="gray.500"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.012 5.006l-2-.001a2 2 0 00-2 1.998l-.009 12a2 2 0 001.999 2.002l10 .006a2 2 0 002.001-1.998l.008-12a2 2 0 00-1.999-2.002l-2-.001"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.014 3.009l-2-.002a2 2 0 10-.003 4l2 .002a2 2 0 00.003-4zM9.008 12.006h.01M13.008 12.008l2 .002M9.005 16.006h.01M13.005 16.009h2"
      />
    </Icon>
  );
};

export default FinancialsIcon;
