import { useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { ADVANCE_APPLICATION_WORKFLOW_STEP } from '../graphql/mutations';
import {
  APPLICABLE_CUSTOMER_APPLICATIONS_QUERY,
  PORTFOLIO_QUERY,
} from '../graphql/queries';
import { trimGraphQLError } from '../lib/Utils';
import { useFormInput, useKeyPair } from '../Providers-new/Form';

const AdvanceApplicationWorkFlowStep = ({
  render,
  application,
  portfolio,
  shouldReload = false,
}) => {
  const data = {
    showToast: useKeyPair(false),
    content: useKeyPair(),
    status: useKeyPair(),
    showPopup: useKeyPair(false),
    comment: useFormInput(),
    message: useKeyPair(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 2500);
  };

  const handleAdvanceWorkflowStep = (e, advanceWorkflowStepMutation) => {
    e.preventDefault();

    const { comment: message } = data;

    advanceWorkflowStepMutation({
      variables: {
        input: {
          applicationId: application.id,
          comment: message.value,
        },
      },
      refetchQueries: () => [
        shouldReload
          ? {
              query: PORTFOLIO_QUERY,
              variables: {
                portfolioNumber: portfolio.portfolioNumber,
                transactionWhere: {
                  type_in: ['Manual Repayment', 'Repayment'],
                },
              },
            }
          : {
              query: APPLICABLE_CUSTOMER_APPLICATIONS_QUERY,
              variables: { applicationNumber: application.applicationNumber },
            },
      ],
    });
  };

  const showCompleteApplicationReviewPopup = e => {
    e.preventDefault();

    data.showPopup.setValue(!data.showPopup.value);
  };

  const handleHidePopup = () => {
    data.showPopup.setValue(!data.showPopup.value);
  };

  const [advanceWorkflowStepMutation, { loading }] = useMutation(
    ADVANCE_APPLICATION_WORKFLOW_STEP,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Successful');
        data.showPopup.setValue(!data.showPopup.value);

        handleHideToast();
        setTimeout(() => {
          !shouldReload && window.location.reload();
        }, 1000);
      },

      onError(error) {
        data.showToast.setValue(true);
        data.status.setValue('Failed');
        data.content.setValue(trimGraphQLError(error.message));
        handleHideToast();
      },
    },
  );

  return render({
    handleHideToast,
    handleCompleteApplication: e =>
      handleAdvanceWorkflowStep(e, advanceWorkflowStepMutation),
    input: data,
    showCompleteApplicationReviewPopup,
    completeReviewLoader: loading,
    handleHidePopup,
  });
};

export default withRouter(AdvanceApplicationWorkFlowStep);
