import { UPDATE_LOAN_PRODUCT } from '@/graphql/mutations/loan-products';
import { GET_LOAN_PRODUCTS } from '@/graphql/queries/loan-products';
import { UpdateLoanProductMutationVariables } from '@/__generated/graphql';
import { useMutation } from '@apollo/client';

export const useUpdateLoanProduct = () => {
  const [mutate, { data, loading, error }] = useMutation(UPDATE_LOAN_PRODUCT, {
    update: (cache, { data: { policyUpdate } }) => {
      const queryResult = cache.readQuery({ query: GET_LOAN_PRODUCTS });
      const viewer = queryResult?.viewer;

      if (viewer) {
        const updatedPolicies = viewer.account.policies.nodes.map(policy => {
          if (policy.id === policyUpdate?.recordId) {
            return { ...policy };
          }
          return policy;
        });

        cache.writeQuery({
          query: GET_LOAN_PRODUCTS,
          data: {
            viewer: {
              ...viewer,
              account: {
                ...viewer.account,
                policies: {
                  ...viewer.account.policies,
                  nodes: updatedPolicies,
                },
              },
            },
          },
        });
      }
    },
  });

  const updateProduct = ({
    policyId,
    data,
  }: UpdateLoanProductMutationVariables) => {
    mutate({
      variables: {
        policyId,
        data,
      },
    });
  };

  return {
    updateProduct,
    updateProductError: error,
    updateProductLoading: loading,
    productId: data?.policyUpdate?.recordId,
  };
};
