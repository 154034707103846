import React, { Fragment } from 'react';
import { Button, DataCard, Export, Table } from '../../../../components';
import { nairaAmount, numberWithCommas } from '../../../../lib/Utils';
import moment from 'moment';

const CrcMicroReportScoreReportTab = ({
  data,
  handleExportToCsvCrcScoreReport,
}) => {
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          {data &&
          data.application &&
          data.application.credit &&
          data.application.credit.crcMicroScoreReport != null &&
          data.application.credit.crcMicroScoreReport.result.noHit === false ? (
            <Button
              classes="right"
              click_event={handleExportToCsvCrcScoreReport}
            >
              <Export />
              <span className="export-csv">Export CSV</span>
            </Button>
          ) : (
            ' '
          )}
          <Table>
            <div className="table__body">
              <ul>
                {data &&
                data.application &&
                data.application.credit &&
                data.application.credit.crcMicroScoreReport != null &&
                data.application.credit.crcMicroScoreReport.result.noHit ===
                  false ? (
                  <>
                    {' '}
                    <li>
                      <span>Total No Of Delinqent Credit Facilities</span>
                      <span className="right-text">
                        {data.application.credit.crcMicroScoreReport.result
                          .totalNoOfDelinqCreditFacilities != null
                          ? numberWithCommas(
                              data.application.credit.crcMicroScoreReport.result
                                .totalNoOfDelinqCreditFacilities,
                            )
                          : '__'}
                      </span>
                    </li>
                    {data.application.credit.crcMicroScoreReport.result
                      .noHit === true ? (
                      <li>
                        <span>No Hit</span>
                        <span className="right-text">No Hit</span>
                      </li>
                    ) : (
                      ''
                    )}
                    {data.application.credit.crcMicroScoreReport.result
                      .lastReportedDate != null ? (
                      <li>
                        <span>Last Reported Date</span>
                        <span className="right-text">
                          {moment(
                            data.application.credit.crcMicroScoreReport.result
                              .lastReportedDate,
                          ).format('ll')}
                        </span>
                      </li>
                    ) : (
                      ''
                    )}
                    <li>
                      <span>Total Number of Credit Facilities</span>
                      <span className="right-text">
                        {data.application.credit.crcMicroScoreReport.result
                          .totalNumberOfCreditFacilities != null
                          ? numberWithCommas(
                              data.application.credit.crcMicroScoreReport.result
                                .totalNumberOfCreditFacilities,
                            )
                          : '__'}
                      </span>
                    </li>
                    <li>
                      <span>Sanctioned Amount</span>
                      <span className="right-text">
                        {data.application.credit.crcMicroScoreReport.result
                          .sanctionedAmount != null
                          ? nairaAmount(
                              data.application.credit.crcMicroScoreReport.result
                                .sanctionedAmount,
                            )
                          : '__'}
                      </span>
                    </li>
                    <li>
                      <span>Total Outstanding on Loans</span>
                      <span className="right-text">
                        {data.application.credit.crcMicroScoreReport.result
                          .totalOutstandingOnLoans != null
                          ? nairaAmount(
                              data.application.credit.crcMicroScoreReport.result
                                .totalOutstandingOnLoans,
                            )
                          : '__'}
                      </span>
                    </li>
                    {data.application.credit.crcMicroScoreReport.result
                      .totalDueOnLoans != null ? (
                      <li>
                        <span>Total OverDue on Loans</span>
                        <span className="right-text">
                          {nairaAmount(
                            data.application.credit.crcMicroScoreReport.result
                              .totalDueOnLoans,
                          )}
                        </span>
                      </li>
                    ) : (
                      ''
                    )}
                    <li>
                      <span>Number of Days in Delinquency</span>
                      <span className="right-text">
                        {data.application.credit.crcMicroScoreReport.result
                          .numberOfDaysInDelinquency != null
                          ? numberWithCommas(
                              data.application.credit.crcMicroScoreReport.result
                                .numberOfDaysInDelinquency,
                            )
                          : '__'}
                      </span>
                    </li>
                    <li>
                      <span>Number of Open Credit Facilities </span>
                      <span className="right-text">
                        {data.application.credit.crcMicroScoreReport.result
                          .noOfOpenCreditFacilities != null
                          ? numberWithCommas(
                              data.application.credit.crcMicroScoreReport.result
                                .noOfOpenCreditFacilities,
                            )
                          : '__'}
                      </span>
                    </li>
                  </>
                ) : (
                  <li>
                    <span>No Hit</span>
                    <span className="right-text">No Hit</span>
                  </li>
                )}
              </ul>
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default CrcMicroReportScoreReportTab;
