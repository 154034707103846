import React, { Fragment } from 'react';
import {
  Button,
  Input,
  Loader,
  Modal,
  Notification,
  Select,
  Toast,
} from '../../../../components';
import { Close, CompleteIcon } from '../../../../components/Icons';
import {
  capitalizeInitialLetter,
  getLoanDurationName,
  removeUnderscore,
} from '../../../../lib/Utils';

const ModifyLoanDuration = ({
  data,
  handleHideModifyLoanDurationToast,
  handleModalModifyLoanDurationToggle,
  handleUpdateLoanDuration,
  modifyLoanDuration,
  modifyLoanDurationData,
  modifyLoanDurationInput,
  modifyLoanDurationLoading,
}) => {
  return (
    <Fragment>
      {modifyLoanDurationLoading ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          <Toast
            title="Modify Loan Duration"
            content={modifyLoanDurationData.content.value}
            clickEvent={handleHideModifyLoanDurationToast}
            active={modifyLoanDurationData.showToast.value}
            classes={modifyLoanDurationData.status.value}
          />
          {modifyLoanDurationData.showNotificationPopup.value && (
            <Notification>
              <div className="notification_icon-holder">
                <CompleteIcon />
              </div>
              <h5 className={'center-text'}>
                {
                  <p>
                    You have successfully updated the duration for application{' '}
                    <b>{data.application.applicationNumber}</b>. The repayment
                    breakdown has been recalculated and your customer has been
                    notified
                  </p>
                }
              </h5>
            </Notification>
          )}
          <Modal
            title={'Update Loan Duration'}
            classes="repayment-date-modal"
            active={modifyLoanDurationData.showModal.value}
          >
            <Close
              className="close-icon"
              onClick={handleModalModifyLoanDurationToggle}
            />
            <form onSubmit={handleUpdateLoanDuration}>
              <Select
                label="Duration Type"
                required
                {...modifyLoanDurationInput.durationType.input}
              >
                <option value="">-- Select Duration Type--</option>
                {modifyLoanDuration &&
                  modifyLoanDuration.map((item, key) => (
                    <option key={key} value={item.name}>
                      {capitalizeInitialLetter(removeUnderscore(item.name))}
                    </option>
                  ))}
              </Select>
              <Input
                label={`Loan Duration (${getLoanDurationName(
                  modifyLoanDurationInput.durationType.value,
                )})`}
                numberField={true}
                required
                {...modifyLoanDurationInput.loanDuration.input}
              />

              <div className="repayment-double-buttons">
                <Button
                  classes="cancel"
                  click_event={handleModalModifyLoanDurationToggle}
                >
                  Cancel
                </Button>
                <Button classes="secondary blue save" type="submit">
                  Update
                </Button>
              </div>
            </form>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ModifyLoanDuration;
