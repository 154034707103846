import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ATTRIBUTES } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { removeCommas, trimGraphQLError } from '../../lib/Utils';
import { PRIVATE_CLIENT_INFO_QUERY } from '../Client/queries';
import { clientIdentifier } from '../../Providers/OriginateClient';
import { useClientInfo } from '../Client';
import { useEffect } from 'react';

const useMerchantConfig = () => {
  const sessionToken = localStorage.getItem('api-key');
  let isDisabled = false;
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    clientSlug: useFormInput(),
    clientAppUrl: useFormInput(),
    phone: useFormInput(),
    email: useFormInput(),
    primaryColor: useFormInput(),
    secondaryColor: useFormInput(),
  };

  const payload = {
    clientSlug: inputs.clientSlug.value.trim(),
    clientAppUrl: inputs.clientAppUrl.value.trim(),
    contactDetails: {
      phone: inputs.phone.value.trim(),
      email: inputs.email.value.trim(),
    },

    clientTheme: {
      primaryColor: inputs.primaryColor.value.trim(),
      secondaryColor: inputs.secondaryColor.value.trim(),
    },
  };

  if (
    !payload.clientSlug ||
    !payload.clientAppUrl ||
    !payload.contactDetails.phone ||
    !payload.contactDetails.email ||
    !payload.clientTheme.primaryColor ||
    !payload.clientTheme.secondaryColor
  ) {
    isDisabled = true;
  }

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const { clientInfo, loading: ciLoading } = useClientInfo({
    privateFields: false,
  });

  useEffect(() => {
    const setInputValues = () => {
      if (clientInfo && clientInfo.slug) {
        inputs.clientSlug.setValue(clientInfo.slug);
      }

      if (
        clientInfo &&
        clientInfo.contactDetails &&
        clientInfo.contactDetails.phone
      ) {
        inputs.phone.setValue(clientInfo.contactDetails.phone);
      }

      if (
        clientInfo &&
        clientInfo.contactDetails &&
        clientInfo.contactDetails.email
      ) {
        inputs.email.setValue(clientInfo.contactDetails.email);
      }

      if (
        clientInfo &&
        clientInfo.clientTheme &&
        clientInfo.clientTheme.primaryColor
      ) {
        inputs.primaryColor.setValue(clientInfo.clientTheme.primaryColor);
      }
      if (
        clientInfo &&
        clientInfo.clientTheme &&
        clientInfo.clientTheme.secondaryColor
      ) {
        inputs.secondaryColor.setValue(clientInfo.clientTheme.secondaryColor);
      }

      if (clientInfo && clientInfo.clientAppUrl) {
        inputs.clientAppUrl.setValue(clientInfo.clientAppUrl);
      }
    };

    setInputValues();
  }, [clientInfo]);

  const handleUpdateAttributes = async e => {
    e.preventDefault();

    await requestUpdateAttributes({
      variables: {
        input: { accountId: clientInfo.clientId, ...payload },
        token: sessionToken,
      },
      refetchQueries: () => [
        {
          query: PRIVATE_CLIENT_INFO_QUERY,
          variables: { [clientIdentifier[0]]: clientIdentifier[1] },
        },
      ],
    });
  };

  const [requestUpdateAttributes, { loading }] = useMutation(
    UPDATE_ATTRIBUTES,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Updated Attribute Successfully');
        data.showModal.setValue(false);

        handleHideToast();
      },
      onError,
    },
  );

  return {
    handleUpdateAttributes,
    loading,
    ciLoading,
    inputs,
    isDisabled,
    data,
    handleHideToast,
  };
};

export { useMerchantConfig };
