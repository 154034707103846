import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_APPLICATION_DETAILS } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { removeCommas, trimGraphQLError } from '../../lib/Utils';
import { APPLICATION_QUERY } from '../../graphql/queries';

const useMutateUpdateApplicationLoanAmount = ({
  application,
  decideLoanAmount,
}) => {
  const applicationLoanAmountData = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
    showNotificationPopup: useKeyPair(false),
    loanAmount: useFormInput(),
    decideAffordabilityAmount: useFormInput(),
  };

  const [checkboxState, setCheckboxState] = useState(false);
  const [modifyRequestedAmountCheck, updateModifyRequestedAmountCheck] =
    useState({
      checked: '',
    });

  const [
    updateApplicationLoanAmount,
    { loading: updateApplicationLoanAmountLoader },
  ] = useMutation(UPDATE_APPLICATION_DETAILS, {
    onCompleted: () => {
      applicationLoanAmountData.showToast.setValue(true);
      applicationLoanAmountData.status.setValue('Success');
      applicationLoanAmountData.content.setValue('Application Amount Updated');
      applicationLoanAmountData.showModal.setValue(false);
      applicationLoanAmountData.showNotificationPopup.setValue(true);

      handleHideApplicationLoanAmountToast();
      handleHideNotification();
    },
    onError: error => {
      applicationLoanAmountData.showToast.setValue(true);
      applicationLoanAmountData.status.setValue('Failed');
      applicationLoanAmountData.content.setValue(
        trimGraphQLError(error.message),
      );

      handleHideApplicationLoanAmountToast();
      handleApplicationLoanAmountModalToggle();
    },
  });

  const handleHideApplicationLoanAmountToast = () => {
    setTimeout(() => {
      applicationLoanAmountData.showToast.setValue(false);
    }, 3500);
  };

  const handleHideNotification = () => {
    setTimeout(() => {
      applicationLoanAmountData.showNotificationPopup.setValue(false);
    }, 3500);
  };

  const handleUpdateApplicationLoanAmount = async e => {
    e.preventDefault();

    if (
      modifyRequestedAmountCheck.checked.id !== 'LOAN_AMOUNT' &&
      modifyRequestedAmountCheck.checked.id !== 'EXPECTED_AMOUNT'
    ) {
      applicationLoanAmountData.showToast.setValue(true);
      applicationLoanAmountData.status.setValue('Failed');
      applicationLoanAmountData.content.setValue('Please select a loan amount');

      handleHideApplicationLoanAmountToast();
      return;
    }

    if (
      modifyRequestedAmountCheck.checked.id === 'LOAN_AMOUNT' &&
      !applicationLoanAmountData.loanAmount.value
    ) {
      applicationLoanAmountData.showToast.setValue(true);
      applicationLoanAmountData.status.setValue('Failed');
      applicationLoanAmountData.content.setValue('Enter a loan amount.');

      handleHideApplicationLoanAmountToast();
      return;
    }

    if (
      modifyRequestedAmountCheck.checked.id === 'EXPECTED_AMOUNT' &&
      decideLoanAmount <= 0
    ) {
      applicationLoanAmountData.showToast.setValue(true);
      applicationLoanAmountData.status.setValue('Failed');
      applicationLoanAmountData.content.setValue(
        'Please provide a loan amount',
      );

      handleHideApplicationLoanAmountToast();
      return;
    }

    await updateApplicationLoanAmount({
      variables: {
        input: {
          applicationId: application.id,
          amount:
            parseInt(
              removeCommas(applicationLoanAmountData.loanAmount.value),
            ) || parseInt(removeCommas(decideLoanAmount)),
        },
      },
      refetchQueries: () => [
        {
          query: APPLICATION_QUERY,
          variables: { applicationNumber: application.applicationNumber },
        },
      ],
      awaitRefetchQueries: true,
    });

    applicationLoanAmountData.loanAmount.setValue('');
    applicationLoanAmountData.decideAffordabilityAmount.setValue('');
    updateModifyRequestedAmountCheck({ checked: '' });
  };

  const handleApplicationLoanAmountModalToggle = () => {
    applicationLoanAmountData.showModal.setValue(
      !applicationLoanAmountData.showModal.value,
    );
    applicationLoanAmountData.loanAmount.setValue('');
    applicationLoanAmountData.decideAffordabilityAmount.setValue('');
    setCheckboxState(false);
    updateModifyRequestedAmountCheck({ checked: '' });
  };

  const handleLoanAmountCheckBoxInput = e => {
    const isChecked = e.target.checked;
    setCheckboxState(isChecked);
    if (isChecked) {
      applicationLoanAmountData.decideAffordabilityAmount.setValue(
        parseInt(decideLoanAmount),
      );
    }
    if (!isChecked) {
      applicationLoanAmountData.decideAffordabilityAmount.setValue('');
      setCheckboxState(false);
    }
  };

  const handleModifyRequestedAmountCheck = id => {
    updateModifyRequestedAmountCheck({ checked: id });
  };

  return {
    applicationLoanAmountData,
    handleHideApplicationLoanAmountToast,
    updateApplicationLoanAmountLoader,
    handleApplicationLoanAmountModalToggle,
    handleUpdateApplicationLoanAmount,
    handleLoanAmountCheckBoxInput,
    handleModifyRequestedAmountCheck,
    modifyRequestedAmountCheck,
    checkboxState,
  };
};

export { useMutateUpdateApplicationLoanAmount };
