import { useQuery } from '@apollo/react-hooks';

import { GET_APPROVAL_WORKFLOWS } from './queries';

const useGetApprovalWorkflows = () => {
  let output = {
    approvalWorkflows: [],
  };

  const qResult = useQuery(GET_APPROVAL_WORKFLOWS);
  const { data, loading, error } = qResult;

  if (!loading && data && data.approvalWorkflows) {
    output = {
      approvalWorkflows: data.approvalWorkflows,
    };
  }

  return {
    loading,
    error,
    ...output,
    result: qResult,
  };
};

export { useGetApprovalWorkflows };
