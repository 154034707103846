import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  BreadCrumbSection,
  Button,
  CustomerSummary,
  DataCard,
  DateRange,
  EmptyScreen,
  Export,
  Input,
  SideBar,
  Table,
  Toast,
  Loader,
} from '../../components';
import { reduceStringLength } from '../../lib/Utils';
import {
  CustomersProvider,
  CustomerSummaryProvider,
  CustomerTableProvider,
} from '../../Providers';

const headers = [
  'Facility ID',
  'Facility Name',
  'Applicant Name',
  'Applicant Phone',
  'Applicant Email',
];

const Facilities = () => (
  <>
    <CustomersProvider
      render={({
        data,
        handleHideToast,
        input,
        handleExportToCsv,
        handleReset,
        runSearchQuery,
        handleStartDate,
        handleEndDate,
      }) => (
        <Fragment>
          <BreadCrumbSection />

          <SideBar>
            <section>
              <h4 className={'sidebar-header'}>Search Parameter</h4>

              <form>
                <Input
                  label="Facility ID"
                  placeholder="Enter Facility ID"
                  {...input.customerId.input}
                />
                <Input
                  label="Email"
                  placeholder="Enter Email"
                  {...input.email.input}
                />
                <Input
                  label="Applicant Name"
                  placeholder="Enter Applicant Name"
                  {...input.name.input}
                />
                <div>
                  <span className="sidebar__label">{'Created at'}</span>
                  <div className={'filter-range'}>
                    <DateRange
                      startDateInput={input.createdAtLt.value}
                      endDateInput={input.createdAtGt.value}
                      startDate={handleStartDate}
                      endDate={handleEndDate}
                    />
                  </div>
                </div>

                <div>
                  <Button click_event={handleReset}>Reset</Button>
                  <Button
                    classes={'right secondary'}
                    click_event={runSearchQuery}
                  >
                    Search
                  </Button>
                </div>
              </form>
            </section>
          </SideBar>

          <main className={'layout-main'}>
            <h5>Customer Summary</h5>

            <Toast
              title="Customers"
              content={input.content.value}
              classes={input.status.value}
              active={input.showToast.value}
              clickEvent={handleHideToast}
            />

            <CustomerSummaryProvider
              render={({ customerSummaryData }) => (
                <CustomerSummary
                  title="Active Customers"
                  classes={'three-grid'}
                >
                  <div>
                    <h5>Total Number of Facilities</h5>
                    <h3>
                      {customerSummaryData
                        ? customerSummaryData.customerSummary.youngAdults
                        : 0}
                    </h3>
                  </div>
                  <div className="mid">
                    <h5>New Facilities</h5>
                    <h3>
                      {customerSummaryData
                        ? customerSummaryData.customerSummary.middleAged
                        : 0}
                    </h3>
                  </div>
                  <div className="right">
                    <h5>Aged (35 - 55)</h5>
                    <h3>
                      {customerSummaryData
                        ? customerSummaryData.customerSummary.aged
                        : 0}
                    </h3>
                  </div>
                </CustomerSummary>
              )}
            />

            <div className="customers">
              <h5>All Facilities</h5>

              <Button classes="right" click_event={handleExportToCsv}>
                <Export />
                <span className="export-csv">Export CSV</span>
              </Button>
            </div>

            {data &&
            data.views &&
            data.views.customers &&
            data.views.customers.nodes.length > 0 ? (
              <Fragment>
                <CustomerTableProvider
                  render={({
                    isLoading,
                    handlePagination,
                    customerTableData,
                  }) => (
                    <section>
                      <DataCard noPadding={false}>
                        <Table>
                          <div className="table__head">
                            {headers.map((header, key) => (
                              <span key={key}>{header}</span>
                            ))}
                          </div>
                          <div className="table__body">
                            {customerTableData &&
                              customerTableData.views &&
                              customerTableData.views.customers.nodes.map(
                                (customer, key) => (
                                  <ul key={key}>
                                    <Link
                                      to={`/facilities/${customer.customerId}`}
                                    >
                                      <li>
                                        <span>
                                          <div className="first-td">
                                            {reduceStringLength(
                                              customer.customerId,
                                              12,
                                            )}
                                          </div>
                                        </span>
                                        <span>{customer.facilityName}</span>
                                        <span>{`${customer.fullName}`}</span>
                                        <span>{customer.phoneNumber}</span>
                                        <span>{customer.contactEmail}</span>
                                      </li>
                                    </Link>
                                  </ul>
                                ),
                              )}
                          </div>
                        </Table>
                      </DataCard>

                      {customerTableData.views.customers.pageInfo.hasNextPage &&
                        (isLoading ? (
                          <Loader />
                        ) : (
                          <Button
                            classes={'secondary center activate-policy'}
                            click_event={handlePagination}
                          >
                            Load more
                          </Button>
                        ))}
                    </section>
                  )}
                />
              </Fragment>
            ) : (
              <DataCard noPadding={true}>
                <EmptyScreen message="No Facilities Yet" />
              </DataCard>
            )}
          </main>
        </Fragment>
      )}
    />
  </>
);

export default Facilities;
