import React, { Fragment } from 'react';
import moment from 'moment';
import {
  DataCard,
  Table,
  Button,
  EmptyScreen,
  BreadCrumbSection,
  Loader,
} from '../../components';
import {
  ActionableCustomerApplications,
  TasksTableProvider,
} from '../../Providers';
import { Link } from 'react-router-dom';
import {
  reduceStringLength,
  removeUnderscore,
  nairaAmount,
} from '../../lib/Utils';
import cx from 'classnames';
import './_Tasks.scss';
import { ErrorBoundary } from 'react-error-boundary';

const headers = {
  applicationHeader: [
    'Status',
    'Application ID',
    "Applicant's Name",
    'Amount Requested',
    'Application Date',
    '',
  ],
};

/**
 * unapplicableApplications are applications that the merchant cannot perform an action on and should not be on the tasks page
 * @type {array}
 */

const unapplicableApplications = ['DENIED', 'APPROVED'];

const Portfolios = () => (
  <>
    <ActionableCustomerApplications
      render={({ data }) => {
        return (
          <Fragment>
            <BreadCrumbSection />

            <main className="form_card ">
              <h5>Tasks</h5>
              {data?.viewer?.account?.applicableCustomerApplications?.nodes
                ?.length > 0 ? (
                <div className="loans">
                  <ErrorBoundary
                    fallback={
                      <DataCard noPadding={true}>
                        <EmptyScreen message="Error Fetching Tasks" />
                      </DataCard>
                    }
                  >
                    <TasksTableProvider
                      render={({
                        isLoading,
                        handlePagination,
                        taskTableData,
                      }) => (
                        <section>
                          <DataCard noPadding={false}>
                            <Table>
                              <div className="table__head">
                                {headers.applicationHeader.map(
                                  (header, key) => (
                                    <span
                                      key={key}
                                      className={cx({
                                        Status: header,
                                        'checked-width': header === 'Status',
                                      })}
                                    >
                                      {' '}
                                      {header}{' '}
                                    </span>
                                  ),
                                )}
                              </div>
                              <div className="table__body applications-table">
                                <ul>
                                  {taskTableData?.viewer?.account?.applicableCustomerApplications?.nodes.map(
                                    (customerApplication, key) => (
                                      <li key={key} className="table_row">
                                        {!unapplicableApplications.includes(
                                          customerApplication.status.name,
                                        ) && (
                                          <Fragment>
                                            <span
                                              className="tooltip checked-width"
                                              data-title={removeUnderscore(
                                                customerApplication.status.name,
                                              )}
                                            >
                                              <span
                                                className={`status fixed ${
                                                  customerApplication.status.name.toLowerCase() ||
                                                  ''
                                                }`}
                                              >
                                                {(() => {
                                                  const statusName =
                                                    customerApplication.status
                                                      .name;
                                                  switch (statusName) {
                                                    case 'APPROVED':
                                                      return 'APP';
                                                    case 'DENIED':
                                                      return 'DEN';
                                                    case 'PENDING':
                                                      return 'PEN';
                                                    case 'UNDER_REVIEW':
                                                      return 'REV';
                                                    case 'ABANDONED':
                                                      return 'ABA';
                                                    case 'AWAITING_FEEDBACK':
                                                      return 'AWF';
                                                    default:
                                                      return 'PEN';
                                                  }
                                                })()}
                                              </span>
                                            </span>
                                            <span>
                                              <div className="first-td">
                                                {reduceStringLength(
                                                  customerApplication.applicationNumber,
                                                  12,
                                                )}
                                              </div>
                                            </span>
                                            <span>{`${customerApplication.user.firstName} ${customerApplication.user.lastName}`}</span>
                                            <span>
                                              {nairaAmount(
                                                customerApplication.amount,
                                              )}
                                            </span>
                                            <span>
                                              {moment(
                                                customerApplication.createdAt,
                                              ).format('lll')}
                                            </span>
                                            <span className="action_button">
                                              <Link
                                                to={`/v1/applications/${customerApplication.applicationNumber}/${customerApplication.user.email}`}
                                              >
                                                <Button className="edit_button">
                                                  {(() => {
                                                    const approvalType =
                                                      customerApplication
                                                        .approvalWorkflow
                                                        .nextStep.approvalType;

                                                    switch (approvalType) {
                                                      case 'REVIEW':
                                                        return 'Review';
                                                      case 'APPROVE':
                                                        return 'Approve';
                                                      case 'DISBURSE':
                                                        return 'Disburse';
                                                      default:
                                                        return 'yayaya';
                                                    }
                                                  })()}
                                                </Button>
                                              </Link>
                                            </span>
                                          </Fragment>
                                        )}
                                      </li>
                                    ),
                                  )}
                                </ul>
                              </div>
                            </Table>
                          </DataCard>

                          {taskTableData?.viewer?.account
                            ?.applicableCustomerApplications.pageInfo
                            .hasNextPage &&
                            (isLoading ? (
                              <Loader />
                            ) : (
                              <Button
                                classes={'secondary center load-more'}
                                click_event={handlePagination}
                              >
                                Load more
                              </Button>
                            ))}
                        </section>
                      )}
                    />
                  </ErrorBoundary>
                </div>
              ) : (
                <DataCard noPadding={false}>
                  <EmptyScreen message="No Tasks Yet" />
                </DataCard>
              )}
            </main>
          </Fragment>
        );
      }}
    />
  </>
);

export default Portfolios;
