import React from 'react';
import './_Styles.scss';
import PropTypes from 'prop-types';
import { OptionsIcon } from '../../components/Icons/';
const MoreOptions = props => {
  return (
    <span className={`more-options ${props.id ? props.id : ''}`}>
      <OptionsIcon color={`${props.iconColor || '#0077ff'}`} />
      <span className="more-content">{props.children}</span>
    </span>
  );
};
MoreOptions.propTypes = {
  id: PropTypes.string,
  iconColor: PropTypes.string,
};
export default MoreOptions;
