import { useGetLoanProducts } from '@/services/loan-products';
import { processGraphQLError } from '@/utils/processGraphQLError';
import { debounce, startCase, toLower } from 'lodash';
import React from 'react';
import { MultiSelect, MultiSelectProps } from '../MultiSelect';

type SelectLoanProductType = MultiSelectProps & {
  productStatus?: 'ACTIVE';
};

export const SelectLoanProduct = ({
  isInvalid,
  errorMessage,
  productStatus,
  placeholder,
  ...props
}: SelectLoanProductType) => {
  const {
    loanProducts,
    refetchProducts,
    loadingInitialProducts,
    loanProductsError,
  } = useGetLoanProducts(productStatus && { status: productStatus });

  const debouncedSearch = debounce((searchTerm: string) => {
    refetchProducts({ name: searchTerm });
  }, 1000);

  const handleSearch = (searchTerm: string) => {
    searchTerm && debouncedSearch(searchTerm);
  };

  const error = loanProductsError && processGraphQLError(loanProductsError);

  return (
    <MultiSelect
      isInvalid={isInvalid || !!error}
      isLoading={loadingInitialProducts}
      errorMessage={errorMessage || error}
      onInputChange={handleSearch}
      placeholder={placeholder || '-- Select product --'}
      {...props}
      options={loanProducts?.map(item => ({
        label: startCase(toLower(item.name)),
        value: item.id,
      }))}
      noOptionsMessage={() =>
        loanProducts?.length === 0
          ? 'Product not found'
          : 'Searching products . . .'
      }
    />
  );
};

export default SelectLoanProduct;
