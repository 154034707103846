import { gql } from 'apollo-boost';

const ACCOUNT_FRAGMENT = `
    on Account {
        id
        name
        bvn{
            phone
            number
            gender
            dateOfBirth
            firstName
            lastName
            name
            image {
                key
                bucket
            }
        }
        user {
            id
            firstName
            lastName
            email
            phone
            status
            isBvnVerified
            kycInformation
            userMetadata {
                id
                name
                value
            }
            isEmailConfirmed
            isPhoneConfirmed
            bvnStatus {
                id
                isVerified
                isMatchingPhone
                bvn
                phone
                dateOfBirth
                image
            }
            supportingDocument{
                fileUrl
                documentName
                file {
                    key
                    bucket
                }
            }
            blacklistDetails {
                blacklisted
                isInternalBlacklist
                reports {
                    id
                    reason
                    isSameEmail
                    isSamePhone
                    isSameBvn
                    createdAt
                    actionBy {
                        firstName
                        lastName
                    }
                    expiryDate
                }
            }
        }
        accountAttribute {
            attribute {
              name
            }
            value
            data
          }
        applications {
            nodes {
                id
                applicationNumber
                amount
                chargesAmount
                baseAmount
                fullAmount
                taxAmount
                loanDuration
                dateOfRepayment
                createdAt
                status {
                    name
                }
                user{
                    id
                   email
                }
                policy{
                    id
                    durationType
                }
            }
            totalCount
        }
        portfolios {
            nodes {
                id
                portfolioNumber
                amount
                policy{
                    id
                    durationType
                }
                status {
                  name
                }
                user{
                   id
                   email
                }
                taxAmount
                createdAt
                baseAmount
                fullAmount
                amountPaid
                loanDuration
                chargesAmount
                dayOfRepayment
                dateOfRepayment
                amountDisbursed
                lateRepaymentFee
            }
            totalCount
        }
        cards {
            maskedPan
            expiryDate
        }
        bankAccounts {
            accountName
            accountNumber
            bank {
                name
                code
            }
            isDefault
        }
    }
`;

export const CUSTOMER_BY_ID_QUERY = gql`
    query MP_CustomerByIdQuery($id: ID!) {
        customerById(id: $id) {
            ...${ACCOUNT_FRAGMENT}
        }
    }
`;

export const CUSTOMER_BY_PARAMS_QUERY = gql`
    query MP_CustomerByParamsQuery($name: String!) {
      customer(input: {name: $name}) {
        ...${ACCOUNT_FRAGMENT}
      }
      latestApplicationFormData(input: { customerId: $name }) {
        data
        success
      }
    }
`;

export const CUSTOMER_SUMMARY_QUERY = gql`
  query MP_CustomerSummaryQuery {
    customerSummary {
      youngAdults
      middleAged
      aged
    }
  }
`;
