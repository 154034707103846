import React, { Fragment } from 'react';
import moment from 'moment';
import {
  DataCard,
  Table,
  CustomerSummary,
  BreadCrumbSection,
  SideBar,
  Toast,
  Input,
  Button,
  Export,
  EmptyScreen,
  DateRange,
  Loader,
} from '../../components';
import {
  CustomersProvider,
  CustomerSummaryProvider,
  CustomerTableProvider,
} from '../../Providers';
import { Link } from 'react-router-dom';
import { reduceStringLength } from '../../lib/Utils';
import { ErrorBoundary } from 'react-error-boundary';

const headers = [
  'Customer ID',
  'Full Name',
  'Phone Number',
  'Contact Email',
  'Created At',
];

const Customers = ({
  data,
  handleHideToast,
  input,
  handleExportToCsv,
  handleReset,
  runSearchQuery,
  handleStartDate,
  handleEndDate,
  setSearchParams,
}) => {
  return (
    <Fragment>
      <BreadCrumbSection />

      <SideBar>
        <section>
          <h4 className={'sidebar-header'}>Search Parameter</h4>

          <form>
            <Input
              data-testid="customer-ID"
              label="Customer ID"
              placeholder="Enter Customer ID"
              {...input?.customerId?.input}
            />
            <Input
              data-testid="customer-email"
              label="Email"
              placeholder="Enter Email"
              {...input?.email?.input}
            />
            <Input
              data-testid="customer-phonenumber"
              label="Phone"
              placeholder="Enter Phone Number"
              {...input?.phone?.input}
            />
            <Input
              data-testid="customer-name"
              label="Name"
              placeholder="Enter Customer Name"
              {...input?.name?.input}
            />
            <div>
              <span className="sidebar__label">{'Created at'}</span>
              <div className={'filter-range'}>
                <DateRange
                  startDateInput={input?.createdAtGt?.value}
                  endDateInput={input?.createdAtLt?.value}
                  startDate={handleStartDate}
                  endDate={handleEndDate}
                />
              </div>
            </div>

            <div>
              <Button click_event={handleReset} data-testid="reset-button">
                Reset
              </Button>
              <Button
                data-testid="search-button"
                classes={'right secondary'}
                click_event={runSearchQuery}
              >
                Search
              </Button>
            </div>
          </form>
        </section>
      </SideBar>

      <main className={'layout-main'}>
        <h5>Customer Summary</h5>

        <Toast
          title="Customers"
          content={input?.content?.value}
          classes={input?.status?.value}
          active={input?.showToast?.value}
          clickEvent={handleHideToast}
        />

        <CustomerSummaryProvider
          render={({ customerSummaryData }) => (
            <CustomerSummary title="Active Customers" classes={'three-grid'}>
              <div>
                <h5>Young Adults (18 - 24)</h5>
                <h3>
                  {customerSummaryData
                    ? customerSummaryData?.customerSummary?.youngAdults
                    : 0}
                </h3>
              </div>
              <div className="mid">
                <h5>Middle Aged (25 - 35)</h5>
                <h3>
                  {customerSummaryData
                    ? customerSummaryData?.customerSummary?.middleAged
                    : 0}
                </h3>
              </div>
              <div className="right">
                <h5>Aged (35 - 55)</h5>
                <h3>
                  {customerSummaryData
                    ? customerSummaryData?.customerSummary?.aged
                    : 0}
                </h3>
              </div>
            </CustomerSummary>
          )}
        />

        <div className="customers">
          <h5>All Customers</h5>

          <Button classes="right" click_event={handleExportToCsv}>
            <Export />
            <span className="export-csv">Export CSV</span>
          </Button>
        </div>

        {data?.views?.customers?.nodes?.length > 0 ? (
          <ErrorBoundary
            fallback={
              <DataCard noPadding={false}>
                <EmptyScreen message="Error Fetching Customers" />
              </DataCard>
            }
          >
            <Fragment>
              <CustomerTableProvider
                setSearchParams={setSearchParams}
                render={({
                  isLoading,
                  handlePagination,
                  customerTableData,
                }) => (
                  <section>
                    <DataCard noPadding={false}>
                      <Table>
                        <div className="table__head">
                          {headers.map((header, key) => (
                            <span key={key}>{header}</span>
                          ))}
                        </div>
                        <div className="table__body">
                          {customerTableData?.views?.customers?.nodes.map(
                            (customer, key) => (
                              <ul key={key}>
                                <Link to={`/customers/${customer.customerId}`}>
                                  <li>
                                    <span>
                                      <div className="first-td">
                                        {reduceStringLength(
                                          customer.customerId,
                                          12,
                                        )}
                                      </div>{' '}
                                    </span>
                                    <span>
                                      {customer?.fullName
                                        ? `${customer?.fullName}`
                                        : 'N/A'}
                                    </span>
                                    <span>{customer?.phoneNumber}</span>
                                    <span>{customer?.contactEmail}</span>
                                    <span>
                                      {moment(customer?.createdAt).format(
                                        'LLL',
                                      )}
                                    </span>
                                  </li>
                                </Link>
                              </ul>
                            ),
                          )}
                        </div>
                      </Table>
                    </DataCard>

                    {customerTableData?.views?.customers?.pageInfo
                      ?.hasNextPage &&
                      (isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          classes={'secondary center activate-policy'}
                          click_event={handlePagination}
                        >
                          Load more
                        </Button>
                      ))}
                  </section>
                )}
              />
            </Fragment>
          </ErrorBoundary>
        ) : (
          <DataCard noPadding={true}>
            <EmptyScreen message="No Customers Yet" />
          </DataCard>
        )}
      </main>
    </Fragment>
  );
};

const CustomerWrapper = () => (
  <CustomersProvider
    render={({
      data,
      error,
      handleHideToast,
      input,
      handleExportToCsv,
      handleReset,
      runSearchQuery,
      handleStartDate,
      handleEndDate,
      setSearchParams,
    }) => {
      return (
        <Customers
          data={data}
          error={error}
          handleHideToast={handleHideToast}
          input={input}
          handleExportToCsv={handleExportToCsv}
          handleReset={handleReset}
          runSearchQuery={runSearchQuery}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          setSearchParams={setSearchParams}
        />
      );
    }}
  />
);

export default CustomerWrapper;
