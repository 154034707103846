import React, { Fragment } from 'react';
import {
  Button,
  DataCard,
  Input,
  Popup,
  Select,
  Table,
  Toast,
  DropDown,
} from '../../../../components/index';
import { NewCaretDown } from '../../../../components/Icons/index';
import { nairaAmount } from '../../../../lib/Utils';
import {
  MakeManualRepayment,
  OriginateClient,
} from '../../../../Providers/index';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const MakeManualRepaymentPopup = ({ portfolioData, closeOutLoanToggle }) => {
  return (
    <Fragment>
      <div className="manual-approval">
        <MakeManualRepayment
          portfolio={portfolioData.portfolio}
          render={({
            handleHideToast,
            input,
            handleMakeManualRepayment,
            showManualRepaymentPopup,
            hideManualRepaymentPopup,
            banks,
            isNumber,
            loading,
          }) => (
            <Fragment>
              <Popup
                title={'Make Cash Repayment'}
                classes="cash-repayment"
                active={input.showPopup.value}
              >
                <DataCard noPadding={true}>
                  <form onSubmit={handleMakeManualRepayment}>
                    <div className="cash-repayment-table">
                      <Table>
                        <div className="table__body">
                          <ul>
                            <li>
                              <span>Customer Name</span>
                              <span className="right-text">
                                {`${portfolioData.portfolio.user.firstName} ${portfolioData.portfolio.user.lastName}`}
                              </span>
                            </li>
                            <li>
                              <span>Email Address</span>
                              <span className="right-text">
                                {portfolioData.portfolio.user.email}
                              </span>
                            </li>
                            <li>
                              <span>Loan ID</span>
                              <span className="right-text">
                                {portfolioData.portfolio.portfolioNumber}
                              </span>
                            </li>
                            <li>
                              <span>Loan Amount</span>
                              <span className="right-text">
                                {nairaAmount(portfolioData.portfolio.amount)}
                              </span>
                            </li>
                            <li>
                              <span>Interest</span>
                              <span className="right-text">
                                {nairaAmount(
                                  portfolioData.portfolio.chargesAmount,
                                )}
                              </span>
                            </li>
                            <li>
                              <span>Total Amount</span>
                              <span className="right-text">
                                {nairaAmount(
                                  portfolioData.portfolio.fullAmount,
                                )}
                              </span>
                            </li>
                            <li>
                              <span>Paid Amount</span>
                              <span className="right-text">
                                {nairaAmount(
                                  portfolioData.portfolio.amountPaid,
                                )}
                              </span>
                            </li>
                            <li>
                              <span>Outstanding Amount</span>
                              <span className="right-text">
                                {nairaAmount(
                                  portfolioData.portfolio.fullAmount -
                                    portfolioData.portfolio.amountPaid,
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Table>
                    </div>

                    <div className="manual-approval__amount">
                      <label htmlFor="payment-amount" className="input_label">
                        Payment Amount
                      </label>
                      <NumberFormat
                        id="payment-amount"
                        thousandSeparator={true}
                        allowLeadingZeros={false}
                        className="input"
                        placeholder="Amount"
                        {...input.amount.input}
                        allowNegative={false}
                      />
                      {portfolioData?.portfolio?.transactions?.nodes[0] && (
                        <li
                          style={{
                            listStyle: 'none',
                          }}
                        >
                          <span>Last Repayment: </span>
                          <span className="">
                            {nairaAmount(
                              portfolioData?.portfolio?.transactions?.nodes[0]
                                ?.amount,
                            )}{' '}
                          </span>
                          <span className="">
                            on{' '}
                            {moment(
                              portfolioData?.portfolio?.transactions?.nodes[0]
                                ?.createdAt,
                            ).format('ddd, LLL')}{' '}
                          </span>
                        </li>
                      )}
                    </div>
                    <Select label={'Receiving Bank'} {...input.bankId.input}>
                      <option disabled value="">
                        -- Select a bank --
                      </option>
                      {banks &&
                        banks.map((bank, index) => (
                          <option key={index} value={bank.id}>
                            {bank.name}
                          </option>
                        ))}
                    </Select>
                    <Input
                      type="tel"
                      classes="account-number"
                      label="Account Number:"
                      placeholder="Account Number"
                      maxLength={10}
                      minLength={10}
                      onKeyPress={isNumber}
                      {...input.accountNumber.input}
                    />
                    <Input
                      type="text"
                      label="Reference"
                      placeholder="Reference"
                      {...input.reference.input}
                    />

                    <div className="button-list center-text">
                      <Button classes="secondary" type="submit">
                        Make Cash Repayment
                      </Button>
                      <Button
                        type="submit"
                        disabled={loading}
                        click_event={hideManualRepaymentPopup}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </form>
                </DataCard>
              </Popup>
              <Toast
                title="Make Cash Repayment"
                content={input.content.value}
                classes={input.status.value}
                active={input.showToast.value}
                clickEvent={handleHideToast}
              />
              <OriginateClient
                render={({
                  clientId: {
                    clientInfo: { loanAndApplicationServiceEnabled },
                  },
                }) => (
                  <>
                    {loanAndApplicationServiceEnabled &&
                      portfolioData.portfolio &&
                      portfolioData.portfolio.status.name !==
                        'PENDING_MANUAL_DISBURSEMENT' &&
                      portfolioData.portfolio.status.name !== 'CLOSED' &&
                      portfolioData.portfolio.status.name !==
                        'PENDING_DISBURSEMENT' &&
                      portfolioData.portfolio.status.name !== 'DISBURSING' && (
                        <div className="perform-action">
                          <DropDown
                            dropdownTrigger={
                              <div className="perform-action__name">
                                <span>Perform Action </span>

                                <span className="icon">
                                  <NewCaretDown />
                                </span>
                              </div>
                            }
                            dropdownContent={
                              <div className={'perform-action__dropdown'}>
                                <Button click_event={closeOutLoanToggle}>
                                  Close out Loan
                                </Button>
                              </div>
                            }
                          />
                        </div>
                      )}
                  </>
                )}
              />
            </Fragment>
          )}
        />
      </div>
    </Fragment>
  );
};

export default MakeManualRepaymentPopup;
