import { gql } from 'apollo-boost';

export const ASSIGN_APPLICATION_MUTATION = gql`
  mutation MP_AssignApplicationMutation($applicationId: ID!, $assignTo: ID!) {
    assignApplicationToMerchantUser(
      input: { applicationId: $applicationId, assignTo: $assignTo }
    ) {
      success
      message
    }
  }
`;
