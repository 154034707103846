import React from 'react';

const WaitIcon = props => (
  <svg width={15} height={14} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.336 13c3.5 0 6.336-2.686 6.336-6s-2.837-6-6.336-6C3.836 1 1 3.686 1 7s2.837 6 6.336 6z"
      stroke="#07F"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.334 4.6V7"
      stroke="#07F"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WaitIcon;
