import React, { Fragment, useEffect, useState } from 'react';
import {
  Close,
  DashedPlus,
  DeleteIcon,
  Document,
  SuccessIcon,
  UploadIcon,
} from '../../../../components/Icons';
import { Button, Loader, Modal, MoreOptions } from '../../../../components';
import {
  UploadSupportingDocument,
  UrlFromS3Provider,
} from '../../../../Providers';
import {
  insertSpaceBeforeTwoConsecutiveCapitalLetters,
  removeUnderscoreAndCapitalizeInitialLetters,
} from '../../../../lib/Utils';
import { useDeleteSupportingDocument } from '../../../../Providers-new/LoanModification/useDeleteSupportingDocument';

const SupportingDocumentsTab = ({
  data,
  fileName,
  setFileName,
  updateApplicationSupportingDocument,
  hasApplicationMeta,
  handleModalReviewDocToggle,
  handleModalToggle,
  applicationMetaKey,
}) => {
  const {
    handleDeleteSupportingDocument,
    data: handleDeleteSupportingDocumentData,
    loading: handleDeleteSupportingDocumentLoading,
  } = useDeleteSupportingDocument();

  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModal(prevState => !prevState);
  };

  const deleteDocument = file => {
    const { bucket, key } = file;

    handleDeleteSupportingDocument({
      key: key,
      bucket: bucket,
      application: data.application,
    });
  };

  useEffect(() => {
    const { success } =
      handleDeleteSupportingDocumentData?.deleteApplicationDocument || {};
    if (success) {
      history.go(0);
      toggleDeleteModal();
    }
  }, [handleDeleteSupportingDocumentData]);

  return (
    <Fragment>
      {handleDeleteSupportingDocumentLoading && (
        <Loader loadingText="loading..." />
      )}

      <div className="supporting-doc">
        <div className={'supporting-doc__wrapper wrap'}>
          {data.application.status.name !== 'DENIED' ? (
            <UploadSupportingDocument
              userId={data.application.user.id}
              user={data.application.user}
              applicationId={data.application.id}
              applicationNumber={data.application.applicationNumber}
              fileName={fileName}
              setFileName={setFileName}
              updateApplicationSupportingDocument={
                updateApplicationSupportingDocument
              }
              render={({
                data,
                handleUpload,
                handlePreview,
                documentDetails,
                uploadDocumentLoading,
                uploadDocumentError,
                uploadDocumentSuccess,
                isLoading,
                handleUploadPopup,
                hideUploadPopup,
                handleSuccessClose,
                ext,
              }) => (
                <>
                  {uploadDocumentLoading ? (
                    <Loader loadingText="loading..." />
                  ) : (
                    ''
                  )}{' '}
                  {uploadDocumentSuccess ? (
                    <>
                      {isLoading ? <Loader loadingText="loading..." /> : ''}
                      <Modal
                        className="close-icon"
                        active={data.showSuccessPopup.value}
                        close={true}
                      >
                        <Close
                          className="close-icon"
                          onClick={handleSuccessClose}
                        />
                        <div className="icon-style enlarge-success">
                          <SuccessIcon />
                        </div>
                        <div className="success-text-wrap">
                          {uploadDocumentSuccess}
                          <div className="success-text">
                            <ul>
                              {' '}
                              <li>{`- ${fileName}.${ext}`}</li>{' '}
                            </ul>
                          </div>
                        </div>
                      </Modal>
                    </>
                  ) : (
                    ''
                  )}
                  <label
                    className="supporting-doc__card custom flexCenter"
                    style={{ cursor: 'pointer' }}
                    onClick={handleUploadPopup}
                  >
                    <div className="icon-style top">
                      <UploadIcon />
                    </div>
                    <div className={'new-doc'}>Upload Document</div>
                  </label>
                  <Modal
                    title={'Upload Document'}
                    className="close-icon text"
                    active={data.showUploadPopup.value}
                  >
                    <Close
                      className="close-icon"
                      onClick={() => {
                        hideUploadPopup();
                        setFileName('');
                      }}
                    />
                    {uploadDocumentError ? uploadDocumentError : ''}
                    <div className="upload-document-wrapper">
                      <input
                        name="Supporting Document"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={handlePreview}
                      />
                      <div className="icon-style top enlarge">
                        <UploadIcon />
                      </div>
                      <div className="upload-text">
                        click to upload document
                      </div>

                      {documentDetails.url && (
                        <section>
                          <img
                            src={documentDetails.url}
                            alt=""
                            style={{ objectFit: 'contain' }}
                          />
                        </section>
                      )}
                    </div>
                    <input
                      type="text"
                      placeholder="Document Name"
                      value={fileName}
                      onChange={e => setFileName(e.target.value)}
                    />
                    <div className="#c53030#c53030upload-button">
                      <Button
                        classes="secondary span"
                        click_event={handleUpload}
                      >
                        Upload
                      </Button>
                    </div>
                  </Modal>
                </>
              )}
            />
          ) : (
            ''
          )}

          {hasApplicationMeta &&
            applicationMetaKey
              .filter(item => item.document != null)
              .map(({ document, key }) => {
                return document != null ? (
                  <Fragment>
                    <Modal
                      title="Are you sure you want to delete this document?"
                      classes="repayment-date-modal"
                      active={deleteModal}
                    >
                      <Close
                        className="close-icon"
                        onClick={toggleDeleteModal}
                      />

                      <div
                        className="repayment-double-buttons"
                        style={{ marginTop: '3rem' }}
                      >
                        <Button
                          classes="cancel"
                          click_event={toggleDeleteModal}
                        >
                          Cancel
                        </Button>

                        <Button
                          classes="secondary blue save"
                          type="button"
                          onClick={() => deleteDocument(document.file)}
                        >
                          Yes, Delete
                        </Button>
                      </div>
                    </Modal>

                    <UrlFromS3Provider file={document.file}>
                      {({ loading, exportFromS3 }) => (
                        <div className="supporting-doc__card">
                          {document?.uploadType && (
                            <button
                              className={'delete-file'}
                              onClick={toggleDeleteModal}
                              disabled={loading}
                            >
                              <DeleteIcon color="#c53030" />
                            </button>
                          )}

                          <div className={'icon'}>
                            <Document />
                          </div>
                          <div className={'title'}>
                            {insertSpaceBeforeTwoConsecutiveCapitalLetters(key)}
                          </div>
                          <div className={'desc'}>
                            <button
                              className={'viewFile'}
                              onClick={exportFromS3}
                              disabled={loading}
                            >
                              View File
                            </button>
                          </div>
                        </div>
                      )}
                    </UrlFromS3Provider>
                  </Fragment>
                ) : (
                  ''
                );
              })}

          {data &&
            data.application &&
            data.application.reviewFeedbacks &&
            data.application.reviewFeedbacks.map(item => {
              const document = item.documentRequests;
              const status = item.status === 'COMPLETED';
              return document.map(item => {
                return status && item.document ? (
                  <div className="supporting-doc__card">
                    {data.application.status.name !== 'APPROVED' &&
                    data.application.status.name !== 'DENIED' &&
                    data.application.status.name !== 'OFFER_LETTER_SENT' ? (
                      <MoreOptions>
                        <div
                          onClick={e => handleModalReviewDocToggle(e, item.id)}
                        >
                          Request Change
                        </div>
                      </MoreOptions>
                    ) : (
                      ''
                    )}
                    <div className={'icon'}>
                      <Document />
                    </div>
                    <UrlFromS3Provider file={item.document.file}>
                      {({ exportFromS3, loading }) => (
                        <Fragment>
                          <div className={'title'}>
                            {removeUnderscoreAndCapitalizeInitialLetters(
                              item.document.documentName,
                            )}
                          </div>
                          <div className={'desc'}>
                            <button
                              className={'viewFile'}
                              onClick={exportFromS3}
                              disabled={loading}
                            >
                              {item.document.fileUrl ? 'View File ' : 'Pending'}
                            </button>
                          </div>
                        </Fragment>
                      )}
                    </UrlFromS3Provider>
                  </div>
                ) : (
                  <div className="flexStart supporting-doc__card">
                    <div className={'icon'}>
                      <Document />
                    </div>
                    <div className={'title'}>{item.title}</div>
                    <div className={'desc center-text'}>Pending</div>
                  </div>
                );
              });
            })}

          {data.application.status.name !== 'APPROVED' &&
            data.application.status.name !== 'DENIED' &&
            data.application.status.name !== 'AWAITING_FEEDBACK' &&
            data.application.status.name !== 'OFFER_LETTER_SENT' && (
              <div
                onClick={handleModalToggle}
                className={'supporting-doc__card custom flexCenter'}
              >
                <DashedPlus />
                <div className={'new-doc'}>Request for New Document</div>
              </div>
            )}
        </div>
      </div>
    </Fragment>
  );
};

export default SupportingDocumentsTab;
