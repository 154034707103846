import React from 'react';
import { Mutation } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { TRIGGER_PASSWORD_RESET_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError, validateEmail } from '../lib/Utils';
import { Loader } from '../components';
import OriginateClient from './OriginateClient';

const TriggerResetPassword = ({ render, history }) => {
  const triggerFormInput = {
    email: useCustomState(),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  const handleTriggerResetPwd = (e, triggerMutation, clientId) => {
    e.preventDefault();

    if (
      !triggerFormInput.email.value ||
      !validateEmail(triggerFormInput.email.value)
    ) {
      triggerFormInput.showToast.setValue(true);
      triggerFormInput.status.setValue('pending');
      triggerFormInput.content.setValue('Please enter a valid email');
    }

    triggerMutation({
      variables: {
        clientId: clientId.clientInfo.clientId,
        asClient: true,
        email: triggerFormInput.email.value,
      },
    });
  };

  const handleHideToast = e => {
    e.preventDefault();

    triggerFormInput.showToast.setValue(false);
  };

  return (
    <OriginateClient
      render={({ clientId }) => (
        <Mutation
          mutation={TRIGGER_PASSWORD_RESET_MUTATION}
          onCompleted={({ triggerPasswordReset: { ok } }) => {
            if (ok) history.push('/reset-password-confirmation');
          }}
          onError={error => {
            triggerFormInput.showToast.setValue(true);
            triggerFormInput.status.setValue('Failed');
            triggerFormInput.content.setValue(trimGraphQLError(error.message));
          }}
        >
          {(triggerMutation, { loading }) => {
            if (loading) {
              return <Loader />;
            }

            return render({
              onSubmit: e =>
                handleTriggerResetPwd(e, triggerMutation, clientId),
              input: triggerFormInput,
              handleHideToast,
            });
          }}
        </Mutation>
      )}
    />
  );
};

export default withRouter(TriggerResetPassword);
