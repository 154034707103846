import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import { BreadCrumbSection, Loader } from '../../components';
import ReportsNavbar from './components/ReportsNavbar/ReportsNavbar';
import './_Reports.scss';

const GET_LOANS_REPORT_URL = gql`
  query MP_GetLoansReportUrl {
    getLoansReportUrl {
      iframeUrl
      dashboard
    }
  }
`;

const MetabaseLoansReport = () => {
  const { data: loansReportData, loading: loadingLoansReport } =
    useQuery(GET_LOANS_REPORT_URL);

  return (
    <Fragment>
      <BreadCrumbSection />
      <ReportsNavbar />

      {loadingLoansReport && <Loader />}

      <div className="metabase-wrapper">
        {loansReportData && (
          <iframe
            className="iframe"
            src={`${loansReportData?.getLoansReportUrl?.iframeUrl}#bordered=false&titled=true`}
            frameBorder="0"
            width="100%"
            height="600px"
          />
        )}
      </div>
    </Fragment>
  );
};

export default MetabaseLoansReport;
