import React from 'react';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import OriginateClient from '../../Providers/OriginateClient';

const FaviconWrapper = () => (
  <OriginateClient
    render={({ clientId }) => {
      return <Favicons clientId={clientId} />;
    }}
  />
);

const Favicons = props => {
  const {
    clientId: {
      clientInfo: { faviconUrl },
    },
  } = props;

  return (
    <Helmet>
      <link
        rel="icon"
        id="favicon"
        size="16x16"
        type="image/png"
        href={faviconUrl}
      />
    </Helmet>
  );
};

export default withRouter(FaviconWrapper);
