import { gql } from 'apollo-boost';

export const fragments = {
  authPayload: gql`
    fragment PayloadDetails on AuthPayload {
      token
    }
  `,
  authPayloadUser: gql`
    fragment PayloadUserDetails on User {
      id
      firstName
      lastName
      email
      phone
      status
    }
  `,
  role: gql`
    fragment RoleDetails on Role {
      id
      name
      description
      isCustom
    }
  `,
};

export const UPDATE_CUSTOM_APPROVAL_WORKFLOW = gql`
  mutation MP_UpdateCustomApprovalWorkflow(
    $input: UpdateApprovalWorkflowInput!
  ) {
    customApprovalWorkflowUpdate(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_DEFAULT_APPROVAL_WORKFLOW = gql`
  mutation MP_UpdateDefaultApprovalWorkflow(
    $input: UpdateDefaultApprovalWorkflow
  ) {
    updateDefaultApprovalWorkflow(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_CUSTOM_APPROVAL_WORKFLOW = gql`
  mutation MP_DeleteCustomApprovalWorkflow(
    $input: MutateApprovalWorkflowInput!
  ) {
    customApprovalWorkflowDelete(input: $input) {
      id
    }
  }
`;

export const CREATE_CUSTOM_APPROVAL_WORKFLOW = gql`
  mutation MP_CreateCustomApprovalWorkflow(
    $input: CreateApprovalWorkflowInput!
  ) {
    customApprovalWorkflowCreate(input: $input) {
      id
      name
      description
    }
  }
`;

export const GET_APPROVAL_WORKFLOWS = gql`
  query MP_GetApprovalWorkflows {
    approvalWorkflows {
      nodes {
        id
        description
        name
        title
        status
        isCustom
        steps {
          id
          approvalType
          orderNo
          roles {
            id
            name
            description
            isCustom
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const GET_APPROVAL_WORKFLOWS_DROPDOWN = gql`
  query MP_GetApprovalWorkflows {
    approvalWorkflows {
      nodes {
        id
        title
      }
    }
  }
`;

export const GET_AVAILABLE_ROLES = gql`
  query MP_GetAvailableRoles {
    availableRoles {
      ...RoleDetails
    }
  }

  ${fragments.role}
`;

export const CLIENT_ACCOUNT_USER_QUERY = gql`
  query MP_ClientAccountUserQuery(
    $first: ConnectionLimitInt
    $cursor: ConnectionCursor
    $where: ClientAccountUsersWhereInput!
  ) {
    clientAccountUsers: clientAccountUsersXn(
      first: $first
      after: $cursor
      orderBy: createdAt_DESC
      where: $where
    ) @connection(key: "clientAccountUsers") {
      nodes {
        id
        user {
          ...PayloadUserDetails
        }
        status
        createdAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }

  ${fragments.authPayloadUser}
`;
