import { useMutation } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CREDIT_POLICIES_QUERY } from '../../graphql/queries';
import { useFormInput, useKeyPair } from '../Form';
import { POLICY_CREATE_MUTATION, POLICY_UPDATE_MUTATION } from './queries';
import { trimGraphQLError } from '../../lib/Utils';
import { toast } from 'react-toastify';
import Constants from '../../lib/Constants';

const useMutatePolicyCreate = ({
  productName,
  productId,
  policyList,
  formData: updatedFormData,
}) => {
  let productInView;

  const data = {
    showToast: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
    showNotificationPopup: useKeyPair(false),
    isEditMode: useKeyPair(!!productName),
  };

  const inputs = {
    startDate: useFormInput(''),
    endDate: useFormInput(''),
    lateFeeValue: useFormInput(),
    processingFeeFixed: useFormInput(''),
    processingFeePercent: useFormInput(),
    loanProcessCheck: useFormInput(''),
    gradDurationType: useFormInput('', { type: 'select' }),
    lateFeeCalcRate: useFormInput('', { type: 'select' }),
  };

  const history = useHistory();

  const [processingFeeCheck, updateProcessingFeeCheck] = useState({ vat: '' });
  const [optInForLateFees, setOptInForLateFees] = useState(false);

  const [loanTypeValue, loanTypeSetValue] = useState('');

  const handleLoanType = e => {
    loanTypeSetValue(e.target.value);
  };

  let handleProcessingFeeCheck = value => {
    updateProcessingFeeCheck({ vat: value });
  };

  const [validityPeriod, updateValidityPeriod] = useState({ valPeriod: '' });

  const handleValidityPeriod = value => {
    updateValidityPeriod({ valPeriod: value });
  };

  if (
    processingFeeCheck.vat.value === 'FIXED' &&
    document.getElementById('vatPercent')
  ) {
    document.getElementById('vatPercent').setAttribute('disabled', 'disabled');
    document.getElementById('vatFixed').removeAttribute('disabled');
  }

  if (
    processingFeeCheck.vat.value === 'PERCENTAGE' &&
    document.getElementById('vatFixed')
  ) {
    document.getElementById('vatFixed').setAttribute('disabled', 'disabled');
    document.getElementById('vatPercent').removeAttribute('disabled');
  }

  const handleStartDate = e => {
    inputs.startDate.setValue(e);
  };

  const handleEndDate = e => {
    inputs.endDate.setValue(e);
  };

  const blankStep = {
    amount: '',
    interestRate: '',
    repaymentCount: '',
    maxLoanDuration: '',
    durationType: '',
  };

  const [cycleState, setCycleState] = useState([{ ...blankStep }]);

  const addNewStep = () => {
    setCycleState([...cycleState, { ...blankStep }]);
  };

  const removeStep = id => {
    const newCycleState = cycleState.filter((item, idx) => idx !== id);
    setCycleState([...newCycleState]);
  };

  const handleCycleChange = e => {
    const updatedCycles = [...cycleState];

    updatedCycles[e.target.dataset.idx][e.target.getAttribute('data-set')] =
      e.target.value;

    setCycleState(updatedCycles);
  };

  useEffect(() => {
    if (productName) {
      productInView = policyList.find(item => item.name === productName);
      let blankStep = [];
      setOptInForLateFees(
        !!(
          productInView.lateFeeCalculation &&
          productInView.lateFeeCalculation.lateFeeChargeOn
        ),
      );

      loanTypeSetValue(productInView.loanType);
      productInView.lateFeeCalculation &&
        inputs.lateFeeCalcRate.setValue(
          productInView.lateFeeCalculation.rate.calcBy,
        );
      productInView.lateFeeCalculation &&
        inputs.lateFeeValue.setValue(
          productInView.lateFeeCalculation.rate.value,
        );
      inputs.gradDurationType.setValue(productInView.durationType);

      productInView.processingFee &&
      productInView.processingFee.calcBy === 'FIXED'
        ? inputs.processingFeeFixed.setValue(productInView.processingFee.value)
        : inputs.processingFeePercent.setValue(
            productInView.processingFee.value,
          );
      inputs.startDate.setValue(
        productInView.startDate === null
          ? productInView.startDate
          : new Date(productInView.startDate),
      );
      inputs.endDate.setValue(
        productInView.endDate === null
          ? productInView.endDate
          : new Date(productInView.endDate),
      );
      updatedFormData.name = productInView.name;
      updatedFormData.durationType = productInView.durationType;
      updatedFormData.minLoanAmount = productInView.minLoanAmount;
      updatedFormData.maxLoanAmount = productInView.maxLoanAmount;
      updatedFormData.minLoanDuration = productInView.minLoanDuration;
      updatedFormData.maxLoanDuration = productInView.maxLoanDuration;
      updatedFormData.allowEarlyRepayment = productInView.allowEarlyRepayment;
      updatedFormData.approvalWorkflowId = productInView.approvalWorkflow
        ? productInView.approvalWorkflow.id
        : '';
      updatedFormData.ruleSetIds =
        productInView.baseRuleSet[0] && productInView.baseRuleSet[0].id;
      updatedFormData.interestRate.value =
        productInView.interestRate && productInView.interestRate.value;
      updatedFormData.repaymentType = productInView.repaymentType;
      updatedFormData.lateFeeCalculation.lateFeeChargeOn =
        productInView.lateFeeCalculation &&
        productInView.lateFeeCalculation.lateFeeChargeOn;
      updatedFormData.lateFeeCalculation.penaltyDelay =
        productInView.lateFeeCalculation &&
        productInView.lateFeeCalculation.penaltyDelay;
      updatedFormData.lateFeeCalculation.lateFeeFrequency =
        productInView.lateFeeCalculation &&
        productInView.lateFeeCalculation.lateFeeFrequency;
      updatedFormData.interestCalculationMethod =
        productInView.interestCalculationMethod
          ? productInView.interestCalculationMethod.name
          : '';
      updatedFormData.requiresDebitMandate = productInView.requiresDebitMandate;
      updateProcessingFeeCheck({
        vat: { value: productInView.processingFee.calcBy },
      });
      updateValidityPeriod({
        valPeriod: {
          value:
            !productInView.startDate && !productInView.endDate ? 'no' : 'yes',
        },
      });
      updatedFormData.maxLateFeeDuration = productInView.maxLateFeeDuration;
      updatedFormData.offerLetterEnabled = productInView.offerLetterEnabled;
      updatedFormData.validityInDays = productInView.validityInDays;

      const workflowSteps = productInView.graduatedLoanCycles;
      // eslint-disable-next-line array-callback-return
      workflowSteps.map(item => {
        blankStep.push({
          interestRate: item.interestRate,
          amount: item.amount,
          maxLoanDuration: item.maxLoanDuration,
          repaymentCount: item.repaymentCount,
        });
      });
      setCycleState([...blankStep]);
    }
  }, [productName]);

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 5000);
  };

  const handleHideNewToast = () => {
    setTimeout(() => {
      data.showNotificationPopup.setValue(false);
    }, 5000);
  };

  const getMutationVariables = () => {
    let graduatedLoanData = [];
    cycleState.map(item =>
      graduatedLoanData.push({
        interestRate: parseFloat(item.interestRate),
        amount: parseFloat(item.amount),
        maxLoanDuration: parseInt(item.maxLoanDuration),
        repaymentCount: parseInt(item.repaymentCount),
      }),
    );

    return {
      ...updatedFormData,
      maxLoanAmount: parseFloat(updatedFormData.maxLoanAmount),
      minLoanAmount: parseFloat(updatedFormData.minLoanAmount),
      minLoanDuration: parseInt(updatedFormData.minLoanDuration),
      maxLoanDuration: parseInt(updatedFormData.maxLoanDuration),
      interestRate:
        loanTypeValue === 'REGULAR'
          ? {
              value: parseFloat(updatedFormData.interestRate.value),
              calcBy: 'PERCENTAGE',
            }
          : undefined,
      lateFeeCalculation: optInForLateFees
        ? {
            lateFeeChargeOn: updatedFormData.lateFeeCalculation.lateFeeChargeOn,
            lateFeeFrequency:
              updatedFormData.lateFeeCalculation.lateFeeFrequency,
            penaltyDelay: parseInt(
              updatedFormData.lateFeeCalculation.penaltyDelay,
            ),
            rate: {
              calcBy: inputs.lateFeeCalcRate.value,
              value: parseFloat(inputs.lateFeeValue.value),
            },
          }
        : null,
      loanType: loanTypeValue,
      startDate:
        validityPeriod.valPeriod.value === 'yes'
          ? inputs.startDate.value
          : null,
      endDate:
        validityPeriod.valPeriod.value === 'yes' ? inputs.endDate.value : null,
      graduatedLoanCycles:
        loanTypeValue === 'REGULAR' ? undefined : graduatedLoanData,
      isCustom: loanTypeValue === 'GRADUATED' && true,
      processingFee: !(
        inputs.processingFeeFixed.value || inputs.processingFeePercent.value
      )
        ? {
            value:
              processingFeeCheck.vat.value === 'FIXED'
                ? (inputs.processingFeeFixed.value = 0)
                : (inputs.processingFeePercent.value = 0),

            calcBy: 'PERCENTAGE',
          }
        : {
            value:
              processingFeeCheck.vat.value === 'FIXED'
                ? parseFloat(inputs.processingFeeFixed.value)
                : parseFloat(inputs.processingFeePercent.value),
            calcBy: processingFeeCheck.vat.value,
          },
      ruleSetIds: !updatedFormData.ruleSetIds
        ? undefined
        : updatedFormData.ruleSetIds,
      durationType:
        loanTypeValue === 'REGULAR'
          ? updatedFormData.durationType
          : inputs.gradDurationType.value,
      maxLateFeeDuration: parseInt(updatedFormData.maxLateFeeDuration),
      allowEarlyRepayment: updatedFormData.allowEarlyRepayment,
      requiresDebitMandate: updatedFormData.requiresDebitMandate,
      offerLetterEnabled: updatedFormData.offerLetterEnabled,
      validityInDays: parseInt(updatedFormData.validityInDays),
    };
  };

  const handleCreatePolicy = async () => {
    const variables = getMutationVariables();
    if (variables.lateFeeCalculation === null) {
      delete variables.lateFeeCalculation;
    }

    if (variables) {
      await policyCreateMutation({
        variables: {
          input: {
            ...variables,
          },
        },
      });
    }
  };

  const handleUpdatePolicy = async () => {
    const variables = getMutationVariables();
    if (variables.lateFeeCalculation === null) {
      delete variables.lateFeeCalculation;
    }

    if (variables && productId) {
      await policyUpdateMutation({
        variables: {
          where: {
            id: productId,
          },
          data: {
            ...variables,
          },
        },
        refetchQueries: () => [{ query: CREDIT_POLICIES_QUERY }],
      });
    }
  };

  const [
    policyUpdateMutation,
    { loading: policyLoadingUpdate, error: updateError },
  ] = useMutation(POLICY_UPDATE_MUTATION, {
    onCompleted: () => {
      data.showToast.setValue(true);
      data.status.setValue('Success');
      data.content.setValue('Created');
      data.showNotificationPopup.setValue(true);

      handleHideNewToast();
      history.push('/loan-products');
    },
    onError: error => {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue(trimGraphQLError(error.message));

      handleHideToast();
    },
  });

  const [policyCreateMutation, { loading, error }] = useMutation(
    POLICY_CREATE_MUTATION,
    {
      onCompleted: () => {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Created');
        data.showNotificationPopup.setValue(true);

        handleHideNewToast();
        history.push('/loan-products');
      },
      onError: error => {
        data.showToast.setValue(true);
        data.status.setValue('Failed');
        data.content.setValue(trimGraphQLError(error.message));

        handleHideToast();
      },
    },
  );

  useEffect(() => {
    if (error) {
      toast.error(
        trimGraphQLError(
          error?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
  }, [error]);

  return {
    handleHideToast,
    loading,
    error,
    data,
    handleStartDate,
    handleEndDate,
    inputs,
    cycleState,
    addNewStep,
    removeStep,
    handleCycleChange,
    handleUpdatePolicy,
    handleCreatePolicy,
    handleProcessingFeeCheck,
    processingFeeCheck,
    updateProcessingFeeCheck,
    validityPeriod,
    handleValidityPeriod,
    policyLoadingUpdate,
    updateError,
    loanTypeSetValue,
    loanTypeValue,
    handleLoanType,
    setOptInForLateFees,
    optInForLateFees,
  };
};

export { useMutatePolicyCreate };
