import { useMutation } from '@apollo/react-hooks';
import { ENABLE_LOAN_CATEGORY, GET_LOAN_CATEGORIES } from './queries';
import { useKeyPair } from '../Form';
import { useCustomState } from '../../lib/CustomHooks';
import { trimGraphQLError } from '../../lib/Utils';

const useMutateEnableLoanCategory = () => {
  const enableLoanCategoryData = {
    showToast: useKeyPair(false),
    showEnableLoanGroupPopup: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
    selectedLoanGroupData: useCustomState({}),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      enableLoanCategoryData.showToast.setValue(false);
    }, 5000);
  };

  const handleHideEnableLoanGroupToggle = async (e, data = {}) => {
    enableLoanCategoryData.showEnableLoanGroupPopup.setValue(
      !enableLoanCategoryData.showEnableLoanGroupPopup.value,
    );
    enableLoanCategoryData.selectedLoanGroupData.setValue(data);
  };

  const [enableLoanCategory, { loading: enableLoanCategoryLoading }] =
    useMutation(ENABLE_LOAN_CATEGORY, {
      onCompleted: () => {
        enableLoanCategoryData.showToast.setValue(true);
        enableLoanCategoryData.status.setValue('Success');
        enableLoanCategoryData.content.setValue('Loan Category Enabled');
        enableLoanCategoryData.showEnableLoanGroupPopup.setValue(false);

        handleHideToast();
      },
      onError: error => {
        enableLoanCategoryData.showToast.setValue(true);
        enableLoanCategoryData.status.setValue('Failed');
        enableLoanCategoryData.content.setValue(
          trimGraphQLError(error.message),
        );

        handleHideToast();
        handleHideEnableLoanGroupToggle();
      },
    });

  const handleEnableLoanGroup = async (e, categoryData = {}) => {
    e.preventDefault();

    let products = [];
    categoryData &&
      categoryData.products.map(item => products.push({ id: item.id }));

    if (!(categoryData && categoryData.id)) {
      enableLoanCategoryData.showToast.setValue(true);
      handleHideToast();
      return;
    }

    await enableLoanCategory({
      variables: {
        input: {
          loanCategoryId: categoryData.id,
        },
      },
      refetchQueries: () => [
        {
          query: GET_LOAN_CATEGORIES,
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return {
    handleLoanGroupEnableToast: handleHideToast,
    enableLoanCategoryLoading,
    enableLoanCategoryData,
    handleHideEnableLoanGroupToggle,
    handleEnableLoanGroup: (e, categoryData) =>
      handleEnableLoanGroup(e, categoryData),
  };
};

export { useMutateEnableLoanCategory };
