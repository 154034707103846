import React, { Fragment } from 'react';
import { Button, DataCard, Export, Table } from '../../../../components';
import { roundDoublePrecisionWithCommas } from '../../../../lib/Utils';

const BankStatementAnalysisTab = ({
  handleExportToCsvBankStatementAnalysis,
  flat,
}) => {
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Button
            classes="right"
            click_event={handleExportToCsvBankStatementAnalysis}
          >
            <Export />
            <span className="export-csv">Export CSV</span>
          </Button>
          <Table>
            <div className="table__body">
              <ul>
                <li>
                  <span>Average DTI</span>
                  <span className="right-text">
                    {flat.AverageDTI || flat.averageDTI}
                  </span>
                </li>
                <li>
                  <span>Average Other Income</span>
                  <span className="right-text">
                    {roundDoublePrecisionWithCommas(flat.AverageOtherIncome) ||
                      roundDoublePrecisionWithCommas(flat.averageOtherIncome)}
                  </span>
                </li>
                <li>
                  <span>Free Income</span>
                  <span className="right-text">
                    {roundDoublePrecisionWithCommas(flat.FreeIncome) ||
                      roundDoublePrecisionWithCommas(flat.freeIncome)}
                  </span>
                </li>
                <li>
                  <span>Tenor Of Statement</span>
                  <span className="right-text">
                    {flat.TenorOfStatement || flat.tenorOfStatement}
                  </span>
                </li>
                <li>
                  <span>Expected Income</span>
                  <span className="right-text">
                    {roundDoublePrecisionWithCommas(flat.ExpectedIncome) ||
                      roundDoublePrecisionWithCommas(flat.expectedIncome)}
                  </span>
                </li>
              </ul>
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default BankStatementAnalysisTab;
