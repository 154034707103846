import { gql } from 'apollo-boost';

export const UPDATE_CLIENT_ACCOUNT_USER_MUTATION = gql`
  mutation MP_UpdateClientAccountUserMutation(
    $clientId: ID!
    $userId: ID!
    $email: String!
    $phone: String!
    $firstName: String
    $lastName: String
    $permissions: [PermissionName!]
    $roles: [ClientUserRoles!]
  ) {
    updateClientAccountUser(
      input: {
        clientId: $clientId
        userId: $userId
        email: $email
        phone: $phone
        firstName: $firstName
        lastName: $lastName
        permissions: $permissions
        roles: $roles
      }
    ) {
      user {
        id
        firstName
        lastName
        email
        status
      }
    }
  }
`;

export const UPDATE_CLIENT_ACCOUNT_USER_DETAILS = gql`
  mutation MP_UpdateClientAccountUserDetails(
    $input: UpdateClientAccountUserDetailsInput!
  ) {
    updateClientAccountUserDetails(input: $input) {
      token
    }
  }
`;
