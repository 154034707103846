import React, { useRef, useState } from 'react';
import Button from '../Button/Button';
import { Close } from '../Icons';
import Modal from '../Modal/Modal';

import './_UploadMerchantFile.scss';

const UploadMerchantFile = ({
  acceptedTypes,
  handleUploadAttribute,
  name,
  setType,
}) => {
  const fileInputRef = useRef(null);
  const [fileState, setFileState] = useState({
    file: null,
    fileName: '',
    fileSizeError: false,
    isPublic: false,
  });
  const [isOpen, setIsOpen] = useState(false);

  const MAX_FILE_SIZE_MB = 10;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    const fieldName = event.target.name;
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
        setFileState({
          file: null,
          fileName: '',
          fileSizeError: true,
        });
      } else {
        const typeMap = {
          favicon: 'favicon',
          logo: 'logo',
          tsAndCs: 'tsandCs',
          policy: 'policy',
        };
        setType(typeMap[fieldName]);

        const isPublic = !!typeMap[fieldName];

        setFileState({
          file: selectedFile,
          fileName: selectedFile.name,
          fileSizeError: false,
          isPublic,
        });
        setIsOpen(true);
      }
    } else {
      setFileState({
        file: null,
        fileName: '',
        fileSizeError: false,
      });
    }
  };

  const handleUpload = async e => {
    e.preventDefault();

    await handleUploadAttribute(e, fileState.file, fileState.isPublic);

    setFileState({
      file: null,
      fileName: '',
      fileSizeError: false,
      isPublic: false,
    });
    setIsOpen(false);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="file-upload-button">
      <div className="button-container">
        <button className="upload-button" onClick={handleClick}>
          Choose File
        </button>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        className="file-input"
        onChange={handleFileChange}
        accept={acceptedTypes ? acceptedTypes.join(',') : ''}
        hidden
        name={name}
      />
      {fileState.fileSizeError && (
        <div className="error-text">File size exceeds 10MB.</div>
      )}

      {fileState.file && fileState.file.size < MAX_FILE_SIZE_BYTES && (
        <Modal
          title="File Upload Confirmation"
          classes="upload-file-modal"
          active={isOpen}
        >
          <Close
            className="close-icon"
            onClick={() =>
              setFileState({
                file: null,
                fileName: '',
                fileSizeError: false,
              })
            }
            width="15"
            height="15"
          />

          <p>
            Are you sure you want to upload{' '}
            <span className="file-name">{fileState.fileName}</span>?
          </p>
          <div className="button-container">
            <Button classes="secondary" type="button" onClick={handleUpload}>
              Upload File
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UploadMerchantFile;
