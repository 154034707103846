import cx from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import {
  BreadCrumbSection,
  Button,
  CustomerDetailsSideBar,
  DataCard,
  EmptyScreen,
  Export,
  MerchantNotFound,
  Table,
} from '../../components';
import {
  capitalizeInitialLetter,
  daysOnTable,
  nairaAmount,
  reduceStringLength,
  removeUnderscore,
  splitString,
  unCamelize,
} from '../../lib/Utils';
import {
  CustomerLoanDetailsProvider,
  UrlFromS3Provider,
} from '../../Providers';

const headers = {
  portfolioHeader: [
    'Loan ID',
    'Amount (N)',
    'Duration (days)',
    'Total (N)',
    'Disbursed (N)',
    'Paid (N)',
    'Created',
  ],
  applicationHeader: [
    'Status',
    'Application ID',
    'Amount (N)',
    'Duration (days)',
    'Interest',
    'Total',
    'Created',
  ],
};

const FacilityDetails = () => (
  <CustomerLoanDetailsProvider
    render={({
      data,
      handleExportToCsvApplications,
      handleExportToCsvPortfolios,
      abbreviateApplicationStatus,
      attributes,
    }) =>
      data && attributes ? (
        <Fragment>
          <BreadCrumbSection />
          <CustomerDetailsSideBar>
            <article className="customer-info">
              <span className="cover-image" />
              {data && attributes.facilityName ? (
                <div className="avatar">
                  {`${attributes.firstName
                    .charAt(0)
                    .toUpperCase()}${attributes.lastName
                    .charAt(0)
                    .toUpperCase()}`}
                </div>
              ) : (
                <div className="avatar">...</div>
              )}
              {data && data.customer ? (
                <h3>{`${attributes.firstName} ${attributes.lastName}`}</h3>
              ) : (
                <h3>...</h3>
              )}
            </article>

            <article className="detail-section">
              <h6>Applicant Information</h6>
              {data && attributes && (
                <ul>
                  <li>
                    <span>Applicant ID</span>
                    <span>
                      {attributes.accountName ? attributes.accountName : ''}
                    </span>
                  </li>
                  <li>
                    <span>Applicant Email</span>
                    <span>{attributes.email}</span>
                  </li>
                  <li>
                    <span>Applicant Number</span>
                    <span>{attributes.phoneNumber}</span>
                  </li>
                  <li>
                    <span>Gender</span>
                    <span>{attributes.gender}</span>
                  </li>
                  <li>
                    <span>Date of Birth</span>
                    <span>{attributes.dob}</span>
                  </li>
                </ul>
              )}
            </article>

            <article className="detail-section">
              <h6>Bank Information</h6>
              {data &&
                data.customer &&
                data.customer.bankAccounts.map((bankAccount, idx) => (
                  <ul key={idx}>
                    <li>
                      <span>Account Name</span>
                      <span>{bankAccount.accountName}</span>
                    </li>
                    <li>
                      <span>Account Number</span>
                      <span>{bankAccount.accountNumber}</span>
                    </li>
                    <li>
                      <span>Bank</span>
                      <span>{bankAccount.bank.name}</span>
                    </li>
                  </ul>
                ))}
            </article>
          </CustomerDetailsSideBar>

          <main className="layout-customerdetails">
            <div className="layout-customerdetails--header">
              {data && attributes.facilityName ? (
                <h5>{`${attributes.facilityName}'s Loans`}</h5>
              ) : (
                <h5>...</h5>
              )}
            </div>

            <DataCard noPadding={true}>
              <Tabs>
                <TabList>
                  <Tab tabFor="applications" className={'tab-title'}>
                    Applications
                  </Tab>
                  <Tab tabFor="portfolios" className={'tab-title'}>
                    Loan
                  </Tab>
                  <Tab tabFor="customer-information" className={'tab-title'}>
                    Facility Information
                  </Tab>
                  <Tab tabFor="facility-expenses" className={'tab-title'}>
                    Facility Expenses
                  </Tab>
                  <Tab tabFor="applicant-information" className={'tab-title'}>
                    Applicant Information
                  </Tab>
                  <Tab tabFor="personnel-information" className={'tab-title'}>
                    Personnel Information
                  </Tab>
                  <Tab tabFor="financials-information" className={'tab-title'}>
                    Financials
                  </Tab>
                  <Tab tabFor="supporting-documents" className={'tab-title'}>
                    Supporting Documents
                  </Tab>
                </TabList>

                <TabPanel tabId="applications">
                  {data &&
                  data.customer &&
                  data.customer.applications.nodes.length > 0 ? (
                    <Fragment>
                      <Button
                        classes="right handle-export"
                        click_event={handleExportToCsvApplications}
                      >
                        <Export />
                        <span className="export-csv">Export CSV</span>
                      </Button>
                      <DataCard noPadding={false}>
                        <Table>
                          <div className="table__head">
                            {headers.applicationHeader.map((header, key) => (
                              <span
                                key={key}
                                className={cx({
                                  Status: header,
                                  'checked-width': header === 'Status',
                                })}
                              >
                                {' '}
                                {header}{' '}
                              </span>
                            ))}
                          </div>
                          <div className="table__body">
                            {data &&
                              data.customer &&
                              data.customer.applications.nodes.map(
                                (application, key) => (
                                  <ul key={key}>
                                    <Link
                                      to={`/v1/applications/${application.applicationNumber}/${application.user.email}`}
                                    >
                                      <li>
                                        <span className="checked-width">
                                          <span
                                            data-title={
                                              application.status.name ===
                                              'DISBURSED'
                                                ? 'ACTIVE'
                                                : removeUnderscore(
                                                    application.status.name,
                                                  )
                                            }
                                            className={`tooltip status fixed ${
                                              application.status.name.toLowerCase() ||
                                              ''
                                            }`}
                                          >
                                            {abbreviateApplicationStatus(
                                              application.status.name,
                                            )}
                                          </span>
                                        </span>
                                        <span>
                                          <div className="first-td">
                                            {reduceStringLength(
                                              application.applicationNumber,
                                              12,
                                            )}
                                          </div>
                                        </span>
                                        <span>
                                          {nairaAmount(application.amount)}
                                        </span>
                                        <span>
                                          {daysOnTable(
                                            application.loanDuration,
                                          )}
                                        </span>
                                        <span>
                                          {nairaAmount(
                                            application.chargesAmount,
                                          )}
                                        </span>
                                        <span>
                                          {nairaAmount(application.fullAmount)}
                                        </span>
                                        <span>
                                          {moment(application.createdAt).format(
                                            'lll',
                                          )}
                                        </span>
                                      </li>
                                    </Link>
                                  </ul>
                                ),
                              )}
                          </div>
                        </Table>
                      </DataCard>
                    </Fragment>
                  ) : (
                    <DataCard noPadding={false}>
                      <EmptyScreen
                        noPadding={true}
                        message={'No Application Details Yet'}
                      />
                    </DataCard>
                  )}
                </TabPanel>

                <TabPanel tabId="portfolios">
                  {data &&
                  data.customer &&
                  data.customer.portfolios.nodes.length > 0 ? (
                    <Fragment>
                      <Button
                        classes="right handle-export"
                        click_event={handleExportToCsvPortfolios}
                      >
                        <Export />
                        <span className="export-csv">Export CSV</span>
                      </Button>
                      <DataCard noPadding={false}>
                        <Table>
                          <div className="table__head">
                            {headers.portfolioHeader.map((header, key) => (
                              <span key={key}> {header} </span>
                            ))}
                          </div>
                          <div className="table__body">
                            {data &&
                              data.customer &&
                              data.customer.portfolios.nodes.map(
                                (portfolio, idx) => (
                                  <ul key={idx}>
                                    <Link
                                      to={`/loans/${portfolio.portfolioNumber}/${portfolio.user.email}`}
                                    >
                                      <li>
                                        <span>
                                          <div className="first-td">
                                            {reduceStringLength(
                                              portfolio.portfolioNumber,
                                              12,
                                            )}
                                          </div>
                                        </span>
                                        <span>
                                          {nairaAmount(portfolio.amount)}
                                        </span>
                                        <span>
                                          {daysOnTable(portfolio.loanDuration)}
                                        </span>
                                        <span>
                                          {nairaAmount(portfolio.fullAmount)}
                                        </span>
                                        <span>
                                          {nairaAmount(
                                            portfolio.amountDisbursed,
                                          )}
                                        </span>
                                        <span>
                                          {nairaAmount(portfolio.amountPaid)}
                                        </span>
                                        <span>
                                          {moment(portfolio.createdAt).format(
                                            'lll',
                                          )}
                                        </span>
                                      </li>
                                    </Link>
                                  </ul>
                                ),
                              )}
                          </div>
                        </Table>
                      </DataCard>
                    </Fragment>
                  ) : (
                    <DataCard noPadding={false}>
                      <EmptyScreen
                        noPadding={true}
                        message={'No Loan Details Yet'}
                      />
                    </DataCard>
                  )}
                </TabPanel>

                <TabPanel tabId="customer-information">
                  <div className="information--content">
                    {data && attributes && (
                      <Table>
                        <div className="table__body">
                          <ul>
                            <li>
                              <span> Facility Name</span>
                              <span className="right-text">
                                {attributes && attributes.facilityName
                                  ? capitalizeInitialLetter(
                                      attributes.facilityName,
                                    )
                                  : ''}
                              </span>
                            </li>
                            <li>
                              <span> RC Number</span>
                              <span className="right-text">
                                {attributes.rcNumber ? attributes.rcNumber : ''}
                              </span>
                            </li>
                            <li>
                              <span> License Number</span>
                              <span className="right-text">
                                {attributes.licenseNo
                                  ? attributes.licenseNo
                                  : ''}
                              </span>
                            </li>
                            <li>
                              <span> Helium ID</span>
                              <span className="right-text">
                                {attributes.heliumFacilityId
                                  ? attributes.heliumFacilityId
                                  : ''}
                              </span>
                            </li>
                            <li>
                              <span> Facility Level</span>
                              <span className="right-text">
                                {attributes &&
                                  attributes.facilityType &&
                                  capitalizeInitialLetter(
                                    attributes.facilityType,
                                  )}
                              </span>
                            </li>
                            <li>
                              <span> Type of Business</span>
                              <span className="right-text">
                                {attributes &&
                                  attributes.typeOfbusiness &&
                                  capitalizeInitialLetter(
                                    attributes.typeOfbusiness,
                                  )}
                              </span>
                            </li>
                            <li>
                              <span> Ownership</span>
                              <span className="right-text">
                                {attributes &&
                                  attributes.ownershipType &&
                                  capitalizeInitialLetter(
                                    attributes.ownershipType,
                                  )}
                              </span>
                            </li>
                            <li>
                              <span> Start Date</span>
                              <span className="right-text">
                                {attributes.facilityRegDate
                                  ? attributes.facilityRegDate
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Hours of Operation</span>
                              <span className="right-text">
                                {attributes.operatingHours
                                  ? attributes.operatingHours
                                  : '--'}{' '}
                                Hours
                              </span>
                            </li>
                            <li>
                              <span> No of Beds</span>
                              <span className="right-text">
                                {attributes.noOfBeds
                                  ? attributes.noOfBeds
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Email Address</span>
                              <span className="right-text">
                                {attributes.facilityContactEmail
                                  ? attributes.facilityContactEmail
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Phone Number</span>
                              <span className="right-text">
                                {attributes.facilityContactPhoneNo
                                  ? attributes.facilityContactPhoneNo
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span>Website</span>
                              <span className="right-text">
                                {attributes.website ? attributes.website : '--'}
                              </span>
                            </li>
                            <li>
                              <span> State</span>
                              <span className="right-text">
                                {attributes.state ? attributes.state : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Local Government</span>
                              <span className="right-text">
                                {attributes.lga ? attributes.lga : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Loan Request Reason</span>
                              <span className="right-text">
                                {attributes.loanRequestReason
                                  ? attributes.loanRequestReason
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Address</span>
                              <span className="right-text">
                                {attributes.facilityAddress
                                  ? attributes.facilityAddress
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Referral</span>
                              <span className="right-text">
                                {attributes.referralSource
                                  ? attributes.referralSource
                                  : '--'}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Table>
                    )}
                  </div>
                </TabPanel>

                <TabPanel tabId="facility-expenses">
                  <div className="information--content">
                    {data && attributes && (
                      <Table>
                        <div className="table__body">
                          <ul>
                            <li>
                              <span> Total Salaries</span>
                              <span className="right-text">
                                ₦
                                {attributes.totalSalaries
                                  ? attributes.totalSalaries
                                  : '0'}
                              </span>
                            </li>
                            <li>
                              <span> Electricity Cost</span>
                              <span className="right-text">
                                ₦
                                {attributes.electricityCost
                                  ? attributes.electricityCost
                                  : '0'}
                              </span>
                            </li>
                            <li>
                              <span> Fuel Cost</span>
                              <span className="right-text">
                                ₦
                                {attributes.fuelCost
                                  ? attributes.fuelCost
                                  : '0'}
                              </span>
                            </li>
                            <li>
                              <span> Transportation Cost</span>
                              <span className="right-text">
                                ₦
                                {attributes.transportationCost
                                  ? attributes.transportationCost
                                  : '0'}
                              </span>
                            </li>
                            <li>
                              <span> Internet and other Subscriptions</span>
                              <span className="right-text">
                                ₦
                                {attributes.internetCost
                                  ? attributes.internetCost
                                  : '0'}
                              </span>
                            </li>
                            <li>
                              <span> Telephone Cost</span>
                              <span className="right-text">
                                ₦
                                {attributes.telephoneCost
                                  ? attributes.telephoneCost
                                  : '0'}
                              </span>
                            </li>
                            <li>
                              <span> Service Cost</span>
                              <span className="right-text">
                                ₦
                                {attributes.serviceCost
                                  ? attributes.serviceCost
                                  : '0'}
                              </span>
                            </li>
                            <li>
                              <span> Annual Rent</span>
                              <span className="right-text">
                                ₦
                                {attributes.annualRent
                                  ? attributes.annualRent
                                  : '0'}
                              </span>
                            </li>
                            <li>
                              <span> License and Insurance</span>
                              <span className="right-text">
                                ₦
                                {attributes.licenseAndInsurance
                                  ? attributes.licenseAndInsurance
                                  : '0'}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Table>
                    )}
                  </div>
                </TabPanel>

                <TabPanel tabId="applicant-information">
                  <div className="information--content">
                    {data && attributes && (
                      <Table>
                        <div className="table__body">
                          <ul>
                            <li>
                              <span> Applicant's Name</span>
                              <span className="right-text">
                                {data.customer.user
                                  ? capitalizeInitialLetter(
                                      data.customer.user.firstName,
                                    ) +
                                    ' ' +
                                    capitalizeInitialLetter(
                                      data.customer.user.lastName,
                                    )
                                  : ''}
                              </span>
                            </li>
                            <li>
                              <span> Applicant's Role</span>
                              <span className="right-text">
                                {attributes.employmentStatus
                                  ? attributes.employmentStatus
                                  : ''}
                              </span>
                            </li>
                            <li>
                              <span>Gender</span>
                              <span className="right-text">
                                {attributes.gender ? attributes.gender : '--'}
                              </span>
                            </li>
                            {data.customer.bvn ? (
                              <>
                                <li>
                                  <span> BVN</span>
                                  <span className="right-text">
                                    {data.customer.bvn.number}
                                  </span>
                                </li>
                                <li>
                                  <span> BVN Phone Number</span>
                                  <span className="right-text">
                                    {data.customer.bvn.phone}
                                  </span>
                                </li>
                              </>
                            ) : null}
                            <li>
                              <span> Phone Number</span>
                              <span className="right-text">
                                {data.customer.user
                                  ? data.customer.user.phone
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Email</span>
                              <span className="right-text">
                                {data.customer.user
                                  ? data.customer.user.email
                                  : '--'}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Table>
                    )}
                  </div>
                </TabPanel>

                <TabPanel tabId="personnel-information">
                  <div className="information--content">
                    {data && attributes && (
                      <Table>
                        <div className="table__body">
                          <ul>
                            <li>
                              <span> No of Doctors</span>
                              <span className="right-text">
                                {attributes.noOfDoctors
                                  ? attributes.noOfDoctors
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> No of Nurses</span>
                              <span className="right-text">
                                {attributes.noOfNurses
                                  ? attributes.noOfNurses
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> No of Lab Technicians</span>
                              <span className="right-text">
                                {attributes.noOfLabTech
                                  ? attributes.noOfLabTech
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> No of midwives</span>
                              <span className="right-text">
                                {attributes.noOfMidwives
                                  ? attributes.noOfMidwives
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> No of Community Health workers</span>
                              <span className="right-text">
                                {attributes.noOfHWorkers
                                  ? attributes.noOfHWorkers
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> No of Administrators</span>
                              <span className="right-text">
                                {attributes.noOfAdmin
                                  ? attributes.noOfAdmin
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span>No of Patients Per Day</span>
                              <span className="right-text">
                                {attributes.dailyPatientsCount
                                  ? attributes.dailyPatientsCount
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span>No of Patients In The Last 30 Days</span>
                              <span className="right-text">
                                {attributes.monthlyPatientsCount
                                  ? attributes.monthlyPatientsCount
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> No of Patients In The Last 3 Months</span>
                              <span className="right-text">
                                {attributes.avg3MonthsPatients
                                  ? attributes.avg3MonthsPatients
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Total Number of Registered Patients</span>
                              <span className="right-text">
                                {attributes.registeredPatientsCount
                                  ? attributes.registeredPatientsCount
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Total Number of Unique Patients</span>
                              <span className="right-text">
                                {attributes.totalNoOfUniquePatients
                                  ? attributes.totalNoOfUniquePatients
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Total Number of staff</span>
                              <span className="right-text">
                                {attributes.totalNoOfStaff
                                  ? attributes.totalNoOfStaff
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> No of Facility Locations</span>
                              <span className="right-text">
                                {attributes.numberOfLocation
                                  ? attributes.numberOfLocation
                                  : '--'}
                              </span>
                            </li>
                            <li>
                              <span> Services Offered</span>
                              {attributes.servicesOffered &&
                              attributes.servicesOffered.length !== 0 ? (
                                <span>
                                  {attributes.servicesOffered.toString()}
                                </span>
                              ) : (
                                '--'
                              )}
                            </li>
                          </ul>
                        </div>
                      </Table>
                    )}
                  </div>
                </TabPanel>

                <TabPanel tabId="financials-information">
                  <div className="information--content">
                    {data && attributes && (
                      <Table>
                        <div className="table__body">
                          <ul>
                            <li>
                              <span> Revenue In The last 12 Months</span>
                              {attributes.last12MonthsRevenue ? (
                                <span className="right-text">
                                  ₦{attributes.last12MonthsRevenue}{' '}
                                </span>
                              ) : (
                                <span className="right-text">--</span>
                              )}
                            </li>
                            <li>
                              <span> Average Monthly Revenue</span>
                              {attributes.avgMonthlyRevenue ? (
                                <span className="right-text">
                                  ₦{attributes.avgMonthlyRevenue}{' '}
                                </span>
                              ) : (
                                <span className="right-text">--</span>
                              )}
                            </li>
                            <li>
                              <span> Do You Have An Existing Loan?</span>
                              {attributes.isExistingLoan === true ||
                              attributes.existingDebt ? (
                                <span className="right-text"> Yes </span>
                              ) : (
                                <span className="right-text">No </span>
                              )}
                            </li>
                            <li>
                              <span> How much is the Loan? </span>
                              {attributes.existingDebt ? (
                                <span className="right-text">
                                  {' '}
                                  ₦{attributes.existingDebt}{' '}
                                </span>
                              ) : (
                                <span className="right-text">--</span>
                              )}
                            </li>
                            <li>
                              <span> Are You Current On The Loan?</span>
                              {attributes.onCurrentLoan === true ||
                              attributes.monthlyRepayment ? (
                                <span className="right-text"> Yes </span>
                              ) : (
                                <span className="right-text">No </span>
                              )}
                            </li>
                            <li>
                              <span> Monthly Loan Repayment </span>
                              {attributes.monthlyRepayment ? (
                                <span className="right-text">
                                  {' '}
                                  ₦{attributes.monthlyRepayment}{' '}
                                </span>
                              ) : (
                                <span className="right-text">--</span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </Table>
                    )}
                  </div>
                </TabPanel>

                <TabPanel tabId="supporting-documents">
                  <div className="information--content">
                    <Table>
                      <div className="table__body">
                        <ul>
                          {data &&
                            data.customer &&
                            data.customer.user &&
                            data.customer.user.supportingDocument &&
                            data.customer.user.supportingDocument.map(
                              (document, key) => (
                                <li key={key}>
                                  <span>
                                    {
                                      splitString(
                                        unCamelize(document.documentName),
                                      )[0]
                                    }
                                  </span>
                                  <UrlFromS3Provider file={document.file}>
                                    {({ exportFromS3, loading }) => (
                                      <span className="right-text">
                                        <Button
                                          classes="link"
                                          click_event={exportFromS3}
                                          disabled={loading}
                                        >
                                          View
                                        </Button>
                                      </span>
                                    )}
                                  </UrlFromS3Provider>
                                </li>
                              ),
                            )}
                        </ul>
                      </div>
                    </Table>
                  </div>
                </TabPanel>
              </Tabs>
            </DataCard>
          </main>
        </Fragment>
      ) : (
        <MerchantNotFound />
      )
    }
  />
);

export default FacilityDetails;
