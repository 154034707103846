import React from 'react';
import PropTypes from 'prop-types';
import { Button, DateRange, Input, Select, SideBar } from '../../components';
import { loan_types } from '../../config/config';
import { useQuery } from '@apollo/react-hooks';
import { capitalizeInitialLetter, removeUnderscore } from '../../lib/Utils';
import { GET_STATUS_ENUM } from '../../graphql/queries/getEnum';

const Search = ({
  input,
  type,
  resetAction,
  searchAction,
  handleRepaymentStartDate,
  handleRepaymentEndDate,
  handleLoanObjStartDate,
  handleLoanObjEndDate,
  approvalWorkflowList,
  userDetails,
  policyList,
}) => {
  const { data } = useQuery(GET_STATUS_ENUM, {
    variables: {
      type1: 'PortfolioStatus',
      type2: 'ApplicationStatus',
    },
  });

  let portfolioStatuses = [];
  let applicationStatuses = [];
  if (data) {
    portfolioStatuses = data.portfolioEnum;
    applicationStatuses = data.applicationEnum;
  }

  const myTask = localStorage.getItem('email');

  return (
    <SideBar>
      <section>
        <h4 className={'sidebar-header'}>Search Parameter</h4>
        <Input
          label={`${type} ID`}
          placeholder={`Enter ${type} ID`}
          {...input.searchParams.id.input}
        />
        <Select
          {...input.searchParams.status.input}
          label="Status"
          data-testid="search-status"
        >
          <option value="">All</option>
          {type === loan_types.loan
            ? portfolioStatuses &&
              portfolioStatuses.map((status, index) => (
                <option key={index} value={status.name}>
                  {status.name === 'DISBURSED'
                    ? 'Active'
                    : removeUnderscore(capitalizeInitialLetter(status.name))}
                </option>
              ))
            : applicationStatuses &&
              applicationStatuses.map((status, index) => (
                <option key={index} value={status.name}>
                  {removeUnderscore(capitalizeInitialLetter(status.name))}
                </option>
              ))}
        </Select>
        <Input
          data-testid="search-email"
          classes={'sidebar--input'}
          label="Email"
          placeholder="Enter Email"
          {...input.searchParams.email.input}
        />
        <Input
          data-testid="search-applicantname"
          label="Applicant's Name"
          placeholder="Enter Name"
          {...input.searchParams.name.input}
        />
        {type === loan_types.application && (
          <Select {...input.searchParams.productId.input} label="Loan Product">
            <option value="">-- Select a Product --</option>
            {policyList?.policies?.nodes?.map((item, key) => (
              <option key={key} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </Select>
        )}
        {type === loan_types.application && (
          <Select
            {...input.searchParams.approvalWorkflowId.input}
            label="Approval Workflow"
          >
            <option value="">-- Select a Workflow --</option>
            {approvalWorkflowList?.map((item, key) => (
              <option key={key} value={item?.id}>
                {item?.title}
              </option>
            ))}
          </Select>
        )}
        {type === loan_types.application && (
          <Select
            {...input.searchParams.approvalWorkflowStep.input}
            label="Approval Workflow Step"
          >
            <option value="">-- Select a Workflow Step --</option>
            <option value={'REVIEW'}>Review</option>
            <option value={'APPROVE'}>Approve</option>
            <option value={'DISBURSE'}>Disburse</option>
          </Select>
        )}
        {type === loan_types.application && (
          <Select
            {...input.searchParams.assignedToId.input}
            label="Assigned To"
          >
            <option value="">-- Select a User --</option>
            {userDetails?.some(item => item?.user?.email === myTask) && (
              <option
                value={
                  userDetails.find(item => item?.user?.email === myTask)?.user
                    ?.id
                }
              >
                My Tasks
              </option>
            )}
            {userDetails?.map((item, key) => {
              if (item?.user?.email === myTask) return null;
              return (
                <option key={key} value={item?.user?.id}>
                  {item?.user?.email}
                </option>
              );
            })}
          </Select>
        )}
        <div>
          <span className="sidebar__label">
            {type === 'Application' ? 'Requested' : 'Due'} Date
          </span>
          <div className={'filter-range'}>
            <DateRange
              startDate={
                type === 'Application'
                  ? handleLoanObjStartDate
                  : handleRepaymentStartDate
              }
              endDate={
                type === 'Application'
                  ? handleLoanObjEndDate
                  : handleRepaymentEndDate
              }
              startDateInput={
                type === 'Application'
                  ? input.searchParams.applicationDateGt.value
                  : input.searchParams.repaymentDateGt.value
              }
              endDateInput={
                type === 'Application'
                  ? input.searchParams.applicationDateLt.value
                  : input.searchParams.repaymentDateLt.value
              }
            />
          </div>
        </div>
        <div>
          <Button
            classes={'reset'}
            click_event={resetAction}
            data-testid="reset-button"
          >
            Reset
          </Button>
          <Button
            classes={'secondary right'}
            click_event={searchAction}
            data-testid="search-button"
          >
            Search
          </Button>
        </div>
      </section>
    </SideBar>
  );
};

Search.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  resetAction: PropTypes.func,
  searchAction: PropTypes.func,
};

export default Search;
