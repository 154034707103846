import { graphql } from '@/__generated/gql';

export const MP_SET_APPLICATION_BANK_ACCOUNT = graphql(/* GraphQL */ `
  mutation MpSetApplicationBankAccount(
    $input: MpSetApplicationBankAccountInput
  ) {
    mpSetApplicationBankAccount(input: $input) {
      success
      message
      applicationId
      applicationNumber
    }
  }
`);

export const SET_APPLICATION_BANK_ACCOUNT = graphql(/* GraphQL */ `
  mutation SetApplicationBankAccount($input: ApplicationBankStageInput!) {
    setApplicationBankAccount(input: $input) {
      success
      application {
        applicationNumber
      }
    }
  }
`);
