// This handles what tab to be visible
export const VIEW_LOAN_TAB = [
  'view_loan',
  'approve_loan',
  'review_loan',
  'reject_loan',
  'repay_loan',
];
export const VIEW_CUSTOMERS_TAB = [
  'view_customers',
  'get_user',
  'update_user',
  'assign_loan_to_user',
  'delete_user',
];
export const VIEW_TASKS_TAB = ['view_loan'];
export const VIEW_DASHBOARD_TAB = ['view_dashboard'];
export const VIEW_LIQUIDITY_MGT_TAB = ['view_liquidity_management'];
export const VIEW_POLICY_TAB = ['create_policy', 'view_policy'];
export const VIEW_SETTINGS_TAB = ['view_settings', 'update_settings'];
export const VIEW_APPLICATIONS_REPORT = ['view_applications_report'];
export const VIEW_LOANS_REPORT = ['view_loans_report'];
export const VIEW_REPAYMENTS_REPORT = ['view_repayments_report'];
export const VIEW_ACCOUNTS_REPORT = ['view_accounts_report'];
