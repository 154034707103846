import React from 'react';
import { GET_REPAYMENTBREAKDOWN_QUERY } from '../graphql/queries';
import { Loader } from '../components';
import { useQuery } from '@apollo/react-hooks';

const RepaymentBreakDown = ({
  policyId,
  principalAmount,
  duration,
  moratoriumPeriod,
  applicationId,
}) => {
  const props = {
    principalAmount: '',
    duration: '',
    totalInterest: '',
    rateInDecimal: '',
    repaymentFrequency: '',
    repaymentBreakdown: null,
  };

  const { data, loading, error } = useQuery(GET_REPAYMENTBREAKDOWN_QUERY, {
    variables: {
      policyId: policyId ? policyId : '',
      applicationId: applicationId ? applicationId : '',
      principalAmount: principalAmount ? principalAmount : '',
      duration: duration ? duration : '',
      moratoriumPeriod: moratoriumPeriod ? moratoriumPeriod : null,
    },
  });

  props.error = error;
  props.loading = loading;

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return props;
  }

  if (!loading && !error && data) {
    const { getRepaymentBreakdown } = data;

    props.principalAmount = getRepaymentBreakdown.principalAmount;
    props.duration = getRepaymentBreakdown.duration;
    props.totalInterest = getRepaymentBreakdown.totalInterest;
    props.rateInDecimal = getRepaymentBreakdown.rateInDecimal;
    props.repaymentFrequency = getRepaymentBreakdown.repaymentFrequency;
    props.repaymentBreakdown = getRepaymentBreakdown.repaymentBreakdown;
  }

  return props;
};

export default RepaymentBreakDown;
