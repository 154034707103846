import { gql } from 'apollo-boost';

export const CHANGE_CLIENT_USER_MUTATION = gql`
  mutation MP_ChangeClientUserMutation($userId: ID!, $action: UserActions!) {
    changeClientUserStatus(input: { userId: $userId, action: $action }) {
      ok
    }
  }
`;

export const CHANGE_CLIENT_USER_ROLE_MUTATION = gql`
  mutation MP_ChangeClientUserRoleMutation($userId: ID!, $roles: [ID!]!) {
    changeClientUserRole(input: { userId: $userId, roles: $roles }) {
      ok
    }
  }
`;

export const DELETE_CLIENT_USER_MUTATION = gql`
  mutation MP_DeleteClientUserMutation($userId: ID!) {
    deleteClientUser(userId: $userId) {
      ok
    }
  }
`;
