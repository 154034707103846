import React, { useEffect, useState } from 'react';
import { Button, Close, Modal, Toast } from '../index';
import { useMutation } from '@apollo/react-hooks';
import './_WhitelistUser.scss';
import { Loader } from '../';
import { trimGraphQLError } from '../../lib/Utils';
import { WHITELIST_CUSTOMER_MUTATION } from '../../graphql/mutations/whitelistCustomer';

const WhitelistUser = ({ userId, refetch }) => {
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [loader, setLoader] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [whitelistCustomer, { data, loading, error }] = useMutation(
    WHITELIST_CUSTOMER_MUTATION,
  );

  const toggleModal = () => setShowModal(showModal => setShowModal(!showModal));

  useEffect(() => {
    if (data) {
      refetch();

      setSuccessToast(true);

      setTimeout(() => {
        setLoader(false);
        toggleModal();
      }, 3000);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toggleModal();

      setErrorToast(true);
    }
  }, [error]);

  useEffect(() => {
    if (loading) {
      setLoader(true);
    }
  }, [loading]);

  useEffect(() => {
    if (errorToast) {
      handleHidErrorToast();
    }
  }, [errorToast]);

  useEffect(() => {
    if (successToast) {
      handleHideSuccessToast();
    }
  }, [successToast]);

  const handleHidErrorToast = () => {
    setTimeout(() => {
      setErrorToast(false);
    }, 2000);
  };

  const handleHideSuccessToast = () => {
    setTimeout(() => {
      setSuccessToast(false);
    }, 2000);
  };

  const removeFromBlacklist = () => {
    whitelistCustomer({
      variables: {
        userId,
      },
    });
  };

  return (
    <>
      {loader && <Loader />}

      {errorToast && (
        <Toast
          title={trimGraphQLError(error?.message)}
          classes={'fail'}
          active={true}
        />
      )}

      <section className="blacklist-user">
        <Button
          classes="btn-medium secondary yellow solid"
          click_event={toggleModal}
        >
          Remove Customer from Blacklist
        </Button>
      </section>

      <Modal
        title="Are you sure you want to remove customer from blacklist?"
        classes="repayment-date-modal"
        active={showModal}
      >
        <Close className="close-icon" onClick={toggleModal} />

        <div className="repayment-double-buttons">
          <Button classes="cancel" click_event={toggleModal}>
            Cancel
          </Button>

          <Button
            classes="secondary blue save"
            type="button"
            onClick={removeFromBlacklist}
          >
            Yes, remove from blacklist
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default WhitelistUser;
