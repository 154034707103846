import React, { Fragment } from 'react';
import { UseUserDetails } from '../../Providers';
import {
  BreadCrumbSection,
  CustomerDetailsSideBar,
  DataCard,
  Table,
} from '../../components';
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import { capitalizeInitialLetter, removeUnderscore } from '../../lib/Utils';

const UserDetails = () => (
  <>
    <UseUserDetails
      render={({ data }) => (
        <Fragment>
          <BreadCrumbSection />

          <CustomerDetailsSideBar>
            <article className="customer-info">
              <span className="cover-image" />
              {data && data.clientAccountUsersXn.nodes.length > 0 ? (
                data.clientAccountUsersXn.nodes.map(
                  (clientAccountUser, index) => (
                    <div className="avatar" key={index}>
                      {`${clientAccountUser.user.firstName
                        .charAt(0)
                        .toUpperCase()}${clientAccountUser.user.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  ),
                )
              ) : (
                <div className="avatar">...</div>
              )}

              {data && data.clientAccountUsersXn.nodes.length > 0 ? (
                data.clientAccountUsersXn.nodes.map(
                  (clientAccountUser, index) => (
                    <h3
                      key={index}
                    >{`${clientAccountUser.user.firstName} ${clientAccountUser.user.lastName}`}</h3>
                  ),
                )
              ) : (
                <h3>...</h3>
              )}
            </article>

            <article className="detail-section">
              <h6>User Details</h6>
              {data && data.clientAccountUsersXn.nodes.length > 0 && (
                <ul>
                  <li>
                    <span>User Name</span>
                    <span>
                      {data.clientAccountUsersXn.nodes[0].user.firstName}{' '}
                      {data.clientAccountUsersXn.nodes[0].user.lastName}
                    </span>
                  </li>
                  <li>
                    <span>User Email</span>
                    <span>{data.clientAccountUsersXn.nodes[0].user.email}</span>
                  </li>
                  <li className="user-roles-list">
                    <div className="detail-section__label">User Roles</div>
                    {data.clientAccountUsersXn.nodes[0].roles.length <= 2 && (
                      <aside className="right">
                        {data.clientAccountUsersXn.nodes[0].roles.map(item => (
                          <i>{item.name}</i>
                        ))}
                      </aside>
                    )}
                  </li>
                  {data.clientAccountUsersXn.nodes[0].roles.length > 2 && (
                    <li className="user-roles-list">
                      <aside>
                        {data.clientAccountUsersXn.nodes[0].roles.map(item => (
                          <i>{item.name}</i>
                        ))}
                      </aside>
                    </li>
                  )}
                </ul>
              )}
            </article>
          </CustomerDetailsSideBar>

          <main className="layout-customerdetails">
            <div>
              <Tabs>
                <TabList>
                  <Tab tabFor="user-details" className={'tab-title'}>
                    User Permissions
                  </Tab>
                </TabList>

                <TabPanel tabId="user-details">
                  {data && data.clientAccountUsersXn && (
                    <DataCard>
                      <Table>
                        <div className="table__body align-left">
                          {data.clientAccountUsersXn.nodes.map(
                            clientAccountUser =>
                              clientAccountUser.permissions.map(
                                (item, index) => (
                                  <ul key={index}>
                                    <li>
                                      <span className="font-semibold">
                                        {capitalizeInitialLetter(
                                          removeUnderscore(item.name),
                                        )}
                                      </span>
                                      <span>{item.description}</span>
                                    </li>
                                  </ul>
                                ),
                              ),
                          )}
                        </div>
                      </Table>
                    </DataCard>
                  )}
                </TabPanel>
              </Tabs>
            </div>
          </main>
        </Fragment>
      )}
    />
  </>
);

export default UserDetails;
