import React, { Fragment } from 'react';
import { DataCard, Table } from '../../../../components';
import {
  nairaAmount,
  numberWithCommas,
  roundWithDoublePrecision,
  sumColumn,
} from '../../../../lib/Utils';
import moment from 'moment';

const RepaymentDetailsTab = ({ data }) => {
  return (
    <Fragment>
      <DataCard noPadding={false}>
        <Table
          tableHeaders={[
            'Repayment Date',
            'Principal',
            'Interest',
            'Total Payment',
          ]}
        >
          <div className="table__body">
            <ul>
              {data &&
                data.application &&
                data.application.portfolio &&
                data.application.portfolio.repayments &&
                data.application.portfolio.repayments.map(
                  (breakDownData, key) => (
                    <li key={key}>
                      <span>
                        {breakDownData.repaymentDate === null
                          ? 'N/A'
                          : moment(breakDownData.repaymentDate).format('ll')}
                      </span>
                      <span>
                        ₦{numberWithCommas(breakDownData.principalPortion)}
                      </span>
                      <span>
                        ₦{numberWithCommas(breakDownData.interestPortion)}
                      </span>
                      <span>
                        ₦
                        {numberWithCommas(
                          roundWithDoublePrecision(breakDownData.totalPayment),
                        )}
                      </span>
                    </li>
                  ),
                )}

              {data &&
                data.application &&
                data.application.portfolio &&
                data.application.portfolio.repayments && (
                  <li className="table__footer">
                    <span>Total</span>
                    <span>
                      {nairaAmount(
                        sumColumn(
                          data.application.portfolio.repayments,
                          'principalPortion',
                        ),
                      )}
                    </span>
                    <span>
                      {nairaAmount(
                        sumColumn(
                          data.application.portfolio.repayments,
                          'interestPortion',
                        ),
                      )}
                    </span>
                    <span>
                      {nairaAmount(
                        sumColumn(
                          data.application.portfolio.repayments,
                          'totalPayment',
                        ),
                      )}
                    </span>
                  </li>
                )}
            </ul>
          </div>
        </Table>
      </DataCard>
    </Fragment>
  );
};

export default RepaymentDetailsTab;
