import React from 'react';
import { withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { LOGIN_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError, validateEmail } from '../lib/Utils';
import { Loader } from '../components';
import OriginateClient from './OriginateClient';

const Login = ({ render, history, location }) => {
  const loginInput = {
    email: useCustomState(),
    password: useCustomState(),
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
  };

  const handleLogin = (e, loginMutation, clientId) => {
    e.preventDefault();
    if (!loginInput.email.value || !loginInput.password.value) {
      loginInput.showToast.setValue(true);
      loginInput.status.setValue('Failed');
      loginInput.content.setValue('Please fill all required input');

      handleHideToast();

      return;
    }

    if (!validateEmail(loginInput.email.value)) {
      loginInput.showToast.setValue(true);
      loginInput.status.setValue('Failed');
      loginInput.content.setValue('Please enter a valid email');

      handleHideToast();

      return;
    }

    loginMutation({
      variables: {
        email: loginInput.email.value,
        password: loginInput.password.value,
        clientId: clientId.clientInfo.clientId,
      },
    });
  };

  const handleHideToast = () => {
    setTimeout(() => {
      loginInput.showToast.setValue(false);
    }, 2500);
  };

  return (
    <OriginateClient
      render={({ clientId }) => (
        <Mutation
          mutation={LOGIN_MUTATION}
          onCompleted={({ login: { token, user } }) => {
            if (token) {
              localStorage.setItem('api-key', token);

              let slug = clientId.clientInfo.slug;

              let { firstName, lastName } = user;
              localStorage.setItem('firstName', firstName);
              localStorage.setItem('lastName', lastName);
              localStorage.setItem('email', loginInput.email.value);
              localStorage.setItem('slug', slug);

              const { from } = location.state || { from: { pathname: '/' } };

              history.push(from);
            }
          }}
          onError={error => {
            loginInput.showToast.setValue(true);
            loginInput.status.setValue('Failed');
            loginInput.content.setValue(trimGraphQLError(error.message));

            handleHideToast();
          }}
        >
          {(loginMutation, { loading }) => {
            if (loading) {
              return <Loader />;
            }

            return render({
              onSubmit: e => handleLogin(e, loginMutation, clientId),
              input: loginInput,
            });
          }}
        </Mutation>
      )}
    />
  );
};

export default withRouter(Login);
