import React, { Fragment, useEffect, useState } from 'react';
import {
  DataCard,
  Card,
  Input,
  Select,
  NewRadio,
  NewCheckBox,
  Button,
  BreadCrumbSection,
  Toast,
  Notification,
  Loader,
} from '../../components';
import './_Styles.scss';
import { capitalizeInitialLetter, removeUnderscore } from '../../lib/Utils';
import { withRouter } from 'react-router-dom';
import { CompleteIcon } from '../../components/Icons';

const RepaymentDetails = ({
  lateFeeCharge,
  lateFeeFreq,
  handleCreatePolicy,
  policyCreateData,
  policyCreateInput,
  rateCalcByData,
  repaymentTypeData,
  handleProcessingFeeCheck,
  processingFeeCheck,
  updateProcessingFeeCheck,
  interestCalcMethodData,
  setForm,
  formData,
  navigation,
  handleUpdatePolicy,
  loanTypeValue,
  requiresRemitaMandate: merchantUsesDebitMandate,
  setOptInForLateFees,
  optInForLateFees,
}) => {
  const { previous } = navigation;
  const {
    interestRate,
    lateFeeCalculation,
    repaymentType,
    interestCalculationMethod,
    allowEarlyRepayment,
    maxLateFeeDuration,
    requiresDebitMandate,
  } = formData;
  const [isSubmitted, updateSubmit] = useState(false);
  const [inputErrors, setInputErrors] = useState([]);
  const [loading, updateLoadingState] = useState(false);

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case 'interestRateValue':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            interestRateValue: 'Kindly enter interest rate',
          }));
        } else {
          setInputErrors(prevState => ({
            ...prevState,
            interestRateValue: '',
          }));
        }
        break;
      case 'repaymentType':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            repaymentType: 'Kindly choose repayment type',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, repaymentType: '' }));
        }
        break;
      case 'lateFeeChargeOn':
        if (optInForLateFees && (!value || value.length === 0)) {
          setInputErrors(prevState => ({
            ...prevState,
            lateFeeChargeOn: 'Kindly choose a late fee principal',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, lateFeeChargeOn: '' }));
        }
        break;
      case 'lateFeeFrequency':
        if (optInForLateFees && (!value || value.length === 0)) {
          setInputErrors(prevState => ({
            ...prevState,
            lateFeeFrequency: 'Kindly select a frequency ',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, lateFeeFrequency: '' }));
        }
        break;
      case 'lateFeeCalcRate':
        if (
          optInForLateFees &&
          (!policyCreateInput.lateFeeCalcRate.input.value ||
            policyCreateInput.lateFeeCalcRate.input.value.length === 0)
        ) {
          setInputErrors(prevState => ({
            ...prevState,
            lateFeeCalcRate: 'Kindly choose late fee type',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, lateFeeCalcRate: '' }));
        }
        break;
      case 'lateFeeValue':
        if (
          optInForLateFees &&
          (!policyCreateInput.lateFeeValue.input.value ||
            policyCreateInput.lateFeeValue.input.value.length === 0)
        ) {
          setInputErrors(prevState => ({
            ...prevState,
            lateFeeValue: 'Kindly enter an amount',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, lateFeeValue: '' }));
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const {
      interestRate: { value: interestRateValue },
      repaymentType,
      lateFeeCalculation: { lateFeeChargeOn, lateFeeFrequency },
      maxLateFeeDuration,
    } = formData;
    const { lateFeeCalcRate, lateFeeValue } = policyCreateInput;

    validateForm('interestRateValue', interestRateValue);
    validateForm('repaymentType', repaymentType);
    validateForm('lateFeeChargeOn', lateFeeChargeOn);
    validateForm('lateFeeFrequency', lateFeeFrequency);
    validateForm('lateFeeCalcRate', lateFeeCalcRate);
    validateForm('lateFeeValue', lateFeeValue);
    validateForm('maxLateFeeDuration', maxLateFeeDuration);
  }, [formData, policyCreateInput, isSubmitted]);

  const initiateApplication = async () => {
    updateLoadingState(true);
    const {
      interestRate: { value: interestRateValue },
      repaymentType,
      lateFeeCalculation: { lateFeeChargeOn, lateFeeFrequency },
      maxLateFeeDuration,
    } = formData;
    formData.requiresDebitMandate =
      merchantUsesDebitMandate && formData.requiresDebitMandate;
    const { lateFeeCalcRate, lateFeeValue } = policyCreateInput;

    if (
      repaymentType ||
      (lateFeeChargeOn &&
        lateFeeFrequency &&
        lateFeeCalcRate &&
        lateFeeValue &&
        maxLateFeeDuration) ||
      (loanTypeValue === 'REGULAR' && interestRateValue)
    ) {
      updateLoadingState(false);
      policyCreateData.isEditMode.value
        ? handleUpdatePolicy(formData)
        : handleCreatePolicy(formData);
    }
  };

  const handleSubmit = () => {
    const {
      interestRate: { value: interestRateValue },
      repaymentType,
      lateFeeCalculation: { lateFeeChargeOn, lateFeeFrequency },
      maxLateFeeDuration,
    } = formData;
    const { lateFeeCalcRate, lateFeeValue } = policyCreateInput;

    updateSubmit(true);

    if (
      repaymentType ||
      (lateFeeChargeOn &&
        lateFeeFrequency &&
        lateFeeCalcRate &&
        lateFeeValue &&
        maxLateFeeDuration) ||
      (loanTypeValue === 'REGULAR' && interestRateValue)
    ) {
      initiateApplication();
    }
  };

  const handleOptInToggle = () => {
    let check = document.getElementById('opt-in');
    let processingFeeType = document.getElementById('processing-fee-type');
    let processingFeeTypeEditMode = document.getElementById(
      'processing-fee-type-edit',
    );
    let toggle = check.checked;

    if (
      policyCreateData.isEditMode.value &&
      (policyCreateInput.processingFeePercent.value > 0 ||
        policyCreateInput.processingFeeFixed.value > 0)
    ) {
      toggle
        ? (processingFeeTypeEditMode.style.display = 'block')
        : (processingFeeTypeEditMode.style.display = 'none');
    } else {
      toggle
        ? (processingFeeType.style.display = 'block')
        : (processingFeeType.style.display = 'none');
    }

    policyCreateInput.processingFeeFixed.setValue('');
    policyCreateInput.processingFeePercent.setValue('');

    updateProcessingFeeCheck({ vat: { value: undefined } });
  };

  const handleLateFeeToggle = () => {
    setOptInForLateFees(prevState => !prevState);
  };

  return (
    <Fragment>
      {loading && <Loader loadingText="loading..." />}
      <BreadCrumbSection />
      {policyCreateData?.isEditMode?.value && (
        <Toast
          title={
            policyCreateData.isEditMode.value
              ? 'Update Policy'
              : 'Create Policy'
          }
          classes={policyCreateData.status.value}
          content={policyCreateData.content.value}
          active={policyCreateData.showToast.value}
        />
      )}
      {policyCreateData.showNotificationPopup.value && (
        <Notification title="Success">
          <div className="notification_icon-holder">
            <CompleteIcon />
          </div>
          <h5 className={'center-text'}>
            {policyCreateData.isEditMode.value
              ? 'Loan Product Updated Successfully'
              : 'Loan Product Created Successfully'}
          </h5>
        </Notification>
      )}
      <div className="loan-product">
        <div className="product-details">
          <form>
            <div className="form-card top-border">
              <DataCard>
                <div className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Repayment Details">
                      This section collects information on how the merchant
                      would like repayments to be structured for the loan
                      product
                    </Card>
                  </div>
                  <div className="form-fields flex-container space-between">
                    {loanTypeValue === 'REGULAR' && (
                      <Fragment>
                        <div className="input-container">
                          <Card title="Interest Rate (in %)" required>
                            <Input
                              data-testid="interest-rate"
                              name="interestRate.value"
                              placeholder="e.g 10"
                              required
                              type="number"
                              value={interestRate.value}
                              onChange={setForm}
                              errorMessage={
                                isSubmitted && inputErrors.interestRateValue
                                  ? inputErrors.interestRateValue
                                  : ''
                              }
                            />
                          </Card>
                        </div>
                        <Card>
                          <div className="interest-calc-method">
                            <Select
                              data-testid="interest-calculation-method"
                              name="interestCalculationMethod"
                              label={'Interest Calculation Method'}
                              value={interestCalculationMethod}
                              onChange={setForm}
                            >
                              <option>
                                -- Select Interest Calculation Method --
                              </option>
                              {interestCalcMethodData &&
                                interestCalcMethodData.interestCalculationMethods.map(
                                  (item, key) => (
                                    <option key={key} value={item.name}>
                                      {capitalizeInitialLetter(
                                        removeUnderscore(item.name),
                                      )}
                                    </option>
                                  ),
                                )}
                            </Select>
                            <div id="interest-calc-method_dropdown">
                              {interestCalcMethodData.interestCalculationMethods.map(
                                (item, idx) => (
                                  <div key={idx}>
                                    <span>
                                      {capitalizeInitialLetter(
                                        removeUnderscore(item.name),
                                      )}
                                      :{' '}
                                    </span>
                                    {item.description}
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        </Card>
                      </Fragment>
                    )}
                    <Card>
                      <Select
                        data-testid="repayment-frequency"
                        name="repaymentType"
                        label={'Repayment Frequency'}
                        value={repaymentType}
                        onChange={setForm}
                        errorMessage={
                          isSubmitted && inputErrors.repaymentType
                            ? inputErrors.repaymentType
                            : ''
                        }
                      >
                        <option>-- Select Repayment Frequency --</option>
                        {repaymentTypeData &&
                          repaymentTypeData.map((item, key) => (
                            <option key={key} value={item.name}>
                              {capitalizeInitialLetter(
                                removeUnderscore(item.name),
                              )}
                            </option>
                          ))}
                      </Select>
                    </Card>
                    <Card>
                      <NewCheckBox
                        data-testid="early-repayment-checkbox"
                        label={'Allow Early Repayment'}
                        name="allowEarlyRepayment"
                        value="true"
                        id="early-repayment"
                        checked={allowEarlyRepayment}
                        onChange={setForm}
                      />
                    </Card>
                    <Card>
                      <NewCheckBox
                        data-testid="debit-mandate-checkbox"
                        label={
                          'Require Debit Mandate Activation for Loan Disbursal'
                        }
                        name="requiresDebitMandate"
                        disabled={!merchantUsesDebitMandate}
                        value={merchantUsesDebitMandate && requiresDebitMandate}
                        id="requires-debit-mandate"
                        checked={
                          merchantUsesDebitMandate && requiresDebitMandate
                        }
                        onChange={setForm}
                      />
                    </Card>
                  </div>
                </div>
              </DataCard>

              <DataCard>
                <div className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Loan Processing Fees">
                      This section collects information on processing fees the
                      merchant would like to attribute to the loan product.
                    </Card>
                  </div>
                  <div className="form-fields flex-container space-between">
                    <NewCheckBox
                      data-testid="loanprocessingfee-checkbox"
                      label={'Opt in for Loan Processing Fee'}
                      value="Opt in"
                      id="opt-in"
                      onChange={handleOptInToggle}
                      defaultChecked={
                        policyCreateData.isEditMode.value &&
                        (policyCreateInput.processingFeePercent.value > 0 ||
                          policyCreateInput.processingFeeFixed.value > 0)
                      }
                    />

                    <div
                      id={
                        policyCreateData.isEditMode.value &&
                        (policyCreateInput.processingFeePercent.value > 0 ||
                          policyCreateInput.processingFeeFixed.value > 0)
                          ? 'processing-fee-type-edit'
                          : 'processing-fee-type'
                      }
                    >
                      <Card title="Select Processing Fee Type">
                        <div className="radio-button">
                          <div className="radio-period">
                            <NewRadio
                              name="processingFee"
                              label="Fixed Price ₦"
                              value="FIXED"
                              checked={processingFeeCheck.vat.value === 'FIXED'}
                              onChange={() =>
                                handleProcessingFeeCheck({ value: 'FIXED' })
                              }
                            />
                            <Input
                              classes="radio_inputbox"
                              type="number"
                              id="vatFixed"
                              placeholder="e.g 200"
                              {...policyCreateInput.processingFeeFixed.input}
                            />
                          </div>
                          <div className="radio-period">
                            <NewRadio
                              name="processingFee"
                              label="% of Principal (in %)"
                              value="PERCENTAGE"
                              checked={
                                processingFeeCheck.vat.value === 'PERCENTAGE'
                              }
                              onChange={() =>
                                handleProcessingFeeCheck({
                                  value: 'PERCENTAGE',
                                })
                              }
                            />
                            <Input
                              classes="radio_inputbox"
                              type="number"
                              id="vatPercent"
                              placeholder="e.g 5"
                              {...policyCreateInput.processingFeePercent.input}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </DataCard>

              <DataCard>
                <div className="flex-container space-between">
                  <div className="form-desc">
                    <Card title="Late Fees (Penalties)">
                      This section collects information on late fees the
                      merchant would like to attribute to the loan product
                    </Card>
                  </div>
                  <div className="form-fields flex-container space-between">
                    <NewCheckBox
                      data-testid="latefee-checkbox"
                      label={'Opt in for Late fees'}
                      s
                      value="optInForLateFees"
                      onChange={handleLateFeeToggle}
                      defaultChecked={optInForLateFees}
                    />
                    {optInForLateFees && (
                      <div>
                        <div className="form-sub">
                          <Card title="Choose Late Fee Type">
                            <Select
                              data-testid="latefee-type"
                              {...policyCreateInput.lateFeeCalcRate.input}
                              errorMessage={
                                isSubmitted && inputErrors.lateFeeCalcRate
                                  ? inputErrors.lateFeeCalcRate
                                  : ''
                              }
                            >
                              <option>-- Select Late Fee Type --</option>
                              {rateCalcByData &&
                                rateCalcByData.map((item, key) => (
                                  <option key={key} value={item.name}>
                                    {capitalizeInitialLetter(item.name)}
                                  </option>
                                ))}
                            </Select>
                          </Card>

                          <Card title=" Amount ₦ || Value (in %)">
                            <Input
                              data-testid="latefee-amount"
                              placeholder="Amount"
                              required
                              type="number"
                              {...policyCreateInput.lateFeeValue.input}
                              errorMessage={
                                isSubmitted && inputErrors.lateFeeValue
                                  ? inputErrors.lateFeeValue
                                  : ''
                              }
                            />
                          </Card>
                        </div>
                        <div className="form-sub">
                          <Card title="Choose Late Fee Principal">
                            <Select
                              data-testid="latefee-principal"
                              name="lateFeeCalculation.lateFeeChargeOn"
                              value={lateFeeCalculation.lateFeeChargeOn}
                              onChange={setForm}
                              errorMessage={
                                isSubmitted && inputErrors.lateFeeChargeOn
                                  ? inputErrors.lateFeeChargeOn
                                  : ''
                              }
                            >
                              <option>-- Select Late Fee Principal --</option>
                              {lateFeeCharge &&
                                lateFeeCharge.map((item, key) => (
                                  <option key={key} value={item.name}>
                                    {capitalizeInitialLetter(
                                      removeUnderscore(item.name),
                                    )}
                                  </option>
                                ))}
                            </Select>
                          </Card>
                        </div>
                        <Card title="Grace Period Before Late Fees are Collected (in Days)">
                          <Input
                            data-testid="latefee-penaltydays"
                            placeholder="e.g 5"
                            type="number"
                            name="lateFeeCalculation.penaltyDelay"
                            value={lateFeeCalculation.penaltyDelay}
                            onChange={setForm}
                          />
                        </Card>
                        <Card title="Fee Frequency">
                          <Select
                            data-testid="lateFee-frequency"
                            name="lateFeeCalculation.lateFeeFrequency"
                            value={lateFeeCalculation.lateFeeFrequency}
                            onChange={setForm}
                            warning="This frequency is not applicable to the “Percentage on Outstanding Balance” late fee type."
                            errorMessage={
                              isSubmitted && inputErrors.lateFeeFrequency
                                ? inputErrors.lateFeeFrequency
                                : ''
                            }
                          >
                            <option>-- Select a Frequency --</option>
                            {lateFeeFreq &&
                              lateFeeFreq.map((item, key) => (
                                <option key={key} value={item.name}>
                                  {capitalizeInitialLetter(
                                    removeUnderscore(item.name),
                                  )}
                                </option>
                              ))}
                          </Select>
                        </Card>

                        {lateFeeCalculation.lateFeeFrequency ===
                          'RECURRENT' && (
                          <div className="form-sub">
                            <Card
                              title={`Maximum Late Fee Duration ( in Days )`}
                            >
                              <Input
                                data-testid="maxLateFeeDuration"
                                name="maxLateFeeDuration"
                                type="number"
                                value={maxLateFeeDuration}
                                dataType={'Int'}
                                placeholder="e.g 15"
                                onChange={setForm}
                                errorMessage={
                                  isSubmitted && inputErrors.maxLateFeeDuration
                                    ? inputErrors.maxLateFeeDuration
                                    : ''
                                }
                              />
                            </Card>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </DataCard>
            </div>
          </form>
        </div>
        <div className="page">
          <div className="double-buttons">
            <Button click_event={previous} data-testid="previous-button">
              {' '}
              Previous{' '}
            </Button>
            <Button
              classes="secondary"
              click_event={handleSubmit}
              data-testid="complete-button"
            >
              {policyCreateData.isEditMode.value ? 'Update' : 'Complete'}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(RepaymentDetails);
