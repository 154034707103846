import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import {
  DELETE_CUSTOM_APPROVAL_WORKFLOW,
  GET_APPROVAL_WORKFLOWS,
  UPDATE_DEFAULT_APPROVAL_WORKFLOW,
} from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';

const useMutateUpdateApprovalWorkflow = () => {
  const data = {
    showToast: useKeyPair(false),
    showNotificationPopup: useKeyPair(false),
    showWorkflowPopup: useKeyPair(false),
    showDelWorkflowPopup: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    selectedStep: useFormInput(),
  };

  const history = useHistory();

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 5000);
  };

  const handleHideNewToast = () => {
    setTimeout(() => {
      data.showNotificationPopup.setValue(false);
    }, 9000);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const handleApprovalWorkflowSwitch = async e => {
    e.preventDefault();

    const selectedStep =
      inputs.selectedStep.value === 'none' ? null : inputs.selectedStep.value;

    await updateDefaultApprovalWorkflow({
      variables: {
        input: { id: selectedStep },
      },
      refetchQueries: () => [{ query: GET_APPROVAL_WORKFLOWS }],
    });
  };

  const handleApprovalWorkflowDelete = async e => {
    e.preventDefault();

    const selectedStep =
      inputs.selectedStep.value === 'none' ? null : inputs.selectedStep.value;

    await customApprovalWorkflowDelete({
      variables: {
        input: { id: selectedStep },
      },
      refetchQueries: () => [{ query: GET_APPROVAL_WORKFLOWS }],
    });
  };

  const handleHideWorkflowToggle = async (e, selectedStep = null) => {
    e.preventDefault();

    if (selectedStep) inputs.selectedStep.setValue(selectedStep);
    data.showWorkflowPopup.setValue(!data.showWorkflowPopup.value);
  };

  const handleHideDeleteWorkflowToggle = async (e, selectedStep = null) => {
    e.preventDefault();

    if (selectedStep) inputs.selectedStep.setValue(selectedStep);
    data.showDelWorkflowPopup.setValue(!data.showDelWorkflowPopup.value);
  };

  const [updateDefaultApprovalWorkflow, { loading, error }] = useMutation(
    UPDATE_DEFAULT_APPROVAL_WORKFLOW,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Workflow Updated');
        data.showNotificationPopup.setValue(true);
        data.showWorkflowPopup.setValue(!data.showWorkflowPopup.value);

        handleHideNewToast();
        history.push('/settings/approval-workflows');
      },
      onError,
    },
  );

  const [customApprovalWorkflowDelete, { loading: delLoading }] = useMutation(
    DELETE_CUSTOM_APPROVAL_WORKFLOW,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Workflow Deleted');
        data.showDelWorkflowPopup.setValue(!data.showDelWorkflowPopup.value);

        handleHideToast();
        history.push('/settings/approval-workflows');
      },
      onError,
    },
  );

  return {
    handleHideToast,
    loading,
    delLoading,
    error,
    form: inputs,
    data,
    handleHideWorkflowToggle,
    handleHideDeleteWorkflowToggle,
    handleApprovalWorkflowSwitch,
    handleApprovalWorkflowDelete,
  };
};

export { useMutateUpdateApprovalWorkflow };
