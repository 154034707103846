import React, { Fragment } from 'react';
import { DataCard, Table } from '../../../../components';
import { nairaAmount, ratioToPercent } from '../../../../lib/Utils';

const BehaviouralAnalysisTab = ({ behaviouralAnalysis }) => {
  const {
    accountSweep,
    gamblingRate,
    inflowOutflowRate,
    loanAmount,
    loanInflowRate,
    loanRepaymentInflowRate,
    loanRepayments,
    topIncomingTransferAccount,
    topTransferRecipientAccount,
  } = behaviouralAnalysis || {};
  return (
    <Fragment>
      <div className="information--content">
        <DataCard noPadding={true}>
          <Table>
            <div className="table__body">
              {behaviouralAnalysis != null &&
              Object.keys(behaviouralAnalysis).length ? (
                <ul>
                  <li>
                    <span className="decide-analysis-span">
                      Behavioural Analysis
                    </span>
                  </li>
                  <li>
                    <span>Account Sweep</span>
                    <span className="right-text">
                      {accountSweep != null ? accountSweep : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Gambling Rate (% of inflow)</span>
                    <span className="right-text">
                      {gamblingRate != null && gamblingRate !== 'NaN'
                        ? `${gamblingRate}%`
                        : 'N/A'}
                    </span>
                  </li>
                  <li>
                    <span>Inflow to Outflow Rate</span>
                    <span className="right-text">
                      {inflowOutflowRate != null ? inflowOutflowRate : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Loan Amounts</span>
                    <span className="right-text">
                      {loanAmount != null ? nairaAmount(loanAmount) : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Loan to Inflow Rate</span>
                    <span className="right-text">
                      {loanInflowRate != null
                        ? ratioToPercent(loanInflowRate)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Loan Repayment to Inflow Rate</span>
                    <span className="right-text">
                      {loanRepaymentInflowRate != null
                        ? ratioToPercent(loanRepaymentInflowRate)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Loan Repayments</span>
                    <span className="right-text">
                      {loanRepayments != null
                        ? nairaAmount(loanRepayments)
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Top Incoming Transfer Account</span>
                    <span className="right-text">
                      {topIncomingTransferAccount != null
                        ? topIncomingTransferAccount
                        : '__'}
                    </span>
                  </li>
                  <li>
                    <span>Top Transfer Recipient Account</span>
                    <span className="right-text">
                      {topTransferRecipientAccount != null
                        ? topTransferRecipientAccount
                        : '__'}
                    </span>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
          </Table>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default BehaviouralAnalysisTab;
