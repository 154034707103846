import { Icon, IconProps } from '@chakra-ui/icon';
import React from 'react';

const BusinessIcon = ({ ...props }: IconProps) => {
  return (
    <Icon
      w={6}
      h={6}
      fill="none"
      stroke="gray.500"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 13.255A23.93 23.93 0 0112 15c-3.183 0-6.22-.62-9-1.745M12 12h.01M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2h8zM5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
      />
    </Icon>
  );
};

export default BusinessIcon;
