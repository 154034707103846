import { gql } from 'apollo-boost';

export const fragments = {
  authPayload: gql`
    fragment PayloadDetails on AuthPayload {
      token
    }
  `,
  role: gql`
    fragment RoleDetails on Role {
      id
      name
      description
      isCustom
    }
  `,
  rule: gql`
    fragment RuleTypeDetails on Rule {
      minAge
      maxAge
      states
      employmentStatuses
      isWorkEmailConfirmed
      maxDTIRatio
      minDTIRatio
      minDTIRatioBs
      maxDTIRatioBs
      minBouncedCheckCount
      maxBouncedCheckCount
      minPercentageBetting
      maxPercentageBetting
      minNetIncome
      maxNetIncome
      minNetIncomeBS
      minLoanAmount
      maxLoanAmount
      minLoanDuration
      maxLoanDuration
      minAccountsInBadStanding
      maxAccountsInBadStanding
      minTenorBs
      minTotalDebitBs
      maxOTIRatioBs
      maxPercentageBettingBS
      bankHasMbsSupport
      noHit
      minAverageSalaryBSP
      maxAverageSalaryBSP
      minAverageOtherIncomeBSP
      maxAverageOtherIncomeBSP
      minNumberOfSalaryPaymentsBSP
      maxNumberOfSalaryPaymentsBSP
      minNumberOfOtherIncomePaymentsBSP
      maxNumberOfOtherIncomePaymentsBSP
      minAverageBalanceBSP
      maxAverageBalanceBSP
      minNetAverageMonthlyEarningsBSP
      maxNetAverageMonthlyEarningsBSP
      minTotalExpensesBSP
      maxTotalExpensesBSP
      isCashFlowPositiveBSP
      minLoanAmountBSP
      maxLoanAmountBSP
      minLoanRepaymentsBSP
      maxLoanRepaymentsBSP
      minLoanToInflowRate
      maxLoanToInflowRate
      minLoanRepaymentToInflowRate
      maxLoanRepaymentToInflowRate
      minGamblingRateBSP
      maxGamblingRateBSP
      accountSweepBSP
      minTotalCreditTurnoverBSP
      maxTotalCreditTurnoverBSP
      minTotalDebitTurnoverBSP
      maxTotalDebitTurnoverBSP
      minAverageMonthlyCreditsBSP
      maxAverageMonthlyCreditsBSP
      minAverageMonthlyDebitsBSP
      maxAverageMonthlyDebitsBSP
      minBalanceAtStatementEndBSP
      maxBalanceAtStatementEndBSP
      minDTIRatioBSP
      maxDTIRatioBSP
      salaryEarnerBSP
    }
  `,
};

export const GET_POLICY_RULE_TYPES = gql`
  query MP_GetPolicyRuleTypes {
    policyRuleTypes {
      id
      name
      label
      status
      fields {
        id
        label
        inputVariableName
        isMulti
        options
        isRequired
        dataType
      }
    }
  }
`;

export const GET_LOAN_PRODUCTS = gql`
  query MP_GetLoanProducts(
    $first: ConnectionLimitInt
    $cursor: ConnectionCursor
    $name: String
    $status: String
  ) {
    viewer {
      account {
        policies(
          first: $first
          after: $cursor
          orderBy: createdAt_DESC
          where: { status: $status, name_contains: $name }
        ) @connection(key: "loanRules-policy") {
          nodes {
            value: id
            label: name
            status
          }
          totalCount
        }
      }
    }
  }
`;

export const CREATE_RULE_SET_MUTATION = gql`
  mutation MP_CreateRuleSet($input: CreateClientRuleSetInput!) {
    clientRuleSetCreate(input: $input) {
      name
      description
      status
    }
  }
`;

export const RULE_SET_DELETE_MUTATION = gql`
  mutation MP_RuleSetDelete($input: MutateClientRuleSetInput!) {
    clientRuleSetDelete(input: $input) {
      id
    }
  }
`;

export const RULE_SET_ENABLE_MUTATION = gql`
  mutation MP_RuleSetEnable($input: MutateClientRuleSetInput!) {
    clientRuleSetEnable(input: $input) {
      id
    }
  }
`;

export const RULE_SET_DISABLE_MUTATION = gql`
  mutation MP_RuleSetDisable($input: MutateClientRuleSetInput!) {
    clientRuleSetDisable(input: $input) {
      id
    }
  }
`;

export const RULE_SET_UPDATE_MUTATION = gql`
  mutation MP_RuleSetUpdate($input: UpdateClientRuleSetInput!) {
    clientRuleSetUpdate(input: $input) {
      id
      name
      description
    }
  }
`;
