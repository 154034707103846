import { useQuery } from '@apollo/client';

import { GET_BANKS } from '@/graphql/queries/bank';

export const useBankList = () => {
  const { data, loading, error, refetch } = useQuery(GET_BANKS);

  return {
    bankListError: error,
    bankListLoading: loading,
    refetchBankList: refetch,
    bankList: data?.getBanks,
  };
};
