import * as React from 'react';

function CheckIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m17.584 9.25-6.666 6.666a.757.757 0 0 1-.584.25.756.756 0 0 1-.583-.25l-3.334-3.333a.806.806 0 0 1 0-1.167.806.806 0 0 1 1.167 0l2.75 2.75 6.084-6.083a.805.805 0 0 1 1.166 0 .805.805 0 0 1 0 1.167M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2"
        fill="#07F"
      />
    </svg>
  );
}

export default CheckIcon;
