/* eslint-disable no-unused-vars */
import cx from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import {
  BreadCrumbSection,
  Button,
  Card,
  DataCard,
  DateRange,
  EmptyScreen,
  Input,
  Loader,
  NewRadio,
  Notification,
  Select,
  Toggle,
} from '../../components';
import { CompleteIcon, DeleteIcon, Plus } from '../../components/Icons';
import { capitalizeInitialLetter, removeUnderscore } from '../../lib/Utils';
import { OriginateClient } from '../../Providers';
import './_Styles.scss';
import { ErrorBoundary } from 'react-error-boundary';

const RegularLoans = ({
  setForm,
  formData,
  navigation,
  loanTypes,
  approvalWorkflowList,
  ruleSets,
  cycleState,
  addNewStep,
  removeStep,
  handleCycleChange,
  handleStartDate,
  handleEndDate,
  policyCreateData,
  policyCreateInput,
  handleValidityPeriod,
  validityPeriod,
  loanDurationType,
  loanDescription,
  handleLoanType,
  loanTypeValue,
}) => {
  const {
    name,
    approvalWorkflowId,
    ruleSetIds,
    minLoanAmount,
    maxLoanAmount,
    minLoanDuration,
    maxLoanDuration,
    durationType,
    offerLetterEnabled,
    validityInDays,
  } = formData;

  const { next } = navigation;
  const [isSubmitted, updateSubmit] = useState(false);
  const [inputErrors, setInputErrors] = useState([]);
  const [loading, updateLoadingState] = useState(false);
  const validityDays = ['1', '2', '3', '4', '5', '6', '7'];

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case 'name':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            name: 'Kindly enter loan product name',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, name: '' }));
        }
        break;
      case 'loanType':
        if (!loanTypeValue || loanTypeValue.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            loanType: 'Kindly select a loan type.',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, loanType: '' }));
        }
        break;
      case 'minLoanAmount':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            minLoanAmount: 'Kindly enter a minimum loan amount.',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, minLoanAmount: '' }));
        }
        break;
      case 'maxLoanAmount':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            maxLoanAmount: 'Kindly enter a maximum loan amount.',
          }));
        } else if (parseFloat(value) < parseFloat(minLoanAmount)) {
          setInputErrors(prevState => ({
            ...prevState,
            maxLoanAmount:
              'Maximum loan amount must be greater than or equal to minimum loan amount.',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, maxLoanAmount: '' }));
        }
        break;
      case 'minLoanDuration':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            minLoanDuration: 'Kindly enter a minimum loan duration.',
          }));
        } else {
          setInputErrors(prevState => ({
            ...prevState,
            minLoanDuration: '',
          }));
        }
        break;
      case 'maxLoanDuration':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            maxLoanDuration: 'Kindly enter a maximum loan duration.',
          }));
        } else if (parseInt(value) < parseInt(minLoanDuration)) {
          setInputErrors(prevState => ({
            ...prevState,
            maxLoanDuration:
              'Maximum loan duration must be greater than or equal to minimum loan duration.',
          }));
        } else {
          setInputErrors(prevState => ({
            ...prevState,
            maxLoanDuration: '',
          }));
        }
        break;
      case 'amount':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            amount: 'Kindly enter a loan amount.',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, amount: '' }));
        }
        break;
      case 'gradMaxDuration':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            gradMaxDuration: 'Kindly enter a max loan duration.',
          }));
        } else {
          setInputErrors(prevState => ({
            ...prevState,
            gradMaxDuration: '',
          }));
        }
        break;
      case 'interestRate':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            interestRate: 'Kindly enter an interest rate.',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, interestRate: '' }));
        }
        break;
      case 'durationType':
        if (!value || value.length === 0) {
          setInputErrors(prevState => ({
            ...prevState,
            durationType: 'Kindly select a duration.',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, durationType: '' }));
        }
        break;
      case 'gradDurationType':
        if (
          !policyCreateInput.gradDurationType.input.value ||
          policyCreateInput.gradDurationType.input.value.length === 0
        ) {
          setInputErrors(prevState => ({
            ...prevState,
            gradDurationType: 'Kindly select a duration.',
          }));
        } else {
          setInputErrors(prevState => ({
            ...prevState,
            gradDurationType: '',
          }));
        }
        break;
      case 'validityInDays':
        if (!value) {
          setInputErrors(prevState => ({
            ...prevState,
            validityInDays: 'Validity should be between 1 and 7 days',
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, validityInDays: '' }));
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const {
      name,
      minLoanAmount,
      maxLoanAmount,
      minLoanDuration,
      maxLoanDuration,
      durationType,
      validityInDays,
    } = formData;
    const { gradDurationType } = policyCreateInput;

    let graduatedLoanData = [];
    cycleState.map(item =>
      graduatedLoanData.push(
        validateForm('amount', item.amount),
        validateForm('interestRate', item.interestRate),
        validateForm('gradMaxDuration', item.maxLoanDuration),
      ),
    );
    validateForm('name', name);
    validateForm('loanType', loanTypeValue);
    validateForm('minLoanAmount', minLoanAmount);
    validateForm('maxLoanAmount', maxLoanAmount);
    validateForm('minLoanDuration', minLoanDuration);
    validateForm('maxLoanDuration', maxLoanDuration);
    validateForm('durationType', durationType);
    validateForm('gradDurationType', gradDurationType);
    validateForm('validityInDays', validityInDays);
  }, [formData, isSubmitted]);

  const filterArrayOfObjects = take => {
    return take.every(function (o) {
      return Object.keys(o).every(function (key) {
        return o[key] !== '';
      });
    });
  };

  let graduatedLoanData = [];
  cycleState.map(item =>
    graduatedLoanData.push({
      interestRate: item.interestRate,
      amount: item.amount,
      maxLoanDuration: item.maxLoanDuration,
    }),
  );

  const initiateApplication = async () => {
    updateLoadingState(true);
    const {
      name,
      minLoanAmount,
      maxLoanAmount,
      minLoanDuration,
      maxLoanDuration,
      durationType,
    } = formData;
    const { gradDurationType } = policyCreateInput;

    if (
      name &&
      ((loanTypeValue === 'REGULAR' &&
        minLoanAmount &&
        maxLoanAmount &&
        !(parseFloat(minLoanAmount) > parseFloat(maxLoanAmount)) &&
        minLoanDuration &&
        maxLoanDuration &&
        !(parseInt(minLoanDuration) > parseInt(maxLoanDuration)) &&
        durationType) ||
        (loanTypeValue === 'GRADUATED' &&
          gradDurationType &&
          filterArrayOfObjects(graduatedLoanData)))
    ) {
      updateLoadingState(false);
      next();
    }
  };

  const nextStep = () => {
    const {
      name,
      minLoanAmount,
      maxLoanAmount,
      minLoanDuration,
      maxLoanDuration,
      validityInDays,
      offerLetterEnabled,
    } = formData;
    const { gradDurationType } = policyCreateInput;

    updateSubmit(true);

    if (
      name &&
      ((loanTypeValue === 'REGULAR' &&
        minLoanAmount &&
        maxLoanAmount &&
        !(parseFloat(minLoanAmount) > parseFloat(maxLoanAmount)) &&
        minLoanDuration &&
        maxLoanDuration &&
        !(parseInt(minLoanDuration) > parseInt(maxLoanDuration)) &&
        durationType) ||
        (loanTypeValue === 'GRADUATED' &&
          gradDurationType &&
          filterArrayOfObjects(graduatedLoanData)))
    ) {
      if (offerLetterEnabled && !validityInDays) {
        return;
      }

      initiateApplication();
    }
  };

  if (
    document.getElementById('filterRange') &&
    validityPeriod.valPeriod.value === 'no'
  ) {
    document.getElementById('filterRange').style.pointerEvents = 'none';
  }

  if (
    document.getElementById('filterRange') &&
    validityPeriod.valPeriod.value === 'yes'
  ) {
    document.getElementById('filterRange').style.pointerEvents = '';
  }

  return (
    <Fragment>
      {loading && <Loader loadingText="loading..." />}

      <BreadCrumbSection />

      {policyCreateData.showNotificationPopup.value && (
        <Notification title="Success">
          <div className="notification_icon-holder">
            <CompleteIcon />
          </div>
          <h5 className={'center-text'}>Loan Product Created Successfully</h5>
        </Notification>
      )}

      <div className="loan-product">
        <ErrorBoundary
          fallback={
            <DataCard noPadding={false}>
              <EmptyScreen message="Error Fetching Loan Products" />
            </DataCard>
          }
        >
          <div className="product-details">
            <form>
              <div className="form-card top-border">
                <DataCard>
                  <div className="flex-container space-between">
                    <div className="form-desc">
                      <Card title="Product Details">
                        Define the basic metadata which will be associated with
                        the loan product
                      </Card>
                    </div>
                    <div className="form-fields flex-container space-between">
                      <div className="input-container">
                        <Card title="Name" required>
                          <Input
                            data-testid="newloanproduct-name"
                            name="name"
                            placeholder="Name of loan product"
                            type="text"
                            value={name}
                            onChange={setForm}
                            required
                            errorMessage={
                              isSubmitted && inputErrors?.name
                                ? inputErrors?.name
                                : ''
                            }
                          />
                        </Card>
                      </div>
                      <Card title="Validity Period">
                        <div className="radio-button">
                          <div className="radio-period">
                            <NewRadio
                              label="Indefinitely"
                              name="validityPeriod"
                              value="no"
                              checked={
                                validityPeriod?.valPeriod?.value === 'no'
                              }
                              onChange={() =>
                                handleValidityPeriod({ value: 'no' })
                              }
                            />
                          </div>
                          <div className="radio-input">
                            <NewRadio
                              name="validityPeriod"
                              value="yes"
                              checked={
                                validityPeriod?.valPeriod?.value === 'yes'
                              }
                              onChange={() =>
                                handleValidityPeriod({ value: 'yes' })
                              }
                            />
                            <span className={'filter-range'}>
                              <div
                                id="filterRange"
                                className={cx({
                                  'show-date-range':
                                    validityPeriod?.valPeriod === 'yes',
                                })}
                              >
                                <DateRange
                                  startDateInput={
                                    policyCreateInput?.startDate?.input?.value
                                  }
                                  endDateInput={
                                    policyCreateInput?.endDate?.input?.value
                                  }
                                  startDate={handleStartDate}
                                  endDate={handleEndDate}
                                />
                              </div>
                            </span>
                          </div>
                        </div>
                      </Card>
                      <Card>
                        <Select
                          data-testid="approval-workflow"
                          name="approvalWorkflowId"
                          label={'Choose Approval Workflow'}
                          value={approvalWorkflowId}
                          onChange={setForm}
                          required
                        >
                          <option>-- Select a Workflow --</option>
                          {approvalWorkflowList &&
                            approvalWorkflowList?.map((item, key) => (
                              <option key={key} value={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                        </Select>
                      </Card>

                      <Card>
                        <Select
                          data-testid="loan-rule"
                          name="ruleSetIds"
                          label={'Choose Loan Rule'}
                          value={ruleSetIds}
                          onChange={setForm}
                        >
                          <option>-- Select a Loan Rule --</option>
                          {ruleSets &&
                            ruleSets.map((item, key) => (
                              <option key={key} value={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                        </Select>
                      </Card>

                      <OriginateClient
                        render={({
                          clientId: {
                            clientInfo: { loanOfferLetterFeatureIsEnabled },
                          },
                        }) => (
                          <Fragment>
                            {loanOfferLetterFeatureIsEnabled ? (
                              <Fragment>
                                <Card title="Offer Letter">
                                  <div className="require-offer-letter">
                                    <span>Require Offer Letter</span>
                                    <Toggle
                                      name="offerLetterEnabled"
                                      value="true"
                                      onChange={setForm}
                                      checked={offerLetterEnabled}
                                    />
                                  </div>
                                </Card>

                                {offerLetterEnabled ? (
                                  <Card>
                                    <Select
                                      name="validityInDays"
                                      label={'Offer Validity (in days)'}
                                      value={validityInDays}
                                      onChange={setForm}
                                      required
                                      errorMessage={
                                        isSubmitted &&
                                        inputErrors?.validityInDays
                                          ? inputErrors?.validityInDays
                                          : ''
                                      }
                                    >
                                      <option value="">
                                        -- Number of days --
                                      </option>

                                      {validityDays?.map(number => (
                                        <option value={number} key={number}>
                                          {number}
                                        </option>
                                      ))}
                                    </Select>
                                  </Card>
                                ) : (
                                  ''
                                )}
                              </Fragment>
                            ) : (
                              ''
                            )}
                          </Fragment>
                        )}
                      />
                    </div>
                  </div>
                </DataCard>
              </div>
            </form>
          </div>
          <div className="loan-details">
            <form>
              <div className="form-card top-border">
                <DataCard>
                  <div className="flex-container space-between">
                    <div className="form-desc">
                      <Card title="Loan Details">
                        This section collects information on the applicable
                        amount and interest for the loan product
                      </Card>
                    </div>
                    <div className="form-fields flex-container space-between">
                      <Card>
                        <Select
                          data-testid="loan-type"
                          label={'Choose Loan Type'}
                          id="getFname"
                          value={loanTypeValue}
                          onChange={handleLoanType}
                          errorMessage={
                            isSubmitted && inputErrors?.loanType
                              ? inputErrors?.loanType
                              : ''
                          }
                        >
                          <option>-- Select Loan Type--</option>
                          {loanTypes?.map((item, key) => (
                            <option key={key} value={item?.name}>
                              {capitalizeInitialLetter(item?.name)}
                            </option>
                          ))}
                        </Select>
                      </Card>
                      {loanTypeValue !== 'GRADUATED' ? (
                        <div className="form-fields ">
                          <div className="flex-container double-col ">
                            <div className="range">
                              <div className="form-fields">
                                <Card title="Minimum Loan ₦" required>
                                  <Input
                                    data-testid="minloanamount"
                                    name="minLoanAmount"
                                    type="number"
                                    value={minLoanAmount}
                                    dataType={'float'}
                                    placeholder="e.g 30,000"
                                    onChange={setForm}
                                    errorMessage={
                                      isSubmitted &&
                                      loanTypeValue === 'REGULAR' &&
                                      inputErrors?.minLoanAmount
                                        ? inputErrors?.minLoanAmount
                                        : ''
                                    }
                                  />
                                </Card>
                              </div>
                              <div className="arrow" />
                              <div className="form-fields">
                                <Card title="Maximum Loan ₦" required>
                                  <Input
                                    data-testid="maxloanamount"
                                    name="maxLoanAmount"
                                    type="number"
                                    value={maxLoanAmount}
                                    onChange={setForm}
                                    placeholder="e.g 50,000"
                                    dataType={'float'}
                                    errorMessage={
                                      isSubmitted &&
                                      loanTypeValue === 'REGULAR' &&
                                      inputErrors?.maxLoanAmount
                                        ? inputErrors?.maxLoanAmount
                                        : ''
                                    }
                                  />
                                </Card>
                              </div>
                            </div>
                          </div>

                          <div className="flex-container double-col ">
                            <div className="range">
                              <div className="form-fields">
                                <Card title="Duration Type" required>
                                  <Select
                                    data-testid="duration-type"
                                    name="durationType"
                                    value={durationType}
                                    onChange={setForm}
                                    errorMessage={
                                      isSubmitted &&
                                      loanTypeValue === 'REGULAR' &&
                                      inputErrors?.durationType
                                        ? inputErrors?.durationType
                                        : ''
                                    }
                                  >
                                    <option>-- Select Loan Type--</option>
                                    {loanDurationType &&
                                      loanDurationType?.map((item, key) => (
                                        <option key={key} value={item?.name}>
                                          {capitalizeInitialLetter(
                                            removeUnderscore(item?.name),
                                          )}
                                        </option>
                                      ))}
                                  </Select>
                                </Card>
                              </div>
                            </div>
                          </div>

                          <div className="flex-container double-col">
                            <div className="range">
                              <div className="form-fields">
                                <Card
                                  title={`Minimum Duration (${loanDescription(
                                    durationType,
                                  )}):`}
                                >
                                  <Input
                                    data-testid="minloanduration"
                                    name="minLoanDuration"
                                    type="number"
                                    value={minLoanDuration}
                                    placeholder="e.g 20"
                                    onChange={setForm}
                                    errorMessage={
                                      isSubmitted &&
                                      loanTypeValue === 'REGULAR' &&
                                      inputErrors?.minLoanDuration
                                        ? inputErrors?.minLoanDuration
                                        : ''
                                    }
                                  />
                                </Card>
                              </div>
                              <div className="form-fields">
                                <Card
                                  title={`Maximum Duration (${loanDescription(
                                    durationType,
                                  )}):`}
                                >
                                  <Input
                                    data-testid="maxloanduration"
                                    name="maxLoanDuration"
                                    type="number"
                                    value={maxLoanDuration}
                                    placeholder="e.g 60"
                                    onChange={setForm}
                                    errorMessage={
                                      isSubmitted &&
                                      loanTypeValue === 'REGULAR' &&
                                      inputErrors?.maxLoanDuration
                                        ? inputErrors?.maxLoanDuration
                                        : ''
                                    }
                                  />
                                </Card>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Fragment>
                          <Card title="Duration Type" required>
                            <Select
                              {...policyCreateInput.gradDurationType.input}
                              errorMessage={
                                isSubmitted && inputErrors?.gradDurationType
                                  ? inputErrors?.gradDurationType
                                  : ''
                              }
                            >
                              <option>-- Select Loan Type--</option>
                              {loanDurationType &&
                                loanDurationType.map((item, key) => (
                                  <option key={key} value={item?.name}>
                                    {capitalizeInitialLetter(
                                      removeUnderscore(item?.name),
                                    )}
                                  </option>
                                ))}
                            </Select>
                          </Card>

                          <div className="flex-container ">
                            <div className="cycle">
                              <div className="form-fields">
                                {cycleState.length < 1 && (
                                  <div className={'required custom-rule-error'}>
                                    It's required to add a cycle
                                  </div>
                                )}
                                {cycleState.length >= 1 &&
                                  cycleState.map((val, idx) => {
                                    const loanAmountId = `amount-${idx}`;
                                    const interestRateId = `interestRate-${idx}`;
                                    const maxLoanDurationId = `maxLoanDuration-${idx}`;
                                    const repaymentCount = `repaymentCount-${idx}`;

                                    return (
                                      <Fragment key={idx}>
                                        <div className="cycle-steps">
                                          <div
                                            className="remove-step"
                                            onClick={() => removeStep(idx)}
                                          >
                                            <DeleteIcon color={'#C1292E'} />
                                          </div>

                                          <div className="form-fields step-text">
                                            <Card title={`Cycle ${idx + 1}`} />
                                          </div>
                                          <div className="flex-container double-col">
                                            <div className="cycle-input">
                                              <div className="form-fields">
                                                <Card
                                                  title="Loan Amount ₦"
                                                  required
                                                >
                                                  <Input
                                                    name={loanAmountId}
                                                    data-idx={idx}
                                                    data-set="amount"
                                                    type="number"
                                                    placeholder="e.g 25,000"
                                                    id={loanAmountId}
                                                    value={
                                                      cycleState[idx]?.amount
                                                    }
                                                    onChange={handleCycleChange}
                                                    errorMessage={
                                                      isSubmitted &&
                                                      inputErrors?.amount
                                                        ? inputErrors?.amount
                                                        : ''
                                                    }
                                                  />
                                                </Card>
                                              </div>
                                              <div className="form-fields">
                                                <Card
                                                  title="Interest Rate ( in % )"
                                                  required
                                                >
                                                  <Input
                                                    name={interestRateId}
                                                    type="number"
                                                    data-idx={idx}
                                                    data-set="interestRate"
                                                    placeholder="e.g 10"
                                                    id={interestRateId}
                                                    value={
                                                      cycleState[idx]
                                                        ?.interestRate
                                                    }
                                                    onChange={handleCycleChange}
                                                    errorMessage={
                                                      isSubmitted &&
                                                      inputErrors?.interestRate
                                                        ? inputErrors?.interestRate
                                                        : ''
                                                    }
                                                  />
                                                </Card>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-sub">
                                            <Card
                                              title={`Max Loan Duration (${loanDescription(
                                                policyCreateInput
                                                  ?.gradDurationType?.value,
                                              )}):`}
                                            >
                                              <Input
                                                type="number"
                                                name={maxLoanDurationId}
                                                data-idx={idx}
                                                id={maxLoanDurationId}
                                                placeholder="e.g 30"
                                                data-set="maxLoanDuration"
                                                value={
                                                  cycleState[idx]
                                                    ?.maxLoanDuration
                                                }
                                                onChange={handleCycleChange}
                                                errorMessage={
                                                  isSubmitted &&
                                                  inputErrors?.gradMaxDuration
                                                    ? inputErrors?.gradMaxDuration
                                                    : ''
                                                }
                                              />
                                            </Card>
                                            <Card title={'Repayment Count'}>
                                              <Input
                                                type="number"
                                                name={repaymentCount}
                                                data-idx={idx}
                                                id={repaymentCount}
                                                placeholder="e.g 2"
                                                data-set="repaymentCount"
                                                value={
                                                  cycleState[idx]
                                                    ?.repaymentCount
                                                }
                                                onChange={handleCycleChange}
                                              />
                                            </Card>
                                          </div>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>

                              <div className="form-fields">
                                <div
                                  className="flex-container add-new-step"
                                  onClick={() => addNewStep()}
                                >
                                  <Plus /> Add new step
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </DataCard>
              </div>
            </form>
          </div>

          <div className="page">
            <div className="double-buttons">
              <NavLink to="/loan-products">
                <Button classes="primary" data-testid="cancel-button">
                  Cancel
                </Button>
              </NavLink>
              <Button
                classes="secondary "
                click_event={nextStep}
                data-testid="next-button"
              >
                Next
              </Button>
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </Fragment>
  );
};

export default withRouter(RegularLoans);
