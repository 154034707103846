import React from 'react';
import './_DateFilter.scss';
import Button from '../Button/Button';

class DateFilter extends React.Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
    };

    this.dropdownMenu = null;

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    if (
      this.dropdownMenu !== null &&
      !this.dropdownMenu.contains(event.target)
    ) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  }

  render() {
    return (
      <div ref={this.setWrapperRef} className="dd-wrapper">
        <div onClick={this.showMenu}>{this.props.dropdownTrigger}</div>

        {this.state.showMenu ? (
          <aside
            ref={element => {
              this.dropdownMenu = element;
            }}
            className="datefilter"
          >
            <div className="datefilter__top">
              <h6>Last Month</h6>
              <h5>Dec. 1, 2018 - Dec. 31, 2018</h5>
            </div>
            <ul className="datefilter__block-list">
              <li>Today</li>
              <li>Yesterday</li>
              <li>Last Week</li>
              <li>Last 14 days</li>
              <li>Last Month</li>
            </ul>
            <Button classes={'block secondary'}>Confirm Range</Button>
          </aside>
        ) : null}
      </div>
    );
  }
}

export default DateFilter;
