import React, { Fragment } from 'react';
import { Button, Table } from '../../../../components/index';
import {
  nairaAmount,
  capitalizeInitialLetter,
  formatLoanDuration,
  getLoanDurationName,
  removeUnderscore,
} from '../../../../lib/Utils';
import moment from 'moment';
import { Link } from 'react-router-dom';

const PortfolioDetailsTab = ({ portfolioData, nextDueDate }) => {
  return (
    <Fragment>
      <Table>
        {portfolioData.portfolio.application &&
          portfolioData.portfolio.application.applicationNumber && (
            <Button classes="link right">
              <Link
                to={`/v1/applications/${portfolioData.portfolio.application.applicationNumber}/${portfolioData.portfolio.user.email}`}
              >
                View Linked Application
              </Link>
            </Button>
          )}
        <div className="table__body">
          <ul>
            <li>
              <span>Loan ID</span>
              <span className="right-text">
                {portfolioData.portfolio.portfolioNumber}
              </span>
            </li>
            <li>
              <span>Principal Amount</span>
              <span className="right-text">
                {nairaAmount(portfolioData.portfolio.amount)}
              </span>
            </li>
            <li>
              <span>Interest Amount</span>
              <span className="right-text">
                {nairaAmount(portfolioData.portfolio.chargesAmount)}
              </span>
            </li>
            <li>
              <span>Processing Fee</span>
              <span className="right-text">
                {portfolioData.portfolio.processingFee > 0
                  ? nairaAmount(portfolioData.portfolio.processingFee)
                  : 'N/A'}
              </span>
            </li>
            <li>
              <span>Late Fees</span>
              <span className="right-text">
                {portfolioData.portfolio.lateRepaymentFee > 0
                  ? nairaAmount(portfolioData.portfolio.lateRepaymentFee)
                  : 'N/A'}
              </span>
            </li>
            <li>
              <span>Loan Duration</span>
              <span className="right-text">
                {portfolioData.portfolio.policy !== null
                  ? `${formatLoanDuration(
                      portfolioData.portfolio.loanDuration,
                      portfolioData.portfolio.policy,
                    )} ${getLoanDurationName(
                      portfolioData.portfolio.policy.durationType,
                    )}`
                  : 'N/A'}
              </span>
            </li>
            <li>
              <span>Amount Disbursed</span>
              <span className="right-text">
                {nairaAmount(portfolioData.portfolio.amountDisbursed)}
              </span>
            </li>
            <li>
              <span>Amount Paid</span>
              <span className="right-text">
                {nairaAmount(portfolioData.portfolio.amountPaid)}
              </span>
            </li>
            <li>
              <span>Status</span>
              <span className="right-text">
                {removeUnderscore(
                  capitalizeInitialLetter(portfolioData.portfolio.status.name),
                )}
              </span>
            </li>
            <li>
              <span>Loan Count</span>
              <span className="right-text">N/A</span>
            </li>
            <li>
              <span>Created at</span>
              <span className="right-text">
                {moment(portfolioData.portfolio.createdAt).format('lll')}
              </span>
            </li>
            {portfolioData &&
              portfolioData.portfolio &&
              portfolioData.portfolio.status &&
              portfolioData.portfolio.status.name === 'DISBURSED' && (
                <li>
                  <span>Repayment Date</span>
                  <span className="right-text">
                    {nextDueDate
                      ? moment(nextDueDate.dueDate).format('ll')
                      : 'N/A'}
                  </span>
                </li>
              )}
            {portfolioData &&
              portfolioData.portfolio &&
              portfolioData.portfolio.debitMandate &&
              portfolioData.portfolio.debitMandate.status.name && (
                <li>
                  <span>Mandate Status</span>
                  <span className="right-text">
                    <span
                      className={`status-text fixed ${
                        portfolioData.portfolio.debitMandate.status.name.toLowerCase() ||
                        ''
                      }`}
                    >
                      {(() => {
                        let statusName =
                          portfolioData.portfolio.debitMandate.status.name;
                        switch (statusName) {
                          case 'PENDING_ACTIVATION':
                            return 'Pending Activation';
                          case 'ACTIVATED':
                            return 'Activated';
                          case 'DEACTIVATED':
                            return 'Deactivated';
                          default:
                            return 'Pending';
                        }
                      })()}
                    </span>
                  </span>
                </li>
              )}

            {portfolioData &&
              portfolioData.portfolio &&
              portfolioData.portfolio.debitMandate &&
              portfolioData.portfolio.debitMandate.mandateId && (
                <li>
                  <span>Mandate ID</span>
                  <span className="right-text">
                    {portfolioData.portfolio.debitMandate.mandateId}
                  </span>
                </li>
              )}

            {portfolioData &&
              portfolioData.portfolio &&
              portfolioData.portfolio.debitMandate &&
              portfolioData.portfolio.debitMandate.formUrl && (
                <li>
                  <span>Mandate Form</span>
                  <span className="right-text">
                    <a
                      href={portfolioData.portfolio.debitMandate.formUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      download={`${portfolioData.portfolio.user.firstName}'s Debit Mandate Form`}
                    >
                      View Mandate Form
                    </a>
                  </span>
                </li>
              )}

            {portfolioData?.portfolio?.debitMandateMeta ? (
              <Fragment>
                {portfolioData?.portfolio?.debitMandateMeta?.type?.name && (
                  <li>
                    <span>Mandate Type</span>
                    <span
                      className="right-text"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {portfolioData?.portfolio?.debitMandateMeta?.type?.name.toLowerCase()}
                    </span>
                  </li>
                )}

                {portfolioData?.portfolio?.debitMandateMeta?.status?.name && (
                  <li>
                    <span>Mandate Status</span>
                    <span
                      className="right-text"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {removeUnderscore(
                        portfolioData?.portfolio?.debitMandateMeta?.status?.name.toLowerCase(),
                      )}
                    </span>
                  </li>
                )}

                {portfolioData?.portfolio?.debitMandateMeta?.lidyaCollect
                  ?.collectId && (
                  <li>
                    <span>Mandate Id</span>
                    <span
                      className="right-text"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {removeUnderscore(
                        portfolioData?.portfolio?.debitMandateMeta?.lidyaCollect?.collectId.toLowerCase(),
                      )}
                    </span>
                  </li>
                )}

                {portfolioData?.portfolio?.debitMandateMeta?.activationDate && (
                  <li>
                    <span>Activation Date</span>
                    <span
                      className="right-text"
                      style={{ textTransform: 'capitalize' }}
                    >
                      <span className="status-text">
                        {
                          portfolioData?.portfolio?.debitMandateMeta
                            ?.activationDate
                        }
                      </span>
                    </span>
                  </li>
                )}
              </Fragment>
            ) : null}
          </ul>
        </div>
      </Table>
    </Fragment>
  );
};

export default PortfolioDetailsTab;
