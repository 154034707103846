import { useLazyQuery } from '@apollo/client';

import { RESOLVE_ACCOUNT_NUMBER } from '@/graphql/mutations/bank';
import { ResolveAccountNumberQueryVariables } from '@/__generated/graphql';

export const useResolveAccountNumber = () => {
  const [query, { data, loading, error }] = useLazyQuery(
    RESOLVE_ACCOUNT_NUMBER,
  );

  const resolveAccountNumber = ({
    bankId,
    accountNumber,
  }: ResolveAccountNumberQueryVariables) => {
    query({
      variables: {
        bankId,
        accountNumber,
      },
    });
  };

  return {
    resolveAccountNumber,
    accountNameError: error,
    accountNameLoading: loading,
    accountName: data?.resolveAccountNumber?.accountName,
  };
};
