import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components';
import BlacklistedUser from '../../../../components/BlacklistedUser/BlacklistedUser';

const Sidebar = ({ portfolioData, isVisible, facilityInfo, customerName }) => {
  return (
    <Fragment>
      <article className="customer-info">
        <span className="cover-image" />
        {portfolioData?.portfolio?.user ? (
          <div className="avatar">
            {portfolioData?.portfolio?.user?.firstName &&
            portfolioData?.portfolio?.user?.lastName
              ? `
              ${portfolioData.portfolio.user?.firstName
                ?.charAt(0)
                ?.toUpperCase()}
              ${portfolioData.portfolio.user?.lastName
                ?.charAt(0)
                ?.toUpperCase()}
            `
              : 'N/A'}
          </div>
        ) : (
          <div className="avatar">...</div>
        )}
        {portfolioData?.portfolio?.user ? (
          <h3>
            {portfolioData?.portfolio?.user?.firstName &&
            portfolioData?.portfolio?.user?.lastName
              ? `${portfolioData.portfolio.user?.firstName} ${portfolioData.portfolio.user?.lastName} `
              : 'N/A'}
          </h3>
        ) : (
          <h3>...</h3>
        )}

        {portfolioData?.portfolio?.user?.blacklistDetails && (
          <BlacklistedUser
            blacklistDetails={portfolioData?.portfolio?.user?.blacklistDetails}
            customerName={customerName}
          />
        )}
      </article>

      <article className="detail-section">
        {isVisible ? (
          facilityInfo && (
            <Link
              to={`/facilities/${encodeURIComponent(facilityInfo.accountName)}`}
            >
              <Button classes="green center view-customerdetails">
                View Facility Details
              </Button>
            </Link>
          )
        ) : (
          <Link to={`/customers/${encodeURIComponent(customerName)}`}>
            <Button
              disabled={!customerName}
              classes="green center view-customerdetails"
            >
              View Customer Details
            </Button>
          </Link>
        )}

        <h6>Personal Information</h6>
        {
          <ul>
            <li>
              <span>Customer ID</span>
              <span>{customerName || '...'}</span>
            </li>

            <li>
              <span>Contact Email</span>
              <span>{portfolioData?.portfolio?.user?.email || '...'}</span>
            </li>
          </ul>
        }
      </article>
    </Fragment>
  );
};

export default Sidebar;
