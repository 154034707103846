import { useQuery } from '@apollo/react-hooks';
import { GET_LOAN_CATEGORIES } from './queries';

const useGetLoanCategories = () => {
  let output = {
    getLoanCategoryResponse: [],
  };

  const qResult = useQuery(GET_LOAN_CATEGORIES);
  const { data, loading, error } = qResult;

  if (!loading && data) {
    output = {
      getLoanCategoryResponse: data,
    };
  }

  return {
    loading,
    error,
    ...output,
    result: qResult,
  };
};

export { useGetLoanCategories };
