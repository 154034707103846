import { withRouter } from 'react-router-dom';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';
import { useKeyPair, useFormInput } from '../Providers-new/Form';
import { COMPLETE_INVITATION_MUTATION } from '../graphql/mutations';
import JwtDecode from 'jwt-decode';

const useCompleteInvitation = ({ render, history, match: { params } }) => {
  const { invitationToken } = params;

  const data = {
    showToast: useKeyPair(false),
    content: useKeyPair(),
    status: useKeyPair(),
  };

  const { email, invitedBy } = JwtDecode(invitationToken);

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 2500);
  };

  const inputs = {
    email: useFormInput(),
    password: useFormInput(),
    confirmPassword: useFormInput(),
    firstName: useFormInput(),
    lastName: useFormInput(),
  };

  const handleReset = () => {
    inputs.firstName.setValue('');
    inputs.lastName.setValue('');
    inputs.password.setValue('');
    inputs.confirmPassword.setValue('');
  };

  const handleCompleteInvitation = (e, completeInvitationMutation) => {
    e.preventDefault();

    if (
      !inputs.firstName.value ||
      !inputs.lastName.value ||
      !inputs.password.value ||
      !inputs.confirmPassword.value
    ) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please fill all required fields');

      handleHideToast();
      return;
    }

    if (inputs.password.value !== inputs.confirmPassword.value) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue("Passwords don't match");

      handleHideToast();
      return;
    }

    if (inputs.firstName.value.length < 3 || inputs.lastName.value.length < 3) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('More Than 3 Characters Needed For User Name.');

      handleHideToast();
      return;
    }

    completeInvitationMutation({
      variables: {
        input: {
          email: email,
          firstName: inputs.firstName.value,
          lastName: inputs.lastName.value,
          password: inputs.password.value,
          invitationToken,
        },
      },
    });
  };

  const [completeInvitationMutation, { loading }] = useMutation(
    COMPLETE_INVITATION_MUTATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Profile Created Successfully');
        handleReset();
        handleHideToast();

        setTimeout(() => {
          history.push('/login');
        }, 1000);
      },

      onError(error) {
        data.showToast.setValue(true);
        data.status.setValue('Failed');
        data.content.setValue(trimGraphQLError(error.message));
        handleHideToast();
      },
    },
  );

  return render({
    handleHideToast,
    onSubmit: e => handleCompleteInvitation(e, completeInvitationMutation),
    form: inputs,
    data,
    loading,
    email,
    invitedBy,
  });
};

export default withRouter(useCompleteInvitation);
