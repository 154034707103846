import React, { Fragment } from 'react';
import {
  Button,
  Loader,
  Modal,
  Notification,
  TextArea,
  Toast,
} from '../../../../components';
import { Close, CompleteIcon } from '../../../../components/Icons';

const ReviewDoc = ({
  reviewSupportingDocData,
  handleModalReviewDocToggle,
  handleReviewSupportingDocument,
  handleHideReviewDocToast,
  reviewDocLoader,
}) => {
  return (
    <Fragment>
      {reviewDocLoader ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          <Toast
            title="Review Supporting Document"
            content={reviewSupportingDocData.content.value}
            clickEvent={handleHideReviewDocToast}
            active={reviewSupportingDocData.showToast.value}
            classes={reviewSupportingDocData.status.value}
          />
          {reviewSupportingDocData.showNotificationPopup.value && (
            <Notification>
              <div className="notification_icon-holder">
                <CompleteIcon />
              </div>
              <h5 className={'center-text'}>
                {
                  'The customer has been notified of your request for new documents'
                }
              </h5>
            </Notification>
          )}
          <Modal
            title="Review Document"
            active={reviewSupportingDocData.showModal.value}
            classes="new-document-modal"
          >
            <Close
              className="close-icon"
              onClick={handleModalReviewDocToggle}
            />
            <p className="new-doc-subtext">
              If there’s an error in the document, drop a comment requesting for
              a new document from your customer
            </p>
            <TextArea label="Message:" rows={7} />
            <Button
              classes="secondary center send-req"
              click_event={handleReviewSupportingDocument}
            >
              Send Request
            </Button>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ReviewDoc;
