import { gql } from 'apollo-boost';

export const GET_REPAYMENTBREAKDOWN_QUERY = gql`
  query MP_GetRepaymentBreakdown(
    $policyId: ID!
    $applicationId: ID!
    $principalAmount: Float!
    $duration: Int!
    $moratoriumPeriod: Int
  ) {
    getRepaymentBreakdown(
      input: {
        policyId: $policyId
        applicationId: $applicationId
        principalAmount: $principalAmount
        duration: $duration
        moratoriumPeriod: $moratoriumPeriod
      }
    ) {
      principalAmount
      duration
      totalInterest
      rateInDecimal
      repaymentFrequency
      repaymentBreakdown {
        principalBalance
        expectedPayment
        interestPortion
        principalPortion
        endingBalance
        dueDate
      }
    }
  }
`;
