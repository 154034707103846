import React, { Fragment } from 'react';
import { Button, Toast, Loader } from '../../../../components/index';
import { AdvanceApplicationWorkFlowStep } from '../../../../Providers/index';

const AdvanceApplicationWorkFlow = ({ portfolioData }) => {
  return (
    <Fragment>
      <div className="manual-approval">
        <AdvanceApplicationWorkFlowStep
          application={portfolioData.portfolio.application}
          portfolio={portfolioData.portfolio}
          shouldReload={true}
          render={({
            input,
            completeReviewLoader,
            handleCompleteApplication,
            handleHideToast,
          }) => (
            <Fragment>
              {completeReviewLoader && <Loader loadingText="loading..." />}
              <Toast
                title="Disburse Loan"
                content={input.content.value}
                classes={input.status.value}
                active={input.showToast.value}
                clickEvent={handleHideToast}
              />
              {portfolioData.portfolio.application &&
              portfolioData.portfolio.application.status &&
              portfolioData.portfolio.application.reviewFeedbacks &&
              portfolioData.portfolio.application.reviewFeedbacks.find(
                feedback =>
                  feedback.reviewType === 'OFFER_LETTER_REQUEST' &&
                  feedback.status === 'PENDING',
              ) ? (
                ''
              ) : (
                <Button
                  classes="right secondary"
                  click_event={handleCompleteApplication}
                >
                  Disburse Loan
                </Button>
              )}
            </Fragment>
          )}
        />
      </div>
    </Fragment>
  );
};

export default AdvanceApplicationWorkFlow;
