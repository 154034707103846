import { useMutation } from '@apollo/react-hooks';
import { DISABLE_LOAN_CATEGORY, GET_LOAN_CATEGORIES } from './queries';
import { useKeyPair } from '../Form';
import { useCustomState } from '../../lib/CustomHooks';
import { trimGraphQLError } from '../../lib/Utils';

const useMutateDisableLoanCategory = () => {
  const disableLoanCategoryData = {
    showToast: useKeyPair(false),
    showDisableLoanGroupPopup: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
    selectedLoanGroupData: useCustomState({}),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      disableLoanCategoryData.showToast.setValue(false);
    }, 5000);
  };

  const [disableLoanCategory, { loading: disableLoanCategoryLoading }] =
    useMutation(DISABLE_LOAN_CATEGORY, {
      onCompleted: () => {
        disableLoanCategoryData.showToast.setValue(true);
        disableLoanCategoryData.status.setValue('Success');
        disableLoanCategoryData.content.setValue('Loan Category Disabled');
        disableLoanCategoryData.showDisableLoanGroupPopup.setValue(false);

        handleHideToast();
      },
      onError: error => {
        disableLoanCategoryData.showToast.setValue(true);
        disableLoanCategoryData.status.setValue('Failed');
        disableLoanCategoryData.content.setValue(
          trimGraphQLError(error.message),
        );

        handleHideToast();
        handleHideDisableLoanGroupToggle();
      },
    });

  const handleHideDisableLoanGroupToggle = async (e, data = {}) => {
    disableLoanCategoryData.showDisableLoanGroupPopup.setValue(
      !disableLoanCategoryData.showDisableLoanGroupPopup.value,
    );
    disableLoanCategoryData.selectedLoanGroupData.setValue(data);
  };

  const handleDisableLoanGroup = async (e, categoryData = {}) => {
    e.preventDefault();

    let products = [];
    categoryData &&
      categoryData.products.map(item => products.push({ id: item.id }));

    if (!(categoryData && categoryData.id)) {
      disableLoanCategoryData.showToast.setValue(true);
      handleHideToast();
      return;
    }

    await disableLoanCategory({
      variables: {
        input: {
          loanCategoryId: categoryData.id,
        },
      },
      refetchQueries: () => [
        {
          query: GET_LOAN_CATEGORIES,
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return {
    handleLoanGroupDisableToast: handleHideToast,
    disableLoanCategoryLoading,
    disableLoanCategoryData,
    handleHideDisableLoanGroupToggle,
    handleDisableLoanGroup: (e, categoryData) =>
      handleDisableLoanGroup(e, categoryData),
  };
};

export { useMutateDisableLoanCategory };
