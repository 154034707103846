import { gql } from 'apollo-boost';

export const CLIENT_INFO_QUERY = gql`
  query MP_ClientInfoQuery(
    $slug: String
    $clientUrl: String
    $loanCategoryId: ID
  ) {
    clientInfo(
      input: {
        slug: $slug
        clientUrl: $clientUrl
        loanCategoryId: $loanCategoryId
      }
    ) {
      clientId
      name
      slug
      logoUrl
      clientAppUrl
      secretKey
      faviconUrl
      loanOfferLetterFeatureIsEnabled
      loanAndApplicationServiceEnabled
      requiresExternalDisbursement
      tableauURL
      requiredSupportingDocuments {
        description
        documentName
      }
      requiresOkraTransactions
      requiresCrcReport
      requiresCrcMicroReport
    }
  }
`;
