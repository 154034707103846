import { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_RULE_SET_MUTATION, RULE_SET_UPDATE_MUTATION } from './queries';
import { FormInput, useKeyPair } from '../Form';
import { trimGraphQLError, removeCommas } from '../../lib/Utils';
import {
  approvedChkBoxStatuses,
  underReviewChkBoxStatuses,
  approvedFirstCentralCheckBoxStatuses,
  underReviewFirstCentralCheckBoxStatuses,
  clientRulesInput,
  approvedCrcCheckBoxStatuses,
  underReviewCrcCheckBoxStatuses,
  approvedBlacklistStatusCheckbox,
  underReviewBlacklistStatusCheckbox,
  approvedCreditRegistryCheckboxStatuses,
  underReviewCreditRegistryCheckboxStatuses,
  approvedBvnNinMatchCheckboxStatuses,
  underReviewBvnNinMatchCheckboxStatuses,
} from './constants';
import { useHistory } from 'react-router-dom';
import { PRIVATE_CLIENT_INFO_QUERY } from '../Client/queries';
import { clientIdentifier } from '../../Providers/OriginateClient';

let loanRuleInView;

const useMutateCreateCustomRule = ({
  ruleId,
  loanRuleList = [],
  rmvApproveRules = [],
  rmvUnderReviewRules = [],
}) => {
  let rulesetId = loanRuleList.find(item => item.name === ruleId);

  const history = useHistory();

  const data = {
    isEditMode: useKeyPair(!!ruleId),
    showToast: useKeyPair(false),
    showNotificationPopup: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const approvedEmploymentStatuses = {};
  approvedChkBoxStatuses.forEach(p => {
    approvedEmploymentStatuses[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const underReviewEmploymentStatuses = {};
  underReviewChkBoxStatuses.forEach(p => {
    underReviewEmploymentStatuses[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const approvedFirstCentralDelinquency = {};
  approvedFirstCentralCheckBoxStatuses.forEach(p => {
    approvedFirstCentralDelinquency[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const approvedCrcDelinquency = {};
  approvedCrcCheckBoxStatuses.forEach(p => {
    approvedCrcDelinquency[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const underReviewFirstCentralDelinquency = {};
  underReviewFirstCentralCheckBoxStatuses.forEach(p => {
    underReviewFirstCentralDelinquency[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const underReviewCrcDelinquency = {};
  underReviewCrcCheckBoxStatuses.forEach(p => {
    underReviewCrcDelinquency[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const approvedBlacklistStatuses = {};
  approvedBlacklistStatusCheckbox.forEach(p => {
    approvedBlacklistStatuses[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const underReviewBlacklistStatuses = {};
  underReviewBlacklistStatusCheckbox.forEach(p => {
    underReviewBlacklistStatuses[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const approvedCreditRegistryDelinquency = {};
  approvedCreditRegistryCheckboxStatuses.forEach(p => {
    approvedCreditRegistryDelinquency[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const underReviewCreditRegistryDelinquency = {};
  underReviewCreditRegistryCheckboxStatuses.forEach(p => {
    underReviewCreditRegistryDelinquency[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const approvedBvnNinMatch = {};
  approvedBvnNinMatchCheckboxStatuses.forEach(p => {
    approvedBvnNinMatch[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const underReviewBvnNinMatch = {};
  underReviewBvnNinMatchCheckboxStatuses.forEach(p => {
    underReviewBvnNinMatch[p.id] = FormInput(false, {
      type: 'checkbox',
    });
  });

  const approvedInputs = {};
  const underReviewInputs = {};

  // eslint-disable-next-line array-callback-return
  clientRulesInput.map(item => {
    if (
      item !== 'employmentStatuses' &&
      item !== 'isWorkEmailConfirmed' &&
      item !== 'bankHasMbsSupport' &&
      item !== 'isPerforming' &&
      item !== 'states' &&
      item !== 'inflowOutFlowRateBSP' &&
      item !== 'accountSweepBSP' &&
      item !== 'salaryEarnerBSP'
    ) {
      approvedInputs[item] = FormInput();
      underReviewInputs[item] = FormInput();
    }
  });

  const inputs = {
    name: FormInput(''),
    description: FormInput(''),
    status: 'DRAFT',
    approved: { ...approvedInputs },
    underReview: { ...underReviewInputs },
  };

  useEffect(() => {
    if (ruleId) {
      loanRuleInView = loanRuleList.find(item => item.name === ruleId);
      const { approve, manualReview } = loanRuleInView;

      inputs.name.setValue(loanRuleInView.name);

      if (approve.length > 0) {
        // eslint-disable-next-line array-callback-return
        approve.map(item => {
          const { data } = item;
          // eslint-disable-next-line array-callback-return
          Object.keys(data).map(idx => {
            if (idx === 'employmentStatuses') {
              approvedChkBoxStatuses.forEach(p => {
                approvedEmploymentStatuses[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'firstCentralDelinquency') {
              approvedFirstCentralCheckBoxStatuses.forEach(p => {
                approvedFirstCentralDelinquency[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'crcDelinquency') {
              approvedCrcCheckBoxStatuses.forEach(p => {
                approvedCrcDelinquency[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'blacklistStatus') {
              approvedBlacklistStatusCheckbox.forEach(p => {
                approvedBlacklistStatuses[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'creditRegistry') {
              approvedCreditRegistryCheckboxStatuses.forEach(p => {
                approvedCreditRegistryDelinquency[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'bvnNinMatch') {
              approvedBvnNinMatchCheckboxStatuses.forEach(p => {
                approvedBvnNinMatch[p.id].setValue(data[idx].includes(p.value));
              });
            }

            if (inputs.approved[idx]) inputs.approved[idx].setValue(data[idx]);
          });
        });
      }

      if (manualReview.length > 0) {
        // eslint-disable-next-line array-callback-return
        manualReview.map(item => {
          const { data } = item;

          // eslint-disable-next-line array-callback-return
          Object.keys(data).map(idx => {
            if (idx === 'employmentStatuses') {
              underReviewChkBoxStatuses.forEach(p => {
                underReviewEmploymentStatuses[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'firstCentralDelinquency') {
              underReviewFirstCentralCheckBoxStatuses.forEach(p => {
                underReviewFirstCentralDelinquency[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'crcDelinquency') {
              underReviewCrcCheckBoxStatuses.forEach(p => {
                underReviewCrcDelinquency[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'blacklistStatus') {
              underReviewBlacklistStatusCheckbox.forEach(p => {
                underReviewBlacklistStatuses[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'creditRegistry') {
              underReviewCreditRegistryCheckboxStatuses.forEach(p => {
                underReviewCreditRegistryDelinquency[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (idx === 'bvnNinMatch') {
              underReviewBvnNinMatchCheckboxStatuses.forEach(p => {
                underReviewBvnNinMatch[p.id].setValue(
                  data[idx].includes(p.value),
                );
              });
            }

            if (inputs.underReview[idx])
              inputs.underReview[idx].setValue(data[idx]);
          });
        });
      }
    }
  }, [ruleId]);

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 5000);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const getStructuredData = data => {
    const response = [];
    if (data && data.length >= 1) {
      data.map(item => response.push(item.value));
    }

    return response;
  };

  const getStatusesData = () => {
    let statuses = {
      approved: [],
      manualReview: [],
    };

    // eslint-disable-next-line array-callback-return
    underReviewChkBoxStatuses.map(item => {
      if (underReviewEmploymentStatuses[item.id].input.checked) {
        statuses.manualReview.push(item.value);
      }
    });

    // eslint-disable-next-line array-callback-return
    approvedChkBoxStatuses.map(item => {
      if (approvedEmploymentStatuses[item.id].input.checked) {
        statuses.approved.push(item.value);
      }
    });
    return statuses;
  };

  const getBlacklistStatusData = () => {
    let blacklistStatus = {
      approved: [],
      manualReview: [],
    };

    // eslint-disable-next-line array-callback-return
    underReviewBlacklistStatusCheckbox.map(item => {
      if (underReviewBlacklistStatuses[item.id].input.checked) {
        blacklistStatus.manualReview.push(item.value);
      }
    });

    // eslint-disable-next-line array-callback-return
    approvedBlacklistStatusCheckbox.map(item => {
      if (approvedBlacklistStatuses[item.id].input.checked) {
        blacklistStatus.approved.push(item.value);
      }
    });
    return blacklistStatus;
  };

  const getCrcData = () => {
    let statuses = {
      approved: [],
      manualReview: [],
    };

    // eslint-disable-next-line array-callback-return
    underReviewCrcCheckBoxStatuses.map(item => {
      if (underReviewCrcDelinquency[item.id].input.checked) {
        statuses.manualReview.push(item.value);
      }
    });

    // eslint-disable-next-line array-callback-return
    approvedCrcCheckBoxStatuses.map(item => {
      if (approvedCrcDelinquency[item.id].input.checked) {
        statuses.approved.push(item.value);
      }
    });

    return statuses;
  };

  const getCreditRegistryData = () => {
    let statuses = {
      approved: [],
      manualReview: [],
    };

    // eslint-disable-next-line array-callback-return
    underReviewCreditRegistryCheckboxStatuses.map(item => {
      if (underReviewCreditRegistryDelinquency[item.id].input.checked) {
        statuses.manualReview.push(item.value);
      }
    });

    // eslint-disable-next-line array-callback-return
    approvedCreditRegistryCheckboxStatuses.map(item => {
      if (approvedCreditRegistryDelinquency[item.id].input.checked) {
        statuses.approved.push(item.value);
      }
    });

    return statuses;
  };

  const getFirstCentralData = () => {
    let statuses = {
      approved: [],
      manualReview: [],
    };

    // eslint-disable-next-line array-callback-return
    underReviewFirstCentralCheckBoxStatuses.map(item => {
      if (underReviewFirstCentralDelinquency[item.id].input.checked) {
        statuses.manualReview.push(item.value);
      }
    });

    // eslint-disable-next-line array-callback-return
    approvedFirstCentralCheckBoxStatuses.map(item => {
      if (approvedFirstCentralDelinquency[item.id].input.checked) {
        statuses.approved.push(item.value);
      }
    });

    return statuses;
  };

  const getBvnNinMatchData = () => {
    let statuses = {
      approved: [],
      manualReview: [],
    };

    // eslint-disable-next-line array-callback-return
    underReviewBvnNinMatchCheckboxStatuses.map(item => {
      if (underReviewBvnNinMatch[item.id].input.checked) {
        statuses.manualReview.push(item.value);
      }
    });

    // eslint-disable-next-line array-callback-return
    approvedBvnNinMatchCheckboxStatuses.map(item => {
      if (approvedBvnNinMatch[item.id].input.checked) {
        statuses.approved.push(item.value);
      }
    });

    return statuses;
  };

  // To Do: refactor the approve and manualReview section to dynamically load and fetch response
  const getMutationVariables = params => {
    const {
      workVerificationStatus,
      loanProductList,
      prefilledLoanProductList,
      prefilledStatesApp,
      prefilledStatesUnr,
      bankHasMbsSupportStatus,
      sweepStatus,
      inflowOutFlowRateStatus,
      salaryEarnerBSPStatus,
      pdfBankStatementDetailsMatchStatus,
    } = params;
    const { approved, underReview } = inputs;
    const statusesData = getStatusesData(params);
    const blacklistStatusData = getBlacklistStatusData(params);
    const firstCentralData = getFirstCentralData(params);
    const isEditMode = !!ruleId;
    const crcDelinquencyData = getCrcData(params);
    const creditRegistryDelinquencyData = getCreditRegistryData(params);
    const bvnNinMatchData = getBvnNinMatchData(params);

    const data = {
      ruleSetId: isEditMode ? rulesetId.id : undefined,
      name: inputs.name.value,
      status: isEditMode ? undefined : 'DRAFT',
      description: inputs.name.value,
      policyIds: getStructuredData(
        ruleId ? prefilledLoanProductList : loanProductList,
      ),
      approve: {
        ...(approved.minAge && {
          minAge: parseInt(
            removeCommas(approved.minAge ? approved.minAge.value : null),
          ),
        }),
        ...(approved.maxAge && {
          maxAge: parseInt(
            removeCommas(approved.maxAge ? approved.maxAge.value : null),
          ),
        }),
        employmentStatuses:
          statusesData.approved.length >= 1 ? statusesData.approved : [],
        blacklistStatus:
          blacklistStatusData.approved.length >= 1
            ? blacklistStatusData.approved
            : [],
        states: getStructuredData(prefilledStatesApp),
        ...(approved.minDTIRatio && {
          minDTIRatio: parseInt(
            removeCommas(
              approved.minDTIRatio ? approved.minDTIRatio.value : null,
            ),
          ),
        }),

        creditRegistry:
          creditRegistryDelinquencyData.approved.length >= 1
            ? creditRegistryDelinquencyData.approved
            : [],

        bvnNinMatch:
          bvnNinMatchData.approved.length >= 1 ? bvnNinMatchData.approved : [],

        firstCentralDelinquency:
          firstCentralData.approved.length >= 1
            ? firstCentralData.approved
            : [],
        crcDelinquency:
          crcDelinquencyData.approved.length >= 1
            ? crcDelinquencyData.approved
            : [],
        minDTIRatio: parseFloat(
          removeCommas(
            approved.minDTIRatio ? approved.minDTIRatio.value : null,
          ),
        ),
        maxDTIRatio: parseFloat(
          removeCommas(
            approved.maxDTIRatio ? approved.maxDTIRatio.value : null,
          ),
        ),
        minDTIRatioBs: parseFloat(
          removeCommas(
            approved.minDTIRatioBs ? approved.minDTIRatioBs.value : null,
          ),
        ),
        maxDTIRatioBs: parseFloat(
          removeCommas(
            approved.maxDTIRatioBs ? approved.maxDTIRatioBs.value : null,
          ),
        ),
        minNetIncome: parseFloat(
          removeCommas(
            approved.minNetIncome ? approved.minNetIncome.value : null,
          ),
        ),
        maxNetIncome: parseFloat(
          removeCommas(
            approved.maxNetIncome ? approved.maxNetIncome.value : null,
          ),
        ),
        minNetIncomeBS: parseFloat(
          removeCommas(
            approved.minNetIncomeBS ? approved.minNetIncomeBS.value : null,
          ),
        ),
        minLoanAmount: parseFloat(
          removeCommas(
            approved.minLoanAmount ? approved.minLoanAmount.value : null,
          ),
        ),
        maxLoanAmount: parseFloat(
          removeCommas(
            approved.maxLoanAmount ? approved.maxLoanAmount.value : null,
          ),
        ),
        minLoanDuration: parseFloat(
          removeCommas(
            approved.minLoanDuration ? approved.minLoanDuration.value : null,
          ),
        ),
        maxLoanDuration: parseFloat(
          removeCommas(
            approved.maxLoanDuration ? approved.maxLoanDuration.value : null,
          ),
        ),
        minAccountsInBadStanding: parseInt(
          removeCommas(
            approved.minAccountsInBadStanding
              ? approved.minAccountsInBadStanding.value
              : null,
          ),
        ),
        maxAccountsInBadStanding: parseInt(
          removeCommas(
            approved.maxAccountsInBadStanding
              ? approved.maxAccountsInBadStanding.value
              : null,
          ),
        ),
        minTenorBs: parseInt(
          removeCommas(approved.minTenorBs ? approved.minTenorBs.value : null),
        ),
        minTotalDebitBs: parseFloat(
          removeCommas(
            approved.minTotalDebitBs ? approved.minTotalDebitBs.value : null,
          ),
        ),
        maxOTIRatioBs: parseInt(
          removeCommas(
            approved.maxOTIRatioBs ? approved.maxOTIRatioBs.value : null,
          ),
        ),
        maxPercentageBettingBS: parseInt(
          removeCommas(
            approved.maxPercentageBettingBS
              ? approved.maxPercentageBettingBS.value
              : null,
          ),
        ),
        ...(workVerificationStatus.approved && {
          isWorkEmailConfirmed:
            workVerificationStatus.approved === 'yes'
              ? true
              : workVerificationStatus.approved === 'no'
              ? false
              : undefined,
        }),
        ...(bankHasMbsSupportStatus.approved && {
          bankHasMbsSupport:
            bankHasMbsSupportStatus.approved === 'yes'
              ? true
              : bankHasMbsSupportStatus.approved === 'no'
              ? false
              : undefined,
        }),
        ...(pdfBankStatementDetailsMatchStatus.approved && {
          pdfBankStatementDetailsMatch:
            pdfBankStatementDetailsMatchStatus.approved === 'yes'
              ? true
              : pdfBankStatementDetailsMatchStatus.approved === 'no'
              ? false
              : undefined,
        }),
        minAverageSalaryBSP: parseFloat(
          removeCommas(
            approved.minAverageSalaryBSP
              ? approved.minAverageSalaryBSP.value
              : null,
          ),
        ),
        maxAverageSalaryBSP: parseFloat(
          removeCommas(
            approved.maxAverageSalaryBSP
              ? approved.maxAverageSalaryBSP.value
              : null,
          ),
        ),
        minAverageOtherIncomeBSP: parseFloat(
          removeCommas(
            approved.minAverageOtherIncomeBSP
              ? approved.minAverageOtherIncomeBSP.value
              : null,
          ),
        ),
        maxAverageOtherIncomeBSP: parseFloat(
          removeCommas(
            approved.maxAverageOtherIncomeBSP
              ? approved.maxAverageOtherIncomeBSP.value
              : null,
          ),
        ),
        ...(approved.minNumberOfSalaryPaymentsBSP && {
          minNumberOfSalaryPaymentsBSP: parseFloat(
            removeCommas(
              approved.minNumberOfSalaryPaymentsBSP
                ? approved.minNumberOfSalaryPaymentsBSP.value
                : null,
            ),
          ),
        }),
        ...(approved.maxNumberOfSalaryPaymentsBSP && {
          maxNumberOfSalaryPaymentsBSP: parseFloat(
            removeCommas(
              approved.maxNumberOfSalaryPaymentsBSP
                ? approved.maxNumberOfSalaryPaymentsBSP.value
                : null,
            ),
          ),
        }),
        ...(approved.minNumberOfOtherIncomePaymentsBSP && {
          minNumberOfOtherIncomePaymentsBSP: parseFloat(
            removeCommas(
              approved.minNumberOfOtherIncomePaymentsBSP
                ? approved.minNumberOfOtherIncomePaymentsBSP.value
                : null,
            ),
          ),
        }),
        ...(approved.maxNumberOfOtherIncomePaymentsBSP && {
          maxNumberOfOtherIncomePaymentsBSP: parseFloat(
            removeCommas(
              approved.maxNumberOfOtherIncomePaymentsBSP
                ? approved.maxNumberOfOtherIncomePaymentsBSP.value
                : null,
            ),
          ),
        }),
        minAverageBalanceBSP: parseFloat(
          removeCommas(
            approved.minAverageBalanceBSP
              ? approved.minAverageBalanceBSP.value
              : null,
          ),
        ),
        maxAverageBalanceBSP: parseFloat(
          removeCommas(
            approved.maxAverageBalanceBSP
              ? approved.maxAverageBalanceBSP.value
              : null,
          ),
        ),
        minNetAverageMonthlyEarningsBSP: parseFloat(
          removeCommas(
            approved.minNetAverageMonthlyEarningsBSP
              ? approved.minNetAverageMonthlyEarningsBSP.value
              : null,
          ),
        ),
        maxNetAverageMonthlyEarningsBSP: parseFloat(
          removeCommas(
            approved.maxNetAverageMonthlyEarningsBSP
              ? approved.maxNetAverageMonthlyEarningsBSP.value
              : null,
          ),
        ),
        minTotalExpensesBSP: parseFloat(
          removeCommas(
            approved.minTotalExpensesBSP
              ? approved.minTotalExpensesBSP.value
              : null,
          ),
        ),
        maxTotalExpensesBSP: parseFloat(
          removeCommas(
            approved.maxTotalExpensesBSP
              ? approved.maxTotalExpensesBSP.value
              : null,
          ),
        ),
        ...(inflowOutFlowRateStatus.approved && {
          isCashFlowPositiveBSP:
            inflowOutFlowRateStatus.approved === 'yes'
              ? true
              : inflowOutFlowRateStatus.approved === 'no'
              ? false
              : undefined,
        }),
        minLoanAmountBSP: parseFloat(
          removeCommas(
            approved.minLoanAmountBSP ? approved.minLoanAmountBSP.value : null,
          ),
        ),
        maxLoanAmountBSP: parseFloat(
          removeCommas(
            approved.maxLoanAmountBSP ? approved.maxLoanAmountBSP.value : null,
          ),
        ),
        minLoanRepaymentsBSP: parseFloat(
          removeCommas(
            approved.minLoanRepaymentsBSP
              ? approved.minLoanRepaymentsBSP.value
              : null,
          ),
        ),
        maxLoanRepaymentsBSP: parseFloat(
          removeCommas(
            approved.maxLoanRepaymentsBSP
              ? approved.maxLoanRepaymentsBSP.value
              : null,
          ),
        ),
        minLoanToInflowRate: parseFloat(
          removeCommas(
            approved.minLoanToInflowRate
              ? approved.minLoanToInflowRate.value
              : null,
          ),
        ),
        maxLoanToInflowRate: parseFloat(
          removeCommas(
            approved.maxLoanToInflowRate
              ? approved.maxLoanToInflowRate.value
              : null,
          ),
        ),
        minLoanRepaymentToInflowRate: parseFloat(
          removeCommas(
            approved.minLoanRepaymentToInflowRate
              ? approved.minLoanRepaymentToInflowRate.value
              : null,
          ),
        ),
        maxLoanRepaymentToInflowRate: parseFloat(
          removeCommas(
            approved.maxLoanRepaymentToInflowRate
              ? approved.maxLoanRepaymentToInflowRate.value
              : null,
          ),
        ),
        minGamblingRateBSP: parseFloat(
          removeCommas(
            approved.minGamblingRateBSP
              ? approved.minGamblingRateBSP.value
              : null,
          ),
        ),
        maxGamblingRateBSP: parseFloat(
          removeCommas(
            approved.maxGamblingRateBSP
              ? approved.maxGamblingRateBSP.value
              : null,
          ),
        ),
        ...(sweepStatus.approved && {
          accountSweepBSP:
            sweepStatus.approved === 'yes'
              ? true
              : sweepStatus.approved === 'no'
              ? false
              : undefined,
        }),
        minTotalCreditTurnoverBSP: parseFloat(
          removeCommas(
            approved.minTotalCreditTurnoverBSP
              ? approved.minTotalCreditTurnoverBSP.value
              : null,
          ),
        ),
        maxTotalCreditTurnoverBSP: parseFloat(
          removeCommas(
            approved.maxTotalCreditTurnoverBSP
              ? approved.maxTotalCreditTurnoverBSP.value
              : null,
          ),
        ),
        minTotalDebitTurnoverBSP: parseFloat(
          removeCommas(
            approved.minTotalDebitTurnoverBSP
              ? approved.minTotalDebitTurnoverBSP.value
              : null,
          ),
        ),
        maxTotalDebitTurnoverBSP: parseFloat(
          removeCommas(
            approved.maxTotalDebitTurnoverBSP
              ? approved.maxTotalDebitTurnoverBSP.value
              : null,
          ),
        ),
        minAverageMonthlyCreditsBSP: parseFloat(
          removeCommas(
            approved.minAverageMonthlyCreditsBSP
              ? approved.minAverageMonthlyCreditsBSP.value
              : null,
          ),
        ),
        maxAverageMonthlyCreditsBSP: parseFloat(
          removeCommas(
            approved.maxAverageMonthlyCreditsBSP
              ? approved.maxAverageMonthlyCreditsBSP.value
              : null,
          ),
        ),
        minAverageMonthlyDebitsBSP: parseFloat(
          removeCommas(
            approved.minAverageMonthlyDebitsBSP
              ? approved.minAverageMonthlyDebitsBSP.value
              : null,
          ),
        ),
        maxAverageMonthlyDebitsBSP: parseFloat(
          removeCommas(
            approved.maxAverageMonthlyDebitsBSP
              ? approved.maxAverageMonthlyDebitsBSP.value
              : null,
          ),
        ),
        minBalanceAtStatementEndBSP: parseFloat(
          removeCommas(
            approved.minBalanceAtStatementEndBSP
              ? approved.minBalanceAtStatementEndBSP.value
              : null,
          ),
        ),
        maxBalanceAtStatementEndBSP: parseFloat(
          removeCommas(
            approved.maxBalanceAtStatementEndBSP
              ? approved.maxBalanceAtStatementEndBSP.value
              : null,
          ),
        ),
        minDTIRatioBSP: parseFloat(
          removeCommas(
            approved.minDTIRatioBSP ? approved.minDTIRatioBSP.value : null,
          ),
        ),
        maxDTIRatioBSP: parseFloat(
          removeCommas(
            approved.maxDTIRatioBSP ? approved.maxDTIRatioBSP.value : null,
          ),
        ),
        ...(salaryEarnerBSPStatus.approved && {
          salaryEarnerBSP:
            salaryEarnerBSPStatus.approved === 'yes'
              ? true
              : salaryEarnerBSPStatus.approved === 'no'
              ? false
              : undefined,
        }),
      },
      manualReview: {
        minAge: parseInt(
          removeCommas(underReview.minAge ? underReview.minAge.value : null),
        ),
        maxAge: parseInt(
          removeCommas(underReview.maxAge ? underReview.maxAge.value : null),
        ),
        states: getStructuredData(prefilledStatesUnr),
        employmentStatuses:
          statusesData.manualReview.length >= 1
            ? statusesData.manualReview
            : [],

        creditRegistry:
          creditRegistryDelinquencyData.manualReview.length >= 1
            ? creditRegistryDelinquencyData.manualReview
            : [],

        bvnNinMatch:
          bvnNinMatchData.manualReview.length >= 1
            ? bvnNinMatchData.manualReview
            : [],

        blacklistStatus:
          blacklistStatusData.manualReview.length >= 1
            ? blacklistStatusData.manualReview
            : [],

        firstCentralDelinquency:
          firstCentralData.manualReview.length >= 1
            ? firstCentralData.manualReview
            : [],

        crcDelinquency:
          crcDelinquencyData.manualReview.length >= 1
            ? crcDelinquencyData.manualReview
            : [],

        maxDTIRatio: parseFloat(
          removeCommas(
            underReview.maxDTIRatio ? underReview.maxDTIRatio.value : null,
          ),
        ),
        minDTIRatio: parseFloat(
          removeCommas(
            underReview.minDTIRatio ? underReview.minDTIRatio.value : null,
          ),
        ),
        minDTIRatioBs: parseFloat(
          removeCommas(
            underReview.minDTIRatioBs ? underReview.minDTIRatioBs.value : null,
          ),
        ),
        maxDTIRatioBs: parseFloat(
          removeCommas(
            underReview.maxDTIRatioBs ? underReview.maxDTIRatioBs.value : null,
          ),
        ),
        minNetIncome: parseFloat(
          removeCommas(
            underReview.minNetIncome ? underReview.minNetIncome.value : null,
          ),
        ),
        maxNetIncome: parseFloat(
          removeCommas(
            underReview.maxNetIncome ? underReview.maxNetIncome.value : null,
          ),
        ),
        minNetIncomeBS: parseFloat(
          removeCommas(
            underReview.minNetIncomeBS
              ? underReview.minNetIncomeBS.value
              : null,
          ),
        ),
        minLoanAmount: parseFloat(
          removeCommas(
            underReview.minLoanAmount ? underReview.minLoanAmount.value : null,
          ),
        ),
        maxLoanAmount: parseFloat(
          removeCommas(
            underReview.maxLoanAmount ? underReview.maxLoanAmount.value : null,
          ),
        ),
        minLoanDuration: parseFloat(
          removeCommas(
            underReview.minLoanDuration
              ? underReview.minLoanDuration.value
              : null,
          ),
        ),
        maxLoanDuration: parseFloat(
          removeCommas(
            underReview.maxLoanDuration
              ? underReview.maxLoanDuration.value
              : null,
          ),
        ),
        minAccountsInBadStanding: parseInt(
          removeCommas(
            underReview.minAccountsInBadStanding
              ? underReview.minAccountsInBadStanding.value
              : null,
          ),
        ),
        maxAccountsInBadStanding: parseInt(
          removeCommas(
            underReview.maxAccountsInBadStanding
              ? underReview.maxAccountsInBadStanding.value
              : null,
          ),
        ),
        minTenorBs: parseInt(
          removeCommas(
            underReview.minTenorBs ? underReview.minTenorBs.value : null,
          ),
        ),
        minTotalDebitBs: parseFloat(
          removeCommas(
            underReview.minTotalDebitBs
              ? underReview.minTotalDebitBs.value
              : null,
          ),
        ),
        maxOTIRatioBs: parseInt(
          removeCommas(
            underReview.maxOTIRatioBs ? underReview.maxOTIRatioBs.value : null,
          ),
        ),
        maxPercentageBettingBS: parseInt(
          removeCommas(
            underReview.maxPercentageBettingBS
              ? underReview.maxPercentageBettingBS.value
              : null,
          ),
        ),
        ...(workVerificationStatus.underReview && {
          isWorkEmailConfirmed:
            workVerificationStatus.underReview === 'yes'
              ? true
              : workVerificationStatus.underReview === 'no'
              ? false
              : undefined,
        }),
        ...(bankHasMbsSupportStatus.underReview && {
          bankHasMbsSupport:
            bankHasMbsSupportStatus.underReview === 'yes'
              ? true
              : bankHasMbsSupportStatus.underReview === 'no'
              ? false
              : undefined,
        }),
        ...(pdfBankStatementDetailsMatchStatus.underReview && {
          pdfBankStatementDetailsMatch:
            pdfBankStatementDetailsMatchStatus.underReview === 'yes'
              ? true
              : pdfBankStatementDetailsMatchStatus.underReview === 'no'
              ? false
              : undefined,
        }),

        minAverageSalaryBSP: parseFloat(
          removeCommas(
            underReview.minAverageSalaryBSP
              ? underReview.minAverageSalaryBSP.value
              : null,
          ),
        ),
        maxAverageSalaryBSP: parseFloat(
          removeCommas(
            underReview.maxAverageSalaryBSP
              ? underReview.maxAverageSalaryBSP.value
              : null,
          ),
        ),
        minAverageOtherIncomeBSP: parseFloat(
          removeCommas(
            underReview.minAverageOtherIncomeBSP
              ? underReview.minAverageOtherIncomeBSP.value
              : null,
          ),
        ),
        maxAverageOtherIncomeBSP: parseFloat(
          removeCommas(
            underReview.maxAverageOtherIncomeBSP
              ? underReview.maxAverageOtherIncomeBSP.value
              : null,
          ),
        ),
        ...(underReview.minNumberOfSalaryPaymentsBSP && {
          minNumberOfSalaryPaymentsBSP: parseFloat(
            removeCommas(
              underReview.minNumberOfSalaryPaymentsBSP
                ? underReview.minNumberOfSalaryPaymentsBSP.value
                : null,
            ),
          ),
        }),
        ...(underReview.maxNumberOfSalaryPaymentsBSP && {
          maxNumberOfSalaryPaymentsBSP: parseFloat(
            removeCommas(
              underReview.maxNumberOfSalaryPaymentsBSP
                ? underReview.maxNumberOfSalaryPaymentsBSP.value
                : null,
            ),
          ),
        }),
        ...(underReview.minNumberOfOtherIncomePaymentsBSP && {
          minNumberOfOtherIncomePaymentsBSP: parseFloat(
            removeCommas(
              underReview.minNumberOfOtherIncomePaymentsBSP
                ? underReview.minNumberOfOtherIncomePaymentsBSP.value
                : null,
            ),
          ),
        }),
        ...(underReview.maxNumberOfOtherIncomePaymentsBSP && {
          maxNumberOfOtherIncomePaymentsBSP: parseFloat(
            removeCommas(
              underReview.maxNumberOfOtherIncomePaymentsBSP
                ? underReview.maxNumberOfOtherIncomePaymentsBSP.value
                : null,
            ),
          ),
        }),
        minAverageBalanceBSP: parseFloat(
          removeCommas(
            underReview.minAverageBalanceBSP
              ? underReview.minAverageBalanceBSP.value
              : null,
          ),
        ),
        maxAverageBalanceBSP: parseFloat(
          removeCommas(
            underReview.maxAverageBalanceBSP
              ? underReview.maxAverageBalanceBSP.value
              : null,
          ),
        ),
        minNetAverageMonthlyEarningsBSP: parseFloat(
          removeCommas(
            underReview.minNetAverageMonthlyEarningsBSP
              ? underReview.minNetAverageMonthlyEarningsBSP.value
              : null,
          ),
        ),
        maxNetAverageMonthlyEarningsBSP: parseFloat(
          removeCommas(
            underReview.maxNetAverageMonthlyEarningsBSP
              ? underReview.maxNetAverageMonthlyEarningsBSP.value
              : null,
          ),
        ),
        minTotalExpensesBSP: parseFloat(
          removeCommas(
            underReview.minTotalExpensesBSP
              ? underReview.minTotalExpensesBSP.value
              : null,
          ),
        ),
        maxTotalExpensesBSP: parseFloat(
          removeCommas(
            underReview.maxTotalExpensesBSP
              ? underReview.maxTotalExpensesBSP.value
              : null,
          ),
        ),
        ...(inflowOutFlowRateStatus.underReview && {
          isCashFlowPositiveBSP:
            inflowOutFlowRateStatus.underReview === 'yes'
              ? true
              : inflowOutFlowRateStatus.underReview === 'no'
              ? false
              : undefined,
        }),
        minLoanAmountBSP: parseFloat(
          removeCommas(
            underReview.minLoanAmountBSP
              ? underReview.minLoanAmountBSP.value
              : null,
          ),
        ),
        maxLoanAmountBSP: parseFloat(
          removeCommas(
            underReview.maxLoanAmountBSP
              ? underReview.maxLoanAmountBSP.value
              : null,
          ),
        ),
        minLoanRepaymentsBSP: parseFloat(
          removeCommas(
            underReview.minLoanRepaymentsBSP
              ? underReview.minLoanRepaymentsBSP.value
              : null,
          ),
        ),
        maxLoanRepaymentsBSP: parseFloat(
          removeCommas(
            underReview.maxLoanRepaymentsBSP
              ? underReview.maxLoanRepaymentsBSP.value
              : null,
          ),
        ),
        minLoanToInflowRate: parseFloat(
          removeCommas(
            underReview.minLoanToInflowRate
              ? underReview.minLoanToInflowRate.value
              : null,
          ),
        ),
        maxLoanToInflowRate: parseFloat(
          removeCommas(
            underReview.maxLoanToInflowRate
              ? underReview.maxLoanToInflowRate.value
              : null,
          ),
        ),
        minLoanRepaymentToInflowRate: parseFloat(
          removeCommas(
            underReview.minLoanRepaymentToInflowRate
              ? underReview.minLoanRepaymentToInflowRate.value
              : null,
          ),
        ),
        maxLoanRepaymentToInflowRate: parseFloat(
          removeCommas(
            underReview.maxLoanRepaymentToInflowRate
              ? underReview.maxLoanRepaymentToInflowRate.value
              : null,
          ),
        ),
        minGamblingRateBSP: parseFloat(
          removeCommas(
            underReview.minGamblingRateBSP
              ? underReview.minGamblingRateBSP.value
              : null,
          ),
        ),
        maxGamblingRateBSP: parseFloat(
          removeCommas(
            underReview.maxGamblingRateBSP
              ? underReview.maxGamblingRateBSP.value
              : null,
          ),
        ),
        ...(sweepStatus.underReview && {
          accountSweepBSP:
            sweepStatus.underReview === 'yes'
              ? true
              : sweepStatus.underReview === 'no'
              ? false
              : undefined,
        }),
        minTotalCreditTurnoverBSP: parseFloat(
          removeCommas(
            underReview.minTotalCreditTurnoverBSP
              ? underReview.minTotalCreditTurnoverBSP.value
              : null,
          ),
        ),
        maxTotalCreditTurnoverBSP: parseFloat(
          removeCommas(
            underReview.maxTotalCreditTurnoverBSP
              ? underReview.maxTotalCreditTurnoverBSP.value
              : null,
          ),
        ),
        minTotalDebitTurnoverBSP: parseFloat(
          removeCommas(
            underReview.minTotalDebitTurnoverBSP
              ? underReview.minTotalDebitTurnoverBSP.value
              : null,
          ),
        ),
        maxTotalDebitTurnoverBSP: parseFloat(
          removeCommas(
            underReview.maxTotalDebitTurnoverBSP
              ? underReview.maxTotalDebitTurnoverBSP.value
              : null,
          ),
        ),
        minAverageMonthlyCreditsBSP: parseFloat(
          removeCommas(
            underReview.minAverageMonthlyCreditsBSP
              ? underReview.minAverageMonthlyCreditsBSP.value
              : null,
          ),
        ),
        maxAverageMonthlyCreditsBSP: parseFloat(
          removeCommas(
            underReview.maxAverageMonthlyCreditsBSP
              ? underReview.maxAverageMonthlyCreditsBSP.value
              : null,
          ),
        ),
        minAverageMonthlyDebitsBSP: parseFloat(
          removeCommas(
            underReview.minAverageMonthlyDebitsBSP
              ? underReview.minAverageMonthlyDebitsBSP.value
              : null,
          ),
        ),
        maxAverageMonthlyDebitsBSP: parseFloat(
          removeCommas(
            underReview.maxAverageMonthlyDebitsBSP
              ? underReview.maxAverageMonthlyDebitsBSP.value
              : null,
          ),
        ),
        minBalanceAtStatementEndBSP: parseFloat(
          removeCommas(
            underReview.minBalanceAtStatementEndBSP
              ? underReview.minBalanceAtStatementEndBSP.value
              : null,
          ),
        ),
        maxBalanceAtStatementEndBSP: parseFloat(
          removeCommas(
            underReview.maxBalanceAtStatementEndBSP
              ? underReview.maxBalanceAtStatementEndBSP.value
              : null,
          ),
        ),
        minDTIRatioBSP: parseFloat(
          removeCommas(
            underReview.minDTIRatioBSP
              ? underReview.minDTIRatioBSP.value
              : null,
          ),
        ),
        maxDTIRatioBSP: parseFloat(
          removeCommas(
            underReview.maxDTIRatioBSP
              ? underReview.maxDTIRatioBSP.value
              : null,
          ),
        ),
        ...(salaryEarnerBSPStatus.underReview && {
          salaryEarnerBSP:
            salaryEarnerBSPStatus.underReview === 'yes'
              ? true
              : salaryEarnerBSPStatus.underReview === 'no'
              ? false
              : undefined,
        }),
      },
      removeRules: isEditMode
        ? {
            approve: rmvApproveRules,
            manualReview: rmvUnderReviewRules,
          }
        : undefined,
    };

    if (!inputs.name.value) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Please fill all required input');

      handleHideToast();
      return;
    }

    return { ...data };
  };

  const handleUpdateCustomRule = async (e, params) => {
    e.preventDefault();

    const variables = getMutationVariables(params);

    if (variables) {
      await clientRuleSetUpdate({
        variables: {
          input: {
            ...variables,
          },
        },
        refetchQueries: () => [
          {
            query: PRIVATE_CLIENT_INFO_QUERY,
            variables: { [clientIdentifier[0]]: clientIdentifier[1] },
          },
        ],
      });
    }
  };

  const handleCreateCustomRule = async (e, params) => {
    e.preventDefault();
    const variables = getMutationVariables(params);

    if (variables) {
      await clientRuleSetCreate({
        variables: {
          input: {
            ...variables,
          },
        },
        refetchQueries: () => [
          {
            query: PRIVATE_CLIENT_INFO_QUERY,
            variables: { [clientIdentifier[0]]: clientIdentifier[1] },
          },
        ],
      });
    }
  };

  const [clientRuleSetCreate, { loading, error }] = useMutation(
    CREATE_RULE_SET_MUTATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Rule Created');
        data.showNotificationPopup.setValue(true);

        handleHideToast();
        history.push('/settings/loan-rules');
      },
      onError,
    },
  );

  const [clientRuleSetUpdate, { loading: updateLoading, error: updateError }] =
    useMutation(RULE_SET_UPDATE_MUTATION, {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Rule Updated');
        data.showNotificationPopup.setValue(true);

        handleHideToast();
        window.location = '/settings/loan-rules';
      },
      onError,
    });

  return {
    handleHideToast,
    loading,
    updateLoading,
    error,
    updateError,
    form: inputs,
    data,
    handleCreateCustomRule: (e, params) => handleCreateCustomRule(e, params),
    handleUpdateCustomRule: (e, params) => handleUpdateCustomRule(e, params),
    approvedEmploymentStatuses,
    underReviewEmploymentStatuses,
    approvedFirstCentralDelinquency,
    underReviewFirstCentralDelinquency,
    approvedCrcDelinquency,
    underReviewCrcDelinquency,
    loanRuleInView,
    approvedBlacklistStatuses,
    underReviewBlacklistStatuses,
    approvedCreditRegistryDelinquency,
    underReviewCreditRegistryDelinquency,
    approvedBvnNinMatch,
    underReviewBvnNinMatch,
  };
};

export { useMutateCreateCustomRule };
