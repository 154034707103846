import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  UserManagementProvider,
  DeleteClientUserProvider,
  UpdateClientUserRoleProvider,
  UserManagementTableProvider,
} from '../../Providers';
import {
  DataCard,
  Table,
  Button,
  Modal,
  Close,
  Input,
  Loader,
  Toast,
  BreadCrumbSection,
  SideBar,
  DateRange,
  EmptyScreen,
  Select,
  Card,
  ReactSelect,
} from '../../components';
import { reduceStringLength } from '../../lib/Utils';

import './_UserManagement.scss';

const headers = {
  clientUsersHeader: ['Full Name', 'Email Address', 'Role', ' '],
};
const UserManagement = ({
  data,
  userManagementInput,
  handleReset,
  handleStartDate,
  handleEndDate,
  runSearchQuery,
}) => {
  const {
    handleUserRoleUpdate,
    updateRoleInput,
    loading: updateUserRoleLoading,
    handleHideToast: handleUpdateUserRoleHideToast,
    userRoles,
    toggleEditRolePopup,
    selectedRoles,
    updateSelectedRoles,
  } = UpdateClientUserRoleProvider();

  const {
    handleDeleteClientUser,
    deleteClientUserInput,
    loading: deleteClientUserLoading,
    handleToast: handleDeleteClientHideToast,
    toggleDeleteUserPopup,
  } = DeleteClientUserProvider();

  return (
    <>
      <Fragment>
        <BreadCrumbSection>
          <Link
            to="/settings/create-user"
            className="button secondary"
            data-testid="createUsers-button"
          >
            Create User
          </Link>
        </BreadCrumbSection>

        <SideBar>
          <section>
            <h4 className={'sidebar-header'}>Search Parameter</h4>

            <form>
              <Input
                data-testid="user-fullname"
                label="Full Name"
                placeholder="Enter Full Name"
                {...userManagementInput.name.input}
              />
              <Input
                data-testid="user-emailaddress"
                label="Email Address"
                placeholder="Enter Email Address"
                {...userManagementInput.email.input}
              />
              <Select
                label="Client Role"
                {...userManagementInput.roles.input}
                data-testid="user-role"
              >
                <option disabled value="">
                  --Select a Client Role--
                </option>
                {userRoles &&
                  userRoles.map((status, key) => (
                    <option key={key} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </Select>
              <div>
                <span className="sidebar__label">{'Created at'}</span>
                <div className={'filter-range'}>
                  <DateRange
                    startDateInput={userManagementInput.createdAtLt.value}
                    endDateInput={userManagementInput.createdAtGt.value}
                    startDate={handleStartDate}
                    endDate={handleEndDate}
                  />
                </div>
              </div>

              <div>
                <Button click_event={handleReset} data-testid="reset-button">
                  Reset
                </Button>
                <Button
                  classes={'right secondary'}
                  click_event={runSearchQuery}
                  data-testid="search-button"
                >
                  Search
                </Button>
              </div>
            </form>
          </section>
        </SideBar>

        {updateUserRoleLoading ? (
          <Loader loadingText="loading..." />
        ) : (
          <div className="actions change-role-popup">
            <Fragment>
              <Toast
                title={`Change Role`}
                content={updateRoleInput.content.value}
                active={updateRoleInput.showToast.value}
                classes={updateRoleInput.status.value}
                clickEvent={handleUpdateUserRoleHideToast}
              />
              <Modal
                title={'Change Role'}
                active={updateRoleInput.showEditRolePopup.value}
              >
                <Close
                  className="close-icon"
                  onClick={() => toggleEditRolePopup()}
                />

                <div className="form-fields">
                  <Card title="User Email">
                    <Input
                      type="text"
                      readOnly
                      value={updateRoleInput.selectedUser.value.email}
                    />
                  </Card>
                  <Card>
                    <label className={'input_group'}>
                      <span className={'input_label'}>
                        Select Role
                        <span className="required">*</span>
                      </span>
                      <div className={'wrapper'}>
                        <ReactSelect
                          options={userRoles.map(i => ({
                            value: i.id,
                            label: i.name,
                          }))}
                          name="userRoles"
                          isMulti
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          value={selectedRoles}
                          maxMenuHeight="120px"
                          onChange={updateSelectedRoles}
                          menuPortalTarget={document?.body}
                          menuPlacement="bottom"
                          menuPosition="absolute"
                        />
                      </div>
                    </label>
                  </Card>
                </div>

                <div className="flex-container center">
                  <Button
                    classes="secondary center"
                    click_event={e =>
                      handleUserRoleUpdate(
                        e,
                        updateRoleInput.selectedUser.value,
                      )
                    }
                  >
                    Update
                  </Button>
                </div>
              </Modal>
            </Fragment>
          </div>
        )}

        {deleteClientUserLoading ? (
          <Loader loadingText="loading..." />
        ) : (
          <Fragment>
            <Toast
              title={`Delete Selected User`}
              content={deleteClientUserInput.content.value}
              active={deleteClientUserInput.showToast.value}
              classes={deleteClientUserInput.status.value}
              clickEvent={handleDeleteClientHideToast}
            />
            <Modal
              title={'Delete User'}
              active={deleteClientUserInput.showDeleteUserPopup.value}
            >
              <Close
                className="close-icon"
                onClick={() => toggleDeleteUserPopup()}
              />
              <div className="content center">
                Are you sure you want to delete this -{' '}
                <span className="caption">
                  {' '}
                  {deleteClientUserInput.selectedUserId.value.email}
                </span>{' '}
                - user?
              </div>
              <div className="actions">
                <div className="flex-container center">
                  <Button
                    classes="secondary"
                    click_event={e =>
                      handleDeleteClientUser(
                        e,
                        deleteClientUserInput.selectedUserId.value.id,
                      )
                    }
                  >
                    Delete User
                  </Button>
                </div>
              </div>
            </Modal>
          </Fragment>
        )}

        <main className={'layout-main'}>
          <div className="user_management">
            {data && data.clientAccountUsersXn.nodes.length > 0 ? (
              <Fragment>
                <UserManagementTableProvider
                  render={({
                    userManagementTableData,
                    isLoading,
                    handlePagination,
                  }) => (
                    <section>
                      <DataCard noPadding={false}>
                        <Table>
                          <div className="table__head align-left">
                            {headers.clientUsersHeader.map(header => (
                              <span key={header}> {header} </span>
                            ))}
                          </div>
                          <div className="table__body align-left">
                            {userManagementTableData &&
                              userManagementTableData.clientAccountUsersXn.nodes
                                .length > 0 &&
                              userManagementTableData.clientAccountUsersXn.nodes.map(
                                (clientAccountUser, key) => (
                                  <ul
                                    key={`${key}${clientAccountUser.user.email}`}
                                  >
                                    <li className="table_line">
                                      <Link
                                        to={`user-management/${clientAccountUser.user.email}`}
                                      >
                                        <span>
                                          {clientAccountUser.user.firstName}{' '}
                                          {clientAccountUser.user.lastName}
                                        </span>
                                        <span>
                                          {reduceStringLength(
                                            clientAccountUser.user.email,
                                            46,
                                          )}
                                        </span>
                                        <span>
                                          {clientAccountUser.roles.map(
                                            (role, index) => (
                                              <>
                                                {index > 0 && ', '}
                                                {role.name}
                                              </>
                                            ),
                                          )}
                                        </span>
                                      </Link>
                                      <span className="action_button">
                                        <Button
                                          data-testid="edit-button"
                                          className="edit_button"
                                          click_event={() =>
                                            toggleEditRolePopup(
                                              clientAccountUser.user,
                                              clientAccountUser.roles,
                                            )
                                          }
                                        >
                                          Edit Role
                                        </Button>
                                        <Button
                                          data-testid="delete-button"
                                          click_event={() =>
                                            toggleDeleteUserPopup(
                                              clientAccountUser.user,
                                            )
                                          }
                                          classes="delete_button"
                                        >
                                          Delete
                                        </Button>
                                      </span>
                                    </li>
                                  </ul>
                                ),
                              )}
                          </div>
                        </Table>
                      </DataCard>

                      {userManagementTableData.clientAccountUsersXn.pageInfo
                        .hasNextPage &&
                        (isLoading ? (
                          <Loader />
                        ) : (
                          <Button
                            classes={'secondary center activate-policy'}
                            click_event={handlePagination}
                          >
                            Load more
                          </Button>
                        ))}
                    </section>
                  )}
                />
              </Fragment>
            ) : (
              <DataCard noPadding={true}>
                <EmptyScreen message="No Record Found" />
              </DataCard>
            )}
          </div>
        </main>
      </Fragment>
    </>
  );
};

const UserManagementWrapper = () => (
  <UserManagementProvider
    render={({
      data,
      userManagementInput,
      handleReset,
      handleStartDate,
      handleEndDate,
      runSearchQuery,
      handlePagination,
    }) => (
      <UserManagement
        data={data}
        userManagementInput={userManagementInput}
        handleReset={handleReset}
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
        runSearchQuery={runSearchQuery}
        handlePagination={handlePagination}
      />
    )}
  />
);

export default UserManagementWrapper;
