import React from 'react';
import { Flex, Skeleton, SkeletonProps } from '@chakra-ui/react';

const CustomSkeleton = (props: SkeletonProps) => (
  <Skeleton {...props}>
    {props.children ? <>{props.children}</> : <Flex opacity={0}>skeleton</Flex>}
  </Skeleton>
);

export default CustomSkeleton;
