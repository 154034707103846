import React from 'react';
import './_Login.scss';
import { Input, Button, Toast } from '../../components/';
import { Link } from 'react-router-dom';
import { OriginateLogo } from '../../components/Icons/';
import { ChangePasswordProvider } from '../../Providers';

const NewPassword = () => (
  <>
    {
      <ChangePasswordProvider
        render={({ onSubmit, input, handleHideToast }) => (
          <section className={'login_page'}>
            <section className={'login_logo'}>
              <OriginateLogo />
            </section>
            <main className={'login_holder'}>
              <h3 className={'center-text'}>New Password</h3>
              <Toast
                title="Customers"
                content={input.content.value}
                classes={input.status.value}
                active={input.showToast.value}
                clickEvent={handleHideToast}
              />
              <form onSubmit={onSubmit}>
                <Input
                  type="password"
                  name="old Password"
                  label="Current Password"
                  placeholder="Enter current password"
                  {...input.oldPassword.input}
                />
                <Input
                  type="password"
                  name="new Password"
                  label="New Password"
                  placeholder="Enter new password"
                  {...input.newPassword.input}
                />
                <Input
                  type="password"
                  name="confirm New Password"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  {...input.confirmNewPassword.input}
                />
                <Button classes={'block secondary'} type="submit">
                  Update Password
                </Button>
                <div className="login_link">
                  <Link to="/login">Return to Login →</Link>
                </div>
              </form>
            </main>
          </section>
        )}
      />
    }
  </>
);

export default NewPassword;
