/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  BreadCrumbSection,
  Button,
  DataCard,
  Table,
  EmptyScreen,
  CustomerDetailsSideBar,
} from '../../components';
import { CreditPolicyDetailsProvider as PolicyDetailsProvider } from '../../Providers';
import {
  reduceStringLength,
  nairaAmount,
  daysOnTable,
  formatPolicyMinMaxAmount,
} from '../../lib/Utils';
import { Tabs, TabList, Tab, TabPanel } from 'react-web-tabs';
import moment from 'moment';
import { ErrorBoundary } from 'react-error-boundary';

const headers = {
  portfolioHeader: [
    'Loan ID',
    'Amount (N)',
    'Duration (days)',
    'Total (N)',
    'Disbursed (N)',
    'Paid (N)',
    'Created',
  ],
  applicationHeader: [
    'Application ID',
    'Amount (N)',
    'Duration (days)',
    'Interest',
    'Total',
    'Created',
  ],
};

const PolicyDetails = () => (
  <>
    <PolicyDetailsProvider
      render={({ policyDetails }) => {
        return (
          <Fragment>
            <BreadCrumbSection />

            <CustomerDetailsSideBar>
              <article className="customer-info policy-details">
                <span className="cover-image" />
                {policyDetails?.viewer?.account?.policies?.nodes?.length ? (
                  <div className="avatar">
                    {`${policyDetails.viewer.account.policies.nodes[0]?.name
                      ?.charAt(0)
                      ?.toUpperCase()}`}
                  </div>
                ) : (
                  <div className="avatar">...</div>
                )}
              </article>

              <article className="detail-section">
                <h6>Policy Information</h6>
                {policyDetails?.viewer?.account?.policies?.nodes?.length && (
                  <ul>
                    <li>
                      <span>Policy Name</span>
                      <span>
                        {
                          policyDetails?.viewer?.account?.policies?.nodes[0]
                            ?.name
                        }
                      </span>
                    </li>
                    <li>
                      <span>Duration Type</span>
                      <span>
                        {
                          policyDetails?.viewer?.account?.policies?.nodes[0]
                            ?.durationType
                        }
                      </span>
                    </li>
                    <li>
                      <span>Repayment Type</span>
                      <span>
                        {
                          policyDetails?.viewer?.account?.policies?.nodes[0]
                            ?.repaymentType
                        }
                      </span>
                    </li>
                    <li>
                      <span>Amount Range</span>
                      <span>
                        {formatPolicyMinMaxAmount(
                          policyDetails?.viewer?.account?.policies?.nodes[0],
                        )}
                      </span>
                    </li>
                    <li>
                      <span>Duration Range</span>
                      <span>{`${
                        policyDetails?.viewer?.account?.policies?.nodes[0]
                          ?.minLoanDuration !== null
                          ? policyDetails?.viewer?.account?.policies?.nodes[0]
                              ?.minLoanDuration
                          : 'N/A'
                      } - ${
                        policyDetails?.viewer?.account?.policies?.nodes[0]
                          ?.maxLoanDuration !== null
                          ? policyDetails?.viewer?.account?.policies?.nodes[0]
                              ?.maxLoanDuration
                          : 'N/A'
                      }`}</span>
                    </li>
                  </ul>
                )}
              </article>
              <div className="center">
                <Link to={`/loan-products`}>
                  <Button classes="reset">View All Policies</Button>
                </Link>
              </div>
            </CustomerDetailsSideBar>

            <main className="layout-customerdetails">
              <div className="">
                {policyDetails?.viewer?.account?.policies?.nodes?.length ? (
                  <h5>{`${
                    policyDetails.viewer.account.policies.nodes[0]?.name ||
                    'N/A'
                  }`}</h5>
                ) : (
                  <h5>...</h5>
                )}
              </div>

              <div>
                <Tabs>
                  <TabList>
                    <Tab tabFor="applications" className={'tab-title'}>
                      Applications
                    </Tab>
                    <Tab tabFor="portfolios" className={'tab-title'}>
                      Loan
                    </Tab>
                  </TabList>

                  <TabPanel tabId="applications">
                    {policyDetails?.viewer?.account?.policies?.nodes?.length &&
                    policyDetails.viewer.account.policies.nodes[0]?.applications
                      ?.nodes?.length ? (
                      <ErrorBoundary
                        fallback={
                          <DataCard noPadding={false}>
                            <EmptyScreen message="Error Fetching Loan Products" />
                          </DataCard>
                        }
                      >
                        <Fragment>
                          <DataCard noPadding={false}>
                            <Table>
                              <div className="table__head">
                                {headers?.applicationHeader?.map(
                                  (header, key) => (
                                    <span key={key}> {header} </span>
                                  ),
                                )}
                              </div>
                              <div className="table__body">
                                {policyDetails?.viewer?.account?.policies?.nodes[0]?.applications?.nodes?.map(
                                  (application, key) => (
                                    <ul key={key}>
                                      <Link
                                        to={`/v1/applications/${application?.applicationNumber}/${application?.user?.email}`}
                                      >
                                        <li>
                                          <span>
                                            <div className="first-td">
                                              {reduceStringLength(
                                                application?.applicationNumber,
                                                12,
                                              )}
                                            </div>
                                          </span>
                                          <span>
                                            {nairaAmount(application?.amount)}
                                          </span>
                                          <span>
                                            {daysOnTable(
                                              application?.loanDuration,
                                            )}
                                          </span>
                                          <span>
                                            {nairaAmount(
                                              application?.chargesAmount,
                                            )}
                                          </span>
                                          <span>
                                            {nairaAmount(
                                              application?.fullAmount,
                                            )}
                                          </span>
                                          <span>
                                            {moment(
                                              application?.createdAt,
                                            ).format('lll')}
                                          </span>
                                        </li>
                                      </Link>
                                    </ul>
                                  ),
                                )}
                              </div>
                            </Table>
                          </DataCard>
                        </Fragment>
                      </ErrorBoundary>
                    ) : (
                      <DataCard noPadding={false}>
                        <EmptyScreen
                          noPadding={true}
                          message={'No Application On This Yet'}
                        />
                      </DataCard>
                    )}
                  </TabPanel>

                  <TabPanel tabId="portfolios">
                    {policyDetails &&
                    policyDetails.viewer.account.policies.nodes.length &&
                    policyDetails.viewer.account.policies.nodes[0]?.portfolios
                      ?.nodes?.length ? (
                      <ErrorBoundary
                        fallback={
                          <DataCard noPadding={false}>
                            <EmptyScreen message="Error Fetching Loan Products" />
                          </DataCard>
                        }
                      >
                        <Fragment>
                          <DataCard noPadding={false}>
                            <Table>
                              <div className="table__head">
                                {headers?.portfolioHeader?.map(
                                  (header, key) => (
                                    <span key={key}> {header} </span>
                                  ),
                                )}
                              </div>
                              <div className="table__body">
                                {policyDetails?.viewer?.account?.policies?.nodes[0]?.portfolios?.nodes?.map(
                                  (portfolio, key) => (
                                    <ul key={key}>
                                      <Link
                                        to={`/loans/${portfolio?.portfolioNumber}/${portfolio?.user?.email}`}
                                      >
                                        <li>
                                          <span>
                                            <div className="first-td">
                                              {reduceStringLength(
                                                portfolio?.portfolioNumber,
                                                12,
                                              )}
                                            </div>
                                          </span>
                                          <span>
                                            {nairaAmount(portfolio?.amount)}
                                          </span>
                                          <span>
                                            {daysOnTable(
                                              portfolio?.loanDuration,
                                            )}
                                          </span>
                                          <span>
                                            {nairaAmount(portfolio?.fullAmount)}
                                          </span>
                                          <span>
                                            {nairaAmount(
                                              portfolio?.amountDisbursed,
                                            )}
                                          </span>
                                          <span>
                                            {nairaAmount(portfolio?.amountPaid)}
                                          </span>
                                          <span>
                                            {moment(
                                              portfolio?.createdAt,
                                            ).format('lll')}
                                          </span>
                                        </li>
                                      </Link>
                                    </ul>
                                  ),
                                )}
                              </div>
                            </Table>
                          </DataCard>
                        </Fragment>
                      </ErrorBoundary>
                    ) : (
                      <DataCard noPadding={false}>
                        <EmptyScreen
                          noPadding={true}
                          message={'No Portfolio On This Yet'}
                        />
                      </DataCard>
                    )}
                  </TabPanel>
                </Tabs>
              </div>
            </main>
          </Fragment>
        );
      }}
    />
  </>
);

export default PolicyDetails;
