import React, { Fragment } from 'react';
import moment from 'moment';
import { DataCard } from '../../../../components';
import { reduceStringLength } from '../../../../lib/Utils';

const MandateHistoryTab = ({ portfolioData }) => {
  return (
    <Fragment>
      <div className="history-timeline">
        <DataCard>
          <div className="timeline">
            <div className="timeline-body">
              <div className="timeline-item">
                <ul>
                  {portfolioData &&
                    portfolioData.portfolio &&
                    portfolioData.portfolio.debitMandate &&
                    portfolioData.portfolio.debitMandate.history
                      .reverse()
                      .map((mandateHistory, key) => (
                        <li key={key}>
                          <div className="timeline-content">
                            <p>
                              {mandateHistory.comment != null
                                ? reduceStringLength(mandateHistory.comment)
                                : 'N/A'}{' '}
                            </p>
                            <div className="history_info">
                              <p className="info">
                                {moment(mandateHistory.updatedAt).format(
                                  'llll',
                                )}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                </ul>
              </div>
            </div>
          </div>
        </DataCard>
      </div>
    </Fragment>
  );
};

export default MandateHistoryTab;
