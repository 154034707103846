import { gql } from 'apollo-boost';

export const CLOSE_OUT_LOAN_BEFORE_DISBURSEMENT = gql`
  mutation MP_CloseOutLoanBeforeDisbursement(
    $input: CloseOutLoanBeforeDisbursementInput!
  ) {
    closeOutLoanBeforeDisbursement(input: $input) {
      success
    }
  }
`;
