import { gql } from 'apollo-boost';

export const fragments = {
  permission: gql`
    fragment PermissionDetails on Permission {
      id
      name
      description
      category
    }
  `,
  role: gql`
    fragment RoleDetails on Role {
      id
      name
      description
      isCustom
    }
  `,
  authPayload: gql`
    fragment PayloadDetails on AuthPayload {
      token
    }
  `,
  authPayloadUser: gql`
    fragment PayloadUserDetails on User {
      id
      firstName
      lastName
      email
      phone
      status
    }
  `,
};

export const GET_AVAILABLE_ROLES_AND_PERMISSIONS = gql`
  query MP_AvailableRolesAndPermissions {
    availableRoles {
      ...RoleDetails
      permissions {
        ...PermissionDetails
      }
    }
    availablePermissions {
      ...PermissionDetails
    }
  }

  ${fragments.role}
  ${fragments.permission}
`;

export const CREATE_CLIENT_ACCOUNT_USER_MUTATION = gql`
  mutation MP_CreateClientAccountUserMutation(
    $input: CreateClientAccountUserInput!
  ) {
    createClientAccountUser(input: $input) {
      user {
        ...PayloadUserDetails
      }
    }
  }

  ${fragments.authPayloadUser}
`;

export const CUSTOM_ROLE_CREATE_MUTATION = gql`
  mutation MP_CustomRoleCreate($input: CreateCustomRoleInput!) {
    customRoleCreate(input: $input) {
      name
      description
      isCustom
    }
  }
`;

export const CUSTOM_ROLE_DELETE_MUTATION = gql`
  mutation MP_CustomRoleDelete($input: DeleteCustomRoleInput!) {
    customRoleDelete(input: $input) {
      id
    }
  }
`;

export const CUSTOM_ROLE_UPDATE_MUTATION = gql`
  mutation MP_CustomRoleUpdate($input: UpdateCustomRoleInput!) {
    customRoleUpdate(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_CLIENT_ACCOUNT_USER_MUTATION = gql`
  mutation MP_UpdateClientAccountUserMutation(
    $input: UpdateClientAccountUserInput!
  ) {
    updateClientAccountUser(input: $input) {
      user {
        ...PayloadUserDetails
      }
    }
  }

  ${fragments.authPayloadUser}
`;

export const SEND_CLIENT_USER_INVITATION_MUTATION = gql`
  mutation MP_SendClientUserInvitation($input: SendClientUserInvitationInput!) {
    sendClientUserInvitation(input: $input) {
      ok
    }
  }
`;

export const CLIENT_ACCOUNT_USER_QUERY = gql`
  query MP_ClientAccountUserQuery(
    $first: ConnectionLimitInt
    $cursor: ConnectionCursor
    $where: ClientAccountUsersWhereInput!
  ) {
    clientAccountUsers: clientAccountUsersXn(
      first: $first
      after: $cursor
      orderBy: createdAt_DESC
      where: $where
    ) @connection(key: "clientAccountUsers") {
      nodes {
        id
        user {
          ...PayloadUserDetails
        }
        roles {
          ...RoleDetails
          permissions {
            ...PermissionDetails
          }
        }
        permissions {
          ...PermissionDetails
        }
        status
        createdAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }

  ${fragments.authPayloadUser}
  ${fragments.role}
  ${fragments.permission}
`;

export const POLICY_CREATE_MUTATION = gql`
  mutation MP_PolicyCreate($input: CreatePolicyInput!) {
    policyCreate(input: $input) {
      recordId
      record {
        id
        name
      }
    }
  }
`;

export const POLICY_UPDATE_MUTATION = gql`
  mutation MP_PolicyUpdate(
    $where: PolicyWhereUniqueInput!
    $data: UpdatePolicyInput!
  ) {
    policyUpdate(where: $where, data: $data) {
      recordId
      record {
        id
        name
      }
    }
  }
`;

export const INTEREST_CALC_METHOD_QUERY = gql`
  query MP_InterestCalcMethod {
    interestCalculationMethods {
      name
      description
    }
  }
`;
