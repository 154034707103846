import React from 'react';
import OriginateClient from '../../Providers/OriginateClient';

const selfHosted = () => {
  let hostname = window.location.hostname.toLowerCase();

  if (!hostname.includes('originate') && !hostname.includes('indicina')) {
    return true;
  }
};

const OriginateLogo = () => (
  <OriginateClient
    render={({ clientId }) => (
      <span>
        {selfHosted() ? (
          <img height={40} alt="Logo" src={clientId.clientInfo.logoUrl} />
        ) : (
          <svg width={155} height={32} fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.923 31.845c8.793 0 15.922-7.129 15.922-15.922C31.845 7.129 24.716 0 15.923 0 7.129 0 0 7.129 0 15.923c0 8.793 7.129 15.922 15.923 15.922z"
              fill="#07F"
            />
            <path
              d="M53.447 26.55l-4.033-6.83c1.936-.458 3.926-2.152 3.926-5.324 0-3.335-2.286-5.782-6.023-5.782h-8.39V26.55h3.819v-6.372h2.796l3.523 6.372h4.383zm-6.695-9.735h-4.006v-4.84h4.006c1.533 0 2.69.915 2.69 2.42 0 1.506-1.157 2.42-2.69 2.42zM60.024 26.55V8.613h-3.818V26.55h3.818zM72.27 26.899c3.253 0 5.808-1.345 7.636-3.388v-6.884h-8.55v3.335h4.732v2.15c-.726.673-2.232 1.372-3.818 1.372-3.254 0-5.62-2.5-5.62-5.889 0-3.388 2.366-5.888 5.62-5.888 1.909 0 3.442 1.075 4.248 2.339l3.173-1.721c-1.344-2.124-3.684-4.006-7.421-4.006-5.244 0-9.546 3.603-9.546 9.276 0 5.647 4.302 9.304 9.546 9.304zM86.756 26.55V8.613h-3.819V26.55h3.819zM106.369 26.55V8.613h-3.818v11.267L94.242 8.614h-3.926V26.55h3.818V14.853l8.551 11.696h3.684zM126.63 26.55l-6.91-17.936h-4.786l-6.938 17.935h4.356l1.13-3.038h7.69l1.102 3.038h4.356zm-6.507-6.4h-5.593l2.797-7.717 2.796 7.717zM134.54 26.55V11.974h5.216v-3.36h-14.305v3.36h5.243V26.55h3.846zM154.895 26.55v-3.362h-8.873v-4.06h8.685v-3.361h-8.685v-3.791h8.873V8.614h-12.691V26.55h12.691z"
              fill="#06192F"
            />
            <path
              d="M16.234 27.31c1.323 0 2.436-1.112 2.436-2.435 0-1.324-1.113-2.436-2.436-2.436s-2.436 1.112-2.436 2.436c0 1.323 1.113 2.436 2.436 2.436z"
              fill="#fff"
            />
          </svg>
        )}
      </span>
    )}
  ></OriginateClient>
);

export default OriginateLogo;
