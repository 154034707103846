import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { GET_ENUM } from '../../Providers-new/EnumTypes';
import {
  useMutateCreateCustomApprovalWorkflow,
  useRoles,
  useGetApprovalWorkflows,
} from '../../Providers-new/ApprovalWorkflow';
import { useClientInfo } from '../../Providers-new/Client';

import {
  BreadCrumbSection,
  Button,
  Card,
  DataCard,
  Input,
  Loader,
  TextArea,
  Select,
  Notification,
  Toast,
} from '../../components';
import { CompleteIcon, Plus, DeleteIcon } from '../../components/Icons';

import './_UserManagement.scss';

const CreateCustomApprovalWorkflow = ({
  approvalWorkflowStepType,
  workflowId,
  workflowList,
  clientInfo,
}) => {
  const { roles } = useRoles();

  // Get the workflow id with route name
  if (workflowId) {
    const approvalWorkflowNodes = workflowList.approvalWorkflows.nodes || [];
    ({ id: workflowId } =
      approvalWorkflowNodes.find(({ name }) => name === workflowId) || {});
  }
  const activeWorkflowId = clientInfo.approvalWorkflow || 'none';
  const isActive = activeWorkflowId === workflowId;

  const {
    data,
    inputs,
    stepState,
    addNewStep,
    removeStep,
    handleHideToast,
    handleStepChange,
    handleCreateCustomApprovalWorkflow,
    handleUpdateCustomApprovalWorkflow,
    formLoading,
    getRoleById,
  } = useMutateCreateCustomApprovalWorkflow(workflowId, workflowList);

  return (
    <>
      <BreadCrumbSection />

      {data.showNotificationPopup.value && (
        <Notification title="Success">
          <div className="notification_icon-holder">
            <CompleteIcon />
          </div>
          <h5 className="center-text">
            Custom Approval Workflow Successfully Created.
          </h5>
        </Notification>
      )}

      {data.showUpdatedNotificationPopup.value && (
        <Notification title="Success">
          <div className="notification_icon-holder">
            <CompleteIcon />
          </div>
          <h5 className="center-text">
            Custom Approval Workflow Successfully Updated.
          </h5>
        </Notification>
      )}

      <Fragment>
        <Toast
          title="Create Approval Workflow"
          content={data.content.value}
          classes={data.status.value}
          active={data.showToast.value}
          clickEvent={handleHideToast}
        />
        <form
          onSubmit={
            data.isEditMode.value
              ? handleUpdateCustomApprovalWorkflow
              : handleCreateCustomApprovalWorkflow
          }
        >
          <div className="form-card create-approval-workflow">
            <DataCard>
              <div className="flex-container space-between">
                <div className="form-desc">
                  <Card title="Workflow details">
                    Define basic information for the work flow you created by
                    adding a name and a description
                  </Card>
                </div>

                <div className="form-fields">
                  <div className="flex-container">
                    <div className="form-fields">
                      <Card title="Name" required>
                        <Input
                          placeholder="Name of workflow"
                          required
                          type="text"
                          readOnly={isActive}
                          {...inputs.workflowFieldName.input}
                        />
                      </Card>
                    </div>
                  </div>

                  <div className="flex-container">
                    <div className="form-fields">
                      <Card title="Description of workflow">
                        <TextArea
                          placeholder="Name of workflow"
                          type="text"
                          readOnly={isActive}
                          {...inputs.workflowFieldDesc.input}
                        />
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </DataCard>

            <DataCard>
              <div className="flex-container space-between">
                <div className="form-desc">
                  <Card title="Approval Steps">
                    Define the number of steps required to complete a loan
                    application. You will also assign user roles to each
                    workflow step (ie what user types will have access to
                    approve applications at each step)
                  </Card>
                </div>
                <div className="form-fields">
                  {stepState.length < 1 && (
                    <div className={'required'}>
                      It's required to add an approval workflow step
                    </div>
                  )}
                  {stepState.length >= 1 &&
                    stepState.map((val, idx) => {
                      const approvalType_defaultValue = val
                        ? val.approvalType
                        : '-- Select Workflow Type --';
                      const userRole_defaultValue = val
                        ? val.roles
                        : '-- Select New Role --';
                      const userRole_defaultValueName = getRoleById(
                        userRole_defaultValue,
                        roles,
                      );
                      const approvalTypeId = `approvalType-${idx}`;
                      const rolesId = `selectedRole-${idx}`;
                      return (
                        <Fragment key={idx}>
                          {!isActive ? (
                            <div
                              className="remove-step"
                              onClick={() => removeStep(idx)}
                            >
                              <DeleteIcon color={'#C1292E'} />
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="flex-container" key={`${idx}-`}>
                            <div className="form-fields step-text">
                              <Card title={`Step ${idx + 1}`} />
                            </div>
                            <div className="form-fields">
                              <Card title="Choose Approval Type">
                                <Select
                                  className="approvalType"
                                  onChange={handleStepChange}
                                  id={approvalTypeId}
                                  data-idx={idx}
                                  name={approvalTypeId}
                                  disabled={isActive}
                                >
                                  <option
                                    defaultValue={approvalType_defaultValue}
                                  >
                                    {approvalType_defaultValue}
                                  </option>
                                  {approvalWorkflowStepType &&
                                    approvalWorkflowStepType.map(
                                      (item, key) => (
                                        <option key={key} value={item.name}>
                                          {item.name}
                                        </option>
                                      ),
                                    )}
                                </Select>
                              </Card>
                            </div>
                            <div className="form-fields">
                              <Card title="Choose User Role">
                                <Select
                                  name={rolesId}
                                  data-idx={idx}
                                  id={rolesId}
                                  className="roles"
                                  onChange={handleStepChange}
                                  disabled={isActive}
                                >
                                  <option defaultValue={userRole_defaultValue}>
                                    {userRole_defaultValueName}
                                  </option>
                                  {roles &&
                                    roles.map(item => {
                                      return (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </Select>
                              </Card>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
              </div>

              <div className="form-fields">
                {!isActive ? (
                  <div
                    className="flex-container add-new-step"
                    onClick={() => addNewStep()}
                  >
                    <Plus /> Add new step
                  </div>
                ) : (
                  ''
                )}
              </div>
            </DataCard>

            <div className="flex-container center">
              {formLoading ? <Loader /> : ''}
              {data.isEditMode.value ? (
                <Fragment>
                  <Link to="/settings/approval-workflows" className={'actions'}>
                    <Button classes="primary" type="submit">
                      {!isActive ? 'Cancel' : 'Back'}
                    </Button>
                  </Link>
                  {!isActive && (
                    <Button classes="secondary" type="submit">
                      Update Changes
                    </Button>
                  )}
                </Fragment>
              ) : (
                <Button classes="secondary" type="submit">
                  Create Workflow
                </Button>
              )}
            </div>
          </div>
        </form>
      </Fragment>
    </>
  );
};

const CreateCustomApprovalWorkflowWrapper = (render = {}) => {
  const workflowId = render.match.params.workflowId
    ? render.match.params.workflowId
    : null;
  const { loading, result } = useClientInfo({ privateFields: true });
  const { loading: roleLoading } = useRoles();
  const {
    loading: qLoading,
    result: { data: workflowList },
  } = useGetApprovalWorkflows();

  const { data: enumResult = [] } = useQuery(GET_ENUM, {
    variables: {
      type: 'ApprovalWorkflowStepType',
    },
  });

  if (
    loading ||
    roleLoading ||
    !enumResult ||
    enumResult.length < 1 ||
    qLoading
  )
    return <Loader />;
  return (
    <CreateCustomApprovalWorkflow
      clientInfo={result.data.clientInfo}
      approvalWorkflowStepType={enumResult.enumValues}
      workflowId={workflowId}
      workflowList={workflowList}
    />
  );
};

export default withRouter(CreateCustomApprovalWorkflowWrapper);
