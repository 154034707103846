import React, { Fragment } from 'react';
import { useRevertApplicationWorkFlowStep } from '../../../../Providers-new/ApprovalWorkflow';
import {
  Button,
  DataCard,
  Loader,
  Modal,
  TextArea,
  Toast,
} from '../../../../components';
import { Close } from '../../../../components/Icons';

const RevertApplicationWorkFlowSteps = ({ data }) => {
  const {
    handleHideToast,
    handleRevertReview,
    input,
    showRevertReviewPopup,
    revertReviewLoader,
    handleHidePopup,
  } = useRevertApplicationWorkFlowStep({ data });
  return (
    <Fragment>
      {revertReviewLoader && <Loader loadingText="loading..." />}
      <Modal
        title={'Revert Review'}
        classes="complete-review-popup"
        active={input.showPopup.value}
      >
        <Close className="close-icon" onClick={e => handleHidePopup(e)} />

        <DataCard noPadding={true}>
          <span className="optional">(optional)</span>
          <TextArea
            cols={1}
            rows={7}
            label="Add Comment"
            placeholder="Comments"
            {...input.comment.input}
          />
          {data?.application?.approvalWorkflow?.nextStep?.approvalType !==
            'DISBURSE' &&
            data?.application?.status?.name !== 'APPROVED' && (
              <Button
                classes={'complete-review revert'}
                click_event={handleRevertReview}
              >
                {' '}
                Revert Review
              </Button>
            )}
        </DataCard>
      </Modal>

      <Toast
        title={'Revert Review'}
        content={input.content.value}
        classes={input.status.value}
        active={input.showToast.value}
        clickEvent={handleHideToast}
      />
      {data?.application?.status?.name !== 'APPROVED' &&
        data?.application?.approvalWorkflow?.nextStep?.orderNo > 1 && (
          <Button classes={'right'} click_event={showRevertReviewPopup}>
            Revert Review
          </Button>
        )}
    </Fragment>
  );
};

export default RevertApplicationWorkFlowSteps;
