import React from 'react';
import PropTypes from 'prop-types';
import { Button, DateRange, Input, SideBar } from '../../components';

const Search = ({
  input,
  type,
  resetAction,
  searchAction,
  handleStartDate,
  handleEndDate,
}) => {
  const interestWallet = type === 'Interest Wallet';
  const disbursementWallet = type === 'Disbursement Wallet';
  const collectionWallet = type === 'Collection Wallet';

  return (
    <SideBar>
      <section>
        <h4 className={'sidebar-header'}>Search Parameter</h4>

        {interestWallet && (
          <Input
            label={`${type} ID`}
            placeholder={`Enter ${type} ID`}
            {...input.searchParams.feeWalletId.input}
          />
        )}
        {disbursementWallet && (
          <Input
            label={`${type} ID`}
            placeholder={`Enter ${type} ID`}
            {...input.searchParams.disbursementWalletId.input}
          />
        )}
        {collectionWallet && (
          <Input
            label={`${type} ID`}
            placeholder={`Enter ${type} ID`}
            {...input.searchParams.collectionWalletId.input}
          />
        )}

        <div>
          <span className="sidebar__label">{`Created At`}</span>
          <div className={'filter-range'}>
            <DateRange
              startDateInput={
                disbursementWallet
                  ? input.searchParams.dWCreatedAtLt.value
                  : input.searchParams.fWCreatedAtLt.value
              }
              endDateInput={
                disbursementWallet
                  ? input.searchParams.dWCreatedAtGt.value
                  : input.searchParams.fWCreatedAtGt.value
              }
              startDate={handleStartDate}
              endDate={handleEndDate}
            />
          </div>
        </div>

        <div>
          <Button classes={'reset'} click_event={resetAction}>
            Reset
          </Button>
          <Button classes={'secondary right'} click_event={searchAction}>
            Search
          </Button>
        </div>
      </section>
    </SideBar>
  );
};

Search.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  resetAction: PropTypes.func,
  searchAction: PropTypes.func,
};

export default Search;
