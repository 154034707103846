import * as React from 'react';

const CircleDashIcon = props => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    style={{
      shapeRendering: 'geometricPrecision',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
    {...props}
  >
    <circle cx="14" cy="14" r="14" fill="#F0D77F" fillOpacity={0.24} />
    <path
      d="M8 14H20.5"
      style={{
        stroke: '#F2AF01',
      }}
    />
  </svg>
);

export default CircleDashIcon;
