import React, { Fragment, useState } from 'react';
import {
  Button,
  Toast,
  Loader,
  Modal,
  Close,
  TextArea,
} from '../../../../components/index';
import { useMutateCloseOutLoanBeforeDisbursement } from '../../../../Providers-new/CloseOutLoanBeforeDisbursement/index';

const CloseOutLoanBeforeDisbursement = ({ portfolioData }) => {
  const [comment, setComment] = useState('');
  const updateCommentInput = e => {
    const { value } = e.target;
    setComment(value);
  };

  const {
    handleHideToast: closeOutLoanBeforeDisbursementToast,
    loading: closeOutLoanBeforeDisbursementLoading,
    closeOutLoanBeforeDisbursementToggle,
    data: closeOutLoanBeforeDisbursementData,
    handleCloseOutLoanBeforeDisbursement,
  } = useMutateCloseOutLoanBeforeDisbursement({
    portfolioId: portfolioData?.portfolio?.id,
    portfolioNumber: portfolioData?.portfolio?.portfolioNumber,
    comment,
    setComment,
  });

  return (
    <Fragment>
      {closeOutLoanBeforeDisbursementLoading ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          <div className="manual-approval">
            <Toast
              title="Close out Loan"
              content={closeOutLoanBeforeDisbursementData.content.value}
              clickEvent={closeOutLoanBeforeDisbursementToast}
              active={closeOutLoanBeforeDisbursementData.showToast.value}
              classes={closeOutLoanBeforeDisbursementData.status.value}
            />

            <Modal
              title={'Close Out Loan'}
              classes="repayment-date-modal"
              active={closeOutLoanBeforeDisbursementData.showModal.value}
            >
              <Close
                className="close-icon"
                onClick={closeOutLoanBeforeDisbursementToggle}
              />
              <form onSubmit={handleCloseOutLoanBeforeDisbursement}>
                <p className="close-out-loan_text">
                  You are attempting to close out the loan. Are you sure you
                  want to close out the loan?
                </p>

                <TextArea
                  classes="text-area textAreaHeight"
                  placeholder="Add Comment"
                  type="text"
                  required
                  name="comment"
                  value={comment}
                  onChange={updateCommentInput}
                />

                <div className="repayment-double-buttons">
                  <Button
                    classes="cancel"
                    click_event={closeOutLoanBeforeDisbursementToggle}
                  >
                    Cancel
                  </Button>
                  <Button classes="secondary blue save" type="submit">
                    Update
                  </Button>
                </div>
              </form>
            </Modal>
            {portfolioData.portfolio &&
            (portfolioData.portfolio.status.name ===
              'PENDING_MANUAL_DISBURSEMENT' ||
              portfolioData.portfolio.status.name ===
                'PENDING_DISBURSEMENT') ? (
              <Button
                classes="right"
                click_event={closeOutLoanBeforeDisbursementToggle}
              >
                Decline Loan
              </Button>
            ) : (
              ''
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CloseOutLoanBeforeDisbursement;
