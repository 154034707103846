import { gql } from 'apollo-boost';

export const REVERSE_REPAYMENT_TRANSACTION_MUTATION = gql`
  mutation MP_ReverseRepaymentTransactionMutation(
    $transactionId: ID!
    $comment: String
  ) {
    reverseRepaymentTransaction(
      input: { transactionId: $transactionId, comment: $comment }
    ) {
      success
    }
  }
`;
