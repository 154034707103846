import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface TextareaProps extends ChakraTextareaProps {
  label?: ReactNode;
  errorMessage?: string;
  helperText?: ReactNode;
  isOptional?: boolean;
}

const Textarea = forwardRef(
  (
    {
      id,
      name,
      label,
      isDisabled,
      isRequired,
      isReadOnly,
      helperText,
      errorMessage,
      isOptional,
      ...restProps
    }: TextareaProps,
    ref,
  ) => {
    return (
      <FormControl
        id={id || name}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        isInvalid={!!errorMessage}
      >
        {(label || isOptional) && (
          <Flex
            w="100%"
            as={FormLabel}
            alignItems="center"
            justifyContent="space-between"
          >
            {label}

            {isOptional && (
              <FormHelperText as="span" m={0} ml="auto">
                (optional)
              </FormHelperText>
            )}
          </Flex>
        )}

        <ChakraTextarea
          ref={ref}
          name={name}
          borderRadius={0}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isReadOnly={isReadOnly}
          isInvalid={!!errorMessage}
          {...restProps}
        />

        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);

export default Textarea;
