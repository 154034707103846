import React, { Fragment } from 'react';
import { Table } from '../../../../components';

const PropertyDetailsTab = ({
  hasApplicationMeta,
  requiredPropertyApplicationMeta,
}) => {
  return (
    <Fragment>
      <div className="property-details">
        <Table>
          <div className="table__body">
            <ul>
              {hasApplicationMeta &&
                requiredPropertyApplicationMeta.map(({ key, value, file }) => {
                  return file == null && key !== 'durationMeta' ? (
                    <li>
                      <span className="left-text">
                        {key.replace(/([A-Z])/g, ' $1').trim()}
                      </span>

                      <span className="right-text">{value}</span>
                    </li>
                  ) : (
                    'Property Information Not Provided'
                  );
                })}
            </ul>
          </div>
        </Table>
      </div>
    </Fragment>
  );
};

export default PropertyDetailsTab;
