import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { NewCustomerPageTitle } from './components';
import { CustomerDetails } from './routes/customer-details';

const NewCustomer = () => {
  return (
    <Grid gap={4} h="100%" gridTemplateRows="max-content 1fr" pb={6}>
      <GridItem gap={8}>
        <NewCustomerPageTitle />
      </GridItem>
      <GridItem>
        <CustomerDetails />
      </GridItem>
    </Grid>
  );
};

export default NewCustomer;
