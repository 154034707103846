import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Skeleton,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface InputProps extends ChakraInputProps {
  label?: ReactNode;
  errorMessage?: string;
  helperText?: ReactNode;
  isLoading?: boolean;
}

const Input = forwardRef(
  (
    {
      id,
      name,
      label,
      isLoading,
      isDisabled,
      isRequired,
      isReadOnly,
      helperText,
      errorMessage,
      ...restProps
    }: InputProps,
    ref,
  ) => {
    return (
      <FormControl
        id={id || name}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        isInvalid={!!errorMessage}
      >
        {label && <FormLabel>{label}</FormLabel>}

        <Skeleton isLoaded={!isLoading}>
          <ChakraInput
            ref={ref}
            name={name}
            borderRadius={0}
            isDisabled={isDisabled}
            isRequired={isRequired}
            isReadOnly={isReadOnly}
            isInvalid={!!errorMessage}
            {...restProps}
          />
        </Skeleton>

        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);

export default Input;
