import PropTypes from 'prop-types';
import React from 'react';
import BreadCrumbs from '../Breadcrumbs/BreadCrumbs';
import './_BreadCrumbSection.scss';

const BreadCrumbSection = props => {
  return (
    <div className="breadcrumbsection">
      <BreadCrumbs unlinklast={props.unlinklast} />
      <div className="breadcrumbsection__content">{props.children}</div>
    </div>
  );
};

BreadCrumbSection.propTypes = {
  unlinklast: PropTypes.bool,
};

export default BreadCrumbSection;
