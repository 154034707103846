import React from 'react';
import './_Invitation.scss';
import { Input, Button, Toast } from '../../components';
import { Link, withRouter } from 'react-router-dom';
import { CompleteInvitationProvider } from '../../Providers';

const AcceptInvite = () => (
  <>
    <CompleteInvitationProvider
      render={({ onSubmit, data, form, handleHideToast, email, invitedBy }) => (
        <section className="invitation">
          <main className="invitation_main">
            <div className="content">
              <h3 className="invite-title">Create your originate account</h3>

              <div className="subtext-container">
                <p className="invite-subtext">
                  You've been invited by{' '}
                  <span className="inivitee-email">{invitedBy}</span> to join
                  their business on Originate. Create an account to login to
                  your new dashboard
                </p>
              </div>
              <Toast
                title="Accept Invite"
                content={data.content.value}
                classes={data.status.value}
                active={data.showToast.value}
                clickEvent={handleHideToast}
              />
              <form onSubmit={onSubmit}>
                <Input
                  type={'email'}
                  name={'email'}
                  label={'Email'}
                  placeholder={'company@mail.com'}
                  value={email}
                  disabled
                />
                <div className="names">
                  <Input
                    type={'firstName'}
                    name={'firstName'}
                    label={'First Name'}
                    minLength={2}
                    placeholder={'First Name'}
                    {...form.firstName.input}
                  />
                  <Input
                    type={'lastName'}
                    name={'lastName'}
                    label={'Last Name'}
                    minLength={2}
                    placeholder={'Last Name'}
                    {...form.lastName.input}
                  />
                </div>
                <Input
                  type={'password'}
                  name={'password'}
                  label={'Password'}
                  placeholder={'Enter password'}
                  {...form.password.input}
                />
                <Input
                  type={'password'}
                  name={'confirmPassword'}
                  label={'Confirm Password'}
                  placeholder={'Confirm Password'}
                  {...form.confirmPassword.input}
                />
                <div className="invite-button">
                  <Button classes={'block secondary'} type="submit">
                    Create your Originate account
                  </Button>
                </div>

                <div className="invitation_link">
                  <p>
                    Already have an account?
                    <Link to="/login">Sign in</Link>
                  </p>
                </div>
              </form>
            </div>
          </main>
        </section>
      )}
    />
  </>
);

export default withRouter(AcceptInvite);
