import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import './_Input.scss';

// eslint-disable-next-line
const Input = React.forwardRef(
  (
    {
      label,
      classes,
      errorMessage,
      required,
      amountField,
      numberField,
      phoneField,
      allowZeros,
      readOnly,
      type,
      ...restProps
    },
    ref,
  ) => {
    return (
      <Fragment>
        <label className={'input_group'}>
          {label == null ? (
            ''
          ) : (
            <span className={'input_label'}>
              {label}
              {required && <span className="required">*</span>}
            </span>
          )}
          {amountField || numberField || phoneField ? (
            <NumberFormat
              thousandSeparator={amountField}
              allowLeadingZeros={phoneField || allowZeros}
              decimalSeparator={false}
              type="tel"
              allowNegative={false}
              className={`input ${classes == null ? '' : classes} ${
                readOnly ? 'not-allowed' : ''
              }`}
              ref={ref}
              {...restProps}
            />
          ) : (
            <input
              className={`input ${classes == null ? '' : classes} ${
                readOnly ? 'not-allowed' : ''
              }`}
              type={type === null ? 'text' : type}
              ref={ref}
              required={required}
              {...restProps}
            />
          )}
          {errorMessage && (
            <span className={'input__error'}>{errorMessage}</span>
          )}
        </label>
      </Fragment>
    );
  },
);

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.string,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorMessage: PropTypes.string,
};

export default Input;
