import { withRouter } from 'react-router-dom';
import { APPROVE_MANUAL_REVIEW_APPLICATION_MUTATION } from '../graphql/mutations';
import { useCustomState } from '../lib/CustomHooks';
import { trimGraphQLError } from '../lib/Utils';
import { useMutation } from '@apollo/react-hooks';

const ApproveManualReviewApplication = ({ render, applicationId, history }) => {
  const approveManualReviewApplicationPrompt = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    showPopup: useCustomState(false),
    comment: useCustomState(),
    message: useCustomState(),
  };

  const handleHideToast = e => {
    e.preventDefault();

    setTimeout(() => {
      approveManualReviewApplicationPrompt.showToast.setValue(false);
    }, 4500);
  };

  const handleApproveReviewApplication = (
    e,
    approveManualReviewApplicationMutation,
  ) => {
    e.preventDefault();

    const { comment: message } = approveManualReviewApplicationPrompt;

    approveManualReviewApplicationMutation({
      variables: {
        applicationId,
        comment: message.value,
      },
    });
  };

  const showApproveManualApplicationPopup = e => {
    e.preventDefault();

    approveManualReviewApplicationPrompt.showPopup.setValue(true);
  };

  const hideApproveManualApplicationPopup = e => {
    e.preventDefault();

    approveManualReviewApplicationPrompt.showPopup.setValue(
      !approveManualReviewApplicationPrompt.showPopup.value,
    );
  };

  const [approveManualReviewApplicationMutation, { loading }] = useMutation(
    APPROVE_MANUAL_REVIEW_APPLICATION_MUTATION,
    {
      async onCompleted() {
        approveManualReviewApplicationPrompt.showToast.setValue(true);
        approveManualReviewApplicationPrompt.status.setValue('Success');
        approveManualReviewApplicationPrompt.content.setValue(
          'Application Approved',
        );
      },

      onError(error) {
        approveManualReviewApplicationPrompt.showToast.setValue(true);
        approveManualReviewApplicationPrompt.status.setValue('Failed');
        approveManualReviewApplicationPrompt.content.setValue(
          trimGraphQLError(error.message),
        );
        setTimeout(() => {
          window.location.href = history.location.pathname;
        }, 1500);
      },
    },
  );

  return render({
    handleHideToast,
    handleApproveReviewApplication: e =>
      handleApproveReviewApplication(e, approveManualReviewApplicationMutation),
    applicationId,
    input: approveManualReviewApplicationPrompt,
    showApproveManualApplicationPopup,
    hideApproveManualApplicationPopup,
    approveApplicationLoader: loading,
  });
};

export default withRouter(ApproveManualReviewApplication);
