import { useMutation } from '@apollo/react-hooks';
import { REVERT_APPLICATION_WORKFLOW_STEP } from '../../graphql/mutations';
import { APPLICABLE_CUSTOMER_APPLICATIONS_QUERY } from '../../graphql/queries';
import { trimGraphQLError } from '../../lib/Utils';
import { useFormInput, useKeyPair } from '../Form';

const useRevertApplicationWorkFlowStep = ({ data: { application } }) => {
  const data = {
    showToast: useKeyPair(false),
    content: useKeyPair(),
    status: useKeyPair(),
    showPopup: useKeyPair(false),
    comment: useFormInput(),
    message: useKeyPair(),
  };

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 2500);
  };

  const [revertWorkflowStepMutation, { loading }] = useMutation(
    REVERT_APPLICATION_WORKFLOW_STEP,
    {
      onCompleted: () => {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Successful');
        data.showPopup.setValue(!data.showPopup.value);

        handleHideToast();
        window.location.reload();
      },

      onError: error => {
        data.showToast.setValue(true);
        data.status.setValue('Failed');
        data.content.setValue(trimGraphQLError(error.message));
        handleHideToast();
      },
    },
  );

  const handleRevertWorkflowStep = (e, revertWorkflowStepMutation) => {
    e.preventDefault();

    const { comment: message } = data;

    revertWorkflowStepMutation({
      variables: {
        input: {
          applicationId: application?.id,
          comment: message?.value,
        },
      },
      refetchQueries: () => [
        {
          query: APPLICABLE_CUSTOMER_APPLICATIONS_QUERY,
          variables: { applicationNumber: application?.applicationNumber },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  const showRevertReviewPopup = e => {
    e.preventDefault();

    data.showPopup.setValue(true);
  };

  const handleHidePopup = () => {
    data.showPopup.setValue(false);
  };

  return {
    handleHideToast,
    handleRevertReview: e =>
      handleRevertWorkflowStep(e, revertWorkflowStepMutation),
    input: data,
    showRevertReviewPopup,
    revertReviewLoader: loading,
    handleHidePopup,
  };
};

export { useRevertApplicationWorkFlowStep };
