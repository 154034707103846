import { gql } from 'apollo-boost';

export const MAKE_MANUAL_REPAYMENT_MUTATION = gql`
  mutation MP_MakeManualRepaymentMutation(
    $portfolioId: ID!
    $amount: Float!
    $bankId: ID!
    $accountNumber: String!
    $reference: String!
  ) {
    makeManualRepayment(
      input: {
        portfolioId: $portfolioId
        amount: $amount
        bankId: $bankId
        accountNumber: $accountNumber
        reference: $reference
      }
    ) {
      success
    }
  }
`;
