import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_APPLICATION_LOAN_DURATION } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { GET_ENUM } from '../EnumTypes';
import { APPLICATION_QUERY } from '../../graphql/queries';

const useMutateUpdateLoanDuration = ({ application }) => {
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
    showNotificationPopup: useKeyPair(false),
  };

  const inputs = {
    durationType: useFormInput(),
    loanDuration: useFormInput(),
  };

  const { loading: durationTypeLoading, data: loanDurationType } = useQuery(
    GET_ENUM,
    {
      variables: {
        type: 'DurationType',
      },
    },
  );

  let duration = [];
  if (loanDurationType) duration = loanDurationType.enumValues;

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const handleHideNotification = () => {
    setTimeout(() => {
      data.showNotificationPopup.setValue(false);
    }, 3500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
    handleModalToggle();
  };

  const handleUpdateLoanDuration = async e => {
    e.preventDefault();

    if (!inputs.loanDuration.value && !inputs.durationType.value) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('All fields required');

      handleHideToast();
      return;
    }

    if (!inputs.loanDuration.value) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Input loan duration');

      handleHideToast();
      return;
    }

    if (!inputs.durationType.value) {
      data.showToast.setValue(true);
      data.status.setValue('Failed');
      data.content.setValue('Select a duration type');

      handleHideToast();
      return;
    }

    await updateApplicationLoanDuration({
      variables: {
        input: {
          applicationId: application.id,
          loanDuration: parseInt(inputs.loanDuration.value),
          durationType: inputs.durationType.value,
        },
      },
      refetchQueries: () => [
        {
          query: APPLICATION_QUERY,
          variables: { applicationNumber: application.applicationNumber },
        },
      ],
    });
    inputs.durationType.setValue('');
    inputs.loanDuration.setValue('');
  };

  const handleModalToggle = () => {
    data.showModal.setValue(!data.showModal.value);
    inputs.durationType.setValue('');
    inputs.loanDuration.setValue('');
  };

  const [updateApplicationLoanDuration, { loading }] = useMutation(
    UPDATE_APPLICATION_LOAN_DURATION,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Loan Duration Updated');
        data.showModal.setValue(false);
        data.showNotificationPopup.setValue(true);

        handleHideToast();
        handleHideNotification();
      },
      onError,
    },
  );

  return {
    handleHideToast,
    loading,
    handleModalToggle,
    data,
    inputs,
    handleUpdateLoanDuration,
    duration,
    durationTypeLoading,
  };
};

export { useMutateUpdateLoanDuration };
