import { useMutation } from '@apollo/react-hooks';
import React, { Fragment, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button, Loader, Toast } from '../../../../../components';
import { EDIT_REPAYMENT_MUTATION } from '../../../../../graphql/mutations/editRepayment';
import { removeCommas, trimGraphQLError } from '../../../../../lib/Utils';
import {
  DiscardModal,
  SaveRepaymentModal,
  EditRepaymentFormRow,
  SaveWithoutChanges,
} from './components';
import { PORTFOLIO_QUERY } from '../../../../../graphql/queries';

const EditRepayment = ({ toggleEditTable, portfolio }) => {
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showSaveRepaymentModal, setShowSaveRepaymentModal] = useState(false);
  const [showSaveWithoutChangesModal, setShowSaveWithoutChangesModal] =
    useState(false);
  const { repayments } = portfolio;
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: { repayments },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'repayments',
  });

  const toggleDiscardModal = () => setShowDiscardModal(prevState => !prevState);

  const toggleSaveWithoutChangesModal = () =>
    setShowSaveWithoutChangesModal(prevState => !prevState);

  const toggleSaveRepaymentModal = () =>
    setShowSaveRepaymentModal(prevState => !prevState);

  const discardChanges = () => {
    toggleDiscardModal();
    toggleEditTable();
    reset();
  };

  const [editRepayment, { loading: editRepaymentLoading }] = useMutation(
    EDIT_REPAYMENT_MUTATION,
    {
      onError: err => {
        setErrorMessage(trimGraphQLError(err?.message));
      },
      onCompleted: () => {
        setSuccessMessage('Repayment successfully edited.');
        toggleSaveRepaymentModal();
      },
      refetchQueries: () => [
        {
          query: PORTFOLIO_QUERY,
          variables: { portfolioNumber: portfolio.portfolioNumber },
        },
      ],
    },
  );

  useEffect(() => {
    if (errorMessage !== '') {
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage !== '') {
      setTimeout(() => {
        toggleEditTable();
      }, 2000);
    }
  }, [successMessage]);

  const cleanupPayload = formValues => {
    const payload = [];

    formValues.repayments.forEach(item => {
      if (item?.status?.name === 'PENDING' && !item?.mandateInstruction?.id) {
        const repayment = {
          newPrincipalAmount: item.principalPortion
            ? parseFloat(removeCommas(item.principalPortion))
            : null,
          newInterestAmount: item.interestPortion
            ? parseFloat(removeCommas(item.interestPortion))
            : 0,
          newLateFee: item.lateFee
            ? parseFloat(removeCommas(item.lateFee))
            : null,
          newDueDate: item.dueDate ? item.dueDate : null,
        };

        const isEmpty = Object.values(repayment).every(value => !value);

        const isValid = repayment.newPrincipalAmount && repayment.newDueDate;

        !isEmpty && isValid && payload.push(repayment);
      }
    });

    return payload;
  };

  const onSubmit = values => {
    const repayments = cleanupPayload(values);

    editRepayment({
      variables: {
        portfolioId: portfolio?.id,
        repayments,
      },
    });
  };

  const isFormEdited = () => {
    const payload = [];

    getValues().repayments.forEach(item => {
      if (item?.status?.name === 'PENDING' && !item?.mandateInstruction?.id) {
        const repayment = {
          ...item,
          principalPortion: item.principalPortion
            ? parseFloat(removeCommas(item.principalPortion))
            : null,
          interestPortion: item.interestPortion
            ? parseFloat(removeCommas(item.interestPortion))
            : 0,
          lateFee: item.lateFee ? parseFloat(removeCommas(item.lateFee)) : null,
        };
        payload.push(repayment);
      } else payload.push(item);
    });

    return JSON.stringify(payload) !== JSON.stringify(repayments);
  };

  const formOnSubmit = e => {
    e.preventDefault();

    const isEdited = isFormEdited();

    if (!isEdited) {
      toggleSaveWithoutChangesModal();
      return;
    }

    toggleSaveRepaymentModal();
  };

  return (
    <Fragment>
      {(editRepaymentLoading || successMessage) && <Loader />}

      {errorMessage && (
        <Toast
          title="Error Editing Repayment"
          content={errorMessage}
          classes={'fail'}
          active={true}
        />
      )}

      {successMessage && (
        <Toast
          title="Repayment successfully edited"
          classes={'successful'}
          active={true}
        />
      )}

      <form className="edit-repayment-form" onSubmit={formOnSubmit}>
        <section className="edit-repayment-buttons">
          <Button onClick={toggleDiscardModal} classes="grey" type="reset">
            Discard Changes
          </Button>
          <Button classes="secondary" type="submit">
            Preview Changes
          </Button>
        </section>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <section className="edit-repayment-row edit-repayment-row__title">
            <section>Principal Amount</section>

            <section>Interest Amount</section>

            <section>Late Fee</section>

            <section>Repayment Date</section>

            <section className="delete-repayment-button" />
          </section>

          {fields.map((item, index) => (
            <Fragment key={item.id}>
              {item?.status?.name !== 'CANCELLED' && (
                <EditRepaymentFormRow
                  index={index}
                  control={control}
                  remove={remove}
                  disabled={
                    item?.status?.name !== 'PENDING' ||
                    item?.mandateInstruction?.id
                  }
                  status={item?.status?.name}
                />
              )}
            </Fragment>
          ))}

          <div>
            <Button
              classes="green btn-small"
              click_event={() =>
                append({
                  principalPortion: '',
                  interestPortion: '',
                  lateFee: '',
                  status: { name: 'PENDING' },
                  dueDate: null,
                })
              }
            >
              Add New Repayment
            </Button>
          </div>
        </div>
      </form>

      <DiscardModal
        showDiscardModal={showDiscardModal}
        toggleDiscardModal={toggleDiscardModal}
        discardChanges={discardChanges}
      />

      <SaveWithoutChanges
        showModal={showSaveWithoutChangesModal}
        toggleModal={toggleSaveWithoutChangesModal}
        save={discardChanges}
      />

      <SaveRepaymentModal
        isOpen={showSaveRepaymentModal}
        toggleModal={toggleSaveRepaymentModal}
        saveRepayment={handleSubmit(onSubmit)}
        repayments={cleanupPayload(getValues())}
      />
    </Fragment>
  );
};

export default EditRepayment;
