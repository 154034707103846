import { graphql } from '@/__generated/gql';

export const CREATE_LOAN_PRODUCT = graphql(/* GraphQL */ `
  mutation CreateLoanProduct($input: CreatePolicyInput!) {
    policyCreate(input: $input) {
      recordId
      record {
        id
        name
        status
        loanType
        minLoanAmount
        maxLoanAmount
        repaymentType
        interestCalculationMethod {
          name
        }
      }
    }
  }
`);
