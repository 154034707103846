import React from 'react';
import PropTypes from 'prop-types';
import './_Loader.scss';

const Loader = props => {
  return (
    <div className="loader-container">
      <div className="loader-holder">
        <div className="loader" />
        <div className={'loader-text'}>{props.loadingText}</div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  loadingText: PropTypes.string,
};

export default Loader;
