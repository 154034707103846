import React from 'react';
import './_SideBar.scss';
import PropTypes from 'prop-types';

const SideBar = props => {
  return (
    <div
      className={`sidebar-holder ${
        props.noPadding === true ? 'no-padding' : ''
      }`}
    >
      <aside className={'sidebar'}>{props.children}</aside>
    </div>
  );
};

SideBar.propTypes = {
  noPadding: PropTypes.string,
};

export default SideBar;
