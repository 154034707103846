import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ATTRIBUTES } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { useEffect, useState } from 'react';
import { useClientInfo } from '../Client';
import { useGetAttributesBySlug } from './useGetAttributesBySlug';

const useUpdateIntegrationConfig = () => {
  const sessionToken = localStorage.getItem('api-key');
  let isDisabled = false;
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    paystackPublicKey: useFormInput(),
    paystackSecretKey: useFormInput(),
    paystackEnablePartialDebit: useFormInput(),
    decideClientKey: useFormInput(),
    decideClientSecret: useFormInput(),
    decideClientSlug: useFormInput(),
    mbsClientId: useFormInput(),
    mbsClientSecret: useFormInput(),
    mbsExternalBankStatementTenor: useFormInput(),
    remitaMerchantId: useFormInput(),
    remitaMerchantApiKey: useFormInput(),
    remitaMerchantToken: useFormInput(),
    remitaServiceTypeId: useFormInput(),
    creditBureauUsername: useFormInput(),
    creditBureauPassword: useFormInput(),
    creditRegistrySubscriberId: useFormInput(),
    creditRegistryEmail: useFormInput(),
    creditRegistryPassword: useFormInput(),
    crcStrUserId: useFormInput(),
    crcStrPassword: useFormInput(),
    crcNano: useFormInput(),
    crcMicro: useFormInput(),
    okraPublicKey: useFormInput(),
    okraPrivateKey: useFormInput(),
    okraValidityPeriod: useFormInput(),
    okraWidgetUrl: useFormInput(),
    okraClientToken: useFormInput(),
  };

  const [checkboxPartialDebitState, setPartialDebitCheckboxState] = useState(
    inputs.paystackEnablePartialDebit.value,
  );
  const { merchantAttributes, loading: ciLoading } = useGetAttributesBySlug();

  const [bvnServices, setBvnServices] = useState([]);

  const { clientInfo } = useClientInfo({
    privateFields: false,
  });

  const handlePartialDebitCheckBoxInput = e => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setPartialDebitCheckboxState(true);
    }
    if (!isChecked) {
      setPartialDebitCheckboxState(false);
    }
  };

  const bvnServiceProviders = merchantAttributes?.find(
    item => item?.attribute?.name === 'bvnServiceProviders',
  );

  useEffect(() => {
    setBvnServices(bvnServiceProviders?.data);
  }, [bvnServiceProviders]);

  const bvnDefaultValue = bvnServices
    ?.filter(item => item.enabled === true)
    .map(item => item.name);

  const handleRadioChange = (e, name) => {
    const isChecked = e.target.checked;
    const newValues = {
      data: bvnServiceProviders.data.map(x => {
        return {
          ...x,
          enabled: name === x.name && isChecked ? true : false,
        };
      }),
    };

    setBvnServices(newValues.data);
  };

  const payload = {
    paystackApiCreds: {
      publicKey: inputs.paystackPublicKey.value,
      secretKey: inputs.paystackSecretKey.value,
    },
    enablePartialDebit: checkboxPartialDebitState == false ? 'false' : 'true',
    decideCreds: {
      clientKey: inputs.decideClientKey.value,
      clientSecret: inputs.decideClientSecret.value,
      clientSlug: inputs.decideClientSlug.value,
    },
    mbsApiKeys: {
      clientId: inputs.mbsClientId.value,
      clientSecret: inputs.mbsClientSecret.value,
    },
    externalBankStatementTenor: inputs.mbsExternalBankStatementTenor.value,
    remitaApiCreds: {
      merchantId: inputs.remitaMerchantId.value,
      merchantApiKey: inputs.remitaMerchantApiKey.value,
      merchantToken: inputs.remitaMerchantToken.value,
    },
    remitaServiceTypeId: inputs.remitaServiceTypeId.value,
    creditBureauApiCreds: {
      username: inputs.creditBureauUsername.value,
      password: inputs.creditBureauPassword.value,
    },
    creditRegistryApiCreds: {
      subscriberId: inputs.creditRegistrySubscriberId.value,
      email: inputs.creditRegistryEmail.value,
      password: inputs.creditRegistryPassword.value,
    },
    crcApiCreds: {
      strUserID: inputs.crcStrUserId.value,
      strPassword: inputs.crcStrPassword.value,
    },
    okraApiCreds: {
      publicKey: inputs.okraPublicKey.value,
      privateKey: inputs.okraPrivateKey.value,
      clientToken: inputs.okraClientToken.value,
      widgetUrl: inputs.okraWidgetUrl.value,
    },
    okraValidityPeriod: inputs.okraValidityPeriod.value,
    bvnServiceProviders: bvnServices,
  };

  const validBVN =
    !payload?.bvnServiceProviders?.VFD ||
    !payload?.bvnServiceProviders?.IDENTITY_PASS;

  const validePaystack =
    (!payload.paystackApiCreds.publicKey &&
      !payload.paystackApiCreds.secretKey) ||
    (payload.paystackApiCreds.publicKey && payload.paystackApiCreds.secretKey);

  const validOkra =
    (!payload.okraApiCreds.publicKey &&
      !payload.okraApiCreds.privateKey &&
      !payload.okraApiCreds.clientToken &&
      !payload.okraApiCreds.widgetUrl) ||
    (payload.okraApiCreds.publicKey &&
      payload.okraApiCreds.privateKey &&
      payload.okraApiCreds.clientToken &&
      payload.okraApiCreds.widgetUrl);

  if (
    (payload.decideCreds.clientKey && !payload.decideCreds.clientSecret) ||
    (payload.decideCreds.clientSecret && !payload.decideCreds.clientKey) ||
    (payload.mbsApiKeys.clientId && !payload.mbsApiKeys.clientSecret) ||
    (!payload.mbsApiKeys.clientId && payload.mbsApiKeys.clientSecret) ||
    (payload.remitaApiCreds.merchantId &&
      !payload.remitaApiCreds.merchantApiKey &&
      !payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      payload.remitaApiCreds.merchantApiKey &&
      !payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      payload.remitaApiCreds.merchantApiKey &&
      payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      !payload.remitaApiCreds.merchantApiKey &&
      payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      payload.remitaApiCreds.merchantApiKey &&
      payload.remitaApiCreds.merchantToken &&
      payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      payload.remitaApiCreds.merchantApiKey &&
      !payload.remitaApiCreds.merchantToken &&
      payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      payload.remitaApiCreds.merchantApiKey &&
      !payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      payload.remitaApiCreds.merchantApiKey &&
      !payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      !payload.remitaApiCreds.merchantApiKey &&
      payload.remitaApiCreds.merchantToken &&
      payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      !payload.remitaApiCreds.merchantApiKey &&
      payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (!payload.remitaApiCreds.merchantId &&
      !payload.remitaApiCreds.merchantApiKey &&
      !payload.remitaApiCreds.merchantToken &&
      payload.remitaServiceTypeId) ||
    (payload.remitaApiCreds.merchantId &&
      !payload.remitaApiCreds.merchantApiKey &&
      payload.remitaApiCreds.merchantToken &&
      payload.remitaServiceTypeId) ||
    (payload.remitaApiCreds.merchantId &&
      !payload.remitaApiCreds.merchantApiKey &&
      !payload.remitaApiCreds.merchantToken &&
      payload.remitaServiceTypeId) ||
    (payload.remitaApiCreds.merchantId &&
      !payload.remitaApiCreds.merchantApiKey &&
      payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (payload.remitaApiCreds.merchantId &&
      payload.remitaApiCreds.merchantApiKey &&
      !payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (payload.remitaApiCreds.merchantId &&
      payload.remitaApiCreds.merchantApiKey &&
      payload.remitaApiCreds.merchantToken &&
      !payload.remitaServiceTypeId) ||
    (payload.remitaApiCreds.merchantId &&
      payload.remitaApiCreds.merchantApiKey &&
      !payload.remitaApiCreds.merchantToken &&
      payload.remitaServiceTypeId) ||
    (payload.creditBureauApiCreds.username &&
      !payload.creditBureauApiCreds.password) ||
    (!payload.creditBureauApiCreds.username &&
      payload.creditBureauApiCreds.password) ||
    (payload.creditRegistryApiCreds.subscriberId &&
      !payload.creditRegistryApiCreds.email &&
      !payload.creditRegistryApiCreds.password) ||
    (!payload.creditRegistryApiCreds.subscriberId &&
      payload.creditRegistryApiCreds.email &&
      !payload.creditRegistryApiCreds.password) ||
    (!payload.creditRegistryApiCreds.subscriberId &&
      !payload.creditRegistryApiCreds.email &&
      payload.creditRegistryApiCreds.password) ||
    (!payload.creditRegistryApiCreds.subscriberId &&
      payload.creditRegistryApiCreds.email &&
      payload.creditRegistryApiCreds.password) ||
    (payload.creditRegistryApiCreds.subscriberId &&
      !payload.creditRegistryApiCreds.email &&
      payload.creditRegistryApiCreds.password) ||
    (payload.creditRegistryApiCreds.subscriberId &&
      payload.creditRegistryApiCreds.email &&
      !payload.creditRegistryApiCreds.password) ||
    (payload.crcApiCreds.strUserID && !payload.crcApiCreds.strPassword) ||
    (!payload.crcApiCreds.strUserID && payload.crcApiCreds.strPassword) ||
    !validOkra ||
    !validePaystack ||
    !validBVN
  ) {
    isDisabled = true;
  }

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  useEffect(() => {
    const paystackApiCreds = merchantAttributes?.find(
      item => item?.attribute?.name === 'paystackApiCreds',
    );

    const enablePartialDebit = merchantAttributes?.find(
      item => item?.attribute?.name === 'enablePartialDebit',
    );

    const decideCreds = merchantAttributes?.find(
      item => item?.attribute?.name === 'decideCreds',
    );

    const mbsApiKeys = merchantAttributes?.find(
      item => item?.attribute?.name === 'mbsApiKeys',
    );

    const externalBankStatementTenor = merchantAttributes?.find(
      item => item?.attribute?.name === 'externalBankStatementTenor',
    );

    const remitaApiCreds = merchantAttributes?.find(
      item => item?.attribute?.name === 'remitaApiCreds',
    );

    const remitaServiceTypeId = merchantAttributes?.find(
      item => item?.attribute?.name === 'remitaServiceTypeId',
    );

    const creditBureauApiCreds = merchantAttributes?.find(
      item => item?.attribute?.name === 'creditBureauApiCreds',
    );

    const creditRegistryApiCreds = merchantAttributes?.find(
      item => item?.attribute?.name === 'creditRegistryApiCreds',
    );

    const crcApiCreds = merchantAttributes?.find(
      item => item?.attribute?.name === 'crcApiCreds',
    );

    const crcCreditBureauMicroReportIsEnabled = merchantAttributes?.find(
      item => item?.attribute?.name === 'crcCreditBureauMicroReportIsEnabled',
    );

    const crcCreditBureauNanoReportIsEnabled = merchantAttributes?.find(
      item => item?.attribute?.name === 'crcCreditBureauNanoReportIsEnabled',
    );

    const okraApiCreds = merchantAttributes?.find(
      item => item?.attribute?.name === 'okraApiCreds',
    );

    const okraValidityPeriod = merchantAttributes?.find(
      item => item?.attribute?.name === 'okraValidityPeriod',
    );

    const setInputValues = () => {
      if (paystackApiCreds && paystackApiCreds?.data) {
        inputs.paystackPublicKey.setValue(paystackApiCreds?.data?.publicKey);
        inputs.paystackSecretKey.setValue(paystackApiCreds?.data?.secretKey);
      }

      if (enablePartialDebit && enablePartialDebit?.value) {
        inputs.paystackEnablePartialDebit.setValue(
          enablePartialDebit?.value == 'false' ? false : true,
        );
        setPartialDebitCheckboxState(
          enablePartialDebit?.value == 'false' ? false : true,
        );
      }

      if (decideCreds && decideCreds?.data) {
        inputs.decideClientKey.setValue(decideCreds?.data?.clientKey);
        inputs.decideClientSecret.setValue(decideCreds?.data?.clientSecret);
        inputs.decideClientSlug.setValue(decideCreds?.data?.clientSlug);
      }

      if (mbsApiKeys && mbsApiKeys?.data) {
        inputs.mbsClientId.setValue(mbsApiKeys?.data?.clientId);
        inputs.mbsClientSecret.setValue(mbsApiKeys?.data?.clientSecret);
      }

      if (externalBankStatementTenor && externalBankStatementTenor?.value) {
        inputs.mbsExternalBankStatementTenor.setValue(
          externalBankStatementTenor?.value,
        );
      }

      if (remitaApiCreds && remitaApiCreds?.data) {
        inputs.remitaMerchantId.setValue(remitaApiCreds?.data?.merchantId);
        inputs.remitaMerchantApiKey.setValue(
          remitaApiCreds?.data?.merchantApiKey,
        );
        inputs.remitaMerchantToken.setValue(
          remitaApiCreds?.data?.merchantToken,
        );
      }

      if (remitaServiceTypeId && remitaServiceTypeId?.value) {
        inputs.remitaServiceTypeId.setValue(remitaServiceTypeId?.value);
      }

      if (creditBureauApiCreds && creditBureauApiCreds?.data) {
        inputs.creditBureauUsername.setValue(
          creditBureauApiCreds?.data?.username,
        );
        inputs.creditBureauPassword.setValue(
          creditBureauApiCreds?.data?.password,
        );
      }

      if (creditRegistryApiCreds && creditRegistryApiCreds?.data) {
        inputs.creditRegistryEmail.setValue(
          creditRegistryApiCreds?.data?.email,
        );
        inputs.creditRegistrySubscriberId.setValue(
          creditRegistryApiCreds?.data?.subscriberId,
        );
        inputs.creditRegistryPassword.setValue(
          creditRegistryApiCreds?.data?.password,
        );
      }

      if (crcApiCreds && crcApiCreds?.data) {
        inputs.crcStrUserId.setValue(crcApiCreds?.data?.strUserID);
        inputs.crcStrPassword.setValue(crcApiCreds?.data?.strPassword);
      }

      if (okraApiCreds && okraApiCreds?.data) {
        inputs.okraPublicKey.setValue(okraApiCreds?.data?.publicKey);
        inputs.okraPrivateKey.setValue(okraApiCreds?.data?.privateKey);
        inputs.okraClientToken.setValue(okraApiCreds?.data?.clientToken);
        inputs.okraWidgetUrl.setValue(okraApiCreds?.data?.widgetUrl);
      }

      if (okraValidityPeriod && okraValidityPeriod?.value) {
        inputs.okraValidityPeriod.setValue(okraValidityPeriod?.value);
      }
    };
    setInputValues();
  }, [merchantAttributes]);

  const handleUpdateIntegrationAttributes = async e => {
    e.preventDefault();

    await requestUpdateIntegrationAttributes({
      variables: {
        input: {
          accountId: clientInfo.clientId,
          ...payload,
        },
        token: sessionToken,
      },
    });
  };

  const [requestUpdateIntegrationAttributes, { loading }] = useMutation(
    UPDATE_ATTRIBUTES,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Updated 3rd Party Attribute Successfully');
        data.showModal.setValue(false);

        handleHideToast();
      },
      onError,
    },
  );

  return {
    handleUpdateIntegrationAttributes,
    loading,
    ciLoading,
    clientInfo,
    inputs,
    data,
    isDisabled,
    handleHideToast,
    checkboxPartialDebitState,
    handlePartialDebitCheckBoxInput,
    merchantAttributes,
    handleRadioChange,
    bvnDefaultValue,
    bvnServices,
  };
};

export { useUpdateIntegrationConfig };
